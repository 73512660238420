import { useTechnicalItineraryOperationFormRedirect } from '@modules/encoding/modules/technicalItinerary/hooks/useTechnicalItineraryOperationFormRedirect';
import { encodingShared } from '@modules/encoding/shared';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import entities from '@shared/entities';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useParams } from 'react-router-dom';
import useOperationTimelineList from '../../hooks/useOperationTimelineList';
import technicalItineraryRoutes, { TechnicalItineraryRouteParamsT } from '../../technicalItinerary.routes';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [track] = useTrackMutation();
    const { fieldCropId } = useParams<TechnicalItineraryRouteParamsT['operationTimeline']>();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const [continueButtonClicked, setContinueButtonClicked] = useState(false);
    const [success, setSuccess] = useState(false);

    /* -------------------------------- Entities -------------------------------- */
    const { currentSeasonLoading, currentSeason } = encodingShared.useCurrentSeason();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticSeasonState } = entities.staticSeason.useState();
    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: currentSeasonId,
    });
    const [finalizeOperationsTimelineMutation, { isLoading: isFinalizeOperationsTimelineLoading }] =
        entities.operation.useFinalizeOperationsTimeline();
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { staticOperationTypeConfigState } = entities.staticOperationTypeConfig.useState();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });

    const { navigateToNext } = useTechnicalItineraryOperationFormRedirect(undefined, navigate);

    const operationListForCrop = useOperationTimelineList({ period: 'CROP', fieldCropId, seasonId: currentSeasonId });
    const operationListForIntercrop = useOperationTimelineList({
        period: 'INTERCROP',
        fieldCropId,
        seasonId: currentSeasonId,
    });

    const isLoading =
        currentSeasonLoading ||
        currentFarmLoading ||
        fieldCropState.isLoading ||
        cropState.isLoading ||
        staticCropState.isLoading ||
        staticSeasonState.isLoading ||
        operationState.isLoading ||
        operationListForCrop.isLoading ||
        operationListForIntercrop.isLoading;

    /* ------------------------------- Local Sates ------------------------------ */

    /* -------------------------------- Handlers -------------------------------- */
    const getHandleBackClick = () => navigate(technicalItineraryRoutes.globalCharacteristics({ fieldCropId }));
    const getHandleContinueClick = async () => {
        setContinueButtonClicked(true);

        if (errorMessage) return;

        try {
            await finalizeOperationsTimelineMutation({
                id: Number(fieldCropId),
                farmSeasonId: currentSeasonId,
            });

            track({
                event: EVENTS.CROP_OPERATION_TIMELINE_COMPLETED,
                properties: {
                    crop: ENcropName,
                    field: fieldName ?? '',
                    Interculture_operations_nb: operationNbrForIntercrop,
                    Culture_operations_nb: operationNbrForCrop,
                },
            });

            setSuccess(true);
        } catch {
            enqueueSnackbar(t('constants.error'), { variant: 'error' });
        }
    };

    /* -------------------------------------------------------------------------- */

    const operationErrors: number[] = useMemo(() => [], []);

    const missingSubcategorySlug = useMemo(() => {
        const mandatoryOperationTypeConfigIds = staticOperationTypeConfigState.list
            .filter((otc) => !otc.is_deletable)
            .map((otc) => otc.id);
        const currentOperationIds = operationState.list.map((o) => o.operation_type_config_id);
        const missingMandatoryOperationConfigId = mandatoryOperationTypeConfigIds.find(
            (motcId) => !currentOperationIds.includes(motcId),
        );
        const missingMandatoryOperationConfig = staticOperationTypeConfigState.getById(
            missingMandatoryOperationConfigId,
        );

        const missingSubcategory = staticOperationTypeState.getById(missingMandatoryOperationConfig?.operation_type_id);

        return missingSubcategory
            ? t('encoding-technical-itinerary.timeline.missing-operation-error-message', {
                  missingSubcategory: t(missingSubcategory?.translation_slug),
              })
            : undefined;
    }, [operationState.list, staticOperationTypeConfigState, staticOperationTypeState, t]);

    const missingDatesSlug = useMemo(() => {
        // Error message if missing dates unless spraying all
        const hasMissingDates = operationState.list.find(
            (o) =>
                !o.operation_date &&
                staticOperationTypeState.getById(o.operation_type_id)?.slug !== OPERATION_TYPE_SLUGS.SPRAYING_ALL,
        );
        return hasMissingDates
            ? t('encoding-technical-itinerary.timeline.missing-operation-dates-error-message')
            : undefined;
    }, [operationState.list, staticOperationTypeState, t]);

    // Error message if cover crop destruction without cover crop seeding
    const missingCoverCropSeedingSlug = useMemo(() => {
        const coverCropDestructionOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION
            );
        });
        const coverCropSeedingOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_SEEDING
            );
        });

        return coverCropDestructionOperation && !coverCropSeedingOperation
            ? t('encoding-technical-itinerary.timeline.missing-cover-crop-seeding-error-message')
            : undefined;
    }, [operationState.list, staticOperationTypeState, t]);

    const coverCropDatesSlug = useMemo(() => {
        const coverCropDestructionOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION
            );
        });
        const coverCropSeedingOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_SEEDING
            );
        });

        if (coverCropDestructionOperation && coverCropSeedingOperation) {
            if (coverCropDestructionOperation.operation_date && coverCropSeedingOperation.operation_date) {
                if (
                    new Date(coverCropDestructionOperation.operation_date) <
                    new Date(coverCropSeedingOperation.operation_date)
                ) {
                    if (!operationErrors.includes(coverCropDestructionOperation.id))
                        operationErrors.push(coverCropDestructionOperation.id);
                    return t('encoding-technical-itinerary.timeline.cover-crop-dates-error-message');
                }
            }

            if (operationErrors.includes(coverCropDestructionOperation.id))
                operationErrors.splice(operationErrors.indexOf(coverCropDestructionOperation.id), 1);
        }

        return undefined;
    }, [operationState.list, staticOperationTypeState, t, operationErrors]);

    const harvestDatesSlug = useMemo(() => {
        const harvestCreationOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.HARVEST_CREATION
            );
        });
        const harvestDestructionOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.HARVEST_DESTRUCTION
            );
        });
        const seedingOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.SEEDING_SEEDING
            );
        });

        let errorMsg = undefined;

        if (seedingOperation && seedingOperation.operation_date) {
            if (harvestCreationOperation && harvestCreationOperation.operation_date) {
                if (new Date(harvestCreationOperation.operation_date) < new Date(seedingOperation.operation_date)) {
                    if (!operationErrors.includes(harvestCreationOperation.id))
                        operationErrors.push(harvestCreationOperation.id);
                    errorMsg = t('encoding-technical-itinerary.timeline.harvest-dates-error-message');
                } else {
                    if (operationErrors.includes(harvestCreationOperation.id))
                        operationErrors.splice(operationErrors.indexOf(harvestCreationOperation.id), 1);
                }
            }
            if (harvestDestructionOperation && harvestDestructionOperation.operation_date) {
                if (new Date(harvestDestructionOperation.operation_date) < new Date(seedingOperation.operation_date)) {
                    if (!operationErrors.includes(harvestDestructionOperation.id))
                        operationErrors.push(harvestDestructionOperation.id);
                    errorMsg = t('encoding-technical-itinerary.timeline.harvest-dates-error-message');
                } else {
                    if (operationErrors.includes(harvestDestructionOperation.id))
                        operationErrors.splice(operationErrors.indexOf(harvestDestructionOperation.id), 1);
                }
            }
        }

        return errorMsg;
    }, [operationState.list, staticOperationTypeState, t, operationErrors]);

    const errorMessage =
        missingSubcategorySlug ||
        missingDatesSlug ||
        missingCoverCropSeedingSlug ||
        coverCropDatesSlug ||
        harvestDatesSlug;

    const errorDisplayed = continueButtonClicked ? errorMessage : undefined;
    const handleContinueClickLoading = isFinalizeOperationsTimelineLoading || operationState.isFetching;
    const isContinueDisabled = !!errorDisplayed || handleContinueClickLoading;

    useEffect(() => {
        if (!errorMessage) setContinueButtonClicked(false);
    }, [errorMessage]);

    // Redirect after operations refetch
    useEffect(() => {
        if (success && !handleContinueClickLoading) navigateToNext();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [success, handleContinueClickLoading]);

    const cropName = t(
        staticCropState.getById(cropState.getById(fieldCropState.getById(fieldCropId)?.farm_season_crop_id)?.crop_id)
            ?.translation_slug ?? '',
    );
    const ENcropName =
        staticCropState.getById(cropState.getById(fieldCropState.getById(fieldCropId)?.farm_season_crop_id)?.crop_id)
            ?.name ?? '';
    const fieldName = fieldState.getById(fieldCropState.getById(fieldCropId)?.farm_season_field_id)?.name;
    const harvestYear = staticSeasonState.getById(currentSeason?.season_id)?.harvest_year;

    const operationNbrForCrop = operationListForCrop.items.reduce((total, item) => {
        return total + item.operations.length;
    }, 0);
    const operationNbrForIntercrop = operationListForIntercrop.items.reduce((total, item) => {
        return total + item.operations.length;
    }, 0);

    const { readOnly } = useFarmSeasonReadOnly();

    return {
        cropName,
        harvestYear,
        getHandleBackClick,
        getHandleContinueClick,
        handleContinueClickLoading,
        isLoading,
        fieldCropId: Number(fieldCropId),
        seasonId: currentSeasonId,
        errorDisplayed,
        operationErrors,
        isContinueDisabled,
        readOnly,
    };
};
