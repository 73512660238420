import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { DestructionForm } from '../../components/DestructionForm/DestructionForm';

export const DestructionActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    return (
        <encodingShared.OperationFormActionPage title={t('db.operation_type.harvest.destruction')} navigate={navigate}>
            <DestructionForm />
        </encodingShared.OperationFormActionPage>
    );
};
