import { useParams } from 'react-router-dom';

// get the current seasonId the simple way possible (through url params)
export default () => {
    const { seasonId } = useParams();

    return {
        currentSeasonId: Number(seasonId),
    };
};
