import { useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export const useDropZoneLogic = ({
    files,
    acceptedFilesTypes,
    onFilesChange,
}: {
    files: File[];
    acceptedFilesTypes?: Accept;
    onFilesChange: (newFiles: File[]) => void;
}) => {
    const { t } = useTranslation();
    const [uploadErrors, setUploadErrors] = useState<string[]>([]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: acceptedFilesTypes,
        onDrop: (newFiles: File[], fileRejections: FileRejection[]): void => {
            // merge with already uploaded files + remove duplicates
            onFilesChange(
                [...files, ...newFiles].filter(
                    (field, i, self) => i === self.findIndex((selfField) => selfField.name === field.name),
                ),
            );

            return setUploadErrors(
                fileRejections.map((fileError) => {
                    const fileName = fileError.file.name;
                    return t('encoding-rotation.capfile-import-modal.upload-error', { fileName });
                }),
            );
        },
    });

    const { onClick: onBrowseClick, ...dropzoneRootProps } = getRootProps();

    const handleDeleteFile = (file: File) => {
        const newFiles = files.filter((f) => f.name !== file.name);
        onFilesChange(newFiles);
    };

    return {
        uploadErrors,
        dropzoneRootProps,
        isDragActive,
        getInputProps,
        onBrowseClick,
        handleDeleteFile,
    };
};
