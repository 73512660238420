import fieldApi from '@shared/entities/field/field.api';
import { FieldT } from '@shared/entities';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useUpdateFarmSeasonField = ({ onFieldUpdated }: { onFieldUpdated?: (fieldId: number) => void } = {}) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [updateMutation, { isLoading }] = fieldApi.useUpdate();

    const updateField = async ({
        id,
        area,
        original_area,
        name,
        polygon_geometry,
    }: Omit<Partial<FieldT>, 'id'> & { id: number }) => {
        try {
            return await updateMutation({
                id,
                farmSeasonId: currentSeasonId,
                body: {
                    name,
                    area,
                    original_area,
                    polygon_geometry,
                },
            });

            onFieldUpdated?.(id);
        } catch (e) {
            console.error('Error updating field', e);
        }
    };

    return {
        updateField,
        isLoading,
    };
};
