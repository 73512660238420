export type RouteParamsT<P extends string> = { [k in P]: number | string | undefined };
export type RouteParamsToStringT<P extends void | { [k in string]: number | string | undefined }> = {
    [k in keyof P | 'seasonId']: string;
};

export const constructRouteWithParams = <P extends string>(
    path: P,
    params: Record<string, string | number | undefined>,
) => {
    return Object.entries(params).reduce((pathToReplace, [param, value]) => {
        if (!pathToReplace.includes(`:${param}`)) throw new Error(`path does not include provided param ${param}`);
        return pathToReplace.replace(`:${param}`, String(value));
    }, path as string) as P;
};
