import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { useCoverCropHerbicideQuantityQuestion } from './useCoverCropHerbicideQuantityQuestion';
import { Input } from '@soil-capital/ui-kit/components';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';

export const CoverCropHerbicideQuantityQuestion = ({ onChange }: { onChange: () => void }) => {
    const { t } = useTranslation();
    const methods = useFormContext();
    const { isVisible } = useCoverCropHerbicideQuantityQuestion();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.destruction-product-quantity')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.destruction-product-quantity"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.destruction-product-quantity"
                    />
                    <Input
                        type="number"
                        suffix={t('constants.unit.l-ha')}
                        onChange={onChange}
                        inputProps={{
                            ...methods.register(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_PRODUCT_QUANTITY, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        placeholder={t('encoding-cover-crop.question.placeholder.destruction_product_quantity')}
                        style={{ width: '250px' }}
                    />
                </FormRow>
            )}
        </>
    );
};
