import { makeStyles } from '@soil-capital/ui-kit/style';

export const useEncodingHeaderStyles = makeStyles()((theme) => ({
    rightElement: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(4),
    },
    backButton: {
        display: 'flex',
        gap: theme.spacing(1),
    },
    icon: {
        width: 20,
    },
}));
