import { useWatch, useFormContext } from 'react-hook-form';
import { useGetCoverCropFormStaticDataByTypeQuery } from '../../../api/coverCropFormApi';
import { COVER_CROP_STATIC_DATA_TYPE } from '../../../api/coverCropFormApi.types';
import { COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS } from '../coverCrop.types';
import { useEffect } from 'react';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';

export const useCoverCropDestructionMachineQuestion = () => {
    const { currentFarm } = encodingShared.useCurrentFarm();

    const destructionMethodId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID });

    const destructionMachineId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID });

    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });

    const { setValue } = useFormContext();

    const { data: destructionMethods } = useGetCoverCropFormStaticDataByTypeQuery({
        type: COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_DESTRUCTION,
    });

    const destructionMethod = destructionMethods?.find((item) => item.id === destructionMethodId);

    const isMechanicalWork =
        destructionMethod &&
        destructionMethod.slug === COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_MECHANICAL;

    const isPloughing =
        destructionMethod?.slug === COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_PLOUGHING;

    const isChemical =
        destructionMethod?.slug === COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_CHEMICAL;

    useEffect(() => {
        if (isChemical) {
            const sprayer = staticMachineryState.getBySlug(STATIC_MACHINERY_SLUGS.SPRAYER);

            if (!sprayer) throw new Error('No sprayer found');

            setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID, sprayer?.id);
        } else if (isPloughing) {
            const plough = staticMachineryState.getBySlug(STATIC_MACHINERY_SLUGS.PLOUGH);

            if (!plough) throw new Error('No plough found');

            setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID, plough?.id);
        } else if (isMechanicalWork) {
            if (destructionMachineId !== null) {
                const currentMachine = staticMachineryState.getById(destructionMachineId);
                if (currentMachine && currentMachine.slug === STATIC_MACHINERY_SLUGS.SPRAYER) {
                    setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID, null);
                }
            }
        } else if (
            destructionMethod?.slug === COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_GRAZING ||
            destructionMethod?.slug === COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_FROST
        ) {
            setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID, null);
        }
    }, [
        setValue,
        isMechanicalWork,
        isPloughing,
        isChemical,
        staticMachineryState,
        destructionMachineId,
        destructionMethod,
    ]);

    return {
        isVisible: isMechanicalWork,
    };
};
