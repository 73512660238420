import { makeStyles } from '@soil-capital/ui-kit/style';

export const useEditedFieldModalStyles = makeStyles()((theme) => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    error: {
        fontSize: theme.typography.subcaption.fontSize,
        marginTop: `-${theme.spacing(2)}`,
    },
    description: { fontSize: theme.typography.subcaption.fontSize, marginTop: `${theme.spacing(1)}` },
    label: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'start',
        gap: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        fontSize: theme.typography.caption.fontSize,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
    },
}));
