import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { useGetCurrentOperationFromUrl } from '../../../hooks/useGetCurrentOperationFromUrl';

export const useWeedingMachineFormOptions = () => {
    const { t } = useTranslation();
    const { operation } = useGetCurrentOperationFromUrl();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });

    const weedingMachineMachinery = operation
        ? staticMachineryState.getByOperationTypeId(operation?.operation_type_id)
        : [];
    const machineryOptions = weedingMachineMachinery
        .map((machinery) => ({
            value: machinery.id,
            label: t(`db.machineries.${machinery.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const isLoading = staticMachineryState.isLoading;

    return {
        machineries: machineryOptions || [],
        isLoading,
    };
};
