import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { useNavigate } from 'react-router-dom';

export const NonEligible = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <OnboardingStepsModal
            stepProgress={0}
            ctaAction={() => {
                navigate('/onboarding/eligibility');
            }}
            ctaText={t('constants.continue')}
            isCTAdisabled={true}
            onBackClick={() => navigate('/onboarding/eligibility')}
        >
            <Grid
                display="flex"
                flexDirection="column"
                gap={theme.spacing(3)}
                alignItems="center"
                textAlign="center"
                paddingTop="60px"
                whiteSpace="pre-line"
            >
                <img src={'/assets/images/sad-sunflower.svg'} width="110px" height="110px" />
                <Typography variant="h3">{t('pages.onboarding.eligibility.non-eligible.title')}</Typography>
                <Typography variant="body2" paddingBottom="40px">
                    {t('pages.onboarding.eligibility.non-eligible.subtitle')}
                </Typography>
            </Grid>
        </OnboardingStepsModal>
    );
};
