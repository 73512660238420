import { encodingShared } from '@modules/encoding/shared';
import { useSprayingFormActionPageLogic } from './useSprayingFormActionPage.logic';
import { NavigateFunction } from 'react-router-dom';
import { SprayingForm } from '../../components/SprayingForm/SprayingForm';
import { HelpBox } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useSprayingFormActionPageStyles } from './SprayingFormActionPage.style';

export const SprayingFormActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { title, fieldName, cropName, harvestYear, isLoading } = useSprayingFormActionPageLogic();
    const { classes } = useSprayingFormActionPageStyles();

    return (
        <encodingShared.OperationFormActionPage
            title={title}
            subtitle={t('encoding-technical-itinerary.all-date')}
            navigate={navigate}
        >
            <div className={classes.container}>
                {!isLoading && (
                    <HelpBox>
                        {t('encoding-technical-itinerary.spraying.help-box', { cropName, fieldName, harvestYear })}
                    </HelpBox>
                )}
                <SprayingForm />
            </div>
        </encodingShared.OperationFormActionPage>
    );
};
