import { Typography, useTheme } from '@soil-capital/ui-kit/material-core';

export const BulletedList = ({
    firstPoint,
    secondPoint,
    thirdPoint,
}: {
    firstPoint: string;
    secondPoint: string;
    thirdPoint: string;
}) => {
    const theme = useTheme();

    return (
        <ul>
            <li>
                <Typography variant="body2" color={theme.palette.darkScale[700]}>
                    {firstPoint}
                </Typography>
            </li>
            <li>
                <Typography variant="body2" color={theme.palette.darkScale[700]}>
                    {secondPoint}
                </Typography>
            </li>
            <li>
                <Typography variant="body2" color={theme.palette.darkScale[700]}>
                    {thirdPoint}
                </Typography>
            </li>
        </ul>
    );
};
