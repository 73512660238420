import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    container: {
        boxShadow: '0px 0px 32px 0px rgba(50, 63, 69, 0.08)',
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadiusM,
        padding: theme.spacing(3),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        marginTop: theme.spacing(8),
    },
    titleContainer: { display: 'flex', alignItems: 'center' },
    title: { paddingLeft: theme.spacing(1) },
    skeleton: {
        height: '30vh',
        width: '100%',
        borderRadius: theme.shape.borderRadiusM,
        marginTop: theme.spacing(2),
    },
}));
