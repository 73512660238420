import { FormRow } from '@shared/components';
import { Autocomplete, Input } from '@soil-capital/ui-kit/components';
import { IconCross, IconSearch } from '@soil-capital/ui-kit/icons';
import { Box, Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useOrganicFertiliserProductsInputStyles } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/components/OrganicFertiliserProductsInput/OrganicFertiliserProductsInput.style';
import { useOrganicFertiliserProductsInput } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/components/OrganicFertiliserProductsInput/useOrganicFertiliserProductsInput';
import { FERTILISER_UNIT, StaticFertiliserT } from '@shared/entities/staticFertiliser/staticFertiliser.types';
import { OrganicFertiliserInputNameT } from '@modules/encoding/modules/technicalItinerary/schema/organicFertilisationSchema';

type OrganicFertiliserProductsInputProps = {
    fertilisers: StaticFertiliserT[];
    name: OrganicFertiliserInputNameT;
    onChange: () => void;
    disabled?: boolean;
};

export const OrganicFertiliserProductsInput = ({
    fertilisers,
    name,
    onChange,
    disabled,
}: OrganicFertiliserProductsInputProps) => {
    const { t } = useTranslation();
    const { classes } = useOrganicFertiliserProductsInputStyles();
    const { selectedFertilisers, handleProductsChange, handleProductRemoved, register, getFertiliserById } =
        useOrganicFertiliserProductsInput({
            name,
            onChange,
        });

    return (
        <>
            <FormRow>
                <Typography>
                    {t('encoding-technical-itinerary.organic-fertiliser.question.which-products-did-you-apply')}
                </Typography>
                <Autocomplete
                    className={classes.select}
                    noOptionsText={t('constants.no-results')}
                    multiple
                    value={
                        fertilisers.length
                            ? selectedFertilisers.map((selectedFertiliser) => selectedFertiliser.static_fertiliser_id)
                            : []
                    }
                    onChange={(_, fertiliserIds) => handleProductsChange(fertiliserIds)}
                    options={fertilisers.map((fertiliser) => fertiliser.id)}
                    getOptionLabel={(fertiliserId) => t(getFertiliserById(fertiliserId)?.translation_slug ?? '')}
                    placeholder={t('constants.select')}
                    renderTags={(values) => (
                        <Box component="span" display="flex" alignItems="center" gap={1}>
                            {`${values.length} ${t('constants.selected')}`}
                        </Box>
                    )}
                    disabled={disabled}
                    startIcon={<IconSearch />}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </FormRow>
            <ul data-testid="organic-fertiliser-form-selected-products-list" className={classes.list}>
                {selectedFertilisers?.map((selectedFertilier, index: number) => (
                    <li key={selectedFertilier.static_fertiliser_id} className={classes.row}>
                        <Typography>
                            {t(getFertiliserById(selectedFertilier.static_fertiliser_id)?.translation_slug ?? '')}
                        </Typography>
                        <div className={classes.inputContainer}>
                            <Input
                                onChange={onChange}
                                type="number"
                                suffix={
                                    getFertiliserById(selectedFertilier.static_fertiliser_id)?.unit ===
                                    FERTILISER_UNIT.KG
                                        ? t('constants.unit.kg-ha')
                                        : t('constants.unit.l-ha')
                                }
                                defaultValue={selectedFertilier?.quantity || 0}
                                inputProps={{
                                    ...register(`${name}[${index}].quantity`, {
                                        setValueAs: (v: string | null) =>
                                            v === '' || v === null ? null : parseFloat(v),
                                    }),
                                }}
                                disabled={disabled}
                            />
                            <IconCross
                                data-testid={`organic-fertiliser-form-delete-product-${selectedFertilier.static_fertiliser_id}`}
                                className={classes.iconCross}
                                onClick={() =>
                                    disabled ? null : handleProductRemoved(selectedFertilier.static_fertiliser_id)
                                }
                            />
                        </div>
                    </li>
                ))}
            </ul>
        </>
    );
};
