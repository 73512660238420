/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntityAdapter, EntityState } from '@reduxjs/toolkit';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useMemo, useRef } from 'react';

export default <E>(
    entityCacheAdapter: EntityAdapter<E>,
    apiCb: () => UseQueryHookResult<QueryDefinition<any, any, any, EntityState<E>, any>>,
) => {
    const initialDataRef = useRef(entityCacheAdapter.getInitialState());
    const { data = initialDataRef.current, isLoading, isFetching, isError, refetch } = apiCb();
    /* ------------------------------ Selectors ----------------------------- */
    const { list, getById, getByIds } = useMemo(() => {
        const { selectAll, selectById } = entityCacheAdapter.getSelectors();
        return {
            list: selectAll(data),
            getById: (id: string | number | undefined | null) => (!id ? undefined : selectById(data, id)),
            getByIds: (ids: number[]) => ids.map((id) => selectById(data, id)).filter((item) => !!item) as E[], // TODO Think about error or refetch if state has missmatch ?
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const defaultState = useMemo(
        () => ({
            list,
            isLoading,
            isFetching,
            isError,
            getById,
            getByIds,
            /** refetch the initial api call.\
             * Should not be used, it's useful when we know the state change out of our control (ex: in a legacy flow with another state)
             * */
            refetch,
        }),
        [getById, getByIds, isError, isFetching, isLoading, list, refetch],
    );
    return defaultState;
};
