import { WarningBox } from '@shared/components/WarningBox/WarningBox';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { Modal } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Trans, useTranslation } from 'react-i18next';
import { useReplaceCropModalLogic } from './useReplaceCropModalLogic';

export type ReplaceCropModalOpenProps = { existingFieldCropId: number; newCropId: number };
export type ReplaceCropModalCloseProps = { action: 'add_crop' | 'replace_crop' };
export type ReplaceCropModalProps = {
    replaceCropModalController: ModalPropsT<ReplaceCropModalOpenProps, ReplaceCropModalCloseProps>;
};

export const ReplaceCropModal = ({ replaceCropModalController }: ReplaceCropModalProps) => {
    const { t } = useTranslation();
    const { fieldName, newCropName, previousCropName } = useReplaceCropModalLogic({
        controller: replaceCropModalController,
    });

    return (
        <Modal
            size={'small'}
            open={replaceCropModalController.isOpen}
            onClose={(reason) =>
                reason === 'cancelClick' && replaceCropModalController.onClose?.({ action: 'add_crop' })
            }
            onConfirm={() => replaceCropModalController.onClose?.({ action: 'replace_crop' })}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{t('encoding-rotation.modal-replace-crop.title')}</Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <WarningBox>
                        <Trans
                            t={t}
                            i18nKey={'encoding-rotation.modal-replace-crop.information'}
                            values={{
                                fieldName,
                                previousCropName,
                            }}
                        />
                    </WarningBox>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        <Trans
                            t={t}
                            i18nKey={'encoding-rotation.modal-replace-crop.description'}
                            values={{ newCropName }}
                        />
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel>{t('encoding-rotation.modal-replace-crop.add-crop-action')}</Modal.Cancel>
                    <Modal.Confirm>{t('encoding-rotation.modal-replace-crop.replace-crop-action')}</Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
