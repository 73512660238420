import { makeStyles } from '@soil-capital/ui-kit/style';

export const useHeaderStyles = makeStyles()((theme) => ({
    farmBox: {
        display: 'block',
        background: theme.palette.secondaryScale[100],
        padding: `${theme.spacing(1.2)} ${theme.spacing(5)}`,
        borderRadius: theme.shape.borderRadiusS,
        color: theme.palette.primaryScale[900],
        minWidth: 264,
        minHeight: 40,
        textAlign: 'center',
        '&:first-letter': {
            textTransform: 'uppercase',
        },
    },
    userInfos: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        color: theme.palette.darkScale[700],
        marginLeft: theme.spacing(1),
    },
    avatar: {
        width: 40,
        height: 40,
        border: 'none',
        boxShadow: 'none',
    },
}));
