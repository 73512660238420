import { ProgressSlugT } from '@shared/entities/progress/progress.types';
import entities from '@shared/entities';

export const useArePreviousStepsCompleted = ({ farmSeasonId }: { farmSeasonId: number }) => {
    const { progressState } = entities.progress.useState({ seasonId: farmSeasonId });
    const isLoading = progressState.isLoading;

    const getPreviousSteps = (step: ProgressSlugT) => {
        const progressStep = progressState?.getBySlug(step);
        if (!progressStep) return [];
        return progressState?.list?.filter((step) => step?.order < progressStep.order);
    };

    const arePreviousStepsCompleted = (step: ProgressSlugT) => {
        const previousSteps = getPreviousSteps(step);
        return previousSteps?.every((step) => step.progress_total === step.progress_done);
    };

    return { getPreviousSteps, arePreviousStepsCompleted, isLoading };
};
