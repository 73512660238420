import { useTranslation } from 'react-i18next';
import { Document, Page, pdfjs } from 'react-pdf';
import { IconClose } from '@soil-capital/ui-kit/icons';
import { useTOSModalStyle } from './TOSModal.style';
import { Paper } from '@soil-capital/ui-kit/material-core';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

type TOSModalViewPropsT = {
    className?: string;
    onClose: () => void;
};

export const TOSModal = ({ className, onClose }: TOSModalViewPropsT): JSX.Element => {
    const { classes } = useTOSModalStyle();
    const { i18n } = useTranslation();

    return (
        <Paper className={`${className} ${classes.paper}`} elevation={0}>
            <div className={classes.container}>
                {i18n.language !== 'fr' ? (
                    <Document className={classes.documentEN} file="/terms-and-conditions/tos_en.pdf">
                        <Page pageNumber={1} scale={1.3} />
                        <Page className={`${classes.pdfMargin}`} height={900} pageNumber={2} scale={1.3} width={600} />
                    </Document>
                ) : (
                    <Document className={classes.documentFR} file="/terms-and-conditions/tos_fr.pdf">
                        <Page pageNumber={1} scale={1.3} />
                        <Page className={classes.pdfMargin} pageNumber={2} scale={1.3} />
                        <Page className={`${classes.pdfMarginLastFR}`} pageNumber={3} scale={1.3} />
                    </Document>
                )}
            </div>
            <IconClose className={classes.close} onClick={onClose} />
        </Paper>
    );
};
