import { useDispatch } from 'react-redux';

export default <P, T extends string>(
        action: (payload: P) => {
            payload: P;
            type: T;
        },
    ) =>
    () => {
        const dispatch = useDispatch();
        return (p: P) => dispatch(action(p));
    };
