import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { useTranslation } from 'react-i18next';
import { Grid, useTheme, Typography } from '@soil-capital/ui-kit/material-core';

export const SeasonClosed = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const seasonDates = '2023-2024';
    const year = '2024';
    const nextSeasonDates = '2024-2025';

    return (
        <OnboardingStepsModal stepProgress={5}>
            <Grid
                display="flex"
                flexDirection="column"
                gap={theme.spacing(1.5)}
                paddingBottom={theme.spacing(18)}
                paddingTop={theme.spacing(9)}
                textAlign="center"
            >
                <img src="/assets/images/field-circle.svg" height="180px" />
                <Typography variant="h3" paddingTop={theme.spacing(1.5)} paddingX={theme.spacing(8)}>
                    {t('pages.onboarding.season-closed.title', { seasonDates })}
                </Typography>
                <Typography variant="body2">
                    {t('pages.onboarding.season-closed.subtitle', { year, nextSeasonDates })}
                </Typography>
            </Grid>
        </OnboardingStepsModal>
    );
};
