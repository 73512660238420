import { makeStyles } from '@soil-capital/ui-kit/style';
import { FunctionComponent, SVGAttributes } from 'react';

const useContextualPanelStyle = makeStyles()((theme) => ({
    ContextualPanel: {
        display: 'flex',
        height: '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.primaryScale[900],
    },

    contextualSection: {
        backgroundColor: theme.palette.common.white,
        marginRight: theme.spacing(1),
        borderRadius: theme.shape.borderRadiusM,
        width: '340px',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
    },

    childrenContainer: {
        height: '100%',
    },

    scrollableSection: {
        width: '100%',
        height: '100%',

        padding: `0 ${theme.spacing(2)}`,

        // if webkit (because scrollbar style is supported only by webkit)
        '@media screen and (-webkit-min-device-pixel-ratio:0)': {
            width: 'calc(100% + 6px)',
            paddingRight: 0,
        },
        ...theme.styles.scrollSection,
        overflowY: 'scroll',
    },

    actionSection: {
        flex: '1',
        position: 'relative',
    },

    contextualSectionSkeleton: {
        position: 'absolute',
        zIndex: 900,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        transition: `opacity ${theme.transitions.easing.easeIn} ${theme.transitions.duration.leavingScreen}ms, visibility ${theme.transitions.duration.leavingScreen}ms ${theme.transitions.duration.leavingScreen}ms`,
        opacity: 0,
        visibility: 'hidden',
    },
    contextualSectionSkeletonLoading: {
        opacity: '1 !important',
        visibility: 'visible !important' as 'visible',
    },
}));

export const useContextualPanelImageStyle = makeStyles<{
    background: string | FunctionComponent<SVGAttributes<SVGElement>>;
}>()((theme, { background }) => ({
    imageContainer: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: '100%',
    },
    background: {
        backgroundImage: `url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        minHeight: '100%',
        borderRadius: theme.shape.borderRadius,
        overflow: 'hidden',
    },
}));

export default useContextualPanelStyle;
