import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFieldCreationModalStyles = makeStyles()((theme) => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    error: {
        fontSize: theme.typography.subcaption.fontSize,
        marginTop: `-${theme.spacing(2)}`,
    },
}));
