import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { MapLayerCallbackFeatureDataT } from '@shared/map2';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useFieldCropAssignationMap = ({
    readOnly,
    selectedCropId,
    handleFieldSelection,
}: {
    readOnly: boolean;
    selectedCropId: number | null;
    handleFieldSelection: (fieldId: number) => void;
}) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const [featureIdHovered, setFeatureIdHovered] = useState<number | null>(null);
    const onFeatureHover = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>) => {
            if (!selectedCropId || readOnly) {
                return false;
            }
            setFeatureIdHovered(data ? data.featureId : null);
        },
        [selectedCropId, readOnly],
    );

    const onFeatureClick = useCallback(
        (data: MapLayerCallbackFeatureDataT<{ color: string; label: string }>, layerId: string) => {
            if (!data) {
                return;
            }

            // make sure we don't trigger the toggle twice (exemple on clicking on symbol layer on top of the fill layer)
            if (layerId !== 'feature-fill') {
                return;
            }

            handleFieldSelection(data.featureId);
        },
        [handleFieldSelection],
    );

    const [focusedFieldIds, setFocusedFieldIds] = useState<number[]>(fieldState.list.map((field) => field.id));

    const mapBannerHelpText = readOnly
        ? undefined
        : !selectedCropId
        ? t('encoding-rotation.modal.right-panel.field-selection-disabled-as-no-crop-selected')
        : t('selection-on-map-available');

    return {
        featureIdHovered,
        onFeatureClick,
        setFocusedFieldIds,
        focusedFieldIds,
        mapBannerHelpText,
        onFeatureHover,
        setFeatureIdHovered,
    };
};
