import { useToggle } from '@shared/hooks/useToggle';

const useBillingInfoCardModal = () => {
    const {
        open: billingInfoModalOpen,
        handleOpen: onBillingInfoModalOpen,
        handleClose: onBillingInfoModalClose,
    } = useToggle();

    return {
        billingInfoModalOpen,
        onBillingInfoModalOpen,
        onBillingInfoModalClose,
    };
};

export default useBillingInfoCardModal;
