import { makeStyles } from '@soil-capital/ui-kit/style';

export const useEncodingDoneContentStyle = makeStyles()((theme) => ({
    encodingDoneProgressItem: {
        '&>div>svg': {
            color: theme.palette.success.main,
        },
    },
    icon: {
        color: theme.palette.success.main,
    },
}));
