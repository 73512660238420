import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSelectedCropStyles = makeStyles()((theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    cropInfoBox: {
        border: `2px solid ${theme.palette.primaryScale[300]}`,
        backgroundColor: theme.palette.success.light,
        padding: theme.spacing(1),
        marginRight: theme.spacing(1),
        maxWidth: '90%',
        minWidth: 0,
        flex: 1,
    },
    loaderBox: {
        border: `2px solid ${theme.palette.primaryScale[300]}`,
        padding: theme.spacing(1),
        width: '100%',
        height: 68,
        maxWidth: '90%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadiusS,
    },
    button: {
        minWidth: 32,
        width: 32,
        height: 32,
        padding: theme.spacing(1),
    },
    icon: {
        width: 16,
        color: theme.palette.darkScale[900],
        fontWeight: 'bold',
    },
}));
