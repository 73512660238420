import { useContext, useState } from 'react';
import MapboxDraw, { MapMouseEvent } from '@mapbox/mapbox-gl-draw';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { MapContext } from '../utils/MapProvider';
import { FieldT } from '@shared/entities';
import { PolygonGeometryT } from '@shared/entities/field/field.types';
import { useEffect, useCallback, useMemo } from 'react';
import { calculateArea, isDrawingAllowed } from '../utils/polygonHelper';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useEdit = () => {
    const mapContext = useContext(MapContext);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { map, fields, setFields, setEditMode, editMode, drawMode } = mapContext ?? {};
    const [drawControl, setDrawControl] = useState<null | MapboxDraw>(null);
    const [editedFeatureId, setEditedFeatureId] = useState<null | string>(null);
    const [editedField, setEditedField] = useState<FieldT | null>(null);
    const [updatedField, setUpdatedField] = useState<FieldT | null>(null);
    const drawOptions = useMemo(
        () =>
            new MapboxDraw({
                displayControlsDefault: false,
            }),
        [],
    );

    const handleDrawUpdate = useCallback(
        (e: MapMouseEvent) => {
            if (fields) {
                const point = [e.lngLat.lng, e.lngLat.lat];
                let intersectedField: FieldT | null = null;

                for (const field of fields) {
                    const polygon = field.polygon.geometry;
                    const isInside = booleanPointInPolygon(point, polygon);

                    if (isInside) {
                        intersectedField = field;
                        break;
                    }
                }

                if (intersectedField && !editedField) {
                    setEditedField?.(intersectedField);
                    setFields?.(fields.filter((field) => field !== intersectedField));

                    const featureId = drawOptions.add(intersectedField.polygon);
                    setEditedFeatureId(featureId[0]);
                    drawOptions.changeMode('direct_select', { featureId: featureId[0] });
                }
            }
        },
        [fields, editedField, drawOptions, setEditedField, setFields],
    );

    const edit = () => {
        if (map) {
            setEditMode?.(true);
            setUpdatedField(null);
            if (drawControl) {
                map.removeControl(drawControl);
            }
            map.addControl(drawOptions);
            setDrawControl(drawOptions);
        }
    };

    const stopEditing = () => {
        if (editedField && fields) setFields?.([editedField, ...fields]);
        if (drawControl) map?.removeControl(drawControl);
        setDrawControl(null);
        setEditMode?.(false);
        setEditedField(null);
    };

    const saveUpdatedField = (id: string) => {
        const updatedFeature = drawOptions.get(id);
        if (!updatedFeature || !editedField) return;
        const updatedPolygon = updatedFeature.geometry as PolygonGeometryT;
        const unEditedFieldsGeometry = fields?.map((field) => field.polygon.geometry) || [];

        if (isDrawingAllowed(updatedPolygon, unEditedFieldsGeometry)) {
            const newArea = calculateArea(updatedPolygon);
            setUpdatedField({
                ...editedField,
                original_area: Number(newArea.toFixed(3)),
                area: Number(newArea.toFixed(3)),
                polygon: { ...editedField?.polygon, geometry: updatedPolygon },
            });
        } else {
            enqueueSnackbar(t('encoding-rotation.pac-file.edit-fields-toast.error'), {
                variant: 'error',
            });
        }
        stopEditing();
    };

    useEffect(() => {
        if (editMode) {
            map?.on('click', handleDrawUpdate);
        }
        return () => {
            map?.off('click', handleDrawUpdate);
        };
    }, [map, handleDrawUpdate, editMode]);

    return { edit, stopEditing, editMode, drawMode, saveUpdatedField, editedFeatureId, updatedField };
};
