import { LoaderFullScreen } from '@shared/components';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export default ({ children }: { children: ReactNode }) => {
    const { i18n } = useTranslation();
    const isAppReady = i18n.isInitialized;

    return (
        <LoaderFullScreen isLoading={!isAppReady} displayLoader={false} displayChildrenWhileLoading={false}>
            {children}
        </LoaderFullScreen>
    );
};
