import {
    OPERATION_TYPE_SLUGS,
    OperationTypeSlugT,
} from '@shared/entities/staticOperationType/staticOperationType.types';
import { injectOperationFormEndpoints } from './operationForm.api';

export type OperationFormTypeT =
    | 'cover-crop'
    | 'mineral-fertilisation'
    | 'organic-fertilisation'
    | 'harvest'
    | 'destruction'
    | 'seeding'
    | 'deep-work'
    | 'ploughing'
    | 'shallow-work'
    | 'weeding-machine'
    | 'spraying';

export const mapOperationTypeSlugToOperationFormType: Partial<Record<OperationTypeSlugT, OperationFormTypeT>> = {
    [OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_SEEDING]: 'cover-crop',
    [OPERATION_TYPE_SLUGS.FERTILISATION_MINERAL]: 'mineral-fertilisation',
    [OPERATION_TYPE_SLUGS.FERTILISATION_ORGANIC]: 'organic-fertilisation',
    [OPERATION_TYPE_SLUGS.HARVEST_CREATION]: 'harvest',
    [OPERATION_TYPE_SLUGS.HARVEST_DESTRUCTION]: 'destruction',
    [OPERATION_TYPE_SLUGS.SEEDING_SEEDING]: 'seeding',
    [OPERATION_TYPE_SLUGS.SEEDING_COMPANION_SEEDING]: 'seeding',
    [OPERATION_TYPE_SLUGS.SOIL_WORK_DEEP_WORK_MACHINE]: 'deep-work',
    [OPERATION_TYPE_SLUGS.SOIL_WORK_PLOUGH]: 'ploughing',
    [OPERATION_TYPE_SLUGS.SOIL_WORK_SHALLOW_WORK_MACHINE]: 'shallow-work',
    [OPERATION_TYPE_SLUGS.SOIL_WORK_WEEDING_MACHINE]: 'weeding-machine',
    [OPERATION_TYPE_SLUGS.SPRAYING_ALL]: 'spraying',
};

export const operationFormApiMap: { [K in OperationFormTypeT]: ReturnType<typeof injectOperationFormEndpoints<K>> } = {
    // [OPERATION_TYPE_SLUGS.SOIL_WORK_ROLLER]: , // no form and no api
    // [OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION]: , // no form and no api
    'cover-crop': injectOperationFormEndpoints('cover-crop'),
    'mineral-fertilisation': injectOperationFormEndpoints('mineral-fertilisation'),
    'organic-fertilisation': injectOperationFormEndpoints('organic-fertilisation'),
    harvest: injectOperationFormEndpoints('harvest'),
    destruction: injectOperationFormEndpoints('destruction'),
    seeding: injectOperationFormEndpoints('seeding'),
    'deep-work': injectOperationFormEndpoints('deep-work'),
    ploughing: injectOperationFormEndpoints('ploughing'),
    'shallow-work': injectOperationFormEndpoints('shallow-work'),
    'weeding-machine': injectOperationFormEndpoints('weeding-machine'),
    spraying: injectOperationFormEndpoints('spraying'),
} as const;

export type OperationFormApiMapT = typeof operationFormApiMap;
export type OperationFormApiMapKeysT = keyof OperationFormApiMapT;

export const getOperationFormApi = <OperationTypeT extends OperationFormTypeT>(operationTypeSlug: OperationTypeT) => ({
    useGet: operationFormApiMap[operationTypeSlug].useGetQuery,

    /** Thunk action creator to be dispatched. This will add a subscription to RTK and trigger a fetch to the operation query endpoint mapped */
    initiateGet: operationFormApiMap[operationTypeSlug].initiateGet,

    useUpdate: operationFormApiMap[operationTypeSlug].useUpdateMutation,
});
