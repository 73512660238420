import OperationDatePicker from '@modules/encoding/modules/technicalItinerary/components/OperationDatePicker/OperationDatePicker';
import { OperationDatePickerPropsT } from '@modules/encoding/modules/technicalItinerary/components/OperationDatePicker/OperationDatePicker.types';
import { Controller, useFormContext } from 'react-hook-form';

type FormDatePickerPropsT = {
    name: string;
    onChange?: () => void;
    disabled?: boolean;
} & Omit<OperationDatePickerPropsT, 'value'>;

export const FormDatePicker = ({ name, max, onChange, disabled }: FormDatePickerPropsT) => {
    const context = useFormContext();

    if (!context) throw new Error('FormDatePicker should be used inside a FormProvider');

    return (
        <Controller
            control={context.control}
            name={name}
            render={({ field: { value, onChange: onFieldChange } }) => (
                <OperationDatePicker
                    value={value !== null ? new Date(value) : null}
                    max={max}
                    onChange={(event) => {
                        onFieldChange(event);
                        onChange?.();
                    }}
                    disabled={disabled}
                />
            )}
        />
    );
};
