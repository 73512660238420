import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetFarmSeasonOperations } from '@shared/hooks/useGetFarmSeasonOperations';
import { useGetHistoricalRotationSourceQuery } from '@modules/encoding/modules/history/api/historyApi';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { HISTORY_ROTATION_SOURCE_ENUM } from '@modules/encoding/modules/history/api/historyApi.types';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const usePacImportContextualPanelData = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { harvestYear, isLoading: isLoadingHarvest } = useGetCurrentHarvestYear();
    const { fieldState } = useGetFarmSeasonFields();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { operationState } = useGetFarmSeasonOperations();
    const { isHistoryFarmSeason, baselineHarvestYear, isLoading: isLoadingBaseline } = useBaselineFarmSeason();
    const {
        data: rotationSource,
        isLoading: isLoadingRotationSource,
        isFetching: isFetchingRotationSource,
    } = useGetHistoricalRotationSourceQuery({
        farmSeasonId: currentSeasonId,
    });

    const isLoading =
        isLoadingHarvest ||
        fieldState.isLoading ||
        fieldCropState.isLoading ||
        operationState.isLoading ||
        isLoadingBaseline ||
        isLoadingRotationSource;

    const isRotationSourceManual = rotationSource?.source === HISTORY_ROTATION_SOURCE_ENUM.MANUAL;
    const showCopiedFromBaselineInfobox = !fieldState?.isFetching && isRotationSourceManual && isHistoryFarmSeason;
    const showCapFileAccessInfobox =
        !fieldState?.isFetching && !isFetchingRotationSource && fieldState?.list?.length === 0 && isHistoryFarmSeason;

    return {
        harvestYear,
        baselineHarvestYear,
        showCopiedFromBaselineInfobox,
        showCapFileAccessInfobox,
        isLoading,
    };
};
