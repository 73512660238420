import { Box, Typography, CircularProgress } from '@soil-capital/ui-kit/material-core';
import useGlobalSaveStatusStyle from './useGlobalSaveStatus.style';
import { IconCheckCircleBroken, IconXCircle } from '@soil-capital/ui-kit/icons';
import useGlobalSaveStatusLogic from './useGlobalSaveStatus.logic';
import { ReactElement } from 'react';
import useGlobalSaveStatusData from './useGlobalSaveStatus.data';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation();
    const { classes } = useGlobalSaveStatusStyle();
    const dataContext = useGlobalSaveStatusData();
    const { globalMutationStatus } = useGlobalSaveStatusLogic({ dataContext });

    let saveStatusItem: ReactElement | null = null;

    if (globalMutationStatus === 'pending') {
        saveStatusItem = (
            <div className={classes.saveStatusItem}>
                <CircularProgress className={classes.loading} color="inherit" />
                <Typography color="neutral">{t('encoding-layout.header.global-save-pending')}</Typography>
            </div>
        );
    }

    if (globalMutationStatus === 'success') {
        saveStatusItem = (
            <div className={classes.saveStatusItem}>
                <IconCheckCircleBroken fontSize="small" color="success" />
                <Typography color="success.main">{t('encoding-layout.header.global-save-success')}</Typography>
            </div>
        );
    }

    if (globalMutationStatus === 'failed') {
        saveStatusItem = (
            <div className={classes.saveStatusItem}>
                <IconXCircle fontSize="small" color="inherit" className={classes.errorIcon} />
                <Typography color="neutral">{t('encoding-layout.header.global-save-failed')}</Typography>
            </div>
        );
    }

    if (globalMutationStatus === 'none') {
        saveStatusItem = <div className={classes.saveStatusItem}></div>;
    }

    return <Box className={classes.GlobalSaveStatus}>{saveStatusItem}</Box>;
};
