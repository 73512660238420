import { z } from 'zod';
import { OperationFormSchema } from './OperationFormSchema';
import { DestructionFormDataT } from '../api/operationFormApi.types';

export const destructionFormSchema = new OperationFormSchema(
    {
        destruction_method_static_data_id: z.number().nullable(),
        destruction_pesticide_id: z.number().nullable(),
        destruction_pesticide_quantity: z.number().nullable(),
        machinery_id: z.number().nullable(),
        depth: z.number().nullable(),
        has_companion_crop_stayed_on_field: z.boolean().nullable(),
        sprayer_width: z.number().nullable(),
    },
    (apiData?: DestructionFormDataT) => ({
        depth: apiData?.depth ?? null,
        destruction_method_static_data_id: apiData?.destruction_method_static_data_id ?? null,
        destruction_pesticide_id: apiData?.destruction_pesticide_id ?? null,
        destruction_pesticide_quantity: apiData?.destruction_pesticide_quantity ?? null,
        machinery_id: apiData?.machinery_id ?? null,
        has_companion_crop_stayed_on_field:
            apiData?.has_companion_crop_stayed_on_field !== true &&
            apiData?.has_companion_crop_stayed_on_field !== false
                ? null
                : apiData?.has_companion_crop_stayed_on_field,
        sprayer_width: apiData?.sprayer_width ?? null,
    }),
    ['sprayer_width'],
);

export type DestructionFormSchemaT = z.infer<typeof destructionFormSchema.schema>;

export const DESTRUCTION_FORM_INPUT_NAMES = destructionFormSchema.INPUT_NAMES;
