import { ChangeEvent } from 'react';
import type { FieldT } from '@shared/entities';
import {
    changeActiveFilter,
    changeSearch,
    FilterType,
    resetFilters,
    resetSearch,
    ROTATION_FIELDS_FILTERS,
} from '@modules/encoding/modules/rotation/store/filter.slice';
import { useDispatch, useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { useFormContext, useWatch } from 'react-hook-form';
import { ROTATION_ASSIGNATION_INPUT_NAMES } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/hooks/useRotationAssignationForm';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';

export const useRotationFieldFilters = () => {
    const search = useSelector((state: SharedStateT) => state.rotationFilters.search);
    const selectedFarmSeasonFieldId = useSelector((state: SharedStateT) => state.rotationFilters.farmSeasonFieldId);
    const selectedFilter = useSelector((state: SharedStateT) => state.rotationFilters.selectedFilter);

    const dispatch = useDispatch();
    const context = useFormContext();
    const selectedFieldIds = useWatch({ name: ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_FIELD_IDS });
    const [track] = useTrackMutation();

    const { cropState } = useGetFarmSeasonCrops();
    const { getCropsAssignedToAField } = cropState.useGetCropsAssignedToAField();
    const { fieldState } = useGetFarmSeasonFields();
    const farmSeasonFields = fieldState.list;
    const fieldMatchingFilter = farmSeasonFields.find((fsf) => fsf.id === selectedFarmSeasonFieldId);

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => dispatch(changeSearch(e.target.value));
    const handleResetSearch = () => dispatch(resetSearch());

    const handleFieldIdFilterReset = () => {
        dispatch(resetFilters());
        context.reset({ [ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_FIELD_IDS]: [] });
        context.reset({ [ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID]: [] });
    };

    const doesFieldMatchSearch = (field: FieldT) => field.name.toLowerCase().includes(search.toLowerCase()) || !search;
    const doesFieldHaveCrop = (field: FieldT) => getCropsAssignedToAField(field.id).length > 0;
    const doesFieldHaveNoCrop = (field: FieldT) => getCropsAssignedToAField(field.id).length < 1;
    const isFieldSelected = (field: FieldT) => !!selectedFieldIds.includes(field.id);

    const doesFieldMatchFilters = (field: FieldT) => {
        if (!doesFieldMatchSearch(field)) return false;
        if (selectedFarmSeasonFieldId && field.id !== selectedFarmSeasonFieldId) return false;

        switch (selectedFilter) {
            case ROTATION_FIELDS_FILTERS.FIELDS_WITH_CROP:
                return doesFieldHaveCrop(field);
            case ROTATION_FIELDS_FILTERS.FIELDS_WITHOUT_CROP:
                return doesFieldHaveNoCrop(field);
            case ROTATION_FIELDS_FILTERS.FIELDS_SELECTED:
                return isFieldSelected(field);
            default:
                return true;
        }
    };

    const handleFilterChanged = (value: FilterType) => {
        dispatch(changeActiveFilter(value));
        track({
            event: EVENTS.FIELDS_FILTER_CLICKED,
            properties: { Filter_type: value },
        });
    };
    const handleFilterReset = () => dispatch(resetFilters());

    return {
        search,
        fieldMatchingFilter,
        selectedFilter,
        handleSearchChange,
        handleResetSearch,
        doesFieldMatchFilters,
        handleFilterChanged,
        handleFilterReset,
        handleFieldIdFilterReset,
    };
};
