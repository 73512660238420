import { makeStyles } from '@soil-capital/ui-kit/style';

export const useActionPanelStyles = makeStyles()((theme) => ({
    actionPanel: {
        backgroundColor: theme.palette.primaryScale[800],
        padding: theme.spacing(4),
        borderRadius: theme.shape.borderRadiusM,
        height: '100%',
        width: '100%',
        position: 'absolute',
        overflowY: 'scroll',
        ...theme.styles.scrollSection,
    },
}));
