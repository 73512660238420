import { ChangeEvent, useState } from 'react';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';

export const useDuplicationFieldFilters = () => {
    const [toggleSelectedFields, setToggleSelectedFields] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const handleToggleSelectedFields = () => setToggleSelectedFields(!toggleSelectedFields);
    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => setSearch(e.target.value);
    const handleResetSearch = () => setSearch('');

    const doesFieldMatchFilters = (field: DuplicationFieldT, isSelected: boolean) =>
        (toggleSelectedFields && !isSelected) || (search && !field.name.toLowerCase().includes(search.toLowerCase()));

    return {
        toggleSelectedFields,
        handleToggleSelectedFields,
        search,
        handleSearchChange,
        handleResetSearch,
        doesFieldMatchFilters,
    };
};
