import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import OperationPloughingForm from '@modules/encoding/modules/technicalItinerary/components/OperationPloughingForm/OperationPloughingForm';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    return (
        <encodingShared.OperationFormActionPage
            title={t('encoding-technical-itinerary.ploughing.title')}
            navigate={navigate}
        >
            <OperationPloughingForm />
        </encodingShared.OperationFormActionPage>
    );
};
