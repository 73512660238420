import { makeStyles } from '@soil-capital/ui-kit/style';

export const useEncodingDoneModalStyles = makeStyles()((theme) => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    img: {
        width: 140,
        height: 140,
        marginBottom: theme.spacing(5),
    },
    title: {
        maxWidth: 300,
        textAlign: 'center',
        marginBottom: theme.spacing(1),
    },
    text: {
        textAlign: 'center',
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
    },
    cancel: {
        width: '40%',
    },
    confirm: {},
}));
