import { auth } from '@modules/auth';
import {
    useGetPartnerInvitationQuery,
    usePutDeclinePartnerInvitationMutation,
    usePutAcceptPartnerInvitationMutation,
} from '@modules/onboarding/api/partnerInvitationApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useInvitationLogic = () => {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const { data: userInvitation, isLoading: isUserInvitationLoading } = useGetPartnerInvitationQuery({
        email: auth.useAuthUser()?.email,
    });
    const partnerName = userInvitation?.partner?.name;

    const [acceptInvitation, { isLoading: isAcceptInvitationLoading }] = usePutAcceptPartnerInvitationMutation();
    const [declineInvitation, { isLoading: isDeclineInvitationLoading }] = usePutDeclinePartnerInvitationMutation();

    const handleAcceptInvitation = async () => {
        if (userInvitation?.id) {
            const response = await acceptInvitation({ invitationId: userInvitation.id });
            if ('data' in response) {
                navigate('/onboarding/eligibility');
                enqueueSnackbar(t('pages.onboarding.invitation.accept.success', { partnerName }), {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar(t('pages.onboarding.invitation.accept.error'), {
                    variant: 'error',
                });
            }
        }
    };

    const handleDeclineInvitation = async () => {
        if (userInvitation?.id) {
            const response = await declineInvitation({ invitationId: userInvitation.id });
            if ('data' in response) {
                navigate('/onboarding/eligibility');
                enqueueSnackbar(t('pages.onboarding.invitation.decline.success', { partnerName }), {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar(t('pages.onboarding.invitation.decline.error'), {
                    variant: 'error',
                });
            }
        }
    };

    return {
        isLoading: isUserInvitationLoading,
        partnerName,
        handleAcceptInvitation,
        handleDeclineInvitation,
        isAcceptInvitationLoading,
        isDeclineInvitationLoading,
    };
};
