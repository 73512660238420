import { BaseEntityT } from '../baseEntity.types';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';

export type ProgressT = {
    slug: ProgressSlugT;
    order: number;
    progress_total: number;
    progress_done: number;
    progress_percentage: number;
    is_visible: boolean;
    status: ProgressStatusT;
    progress_details: ProgressDetailT[];
} & BaseEntityT;

export type ProgressDetailT = {
    id: number;
    is_done: boolean;
    farm_season_progress_id: number | null;
    farm_season_crop_id: number | null;
    farm_season_field_id: number | null;
    farm_season_field_crop_id: number | null;
    farm_season_field_crop_operation_id: number | null;
    category: ProgressDetailCategoryT;
};

export const PROGRESS_DETAIL_CATEGORIES = {
    OPERATION_FORM: 'operation-form',
    GLOBAL_CHARACTERISTICS: 'global-characteristics',
    OPERATIONS_TIMELINE: 'operations-timeline',
    ROTATION: 'rotation',
} as const;
export type ProgressDetailCategoryT = (typeof PROGRESS_DETAIL_CATEGORIES)[keyof typeof PROGRESS_DETAIL_CATEGORIES];

export type ProgressEndpointParamsT = {
    seasonId: number;
};

export type ComputeProgressDetailRespT = {
    done: number;
    total: number;
};

export const PROGRESS_SLUGS = {
    PREPARATION: 'preparation',
    FARM_PROFILE: 'farm-profile',
    ROTATION: 'rotation',
    TECHNICAL_ITINERARY: 'technical-itinerary',
    DRAINAGE: 'drainage',
    IRRIGATION: 'irrigation',
    AGROFORESTRY: 'agroforestry',
    LIVESTOCK: 'livestock',
    SOIL_ANALYSIS: 'soil-analysis',
    HISTORY: 'history',
} as const;

export type ProgressSlugT = (typeof encodingRoutesConfig)[keyof typeof encodingRoutesConfig];
export type ProgressStatusT = 'done' | 'ongoing' | 'not-started' | 'disabled';
