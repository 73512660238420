import { Input, Modal, ConditionalTooltip } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useEditedFieldModalStyles } from './useEditedFieldModal.style';
import { EDITED_FIELD, useEditedFieldModalFormLogic } from './useEditedFieldModalFormLogic';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { FieldT } from '@shared/entities/field/field.types';

export type EditedFieldModalPropsT = {
    updatedField: FieldT;
    open: boolean;
    onClose: () => void;
};

export const EditedFieldModal = ({ updatedField, open, onClose }: EditedFieldModalPropsT) => {
    const { classes } = useEditedFieldModalStyles();
    const { t } = useTranslation();
    const { methods, onConfirm, isEditingField, errors, treshold, inputRef } = useEditedFieldModalFormLogic({
        updatedField,
        onClose,
        open,
    });

    return (
        <FormProvider {...methods}>
            <form>
                <Modal
                    size={'small'}
                    open={open}
                    onClose={onClose}
                    onConfirm={onConfirm}
                    header={
                        <Modal.Header>
                            <Modal.HeaderTitle>
                                {t('encoding-rotation.pac-file.update-field-modal.title')}
                            </Modal.HeaderTitle>
                            <Modal.Close />
                        </Modal.Header>
                    }
                    body={
                        <Modal.Body className={classes.body}>
                            <Input
                                label={t('constants.name')}
                                data-testid="field_name"
                                type="text"
                                inputProps={{
                                    ...methods.register(EDITED_FIELD.NAME),
                                }}
                                defaultValue={updatedField.name}
                                placeholder={updatedField.name}
                                inputRef={inputRef}
                            />
                            {errors.edited_field_name && (
                                <Typography color="error" className={classes.error}>
                                    {errors.edited_field_name.message}
                                </Typography>
                            )}
                            <div className={classes.container}>
                                <div className={classes.label}>
                                    <Typography variant="body" color="darkScale.900">
                                        {t('constants.area').toUpperCase()}
                                    </Typography>
                                    <ConditionalTooltip
                                        title={t('encoding.rotation.map.draw.area-tooltip', { treshold })}
                                        scheme="dark"
                                        display="flex"
                                    />
                                </div>
                                <Input
                                    type="number"
                                    suffix={t('constants.hectares')}
                                    placeholder={updatedField.original_area.toFixed(2)}
                                    defaultValue={updatedField.original_area.toFixed(2)}
                                    inputProps={{
                                        ...methods.register(EDITED_FIELD.AREA, {
                                            setValueAs: (v) => Number(v),
                                        }),
                                    }}
                                />

                                {errors.edited_area ? (
                                    <Typography color="error" className={classes.description}>
                                        {errors.edited_area.message}
                                    </Typography>
                                ) : (
                                    <Typography color="GrayText" className={classes.description}>
                                        {t('encoding-rotation.map.draw.area-description', { treshold })}
                                    </Typography>
                                )}
                            </div>
                        </Modal.Body>
                    }
                    footer={
                        <Modal.Footer>
                            <Modal.Confirm loading={isEditingField}>{t('constants.save')}</Modal.Confirm>
                            <Modal.Cancel onClick={onClose}>{t('constants.cancel')}</Modal.Cancel>
                        </Modal.Footer>
                    }
                />
            </form>
        </FormProvider>
    );
};
