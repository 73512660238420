import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useInvitationLogic } from './useInvitationLogic';
import { InvitationSkeleton } from '@modules/onboarding/components/InvitationSkeleton/InvitationSkeleton';
import { Button } from '@soil-capital/ui-kit/components';

export const Invitation = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        isLoading,
        partnerName,
        handleAcceptInvitation,
        handleDeclineInvitation,
        isAcceptInvitationLoading,
        isDeclineInvitationLoading,
    } = useInvitationLogic();

    return (
        <OnboardingStepsModal stepProgress={5} hideBackButton>
            {isLoading ? (
                <InvitationSkeleton />
            ) : (
                <Grid
                    textAlign="center"
                    paddingBottom="120px"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Typography variant="h3">{t('pages.onboarding.invitation.title', { partnerName })}</Typography>
                    <Grid
                        display="flex"
                        justifyContent="space-between"
                        paddingTop={theme.spacing(3)}
                        width="545px"
                        gap={theme.spacing(8)}
                    >
                        <Grid display="flex" flexDirection="column" alignItems="center" gap={theme.spacing(2)} flex="1">
                            <img src="/assets/images/fair-remuneration.svg" height="60px" />
                            <Typography variant="h3">{t('pages.onboarding.invitation.left-subtitle')}</Typography>
                            <Typography variant="body2">{t('pages.onboarding.invitation.left-pargaraph')}</Typography>
                        </Grid>
                        <Grid display="flex" flexDirection="column" alignItems="center" gap={theme.spacing(2)} flex="1">
                            <img src="/assets/images/expert-advices.svg" height="60px" />
                            <Typography variant="h3">{t('pages.onboarding.invitation.right-subtitle')}</Typography>
                            <Typography variant="body2">{t('pages.onboarding.invitation.right-pargaraph')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid paddingTop={theme.spacing(6)}>
                        <Typography>{t('pages.onboarding.invitation.cta', { partnerName })}</Typography>
                        <Grid
                            paddingTop={theme.spacing(4)}
                            display="flex"
                            gap={theme.spacing(3)}
                            justifyContent="center"
                        >
                            <Button onClick={handleDeclineInvitation} loading={isDeclineInvitationLoading}>
                                {t('constants.refuse')}
                            </Button>
                            <Button
                                variant="contained"
                                onClick={handleAcceptInvitation}
                                loading={isAcceptInvitationLoading}
                                endIcon={<IconArrowRight />}
                            >
                                {t('constants.accept')}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </OnboardingStepsModal>
    );
};
