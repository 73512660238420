import { FieldMapSnapshotLogicPropsT } from '@modules/encoding/modules/technicalItinerary/components/FieldMapSnapshot/FieldMapSnapshot.types';
import { useCallback, useEffect, useState } from 'react';
import field from '@shared/entities/field';

export default ({ element, farmSeasonId, fieldId, setFieldMapSnapshotIsLoading }: FieldMapSnapshotLogicPropsT) => {
    const [gap, setGap] = useState(0);

    const _onScroll = useCallback(
        (e: Event) => {
            const paddingTop = parseInt(getComputedStyle(e.target as HTMLElement)?.paddingTop);

            const currentGap =
                (element?.getBoundingClientRect().top ?? 0) -
                ((e.target as HTMLElement)?.getBoundingClientRect().top ?? 0) -
                paddingTop;

            if (currentGap > gap) setGap(currentGap);

            const scrolled = Math.ceil(Math.max(0, ((e.target as HTMLElement)?.scrollTop ?? 0) - gap));

            element?.style.setProperty('--field-map-snapshot-scroll-position', `${scrolled}px`);
        },
        [element, gap],
    );

    useEffect(() => {
        if (!element) return;

        const rootElement = document.querySelector<HTMLElement>(':root');
        // set variable to 0 as inital value if not already set
        if (rootElement && !getComputedStyle(rootElement).getPropertyValue('--field-map-snapshot-scroll-position')) {
            rootElement.style.setProperty('--field-map-snapshot-scroll-position', `0px`);
        }

        let scrollable: HTMLElement | null = null;
        let isScrollableParent = element.parentElement;
        while (isScrollableParent) {
            // Find scrollable parent with id
            if (isScrollableParent.id === 'contextual-panel-section') {
                scrollable = isScrollableParent;
                isScrollableParent = null;
            } else {
                isScrollableParent = isScrollableParent.parentElement;
            }
        }

        if (scrollable) {
            scrollable.addEventListener('scroll', _onScroll);

            return () => {
                scrollable?.removeEventListener('scroll', _onScroll);
            };
        }
    }, [element, _onScroll]);

    const { data: image, isLoading } = field.useGetSnapshot(
        { farmSeasonId, fieldId },
        { skip: !farmSeasonId || !fieldId },
    );

    setFieldMapSnapshotIsLoading(!farmSeasonId || !fieldId || isLoading);

    return {
        image,
    };
};
