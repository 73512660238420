import api from '@shared/api';
import {
    OperationFormBodyMappingEndpointNameT,
    OperationFormDataMappingEndpointNameT,
    OperationFormEndpointParamsT,
    OperationFormEndpointNameT,
} from './operationFormApi.types';

const url = (
    operationFormEndpointName: OperationFormEndpointNameT,
    { farmSeasonId, fieldCropId, operationId }: OperationFormEndpointParamsT,
) =>
    `/v3/farm-seasons/${farmSeasonId}/field-crops/${fieldCropId}/operations/${operationFormEndpointName}/${operationId}`;

/** a generic helper to add get and update endoints for operation forms */
export const injectOperationFormEndpoints = <EntryPointNameT extends OperationFormEndpointNameT>(
    operationFormEndpointName: EntryPointNameT,
) => {
    const getKeyDeclaration = `get-${operationFormEndpointName}-`;
    const getKeyAccess = `useGet-${operationFormEndpointName}-Query`;
    const updateKeyDeclaration = `update-${operationFormEndpointName}-`;
    const updateKeyAccess = `useUpdate-${operationFormEndpointName}-Mutation`;

    const apiInjections = api.injectEndpoints({
        endpoints: (builder) => ({
            [getKeyDeclaration as 'get']: builder.query<
                OperationFormDataMappingEndpointNameT[EntryPointNameT],
                OperationFormEndpointParamsT
            >({
                query: (endpointParams) => ({
                    url: url(operationFormEndpointName, endpointParams),
                    method: 'GET',
                }),
                keepUnusedDataFor: 0,
                structuralSharing: false, // make sure we get a new object reference each time the query is called. even if the data structure/values are the same
            }),
            [updateKeyDeclaration as 'update']: builder.mutation<
                OperationFormDataMappingEndpointNameT[EntryPointNameT],
                OperationFormEndpointParamsT & {
                    body: OperationFormBodyMappingEndpointNameT[EntryPointNameT];
                }
            >({
                query: ({ body, ...endpointParams }) => ({
                    url: url(operationFormEndpointName, endpointParams),
                    method: 'PATCH',
                    body,
                }),
                invalidatesTags: ['Progress'], // any operation update can impact the progress
            }),
        }),
    });

    return {
        useGetQuery: apiInjections[getKeyAccess as 'useGetQuery'],
        initiateGet: apiInjections.endpoints[getKeyDeclaration as 'get'].initiate,
        useUpdateMutation: apiInjections[updateKeyAccess as 'useUpdateMutation'],
    };
};
