import { HarvestStaticDataT } from './harvestFormApi.types';
import { HarvestStaticDataTypeT } from '../harvest.types';
import api from '@shared/api';

export const harvestFormApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getHarvestFormStaticDataByType: builder.query<HarvestStaticDataT[], { type: HarvestStaticDataTypeT }>({
            query: ({ type }) => ({
                url: `/v3/static/static-data/${type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetHarvestFormStaticDataByTypeQuery } = harvestFormApi;
