import { ContentPage } from './components/ContentPage/ContentPage';
import ActionPage from './components/ActionPage/ActionPage';
import ContextualPanel from './components/ContextualPanel/ContextualPanel';
import OperationFormActionPage from './components/OperationFormActionPage/OperationFormActionPage';

import useCurrentSeason from './hooks/useCurrentSeason';
import useCurrentFarm from './hooks/useCurrentFarm';
import getCropGroupImg from './utils/getCropGroupImg';
import useCurrentSeasonId from './hooks/useCurrentSeasonId';
import { useFetchProgressAfterAllRequestsSucceed } from './hooks/useFetchProgressAfterAllRequestsSucceed';
import { FieldIdentityCard } from './components/FieldIdentityCard/FieldIdentityCard';
import FadeTransition from './components/FadeTransition/FadeTransition';
import { IntroProgressLayout } from './components/IntroProgressLayout/IntroProgressLayout';
import { IntroProgressItem } from './components/IntroProgressItem/IntroProgressItem';
import { useGetFieldThumbnail } from './hooks/useGetFieldThumbnail';
import { ActionPanel } from '@modules/encoding/shared/components/ActionPanel/ActionPanel';
import { ActionPageSkeleton } from '@modules/encoding/shared/components/ActionPage/ActionPageSkeleton';
import { useGetFarmSnapshot } from '@modules/encoding/shared/hooks/useGetFarmSnapshot';

export const encodingShared = {
    /* ------------------------------- Components ------------------------------- */
    ContentPage,
    ActionPage,
    ActionPageSkeleton,
    ActionPanel,
    ContextualPanel,
    FieldIdentityCard,
    FadeTransition,
    IntroProgressLayout,
    IntroProgressItem,
    OperationFormActionPage,

    /* ---------------------------------- hooks --------------------------------- */
    useCurrentSeason,
    useCurrentSeasonId,
    useCurrentFarm,
    useFetchProgressAfterAllRequestsSucceed,
    useGetFieldThumbnail,
    useGetFarmSnapshot,

    /* ---------------------------------- utils --------------------------------- */
    getCropGroupImg,
};
