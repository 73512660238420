import { PricingCard } from '../PricingCard';
import { useTranslation, Trans } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { usePricingCardStyle } from '../PricingCard.style';
import { Button } from '@soil-capital/ui-kit/components';
import { IconLinkExternal } from '@soil-capital/ui-kit/icons';
import { useNavigate } from 'react-router-dom';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { usePostCreateOrUpdateNextFarmSeasonAndProductPlanMutation } from '@modules/payment/api/paymentApi';
import { useEffect } from 'react';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import useCurrentLanguage from '@shared/hooks/useCurrentLanguage';
import languageSlugs from '@shared/constants/languageSlugs';

interface NetOffCardPropsT {
    currency: string;
    subscriptionPrice: number;
    payableHectares: number;
    pricePerHectare: number;
    isBelgian: boolean;
    isFloor: boolean;
    isCeiling: boolean;
    nextYear: number;
}

export const NetOffCard = ({
    currency,
    subscriptionPrice,
    payableHectares,
    pricePerHectare,
    isBelgian,
    isFloor,
    isCeiling,
    nextYear,
}: NetOffCardPropsT) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { classes } = usePricingCardStyle();
    const { currentSeason } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const currentLanguage = useCurrentLanguage();
    const seasonId = activeSeason?.id ?? currentSeasonId;
    const [postMutation, { data, isLoading }] = usePostCreateOrUpdateNextFarmSeasonAndProductPlanMutation();
    const handleSubmit = async () => {
        await postMutation({ farmSeasonId: seasonId, farmSeasonProductPlanName: 'NETOFF' });
    };
    const openNetOffLink = () =>
        currentLanguage?.name === languageSlugs.FR
            ? window.open('https://www.soilcapital.com/fr/faq?tab=tab4&article=q4.6')
            : window.open('https://www.soilcapital.com/faq?tab=tab4&article=q4.6');

    useEffect(() => {
        if (data) {
            navigate(`/${seasonId}/payment/net-off`);
        }
    }, [data, navigate, seasonId]);

    return (
        <PricingCard
            title={t('pages.payment.pricing.pricing-card.net-off.title')}
            price={
                <span>
                    {`${t('formatted-price', { price: subscriptionPrice, currency })} `}
                    {isBelgian && t('constants.tax-free')}
                </span>
            }
            tooltip={
                isCeiling || isFloor
                    ? t('billing.subscription.timeline.price-tooltip.harvest.floor-ceiling', {
                          payable_hectares: payableHectares,
                          subscription_price: subscriptionPrice,
                          currency,
                          minOrMax: isFloor ? t('constants.minimum') : t('constants.maximum'),
                      })
                    : t('billing.subscription.timeline.price-tooltip.harvest', {
                          payable_hectares: payableHectares,
                          subscription_price: subscriptionPrice,
                          price_per_hectare: pricePerHectare,
                          currency,
                      })
            }
            cta={t('pages.payment.pricing.pricing-card.net-off.cta', { price: subscriptionPrice, currency })}
            ctaAction={handleSubmit}
            isCtaLoading={isLoading}
        >
            <Typography variant="body2" className={classes.dark}>
                <Trans
                    i18nKey={'pages.payment.pricing.pricing-card.net-off.description'}
                    values={{ currency, price: subscriptionPrice, next_year: nextYear, current_year: nextYear - 1 }}
                />
            </Typography>
            <Button
                variant="text"
                color="inherit"
                className={classes.button}
                startIcon={<IconLinkExternal />}
                onClick={openNetOffLink}
            >
                {t('pages.payment.pricing.pricing-card.net-off.learn-more')}
            </Button>
        </PricingCard>
    );
};
