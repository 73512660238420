import api from '@shared/api';
import { LogErrorPayloadT } from './logErrorApi.types';

export const logErrorApi = api.injectEndpoints({
    endpoints: (builder) => ({
        logError: builder.mutation<null, LogErrorPayloadT>({
            query: (body) => ({
                url: `/v3/errors`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useLogErrorMutation } = logErrorApi;
