import { useEncodingStepStyles } from '@modules/encoding/modules/preparation/components/EncodingStep/EncodingStep.style';
import { HTMLAttributes, ReactNode } from 'react';
import { Chip } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';

type EncodingStepProps = {
    Icon: ReactNode;
    title: string;
    text: string;
    estimatedTime?: string;
} & HTMLAttributes<HTMLDivElement>;

export const EncodingStep = ({ Icon, title, text, estimatedTime, ...rest }: EncodingStepProps) => {
    const { classes } = useEncodingStepStyles();
    const { t } = useTranslation();

    return (
        <div {...rest}>
            <div className={classes.titleContainer}>
                <Typography variant="h3" className={classes.title}>
                    {Icon}
                    {title}
                </Typography>
                {!!estimatedTime && <Chip label={`${t('constants.estimated-time')} : ${estimatedTime}`} />}
            </div>
            <Typography>{text}</Typography>
        </div>
    );
};
