import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { FormAutoCompleteOptionT, FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';

type SeedingMachineInputProps = {
    options: FormAutoCompleteOptionT[];
    onChange: () => void;
};

export const SeedingMachineryInput = ({ options, onChange }: SeedingMachineInputProps) => {
    const { t } = useTranslation();

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.seeding.question.seed-machinery')}
                tooltipTitleKey="encoding-technical-itinerary.seeding.question.seed-machinery.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.seeding.question.seed-machinery.tooltip.text"
            />
            <FormAutocomplete
                name={SEEDING_FORM_INPUT_NAMES.MACHINERY_ID}
                options={options}
                onChange={onChange}
                multiple={false}
            />
        </FormRow>
    );
};
