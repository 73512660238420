import { useParams } from 'react-router-dom';
import entities from '@shared/entities';
import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';

export const useHasOperationGuard = () => {
    const { seasonId: seasonIdParam, fieldCropId, operationId } = useParams();
    const seasonId = Number(seasonIdParam);

    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: Number(seasonId),
    });

    const operation = operationState.getById(Number(operationId));
    const isLoading = operationState.isLoading;

    return isLoading ? undefined : !!operation;
};

export const HasFieldCropOperationsGuard = (props: GuardT) => {
    const passed = useHasOperationGuard();

    return <BaseGuard passed={passed} {...props} />;
};
