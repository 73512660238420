import CustomErrorBoundary from 'app/components/CustomErrorBoundary/CustomErrorBoundary';
import MapFallbackComponent from './components/MapFallbackComponent/MapFallbackComponent';
import { MapComponent, MapComponentProps } from './MapComponent';

export const MapView = (mapProps: MapComponentProps) => {
    return (
        <CustomErrorBoundary fallbackComponent={MapFallbackComponent} sendErrorLog={false}>
            <MapComponent {...mapProps} />
        </CustomErrorBoundary>
    );
};
