import { useTranslation } from 'react-i18next';
import { ConfirmModal, ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import { useConfirmSameSeedingDateModalStyles } from './ConfirmSameSeedingDateModal.style';

export const ConfirmSameSeedingDateModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: ((closeData: ConfirmModalCloseDataT) => void) | null;
}) => {
    const { t } = useTranslation();
    const { classes } = useConfirmSameSeedingDateModalStyles();

    return (
        <ConfirmModal
            isOpen={isOpen}
            className={classes.modal}
            onClose={onClose}
            title={t('encoding-technical-itinerary.same-seeding-date.modal-title')}
            primaryText={{
                translationKey: 'encoding-technical-itinerary.same-seeding-date.modal-primary-text',
            }}
            secondaryText={{
                translationKey: 'encoding-technical-itinerary.same-seeding-date.modal-secondary-text',
            }}
            confirmText={t('constants.continue')}
            cancelText={t('encoding-technical-itinerary.same-seeding-date.modal-cancel-button')}
        />
    );
};
