import createStaticEntity from '../createStaticEntity';
import { StaticCropGroupT } from '@shared/entities';
import { useCallback } from 'react';
import { StaticCropGroupSlugT } from '@shared/entities/staticCropGroup/staticCropGroup.types';

export default createStaticEntity<StaticCropGroupT>()({
    entityName: 'staticCropGroup',
    url: ({ countryId }: { countryId: number }) => `/v3/static/crop-groups/${countryId}`,
    useGetCustomState: (defaultState) => {
        const getById = useCallback(
            (staticCropGroupId: number | null | undefined) => {
                if (!staticCropGroupId) return null;

                return defaultState.list.find((cropGroup) => cropGroup.id === staticCropGroupId) ?? null;
            },
            [defaultState.list],
        );

        const getBySlug = useCallback(
            (slug: StaticCropGroupSlugT) => {
                return defaultState.list.find((cropGroup) => cropGroup.slug === slug) ?? null;
            },
            [defaultState.list],
        );

        return { ...defaultState, getById, getBySlug };
    },
});
