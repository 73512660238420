import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateFarmSeasonPreparationMutation } from '../../api/preparationApi';
import { useEncodingDoneContentData } from '@modules/encoding/pages/useEncodingDoneContentData';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';

export const usePreparationContentPageLogic = () => {
    const { seasonId } = useParams();
    // We know from encoding guard that this param exists
    const farmSeasonId = Number(seasonId as string);
    const { harvestYear, isLoading } = useGetCurrentHarvestYear();
    const [createMutation, { isLoading: isUpdatingPreparationProgress }] = useCreateFarmSeasonPreparationMutation();
    const { getStepProgress } = useEncodingDoneContentData();

    const navigate = useNavigate();

    const handleGoNext = async () => {
        if (getStepProgress('preparation').current < getStepProgress('preparation').max) {
            await createMutation({ farmSeasonId: farmSeasonId });
        }

        navigate(`../${encodingRoutesConfig.FARM_PROFILE}`);
    };

    return { harvestYear, isUpdatingPreparationProgress, isLoading, handleGoNext };
};
