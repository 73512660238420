import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetFieldThumbnail } from '@modules/encoding/shared/hooks/useGetFieldThumbnail';
import entities, { FieldT, StaticCropT } from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { encodingShared } from '@modules/encoding/shared';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { cropNamesT } from '@shared/map/types/mapTypes';
import setupCropPalette from '@shared/map/utils/setupCropPalette';
import { useMemo } from 'react';
import { getTextLayoutLabel } from '@shared/map/utils/layerHelper';

export const useFieldCropAssignationModalRightPanelViewModel = () => {
    const { t } = useTranslation();
    const { fieldState } = useGetFarmSeasonFields();
    const farmSeasonFields = fieldState.list;
    const { cropState } = useGetFarmSeasonCrops();
    const [createFieldCropEntity] = entities.fieldCrop.useCreateOptimistic();
    const [deleteFieldCropEntity] = entities.fieldCrop.useDelete();
    const { getCropsAssignedToAField } = cropState.useGetCropsAssignedToAField();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { harvestYear } = useGetCurrentHarvestYear();
    const { currentSeasonId } = useCurrentSeasonId();
    const { getFieldThumbnail } = useGetFieldThumbnail({ seasonId: currentSeasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { operationState } = entities.operation.useState({ farmSeasonId: currentSeasonId }); // by skiping farmSeasonFieldCropId, we get all operations

    const computedFarmSeasonFields: (FieldT & { snapshot: string; crops: cropNamesT[] })[] = useMemo(
        () =>
            farmSeasonFields.map((fsf) => {
                const allFsc = getCropsAssignedToAField(fsf.id);
                const allCrops = allFsc
                    .map((fsc) => staticCropState.getById(fsc.crop_id))
                    .filter((staticCrop): staticCrop is StaticCropT => !!staticCrop);

                return {
                    ...fsf,
                    snapshot: getFieldThumbnail({ fieldId: fsf.id, updatedAt: fsf.updated_at }),
                    crops: allCrops?.map((c) => ({
                        id: c?.id,
                        name: t(`db.crop.slug-${c.slug}`),
                    })),
                };
            }),
        [farmSeasonFields, getCropsAssignedToAField, getFieldThumbnail, staticCropState, t],
    );

    const staticCropColorsMap = useMemo(() => setupCropPalette(computedFarmSeasonFields), [computedFarmSeasonFields]);

    // the source build for the map layer should be reusable for other field selections
    const mapLayerSource = useMemo(() => {
        return {
            type: 'FeatureCollection',
            features: computedFarmSeasonFields.map((field) => ({
                ...field.polygon,
                properties: {
                    color: staticCropColorsMap[field.crops?.[0]?.id] ?? '#fff',
                    label: getTextLayoutLabel(field, field.crops?.map((crop: cropNamesT) => crop.name).join(' • ')),
                },
            })),
        } as const;
    }, [computedFarmSeasonFields, staticCropColorsMap]);

    const getFieldCropIdByCropIdAndFieldId = ({ cropId, fieldId }: { cropId: number; fieldId: number }) => {
        return fieldCropState.getByCropIdAndFieldId({ cropId, fieldId })?.id;
    };
    const createFieldCrop = ({ cropId, fieldId }: { cropId: number; fieldId: number }) => {
        return createFieldCropEntity({
            farmSeasonId: currentSeasonId,
            body: { farm_season_crop_id: cropId, farm_season_field_id: fieldId },
        });
    };
    const getHasOperationsByFarmSeasonFieldCropId = (fieldCropId: number) => {
        return operationState.getByFarmSeasonFieldCropId(fieldCropId).length > 0;
    };
    const deleteFieldCrop = (fieldCropId: number) => {
        return deleteFieldCropEntity({ farmSeasonId: currentSeasonId, id: fieldCropId });
    };

    const getFieldName = (fieldId: number) => fieldState.getById(fieldId)?.name ?? '';
    const getStaticCropName = (cropId: number) => {
        const staticCropSlug = staticCropState.getById(cropState.getById(cropId)?.crop_id)?.slug;
        const staticCropName = staticCropSlug ? t(`db.crop.slug-${staticCropSlug}`) : '';
        return staticCropName;
    };

    return {
        harvestYear,
        fieldList: computedFarmSeasonFields,
        getFieldCropIdByCropIdAndFieldId,
        getFieldCrop: fieldCropState.getByCropIdAndFieldId,
        getFieldCropsByFieldId: fieldCropState.getByFieldId,
        createFieldCrop,
        getHasOperationsByFarmSeasonFieldCropId,
        deleteFieldCrop,
        getFieldName,
        getStaticCropName,
        mapLayerSource,
    };
};
