import { makeStyles } from '@soil-capital/ui-kit/style';

export const useOnboardingStepsModalStyle = makeStyles()((theme) => ({
    container: {
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3),
        boxShadow: '0px 0px 32px 0px rgba(50, 63, 69, 0.08)',
        borderRadius: theme.shape.borderRadiusL,
        zIndex: 1,
        width: '728px',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));
