import { useGetHistoricalFarmSeasonsQuery } from '@modules/encoding/modules/history/api/historyApi';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import {
    HISTORY_PROGRESS_ENUM,
    HistoryFarmSeasonT,
    HistoryProgressKeysT,
} from '@modules/encoding/modules/history/api/historyApi.types';

export const useHistoryProgress = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { data: historyFarmSeasons } = useGetHistoricalFarmSeasonsQuery({ baselineFarmSeasonId: currentSeasonId });

    const isProgressStepCompleted = (historyFarmSeason: HistoryFarmSeasonT, property: HistoryProgressKeysT) =>
        historyFarmSeason.progress?.[property]?.total > 0 &&
        historyFarmSeason.progress?.[property].done === historyFarmSeason.progress?.[property].total;

    const calculateProgress = (property: HistoryProgressKeysT) => {
        return (
            historyFarmSeasons?.reduce((acc, fs) => {
                const progress = fs.progress?.[property];
                if (!progress) return acc;

                if (isProgressStepCompleted(fs, property)) {
                    acc += 1;
                }

                return acc;
            }, 0) ?? 0
        );
    };

    const maxRotationProgress = 2;
    const maxTechnicalItineraryProgress = 2;

    const currentRotationProgress = calculateProgress(HISTORY_PROGRESS_ENUM.ROTATION);
    const currentTechnicalItineraryProgress = calculateProgress(HISTORY_PROGRESS_ENUM.TECHNICAL_ITINERARY);

    return {
        maxRotationProgress,
        currentRotationProgress,
        maxTechnicalItineraryProgress,
        currentTechnicalItineraryProgress,
        isProgressStepCompleted,
    };
};
