import { Modal } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { IconCheckCircle } from '@soil-capital/ui-kit/icons';
import { BillingInfoForm } from '../BillingInfoForm/BillingInfoForm';
import useBillingInfoModalFormStyle from './useBillingInfoModalForm.style';
import { useRef, useState } from 'react';
import { CompanyT } from '@modules/billing/api/userCompany.types';
import { Typography } from '@soil-capital/ui-kit/material-core';

interface BillingInfoModalFormProps {
    open: boolean;
    onClose: () => void;
    refetch: () => void;
    userCompany?: CompanyT;
}

export const BillingInfoModalForm = ({ open, onClose, refetch, userCompany }: BillingInfoModalFormProps) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { classes } = useBillingInfoModalFormStyle();
    const onConfirmRef = useRef<(() => void) | null>(null);
    const handleConfirmClick = () => {
        if (onConfirmRef.current) {
            onConfirmRef.current();
        }
    };

    return (
        <Modal
            size="medium"
            open={open}
            onClose={onClose}
            onConfirm={handleConfirmClick}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{t('billing.subscription.billing-form.title')}</Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <Typography variant="h3" className={classes.typography}>
                        {t('billing.subscription.billing-form.billing-address')}
                    </Typography>
                    <BillingInfoForm
                        setOnConfirm={(onConfirm) => (onConfirmRef.current = onConfirm)}
                        userCompany={userCompany}
                        onClose={onClose}
                        refetch={refetch}
                        setIsSubmitting={(isSubmitting) => setIsSubmitting(isSubmitting)}
                        data-testid="billing-info-form"
                    />
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Confirm loading={isSubmitting} className={classes.confirm}>
                        <IconCheckCircle />
                        {t('constants.save')}
                    </Modal.Confirm>
                    <Modal.Cancel className={classes.cancel} onClick={onClose}>
                        {t('constants.cancel')}
                    </Modal.Cancel>
                </Modal.Footer>
            }
        />
    );
};
