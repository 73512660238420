import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import { useGetFarmer } from '@shared/hooks/useGetFarmer';

export const useIsPartnerPrepayingGuard = () => {
    const { farmer } = useGetFarmer();
    const { data, isLoading, isError } = useGetPartnerInfoQuery({ userId: farmer?.id });

    return isLoading ? undefined : isError ? true : !!data?.is_partner_prepaying;
};
export const IsPartnerNotPrepayingGuard = (props: GuardT) => {
    const passed = !useIsPartnerPrepayingGuard();

    return <BaseGuard passed={passed} {...props} />;
};
