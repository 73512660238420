import entities from '@shared/entities';

export const useGetSoilCapitalCountryList = () => {
    const { staticCountryState } = entities.staticCountry.useState();

    const countries = staticCountryState.list.filter(
        (country) =>
            country.translation_slug === 'db.country.france' ||
            country.translation_slug === 'db.country.belgique' ||
            country.translation_slug === 'db.country.united-kingdom',
    );

    return { countries };
};
