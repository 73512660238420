import { useGetCoverCropFormStaticDataByTypeQuery } from '../../api/coverCropFormApi';
import { COVER_CROP_STATIC_DATA_TYPE } from '../../api/coverCropFormApi.types';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { PESTICIDE_TYPE_SLUGS } from '@shared/entities/staticPesticide/staticPesticide.types';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import { useTranslation } from 'react-i18next';

export const useCoverCropFormOptions = () => {
    const { t } = useTranslation();
    const { currentFarm } = encodingShared.useCurrentFarm();

    const { data: destructionMethods, isLoading: isDestructionMethodsLoading } =
        useGetCoverCropFormStaticDataByTypeQuery({
            type: COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_DESTRUCTION,
        });

    const { data: destructionHeights, isLoading: isDestructionHeightsLoading } =
        useGetCoverCropFormStaticDataByTypeQuery({
            type: COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_HEIGHT,
        });

    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const coverCropDestructionOperationType = staticOperationTypeState.getBySlug(
        OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION,
    );
    const coverCropSeedingOperationType = staticOperationTypeState.getBySlug(
        OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_SEEDING,
    );

    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });

    const destructionMachines = staticMachineryState.getByOperationTypeId(
        coverCropDestructionOperationType?.id as number,
    );

    const seedingMachines = staticMachineryState.getByOperationTypeId(coverCropSeedingOperationType?.id as number);

    const { staticPesticideState } = entities.staticPesticide.useState({ country_id: currentFarm?.country_id });
    const pesticides = staticPesticideState.getByPesticideType(PESTICIDE_TYPE_SLUGS.HERBICIDE);

    const isLoading =
        isDestructionMethodsLoading ||
        isDestructionHeightsLoading ||
        staticOperationTypeState.isLoading ||
        staticMachineryState.isLoading ||
        staticPesticideState.isLoading;

    const destructionMethodOptions = destructionMethods
        ?.map((method) => ({
            value: method.id,
            label: t(method.slug),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const destructionHeightOptions = destructionHeights
        ?.map((height) => ({
            value: height.id,
            label: t(height.slug),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    // exlude the sprayer from the list as its never displayed to the user and defaulted for chemical destruction
    const destructionMachinesOptions = destructionMachines
        ?.filter((m) => m.slug !== STATIC_MACHINERY_SLUGS.SPRAYER)
        .map((machine) => ({
            value: machine.id,
            label: t(`db.machineries.${machine.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const seedingMachinesOptions = seedingMachines
        ?.map((machine) => ({
            value: machine.id,
            label: t(`db.machineries.${machine.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const pesticidesOptions = pesticides
        ?.map((pesticide) => ({
            value: pesticide.id,
            label: t(pesticide.name),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    return {
        isLoading,
        destructionMethodOptions,
        destructionHeightOptions,
        destructionMachinesOptions,
        seedingMachinesOptions,
        pesticidesOptions,
    };
};
