import { ConditionalTooltip } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useFormQuestionStyle } from './FormQuestion.style';
import { useTranslation } from 'react-i18next';

type FormQuestionProps = {
    question: string;
    tooltipTitleKey?: string;
    tooltipTextKey?: string;
};

export const FormQuestion = ({ question, tooltipTitleKey = '', tooltipTextKey = '' }: FormQuestionProps) => {
    const { t, i18n } = useTranslation();
    const { classes } = useFormQuestionStyle();

    return (
        <div className={classes.container}>
            <Typography>{question}</Typography>
            <ConditionalTooltip
                title={i18n.exists(tooltipTitleKey) ? t(tooltipTitleKey) : ''}
                text={i18n.exists(tooltipTextKey) ? t(tooltipTextKey) : ''}
            />
        </div>
    );
};
