import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSuccessDuplicationModalStyles = makeStyles()((theme) => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
    },
    text: {
        color: theme.palette.success.main,
    },
    icon: {
        width: 30,
    },
}));
