import { encodingShared } from '@modules/encoding/shared';
import entities, { ProgressT } from '@shared/entities';
import { TFunction, useTranslation } from 'react-i18next';
import { useArePreviousStepsCompleted } from '@shared/hooks/useArePreviousStepsCompleted';

type SlugT = 'technical-itinerary' | 'soil-analysis';
const progressMappingTranslationKeys: Record<SlugT, string> = {
    'technical-itinerary': 'encoding-layout.nav.technical-itinerary-subtitle',
    'soil-analysis': 'encoding-layout.nav.soil-analysis-subtitle',
};

export const computeSubtitle =
    (
        t: TFunction<'translation', undefined>,
        getProgressStepBySlug: (slug: ProgressT['slug']) => ProgressT | undefined,
    ) =>
    (progressStepSlug: SlugT) => {
        const translationKey = progressMappingTranslationKeys[progressStepSlug] ?? '';
        const currentProgress = getProgressStepBySlug(progressStepSlug)?.progress_done;
        const totalProgress = getProgressStepBySlug(progressStepSlug)?.progress_total;

        return t(translationKey, { currentProgress, totalProgress });
    };

export default () => {
    const { t } = useTranslation();
    const { currentSeason, currentSeasonLoading } = encodingShared.useCurrentSeason();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const isBaseline = !currentSeason?.baseline_farm_season_id;
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId }, { skip: !currentSeasonId });
    const { arePreviousStepsCompleted } = useArePreviousStepsCompleted({
        farmSeasonId: currentSeasonId,
    });

    const isLoading = currentSeasonLoading || progressState.isLoading;

    const getProgressStepBySlug = (slug: ProgressT['slug']) => progressState.getBySlug(slug);

    const getProgressStepStatus = (slug: ProgressT['slug']) => {
        return arePreviousStepsCompleted(slug) ? getProgressStepBySlug(slug)?.status ?? 'not-started' : 'disabled';
    };

    return {
        isLoading,
        isBaseline,
        getProgressStepBySlug,
        getProgressStepStatus,
        computeSubtitle: computeSubtitle(t, getProgressStepBySlug),
    };
};
