import { Tooltip } from '@soil-capital/ui-kit/components';
import { IconInfo, IconCheckCircle } from '@soil-capital/ui-kit/icons';
import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

export const PasswordValidationTooltip = ({
    password,
    children,
    showPasswordValidations,
}: {
    password: string;
    children: ReactNode;
    showPasswordValidations: boolean;
}) => {
    const { t } = useTranslation();

    const validatePassword = (password: string): string[] => {
        const errors: string[] = [];

        if (password.length < 8) {
            errors.push(t('validation.password-must-be-at-least-x-characters-short', { min: 8 }));
        }
        if (!/\d/.test(password)) {
            errors.push(t('validation.password-must-at-least-contain-one-figure-short'));
        }
        if (!/[^A-Za-z0-9]/.test(password)) {
            errors.push(t('validation.password-must-at-least-contain-one-special-character-short'));
        }

        return errors;
    };

    return (
        <Tooltip
            open={showPasswordValidations}
            placement="right"
            elements={
                <Grid display="flex" flexDirection="column" gap="8px">
                    <Grid display="flex" gap="8px">
                        {password.length < 8 ? <IconInfo color="warning" /> : <IconCheckCircle color="success" />}
                        <Typography>
                            {t('validation.password-must-be-at-least-x-characters-short', { min: 8 })}
                        </Typography>
                    </Grid>
                    <Grid display="flex" gap="8px">
                        {!/\d/.test(password) ? <IconInfo color="warning" /> : <IconCheckCircle color="success" />}
                        <Typography>{t('validation.password-must-at-least-contain-one-figure-short')}</Typography>
                    </Grid>
                    <Grid display="flex" gap="8px">
                        {!/[^A-Za-z0-9]/.test(password) ? (
                            <IconInfo color="warning" />
                        ) : (
                            <IconCheckCircle color="success" />
                        )}
                        <Typography>
                            {t('validation.password-must-at-least-contain-one-special-character-short')}
                        </Typography>
                    </Grid>
                </Grid>
            }
        >
            {children}
        </Tooltip>
    );
};
