import { useForm } from '@shared/hooks';
import * as z from 'zod';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { useDuplicateOperationsMutation } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/api/duplicationApi';
import { useParams } from 'react-router-dom';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import { useGetTargetFields } from './useGetTargetFields';

export const OPERATION_DUPLICATION_INPUT_NAMES = {
    HAS_SAME_ORGANIC: 'has_same_organic',
    HAS_SAME_TILLAGE_ID: 'has_same_tillage',
    HAS_SAME_COMPANION_CROP_ID: 'has_same_companion_crop',
    OPERATION_IDS: 'source_farm_season_operation_ids',
    TARGET_FIELD_CROP_IDS: 'target_farm_season_field_crop_ids',
} as const;

export const schema = z.object({
    [OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_ORGANIC]: z.boolean(),
    [OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_TILLAGE_ID]: z.boolean(),
    [OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_COMPANION_CROP_ID]: z.boolean(),
    [OPERATION_DUPLICATION_INPUT_NAMES.OPERATION_IDS]: z.array(z.number()),
    [OPERATION_DUPLICATION_INPUT_NAMES.TARGET_FIELD_CROP_IDS]: z.array(z.number()),
});

export type OperationDuplicationSchemaT = z.infer<typeof schema>;

export const useOperationDuplicationForm = ({
    sourceField,
    onMutationSuccess,
}: {
    sourceField: DuplicationFieldT;
    onMutationSuccess: () => void;
}) => {
    const { seasonId } = useParams();
    const [track] = useTrackMutation();
    const [duplicationMutation, { isLoading }] = useDuplicateOperationsMutation();

    const defaultValues = {
        [OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_ORGANIC]: true,
        [OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_TILLAGE_ID]: true,
        [OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_COMPANION_CROP_ID]: true,
        [OPERATION_DUPLICATION_INPUT_NAMES.OPERATION_IDS]: sourceField.operationsByOperationType
            .map((group) => group.operations.map((operation) => operation.id))
            .flat(),
        [OPERATION_DUPLICATION_INPUT_NAMES.TARGET_FIELD_CROP_IDS]: [],
    };

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: true,
    });

    const { targetFields } = useGetTargetFields();

    const onSubmit = methods.handleSubmit(async (data) => {
        try {
            await duplicationMutation({
                farmSeasonId: Number(seasonId),
                payload: {
                    source_farm_season_field_crop_id: sourceField.fieldCrop.id,
                    ...data,
                },
            }).unwrap();

            track({
                event: EVENTS.TECHNICAL_ITINERARY_DUPLICATION_COMFIRMED,
                properties: {
                    Crop: sourceField.staticCrop.name,
                    Field: sourceField.name,
                    Operations_subcategories_nb: sourceField.operationsByOperationType.length,
                    Fields_nb: targetFields.length,
                    Target_fields_nb: data.target_farm_season_field_crop_ids.length,
                },
            });

            onMutationSuccess();
        } catch (e) {
            console.error(e);
        }
    });

    const onReset = () => methods.reset(defaultValues);

    return { methods, onSubmit, onReset, isLoadingDuplication: isLoading };
};
