import { auth } from '@modules/auth';
import farmApi from '@shared/entities/farm/farm.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { STATIC_ROLE_SLUGS, UserT } from '@modules/auth/api/authApi.types';

export const useGetFarmer = () => {
    let farmer = null;
    let isLoading = true;
    const { currentSeasonId } = useCurrentSeasonId();
    const authUser: UserT | null | undefined = auth.useAuthUser();

    if (authUser?.role?.name !== STATIC_ROLE_SLUGS.FARMER) {
        const response = farmApi.useGetFarmer({ farmSeasonId: currentSeasonId });
        farmer = response.data;
        isLoading = response.isLoading;
    } else {
        farmer = authUser;
        isLoading = false;
    }

    return { farmer, isLoading };
};
