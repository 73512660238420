import { ConditionalTooltip, Input, IconButton } from '@soil-capital/ui-kit/components';
import { IconClose } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { BooleanRadioGroup, FormRow } from '@shared/components';
import { useMineralFertiliserInhibitorInputStyles } from './useMineralFertiliserProductInput.style';
import { useMineralFertiliserProductInput } from './useMineralFertiliserProductInput.logic';
import { FERTILISER_UNIT } from '@shared/entities/staticFertiliser/staticFertiliser.types';
import { MineralFertilisationProductSchemaT } from '@modules/encoding/modules/technicalItinerary/schema/mineralFertilisationFormSchema';

type MineralFertiliserProductInputPropsT = {
    name: string;
    product: MineralFertilisationProductSchemaT;
    handleProductRemoved: (productId: number) => void;
    onChange: () => void;
    disabled?: boolean;
};

export const MineralFertiliserProductInput = ({
    name,
    product,
    handleProductRemoved,
    onChange,
    disabled,
}: MineralFertiliserProductInputPropsT) => {
    const { t } = useTranslation();
    const { classes } = useMineralFertiliserInhibitorInputStyles();
    const { hasProductNitrogen, register, staticFertiliser } = useMineralFertiliserProductInput({
        productId: product.static_fertiliser_id,
    });

    return (
        <div className={classes.productInputs}>
            <FormRow>
                <Typography>{t(staticFertiliser?.translation_slug ?? '')}</Typography>
                <div className={classes.inputContainer}>
                    <Input
                        onChange={onChange}
                        type="number"
                        suffix={
                            staticFertiliser?.unit === FERTILISER_UNIT.KG
                                ? t('constants.unit.kg-ha')
                                : t('constants.unit.l-ha')
                        }
                        defaultValue={product?.quantity || 0}
                        inputProps={{
                            ...register(`${name}.quantity`, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        style={{ width: '250px' }}
                        disabled={disabled}
                    />
                    <IconButton
                        disabled={disabled}
                        onClick={() => handleProductRemoved(product.static_fertiliser_id)}
                        className={classes.iconClose}
                        data-testid={`mineral-fertiliser-form-delete-product-${product.static_fertiliser_id}`}
                    >
                        <IconClose />
                    </IconButton>
                </div>
            </FormRow>
            {hasProductNitrogen && (
                <FormRow>
                    <Typography>{t('encoding-technical-itinerary.mineral-fertiliser.question.inhibitor')}</Typography>
                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.mineral-fertiliser.question.inhibitor.tooltip.title')}
                        text={t('encoding-technical-itinerary.mineral-fertiliser.question.inhibitor.tooltip.text')}
                    />
                    <BooleanRadioGroup name={`${name}.inhibitor`} onChange={onChange} disabled={disabled} />
                </FormRow>
            )}
        </div>
    );
};
