import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => {
    return {
        banner: {
            zIndex: 1000,
            position: 'absolute',
            backgroundColor: theme.palette.darkScale[50],
            left: 0,
            borderRadius: theme.shape.borderRadiusS,
            margin: '10px 0 0 10px',
            padding: theme.spacing(1),
            fontSize: theme.typography.body.fontSize,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.darkScale[900],
        },
    };
});
