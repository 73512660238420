import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    introProgressLayout: {},
    sectionsContainer: {
        display: 'flex',
        gap: theme.spacing(3),

        '& >:first-of-type': {
            flex: 1,
            minWidth: 0,
        },
        '& >div:last-of-type': {
            width: '444px',
            [theme.breakpoints.down('lg')]: {
                width: '300px',
            },
        },
    },
}));
