import { IconSearch } from '@soil-capital/ui-kit/icons';
import { Autocomplete, AutocompletePropsT, AutocompleteValueT } from '@soil-capital/ui-kit/components';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useFormAutocompleteStyle } from './FormAutocomplete.style';
import { useMemo } from 'react';

export type FormAutoCompleteOptionT = {
    value: number;
    label: string;
};

export type FormAutocompleteProps<T extends FormAutoCompleteOptionT, Multiple extends boolean> = {
    name: string;
    onChange: (value: Multiple extends true ? T[] : T) => void;
    multiple: Multiple;
    isOptionEqualToValue?: (option: T, value: T) => boolean;
    getOptionLabel?: (option: T) => string;
} & Omit<AutocompletePropsT<T, Multiple>, 'onChange' | 'isOptionEqualToValue' | 'getOptionLabel'>;

export const FormAutocomplete = <T extends FormAutoCompleteOptionT, Multiple extends boolean>({
    name,
    options,
    onChange,
    placeholder,
    disabled,
    header,
    multiple,
    renderTags,
    getOptionLabel = (option) => option?.label ?? '',
    isOptionEqualToValue = (option, value) => option?.value === value?.value,
    ...rest
}: FormAutocompleteProps<T, Multiple>) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { classes } = useFormAutocompleteStyle();

    const optionsMapping = useMemo(() => {
        return Object.fromEntries(options?.map((option) => [option.value, option]));
    }, [options]);

    if (!context) throw new Error('Autocomplete should be used inside a FormProvider');

    return (
        <Controller
            control={context.control}
            name={name}
            render={({ field: { onChange: onFieldChanged, value } }) => {
                return (
                    <Autocomplete
                        value={
                            (multiple
                                ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  value?.map((v: any) => optionsMapping[v] as AutocompleteValueT<T, Multiple>)
                                : (optionsMapping[value] as AutocompleteValueT<T, Multiple>)) || null
                        }
                        disabled={disabled}
                        multiple={multiple}
                        onChange={(_, option) => {
                            onFieldChanged(
                                multiple
                                    ? (option as T[])?.map((o) => optionsMapping[o.value].value)
                                    : (option as T)?.value,
                            );
                            onChange?.(option as AutocompleteValueT<T, Multiple>);
                        }}
                        options={options}
                        getOptionLabel={getOptionLabel}
                        placeholder={placeholder ? placeholder : t('constants.select')}
                        renderTags={
                            renderTags
                                ? renderTags
                                : (values) => (
                                      <Box component="span" display="flex" alignItems="center" gap={1}>
                                          {`${values?.length} ${t('constants.selected')}`}
                                      </Box>
                                  )
                        }
                        header={header}
                        startIcon={<IconSearch />}
                        isOptionEqualToValue={isOptionEqualToValue}
                        className={classes.auotcomplete}
                        {...rest}
                    />
                );
            }}
        />
    );
};
