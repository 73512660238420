import { ReactNode, useEffect, useState } from 'react';
import { Box, Skeleton, useTheme } from '@soil-capital/ui-kit/material-core';

import useEncodingLayoutStyle from './EncodingLayout.style';
import EncodingHeader from './components/EncodingHeader/EncodingHeader';
import EncodingNav from './components/EncodingNav/EncodingNav';
import { MainScreenLayout } from '@shared/components';
import useListenSaveStatus from './hooks/useListenSaveStatus';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export default ({ children }: { children: ReactNode }) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const theme = useTheme();
    const skeletonBgColor = 'primaryScale.800';
    const { classes } = useEncodingLayoutStyle();

    // listen mutations for the save status
    useListenSaveStatus();

    /* ------------------------------- Fake loader ------------------------------ */
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // 600ms is like get notifications on prod.
        const timer = setTimeout(() => setLoading(false), 600);
        return () => clearTimeout(timer);
    }, []);

    return (
        <MainScreenLayout
            headerComponent={<EncodingHeader />}
            navComponent={<EncodingNav key={currentSeasonId} />}
            contentBgColor={theme.palette.primaryScale[900]}
        >
            {children}

            {/* -------------------------------- Skeletons ------------------------------- */}
            <div className={`${classes.skeletonSection} ${loading ? classes.skeletonSectionLoading : ''}`}>
                <Box height="100%" width="340px" borderRadius={'16px'} style={{ overflow: 'hidden' }} mr={1}>
                    <Skeleton sx={{ bgcolor: skeletonBgColor, height: '100%', width: '100%' }} variant="rectangular" />
                </Box>
                <Box height="100%" flex="1" borderRadius={'16px'} style={{ overflow: 'hidden' }}>
                    <Skeleton sx={{ bgcolor: skeletonBgColor, height: '100%', width: '100%' }} variant="rectangular" />
                </Box>
            </div>
        </MainScreenLayout>
    );
};
