import { useGetStaticMachineries } from '@shared/hooks/useGetStaticMachineries';
import entities from '@shared/entities';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';

export const useGetSeedingMachineries = () => {
    const { staticMachineryState } = useGetStaticMachineries();
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const seedingOperationType = staticOperationTypeState.getBySlug(OPERATION_TYPE_SLUGS.SEEDING_SEEDING);

    const seedingMachineries = staticMachineryState.getByOperationTypeId(seedingOperationType?.id);
    const isLoading = staticMachineryState.isLoading || staticOperationTypeState.isLoading;

    return { seedingMachineries: seedingMachineries ?? [], isLoading };
};
