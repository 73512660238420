import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { useParams } from 'react-router-dom';
import { CROP_SLUGS } from '@shared/entities/staticCrop/staticCrop.types';

export const useSeedingQuantityInputVisibility = () => {
    const { seasonId, fieldCropId } = useParams();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: Number(seasonId) });
    const { cropState } = entities.crop.useState({ farmSeasonId: Number(seasonId) });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });

    const fieldCrop = fieldCropState.getById(Number(fieldCropId));
    const farmSeasonCrop = cropState.getById(fieldCrop?.farm_season_crop_id);
    const staticCrop = staticCropState.getById(farmSeasonCrop?.crop_id);

    const potatoesSlugs: (string | undefined)[] = [
        CROP_SLUGS.SWEET_POTATOES,
        CROP_SLUGS.POTATO_STRACH,
        CROP_SLUGS.POTATOES_FOR_CONSERVATION,
        CROP_SLUGS.POTATOES_PLANT,
    ];
    const isPotatoes = potatoesSlugs.includes(staticCrop?.slug);

    return {
        shouldDisplay: isPotatoes,
    };
};
