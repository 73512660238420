import { IconHome } from '@soil-capital/ui-kit/icons';
import { IconPlus } from '@soil-capital/ui-kit/icons';
import { IconMinus } from '@soil-capital/ui-kit/icons';
import useMapControlStyle from './MapControl.style';

interface MapControlProps {
    onHomeClick: () => void;
    onZoomInClick: () => void;
    onZoomOutClick: () => void;
}

export const MapControl: React.FC<MapControlProps> = ({ onHomeClick, onZoomInClick, onZoomOutClick }) => {
    const { classes } = useMapControlStyle();

    return (
        <div className={classes.button}>
            <div className={classes.home}>
                <IconHome onClick={onHomeClick} color="primary" data-testid="home-icon" />
            </div>
            <div className={classes.zoom}>
                <IconPlus
                    className={classes.plusIcon}
                    onClick={onZoomInClick}
                    color="primary"
                    data-testid="plus-icon"
                />
                <div className={classes.divider} />
                <IconMinus
                    className={classes.minusIcon}
                    onClick={onZoomOutClick}
                    color="primary"
                    data-testid="minus-icon"
                />
            </div>
        </div>
    );
};
