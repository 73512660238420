import { Loader, SearchInput, SelectableList, SidePanel, Tooltip } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { formatNumber } from '@shared/utils';
import { IconCheckCircle, IconPlusCircle } from '@soil-capital/ui-kit/icons';
import { useFieldCropAssignationModalRightPanelStyles } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldCropAssignationModalRightPanel/FieldCropAssignationModalRightPanel.style';
import { FieldListFilters } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldListFilters/FieldListFilters';
import { SelectedFieldFilter } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/SelectedFieldFilter/SelectedFieldFilter';
import { useFieldCropAssignationModalRightPanelLogic } from './useFieldCropAssignationModalRightPanelLogic';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';
import { MapLayers, MapControl, MapPortal, featureSelectionLayers } from '@shared/map2';
import { MapBanner } from '@shared/map/components/MapBanner/MapBanner';
import { useFieldCropAssignationMap } from './useFieldCropAssignationMap';
import { ReplaceCropModal } from '../ReplaceCropModal/ReplaceCropModal';

export const FieldCropAssignationModalRightPanel = ({ readOnly }: { readOnly: boolean }) => {
    const { t } = useTranslation();
    const { classes } = useFieldCropAssignationModalRightPanelStyles();
    const {
        harvestYear,
        fieldList,
        selectedFieldIds,
        search,
        selectedFilter,
        fieldMatchingFilter,
        handleSearchChange,
        handleResetSearch,
        doesFieldMatchFilters,
        handleFilterChanged,
        handleFilterReset,
        handleFieldIdFilterReset,
        handleFieldSelection,
        selectedCropId,
        getFieldCrop,
        mapLayerSource,
        confirmDeleteFieldCropModal,
        replaceCropModal,
    } = useFieldCropAssignationModalRightPanelLogic();

    const {
        featureIdHovered,
        focusedFieldIds,
        mapBannerHelpText,
        onFeatureClick,
        onFeatureHover,
        setFocusedFieldIds,
        setFeatureIdHovered,
    } = useFieldCropAssignationMap({ handleFieldSelection, readOnly, selectedCropId });

    return (
        <>
            <SidePanel
                header={
                    <SidePanel.Header>
                        <Typography variant="h3" mb={2}>
                            {t('encoding-rotation.modal.right-panel.title')}
                        </Typography>
                        <Typography color="neutral" variant="body2" mb={2}>
                            {t('encoding-rotation.modal.right-panel.description', { harvestYear })}
                        </Typography>
                        <div className={classes.filterContainer}>
                            <SearchInput
                                placeholder={t('constants.search-field')}
                                value={search}
                                onClearClick={handleResetSearch}
                                onChange={handleSearchChange}
                            />
                            {fieldMatchingFilter ? (
                                <SelectedFieldFilter
                                    value={fieldMatchingFilter.name}
                                    onDelete={handleFieldIdFilterReset}
                                />
                            ) : (
                                <FieldListFilters
                                    value={selectedFilter}
                                    onChange={handleFilterChanged}
                                    onReset={handleFilterReset}
                                />
                            )}
                        </div>
                    </SidePanel.Header>
                }
                body={
                    <SidePanel.Body>
                        <SelectableList className={classes.list}>
                            <>
                                {fieldList.map((field) => {
                                    const selectedFieldCrop = getFieldCrop({
                                        cropId: selectedCropId,
                                        fieldId: field.id,
                                    });
                                    const disabled = !selectedCropId || Number(selectedFieldCrop?.id) < 0 || readOnly;
                                    return (
                                        <SelectableList.Item
                                            key={field.id}
                                            onChange={() => handleFieldSelection(field.id)}
                                            onMouseEnter={disabled ? undefined : () => setFeatureIdHovered(field.id)}
                                            onMouseLeave={() => setFeatureIdHovered(null)}
                                            className={!doesFieldMatchFilters(field) ? classes.hide : ''}
                                            isSelected={!!selectedFieldCrop}
                                            name={''}
                                            CheckboxProps={{
                                                disabled,
                                            }}
                                        >
                                            <Tooltip
                                                text={
                                                    !readOnly && !selectedCropId
                                                        ? t(
                                                              'encoding-rotation.modal.right-panel.field-selection-disabled-as-no-crop-selected',
                                                          )
                                                        : null
                                                }
                                                disableInteractive
                                                placement="top"
                                            >
                                                <FieldCropInfoBox
                                                    disabled={!selectedCropId}
                                                    className={classes.fieldInfoBoxOverride}
                                                    icon={
                                                        Number(selectedFieldCrop?.id) > 0 ? (
                                                            <IconCheckCircle color="success" />
                                                        ) : Number(selectedFieldCrop?.id) < 0 ? (
                                                            <Loader size={24} />
                                                        ) : (
                                                            <IconPlusCircle />
                                                        )
                                                    }
                                                    handleFieldClick={(event) => {
                                                        event.preventDefault();
                                                        setFocusedFieldIds([field.id]);
                                                    }}
                                                    fieldName={field.name}
                                                    fieldArea={`${formatNumber(field.area, 2)} HA`}
                                                    mapSnapshot={field.snapshot}
                                                    cropNames={field.crops}
                                                />
                                            </Tooltip>
                                        </SelectableList.Item>
                                    );
                                })}
                            </>
                        </SelectableList>
                    </SidePanel.Body>
                }
            />

            <MapPortal>
                <MapBanner helpText={mapBannerHelpText} />
                <MapControl
                    polygons={fieldList.map((f) => f.polygon)}
                    fallbackCoord={[
                        // as we are in a modal after the field assignation, we don't need a real fallback. We always have polygons
                        4.8151,
                        50.6242,
                    ]}
                />
            </MapPortal>
            <MapLayers
                onFeatureClick={onFeatureClick}
                onFeatureHover={onFeatureHover}
                focusedIds={focusedFieldIds}
                selectedIds={selectedFieldIds}
                hoveredId={featureIdHovered}
                dataSource={mapLayerSource}
                layers={featureSelectionLayers}
            />

            <ConfirmModal
                isOpen={confirmDeleteFieldCropModal.isOpen}
                confirmText={t('encoding-rotation.delete-field-crop-modal.confirm')}
                cancelText={t('constants.cancel')}
                title={t('encoding-rotation.delete-field-crop-modal.title')}
                onClose={({ confirmed }) => confirmDeleteFieldCropModal.onClose?.(confirmed)}
                type="warning"
                primaryText={{
                    translationKey: t('encoding-rotation.delete-field-crop-modal.warning-text'),
                    translationValues: {
                        fieldName: confirmDeleteFieldCropModal.data?.fieldName,
                        cropName: confirmDeleteFieldCropModal.data?.staticCropName,
                    },
                }}
                secondaryText={{
                    translationKey: t('encoding-rotation.delete-field-crop-modal.text'),
                    translationValues: {
                        fieldName: confirmDeleteFieldCropModal.data?.fieldName,
                        cropName: confirmDeleteFieldCropModal.data?.staticCropName,
                    },
                }}
            />
            <ReplaceCropModal replaceCropModalController={replaceCropModal} />
        </>
    );
};
