import { ReactNode } from 'react';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { usePaymentPageLayoutStyle } from './PaymentPageLayout.style';
import { Header } from '../Header/Header';
import { PaymentPageLoader } from './PaymentPageLoader';
import { ScrollSection } from '@soil-capital/ui-kit/components';

export type PaymentPageLayoutPropsT = {
    children: ReactNode;
    isLoading?: boolean;
};
export default ({ children, isLoading }: PaymentPageLayoutPropsT) => {
    const { classes } = usePaymentPageLayoutStyle();

    return (
        <ScrollSection spacingX={1} className={classes.container}>
            <Grid px={1}>
                <Header />
            </Grid>
            <Grid className={classes.contentSection}>
                {isLoading ? <PaymentPageLoader /> : children}
                <div className={classes.fieldBg}></div>
            </Grid>
        </ScrollSection>
    );
};
