import { OperationFormSchema } from './OperationFormSchema';
import { CoverCropFormDataT } from '../api/operationFormApi.types';
import { z } from 'zod';

export const coverCropFormSchema = new OperationFormSchema(
    {
        is_from_previous_crop: z.boolean().nullable(),
        species_count: z.number().nullable(),
        has_legumes: z.boolean().nullable(),
        seeding_machine_id: z.number().nullable(),
        seeding_depth: z.number().nullable(),
        seeding_width: z.number().nullable(),
        interrow_spacing: z.number().nullable(),
        destruction_date: z.string().nullable(),
        destruction_method_id: z.number().nullable(),
        destruction_height_id: z.number().nullable(),
        destruction_product_id: z.number().nullable(),
        destruction_product_quantity: z.number().nullable(),
        destruction_machine_id: z.number().nullable(),
        destruction_depth: z.number().nullable(),
        destruction_width: z.number().nullable(),
        destruction_passage: z.number().nullable(),
        biomass: z.number().nullable(),
    },
    (apiData?: CoverCropFormDataT) => ({
        is_from_previous_crop: apiData?.is_from_previous_crop ?? null,
        species_count: apiData?.species_count ?? null,
        has_legumes: apiData?.has_legumes ?? null,
        seeding_machine_id: apiData?.seeding_machinery?.machinery_id ?? null,
        seeding_depth: apiData?.seeding_machinery?.depth ?? null,
        seeding_width: apiData?.seeding_machinery?.width ?? null,
        interrow_spacing: apiData?.seeding_machinery?.interrow_spacing ?? null,
        destruction_date: apiData?.destruction_date ?? null,
        destruction_method_id: apiData?.destruction_method_id ?? null,
        destruction_height_id: apiData?.destruction_height_id ?? null,
        destruction_product_id: apiData?.destruction_machinery?.product_id ?? null,
        destruction_product_quantity: apiData?.destruction_machinery?.product_quantity ?? null,
        destruction_machine_id: apiData?.destruction_machinery?.machinery_id ?? null,
        destruction_depth: apiData?.destruction_machinery?.depth ?? null,
        destruction_width: apiData?.destruction_machinery?.width ?? null,
        destruction_passage: apiData?.destruction_machinery?.passage ?? null,
        biomass: apiData?.biomass ?? null,
    }),
    ['destruction_date'],
);

export const COVER_CROP_FORM_INPUT_NAMES = coverCropFormSchema.INPUT_NAMES;

export type CoverCropFormSchemaT = z.infer<typeof coverCropFormSchema.schema>;
