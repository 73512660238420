import { useMap } from '@shared/map2/useMap';
import bbox from '@turf/bbox';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import { useCallback } from 'react';

export const useMapControlLogic = ({
    polygons,
    fallbackCoord,
}: {
    polygons: GeoJSON.Feature[];
    fallbackCoord: [number, number];
}) => {
    const map = useMap();

    const recenterMapOnPolygons = useCallback(
        (polygonToCenter: GeoJSON.Feature[]) => {
            const bbox = calculateBoundingBox(polygonToCenter);

            if (map && polygonToCenter.length > 0) {
                map.fitBounds(bbox, { padding: 20, animate: false });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [map],
    );

    // Calculate the smallest box that can fit the given polygon(s)
    const calculateBoundingBox = (polygonToCenter: GeoJSON.Feature[]): BBox2d => {
        return bbox({ type: 'FeatureCollection', features: polygonToCenter }) as BBox2d;
    };

    const centerMapOnCoordinates = (coordinates: [number, number]) => {
        if (map && coordinates) {
            map.flyTo({
                center: coordinates,
                zoom: 14,
                essential: true,
            });
        }
    };

    const zoomIn = () => {
        if (map) {
            const currentZoom = map.getZoom();
            const newZoom = currentZoom + 1;

            map.flyTo({
                zoom: newZoom,
                essential: true,
            });
        }
    };

    const zoomOut = () => {
        if (map) {
            const currentZoom = map.getZoom();
            const newZoom = currentZoom - 1;

            map.flyTo({
                zoom: newZoom,
                essential: true,
            });
        }
    };

    const recenterMap = () => {
        return polygons && recenterMapOnPolygons
            ? recenterMapOnPolygons(polygons)
            : centerMapOnCoordinates(fallbackCoord);
    };

    return {
        zoomIn,
        zoomOut,
        recenterMap,
    };
};
