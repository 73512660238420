import { useGetFarmSeasonPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { auth } from '@modules/auth';

export const usePaymentInProgressLogic = () => {
    const navigate = useNavigate();
    const farmSeasonId = auth.useLatestFarmSeasonId();
    const {
        data: paymentStatus,
        refetch,
        isFetching,
    } = useGetFarmSeasonPaymentStatusQuery({
        farmSeasonId: farmSeasonId,
    });
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if ((paymentStatus?.status === 'active' && paymentStatus?.paid_at) || paymentStatus?.status === 'pending') {
            navigate(`/onboarding/confirmation`, { replace: true });
        } else if (paymentStatus?.status === 'payment_failed' && !isFetching) {
            navigate(`/onboarding/onboarding-payment/failed`, { replace: true });
        } else if (
            paymentStatus?.status === 'payment_in_progress' ||
            paymentStatus?.status === 'to_pay' ||
            !paymentStatus?.paid_at
        ) {
            interval = setInterval(() => {
                refetch();
            }, 3000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [paymentStatus, navigate, refetch, isFetching]);

    return {
        farmSeasonId,
    };
};
