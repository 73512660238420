import { useCopyHistoryDataMutation } from '@modules/encoding/modules/history/api/historyApi';

export const useHistoryCopyFromBaseline = ({ onSuccess }: { onSuccess?: () => void } = {}) => {
    const [updateMutation, { isLoading }] = useCopyHistoryDataMutation();

    const copyHistoryFromBaseline = async (farmSeasonId: number) => {
        try {
            await updateMutation({ farmSeasonId });
            onSuccess?.();
        } catch (e) {
            throw new Error('Could not copy history data');
        }
    };

    return { copyHistoryFromBaseline, isLoading };
};
