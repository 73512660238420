import { useNavigate, useRoutes } from 'react-router-dom';
import { irrigationRoutesConfig } from './irrigation.routes';
import { IntroductionContentPage } from './pages/IntroductionContentPage/IntroductionContentPage';
import { encodingShared } from '@modules/encoding/shared';

export default () => {
    const navigate = useNavigate();

    const routes = useRoutes([
        { path: irrigationRoutesConfig.introduction, element: <IntroductionContentPage navigate={navigate} /> },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            {routes}
        </encodingShared.FadeTransition>
    );
};
