import { Input, Modal } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useFieldCreationModalStyles } from './useFieldsCreationModal.style';
import { FIELD_CREATION_OPENDATA, useFieldCreationModalFormLogic } from './useFieldCreationModalFormLogic';
import { geoJsonFeatureT } from '@shared/map/types/mapTypes';
import { Typography } from '@soil-capital/ui-kit/material-core';

export type FieldsCreationModalPropsT = {
    feature: geoJsonFeatureT;
    open: boolean;
    onClose: () => void;
};

export const FieldsCreationModal = ({ feature, open, onClose }: FieldsCreationModalPropsT) => {
    const { classes } = useFieldCreationModalStyles();
    const { t } = useTranslation();
    const { methods, onConfirm, isCreatingField, errors, inputRef } = useFieldCreationModalFormLogic({
        feature,
        onClose,
        open,
    });

    return (
        <FormProvider {...methods}>
            <form>
                <Modal
                    size={'small'}
                    open={open}
                    onClose={onClose}
                    onConfirm={onConfirm}
                    header={
                        <Modal.Header>
                            <Modal.HeaderTitle>{t('encoding-rotation.field-creation-modal.title')}</Modal.HeaderTitle>
                            <Modal.Close />
                        </Modal.Header>
                    }
                    body={
                        <Modal.Body className={classes.body}>
                            <Input
                                label={t('constants.name')}
                                type="text"
                                inputProps={{
                                    ...methods.register(FIELD_CREATION_OPENDATA.FIELD_NAME),
                                }}
                                inputRef={inputRef}
                            />
                            {errors.field_name && (
                                <Typography color="error" className={classes.error}>
                                    {errors.field_name.message}
                                </Typography>
                            )}
                            <Input
                                label={t('constants.area')}
                                type="number"
                                suffix={t('constants.hectare-unit')}
                                defaultValue={feature?.properties?.area.toFixed(2)}
                                inputProps={{
                                    ...methods.register(FIELD_CREATION_OPENDATA.AREA, {
                                        setValueAs: (v) => Number(v),
                                    }),
                                }}
                                placeholder={feature?.properties?.area.toFixed(2)}
                            />
                            {errors.area && (
                                <Typography color="error" className={classes.error}>
                                    {errors.area.message}
                                </Typography>
                            )}
                        </Modal.Body>
                    }
                    footer={
                        <Modal.Footer>
                            <Modal.Confirm loading={isCreatingField}>{t('constants.save')}</Modal.Confirm>
                        </Modal.Footer>
                    }
                />
            </form>
        </FormProvider>
    );
};
