export type SegmentTrackPayloadT = {
    event: string;
    properties?: Record<string, string | number>;
};

export const EVENTS = {
    ENCODING_STARTED: 'Encoding started',
    FIELDS_FILTER_CLICKED: 'Fields filter in rotation clicked',
    CROP_TECHNICAL_ITINERARY_STARTED: 'Crop technical itinerary started',
    CROP_OPERATION_TIMELINE_STARTED: 'Crop operation timeline started',
    CROP_OPERATION_TIMELINE_COMPLETED: 'Crop operation timeline completed',
    FIELDS_DEFINITION_COMPLETED: 'Fields definition completed',
    FORGOTTEN_FIELDS_MODAL_DISPLAYED: 'Forgotten fields modal displayed',
    OPERATION_SUBCATEGORY_ADDED: 'Operation subcategory added',
    TECHNICAL_ITINERARY_DUPLICATION_STARTED: 'Technical itinerary duplication started',
    TECHNICAL_ITINERARY_DUPLICATION_CLOSED: 'Technical itinerary duplication closed',
    TECHNICAL_ITINERARY_DUPLICATION_REQUESTED: 'Technical itinerary duplication requested',
    TECHNICAL_ITINERARY_DUPLICATION_CANCELLED: 'Technical itinerary duplication cancelled',
    TECHNICAL_ITINERARY_DUPLICATION_COMFIRMED: 'Technical itinerary duplication confirmed',
    PRICING_FLOW_STARTED: 'Pricing flow started',
    PRICING_FLOW_ENDED: 'Pricing flow ended',
    RENEW_IN_ADVANCE_OPTION_SELECTED: 'Renew in advance option selected',
} as const;
