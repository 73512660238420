import { useCoverCropDestructionMachineQuestion } from './useCoverCropDestructionMachineQuestion';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';
import { FormAutoCompleteOptionT, FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';

export const CoverCropDestructionMachineQuestion = ({
    options,
    onChange,
}: {
    options: FormAutoCompleteOptionT[];
    onChange: () => void;
}) => {
    const { isVisible } = useCoverCropDestructionMachineQuestion();
    const { t } = useTranslation();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.destruction-machine')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.destruction-machine"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.destruction-machine"
                    />
                    <FormAutocomplete
                        name={COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID}
                        placeholder={t('constants.option.placeholder')}
                        onChange={onChange}
                        options={options}
                        multiple={false}
                    />
                </FormRow>
            )}
        </>
    );
};
