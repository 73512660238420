import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import ChangePasswordLayout from '@modules/auth/components/ChangePasswordLayout/ChangePasswordLayout';
import { Button, Input } from '@soil-capital/ui-kit/components';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { useForgotPasswordLogic, FORGOTPASSWORD } from './useForgotPasswordLogic';

export const ForgotPassword = () => {
    const theme = useTheme();
    const { msgOpen, t, methods, onSubmit, sendResetPasswordLinkLoading, errors } = useForgotPasswordLogic();

    return (
        <ChangePasswordLayout>
            {msgOpen ? (
                <Grid
                    paddingTop={theme.spacing(10)}
                    paddingBottom={theme.spacing(10)}
                    display="flex"
                    flexDirection="column"
                    gap={theme.spacing(6)}
                >
                    <img src="/assets/images/check-circle.svg" height="64px" />
                    <Grid display="flex" flexDirection="column" gap={theme.spacing(2)}>
                        <Typography variant="h3" textAlign="center">
                            {t('pages.forgot-password.confirmation.title')}
                        </Typography>
                        <Typography variant="body2" textAlign="center">
                            {t('pages.forgot-password.confirmation.subtitle')}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <>
                    <Typography variant="h3" textAlign="center">
                        {t('pages.forgot-password.title')}
                    </Typography>
                    <Grid width="324px">
                        <Grid display="flex" flexDirection="column" alignItems="start" gap={theme.spacing(1)}>
                            <Input
                                label={t('pages.forgot-password.email')}
                                placeholder={t('pages.forgot-password.email.placeholder')}
                                inputProps={{
                                    ...methods.register(FORGOTPASSWORD.EMAIL),
                                }}
                                fullWidth
                            />
                            {errors[FORGOTPASSWORD.EMAIL] && (
                                <Typography variant="subcaption" color="error">
                                    {errors[FORGOTPASSWORD.EMAIL]?.message}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        onClick={onSubmit}
                        loading={sendResetPasswordLinkLoading}
                        endIcon={<IconArrowRight />}
                    >
                        {t('pages.forgot-password.cta')}
                    </Button>
                </>
            )}
        </ChangePasswordLayout>
    );
};
