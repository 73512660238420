import { Navigate } from 'react-router-dom';
import { PropsWithChildren, ReactElement } from 'react';

export type GuardT = PropsWithChildren<{ redirectUrl: string; Loader?: ReactElement }>;

type BaseGuardProps = GuardT & {
    passed: boolean | undefined;
};

export const BaseGuard = ({ passed, redirectUrl, children, Loader }: BaseGuardProps) => {
    if (passed === undefined) return Loader || null;
    if (!passed) return <Navigate to={redirectUrl} />;

    return <>{children}</>;
};
