import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { useNavigate } from 'react-router-dom';
import { IconCross } from '@soil-capital/ui-kit/icons';

export const CountryNonEligible = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <OnboardingStepsModal
            stepProgress={0}
            ctaAction={() => {
                navigate('/onboarding/eligibility');
            }}
            ctaText={t('constants.continue')}
            isCTAdisabled={true}
            onBackClick={() => navigate('/onboarding/eligibility')}
        >
            <Grid
                display="flex"
                flexDirection="column"
                gap={theme.spacing(3)}
                alignItems="center"
                textAlign="center"
                paddingTop="60px"
                whiteSpace="pre-line"
            >
                <Grid
                    fontSize="88px"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="88px"
                    width="88px"
                >
                    <IconCross fontSize="inherit" color="error" sx={{ padding: '22px' }} />
                </Grid>
                <Typography variant="h3">{t('pages.onboarding.eligibility.non-eligible.title')}</Typography>
                <Typography variant="body2" paddingBottom="40px">
                    {t('pages.onboarding.eligibility.country-non-eligible.subtitle')}
                </Typography>
            </Grid>
        </OnboardingStepsModal>
    );
};
