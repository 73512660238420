import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import fieldApi from '@shared/entities/field/field.api';
import { opendataFieldT } from '@shared/entities/field/field.types';

export const useCreateFarmSeasonField = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [createMutation, { isLoading }] = fieldApi.useCreate();

    const createField = async (field: opendataFieldT) => {
        return await createMutation({ farmSeasonId: currentSeasonId, body: field });
    };

    return {
        createField,
        isLoading,
    };
};
