import { useWatch } from 'react-hook-form';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { DESTRUCTION_STATIC_DATA_SLUGS, DESTRUCTION_STATIC_DATA_TYPE } from '../../destruction.types';
import { useGetDestructionFormStaticDataByTypeQuery } from '../../api/destructionFormApi';

export const useDestructionDepthInputVisibility = () => {
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });
    const { data: destructionMethods } = useGetDestructionFormStaticDataByTypeQuery({
        type: DESTRUCTION_STATIC_DATA_TYPE.HARVEST_DESTRUCTION_METHOD,
    });

    const machineryId = useWatch({ name: DESTRUCTION_FORM_INPUT_NAMES.MACHINERY_ID });
    const machine = staticMachineryState.getById(machineryId);

    const destructionMethodValue = useWatch({ name: DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_METHOD_STATIC_DATA_ID });
    const ploughingDestructionMethod = destructionMethods?.find(
        (el) => el.slug === DESTRUCTION_STATIC_DATA_SLUGS.HARVEST_DESTRUCTION_METHOD_PLOUGHING,
    )?.id;

    const shouldDisplayDestructionDepthInput =
        machine?.depth === null || destructionMethodValue === ploughingDestructionMethod;

    return { shouldDisplayDestructionDepthInput };
};
