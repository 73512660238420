import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useCoverCropDestructionDateQuestion } from './useCoverCropDestructionDateQuestion';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { FormOperationDatePicker } from '@shared/components/form/FormOperationDatePicker/FormOperationDatePicker';
import { Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useCoverCropDestructionDateStyles } from './CoverCropDestructionDate.style';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';

export const CoverCropDestructionDateQuestion = ({
    onChange,
    error,
}: {
    onChange: () => void;
    error?: boolean | undefined;
}) => {
    const { timeOnField, isTimeonFieldVisible, minDate, harvestYear } = useCoverCropDestructionDateQuestion();
    const { classes } = useCoverCropDestructionDateStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <>
            <FormRow>
                <div>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.destruction-date')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.destruction-date"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.destruction-date"
                    />
                </div>

                <FormOperationDatePicker
                    name={COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE}
                    min={minDate}
                    max={harvestYear ? new Date(harvestYear, 11, 31) : undefined}
                    validationError={error}
                    onChange={() => onChange()}
                />
            </FormRow>

            {isTimeonFieldVisible && (
                <Typography variant="caption" className={classes.timeOnField} color={theme.palette.darkScale[700]}>
                    {t('encoding-technical-itinerary.cover-crop.total-time-on-field', { count: timeOnField })}
                </Typography>
            )}
        </>
    );
};
