import { CROP_CREATION_SOURCE_SLUGS } from '@shared/entities/crop/crop.types';
import { FIELD_AREA_SOURCE_SLUGS } from '@shared/entities/field/field.types';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';

export const useGetNeedFinalizationStep = () => {
    const { fieldState } = useGetFarmSeasonFields();
    const { cropState } = useGetFarmSeasonCrops();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();

    // If any of the field or crop referenced by existing field-crops doesn't come
    // from shapefile import but has been created manually, or if there are less
    // field-crops than fields, then this will be true.
    const hasUnimportedFieldCrop =
        fieldCropState.list.length === 0 ||
        fieldCropState.list.length < fieldState.list.length ||
        fieldCropState.list.some((fieldCrop) => {
            const field = fieldState.getById(fieldCrop.farm_season_field_id);
            const crop = cropState.getById(fieldCrop.farm_season_crop_id);

            return (
                field?.area_source !== FIELD_AREA_SOURCE_SLUGS.PAC ||
                crop?.creation_source !== CROP_CREATION_SOURCE_SLUGS.PAC
            );
        });

    const isLoading = fieldState.isFetching || cropState.isFetching || fieldCropState.isFetching;

    return { needFinalization: !hasUnimportedFieldCrop, isLoading };
};
