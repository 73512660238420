// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/BrownStd/subset-BrownStd-Regular.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/BrownStd/subset-BrownStd-Regular.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/BrownStd/subset-BrownStd-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/BrownStd/subset-BrownStd-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/BrownStd/subset-BrownStd-Bold.eot", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/BrownStd/subset-BrownStd-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'BrownStd-Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('woff2');
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('embedded-opentype'),
        url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}

@font-face {
    font-family: 'BrownStd-Bold';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('woff2');
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('embedded-opentype'),
        url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype');
}

/* pseudo elements */
.mapboxgl-ctrl-group button + button {
    border-top: none;
}
.mapboxgl-ctrl-group button + button:before {
    content: '';
    display: block;
    width: 25px;
    height: 1px;
    margin: 0 auto;
    background-color: #f9f5e9;
}
`, "",{"version":3,"sources":["webpack://./src/app/index.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,4DAA0E;IAC1E;kEAC0E;AAC9E;;AAEA;IACI,4BAA4B;IAC5B,4DAAuE;IACvE;kEACuE;AAC3E;;AAEA,oBAAoB;AACpB;IACI,gBAAgB;AACpB;AACA;IACI,WAAW;IACX,cAAc;IACd,WAAW;IACX,WAAW;IACX,cAAc;IACd,yBAAyB;AAC7B","sourcesContent":["@font-face {\n    font-family: 'BrownStd-Regular';\n    src: url('./fonts/BrownStd/subset-BrownStd-Regular.woff2') format('woff2');\n    src: url('./fonts/BrownStd/subset-BrownStd-Regular.eot') format('embedded-opentype'),\n        url('./fonts/BrownStd/subset-BrownStd-Regular.ttf') format('truetype');\n}\n\n@font-face {\n    font-family: 'BrownStd-Bold';\n    src: url('./fonts/BrownStd/subset-BrownStd-Bold.woff2') format('woff2');\n    src: url('./fonts/BrownStd/subset-BrownStd-Bold.eot') format('embedded-opentype'),\n        url('./fonts/BrownStd/subset-BrownStd-Bold.ttf') format('truetype');\n}\n\n/* pseudo elements */\n.mapboxgl-ctrl-group button + button {\n    border-top: none;\n}\n.mapboxgl-ctrl-group button + button:before {\n    content: '';\n    display: block;\n    width: 25px;\n    height: 1px;\n    margin: 0 auto;\n    background-color: #f9f5e9;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
