import { FarmPaymentStatusT } from '@modules/payment/api/payment.types';

export type GetHomePageStatusPropsT = {
    hasStartedEncoding: boolean;
    isHistoryOngoing: boolean;
    isEncodingDone: boolean;
    isPartnerPrepaying: boolean;
    farmPaymentStatus?: FarmPaymentStatusT['status'];
    isSummaryValidated: boolean;
    hasResultsPublished: boolean;
    isNextSeasonOpened: boolean;
};

export type HomePageStatusT =
    | 'SELF_ENCODING_NOT_STARTED'
    | 'SELF_ENCODING_ONGOING'
    | 'SELF_ENCODING_DONE'
    | 'RESULTS_BEING_REVIEWED'
    | 'RESULTS_READY_DEFERRED_PAYMENT'
    | 'RESULTS_READY_RENEWAL_PAYMENT'
    | 'RESULTS_READY_NO_PAYMENT' // for partner prepaying users
    | 'PAYMENT_DONE_NEXT_SEASON_CLOSED'
    | 'NEXT_SEASON_OPEN'
    | null;

export const getHomePageStatus = ({
    farmPaymentStatus,
    hasResultsPublished,
    isNextSeasonOpened,
    isPartnerPrepaying,
    isEncodingDone,
    isHistoryOngoing,
    hasStartedEncoding,
    isSummaryValidated,
}: GetHomePageStatusPropsT): HomePageStatusT => {
    const hasRenewed = farmPaymentStatus === 'ha_based' || farmPaymentStatus === 'net_off';

    switch (true) {
        case !hasStartedEncoding:
            return 'SELF_ENCODING_NOT_STARTED';
        case hasStartedEncoding && !isHistoryOngoing && !isEncodingDone:
            return 'SELF_ENCODING_ONGOING';
        case (isHistoryOngoing || isEncodingDone) && !isSummaryValidated:
            return 'SELF_ENCODING_DONE';
        case isSummaryValidated && !hasResultsPublished:
            return 'RESULTS_BEING_REVIEWED';
        case farmPaymentStatus === 'deferred' && hasResultsPublished && !isPartnerPrepaying:
            return 'RESULTS_READY_DEFERRED_PAYMENT';
        case farmPaymentStatus === 'to_be_renewed' && hasResultsPublished && !isPartnerPrepaying:
            return 'RESULTS_READY_RENEWAL_PAYMENT';
        case farmPaymentStatus === 'to_be_renewed' && hasResultsPublished && isPartnerPrepaying && !isNextSeasonOpened:
            return 'RESULTS_READY_NO_PAYMENT';
        case !isNextSeasonOpened && hasRenewed:
            return 'PAYMENT_DONE_NEXT_SEASON_CLOSED';
        case isNextSeasonOpened:
            return 'NEXT_SEASON_OPEN';
        default:
            return null;
    }
};
