import entities from '@shared/entities';
import { SharedStateT } from '@shared/store';
import { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

export const useFetchProgressAfterAllRequestsSucceed = ({ seasonId }: { seasonId: number }) => {
    const [getProgress] = entities.progress.useLazyGet();
    const allRequestSuccesseedResolveRef = useRef<null | ((value: void) => void)>(() => undefined);
    const globalRequestStatuses = useSelector((state: SharedStateT) => state.saveStatus.requests);

    // if we don't find a failed or pending request, we consider they are all succeed
    const allRequestSucceed = useMemo(
        () => !Object.values(globalRequestStatuses).find((req) => req.status === 'pending' || req.status === 'failed'),
        [globalRequestStatuses],
    );

    if (allRequestSucceed) {
        allRequestSuccesseedResolveRef.current?.();
    }

    const fetchProgress = useCallback(async () => {
        if (!allRequestSucceed) {
            await new Promise<void>((res) => (allRequestSuccesseedResolveRef.current = res));
            allRequestSuccesseedResolveRef.current = null;
        }
        await getProgress({ seasonId });
    }, [allRequestSucceed, getProgress, seasonId]);

    return {
        fetchProgress,
    };
};
