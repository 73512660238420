import { RadioGroup, RadioGroupOptionT } from '@shared/components/form/RadioGroup/RadioGroup';
import { useTranslation } from 'react-i18next';
import { useStrawsUsageInputStyle } from './StrawsUsageInput.style';
import { FormRow } from '@shared/components';
import { HARVEST_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/harvestFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type StrawsUsageInputPropsT = {
    options: RadioGroupOptionT[];
    onChange: (v: number) => void;
    disabled?: boolean;
};

export const StrawsUsageInput = ({ options, onChange, disabled }: StrawsUsageInputPropsT) => {
    const { t } = useTranslation();
    const { classes } = useStrawsUsageInputStyle();

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.harvest.question.straws-usage')}
                tooltipTitleKey="encoding-technical-itinerary.harvest.question.straws-usage.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.harvest.question.straws-usage.tooltip.text"
            />
            <RadioGroup
                type="number"
                className={classes.radioGroup}
                name={HARVEST_FORM_INPUT_NAMES.STRAWS_USAGE_STATIC_DATA_ID}
                options={options}
                onChange={onChange}
                disabled={disabled}
            />
        </FormRow>
    );
};
