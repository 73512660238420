import { useGetOperationDateString } from '@modules/encoding/modules/technicalItinerary/hooks/useGetOperationDateString';
import entities from '@shared/entities';
import { UseConfirmDeleteOperationModalLogicProps } from './ConfirmDeleteOperationModal.types';
import { useTranslation } from 'react-i18next';

export const useConfirmDeleteOperationModalLogic = ({
    farmSeasonId,
    fieldCropId,
    data,
}: UseConfirmDeleteOperationModalLogicProps) => {
    const { t } = useTranslation();
    const { getOperationDateString } = useGetOperationDateString();

    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: fieldCropId,
        farmSeasonId,
    });
    const { staticOperationTypeState } = entities.staticOperationType.useState();

    const operationIdToDelete = operationState.getById(data?.operationIdToDelete);
    const sourceOperationType = staticOperationTypeState.getById(operationIdToDelete?.operation_type_id);

    const operationToDeleteName = t(sourceOperationType?.translation_slug ?? '');
    const operationToDeleteDate = getOperationDateString(operationIdToDelete?.operation_date);

    return { operationToDeleteName, operationToDeleteDate };
};
