import { useFormContext, useWatch } from 'react-hook-form';
import {
    OPERATION_DUPLICATION_INPUT_NAMES,
    OperationDuplicationSchemaT,
} from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationForm';

export const useOperationDuplicationFormValues = () => {
    const context = useFormContext();

    if (!context) throw new Error('useDuplicationFormValues must be used inside a FormProvider');

    const fieldCropIds = useWatch<
        OperationDuplicationSchemaT,
        typeof OPERATION_DUPLICATION_INPUT_NAMES.TARGET_FIELD_CROP_IDS
    >({
        name: OPERATION_DUPLICATION_INPUT_NAMES.TARGET_FIELD_CROP_IDS,
    });

    const operationIds = useWatch<OperationDuplicationSchemaT, typeof OPERATION_DUPLICATION_INPUT_NAMES.OPERATION_IDS>({
        name: OPERATION_DUPLICATION_INPUT_NAMES.OPERATION_IDS,
    });

    const hasSameTillageId = useWatch<
        OperationDuplicationSchemaT,
        typeof OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_TILLAGE_ID
    >({
        name: OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_TILLAGE_ID,
    });

    const hasSameOrganic = useWatch<
        OperationDuplicationSchemaT,
        typeof OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_ORGANIC
    >({
        name: OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_ORGANIC,
    });

    const hasSameCompanionCrop = useWatch<
        OperationDuplicationSchemaT,
        typeof OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_COMPANION_CROP_ID
    >({
        name: OPERATION_DUPLICATION_INPUT_NAMES.HAS_SAME_COMPANION_CROP_ID,
    });

    return {
        fieldCropIds,
        operationIds,
        hasSameTillageId,
        hasSameOrganic,
        hasSameCompanionCrop,
    };
};
