export const HARVEST_STATIC_DATA_TYPE = {
    HARVEST_STRAWS_USAGE: 'HARVEST_STRAWS_USAGE',
} as const;

export type HarvestStaticDataTypeT = keyof typeof HARVEST_STATIC_DATA_TYPE;

export const HARVEST_STRAWS_USAGE_SLUGS = {
    HARVEST_STRAWS_USAGE_EXPORTED: 'harvest-straws-usage-exported',
    HARVEST_STRAWS_USAGE_LEFT_ON_FIELD: 'harvest-straws-usage-left-on-field',
} as const;

export type HarvestStrawsUsageSlugsT = (typeof HARVEST_STRAWS_USAGE_SLUGS)[keyof typeof HARVEST_STRAWS_USAGE_SLUGS];

export const HARVEST_STATIC_DATA_SLUGS = {
    ...HARVEST_STRAWS_USAGE_SLUGS,
} as const;

export type HarvestStaticDataSlugsT = (typeof HARVEST_STATIC_DATA_SLUGS)[keyof typeof HARVEST_STATIC_DATA_SLUGS];
