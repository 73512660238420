import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { entityTags } from '@shared/entities/entityApi';

export default createApi({
    tagTypes: [
        'UserCompany',
        'NextFarmSeasonProductPlan',
        'Price',
        'IBAN',
        'Notification',
        'FarmSeasonPaymentStatus',
        'FarmPaymentStatus',
        'onboardingPricing',
        'onboardingLivestock',
        'onboardingFarm',
        ...entityTags,
    ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_BASE_URL,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json, */*');
            return headers;
        },
    }),
    refetchOnMountOrArgChange: false,
    endpoints: () => ({}),
    reducerPath: 'api',
});
