import { Autocomplete, Button } from '@soil-capital/ui-kit/components';
import { CreateMineralFertiliserModal } from '../CreateMineralFertiliserModal/CreateMineralFertiliserModal';
import { useSelectMineralFertiliser } from './useSelectMineralFertiliser';
import { IconPlus, IconSearch } from '@soil-capital/ui-kit/icons';
import { Box } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useSelectMineralFertiliserStyles } from './useSelectMineralFertiliser.style';

export type SelectMineralFertiliserPropsT = {
    physicalState: 'solid' | 'liquid';
    disabled?: boolean;
    selectedFertiliserIds: number[];
    handleFertilisersChange: (fertiliserIds: number[]) => void;
};

export const SelectMineralFertiliser = ({
    physicalState,
    selectedFertiliserIds,
    handleFertilisersChange,
    disabled,
}: SelectMineralFertiliserPropsT) => {
    const { t } = useTranslation();
    const { classes } = useSelectMineralFertiliserStyles();

    const {
        handleAddCustomFertiliser,
        createFertiliserModalProps,
        textCreateFertiliser,
        fertilisersList,
        getFertiliserById,
    } = useSelectMineralFertiliser({
        physicalState,
        handleFertilisersChange,
        selectedFertiliserIds,
    });

    return (
        <>
            <Autocomplete
                className={classes.select}
                noOptionsText={t('constants.no-results')}
                multiple
                value={fertilisersList.length ? selectedFertiliserIds : []}
                onChange={(_, fertiliserIds) => handleFertilisersChange(fertiliserIds)}
                options={fertilisersList.map((fertiliser) => fertiliser.id)}
                getOptionLabel={(fertiliserId) => t(getFertiliserById(fertiliserId)?.translation_slug ?? '')}
                placeholder={t('encoding-technical-itinerary.mineral-fertiliser.question.used-products.placeholder')}
                renderTags={(fertiliserIds) => {
                    return (
                        <Box component="span" display="flex" alignItems="center" gap={1}>
                            {`${fertiliserIds.length} ${t('constants.selected')}`}
                        </Box>
                    );
                }}
                disabled={disabled}
                header={
                    <Button
                        aria-label="add custom fertiliser"
                        onClick={handleAddCustomFertiliser}
                        startIcon={<IconPlus />}
                        variant="text"
                        fullWidth
                        size="small"
                    >
                        {textCreateFertiliser}
                    </Button>
                }
                startIcon={<IconSearch />}
                isOptionEqualToValue={(option, value) => option === value}
            />
            <CreateMineralFertiliserModal {...createFertiliserModalProps} />
        </>
    );
};
