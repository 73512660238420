import { auth } from '@modules/auth';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import { useGetOnboardingPricingQuery, usePostFinalizeOnboardingMutation } from '@modules/onboarding/api/onboardingApi';
import { useGetLatestStaticSeason } from '@shared/hooks/useGetLatestStaticSeason';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useConfirmationLogic = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { updateUserInfos } = auth.useUpdateUserInfos();
    const farmSeasonId = auth.useLatestFarmSeasonId();
    const latestSeason = useGetLatestStaticSeason(farmSeasonId);
    const { data: partnerData, isLoading: isPartnerDataLoading } = useGetPartnerInfoQuery({
        userId: auth.useAuthUser()?.id,
    });
    const { data: pricingData, isLoading: isPricingDataLoading } = useGetOnboardingPricingQuery({
        farmSeasonId,
    });
    const [postFinalizeOnboarding, { isLoading: isPostFinalizeOnboardingLoading }] =
        usePostFinalizeOnboardingMutation();

    const harvestYear = latestSeason?.harvest_year;
    const title =
        partnerData?.is_partner_prepaying || pricingData?.is_deferred_payment
            ? t('pages.onboarding.confirmation.title.prepaid-user')
            : t('pages.onboarding.confirmation.title');
    const ctaAction = async () => {
        if (farmSeasonId) {
            const response = await postFinalizeOnboarding({ farmSeasonId });
            if ('data' in response && response.data) {
                navigate(`/${farmSeasonId}`);
                updateUserInfos({ latestActiveSeason: farmSeasonId, latestFarmSeason: farmSeasonId });
            } else enqueueSnackbar('constants.server-error', { variant: 'error' });
        } else enqueueSnackbar('constants.server-error', { variant: 'error' });
    };

    return {
        t,
        title,
        ctaAction,
        harvestYear,
        isPartnerDataLoading,
        isPricingDataLoading,
        isPostFinalizeOnboardingLoading,
    };
};
