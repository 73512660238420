import { useSelectedFieldFilterStyles } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/SelectedFieldFilter/SelectedFieldFilter.style';
import { IconFilter, IconCross } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { Button } from '@soil-capital/ui-kit';

export const SelectedFieldFilter = ({ value, onDelete }: { value: string; onDelete: () => void }) => {
    const { classes } = useSelectedFieldFilterStyles();

    return (
        <div className={classes.container}>
            <IconFilter />
            <Typography variant="body">{value}</Typography>
            <Button className={classes.button} onClick={onDelete}>
                <IconCross className={classes.cross} />
            </Button>
        </div>
    );
};
