import { useForm } from '@shared/hooks';
import * as z from 'zod';
import { useUpdateFarmSeasonField } from '@modules/encoding/shared/hooks/useUpdateFarmSeasonField';
import { FieldT } from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { calculateAreaProperties } from '@shared/utils/calculateAreaProperties';

export const PAC_FIELD_UPDATE_INPUT_NAMES = {
    FIELD: 'field',
} as const;

export const usePacFieldUpdateForm = ({
    field,
    onFieldUpdated,
}: {
    field: FieldT | null;
    onFieldUpdated?: (fieldId: number) => void;
}) => {
    const area = field?.original_area ?? 0;
    const { t } = useTranslation();
    const { treshold, maxArea, minArea } = calculateAreaProperties(area);

    const schema = z.object({
        [PAC_FIELD_UPDATE_INPUT_NAMES.FIELD]: z
            .object({
                id: z.number(),
                area: z
                    .number()
                    .min(
                        Number(minArea.toFixed(2)),
                        t('encoding.rotation.field-creation-modal.error-under-treshold', { treshold }),
                    )
                    .max(
                        Number(maxArea.toFixed(2)),
                        t('encoding.rotation.field-creation-modal.error-over-treshold', { treshold }),
                    ),
                name: z.string(),
            })
            .nullable(),
    });

    const { updateField, isLoading } = useUpdateFarmSeasonField();

    const defaultValues = {
        [PAC_FIELD_UPDATE_INPUT_NAMES.FIELD]: field,
    };

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const {
        formState: { errors, isValid },
    } = methods;

    const onSubmit = methods.handleSubmit(async (data) => {
        if (!data.field) throw new Error('Field is required');
        await updateField(data.field);
        onFieldUpdated?.(data.field.id);
    });

    return {
        methods,
        onSubmit,
        isLoading,
        errors,
        isValid,
    };
};
