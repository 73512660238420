import { SkeletonInputRow } from '@shared/components/form/SkeletonInputRow/SkeletonInputRow';
import { Skeleton, Box } from '@soil-capital/ui-kit/material-core';

export const OrganicFertiliserFormSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" gap={2} data-testid="organic-fertiliser-form-skeleton">
            <SkeletonInputRow size="lg" variant="radio" />
            <Skeleton variant="rounded" width="100%" height={100} />
            <Skeleton variant="rounded" width="100%" height={100} />
        </Box>
    );
};
