import { makeStyles } from '@soil-capital/ui-kit/style';

export const useRadioGroupStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
        flexWrap: 'nowrap',
    },
}));
