import cropApi from './crop.api';
import { cropCacheAdapter } from './crop.cache';
import { CROP_CREATION_SOURCE_SLUGS, CropEndpointParamsT, CropT } from './crop.types';
import fieldCrop from '../fieldCrop';
import createDefaultState from '../createDefaultState';
import staticCrop from '../staticCrop';
import { useCallback } from 'react';

export default ({ farmSeasonId }: CropEndpointParamsT, options?: { skip?: boolean }) => {
    const defaultState = createDefaultState(cropCacheAdapter, () =>
        cropApi.useGet({ farmSeasonId }, { skip: !farmSeasonId || options?.skip }),
    );

    const useGetCropsCountForField = () => {
        const { fieldCropState } = fieldCrop.useState({ farmSeasonId });
        return {
            getCropsCountForField: ({ fieldId }: { fieldId: number }) =>
                fieldCropState.getByFieldId({ fieldId }).length,
        };
    };

    const useHarvestedCrops = useCallback(
        ({ countryId }: { countryId: number | undefined }) => {
            const { staticCropState } = staticCrop.useState({ countryId });
            // const nonCultivatedCropsSlugs = [NON_CULTIVATED_CROP_SLUGS.FALLOW];
            const harvestedCrops = defaultState.list.filter((crop) => {
                const staticCrop = staticCropState.getById(crop.crop_id);

                return !staticCrop?.is_permanent;
                // FP-5864 - Exclude non-cultivated crops from the list (commented because we need to also handle the progress in parallel)
                // && !nonCultivatedCropsSlugs.find((nonCultivatedCropSlug) => staticCrop?.slug === nonCultivatedCropSlug)
            });

            return { harvestedCrops, harvestedCropsLoading: staticCropState.isLoading };
        },
        [defaultState.list],
    );

    const useGetCropsAssignedToAField = useCallback(() => {
        const { fieldCropState } = fieldCrop.useState({ farmSeasonId });

        const getCropsAssignedToAField = useCallback(
            (fieldId: number) => {
                const fsfcForField = fieldCropState.getByFieldId({ fieldId });
                return fsfcForField
                    .map((fsfc) => defaultState.getById(fsfc.farm_season_crop_id))
                    .filter((fsc): fsc is CropT => !!fsc);
            },
            [fieldCropState],
        );

        const isCropAssignedToAtLeastOneField = (cropId: number) => {
            const fsfcForCrop = fieldCropState.list.filter((fsfc) => fsfc.farm_season_crop_id === cropId);
            return fsfcForCrop.length > 0;
        };

        return { getCropsAssignedToAField, isCropAssignedToAtLeastOneField };
    }, [defaultState, farmSeasonId]);

    const getImportedCrops = () => {
        return defaultState.list.filter((crop) => crop.creation_source === CROP_CREATION_SOURCE_SLUGS.PAC);
    };

    return {
        cropState: {
            ...defaultState,
            useGetCropsCountForField,
            useHarvestedCrops,
            useGetCropsAssignedToAField,
            getImportedCrops,
        },
    };
};
