import { CreateLivestockT, FarmSeasonLivestockT } from '@modules/onboarding/api/onboarding.type';
import { useForm } from '@shared/hooks';
import { ForwardedRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { ChildFormRef } from '../useLivestockFormsLogic';
import { SelectChangeEvent } from '@soil-capital/ui-kit/material-core';

export const LIVESTOCK = {
    TYPE: 'livestock_id',
    QUANTITY: 'quantity',
} as const;

export const useLivestockFormLogic = ({
    getData,
    ref,
    defaultFormValues,
    onLivestockChange,
}: {
    getData: (data: CreateLivestockT) => void;
    ref: ForwardedRef<ChildFormRef>;
    defaultFormValues?: FarmSeasonLivestockT;
    onLivestockChange: (value: number) => void;
}) => {
    const { t } = useTranslation();
    const defaultValues = {
        [LIVESTOCK.TYPE]: defaultFormValues?.livestock_id ? defaultFormValues?.livestock_id : 0,
        [LIVESTOCK.QUANTITY]: defaultFormValues?.quantity ? defaultFormValues.quantity : undefined,
    };
    const schema = z.object({
        [LIVESTOCK.TYPE]: z.number().min(1, t('pages.onboarding.eligibility.livestock.card.type-input.error')),
        [LIVESTOCK.QUANTITY]: z.number().min(1, t('pages.onboarding.eligibility.livestock.card.quantity-input.error')),
    });
    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });
    const {
        handleSubmit,
        formState: { isValid, errors },
        watch,
        setValue,
    } = methods;
    const selectedLivestockType = watch(LIVESTOCK.TYPE);
    const livestockQuantity = methods.watch(LIVESTOCK.QUANTITY);
    const onSubmit = handleSubmit(async (data) => {
        getData(data);
    });

    useImperativeHandle(ref, () => ({
        getChildFormValues: async () => {
            await onSubmit();
        },
        changeLivestockType: () => handleLivestockTypeChange,
    }));
    const handleLivestockTypeChange = (event: SelectChangeEvent<unknown>) => {
        const newValue = event.target.value;
        onLivestockChange(Number(newValue));
        methods.setValue(LIVESTOCK.TYPE, Number(newValue));
    };

    return {
        defaultFormValues,
        livestockQuantity,
        selectedLivestockType,
        methods,
        onSubmit,
        isValid,
        errors,
        handleLivestockTypeChange,
        setValue,
        watch,
    };
};
