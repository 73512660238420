import { Button } from '@soil-capital/ui-kit/components';
import { useFallbackComponentStyles } from './useFallbackComponent.style';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';

export default () => {
    const { t } = useTranslation();
    const { classes } = useFallbackComponentStyles();

    return (
        <div className={classes.container}>
            <Typography variant="body">{t('constants.rendering-error')}</Typography>
            <Button variant="contained" onClick={() => window.location.reload()}>
                {t('constants.reload')}
            </Button>
        </div>
    );
};
