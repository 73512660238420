import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';

export const useIsEncodingDoneGuard = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });

    const isLoading = progressState.isLoading;

    if (isLoading) return undefined;
    if (progressState.list?.length === 0) return false;

    return progressState.list.every((step) => step.progress_total === step.progress_done && step.progress_total > 0);
};
export const IsEncodingDoneGuard = (props: GuardT) => {
    const passed = useIsEncodingDoneGuard();

    return <BaseGuard passed={passed} {...props} />;
};
