import { useForm } from '@shared/hooks';
import { useOrganicFertiliserFormOptions } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/hooks/useOrganicFertiliserFormOptions';
import { useEffect } from 'react';
import { useOperationRouteParams } from '../../../hooks/useOperationRouteParams';
import { getOperationFormApi } from '../../../api/getOperationFormApi';
import {
    OrganicFertilisationFormSchemaT,
    organicFertilisationFormSchema,
} from '../../../schema/organicFertilisationSchema';
import { useOperationFormDefaultValues } from '../../../hooks/useOperationFormDefaultValues';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';

export const useOrganicFertiliserForm = () => {
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();
    const {
        formApiData,
        computeApiDataToMatchSchema,
        isFormLoading: isLoadingDefaultValues,
        refetchFormData,
    } = useOperationFormDefaultValues('organic-fertilisation');

    // Form options
    const { isLoading: isLoadingOptions, ...options } = useOrganicFertiliserFormOptions();

    const isFormLoading = isLoadingDefaultValues || isLoadingOptions;

    // Mutations
    const [updateForm] = getOperationFormApi('organic-fertilisation').useUpdate();

    const methods = useForm({
        schema: organicFertilisationFormSchema.schema,
        displaySnackbarOnSchemaError: true,
    });

    const { handleSubmit, reset, getValues } = methods;

    // Reset form values when API data update
    useEffect(() => {
        if (formApiData) reset(computeApiDataToMatchSchema(formApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData]);

    // Form callbacks
    const onSubmit = useStableDebounce(
        handleSubmit(async (formData: OrganicFertilisationFormSchemaT) => {
            await updateForm({
                farmSeasonId: seasonId,
                fieldCropId: fieldCropId,
                operationId: operationId,
                body: formData,
            });
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    return {
        methods,
        onSubmit,
        isFormLoading,
        options: {
            ...options,
        },
        refetchFormData,
        getFormValues: getValues,
    };
};
