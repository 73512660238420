import { makeStyles } from '@soil-capital/ui-kit/style';
import fieldBg from '@assets/images/field-background.jpg';

export const usePaymentPageLayoutStyle = makeStyles()((theme) => ({
    container: {
        minWidth: '1200px',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
        height: '100vh',
        maxHeight: '100vh',
        width: '100%',
        zIndex: 10,
        overflowX: 'hidden',
    },
    contentSection: {
        borderRadius: theme.shape.borderRadiusL,
        backgroundColor: theme.palette.primaryScale[50],
        marginLeft: theme.spacing(3),
        marginBottom: theme.spacing(9),
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
    },
    fieldBg: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        height: '50vh',
        zIndex: 0,
        backgroundImage: `url(${fieldBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        borderBottomLeftRadius: theme.shape.borderRadiusL,
        borderBottomRightRadius: theme.shape.borderRadiusL,
        marginBottom: theme.spacing(3),
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
        pointerEvents: 'none',
        '&::after': {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            content: '""',
            borderBottom: `${theme.spacing(3)} solid ${theme.palette.common.white}`,
        },
    },
    loaderContainer: {
        marginTop: theme.spacing(3),
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(3),
        boxShadow: '0px 0px 32px 0px rgba(50, 63, 69, 0.08)',
        borderRadius: theme.shape.borderRadiusL,
        zIndex: 1,
        width: '728px',
        flexDirection: 'column',
        alignItems: 'center',
    },
}));
