export const HISTORY_PROGRESS_ENUM = {
    ROTATION: 'rotation',
    TECHNICAL_ITINERARY: 'technicalItinerary',
} as const;

export type HistoryProgressKeysT = (typeof HISTORY_PROGRESS_ENUM)[keyof typeof HISTORY_PROGRESS_ENUM];

export type HistoryFarmSeasonT = {
    id: number;
    farm_id: number;
    baseline_farm_season_id: number;
    carbon_programme_year: number;
    harvest_year: number;
    progress: {
        [HISTORY_PROGRESS_ENUM.ROTATION]: {
            total: number;
            done: number;
        };
        [HISTORY_PROGRESS_ENUM.TECHNICAL_ITINERARY]: {
            total: number;
            done: number;
        };
    };
};

export type GetHistoricalFarmSeasonsParamsT = {
    baselineFarmSeasonId: number;
};

export const HISTORY_ROTATION_SOURCE_ENUM = {
    NO_FIELDS: 'no_fields',
    MANUAL: 'manual',
    IMPORT: 'import',
} as const;

export type HistoryRotationSourceT = {
    source: (typeof HISTORY_ROTATION_SOURCE_ENUM)[keyof typeof HISTORY_ROTATION_SOURCE_ENUM];
};

export type HistoryDuplicationDataT = {
    id: number;
    source_farm_season_id: number;
    target_farm_season_id: number;
    source_farm_season_crop_id: number;
    target_farm_season_crop_id: number;
    source_farm_season_operation_id: number | null;
    target_farm_season_operation_id: number | null;
    target_farm_season_field_crop_id: number;
};
