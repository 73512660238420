import { Button } from '@soil-capital/ui-kit/components';
import { IconPolygon } from '@soil-capital/ui-kit/icons';
import { useDraw } from '../../../hook/useDraw';
import { useTranslation } from 'react-i18next';
import { IconClose } from '@soil-capital/ui-kit/icons';
import { IconCheckboxChecked } from '@soil-capital/ui-kit/icons';
import { DrawnFieldsCreationModal } from '@modules/encoding/modules/rotation/components/DrawnFieldCreationModal/DrawnFieldCreationModal';
import useMapDraw from './useMapDraw';

export const MapDraw = ({ readOnly }: { readOnly: boolean }) => {
    const { draw, drawnPolygon, stopDrawing, drawMode, polygonArea, editMode } = useDraw();
    const { drawnFieldCreationModalOpen, onDrawnFieldCreationModalClose, onDrawnFieldCreationModalOpen } = useMapDraw();
    const { t } = useTranslation();

    return (
        <>
            {drawMode ? (
                <>
                    <Button
                        startIcon={<IconClose sx={{ width: '20px', height: '20px' }} />}
                        onClick={() => stopDrawing(true)}
                    >
                        {t('constants.cancel')}
                    </Button>
                    <Button
                        startIcon={<IconCheckboxChecked sx={{ width: '20px', height: '20px' }} />}
                        disabled={!drawnPolygon}
                        variant="contained"
                        onClick={onDrawnFieldCreationModalOpen}
                    >
                        {t('constants.save')}
                    </Button>
                </>
            ) : !editMode ? (
                <Button
                    startIcon={<IconPolygon color="primary" />}
                    sx={{ whiteSpace: 'nowrap' }}
                    onClick={draw}
                    disabled={readOnly}
                >
                    {t('encoding-rotation.map.draw-field')}
                </Button>
            ) : null}
            {drawnPolygon && polygonArea && (
                <DrawnFieldsCreationModal
                    polygon={drawnPolygon.geometry}
                    area={polygonArea}
                    open={drawnFieldCreationModalOpen}
                    onClose={() => {
                        onDrawnFieldCreationModalClose();
                        stopDrawing();
                    }}
                />
            )}
        </>
    );
};
