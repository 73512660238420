import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetFarmPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';
import { useToggle } from '@shared/hooks/useToggle';

export const usePaymentCTAModalLogic = (isPartnerPrepaying: boolean) => {
    const {
        open: paymentCTAModalOpen,
        handleOpen: onPaymentCTAModalOpen,
        handleClose: onPaymentCTAModalClose,
    } = useToggle();
    const { currentSeason } = useCurrentSeason();
    const { data: farmPaymentStatus } = useGetFarmPaymentStatusQuery({ farmId: currentSeason?.farm_id });
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const hasToPayForBaseline = !!(
        farmPaymentStatus?.status === 'deferred' &&
        activeSeason?.has_results_published &&
        !isPartnerPrepaying
    );
    const hasToRenew = !!(
        farmPaymentStatus?.status === 'to_be_renewed' &&
        activeSeason?.has_results_published &&
        !isPartnerPrepaying
    );
    const showModal = hasToPayForBaseline || hasToRenew;
    const renewal = hasToRenew;

    return {
        paymentCTAModalOpen,
        onPaymentCTAModalOpen,
        onPaymentCTAModalClose,
        showModal,
        renewal,
    };
};
