import { useFormContext } from 'react-hook-form';
import { OPERATION_DUPLICATION_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationForm';
import { MouseEvent, useState } from 'react';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { useTranslation } from 'react-i18next';
import { useOperationDuplicationFormValues } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationFormValues';

export const useFieldSelectionLogic = ({
    sourceField,
    targetFields,
    onConfirmSelection,
    onFieldSelected,
}: {
    sourceField: DuplicationFieldT;
    targetFields: DuplicationFieldT[];
    onConfirmSelection?: () => void;
    onFieldSelected?: () => void;
}) => {
    const { t } = useTranslation();
    const { fieldCropIds, operationIds } = useOperationDuplicationFormValues();

    const [selectedField, setSelectedField] = useState<DuplicationFieldT | undefined>(undefined);
    const { setValue } = useFormContext();

    const selectedSourceOperationTypes = sourceField.operationsByOperationType
        .filter((ot) => ot.operations.every((op) => operationIds.includes(op.id)))
        .map((ot) => ot.id);

    const selectedFieldOperationTypes =
        selectedField?.operationsByOperationType
            .filter((ot) => selectedSourceOperationTypes.includes(ot.id))
            .map((ot) => t(ot.translation_slug)) || [];

    const handleFieldSelection = (e: MouseEvent<HTMLElement>, option: DuplicationFieldT, isSelected: boolean) => {
        const fieldCrop = targetFields.find((fc) => fc.id === option.id);
        if (!isSelected) {
            setSelectedField(fieldCrop);

            if (selectedFieldOperationTypes.length > 0) {
                e.preventDefault();
                onFieldSelected?.();
            }
        } else setSelectedField(undefined);
    };

    const handleConfirmSelection = () => {
        setValue(OPERATION_DUPLICATION_INPUT_NAMES.TARGET_FIELD_CROP_IDS, [
            ...fieldCropIds,
            selectedField?.fieldCrop.id,
        ]);
        onConfirmSelection?.();
    };

    return { selectedField, selectedFieldOperationTypes, handleFieldSelection, handleConfirmSelection };
};
