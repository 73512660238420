import { Grid, Skeleton, useTheme } from '@soil-capital/ui-kit/material-core';

export const EligibilityFormSkeleton = () => {
    const theme = useTheme();

    return (
        <>
            <Skeleton variant="text" />
            <Grid display="grid" gridTemplateColumns="1fr 1fr" gap={theme.spacing(3)}>
                <Grid>
                    <Skeleton variant="text" width="150px" />
                    <Skeleton height="56px" />
                </Grid>
                <Grid>
                    <Skeleton variant="text" width="150px" />
                    <Skeleton height="56px" />
                </Grid>
            </Grid>
            <Grid>
                <Skeleton variant="text" width="150px" />
                <Skeleton height="56px" />
            </Grid>
            <Grid display="grid" gap={theme.spacing(3)} gridTemplateColumns="1fr 1fr 1fr">
                <Grid>
                    <Skeleton variant="text" width="150px" />
                    <Skeleton height="56px" />
                </Grid>
                <Grid>
                    <Skeleton variant="text" width="150px" />
                    <Skeleton height="56px" />
                </Grid>
                <Grid>
                    <Skeleton variant="text" width="150px" />
                    <Skeleton height="56px" />
                </Grid>
            </Grid>
            <Grid>
                <Skeleton variant="text" width="150px" />
                <Skeleton height="56px" />
            </Grid>
            <Grid display="flex" gap={theme.spacing(1)}>
                <Skeleton width="22px" height="22px" />
                <Grid width="100%">
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="40%" />
                </Grid>
            </Grid>
            <Grid display="flex" gap={theme.spacing(1)}>
                <Skeleton width="22px" height="22px" />
                <Grid width="100%">
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="60%" />
                </Grid>
            </Grid>
        </>
    );
};
