import { useCallback } from 'react';
import createStaticEntity from '../createStaticEntity';
import { StaticLanguageT } from './staticLanguage.types';

export default createStaticEntity<StaticLanguageT>()({
    entityName: 'staticLanguage',
    url: () => `/v3/static/languages`,
    useGetCustomState: (defaultState) => {
        const getByIsoCode = useCallback(
            (isoCode: string) => defaultState.list.find((language) => language.iso_code === isoCode),
            [defaultState.list],
        );
        return { ...defaultState, getByIsoCode };
    },
});
