import { Modal } from '@soil-capital/ui-kit/components';
import { useFullscreenModalStyles } from '@shared/components/FullscreenModal/FullscreenModal.style';
import { ReactNode } from 'react';

export type FullscreenModalProps = {
    open: boolean;
    onClose: () => void;
    title: ReactNode;
    leftPanel: ReactNode;
    rightPanel: ReactNode;
    extraSection?: ReactNode;
    headerButton: ReactNode;
};

export const FullscreenModal = ({
    open,
    onClose,
    title,
    leftPanel,
    rightPanel,
    extraSection,
    headerButton,
}: FullscreenModalProps) => {
    const { classes } = useFullscreenModalStyles();

    return (
        <Modal
            open={open}
            size={null}
            onClose={onClose}
            className={classes.modal}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{title}</Modal.HeaderTitle>
                    {headerButton}
                </Modal.Header>
            }
            body={
                <div className={classes.body}>
                    {leftPanel}
                    {rightPanel}
                    {extraSection}
                </div>
            }
        />
    );
};
