import { ConditionalTooltip, Input } from '@soil-capital/ui-kit/components';
import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { IBAN, useUpdateIbanFormLogic } from './useUpdateIbanFormLogic';
import { FormProvider } from 'react-hook-form';
import { CompanyT } from '@modules/billing/api/userCompany.types';
import { useEffect } from 'react';

export type UpdateIbanFormProps = {
    refetch: () => void;
    setOnConfirm: (confirmFn: () => void) => void;
    userCompany?: CompanyT;
    setIsSubmitting?: (isSubmitting: boolean) => void;
    nextAction: () => void;
};

export const UpdateIbanForm = ({
    refetch,
    setOnConfirm,
    userCompany,
    setIsSubmitting,
    nextAction,
}: UpdateIbanFormProps) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { methods, canEditIban, errors, submitForm } = useUpdateIbanFormLogic(
        refetch,
        t,
        nextAction,
        userCompany,
        setIsSubmitting,
    );

    useEffect(() => {
        setOnConfirm(submitForm);
    }, [setOnConfirm, submitForm]);

    return (
        <FormProvider {...methods}>
            <form>
                <Grid
                    display="flex"
                    flexDirection="column"
                    paddingX={theme.spacing(3)}
                    gap={theme.spacing(1)}
                    paddingBottom="200px"
                >
                    <Grid display="flex" gap={theme.spacing(1)}>
                        <Typography variant="caption">
                            {t('billing.subscription.billing-form.IBAN').toUpperCase()}
                        </Typography>
                        <ConditionalTooltip text={t('billing.subscription.billing-form.IBAN-tooltip')} />
                    </Grid>
                    <Input
                        label=""
                        placeholder={t('billing.subscription.billing-form.IBAN.placeholder')}
                        inputProps={{
                            ...methods.register(IBAN),
                        }}
                        type="text"
                        disabled={!canEditIban}
                    />
                    {errors[IBAN]?.message && (
                        <Typography variant="caption" color="error">
                            {t(`${errors[IBAN]?.message}`)}
                        </Typography>
                    )}
                    <Typography variant="subcaption" color={theme.palette.darkScale[700]}>
                        {t('billing.subscription.billing-form.IBAN-description')}
                    </Typography>
                </Grid>
            </form>
        </FormProvider>
    );
};
