import { makeStyles } from '@soil-capital/ui-kit/style';

export const useNextStepsStyle = makeStyles()((theme) => ({
    nextSteps: {
        width: '100%',
        display: 'flex',
        padding: theme.spacing(2),
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(2),
        alignSelf: 'stretch',
        borderRadius: theme.shape.borderRadiusM,
        backgroundColor: theme.palette.primaryScale[800],
        boxShadow: `0 0 16px 8px ${theme.palette.darkScale[900]}`,
    },
    contentFrame: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(2),
        flex: '1 0 0',
        alignSelf: 'stretch',
    },
    agroSection: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        alignSelf: 'stretch',
    },
    profilePicture: {
        width: '32px',
        height: '32px',
        borderRadius: '50%',
        backgroundImage: 'url("/assets/images/agro-max-avatar.jpeg")',
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    },
    agroText: {
        display: 'flex',
        flexDirection: 'column',
    },
    resultsSection: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    resultsIcon: {
        width: '32px',
        height: '32px',
        padding: theme.spacing(0.5),
        '>svg': {
            color: theme.palette.darkScale[50],
        },
    },
    certificatesSection: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    certificatesIcon: {
        width: '32px',
        height: '32px',
        padding: theme.spacing(0.5),
        '>svg': {
            color: theme.palette.darkScale[50],
        },
    },
}));
