import { ReactNode, useContext } from 'react';
import { createPortal } from 'react-dom';
import { MapContext } from './MapProvider';

/**
 * render children in the <MapRoot/> component. \
 */
export const MapPortal = ({ children }: { children: ReactNode }) => {
    const mapContext = useContext(MapContext);
    if (!mapContext) {
        throw new Error('MapContext is null');
    }

    if (!mapContext.mapContainerRef.current) {
        return null;
    }

    return createPortal(children, mapContext.mapContainerRef.current);
};
