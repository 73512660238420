import { FormProvider } from 'react-hook-form';
import { useDestructionFormStyles } from './useDestructionForm.style';
import { useDestructionForm } from './useDestructionForm.logic';
import { DestructionFormSkeleton } from './DestructionFormSkeleton';
import { DestructionMethodInput } from '../DestructionMethodInput/DestructionMethodInput';
import { DestructionProductInput } from '../DestructionProductInput/DestructionProductInput';
import { DestructionProductQuantityInput } from '../DestructionProductQuantityInput/DestructionProductQuantityInput';
import { DestructionMachineryInput } from '../DestructionMachineryInput/DestructionMachineryInput';
import { DestructionDepthInput } from '../DestructionDepthInput/DestructionDepthInput';
import { DestructionCompanionRemovedInput } from '../DestructionCompanionRemovedInput/DestructionCompanionRemovedInput';
import { DestructionSprayerWidthInput } from '../DestructionSprayerWidthInput/DestructionSprayerWidthInput';
import { DIVIDER_TEXT_ALIGNMENT, DividerText } from '@shared/components/DividerText/DividerText';
import { useTranslation } from 'react-i18next';

export const DestructionForm = () => {
    const { t } = useTranslation();
    const { classes, cx } = useDestructionFormStyles();

    const {
        methods,
        hasCompanionCrop,
        options,
        isFormLoading,
        onSubmit,
        changeMachineryRelatedInputs,
        changeMethodRelatedInputs,
    } = useDestructionForm();

    const { machineries, herbicides, destructionMethods } = options;

    return (
        <>
            {isFormLoading ? <DestructionFormSkeleton /> : null}

            <FormProvider {...methods}>
                <form onSubmit={onSubmit} className={cx(classes.form, isFormLoading && classes.hiddenForm)}>
                    <DestructionMethodInput
                        destructionMethods={destructionMethods}
                        onChange={() => {
                            changeMethodRelatedInputs();
                            onSubmit();
                        }}
                    />
                    <DestructionProductInput destructionProducts={herbicides} onChange={onSubmit} />
                    <DestructionProductQuantityInput onChange={onSubmit} />
                    <DestructionMachineryInput
                        machineries={machineries}
                        onChange={() => {
                            changeMachineryRelatedInputs();
                            onSubmit();
                        }}
                    />
                    <DestructionDepthInput onChange={onSubmit} />
                    <DestructionSprayerWidthInput onChange={onSubmit} />

                    {hasCompanionCrop && (
                        <>
                            <DividerText text={t('constants.optional')} textAlign={DIVIDER_TEXT_ALIGNMENT.LEFT} />
                            <DestructionCompanionRemovedInput onChange={onSubmit} />
                        </>
                    )}
                </form>
            </FormProvider>
        </>
    );
};
