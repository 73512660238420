import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import {
    FormAutoCompleteOptionT,
    FormAutocomplete,
    FormAutocompleteProps,
} from '@shared/components/FormAutocomplete/FormAutocomplete';

type DestructionMethodInputPropsT = {
    destructionMethods: FormAutoCompleteOptionT[];
    onChange: FormAutocompleteProps<FormAutoCompleteOptionT, false>['onChange'];
};

export const DestructionMethodInput = ({ destructionMethods, onChange }: DestructionMethodInputPropsT) => {
    const { t } = useTranslation();

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.destruction.question.destruction-method')}
                tooltipTitleKey="encoding-technical-itinerary.destruction.question.destruction-method.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.destruction.question.destruction-method.tooltip.text"
            />
            <FormAutocomplete
                name={DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_METHOD_STATIC_DATA_ID}
                options={destructionMethods}
                placeholder={t('encoding-technical-itinerary.destruction.question.destruction-method.placeholder')}
                onChange={onChange}
                multiple={false}
            />
        </FormRow>
    );
};
