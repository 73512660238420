import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import { MapContext } from '@shared/map/utils/MapProvider';
import mapboxgl, { EventData, MapLayerEventType, MapMouseEvent, MapboxGeoJSONFeature, PointLike } from 'mapbox-gl';
import { useContext, useEffect, useRef } from 'react';
import field from '@shared/entities/field';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { intersectPolygon } from '../utils/polygonHelper';
import { Polygon } from '@turf/helpers';

export const MAP_OPENDATA_CONFIG_SLUGS = {
    SOURCE_GLOBAL_2020: 'open-data-parcels',
    SOURCE_FLANDERS_2022: 'open-data-parcels-flanders-2022',
    SOURCE_WALLONIA_2022: 'open-data-parcels-wallonia-2022',
    SOURCE_FRANCE_2022: 'open-data-parcels-france-2022',
    SOURCE_LAYER: 'parcels',
    LAYER_FILL: 'opendata-fill',
    LAYER_LINES: 'opendata-lines',
} as const;

type useOpenDataPropsT = {
    onClick: (feature: MapboxGeoJSONFeature) => void;
    hasOpendata: boolean;
};

const initOpendataSources = (isoCode?: string) => {
    if (isoCode === 'BE') {
        return [MAP_OPENDATA_CONFIG_SLUGS.SOURCE_FLANDERS_2022, MAP_OPENDATA_CONFIG_SLUGS.SOURCE_WALLONIA_2022];
    }
    if (isoCode === 'FR') {
        return [MAP_OPENDATA_CONFIG_SLUGS.SOURCE_FRANCE_2022];
    }
    return [];
};

export const useOpendata = ({ onClick, hasOpendata }: useOpenDataPropsT) => {
    const mapContext = useContext(MapContext);

    if (!mapContext) throw new Error('useOpendata should be used inside a MapProvider');

    let hoveredPolygonId: string | null | undefined | number = null;

    const { map } = mapContext;

    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldState } = field.useState({ farmSeasonId: currentSeasonId });

    const polygonsRef = useRef<Polygon[]>([]);

    const { currentFarm, currentFarmLoading } = useCurrentFarm();

    const isActive = hasOpendata && !!map;

    const isActiveRef = useRef(isActive);

    const isLoading = currentFarmLoading;

    const sources = [...initOpendataSources(currentFarm?.country?.iso_code ?? '')];

    const getLayerLineId = (source: string) => `${MAP_OPENDATA_CONFIG_SLUGS.LAYER_LINES}-${source}`;

    const getLayerFillId = (source: string) => `${MAP_OPENDATA_CONFIG_SLUGS.LAYER_FILL}-${source}`;

    const mapOnClick = (e: MapMouseEvent & EventData) => {
        if (!isActiveRef.current) return;

        const bbox: [PointLike, PointLike] = [
            [e.point.x - 5, e.point.y - 5],
            [e.point.x + 5, e.point.y + 5],
        ];
        // Find features intersecting the bounding box.
        const selectedFeatures = map?.queryRenderedFeatures(bbox, {
            layers: [
                ...(sources?.map((source) => {
                    return getLayerFillId(source);
                }) || []),
            ],
        });

        selectedFeatures && onClick(selectedFeatures[0]);
    };

    const clear = (map: mapboxgl.Map, loadedSources: string[]) => {
        map.off('click', mapOnClick);

        loadedSources?.forEach((source) => {
            const layerFillId = getLayerFillId(source);

            map.off('mousemove', layerFillId, onMouseMove(source));
            map.off('mouseleave', layerFillId, onMouseLeave(source));
        });
    };

    const addMapboxSources = (map: mapboxgl.Map, loadedSources: string[]) => {
        loadedSources?.forEach((source) => {
            if (!map.getSource(source)) {
                map?.addSource(source, {
                    type: 'vector',
                    url: `${process.env.REACT_APP_MAPBOX_OPENDATA_BASE_URL}.${source}`,
                });
            }
        });
    };

    const addMapboxLayers = (map: mapboxgl.Map, loadedSources: string[]) => {
        loadedSources?.forEach((source) => {
            const layerFillId = getLayerFillId(source);
            const layerLineId = getLayerLineId(source);

            if (!map.getLayer(layerFillId)) {
                map.addLayer({
                    id: layerFillId,
                    type: 'fill',
                    source: source,
                    'source-layer': MAP_OPENDATA_CONFIG_SLUGS.SOURCE_LAYER,
                    layout: {},
                    paint: {
                        'fill-color': 'white',
                        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.3, 0],
                    },
                });
            }

            if (!map.getLayer(layerLineId)) {
                map.addLayer({
                    id: layerLineId,
                    type: 'line',
                    source: source,
                    'source-layer': MAP_OPENDATA_CONFIG_SLUGS.SOURCE_LAYER,
                    layout: {},
                    paint: {
                        'line-color': 'white',
                        'line-width': 2,
                        'line-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 1, 0],
                    },
                });
            }
        });
    };

    const onMouseMove = (source: string) => (e: MapLayerEventType['mousemove'] & EventData) => {
        if (!map) return;

        if (!isActiveRef.current) return;

        if (e.features && e.features.length > 0) {
            const feature = e.features[0];

            if (hoveredPolygonId !== feature.id) {
                if (hoveredPolygonId !== null) {
                    map.setFeatureState(
                        {
                            source: source,
                            sourceLayer: MAP_OPENDATA_CONFIG_SLUGS.SOURCE_LAYER,
                            id: hoveredPolygonId,
                        },
                        { hover: false },
                    );
                }

                const featureGeometry = feature.geometry as Polygon;
                if (featureGeometry) {
                    const isIntersectingExistingFields = polygonsRef.current.some((polygon) =>
                        intersectPolygon(featureGeometry, [polygon]),
                    );
                    if (isIntersectingExistingFields) {
                        return;
                    }
                }

                hoveredPolygonId = feature.id;

                map.getCanvas().style.cursor = 'pointer';

                map.setFeatureState(
                    {
                        source: source,
                        sourceLayer: MAP_OPENDATA_CONFIG_SLUGS.SOURCE_LAYER,
                        id: hoveredPolygonId,
                    },
                    { hover: true },
                );
            }
        }
    };

    const onMouseLeave = (source: string) => () => {
        if (!map) return;

        const hoveredIds = [
            ...new Set(
                map
                    .queryRenderedFeatures()
                    .filter(
                        (f) =>
                            f.layer['source-layer'] === 'parcels' && f.state.hover === true && f.layer.type === 'fill',
                    )
                    .map((f) => f.id),
            ),
            hoveredPolygonId,
        ].filter((id) => id !== null && id !== undefined) as number[];

        if (hoveredIds.length > 0) {
            map.getCanvas().style.cursor = 'default';

            hoveredIds.forEach((id) => {
                map.setFeatureState(
                    {
                        source: source,
                        sourceLayer: MAP_OPENDATA_CONFIG_SLUGS.SOURCE_LAYER,
                        id,
                    },
                    { hover: false },
                );
            });
        }
        hoveredPolygonId = null;
    };

    const initMapData = (loadedSources: string[]) => {
        if (!isActive) return;

        addMapboxSources(map, loadedSources);
        addMapboxLayers(map, loadedSources);

        loadedSources?.forEach((source) => {
            const layerFillId = getLayerFillId(source);

            map.on('mousemove', layerFillId, onMouseMove(source));
            map.on('mouseleave', layerFillId, onMouseLeave(source));
        });

        map.on('click', mapOnClick);
    };

    const loadMap = (loadedSources: string[]) => {
        if (!map) return;

        map.on('remove', (e) => {
            clear(e.target, loadedSources);
        });

        if (map.loaded() && map.isStyleLoaded()) {
            initMapData(loadedSources);
        } else {
            map.on('load', () => {
                initMapData(loadedSources);
            });
        }
    };

    useEffect(() => {
        isActiveRef.current = isActive;
        if (isActive) {
            loadMap(sources);
        }

        return () => {
            if (map) {
                clear(map, sources);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, isActive, sources]);

    useEffect(() => {
        const fieldsPolygon = fieldState.list.map((field) => field.polygon.geometry);
        polygonsRef.current = fieldsPolygon;
    }, [fieldState.list]);

    return { isLoading };
};
