import { useTheme, Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { IconCheckCircle } from '@soil-capital/ui-kit/icons';

export const Eligible = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    return (
        <OnboardingStepsModal
            stepProgress={0}
            isCurrentStepDone={true}
            ctaAction={() => {
                navigate('/onboarding/billing-information');
            }}
            ctaText={t('constants.continue')}
            onBackClick={() => navigate('/onboarding/eligibility')}
        >
            <Grid
                display="flex"
                flexDirection="column"
                gap={theme.spacing(3)}
                alignItems="center"
                textAlign="center"
                paddingTop="60px"
                paddingBottom="60px"
                whiteSpace="pre-line"
                justifyContent={'center'}
            >
                <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    fontSize="73.33px"
                    paddingBottom={theme.spacing(1)}
                >
                    <IconCheckCircle variant="filled" color="success" fontSize="inherit" />
                </Grid>
                <Typography variant="h3">{t('pages.onboarding.eligibility.eligible.title')}</Typography>
                <Typography variant="body2" paddingBottom="30px">
                    {t('pages.onboarding.eligibility.eligible.subtitle')}
                </Typography>
            </Grid>
        </OnboardingStepsModal>
    );
};
