import entities, { FarmT } from '@shared/entities';
import useCurrentSeasonId from './useCurrentSeasonId';

export default () => {
    const { currentSeasonId } = useCurrentSeasonId();

    const { farmState } = entities.farm.useState({ farmSeasonId: currentSeasonId });

    return { currentFarm: farmState.list[0] as FarmT | undefined, currentFarmLoading: farmState.isLoading };
};
