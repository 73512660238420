import { makeStyles } from '@soil-capital/ui-kit/style';

const useContentPageStyle = makeStyles()((theme) => ({
    contentPage: {
        backgroundColor: theme.palette.common.white,
        padding: `0 ${theme.spacing(0)} ${theme.spacing(2)} ${theme.spacing(2)}`,
        borderRadius: theme.shape.borderRadiusM,
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: 'scroll',
        ...theme.styles.scrollSection,
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 1,
        background: theme.palette.common.white,
        position: 'sticky',
        top: theme.spacing(0),
        zIndex: 1,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    contentPageSkeleton: {
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadiusM,
        position: 'absolute',
        zIndex: 90,
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        opacity: 0.1,
        visibility: 'hidden',
        transition: `${theme.transitions.easing.easeIn} opacity ${theme.transitions.duration.leavingScreen}ms, visibility ${theme.transitions.duration.leavingScreen}ms`,
    },
    contentPageLoading: {
        opacity: '1 !important',
        visibility: 'visible',
    },
    image: {
        borderRadius: theme.shape.borderRadiusS,
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        objectPosition: 'center', // could be customized
        marginTop: theme.spacing(2),
    },
    imageSkeleton: {
        borderRadius: theme.shape.borderRadiusS,
        width: '100%',
        height: '200px',
        transform: 'none',
    },
    titleSection: {
        display: 'flex',
        alignItems: 'center',
    },
    titleSkeleton: {
        fontSize: theme.typography.h2.fontSize,
        width: '100%',
    },
    subtitle: {
        marginBottom: theme.spacing(4),
        display: 'block',
        color: theme.palette.darkScale[700],
    },
    subtitleSkeleton: {
        marginBottom: theme.spacing(4),
        width: '40%',
        height: '36px',
    },
    actionButton: {
        whiteSpace: 'nowrap',
        width: 'fit-content',
        display: 'flex',
        alignItems: 'center',
        marginLeft: 8,
    },

    childrenSkeleton: {
        height: '70px',
        marginBottom: '40px',
    },
}));

export default useContentPageStyle;
