import { FormRow } from '@shared/components';
import { Skeleton } from '@soil-capital/ui-kit/material-core';

export const SkeletonInputRow = ({
    variant = 'input',
    size = 'md',
}: {
    variant?: 'input' | 'radio';
    size?: 'sm' | 'md' | 'lg';
}) => {
    const width = size === 'sm' ? 180 : size === 'lg' ? 280 : 240;

    if (variant === 'radio')
        return (
            <FormRow data-testid="radio-skeleton">
                <Skeleton variant="text" width={width} height={35} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 16 }}>
                        <Skeleton sx={{ mr: 1 }} variant="circular" width={25} height={25} />
                        <Skeleton variant="rounded" width={60} height={20} />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Skeleton sx={{ mr: 1 }} variant="circular" width={25} height={25} />
                        <Skeleton variant="rounded" width={60} height={20} />
                    </div>
                </div>
            </FormRow>
        );

    return (
        <FormRow data-testid="input-skeleton">
            <Skeleton variant="text" width={width} height={35} />
            <Skeleton variant="rounded" width={250} height={55} />
        </FormRow>
    );
};
