import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetFieldCropsThatNeedFinalisationQuery } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/api/duplicationApi';

export const useFieldCropNeedFinalisation = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { data: fieldCropsThatNeedFinalisation, isLoading } = useGetFieldCropsThatNeedFinalisationQuery({
        farmSeasonId: currentSeasonId,
    });

    const doesFieldCropNeedFinalisation = (fieldCropId: number) =>
        !!fieldCropsThatNeedFinalisation?.some((field) => field.id === Number(fieldCropId));

    return { doesFieldCropNeedFinalisation, isLoading };
};
