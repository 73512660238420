import { makeStyles } from '@soil-capital/ui-kit/style';

export const useMapFallbackComponentStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    helpBox: {
        margin: 'auto',
        width: '80%',
        textAlign: 'center',
    },
}));
