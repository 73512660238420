import entities, { FieldCropT, FieldT, OperationT, StaticCropT, StaticTillagePracticeT } from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { useGetFieldThumbnail } from '@modules/encoding/shared/hooks/useGetFieldThumbnail';
import { OperationTypeSlugT } from '@shared/entities/staticOperationType/staticOperationType.types';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useParams } from 'react-router-dom';
import { useGetFieldCropsToFinaliseQuery } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/api/duplicationApi';

type useGetDuplicationFieldCropDataProps = {
    farmSeasonId: number;
};

export type OperationGroupT = {
    id: number;
    translation_slug: string;
    slug: OperationTypeSlugT;
    operations: OperationT[];
};

export type DuplicationFieldT = FieldT & {
    snapshot: string | null;
    staticCrop: StaticCropT;
    fieldCrop: FieldCropT;
    companionCrop?: StaticCropT;
    tillagePractice?: StaticTillagePracticeT;
    operationsByOperationType: OperationGroupT[];
    harvestYear: number;
};

export const useGetDuplicationFieldCropData = ({ farmSeasonId }: useGetDuplicationFieldCropDataProps) => {
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { getFieldThumbnail } = useGetFieldThumbnail({ seasonId: Number(farmSeasonId) });
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldCropId: fieldCropIdParam } = useParams();

    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: farmSeasonId });
    const { fieldState } = entities.field.useState({ farmSeasonId: farmSeasonId });
    const { cropState } = entities.crop.useState({ farmSeasonId: farmSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticTillagePracticeState } = entities.staticTillagePractice.useState();
    const { operationState } = entities.operation.useState({ farmSeasonId }, { refetchOnMountOrArgChange: true });
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const { staticSeasonState } = entities.staticSeason.useState();

    const farmSeason = seasonState?.list?.find((s) => s.id === farmSeasonId);
    const season = staticSeasonState?.list?.find((s) => s.id === farmSeason?.season_id);

    const { isLoading: isLoadingFieldCropsToFinalise } = useGetFieldCropsToFinaliseQuery({
        farmSeasonId: currentSeasonId,
        farmSeasonFieldCropId: Number(fieldCropIdParam),
    });

    const getComputedField = (fieldCropId: number): DuplicationFieldT | undefined => {
        const fieldCrop = fieldCropState.getById(fieldCropId);
        const field = fieldState.getById(fieldCrop?.farm_season_field_id);
        const farmSeasonCrop = cropState.getById(fieldCrop?.farm_season_crop_id);
        const staticCrop = staticCropState.getById(farmSeasonCrop?.crop_id);
        const harvestYear = season?.harvest_year;
        const operations = operationState.getByFarmSeasonFieldCropId(fieldCropId);
        const companionCrop = staticCropState.getById(fieldCrop?.companion_crop_id);

        const operationsByOperationType = operations.reduce((acc, operation) => {
            const operationTypeId = operation.operation_type_id;
            const operationType = staticOperationTypeState.getById(operationTypeId);

            if (!operationType) return acc;
            const computedOperation = { ...operation, operationType };

            const existingGroup = acc.find((group) => group.id === operationTypeId);
            if (existingGroup) existingGroup.operations.push(computedOperation);
            else
                acc.push({
                    id: operationTypeId,
                    translation_slug: operationType.translation_slug,
                    slug: operationType.slug,
                    operations: [computedOperation],
                });

            return acc;
        }, [] as OperationGroupT[]);

        const snapshot =
            field?.id && field?.updated_at
                ? getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at })
                : null;
        const tillagePractice = staticTillagePracticeState.getById(fieldCrop?.tillage_id);

        return field && staticCrop && fieldCrop && operationsByOperationType && harvestYear
            ? {
                  ...field,
                  fieldCrop,
                  staticCrop,
                  companionCrop,
                  tillagePractice,
                  snapshot,
                  operationsByOperationType,
                  harvestYear,
              }
            : undefined;
    };

    const isLoading =
        currentFarmLoading ||
        fieldCropState.isLoading ||
        fieldState.isLoading ||
        cropState.isLoading ||
        staticCropState.isLoading ||
        staticTillagePracticeState.isLoading ||
        operationState.isLoading ||
        staticOperationTypeState.isLoading ||
        seasonState.isLoading ||
        staticSeasonState.isLoading ||
        isLoadingFieldCropsToFinalise;

    return { getComputedField, isLoading };
};
