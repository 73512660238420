import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSelectedFieldFilterStyles = makeStyles()((theme) => ({
    container: {
        padding: theme.spacing(1, 2),
        background: theme.palette.primaryScale[100],
        borderRadius: theme.shape.borderRadiusS,
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        maxHeight: 38,
    },
    button: {
        minWidth: 0,
        minHeight: 0,
        marginLeft: 'auto',
        padding: theme.spacing(0.2, 1),
    },
    cross: {
        width: 12,
    },
}));
