import { Grid, Skeleton, useTheme } from '@soil-capital/ui-kit/material-core';

export const InvitationSkeleton = () => {
    const theme = useTheme();

    return (
        <Grid
            textAlign="center"
            paddingBottom="40px"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
        >
            <Skeleton variant="text" width="100%" height="40px" />
            <Grid
                display="flex"
                justifyContent="space-between"
                paddingTop={theme.spacing(3)}
                width="545px"
                gap={theme.spacing(8)}
            >
                <Grid display="flex" flexDirection="column" alignItems="center" gap={theme.spacing(2)} flex="1">
                    <Skeleton height="100px" width="100px" />
                    <Skeleton variant="text" width="200px" height="40px" />
                    <Skeleton variant="text" width="200px" height="20px" />
                    <Skeleton variant="text" width="200px" height="20px" />
                    <Skeleton variant="text" width="200px" height="20px" />
                </Grid>
                <Grid display="flex" flexDirection="column" alignItems="center" gap={theme.spacing(2)} flex="1">
                    <Skeleton height="100px" width="100px" />
                    <Skeleton variant="text" width="200px" height="40px" />
                    <Skeleton variant="text" width="200px" height="20px" />
                    <Skeleton variant="text" width="200px" height="20px" />
                    <Skeleton variant="text" width="200px" height="20px" />
                </Grid>
            </Grid>
            <Grid paddingTop={theme.spacing(6)}>
                <Skeleton variant="text" width="100%" height="40px" />
                <Grid paddingTop={theme.spacing(4)} display="flex" gap={theme.spacing(3)} justifyContent="center">
                    <Skeleton variant="text" width="200px" height="40px" />
                    <Skeleton variant="text" width="200px" height="40px" />
                </Grid>
            </Grid>
        </Grid>
    );
};
