import entities from '@shared/entities';
import { useCallback, useState } from 'react';

export const useLivestockListLogic = () => {
    const { staticLivestockState } = entities.staticLivestock.useState();
    const livestockList = staticLivestockState.list;
    const [selectedLivestock, setSelectedLivestock] = useState<{ [key: number]: number | null }>({});

    const getFilteredLivestockList = useCallback(
        (index: number) => {
            return livestockList.filter(
                (item) => !Object.values(selectedLivestock).includes(item.id) || selectedLivestock[index] === item.id,
            );
        },
        [livestockList, selectedLivestock],
    );

    const handleLivestockChange = (formNumber: number, newValue: number) => {
        setSelectedLivestock((prevSelections) => ({
            ...prevSelections,
            [formNumber]: newValue,
        }));
    };

    return {
        livestockList,
        setSelectedLivestock,
        getFilteredLivestockList,
        handleLivestockChange,
    };
};
