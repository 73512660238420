import { makeStyles } from '@soil-capital/ui-kit/style';

const useEncodingLayoutStyle = makeStyles()((theme) => ({
    skeletonSection: {
        display: 'flex',
        backgroundColor: theme.palette.primaryScale[900],
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        transition: `${theme.transitions.easing.easeIn} opacity ${theme.transitions.duration.leavingScreen}ms, visibility ${theme.transitions.duration.leavingScreen}ms`,
        opacity: 0,
        visibility: 'hidden',
        zIndex: 900,
    },

    skeletonSectionLoading: {
        visibility: 'visible',
        opacity: 1,
    },
}));

export default useEncodingLayoutStyle;
