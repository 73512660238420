import intersect from '@turf/intersect';
import area from '@turf/area';
import { Feature } from 'geojson';
import { Polygon } from '@turf/helpers';
import { Properties } from '@turf/helpers';

export const deleteLayer = (map: mapboxgl.Map, layerId: string) => {
    if (map.getLayer(layerId)) {
        map.removeLayer(layerId);
    }

    if (map.getSource(layerId)) {
        map.removeSource(layerId);
    }
};

export const deleteLayersByPattern = (map: mapboxgl.Map, pattern: string, keep: number[]) => {
    const existingLayers = map.getStyle().layers || [];
    const layersToDelete = existingLayers
        .filter((layer) => {
            const layerParts = layer.id.split('-');
            const layerId = layerParts[layerParts.length - 1];
            return layer.id.startsWith(pattern) && !keep.includes(Number(layerId));
        })
        .map((layer) => layer.id);
    layersToDelete.forEach((layer) => {
        deleteLayer(map, layer);
    });
};

export const toleranceOptions = {
    TOLERANCE_RATIO_AREA: 200, // => area()/TOLERANCE_RATIO_AREA (originally set to 200)
    TOLERANCE_MIN_AREA: 500, // in m2 (originally set to 500)
    TOLERANCE_MIN_AREA_SHAPEFILE: 100, // in m2 (originally set to 100)
    TOLERANCE_MERGE_BUFFER: 0.001, // used in turf.buffer() to get contiguous polygons (with very small gap in-between)
};

export const intersectCore = (loopFeature: Polygon, comparingFeature: Polygon) => {
    const interGeo = intersect(loopFeature, comparingFeature);
    let interIsNull = interGeo === null;

    // overlapping tolerance
    if (
        !interIsNull &&
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        area(interGeo as Feature<any, Properties>) <=
            Math.max(
                area(comparingFeature) / toleranceOptions.TOLERANCE_RATIO_AREA,
                toleranceOptions.TOLERANCE_MIN_AREA,
            )
    ) {
        interIsNull = true;
    }

    return !interIsNull;
};

export const intersectPolygon = (geoJsonFeature: Polygon, geoJsonFeatures: Polygon[]): boolean =>
    geoJsonFeatures.some((v) => intersectCore(v, geoJsonFeature));

export const isDrawingAllowed = (newPolygon: Polygon, existingPolygons: Polygon[]) => {
    for (const existingPolygon of existingPolygons) {
        const intersection = intersect(existingPolygon, newPolygon);

        if (intersection) {
            return false;
        }
    }

    // No intersection
    return true;
};

// calculate area in hectare
export const calculateArea = (geoJsonFeature: Polygon): number => area(geoJsonFeature) / 10000;
