import { useGetPaymentTimelineQuery } from '@modules/billing/api/billingApi';
import { useState } from 'react';
import { PaymentDetailsT } from '@modules/billing/api/billing.types';
import { TimelineItemPropsT } from '@soil-capital/ui-kit/components';

export type PaymentTimelineItemT = Omit<TimelineItemPropsT, 'children'> & {
    children: PaymentDetailsT;
};
export const useSubscriptionCardLogic = (farmId: number) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { data, isLoading } = useGetPaymentTimelineQuery({ farmId });
    const isDeferredPayment = data?.some((payment) => payment.deferred_payment);
    const payments: PaymentTimelineItemT[] =
        data?.map((payment, index) => ({
            status:
                (!isDeferredPayment && payment.status === 'to_pay' && payment.product_plan !== 'NETOFF') ||
                payment.deferred_payment ||
                payment.status === 'pending' ||
                payment.status === 'payment_failed'
                    ? 'active'
                    : payment.paid_at || payment.product_plan === 'NETOFF'
                    ? 'done'
                    : 'disabled',
            isLastStep: index === data.length - 1 ? true : false,
            offset: '0.45em',
            children: payment,
        })) || [];

    return { isExpanded, setIsExpanded, data, isLoading, payments };
};
