import { Typography } from '@soil-capital/ui-kit/material-core';
import useIntroProgressLayoutStyle from './useIntroProgressLayout.style';

export const IntroProgressLayout = ({
    leftSection,
    rightSection,
    title,
}: {
    title?: string;
    leftSection?: JSX.Element;
    rightSection?: JSX.Element;
}) => {
    const { classes } = useIntroProgressLayoutStyle();

    return (
        <div className={classes.introProgressLayout}>
            <Typography variant="h3">{title}</Typography>
            <div className={classes.sectionsContainer}>
                <div>{leftSection}</div>
                <div>{rightSection}</div>
            </div>
        </div>
    );
};
