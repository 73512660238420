import { CardScore } from '@soil-capital/ui-kit/components';
import { Grid, Skeleton } from '@soil-capital/ui-kit/material-core';

export const LivestockFormSkeleton = () => {
    return (
        <CardScore>
            <Grid display="flex" justifyContent="space-between" width="100%" alignItems="center">
                <Skeleton variant="text" width="100px" />
                <Skeleton width="40px" height="40px" variant="rectangular" />
            </Grid>
            <Grid display="flex" justifyContent="space-between" width="100%" alignItems="center" gap="16px">
                <Grid width="100%">
                    <Skeleton variant="text" width="150px" />
                    <Skeleton height="45px" />
                </Grid>
                <Grid width="320px">
                    <Skeleton variant="text" width="100px" />
                    <Skeleton height="45px" />
                </Grid>
            </Grid>
        </CardScore>
    );
};
