import { SelectableList } from '@soil-capital/ui-kit/components';
import { Controller, useFormContext } from 'react-hook-form';
import { ReactNode } from 'react';

export type SelectableCheckboxRadioProps = {
    name: string;
    isSelected: boolean;
    label: ReactNode;
};

export const ControlledSelectableCheckboxRadio = ({ name, isSelected, label }: SelectableCheckboxRadioProps) => {
    const context = useFormContext();

    if (!context) throw new Error('ControlledSelectableCheckboxRadio must be used within a FormContext');

    return (
        <Controller
            name={name}
            render={({ field: { name, ...rest } }) => (
                <SelectableList.CheckboxRadio {...rest} name={name} isSelected={isSelected}>
                    {label}
                </SelectableList.CheckboxRadio>
            )}
        />
    );
};
