import { makeStyles } from '@soil-capital/ui-kit/style';

export const useMineralFertiliserInhibitorInputStyles = makeStyles()((theme) => ({
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    productInputs: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    iconClose: {
        height: '50px',
        width: '50px',
    },
}));
