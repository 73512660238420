import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFormQuestionStyle = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
    tooltip: {
        display: 'flex !important',
        alignItems: 'center',
        '& svg': {
            width: 16,
            height: 16,
        },
    },
}));
