import { useGetSeedingMachineries } from '@modules/encoding/modules/technicalItinerary/module/seeding/hooks/useGetSeedingMachineries';
import { useWatch } from 'react-hook-form';
import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';

export const useSeedingMachineryDepthInputVisibility = () => {
    const { seedingMachineries } = useGetSeedingMachineries();
    const machineryId = useWatch({ name: SEEDING_FORM_INPUT_NAMES.MACHINERY_ID });

    const chosenMachinery = seedingMachineries.find(({ id }) => id === machineryId);
    const shouldDisplay = !!chosenMachinery && chosenMachinery?.depth === null;

    return {
        shouldDisplay,
    };
};
