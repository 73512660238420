import { useCallback } from 'react';
import createStaticEntity from '../createStaticEntity';
import { PesticideTypeT, StaticPesticideT } from './staticPesticide.types';

export default createStaticEntity<StaticPesticideT>()({
    sortComparer: false,
    entityName: 'staticPesticide',
    url: ({ country_id }: { country_id: number }) => `/v3/static/pesticides?countryId=${country_id}`,
    useGetCustomState: (defaultState) => {
        const getByPesticideType = useCallback(
            (pesticideType: PesticideTypeT) => {
                return defaultState.list.filter(({ pesticide_type }) => pesticide_type === pesticideType);
            },
            [defaultState.list],
        );

        return { ...defaultState, getByPesticideType };
    },
});
