import { useTranslation } from 'react-i18next';
import { useGetMineralFertiliserStaticDataByTypeQuery } from '../api/mineralFertiliserApi';
import { MINERAL_FERTILISER_STATIC_DATA_TYPE } from '../mineralFertiliser.types';

export const useMineralFertiliserFormOptions = () => {
    const { t } = useTranslation();

    const { data: applicationMethods, isLoading: isLoadingApplicationMethods } =
        useGetMineralFertiliserStaticDataByTypeQuery({
            type: MINERAL_FERTILISER_STATIC_DATA_TYPE.MINERAL_FERTILISATION_APPLICATION,
        });

    const applicationMethodOptions = applicationMethods
        ?.map((el) => ({
            value: el.id,
            label: t(`static-data.slug.${el.slug}`),
        }))
        .sort((a, b) => t(a.label).localeCompare(t(b.label)));

    return {
        applicationMethods: applicationMethodOptions || [],
        isLoading: isLoadingApplicationMethods,
    };
};
