import { Button } from '@soil-capital/ui-kit/components';
import { Typography, Grid, useTheme, Skeleton } from '@soil-capital/ui-kit/material-core';
import { CardScore, ConditionalTooltip } from '@soil-capital/ui-kit/components';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { PromoCode } from '../PromoCode/PromoCode';
import PricingPromoCardStyle from './PricingPromoCard.style';
import { OnboardingDiscountT } from '@modules/onboarding/api/onboarding.type';

type PricingPromoCardProps = {
    hasDiscount: boolean;
    subscriptionPrice: number;
    discountedPrice: number;
    currency: string;
    haPrice: number;
    floorPrice: number;
    ceiledPrice: number;
    isLoading: boolean;
    isBelgian: boolean;
    farmSeasonProductPlanId: number;
    isFetching: boolean;
    promotionCodes: OnboardingDiscountT[] | undefined;
    farmSeasonId: number;
    redirectToStripe: () => void;
    t: (key: string, values?: { [key: string]: number | string }) => string;
};

export const PricingPromoCard = ({
    hasDiscount,
    subscriptionPrice,
    discountedPrice,
    currency,
    haPrice,
    floorPrice,
    ceiledPrice,
    isLoading,
    isBelgian,
    farmSeasonProductPlanId,
    isFetching,
    promotionCodes,
    farmSeasonId,
    redirectToStripe,
    t,
}: PricingPromoCardProps) => {
    const priceFor250ha = haPrice * 250;
    const { classes } = PricingPromoCardStyle();
    const theme = useTheme();

    return (
        <CardScore highlight>
            <Typography variant="caps" color={theme.palette.darkScale[900]}>
                {t('pages.onboarding.pricing.card.title')}
            </Typography>
            <Grid display="flex" alignItems="center" gap={theme.spacing(1)}>
                {isFetching ? (
                    <Skeleton variant="text" width="70px" height="30px" />
                ) : (
                    <Typography variant="h3">
                        {hasDiscount ? (
                            <>
                                <span className={classes.crossed}>
                                    {t('formatted-price', {
                                        price: subscriptionPrice,
                                        currency,
                                    })}
                                </span>
                                {t('formatted-price', {
                                    price: discountedPrice,
                                    currency,
                                })}
                                {isBelgian && ` ${t('constants.tax-free')}`}
                            </>
                        ) : (
                            `${t('formatted-price', { currency, price: subscriptionPrice })}${
                                isBelgian ? ` ${t('constants.tax-free')}` : ''
                            }`
                        )}
                    </Typography>
                )}
                <ConditionalTooltip
                    text={t('pages.onboarding.pricing.card.tooltip', {
                        currency,
                        subscriptionPrice,
                        haPrice,
                        floorPrice,
                        ceiledPrice,
                        priceFor250ha,
                    })}
                />
            </Grid>
            <Typography variant="body2" textAlign="center" color={theme.palette.darkScale[700]}>
                {t('pages.onboarding.pricing.card.description')}
            </Typography>
            <PromoCode
                farmSeasonId={farmSeasonId}
                farmSeasonProductPlanId={farmSeasonProductPlanId}
                promotionCodes={promotionCodes}
                isFetching={isFetching}
            />
            <Button onClick={redirectToStripe} loading={isLoading} variant="contained" endIcon={<IconArrowRight />}>
                {t('pages.onboarding.pricing.card.cta')}
            </Button>
        </CardScore>
    );
};
