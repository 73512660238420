import { z } from 'zod';
import { OperationFormSchema } from './OperationFormSchema';
import { PloughingFormDataT } from '../api/operationFormApi.types';

export const ploughingFormSchema = new OperationFormSchema(
    {
        passage: z.number().nullable(),
        depth: z.number().nullable(),
    },
    (apiData?: PloughingFormDataT) => ({
        depth: apiData?.depth ?? null,
        passage: apiData?.passage ?? null,
    }),
    [],
);

export const PLOUGHING_FORM_INPUT_NAMES = ploughingFormSchema.INPUT_NAMES;

export type PloughingFormSchemaT = z.infer<typeof ploughingFormSchema.schema>;
