import { useWatch, useFormContext, useFormState } from 'react-hook-form';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { useEffect } from 'react';
import { COVER_CROP_STATIC_DATA_TYPE } from '../../../api/coverCropFormApi.types';
import { useGetCoverCropFormStaticDataByTypeQuery } from '../../../api/coverCropFormApi';

export const useCoverCropDestructionDepthQuestion = () => {
    const destructionMachineId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID }) as number;
    const destructionMethodId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID }) as number;
    const { currentFarm } = encodingShared.useCurrentFarm();

    const { dirtyFields } = useFormState();

    const { setValue } = useFormContext();

    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });
    const destructionMachine = staticMachineryState.getById(destructionMachineId);

    const { data: destructionMethods } = useGetCoverCropFormStaticDataByTypeQuery({
        type: COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_DESTRUCTION,
    });
    const destructionMethod = destructionMethods?.find((item) => item.id === destructionMethodId);

    useEffect(() => {
        if (dirtyFields[COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID]) {
            if (destructionMachine) {
                setValue(
                    COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DEPTH,
                    destructionMachine.depth ? Number(destructionMachine.depth) : destructionMachine.depth,
                );
            } else if (!destructionMachine) {
                setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DEPTH, null);
            }
        }
        if (dirtyFields[COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID] && destructionMethod && destructionMachine) {
            setValue(
                COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DEPTH,
                destructionMachine.depth ? Number(destructionMachine.depth) : destructionMachine.depth,
            );
        }
    }, [setValue, destructionMachine, dirtyFields, destructionMethod]);

    return {
        isVisible: destructionMachine && destructionMachine.depth === null,
    };
};
