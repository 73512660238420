import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => {
    return {
        button: {
            zIndex: 1000,
            position: 'absolute',
            right: 0,

            margin: '10px 30px 0 0',
        },
        home: {
            backgroundColor: 'white',
            height: '40px',
            width: '40px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            '&:hover': { cursor: 'pointer' },
        },
        zoom: {
            backgroundColor: 'white',
            height: '80px',
            width: '40px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            borderRadius: '8px',
            marginTop: '4px',
            padding: '12px 8px',
        },
        divider: {
            height: '2px',
            width: '24px',
            backgroundColor: theme.palette.divider,
        },
        minusIcon: {
            width: '16px',
            height: '16px',
            padding: '2px',
            '&:hover': { cursor: 'pointer' },
        },
        plusIcon: {
            width: '18px',
            height: '18px',
            '&:hover': { cursor: 'pointer' },
        },
    };
});
