import api from '@shared/api';
import { StaticFertiliserT } from '@shared/entities/staticFertiliser/staticFertiliser.types';
import { CustomStaticFertiliserBodyT, CustomStaticFertiliserParamsT } from './customStaticFertiliser.types';
import { staticFertiliserCache } from '@shared/entities/staticFertiliser/staticFertiliser.cache';

export const customStaticFertiliserApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createCustomStaticFertiliser: builder.mutation<
            StaticFertiliserT,
            CustomStaticFertiliserParamsT & {
                /** required for static fertiliser cache update. */
                countryId: number;
                body: CustomStaticFertiliserBodyT;
            }
        >({
            query: ({ farmId, body }) => ({
                url: `/v3/farms/${farmId}/fertilisers`,
                method: 'POST',
                body,
            }),
            onQueryStarted: ({ body, farmId }, apiContext) => {
                staticFertiliserCache.add('fetch-only')({ farmId, body: { ...body } }, apiContext);
            },
        }),
    }),
});

export const { useCreateCustomStaticFertiliserMutation } = customStaticFertiliserApi;
