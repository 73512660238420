import { useTranslation } from 'react-i18next';
import { CreateMineralFertiliserModalPropsT } from './CreateMineralFertiliserModal';
import { useForm } from '@shared/hooks';
import {
    customStaticFertiliserFormSchema,
    customStaticFertiliserFormDefaultValues,
} from '../../schema/customStaticFertiliserFormSchema';
import { useEffect, useMemo } from 'react';
import { useCreateCustomStaticFertiliserMutation } from '../../api/customStaticFertiliser.api';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import { FERTILISER_TYPE_SLUGS, FERTILISER_UNIT } from '@shared/entities/staticFertiliser/staticFertiliser.types';
import { CustomStaticFertiliserBodyT } from '../../api/customStaticFertiliser.types';
import entities from '@shared/entities';
import {
    computeCustomName,
    getCustomValidationErrors,
    getExistingFertiliserForNPK,
    getFertiliserByName,
    haveSimilarComponents,
} from './createMineralFertiliserModalUtils';
import { useSnackbar } from 'notistack';

export const useCreateMineralFertiliserModal = ({
    data: modalProps,
    isOpen,
    onClose: closeModal,
}: CreateMineralFertiliserModalPropsT) => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { currentFarm } = useCurrentFarm();
    const { staticFertiliserState } = entities.staticFertiliser.useState({ farmId: currentFarm?.id });
    const [createStaticFertiliser, { isLoading: createStaticFertiliserLoading }] =
        useCreateCustomStaticFertiliserMutation();

    const mineralFertilisers = useMemo(() => {
        return staticFertiliserState.getByFilters({
            fertiliserTypes: [FERTILISER_TYPE_SLUGS.MINERAL_FERTILISER],
            physicalState: modalProps?.physicalState,
        });
    }, [modalProps?.physicalState, staticFertiliserState]);

    let textModalTitle = '';
    let textModalName = '';
    if (modalProps?.physicalState === 'liquid') {
        textModalTitle = t('encoding-technical-itinerary.select-mineral-fertiliser.add-liquid-fertiliser-button');
        textModalName = t('encoding-technical-itinerary.select-mineral-fertiliser.name.liquid');
    } else if (modalProps?.physicalState === 'solid') {
        textModalTitle = t('encoding-technical-itinerary.select-mineral-fertiliser.add-solid-fertiliser-button');
        textModalName = t('encoding-technical-itinerary.select-mineral-fertiliser.name.solid');
    }

    const formMethods = useForm({
        schema: customStaticFertiliserFormSchema,
        defaultValues: customStaticFertiliserFormDefaultValues,
        displaySnackbarOnSchemaError: false,
    });
    const {
        reset,
        handleSubmit,
        watch,
        formState: { isSubmitted, isValid, errors },
    } = formMethods;

    useEffect(() => {
        // Reset form when modal is closed
        if (isOpen === false) {
            reset(customStaticFertiliserFormDefaultValues);
        }
    }, [isOpen, reset]);

    const formDataWatched = watch();
    const globalError = useMemo(
        () => getCustomValidationErrors(formDataWatched, mineralFertilisers, t),
        [formDataWatched, mineralFertilisers, t],
    );
    const existingFertiliserForNPK = useMemo(
        () => getExistingFertiliserForNPK(formDataWatched, mineralFertilisers),
        [formDataWatched, mineralFertilisers],
    );
    const defaultFertiliserNameComputed = computeCustomName(formDataWatched);

    const onSubmit = handleSubmit(async (formData) => {
        if (globalError) {
            return;
        }
        if (!currentFarm?.id || !modalProps?.physicalState || !closeModal) {
            return console.error('Data missing in createMineralFertiliserModal');
        }

        const existingFertiliser = getFertiliserByName(mineralFertilisers, formData);

        if (existingFertiliser && haveSimilarComponents(existingFertiliser, formData)) {
            closeModal({ addedFertiliserId: existingFertiliser.id });
            return;
        }

        const unit = modalProps.physicalState === 'solid' ? FERTILISER_UNIT.KG : FERTILISER_UNIT.L;
        const fertiliserCreateBody: CustomStaticFertiliserBodyT = {
            n: formData.n,
            p: formData.p,
            k: formData.k,
            unit,
            name: formData.fertiliser_name ?? computeCustomName(formData),
            ca: formData.ca ?? 0,
            mg: formData.mg ?? 0,
            s: formData.s ?? 0,
        };

        try {
            const response = await createStaticFertiliser({
                farmId: currentFarm.id,
                countryId: currentFarm.country_id,
                body: fertiliserCreateBody,
            }).unwrap();
            closeModal({ addedFertiliserId: response.id });
        } catch (_) {
            enqueueSnackbar(t('encoding-technical-itinerary.create-mineral-fertiliser.server-error'), {
                variant: 'error',
            });
        }
    });

    return {
        textModalTitle,
        textModalName,
        createStaticFertiliserLoading,
        onSubmit,
        isSubmitted,
        isValid,
        errors,
        formMethods,
        globalError,
        existingFertiliserForNPK,
        defaultFertiliserNameComputed,
    };
};
