import { CardScore } from '@soil-capital/ui-kit/components';
import { Grid, Skeleton } from '@soil-capital/ui-kit/material-core';

export const PricingPromoCardSkeleton = () => {
    return (
        <CardScore highlight>
            <Grid display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                <Skeleton variant="text" width="350px" height="40px" />
                <Skeleton variant="text" width="70px" height="30px" />
            </Grid>
            <Grid width="100%" display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="80%" />
            </Grid>
            <Skeleton variant="text" height="35px" width="300px" />
            <Skeleton width="309px" height="56px" variant="rounded" />
        </CardScore>
    );
};
