import OperationDatePicker from '@modules/encoding/modules/technicalItinerary/components/OperationDatePicker/OperationDatePicker';
import { OperationDatePickerPropsT } from '@modules/encoding/modules/technicalItinerary/components/OperationDatePicker/OperationDatePicker.types';
import { Controller, useFormContext } from 'react-hook-form';

type FormOperationDatePickerProps = {
    name: string;
} & Omit<OperationDatePickerPropsT, 'value'>;

export const FormOperationDatePicker = ({ name, onChange, ...rest }: FormOperationDatePickerProps) => {
    const context = useFormContext();

    if (!context) throw new Error('FormOperationDatePicker should be used inside a FormProvider');

    return (
        <Controller
            control={context.control}
            name={name}
            defaultValue={null}
            render={({ field: { onChange: onFieldChange, value } }) => {
                return (
                    <OperationDatePicker
                        value={value ? new Date(value) : null}
                        onChange={(value) => {
                            onFieldChange(value);
                            onChange?.(value);
                        }}
                        {...rest}
                    />
                );
            }}
        />
    );
};
