import { Grid, Skeleton, useTheme } from '@soil-capital/ui-kit/material-core';

export const ConfirmationStepSkeleton = () => {
    const theme = useTheme();
    return (
        <Grid
            display="flex"
            flexDirection="column"
            alignItems="center"
            height="60vh"
            justifyContent="center"
            textAlign="center"
            gap={theme.spacing(10)}
            marginTop={theme.spacing(5)}
        >
            <Skeleton variant="circular" width="88px" height="88px" />
            <Grid gap={theme.spacing(3)} display="flex" flexDirection="column" width="100%" alignItems="center">
                <Skeleton variant="text" width="90%" />
                <Grid width="100%" display="flex" flexDirection="column" alignItems="center">
                    <Skeleton variant="text" width="100%" />
                    <Skeleton variant="text" width="10%" />
                </Grid>
            </Grid>
            <Skeleton variant="rounded" height="40px" width="40%" />
        </Grid>
    );
};
