import { addListener } from '@reduxjs/toolkit';
import saveStatusSlice, { RequestStatusTypesT } from './saveStatus.slice';

const saveStatusListener: Parameters<typeof addListener>[0] = {
    effect: (action, { dispatch }) => {
        let requestStatusToDispatch: RequestStatusTypesT | null = null;
        if (action.type.includes('pending')) {
            requestStatusToDispatch = 'pending';
        }
        if (action.type.includes('fulfilled')) {
            requestStatusToDispatch = 'success';
        }
        if (action.type.includes('rejected')) {
            requestStatusToDispatch = 'failed';
        }

        if (requestStatusToDispatch) {
            dispatch(
                saveStatusSlice.actions.addRequestStatus({
                    id: action.meta.requestId,
                    status: requestStatusToDispatch,
                }),
            );
        }
    },
    predicate: (action) => {
        // any mutation from shared/api or shared/entities will trigger the effect
        return (
            action.type === 'api/executeMutation/pending' ||
            action.type === 'api/executeMutation/fulfilled' ||
            action.type === 'api/executeMutation/rejected'
        );
    },
};

export default saveStatusListener;
