import entities from '@shared/entities';

/** return all fields linked to a farmSeasonCropId */
export const useFieldCropsWithCommonCrop = ({ fieldCropId, seasonId }: { fieldCropId: number; seasonId: number }) => {
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: seasonId });

    const currentFieldCrop = fieldCropState.getById(fieldCropId);
    const fieldCrops = !currentFieldCrop?.farm_season_crop_id
        ? []
        : fieldCropState.getByCropId({ cropId: currentFieldCrop.farm_season_crop_id });
    const filteredFieldCrops = fieldCrops.filter((fieldCrop) => fieldCrop.id !== fieldCropId);

    const isLoading = fieldCropState.isLoading;
    return { isLoading, fieldCropsWithCommonCrop: filteredFieldCrops };
};
