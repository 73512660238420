import { useGetOperationDateString } from '@modules/encoding/modules/technicalItinerary/hooks/useGetOperationDateString';
import entities, { OperationT } from '@shared/entities';
import { ProgressDetailT } from '@shared/entities/progress/progress.types';

/** get the operation options "reusable" (duplicable) */
export const useReusableOperationOptions = ({
    farmSeasonId,
    fieldCropId,
    operationId,
}: {
    fieldCropId: number;
    operationId: number;
    farmSeasonId: number;
}) => {
    const { operationState } = entities.operation.useState({ farmSeasonFieldCropId: fieldCropId, farmSeasonId });
    const { progressState } = entities.progress.useState({ seasonId: farmSeasonId });
    const currentOperation = operationState.getById(operationId);
    const TechnicalItineraryProgressDetails = progressState.getBySlug('technical-itinerary')?.progress_details;
    const { getOperationDateString } = useGetOperationDateString();

    const hasCurrentFieldCrop = (operation: OperationT) => operation.farm_season_field_crop_id === fieldCropId;
    const hasCurrentOperationType = (operation: OperationT) =>
        operation.operation_type_id === currentOperation?.operation_type_id;
    const isNotCurrentOperation = (operation: OperationT) => operation.id !== operationId;
    const isOperationProgressDone = (operation: OperationT) => {
        const matchFieldCropOperationId = (progressDetail: ProgressDetailT) =>
            progressDetail.farm_season_field_crop_operation_id === operation.farm_season_field_crop_operation_id;
        return TechnicalItineraryProgressDetails?.find(matchFieldCropOperationId)?.is_done;
    };
    const getOperationOptionData = (operation: OperationT) => ({
        label: getOperationDateString(operation.operation_date),
        value: operation.id,
    });

    return {
        reusableOperationOptions: operationState.list
            .filter(hasCurrentFieldCrop)
            .filter(hasCurrentOperationType)
            .filter(isNotCurrentOperation)
            .filter(isOperationProgressDone)
            .map(getOperationOptionData),
    };
};
