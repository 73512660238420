import { Modal } from '@soil-capital/ui-kit/components';
import { useTranslation, Trans } from 'react-i18next';
import usePaymentCTAModalStyles from './usePaymentCTAModal.style';
import { Typography, Grid } from '@soil-capital/ui-kit/material-core';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { usePaymentCTAModalLogic } from './usePaymentCTAModalLogic';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';

interface PaymentCTAModalProps {
    userName: string;
    isPartnerPrepaying: boolean;
}

export const PaymentCTAModal = ({ userName, isPartnerPrepaying }: PaymentCTAModalProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { currentSeason } = useCurrentSeason();
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const { harvestYear } = useGetCurrentHarvestYear(activeSeason?.season_id);
    const seasonId = activeSeason?.id;
    const { classes } = usePaymentCTAModalStyles();
    const handleConfirmClick = () => {
        navigate(`/${seasonId}/payment/pricing`);
    };
    const { paymentCTAModalOpen, onPaymentCTAModalClose, onPaymentCTAModalOpen, showModal, renewal } =
        usePaymentCTAModalLogic(isPartnerPrepaying);

    useEffect(() => {
        if (showModal) {
            onPaymentCTAModalOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showModal]);

    return (
        <Modal
            size="medium"
            open={paymentCTAModalOpen}
            onClose={onPaymentCTAModalClose}
            onConfirm={handleConfirmClick}
            header={
                <Modal.Header>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <Grid display={'flex'} justifyContent={'center'}>
                        <div className={classes.imageContainer} />
                    </Grid>
                    <div className={classes.flexbox}>
                        <Typography variant="h3">{t('billing.payment.modal.title', { userName })}</Typography>
                        <Typography variant="body2">
                            <Trans
                                t={t}
                                i18nKey={
                                    renewal
                                        ? 'billing.payment.modal.description.renewal'
                                        : 'billing.payment.modal.description.deferred-payment'
                                }
                                values={{ year: harvestYear ?? '' }}
                            />
                        </Typography>
                    </div>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Confirm loading={false}>
                        {renewal
                            ? t('billing.payment.modal.CTA.renewal')
                            : t('billing.payment.modal.CTA.deferred-payment')}
                        <IconArrowRight />
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
