import { SkeletonInputRow } from '@shared/components/form/SkeletonInputRow/SkeletonInputRow';
import { Skeleton, Box } from '@soil-capital/ui-kit/material-core';

export const MineralFertiliserFormSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" gap={2} data-testid="mineral-fertiliser-form-skeleton">
            <SkeletonInputRow size="lg" variant="input" />
            <Skeleton variant="rounded" width="100%" height={100} />
            <Skeleton variant="rounded" width="100%" height={100} />
            <SkeletonInputRow size="lg" variant="input" />
        </Box>
    );
};
