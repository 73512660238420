import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { useOrganicFertiliserForm } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/hooks/useOrganicFertiliserForm';
import { useOrganicFertiliserFormStyles } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/components/OrganicFertiliserForm/OrganicFertiliserForm.style';
import { Card } from '@soil-capital/ui-kit/components';
import { OrganicFertiliserFormSkeleton } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/components/OrganicFertiliserForm/OrganicFertiliserFormSkeleton';
import { OrganicFertiliserProductsInput } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/components/OrganicFertiliserProductsInput/OrganicFertiliserProductsInput';
import { ApplicationMethodInput } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/components/ApplicationMethodInput/ApplicationMethodInput';
import { ReuseOperationSelect } from '../../../operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { useReuseOperation } from '../../../operationDuplication/reuse/hooks/useReuseOperation';
import { ORGANIC_FERTILISER_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/organicFertilisationSchema';

export const OrganicFertiliserForm = () => {
    const { t } = useTranslation();
    const { classes, cx } = useOrganicFertiliserFormStyles();

    const { methods, options, isFormLoading, onSubmit, refetchFormData, getFormValues } = useOrganicFertiliserForm();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();

    const { applicationMethods, organicFertilisersSolid, organicFertilisersLiquid } = options;

    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        onOperationReused: refetchFormData,
        getFormValues,
    });

    return (
        <>
            {isFormLoading ? <OrganicFertiliserFormSkeleton /> : null}
            <FormProvider {...methods}>
                <form
                    onSubmit={onSubmit}
                    className={cx(classes.form, isFormLoading && classes.hiddenForm)}
                    style={isFormLoading ? { visibility: 'hidden', height: 0 } : {}}
                >
                    <ReuseOperationSelect
                        fieldCropId={fieldCropId}
                        farmSeasonId={seasonId}
                        operationId={operationId}
                        onReuseOperationTriggered={handleReuseOperation}
                        disabled={isReuseOperationLoading}
                        actionLoading={isReuseOperationLoading}
                    />
                    <ApplicationMethodInput
                        options={applicationMethods}
                        onChange={onSubmit}
                        disabled={isReuseOperationLoading}
                    />
                    <Card title={t('constants.solid')}>
                        <OrganicFertiliserProductsInput
                            onChange={onSubmit}
                            name={ORGANIC_FERTILISER_FORM_INPUT_NAMES.ORGANIC_FERTILISER_SOLID_PRODUCTS}
                            fertilisers={organicFertilisersSolid}
                            disabled={isReuseOperationLoading}
                        />
                    </Card>
                    <Card title={t('constants.liquid')}>
                        <OrganicFertiliserProductsInput
                            onChange={onSubmit}
                            name={ORGANIC_FERTILISER_FORM_INPUT_NAMES.ORGANIC_FERTILISER_LIQUID_PRODUCTS}
                            fertilisers={organicFertilisersLiquid}
                            disabled={isReuseOperationLoading}
                        />
                    </Card>
                </form>
            </FormProvider>
        </>
    );
};
