import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFieldFiltersStyles = makeStyles()((theme) => ({
    filterItem: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    filterIcon: {
        width: 16,
        height: 16,
    },
    placeholder: {
        color: theme.palette.neutral,
    },
    select: {
        padding: theme.spacing(1, 2),
    },
    selectedValue: {
        backgroundColor: `${theme.palette.primaryScale[100]} !important`,
    },
    tooltip: {
        display: 'flex !important',
    },
    reset: {
        marginRight: theme.spacing(3),
        marginLeft: theme.spacing(3),
        width: 12,
        cursor: 'pointer',
    },
    hide: {
        display: 'none',
    },
}));
