/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from '@reduxjs/toolkit/query';
import { UseMutation, UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { OptionalObjectSchema } from 'yup/lib/object';
import { TagNameT } from './entityApi';

export type ShapeEntityPropsT<N extends string = string> = Readonly<{
    api?: Api<any, any, any, any, any>;
    schema?: OptionalObjectSchema<any>;
    useGet?: UseQuery<any>;
    useCreate?: UseMutation<any>;
    useUpdate?: UseMutation<any>;
    useDelete?: UseMutation<any>;
    useState: (props?: any) => Record<
        `${Uncapitalize<N>}State`,
        {
            list: any;
            isLoading: boolean;
            getById: (v: any) => any;
            getByIds: (v: any) => any;
        }
    >;
}>;

export const shapeEntity = <T extends ShapeEntityPropsT, N extends TagNameT>(entityName: N, entity: T): T => entity;
