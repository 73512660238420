import { MapContext } from '@shared/map/utils/MapProvider';
import { useContext, useMemo, useEffect } from 'react';
import { FieldT } from '@shared/entities';

export const useFullscreenModalMap = (fields: FieldT[]) => {
    const mapContext = useContext(MapContext);

    const { setFields, calculateCentroid } = mapContext || {};

    if (!mapContext) {
        throw new Error('MapContext is null');
    }

    const polygons = fields?.map((field) => field.polygon);

    const memoizedFields = useMemo(
        () => fields,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [JSON.stringify(fields)],
    );

    useEffect(() => {
        if (setFields) {
            setFields(memoizedFields);
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [memoizedFields]);

    return { calculateCentroid, polygons };
};
