import { useWatch, useFormContext, useFormState } from 'react-hook-form';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { useEffect } from 'react';

export const useCoverCropDestructionMethodQuestion = () => {
    const destructionDate = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE });
    const { setValue } = useFormContext();
    const { dirtyFields } = useFormState();

    useEffect(() => {
        if (destructionDate === null && dirtyFields[COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE]) {
            setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID, null);
        }
    }, [destructionDate, setValue, dirtyFields]);

    return {
        isVisible: destructionDate !== null,
    };
};
