import { makeStyles } from '@soil-capital/ui-kit/style';

export const useDropZoneStyles = makeStyles()((theme) => ({
    dropZone: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    uploadHelpBox: {
        width: '100%',
        padding: theme.spacing(3),
    },
    hover: {
        backgroundColor: theme.palette.secondaryScale['400'],
    },
    uploadSection: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    uploadIcon: {
        color: theme.palette.secondaryScale['300'],
    },
    fileList: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    container: {
        backgroundColor: theme.palette.darkScale['100'],
        borderRadius: theme.shape.borderRadiusS,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),
        gap: theme.spacing(1),
    },
    icon: {
        cursor: 'pointer',
        fontSize: '1.1em',
    },
    fileImportError: {
        color: theme.palette.error.main,
        fontWeight: theme.typography.fontWeightLight,
    },
}));
