import { z } from 'zod';
import { OperationFormSchema } from './OperationFormSchema';
import { ShallowWorkFormBodyT } from '../api/operationFormApi.types';

export const shallowWorkFormSchema = new OperationFormSchema(
    {
        machinery_id: z.number().nullable(),
        passage: z.number().nullable(),
        depth: z.number().nullable(),
        soil_work_width: z.number().nullable(),
        interrow: z.number().nullable(),
    },
    (apiData?: ShallowWorkFormBodyT) => ({
        depth: apiData?.depth ?? null,
        interrow: apiData?.interrow ?? null,
        machinery_id: apiData?.machinery_id ?? null,
        passage: apiData?.passage ?? null,
        soil_work_width: apiData?.soil_work_width ?? null,
    }),
);

export type ShallowWorkFormSchemaT = z.infer<typeof shallowWorkFormSchema.schema>;

export const SHALLOW_WORK_FORM_INPUT_NAMES = shallowWorkFormSchema.INPUT_NAMES;
