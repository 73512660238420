import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    fieldIdentityCard: {
        borderRadius: theme.shape.borderRadiusM,
        backgroundColor: theme.palette.common.white,
        padding: theme.spacing(1),
        display: 'flex',
        gap: theme.spacing(2),
    },
    cropImg: {
        height: 'auto !important',
        minWidth: '70px',
    },
    detailSection: {
        minWidth: 0,
        padding: `${theme.spacing(1)} 0`,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        whiteSpace: 'nowrap',
    },
    fieldNameEllipsed: {
        minWidth: 0,
    },
    extraIconInfos: { display: 'flex', gap: theme.spacing(1), marginTop: theme.spacing(1) },
    iconInfo: {
        display: 'flex',
        alignItems: 'center',
        minWidth: 0,
        span: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
    },
    organicIconInfo: {
        minWidth: 'auto',
    },
}));
