// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default <E>(accessor: (p: E) => number = (p: any) => p?.id) =>
    (item1: E, item2: E) => {
        if (accessor(item1) === accessor(item2)) {
            return 0;
        }

        // if 1 of both items is negative, it is considered as bigger because it's a virtualId used for optimistic create.
        // the lower is considered as the latest data created.
        if (accessor(item1) < 0 || accessor(item2) < 0) {
            return accessor(item1) < accessor(item2) ? 1 : -1;
        }

        // classical sort order ASC
        return accessor(item1) > accessor(item2) ? 1 : -1;
    };
