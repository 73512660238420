import { useTranslation } from 'react-i18next';
import { formatDateWithTranslatedMonth } from '@shared/utils/formatDateWithTranslatedMonth';
import { PaymentStepTimelineSlugLogicPropsT } from '../PaymentStepTimeline.types';

export const usePaymentStepTimelineSlugLogic = ({
    payment,
    currency,
    isActivePayment,
    isBaselinePayment,
    canRenew,
    currentRenewalDiscount,
    totalDiscount,
    isFuturePayment,
    isPendingPayment,
    isFailedPayment,
    isNetOffPayment,
    ceilingPrice,
    floorPrice,
    isFloorOrCeilingPaidPayment,
    isFloorOrCeilingPayment,
    isDiscountedPaidPayment,
    isDiscountedPayment,
    isDiscountedFloorOrCeilingPaidPayment,
    isDiscountedFloorOrCeilingPayment,
}: PaymentStepTimelineSlugLogicPropsT) => {
    const { t } = useTranslation();

    const priceTooltipDeferredPaymentFutureHarvestSlug = t(
        'billing.subscription.timeline.price-tooltip.deferred-payment.future-harvest',
        {
            currency,
            price_per_hectare: payment.price_amount,
            floor_price: floorPrice,
            ceiling_price: ceilingPrice,
        },
    );

    const priceTooltipDiscountSlug = t('billing.subscription.timeline.price-tooltip.harvest-discount', {
        paid_price: payment.total_excluding_tax,
        subscription_price: payment.subtotal,
        currency,
        discount: isActivePayment ? currentRenewalDiscount : totalDiscount,
        payable_hectares: payment.quantity,
        price_per_hectare: payment.price_amount,
    });

    const priceTooltipDiscountFloorOrCeilingSlug = t(
        'billing.subscription.timeline.price-tooltip.harvest-discount.floor-ceiling',
        {
            paid_price: payment.total_excluding_tax,
            subscription_price: payment.subtotal,
            minOrMax: payment.is_ceiling ? t('constants.maximum') : t('constants.minimum'),
            currency,
            discount: isActivePayment ? currentRenewalDiscount : totalDiscount,
            payable_hectares: payment.quantity,
        },
    );

    const priceTooltipFloorOrCeilingSlug = t('billing.subscription.timeline.price-tooltip.harvest.floor-ceiling', {
        subscription_price: payment.total_excluding_tax,
        minOrMax: payment.is_ceiling ? t('constants.maximum') : t('constants.minimum'),
        currency,
        payable_hectares: payment.quantity,
    });

    const defaultPriceTooltip = t('billing.subscription.timeline.price-tooltip.harvest', {
        payable_hectares: payment.quantity,
        subscription_price: payment.subtotal,
        price_per_hectare: payment.price_amount,
        currency,
    });

    const title = isBaselinePayment
        ? t('billing.subscription.timeline.reference-year', { harvest_year: payment.harvest_year })
        : isFuturePayment
        ? t('billing.subscription.timeline.future-harvests', {
              harvest_year: payment.harvest_year,
              year: payment.program_year,
          })
        : t('billing.subscription.timeline.harvest-payment', {
              harvest_year: payment.harvest_year,
              year: payment.program_year,
          });

    const displayedPrice =
        isFuturePayment ||
        (!canRenew &&
            !isBaselinePayment &&
            !payment.paid_at &&
            !isPendingPayment &&
            !isFailedPayment &&
            !isNetOffPayment)
            ? t('billing.subscription.timeline.price.subscription', {
                  currency,
                  price_per_hectare: payment.price_amount,
              })
            : payment.total_excluding_tax
            ? t('formatted-price', {
                  price: payment.total_excluding_tax,
                  currency,
              })
            : t('formatted-price', {
                  price: payment.subtotal,
                  currency,
              });

    const priceTooltip = isBaselinePayment
        ? t('billing.subscription.timeline.price-tooltip.baseline', {
              price: payment.total_excluding_tax ? payment.total_excluding_tax : payment.price_amount,
              currency,
          })
        : isFuturePayment || (!canRenew && !payment.paid_at && !isPendingPayment && !isNetOffPayment)
        ? priceTooltipDeferredPaymentFutureHarvestSlug
        : isDiscountedPaidPayment || isDiscountedPayment
        ? priceTooltipDiscountSlug
        : isDiscountedFloorOrCeilingPaidPayment || isDiscountedFloorOrCeilingPayment
        ? priceTooltipDiscountFloorOrCeilingSlug
        : isFloorOrCeilingPaidPayment || isFloorOrCeilingPayment
        ? priceTooltipFloorOrCeilingSlug
        : defaultPriceTooltip;

    const displayedPayment = isPendingPayment
        ? t('billing.subscription.timeline.payment.pending')
        : isFailedPayment
        ? t('billing.subscription.timeline.payment.failed')
        : payment.paid_at
        ? t('billing.subscription.timeline.payment.paid', {
              payment_date: formatDateWithTranslatedMonth(payment.paid_at, t),
          })
        : isBaselinePayment && payment.deferred_payment
        ? ''
        : isNetOffPayment
        ? t('billing.subscription.timeline.payment.net-off', { year: payment.harvest_year + 1 })
        : t('billing.subscription.timeline.payment.renewal', {
              renewal_date: payment.harvest_year,
          });

    const paymentTooltip =
        !payment.deferred_payment &&
        !isNetOffPayment &&
        payment.total_excluding_tax &&
        !isBaselinePayment &&
        !isActivePayment
            ? t('billing.subscription.timeline.payment-tooltip.full-price')
            : isNetOffPayment
            ? t('billing.subscription.timeline.payment-tooltip.net-off')
            : '';

    const displayCTA =
        (!payment.paid_at && !isBaselinePayment && !isFuturePayment && !isNetOffPayment) ||
        (payment.deferred_payment && isBaselinePayment && !payment.paid_at);

    const CTAlabel = isFailedPayment
        ? t('billing.subscription.timeline.renew-again')
        : !payment.paid_at && !isBaselinePayment
        ? t('billing.subscription.timeline.renewal')
        : t('billing.subscription.timeline.baseline-payment');

    const displayDescription =
        payment.deferred_payment ||
        isFuturePayment ||
        !!(
            isActivePayment &&
            !payment.paid_at &&
            !isBaselinePayment &&
            canRenew &&
            currentRenewalDiscount &&
            !isPendingPayment &&
            !isFailedPayment
        );

    const displayDescriptionIcon = (!isBaselinePayment && !isFuturePayment) || payment.deferred_payment;

    const descriptionTransKey = isFuturePayment
        ? 'billing.subscription.timeline.description.deferred-payment.future-harvest'
        : payment.deferred_payment
        ? 'billing.subscription.timeline.description.deferred-payment.baseline'
        : 'billing.subscription.timeline.description.future-payment';

    return {
        title,
        displayedPrice,
        priceTooltip,
        displayedPayment,
        paymentTooltip,
        displayCTA,
        CTAlabel,
        displayDescription,
        displayDescriptionIcon,
        descriptionTransKey,
    };
};
