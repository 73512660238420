import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { Input } from '@soil-capital/ui-kit/components';
import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';
import { useSeedingInterRowSpacingInputVisibility } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingInterRowSpacingInput/useSeedingInterRowSpacingInputVisibility';

type SeedingInterRowSpacingInputProps = {
    onChange: () => void;
};

export const SeedingInterRowSpacingInput = ({ onChange }: SeedingInterRowSpacingInputProps) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplay } = useSeedingInterRowSpacingInputVisibility();

    if (!context) throw new Error('SeedingInterRowSpacingInput must be used within a FormProvider');

    return shouldDisplay ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.seeding.question.seed-interrow-spacing')}
                tooltipTitleKey="encoding-technical-itinerary.seeding.question.seed-interrow-spacing.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.seeding.question.seed-interrow-spacing.tooltip.text"
            />
            <Input
                sx={{ maxWidth: 250 }}
                onChange={onChange}
                type="number"
                suffix="cm"
                defaultValue={null}
                inputProps={{
                    ...context.register(SEEDING_FORM_INPUT_NAMES.INTERROW_SPACING, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
            />
        </FormRow>
    ) : (
        <></>
    );
};
