import { makeStyles } from '@soil-capital/ui-kit/style';

export const usePacImportContextualPanelStyles = makeStyles()((theme) => ({
    panel: {
        position: 'relative',
        overflow: 'hidden',
    },
    container: {
        paddingTop: theme.spacing(2),
        height: '100%',
    },
    helpbox: {
        marginTop: theme.spacing(3),
    },
    content: {
        paddingTop: theme.spacing(2),
        // Minus header, footer and some bottom padding
        maxHeight: 'calc(100% - 60px - 150px - 70px)',
        height: '100%',
    },
    footer: {
        background: theme.palette.common.white,
        borderTop: `1px solid ${theme.palette.darkScale[200]}`,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: `${theme.spacing(1)} 0`,
        position: 'absolute',
        left: 0,
        right: 0,
        bottom: 0,
    },
}));
