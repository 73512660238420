import { useTranslation } from 'react-i18next';
import { useFarmSeasonFieldsDeleteModalLogic } from '@modules/encoding/modules/rotation/components/FarmSeasonFieldsDeleteModal/useFarmSeasonFieldsDeleteModalLogic';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';

type FarmSeasonFieldsDeleteModalProps = {
    open: boolean;
    onClose: () => void;
};

export const FarmSeasonFieldsDeleteModal = ({ open, onClose }: FarmSeasonFieldsDeleteModalProps) => {
    const { t } = useTranslation();
    const { farmSeasonFieldsCount, onDeleteConfirm, isDeletingFields } = useFarmSeasonFieldsDeleteModalLogic({
        onClose,
    });

    return (
        <ConfirmModal
            isOpen={open}
            type="warning"
            onClose={({ confirmed }) => (confirmed ? onDeleteConfirm() : onClose())}
            title={t('encoding-rotation.modal.fields-delete-modal.title')}
            primaryText={{
                translationKey: 'encoding-rotation.modal.fields-delete-modal.warning',
                translationValues: {
                    count: farmSeasonFieldsCount,
                },
            }}
            secondaryText={t('encoding-rotation.modal.fields-delete-modal.description')}
            confirmText={t('constants.confirm-deletion')}
            confirmLoading={isDeletingFields}
            cancelText={t('constants.cancel')}
        />
    );
};
