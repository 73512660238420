import { useRoutes } from 'react-router-dom';
import { eligibilityRoutesConfig } from './eligibility.routes';
import { EligibilityForm } from './EligibilityForm/EligibilityForm';
import { Eligible } from './Eligible/Eligible';
import { NonEligible } from './NonEligible/NonEligible';
import { Livestock } from './Livestock/Livestock';
import { LivestockNonEligible } from './LivestockNonEligible/LivestockNonEligible';
import { CountryNonEligible } from './CountryNonEligible/CountryNonEligible';

export default () => {
    const routes = useRoutes([
        {
            path: eligibilityRoutesConfig.eligibilityForm,
            element: <EligibilityForm />,
        },
        {
            path: eligibilityRoutesConfig.livestock,
            element: <Livestock />,
        },
        {
            path: eligibilityRoutesConfig.eligible,
            element: <Eligible />,
        },
        {
            path: eligibilityRoutesConfig.nonEligible,
            element: <NonEligible />,
        },
        {
            path: eligibilityRoutesConfig.livestockNonEligible,
            element: <LivestockNonEligible />,
        },
        {
            path: eligibilityRoutesConfig.countryNonEligible,
            element: <CountryNonEligible />,
        },
    ]);

    return <>{routes}</>;
};
