import { BaseEntityT } from '@shared/entities/baseEntity.types';

export const FERTILISER_UNIT = {
    L: 'L',
    KG: 'Kg',
} as const;

export type FertiliserUnitT = (typeof FERTILISER_UNIT)[keyof typeof FERTILISER_UNIT];

export const FERTILISER_TYPE_SLUGS = {
    MINERAL_FERTILISER: 'mineral-fertiliser',
    ORGANIC_FERTILISER: 'organic-fertiliser',
    MICRO_ORGANISMS: 'micro-organisms',
    ORGANIC_AMENDMENT: 'organic-amendment',
    MINERAL_SOIL_AMENDMENT: 'mineral-soil-amendment',
    PLANT_BASED_FERTILISER: 'plant-based-fertiliser',
} as const;

export type FertiliserTypeSlugT = (typeof FERTILISER_TYPE_SLUGS)[keyof typeof FERTILISER_TYPE_SLUGS];

export type StaticFertiliserT = {
    fertiliser_type_slug: FertiliserTypeSlugT;
    translation_slug: string;
    unit: FertiliserUnitT;
    n: number;
    name: string;
    p: number;
    k: number;
    ca: number;
    mg: number;
    s: number;
    farm_id: number | null;
} & BaseEntityT;
