import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFallbackComponentStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        minHeight: '100vh',
        gap: theme.spacing(2),
    },
}));
