import { useParams } from 'react-router-dom';
import { TechnicalItineraryRouteParamsT } from '../technicalItinerary.routes';

export const useOperationRouteParams = () => {
    const { fieldCropId, operationId, seasonId } = useParams<TechnicalItineraryRouteParamsT['operationForm']>();
    if (!fieldCropId || !operationId || !seasonId) {
        throw new Error('Missing params in route');
    }
    return {
        fieldCropId: Number(fieldCropId),
        operationId: Number(operationId),
        seasonId: Number(seasonId),
    };
};
