import {
    useCreateFarmSeasonProfileMutation,
    useUpdateFarmSeasonProfileMutation,
} from '@modules/encoding/modules/farmProfile/api/farmProfileApi';
import { type FarmSeasonProfileUpdatePayloadT } from '@modules/encoding/modules/farmProfile/api/farmProfileApi.types';

type useCreateOrUpdateFarmSeasonProfileProps = {
    farmSeasonId: number;
    farmSeasonProfileId?: number;
};

export const useCreateOrUpdateFarmSeasonProfile = ({
    farmSeasonId,
    farmSeasonProfileId,
}: useCreateOrUpdateFarmSeasonProfileProps) => {
    const [createMutation, { isLoading: isLoadingCreate }] = useCreateFarmSeasonProfileMutation();
    const [updateMutation, { isLoading: isLoadingUpdate }] = useUpdateFarmSeasonProfileMutation();

    const mutate = async (data: FarmSeasonProfileUpdatePayloadT) => {
        try {
            // Profile already exists -> PATCH
            if (farmSeasonProfileId) {
                return updateMutation({
                    farmSeasonId,
                    farmSeasonProfileId: farmSeasonProfileId,
                    payload: data,
                });
            }

            return createMutation({
                farmSeasonId,
                payload: data,
            });
        } catch (e) {
            throw new Error('Could not update or create Farm season profile');
        }
    };

    return { mutate, isLoading: isLoadingCreate || isLoadingUpdate };
};
