import { Navigate, useRoutes } from 'react-router-dom';
import { preparationRoutesConfig } from '@modules/encoding/modules/preparation/preparation.routes';
import { PreparationContentPage } from '@modules/encoding/modules/preparation/pages/PreparationContentPage/PreparationContentPage';
import { encodingShared } from '@modules/encoding/shared';
import { NOT_FOUND_ROUTE } from '@shared/errors';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { ActionPageSkeleton } from '@modules/encoding/shared/components/ActionPage/ActionPageSkeleton';
import { IsStepVisibleGuard } from '@shared/guards/IsStepVisibleGuard';
import { Guard } from '@shared/guards/Guard';

export default () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const routes = useRoutes([
        {
            path: preparationRoutesConfig.introduction,
            element: (
                <Guard
                    element={<PreparationContentPage />}
                    loader={<ActionPageSkeleton />}
                    guards={[IsStepVisibleGuard]}
                />
            ),
        },
        { path: '*', element: <Navigate to={`/${currentSeasonId}${NOT_FOUND_ROUTE}`} /> },
    ]);

    return (
        <encodingShared.FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
            {routes}
        </encodingShared.FadeTransition>
    );
};
