import { z } from 'zod';
import { OperationFormSchema } from './OperationFormSchema';
import { FormFertiliserProductDataT, MineralFertiliserFormDataT } from '../api/operationFormApi.types';

const productSchema = z.object({
    static_fertiliser_id: z.number(),
    quantity: z.number().nullable().nullable(),
    inhibitor: z.boolean().nullable().nullable(),
});

const mapFertiliserProtuctApiDataToSchema = (
    fertiliserData: FormFertiliserProductDataT,
): MineralFertilisationProductSchemaT => ({
    static_fertiliser_id: fertiliserData.static_fertiliser_id,
    inhibitor: fertiliserData.inhibitor,
    quantity: fertiliserData.quantity,
});

export const mineralFertilisationFormSchema = new OperationFormSchema(
    {
        mineral_fertiliser_solid_products: z.array(productSchema),
        mineral_fertiliser_liquid_products: z.array(productSchema),
        sprayer_width: z.number().nullable(),
    },
    (apiData?: MineralFertiliserFormDataT) => {
        return {
            mineral_fertiliser_solid_products:
                apiData?.mineral_fertiliser_solid_products.map(mapFertiliserProtuctApiDataToSchema) || [],
            mineral_fertiliser_liquid_products:
                apiData?.mineral_fertiliser_liquid_products.map(mapFertiliserProtuctApiDataToSchema) || [],
            sprayer_width: apiData?.sprayer_width || null,
        };
    },
    ['sprayer_width'],
);

export const MINERAL_FERTILISER_FORM_INPUT_NAMES = mineralFertilisationFormSchema.INPUT_NAMES;

export type MineralFertilisationFormSchemaT = z.infer<typeof mineralFertilisationFormSchema.schema>;

export type MineralFertilisationInputNameT =
    (typeof MINERAL_FERTILISER_FORM_INPUT_NAMES)[keyof typeof MINERAL_FERTILISER_FORM_INPUT_NAMES];
export type MineralFertilisationProductSchemaT = z.infer<typeof productSchema>;
