import { StaticTillagePracticeT } from '@shared/entities';
import {
    IconTillageConventional,
    IconTillageNone,
    IconTillageReduced,
    IconTillageUnknown,
} from '@soil-capital/ui-kit/icons';
import { SvgIconProps } from '@soil-capital/ui-kit/material-core';

export const cropGroupSlugImgMapping: {
    [k in StaticTillagePracticeT['name']]: (p: SvgIconProps) => React.JSX.Element;
} = {
    'conventional till': IconTillageConventional,
    'no till': IconTillageNone,
    'reduced till': IconTillageReduced,
    'no tillage': IconTillageUnknown,
};

export default (tillageName: StaticTillagePracticeT['name'] | null | undefined) => {
    if (!tillageName || !cropGroupSlugImgMapping[tillageName]) {
        return { TillageIconComponent: () => null };
    }

    return { TillageIconComponent: cropGroupSlugImgMapping[tillageName] };
};
