import { useCallback } from 'react';
import createStaticEntity from '../createStaticEntity';
import { StaticOperationTypeConfigT } from '@shared/entities';
import { OperationTypePeriodT } from '@shared/entities/staticOperationTypeConfig/staticOperationTypeConfig.types';

export default createStaticEntity<StaticOperationTypeConfigT>()({
    entityName: 'staticOperationTypeConfig',
    url: () => `/v3/static/operation-type-configs`,
    useGetCustomState: (defaultState) => {
        const getByPeriod = useCallback(
            (period: OperationTypePeriodT) => ({
                ...defaultState,
                list: defaultState.list.filter((operationTypeConfig) => operationTypeConfig.period === period),
            }),
            [defaultState],
        );

        return {
            ...defaultState,
            getByPeriod,
        };
    },
});
