import { SnackbarProvider } from 'notistack';

import Snackbar from './Snackbar';
import { Slide } from '@soil-capital/ui-kit/material-core';
import { ReactNode } from 'react';

export default ({ children }: { children: ReactNode }) => (
    <SnackbarProvider
        maxSnack={3}
        Components={{
            success: Snackbar,
            error: Snackbar,
            info: Snackbar,
        }}
        TransitionComponent={(props) => <Slide {...props} direction="up" />}
    >
        {children}
    </SnackbarProvider>
);
