import { makeStyles } from '@soil-capital/ui-kit/style';

const useOperationTimelineInputGroupCardStyle = makeStyles()((theme) => ({
    operationGroupTitle: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        gap: theme.spacing(1),
    },
    spacedCard: {
        marginBottom: theme.spacing(1),
    },
    flexColumn: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    inputRowChild: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
    iconClose: {
        color: theme.palette.darkScale[900],
    },
    iconCloseZone: {
        height: '34px',
        width: '34px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    addNewDate: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
    },
}));

export default useOperationTimelineInputGroupCardStyle;
