import operation from '@shared/entities/operation/index';
import staticOperationType from '@shared/entities/staticOperationType/index';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useParams } from 'react-router-dom';
import { useGetOperationDateString } from '@modules/encoding/modules/technicalItinerary/hooks/useGetOperationDateString';

export const useGetCurrentOperationFromUrl = () => {
    const { fieldCropId: fieldCropIdParam, operationId: operationIdParam } = useParams();
    const { currentSeasonId } = useCurrentSeasonId();
    const fieldCropId = fieldCropIdParam ? Number(fieldCropIdParam) : undefined;
    const operationId = operationIdParam ? Number(operationIdParam) : undefined;

    const { getOperationDateString } = useGetOperationDateString();
    const { operationState } = operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: currentSeasonId,
    });
    const { staticOperationTypeState } = staticOperationType.useState();

    if (!operationId || !fieldCropId) return { operation: null };

    const currentOperation = operationState.list.find((op) => op.id === operationId);
    const operationSlug = staticOperationTypeState.getById(currentOperation?.operation_type_id)?.slug;
    const operationDateFormatted = getOperationDateString(currentOperation?.operation_date);
    const oprt = currentOperation
        ? { ...currentOperation, slug: operationSlug, operation_date_formatted: operationDateFormatted }
        : null;

    return { operation: oprt };
};
