import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { CoverCropForm } from '../../components/CoverCropForm/CoverCropForm';
import { useCoverCropActionPage } from './useCoverCropActionPage';

export const CoverCropActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    const { errors } = useCoverCropActionPage();

    return (
        <encodingShared.OperationFormActionPage
            title={t('encoding-technical-itinerary.cover-crop.title')}
            navigate={navigate}
            errors={errors}
            renderForm={(errorDisplayed) => <CoverCropForm errorDisplayed={errorDisplayed} />}
        />
    );
};
