import { SEEDING_STATIC_DATA_TYPE } from '@modules/encoding/modules/technicalItinerary/module/seeding/seeding.types';
import { useGetSeedingStaticDataByTypeQuery } from '@modules/encoding/modules/technicalItinerary/module/seeding/api/seedingApi';
import { useTranslation } from 'react-i18next';
import { useGetSeedingMachineries } from '@modules/encoding/modules/technicalItinerary/module/seeding/hooks/useGetSeedingMachineries';

export const useSeedingFormOptions = () => {
    const { t } = useTranslation();
    const { data: seedOrigins, isLoading: isLoadingSeedOrigin } = useGetSeedingStaticDataByTypeQuery({
        type: SEEDING_STATIC_DATA_TYPE.SEEDING_ORIGIN,
    });

    const seedOriginsOptions = seedOrigins
        ?.map((seedOrigin) => ({
            label: t(`static-data.slug.${seedOrigin.slug}`),
            value: seedOrigin.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const { seedingMachineries, isLoading: isLoadingSeedingMachineries } = useGetSeedingMachineries();

    const computedMachineries = seedingMachineries
        ?.map((machinery) => ({
            value: machinery.id,
            label: t(`db.machineries.${machinery.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const isLoading = isLoadingSeedOrigin || isLoadingSeedingMachineries;

    return {
        seedingOrigins: seedOriginsOptions || [],
        seedingMachineries: computedMachineries || [],
        isLoading,
    };
};
