import { z } from 'zod';
import { OrganicFertiliserFormDataT } from '../api/operationFormApi.types';
import { OperationFormSchema } from './OperationFormSchema';

const productSchema = z.object({
    static_fertiliser_id: z.number(),
    quantity: z.number().nullable(),
});

export const organicFertilisationFormSchema = new OperationFormSchema(
    {
        application_method_id: z.number().nullable(),
        organic_fertiliser_solid_products: z.array(productSchema),
        organic_fertiliser_liquid_products: z.array(productSchema),
    },
    (apiData?: OrganicFertiliserFormDataT) => {
        return {
            application_method_id: apiData?.application_method_id || null,
            organic_fertiliser_solid_products:
                apiData?.organic_fertiliser_solid_products.map((fertiliser) => ({
                    static_fertiliser_id: fertiliser?.static_fertiliser_id,
                    quantity: fertiliser?.quantity ?? null,
                })) || [],
            organic_fertiliser_liquid_products:
                apiData?.organic_fertiliser_liquid_products.map((fertiliser) => ({
                    static_fertiliser_id: fertiliser?.static_fertiliser_id,
                    quantity: fertiliser?.quantity ?? null,
                })) || [],
        };
    },
    [],
);

export type OrganicFertilisationFormSchemaT = z.infer<typeof organicFertilisationFormSchema.schema>;
export type OrganicFertilisationProductSchemaT = z.infer<typeof productSchema>;

export type OrganicFertiliserInputNameT =
    (typeof ORGANIC_FERTILISER_FORM_INPUT_NAMES)[keyof typeof ORGANIC_FERTILISER_FORM_INPUT_NAMES];

export const ORGANIC_FERTILISER_FORM_INPUT_NAMES = organicFertilisationFormSchema.INPUT_NAMES;
