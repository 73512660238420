import { useState } from 'react';
import { useGetReusedOperationQuery, useReuseOperationMutation } from '../../../api/duplicationApi';
import entities from '@shared/entities';
import { useTranslation } from 'react-i18next';

export const useReuseOperationLogic = ({
    farmSeasonId,
    operationId,
    fieldCropId,
}: {
    farmSeasonId: number;
    operationId: number;
    fieldCropId: number;
}) => {
    const { t } = useTranslation();
    const [sourceOperationDuplicationId, setSourceOperationDuplicationId] = useState<number>();
    const { data: operationDuplicationData } = useGetReusedOperationQuery({
        farmSeasonId,
        operationId,
        fieldCropId,
    });
    const { operationState } = entities.operation.useState({ farmSeasonFieldCropId: fieldCropId, farmSeasonId });
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const operationTypeText = t(
        staticOperationTypeState.getById(operationState.getById(operationId)?.operation_type_id)?.translation_slug ??
            '',
    ).toLowerCase();
    const reuseOperationQuestionText = t('encoding-technical-itinerary.reuse-operation-component.select-label', {
        operationType: operationTypeText,
    });

    const [reuseOperationMutation] = useReuseOperationMutation();

    const handleReuseOperation = async (newSourceOperationId: number) => {
        setSourceOperationDuplicationId(newSourceOperationId);
        await reuseOperationMutation({
            fieldCropId,
            operationId,
            farmSeasonId,
            payload: {
                source_farm_season_operation_id: newSourceOperationId,
            },
        });
    };

    return {
        sourceOperationDuplicationId:
            sourceOperationDuplicationId ?? operationDuplicationData?.source_farm_season_operation_id,
        handleReuseOperation,
        reuseOperationQuestionText,
    };
};
