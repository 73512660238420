import { Grid } from '@soil-capital/ui-kit/material-core';
import { Loader } from '@soil-capital/ui-kit/components';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { usePaymentPageLayoutStyle } from './PaymentPageLayout.style';

export const PaymentPageLoader = () => {
    const theme = useTheme();
    const { classes } = usePaymentPageLayoutStyle();

    return (
        <Grid display="flex" justifyContent="center">
            <Grid className={classes.loaderContainer} zIndex={10}>
                <Grid
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    textAlign="center"
                    justifyContent="center"
                    height="60vh"
                    gap={theme.spacing(10)}
                >
                    <Grid display="flex" justifyContent="space-evenly" alignItems="center">
                        <Loader size={90} thickness={4} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
