import { makeStyles } from '@soil-capital/ui-kit/style';

export const usePacFieldListStyles = makeStyles()((theme) => ({
    list: {
        height: '100%',
        display: 'contents',
    },
    iconContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    iconEdit: {
        width: 20,
        cursor: 'pointer',
    },
    iconCross: {
        width: 12,
        cursor: 'pointer',
    },
    helpButton: {
        marginTop: theme.spacing(1),
    },
    deleteAll: {
        marginTop: theme.spacing(3),
    },
    fieldCropInfoBox: {
        paddingRight: 0,
    },
    skeletonList: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    button: {
        minWidth: 32,
        width: 32,
        height: 32,
        padding: theme.spacing(1),
    },
}));
