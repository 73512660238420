import { useForm } from '@shared/hooks';
import { z } from 'zod';
import { useGetTermsAndConditions } from '@shared/hooks/useGetTermsAndConditions';
import { useNavigate } from 'react-router-dom';
import { useGetTACQuery, usePostTACMutation } from '@modules/onboarding/api/onboardingApi';
import { auth } from '@modules/auth';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import entities from '@shared/entities';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';

export const COMMITMENT = {
    APPROVAL: 'approval',
    LANDOWNERAPPROVAL: 'land_owner_approval',
    SUNFLOWER: 'exclude_sunflower',
    RAPESEED: 'exclude_rapeseed',
} as const;

export const useCommitmentLogic = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const farmSeasonId = auth.useLatestFarmSeasonId();
    const { farmState } = entities.farm.useState({ farmSeasonId: farmSeasonId ?? 0 }, { skip: !farmSeasonId });
    const farm = farmState.list[0];
    const isFrenchFarm = farm?.country?.iso_code === 'FR';
    const { data: partnerData } = useGetPartnerInfoQuery({
        userId: auth.useAuthUser()?.id,
    });
    const { data: tacData, isLoading: isTacDataLoading } = useGetTACQuery({ farmSeasonId });

    const defaultValues = {
        [COMMITMENT.APPROVAL]: tacData?.approval ?? false,
        [COMMITMENT.LANDOWNERAPPROVAL]: tacData?.land_owner_approval ?? false,
        [COMMITMENT.SUNFLOWER]: tacData?.exclude_sunflower ?? false,
        [COMMITMENT.RAPESEED]: tacData?.exclude_rapeseed ?? false,
    };

    const schema = z.object({
        [COMMITMENT.APPROVAL]: z.boolean(),
        [COMMITMENT.LANDOWNERAPPROVAL]: z.boolean(),
        [COMMITMENT.SUNFLOWER]: z.boolean(),
        [COMMITMENT.RAPESEED]: z.boolean(),
    });

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: true,
    });

    const {
        handleSubmit,
        formState: { isValid, errors },
        register,
        watch,
        setValue,
    } = methods;
    const hasApproved = watch(COMMITMENT.APPROVAL);
    const [postTAC, { isLoading: isPostTACLoading }] = usePostTACMutation();

    const onSubmit = handleSubmit(async (data) => {
        if (!farmSeasonId) return;
        const response = await postTAC({ farmSeasonId, body: data });

        if ('data' in response && response.data) {
            if (partnerData?.is_partner_prepaying) {
                navigate('/onboarding/confirmation');
            } else navigate('/onboarding/pricing');
        } else {
            enqueueSnackbar(t('constants.server-error'), { variant: 'error' });
        }
    });

    const onBackClick = () => {
        navigate('/onboarding/billing-information');
    };

    const { termsAndConditionsPdfLink, landownerApprovalPdfLink } = useGetTermsAndConditions();

    useEffect(() => {
        if (tacData) {
            setValue('approval', tacData.approval);
            setValue('land_owner_approval', tacData.land_owner_approval);
            setValue('exclude_sunflower', tacData.exclude_sunflower);
            setValue('exclude_rapeseed', tacData.exclude_rapeseed);
        }
    }, [tacData, setValue]);

    return {
        defaultValues,
        errors,
        methods,
        onSubmit,
        isValid,
        register,
        hasApproved,
        termsAndConditionsPdfLink,
        landownerApprovalPdfLink,
        onBackClick,
        isTacDataLoading,
        isPostTACLoading,
        isFrenchFarm,
        t,
    };
};
