import { createEntityAdapter } from '@reduxjs/toolkit';
import { StaticFertiliserT } from './staticFertiliser.types';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import createCacheQueryHelper from '../createCacheQueryHelper';
import api from '@shared/api';
import { StaticFertiliserEndpointParamsT } from '.';

export const staticFertiliserSortComparer = getDefaultSortComparerById<StaticFertiliserT>();

export const staticFertiliserCacheAdapter = createEntityAdapter<StaticFertiliserT>({
    sortComparer: staticFertiliserSortComparer,
});

const staticFertiliserCacheQueryHelper = createCacheQueryHelper<
    StaticFertiliserEndpointParamsT,
    StaticFertiliserT,
    'StaticFertiliser'
>(api, 'StaticFertiliser', staticFertiliserCacheAdapter);

export const staticFertiliserCache = {
    add: staticFertiliserCacheQueryHelper.add,
};
