import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormRow } from '@shared/components';
import { MenuItem, Select } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useReusableOperationOptions } from './useReusableOperationOptions';
import { useReuseOperationLogic } from './useReuseOperationLogic';
import { HandleReuseOperationParams } from '../../hooks/useReuseOperation';
import { ConfirmReuseOperationModal } from '../ConfirmReuseOperationModal/ConfirmReuseOperationModal';
import { useModalController } from '@shared/hooks/useModalController';
import { ConfirmReuseOperationModalProps } from '../ConfirmReuseOperationModal/ConfirmReuseOperationModal.types';
import { ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';

export type ReuseOperationSelectProps = {
    fieldCropId: number;
    farmSeasonId: number;
    operationId: number;
    onReuseOperationTriggered: (params: HandleReuseOperationParams) => void;
    disabled?: boolean;
    actionLoading?: boolean;
};

export const ReuseOperationSelect = ({
    fieldCropId,
    farmSeasonId,
    operationId,
    onReuseOperationTriggered,
    disabled,
    actionLoading,
}: ReuseOperationSelectProps) => {
    const { t } = useTranslation();
    const { reusableOperationOptions } = useReusableOperationOptions({
        fieldCropId,
        farmSeasonId,
        operationId,
    });
    const { sourceOperationDuplicationId, handleReuseOperation, reuseOperationQuestionText } = useReuseOperationLogic({
        fieldCropId,
        farmSeasonId,
        operationId,
    });

    const { open: openConfirmReuseOperationModal, ...confirmReuseOperationModalProps } = useModalController<
        ConfirmReuseOperationModalProps['data'],
        ConfirmModalCloseDataT
    >();

    const handleReuseOperationUpdate = (newSourceOperationId: number) => {
        onReuseOperationTriggered({
            handleReuseOperation: () => handleReuseOperation(newSourceOperationId),
            openConfirmReuseOperationModal: () => {
                return openConfirmReuseOperationModal({
                    sourceOperationId: newSourceOperationId,
                    targetOperationId: operationId,
                });
            },
        });
    };

    if (!reusableOperationOptions.length) {
        return null;
    }

    return (
        <FormRow>
            <FormQuestion
                question={reuseOperationQuestionText}
                tooltipTitleKey="encoding-technical-itinerary.reuse-operation-component.select-label-tooltip-title"
                tooltipTextKey="encoding-technical-itinerary.reuse-operation-component.select-label-tooltip-text"
            />
            <Select
                disabled={disabled}
                value={sourceOperationDuplicationId ?? -1}
                sx={{ width: '250px' }}
                endAdornment
                actionLoading={actionLoading}
            >
                <MenuItem value={-1} sx={{ display: 'none' }}>
                    <Typography color="neutral" variant="body">
                        {t('encoding-technical-itinerary.reuse-operation-component.select-label-placeholder')}
                    </Typography>
                </MenuItem>
                {reusableOperationOptions.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        onClick={() => handleReuseOperationUpdate(option.value)}
                    >
                        {option.label}
                    </MenuItem>
                ))}
            </Select>
            <ConfirmReuseOperationModal
                {...confirmReuseOperationModalProps}
                fieldCropId={fieldCropId}
                farmSeasonId={farmSeasonId}
            />
        </FormRow>
    );
};
