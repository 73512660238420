import { useCallback } from 'react';
import createStaticEntity from '../createStaticEntity';
import { StaticOperationTypeT } from '@shared/entities';
import { OperationTypeSlugT } from '@shared/entities/staticOperationType/staticOperationType.types';

export default createStaticEntity<StaticOperationTypeT>()({
    entityName: 'staticOperationType',
    url: () => `/v3/static/operation-types`,
    useGetCustomState: (defaultState) => {
        const getByParentId = useCallback(
            (parentId: number | null) => {
                return defaultState.list.filter((item) => item.parent_id === parentId);
            },
            [defaultState.list],
        );

        const getBySlug = useCallback(
            (slug: OperationTypeSlugT) => defaultState.list.find((el) => el.slug === slug),
            [defaultState.list],
        );

        return { ...defaultState, getByParentId, getBySlug };
    },
    sortComparer: (operationType1, operationType2) =>
        operationType1.order === operationType2.order ? 0 : operationType1.order > operationType2.order ? 1 : -1,
});
