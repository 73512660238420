import { useEffect, useMemo, useRef, useState } from 'react';
import { OperationDatePickerLogicPropsT, Period } from './OperationDatePicker.types';
import { useGetOperationDateString } from '../../hooks/useGetOperationDateString';
import getDateToIsoWithoutTimezone from '@shared/utils/getDateToIsoWithoutTimezone';

export default ({ max, min, onChange, startMonthIndex, value }: OperationDatePickerLogicPropsT) => {
    if (!startMonthIndex) {
        startMonthIndex = 0;
    }

    const [panel, setPanel] = useState<'month' | 'period'>('month');
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const panelTitle = useMemo(() => selectedDate.getFullYear()?.toString(), [selectedDate]);
    const fakeMenuItemRef = useRef<HTMLLIElement>(null);
    const { getOperationDateString } = useGetOperationDateString();

    useEffect(() => {
        const defaultDate = computeDateValue(value, min, max);

        setSelectedDate(defaultDate);
        if (value) {
            setPanel('period');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const computeDateValue = (value: Date | null, min?: Date, max?: Date) => {
        let defaultDate = value ?? new Date();
        if (min && min > defaultDate) {
            defaultDate = min;
        }
        if (max && max < defaultDate) {
            defaultDate = max;
        }

        return defaultDate;
    };

    const resetPanelView = () => {
        if (value) {
            setSelectedDate(computeDateValue(value, min, max));
            setPanel('period');
        } else {
            setPanel('month');
        }
    };

    const closePickerByFakeClick = () => {
        fakeMenuItemRef.current?.click();
    };

    const monthlabels = [
        'constants.months.january',
        'constants.months.february',
        'constants.months.march',
        'constants.months.april',
        'constants.months.may',
        'constants.months.june',
        'constants.months.july',
        'constants.months.august',
        'constants.months.september',
        'constants.months.october',
        'constants.months.november',
        'constants.months.december',
    ];

    const months = monthlabels.slice(startMonthIndex).concat(monthlabels.slice(0, startMonthIndex));

    const periodMatchingDays: {
        [key in Period]: {
            value: number;
            prefix: string;
        };
    } = {
        early: {
            value: 5,
            prefix: 'encoding-technical-itinerary.timeline.period.early',
        },
        mid: {
            value: 15,
            prefix: 'encoding-technical-itinerary.timeline.period.mid',
        },
        late: {
            value: 25,
            prefix: 'encoding-technical-itinerary.timeline.period.late',
        },
    };

    const setMonth = (month: number) => {
        const date = new Date(selectedDate);

        if (startMonthIndex) {
            month = month + startMonthIndex;
            if (month > 11) {
                month = month - 12;
            }
        }

        date.setMonth(month, value ? value?.getUTCDate() : 1);
        setSelectedDate(date);

        setPanel('period');
    };

    const setPeriod = (period: Period) => {
        const dateWithoutTimezone = getDateToIsoWithoutTimezone(selectedDate);

        if (dateWithoutTimezone) {
            const date = new Date(dateWithoutTimezone);
            date.setDate(periodMatchingDays[period].value);

            setSelectedDate(date);

            if (onChange) {
                onChange(getDateToIsoWithoutTimezone(date));
                closePickerByFakeClick();
            }
        }
    };

    const getPeriod = (date: Date) => {
        let day = date.getDate();

        if (day != 5 && day != 15 && day != 25) {
            if (day > 25 || (day >= 20 && day < 25)) {
                day = 25;
            } else if (day < 5 || (day <= 10 && day > 5)) {
                day = 5;
            } else {
                day = 15;
            }
        }

        const period = Object.keys(periodMatchingDays).find((period) => {
            const periodDays = periodMatchingDays[period as Period];
            return day > 1 && day <= periodDays.value;
        });

        return period as Period;
    };

    const increaseYear = () => {
        const date = new Date(selectedDate);
        date.setFullYear(date.getFullYear() + 1);

        setSelectedDate(date);
    };

    const decreaseYear = () => {
        const date = new Date(selectedDate);
        date.setFullYear(date.getFullYear() - 1);

        setSelectedDate(date);
    };

    const canIncreaseYear = () => {
        if (!max) {
            return true;
        }
        return selectedDate.getFullYear() < max.getFullYear();
    };

    const canDecreaseYear = () => {
        if (!min) {
            return true;
        }
        return selectedDate.getFullYear() > min.getFullYear();
    };

    const backButtonClicked = () => {
        setPanel('month');
    };

    const displayedValue = getOperationDateString(value?.toString());

    const operationDateStringHelper = (period: number) => {
        const date = new Date(selectedDate);
        date.setDate(period);

        return getOperationDateString(date.toString());
    };

    return {
        panel,
        panelTitle,
        months,
        setMonth,
        setPeriod,
        getPeriod,
        increaseYear,
        decreaseYear,
        canIncreaseYear,
        canDecreaseYear,
        backButtonClicked,
        periodMatchingDays,
        selectedDate,
        displayedValue,
        fakeMenuItemRef,
        resetPanelView,
        operationDateStringHelper,
    };
};
