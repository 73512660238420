import { makeStyles } from '@soil-capital/ui-kit/style';

export const useEncodingStepStyles = makeStyles()((theme) => ({
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginBottom: theme.spacing(1.5),
    },
    title: {
        display: 'flex',
        gap: theme.spacing(1),
    },
}));
