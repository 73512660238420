import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useCoverCropDestructionHeightQuestion } from './useCoverCropDestructionHeightQuestion';
import { useTranslation } from 'react-i18next';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';
import { FormAutocomplete, FormAutoCompleteOptionT } from '@shared/components/FormAutocomplete/FormAutocomplete';

export const CoverCropDestructionHeightQuestion = ({
    options,
    onChange,
}: {
    options: FormAutoCompleteOptionT[];
    onChange: () => void;
}) => {
    const { isVisible } = useCoverCropDestructionHeightQuestion();
    const { t } = useTranslation();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.height-before-destruction')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.height-before-destruction"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.height-before-destruction"
                    />
                    <FormAutocomplete
                        name={COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_HEIGHT_ID}
                        placeholder={t('constants.option.placeholder')}
                        onChange={onChange}
                        options={options}
                        multiple={false}
                    />
                </FormRow>
            )}
        </>
    );
};
