import { Modal } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useEncodingDoneModalStyles } from '@modules/encoding/pages/EncodingDoneModal/useEncodingDoneModal.style';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { useTranslation } from 'react-i18next';

type EncodingDoneModalProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const EncodingDoneModal = ({ open, onClose, onConfirm }: EncodingDoneModalProps) => {
    const { classes } = useEncodingDoneModalStyles();
    const { t } = useTranslation();

    return (
        <Modal
            open={open}
            onConfirm={onConfirm}
            onClose={onClose}
            size="medium"
            header={
                <Modal.Header>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body className={classes.body}>
                    <img className={classes.img} src="/assets/images/farmer.png" alt="Encoding done" />
                    <Typography className={classes.title} variant="h3">
                        {t('encoding-done.modal.title')}
                    </Typography>
                    <Typography className={classes.text} variant="body2">
                        {t('encoding-done.modal.text')}
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel className={classes.cancel}>{t('constants.close')}</Modal.Cancel>
                    <Modal.Confirm className={classes.confirm}>
                        {t('encoding-done.modal.cta')} <IconArrowRight />
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
