import { ReactNode, ErrorInfo, ComponentType } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import FallbackComponent from '../FallbackComponent/FallbackComponent';
import useCustomErrorBoundaryLogic from './useCustomErrorBoundary.logic';

export default ({
    children,
    fallbackComponent,
    sendErrorLog = true,
}: {
    children: ReactNode;
    fallbackComponent?: ComponentType<FallbackProps>;
    sendErrorLog?: boolean;
}) => {
    const { logError } = useCustomErrorBoundaryLogic();

    return (
        <ErrorBoundary
            FallbackComponent={fallbackComponent ? fallbackComponent : FallbackComponent}
            onError={(error: Error, info: ErrorInfo) => {
                if (sendErrorLog) logError(error, info);
            }}
        >
            {children}
        </ErrorBoundary>
    );
};
