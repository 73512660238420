import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';
import { useCanSeeResults } from '@modules/results/hooks/useCanSeeResults';

export const useCanSeeResultsSummaryGuard = () => {
    const { canSeeResults, isLoading } = useCanSeeResults();

    if (isLoading) return undefined;

    return !!canSeeResults;
};

export const CanSeeResultsSummaryGuard = (props: GuardT) => {
    const passed = useCanSeeResultsSummaryGuard();

    return <BaseGuard passed={passed} {...props} />;
};
