import { FormRow } from '@shared/components';
import { Autocomplete, Card } from '@soil-capital/ui-kit';
import { IconCross, IconSearch } from '@soil-capital/ui-kit/icons';
import { Box } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useSprayingFormPesticideTypeInputStyles } from './useSprayingFormPesticideTypeInput.style';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { StaticPesticideTypeT } from '@shared/entities/staticPesticideType/staticPesticideType.types';
import {
    SprayingFormInputNameT,
    useSprayingFormPesticideTypeInput,
} from '@modules/encoding/modules/technicalItinerary/module/spraying/components/SprayingFormPesticideTypeInput/useSprayingFormPesticideTypeInput.logic';
import { SprayingPassageInput } from '@modules/encoding/modules/technicalItinerary/module/spraying/components/SprayingPassageInput/SprayingPassageInput';
import { SPRAYING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/sprayingFormschema';

type SprayingFormPesticideTypeInputProps = {
    types: StaticPesticideTypeT[];
    name: SprayingFormInputNameT;
    onChange: () => void;
    onTypeDelete: () => void;
    disabled?: boolean;
};

export const SprayingFormPesticideTypeInput = ({
    types,
    name,
    onChange,
    onTypeDelete,
    disabled,
}: SprayingFormPesticideTypeInputProps) => {
    const { t } = useTranslation();
    const { classes } = useSprayingFormPesticideTypeInputStyles();
    const { selectedTypes, handleTypeChange, handleTypeRemoved, getPesticideTypeById } =
        useSprayingFormPesticideTypeInput({
            name,
            onChange,
            onTypeDelete,
        });

    return (
        <>
            <FormRow>
                <FormQuestion
                    question={t('encoding-technical-itinerary.spraying.question.pesticide-types')}
                    tooltipTitleKey="encoding-technical-itinerary.spraying.question.pesticide-types.tooltip.title"
                    tooltipTextKey="encoding-technical-itinerary.spraying.question.pesticide-types.tooltip.text"
                />
                <Autocomplete
                    noOptionsText={t('constants.no-results')}
                    className={classes.select}
                    multiple
                    value={types.length ? selectedTypes?.map((type) => type.id) : []} // options and values are from 2 different sources, so we need to check the dependance to avoid data access issue
                    onChange={(_, fertiliserTypeIds) => handleTypeChange(fertiliserTypeIds)}
                    options={types.map((type) => type.id)}
                    getOptionLabel={(option) => t(`db.pesticide-type.${getPesticideTypeById(option)?.slug ?? ''}`)}
                    placeholder={t('encoding-technical-itinerary.spraying.question.pesticide-types.placeholder')}
                    renderTags={(values) => {
                        return (
                            <Box component="span" display="flex" alignItems="center" gap={1}>
                                {`${values.length} ${t('constants.selected')}`}
                            </Box>
                        );
                    }}
                    disabled={disabled}
                    startIcon={<IconSearch />}
                    isOptionEqualToValue={(option, value) => option === value}
                />
            </FormRow>

            {selectedTypes?.map((type, index) => (
                <Card
                    title={t(`db.pesticide-type.${type.slug}`)}
                    key={type.id}
                    action={
                        <IconCross
                            data-testid={`spraying-form-delete-pesticide-type-${type.id}`}
                            className={classes.iconCross}
                            onClick={() => (disabled ? null : handleTypeRemoved(type))}
                        />
                    }
                >
                    <SprayingPassageInput
                        disabled={disabled}
                        onChange={onChange}
                        name={`${SPRAYING_FORM_INPUT_NAMES.PESTICIDE_TYPES}[${index}].${SPRAYING_FORM_INPUT_NAMES.PASSAGE}`}
                    />
                </Card>
            ))}
        </>
    );
};
