import { ReactNode } from 'react';
import { Provider } from 'react-redux';
import store from './store';

export type SharedStateT = ReturnType<typeof store.getState>;

export const SharedStoreProvider = ({ children }: { children: ReactNode }) => (
    <Provider store={store}>{children}</Provider>
);

export const getSharedState = store.getState;
