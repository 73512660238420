import { useRoutes } from 'react-router-dom';
import { NotFoundPanel } from '@shared/errors/pages/NotFoundPanel';
import FadeTransition from '@modules/encoding/shared/components/FadeTransition/FadeTransition';
import Billing from './pages/BillingPage/Billing';
import { MainScreenLayout } from '@shared/components';
import { Header } from '@shared/components/Header/Header';
import { GlobalNav } from '@shared/components/GlobalNav/GlobalNav';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { IsPartnerNotPrepayingGuard } from '@shared/guards/IsPartnerPrepayingGuard';
import { Guard } from '@shared/guards/Guard';

export default () => {
    const theme = useTheme();
    const { currentSeasonId } = useCurrentSeasonId();

    const routes = useRoutes([
        {
            path: '/',
            element: (
                <Guard element={<Billing />} guards={[IsPartnerNotPrepayingGuard]} redirectTo={`/${currentSeasonId}`} />
            ),
        },
        {
            path: '*',
            element: <NotFoundPanel />,
        },
    ]);

    return (
        <MainScreenLayout
            headerComponent={<Header />}
            navComponent={<GlobalNav />}
            contentBgColor={theme.palette.darkScale[50]}
        >
            <FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
                {routes}
            </FadeTransition>
        </MainScreenLayout>
    );
};
