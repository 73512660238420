import { useWatch, useFormContext } from 'react-hook-form';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { useEffect } from 'react';

export const useCoverCropSeedingMachineQuestion = () => {
    const isFromPreviousCrop = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.IS_FROM_PREVIOUS_CROP });
    const { setValue } = useFormContext();

    useEffect(() => {
        if (isFromPreviousCrop) {
            setValue(COVER_CROP_FORM_INPUT_NAMES.SEEDING_MACHINE_ID, null);
        }
    }, [isFromPreviousCrop, setValue]);

    return {
        isVisible: isFromPreviousCrop === false,
    };
};
