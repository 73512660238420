import api from '@shared/api';
import { CoverCropStaticDataTypeT, CoverCropStaticDataT } from './coverCropFormApi.types';

export const coverCropFormApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getCoverCropFormStaticDataByType: builder.query<CoverCropStaticDataT[], { type: CoverCropStaticDataTypeT }>({
            query: ({ type }) => ({
                url: `/v3/static/static-data/${type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetCoverCropFormStaticDataByTypeQuery } = coverCropFormApi;
