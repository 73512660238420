/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useMemo } from 'react';
import { useMap } from './useMap';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import bbox from '@turf/bbox';
import { useSyncFeatureState } from './hooks/useSyncFeatureState';

export type MapLayerListT = (
    | Omit<mapboxgl.FillLayer, 'source'>
    | Omit<mapboxgl.SymbolLayer, 'source'>
    | Omit<mapboxgl.LineLayer, 'source'>
)[];
export type MapLayerFeatureStateT = { hovered: boolean; selected: boolean };
export type MapLayerCallbackFeatureDataT<P extends GeoJSON.GeoJsonProperties> = {
    featureId: number;
    properties: P;
    state: MapLayerFeatureStateT;
} | null;

export const MapLayers = <P extends GeoJSON.GeoJsonProperties>({
    dataSource,
    layers,
    focusedIds,
    hoveredId,
    selectedIds,
    onFeatureClick,
    onFeatureHover,
}: {
    /** should be memoized to minimize the map rerenders */
    dataSource: GeoJSON.FeatureCollection<GeoJSON.Geometry, P>;
    /** layers is considered as constant. This to avoid dependances and potential loops when not memoized */
    layers: MapLayerListT;
    /** will add the feature-state `selected: true` for ids passed. `selected: false` for others */
    selectedIds?: number[];
    /** will add the feature-state `hovered: true` for ids passed. `hovered: false` for others */
    hoveredId?: number | null;
    /** will add the feature-state `focused: true` for ids passed. `focused: false` for others */
    focusedIds?: number[];
    onFeatureClick?: (data: MapLayerCallbackFeatureDataT<P>, layerId: string) => void;
    /* return false to avoid cursor pointer */
    onFeatureHover?: (data: MapLayerCallbackFeatureDataT<P>, layerId: string) => false | void;
    // onMapMove?: (data: { zoom: number; position: [number, number] }) => void;
}) => {
    const map = useMap();

    const sourceId = useMemo(() => 'source-id-' + Math.random().toString(36).slice(2, 11), []);
    const selectedIdsSet = useMemo(() => new Set(selectedIds), [selectedIds]);

    // Initialize Source, state and Layers on load
    useEffect(() => {
        if (!map || map.loaded()) {
            return;
        }

        const addSourceLayerCb = () => {
            map.addSource(sourceId, { type: 'geojson', data: dataSource });
            layers.forEach((layer) => map.addLayer({ ...layer, source: sourceId }));
        };

        map.on('load', addSourceLayerCb);

        return () => {
            map.off('load', addSourceLayerCb);
        };
    }, [map, dataSource]);

    // Update Source Data on change
    useEffect(() => {
        if (!map || !map.loaded()) {
            return;
        }

        const mapSource = map.getSource(sourceId);
        if (mapSource?.type === 'geojson') {
            mapSource.setData(dataSource);
        }
    }, [map, dataSource]);

    // keep hovered sync with featureStates
    useSyncFeatureState({
        map,
        dataSource,
        sourceId,
        getFeatureStateCb: useCallback(
            (feature) => ({
                hovered: feature.id === hoveredId,
            }),
            [hoveredId],
        ),
    });

    // keep selected sync with featureStates
    useSyncFeatureState({
        map,
        dataSource,
        sourceId,
        getFeatureStateCb: useCallback(
            (feature) => ({
                selected: selectedIdsSet.has(Number(feature.id)),
            }),
            [selectedIdsSet],
        ),
    });

    // trigger onFeatureHover
    useEffect(() => {
        if (!map || !onFeatureHover) {
            return;
        }

        const layerCallbacks = layers.map((layer) => ({
            layerId: layer.id,
            callback: (e: mapboxgl.MapLayerMouseEvent) => {
                const feature = e.features?.[0];
                if (!feature) {
                    map.getCanvas().style.cursor = '';
                    onFeatureHover?.(null, layer.id);
                    return;
                }
                const canHover = onFeatureHover?.(
                    {
                        featureId: Number(feature?.id),
                        properties: feature.properties as P,
                        state: feature.state as MapLayerFeatureStateT,
                    },
                    layer.id,
                );
                if (canHover !== false) {
                    map.getCanvas().style.cursor = 'pointer';
                }
            },
        }));

        layerCallbacks.forEach(({ callback, layerId }) => {
            map.on('mousemove', layerId, callback);
            map.on('mouseleave', layerId, callback);
        });

        return () => {
            layerCallbacks.forEach(({ callback, layerId }) => {
                map.off('mousemove', layerId, callback);
                map.off('mouseleave', layerId, callback);
            });
        };
    }, [map, layers, onFeatureHover]);

    // trigger onFeatureClick
    useEffect(() => {
        if (!map || !onFeatureClick) {
            return;
        }

        const layerCallbacks = layers.map((layer) => ({
            layerId: layer.id,
            callback: (e: mapboxgl.MapLayerMouseEvent) => {
                const feature = e.features?.[0];
                if (!feature) {
                    onFeatureClick?.(null, layer.id);
                } else {
                    onFeatureClick?.(
                        {
                            featureId: Number(feature?.id),
                            properties: feature.properties as P,
                            state: feature.state as MapLayerFeatureStateT,
                        },
                        layer.id,
                    );
                }
            },
        }));

        layerCallbacks.forEach(({ callback, layerId }) => {
            map.on('click', layerId, callback);
        });

        return () => {
            layerCallbacks.forEach(({ callback, layerId }) => {
                map.off('click', layerId, callback);
            });
        };
    }, [map, layers, onFeatureClick]);

    // Sync focusedIds
    useEffect(() => {
        if (!map) {
            return;
        }

        const focusFeatures = () => {
            const polygonToCenter = dataSource.features.filter((feature) => focusedIds?.includes(Number(feature.id)));
            // Calculate the smallest box that can fit the given polygon(s)

            if (polygonToCenter.length > 0) {
                const computedBbox = bbox({ type: 'FeatureCollection', features: polygonToCenter }) as BBox2d;
                map.fitBounds(computedBbox, { padding: 20, animate: false });
            }
        };

        focusFeatures();
    }, [map, focusedIds]);

    return null;
};
