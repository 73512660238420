import { PasswordValidationTooltip } from '@modules/auth/pages/SignUp/components/PasswordValidationTooltip/PasswordValidationTooltip';
import { Button, IconButton, Input } from '@soil-capital/ui-kit/components';
import { IconArrowRight, IconEyeOff, IconEyeOutlined } from '@soil-capital/ui-kit/icons';
import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { ChangeEvent, useState } from 'react';
import { RESET } from '../../useResetPasswordLogic';
import { FieldErrors, UseFormReturn } from 'react-hook-form';

type ResetPasswordFormProps = {
    onSubmit: () => void;
    resetPasswordLoading: boolean;
    errors: FieldErrors<{ password: string; email: string; password_confirmation: string }>;
    methods: UseFormReturn<
        {
            email: string;
            password: string;
            password_confirmation: string;
            token: string;
        },
        undefined
    >;
    handlePasswordChange: (event: ChangeEvent<HTMLInputElement>) => void;
    password: string;
    t: (key: string) => string;
};

export const ResetPasswordForm = ({
    onSubmit,
    resetPasswordLoading,
    errors,
    methods,
    handlePasswordChange,
    password,
    t,
}: ResetPasswordFormProps) => {
    const theme = useTheme();
    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [showPasswordValidations, setShowPasswordValidations] = useState(false);

    return (
        <>
            <Typography variant="h3" textAlign="center">
                {t('pages.reset-password.title')}
            </Typography>
            <Grid display="flex" flexDirection="column" width="324px" gap={theme.spacing(3)}>
                <Grid display="flex" flexDirection="column" alignItems="start" gap={theme.spacing(1)}>
                    <Input
                        label={t('pages.forgot-password.email')}
                        placeholder={t('pages.forgot-password.email.placeholder')}
                        inputProps={{
                            ...methods.register(RESET.EMAIL),
                        }}
                        type="email"
                        fullWidth
                        disabled
                    />
                    {errors[RESET.EMAIL] && (
                        <Typography variant="subcaption" color="error">
                            {errors[RESET.EMAIL]?.message}
                        </Typography>
                    )}
                </Grid>
                <Grid display="flex" flexDirection="column" alignItems="start" gap={theme.spacing(1)}>
                    <PasswordValidationTooltip showPasswordValidations={showPasswordValidations} password={password}>
                        <Input
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            label={t('pages.reset-password.new-password')}
                            name="reset-password"
                            sx={{ width: '324px' }}
                            onBlur={() => setShowPasswordValidations(false)}
                            onFocus={() => setShowPasswordValidations(true)}
                            placeholder={t('pages.reset-password.new-password.placeholder')}
                            inputProps={{
                                ...methods.register(RESET.PASSWORD),
                            }}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <IconEyeOff /> : <IconEyeOutlined />}
                                    </IconButton>
                                ),
                            }}
                            onChange={handlePasswordChange}
                        />
                    </PasswordValidationTooltip>
                    {errors[RESET.PASSWORD] && (
                        <Typography variant="subcaption" color="error">
                            {errors[RESET.PASSWORD]?.message}
                        </Typography>
                    )}
                </Grid>
                <Grid display="flex" flexDirection="column" alignItems="start" gap={theme.spacing(1)}>
                    <Input
                        type={showRepeatPassword ? 'text' : 'password'}
                        label={t('pages.reset-password.confirm-password')}
                        placeholder={t('pages.reset-password.confirm-password.placeholder')}
                        inputProps={{
                            ...methods.register(RESET.PASSWORDCONFIRMATION),
                        }}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                    edge="end"
                                >
                                    {showRepeatPassword ? <IconEyeOff /> : <IconEyeOutlined />}
                                </IconButton>
                            ),
                        }}
                        fullWidth
                    />
                    {errors[RESET.PASSWORDCONFIRMATION] && (
                        <Typography variant="subcaption" color="error">
                            {errors[RESET.PASSWORDCONFIRMATION]?.message}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <Button onClick={onSubmit} loading={resetPasswordLoading} variant="contained" endIcon={<IconArrowRight />}>
                {t('pages.reset-password.cta')}
            </Button>
        </>
    );
};
