import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import fieldApi from '@shared/entities/field/field.api';

export const useDeleteFarmSeasonFields = ({ onFieldsDeleted }: { onFieldsDeleted?: () => void } = {}) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [deleteAllMutation, { isLoading }] = fieldApi.useDeleteAll();

    const deleteFields = async () => {
        try {
            await deleteAllMutation({ farmSeasonId: currentSeasonId });
            onFieldsDeleted?.();
        } catch (e) {
            console.error(e);
        }
    };

    return {
        deleteFields,
        isLoading,
    };
};
