import { ConfirmModalCloseDataT } from '@shared/components/ConfirmModal/ConfirmModal';
import { useState } from 'react';

export type HandleReuseOperationParams = {
    handleReuseOperation: () => Promise<unknown>;
    openConfirmReuseOperationModal: () => Promise<ConfirmModalCloseDataT>;
};

const checkIfHasExistingData = (formSchemaData: object) => {
    const hasExistingData = Object.entries(formSchemaData).some(([key, value]) =>
        Array.isArray(value) ? value.length > 0 : value !== null && key !== 'sprayer_width',
    );
    return hasExistingData;
};

/** return the callback to pass for ReuseOperationSelect.\
 * Can use a callback who will be trigerred after the ruse succeed. Usefull to refetch the operation form\
 * Provide also a loader to display during the reuse operation/cb 
 * 
 * Usage example: ```useReuseOperation({
        onOperationReused: () => refetch(),
    });```
*/
export const useReuseOperation = ({
    onOperationReused,
    getFormValues,
}: {
    onOperationReused?: () => Promise<unknown>;
    getFormValues: () => object;
}) => {
    const [isReuseOperationLoading, setIsReuseOperationLoading] = useState(false);

    const handleReuseOperation = async ({
        handleReuseOperation,
        openConfirmReuseOperationModal,
    }: HandleReuseOperationParams) => {
        const existingDataWillBeOverwritten = checkIfHasExistingData(getFormValues());

        if (existingDataWillBeOverwritten) {
            const { confirmed } = await openConfirmReuseOperationModal();
            if (!confirmed) {
                return;
            }
        }

        setIsReuseOperationLoading(true);
        await handleReuseOperation();
        await onOperationReused?.();
        setIsReuseOperationLoading(false);
    };

    return {
        handleReuseOperation,
        isReuseOperationLoading,
    };
};
