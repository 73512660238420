import { OperationDuplicationModal } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/OperationDuplicationModal/OperationDuplicationModal';
import { NavigateFunction, useParams } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useOperationDuplicationActionPanelStyles } from '@modules/encoding/modules/technicalItinerary/pages/OperationDuplicationActionPanel/OperationDuplicationActionPanel.style';
import { Button } from '@soil-capital/ui-kit/components';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { OperationDuplicationActionPanelSkeleton } from '@modules/encoding/modules/technicalItinerary/pages/OperationDuplicationActionPanel/OperationDuplicationActionPanelSkeleton';
import { useDuplicationModals } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useDuplicationModals';
import technicalItineraryRoutes from '@modules/encoding/modules/technicalItinerary/technicalItinerary.routes';
import { useGetSourceField } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetSourceField';
import { useGetTargetFields } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetTargetFields';
import { useFinalizeDuplicationMutation } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/api/duplicationApi';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const OperationDuplicationActionPanel = ({ navigate }: { navigate: NavigateFunction }) => {
    const { classes } = useOperationDuplicationActionPanelStyles();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldCropId } = useParams();
    const { t } = useTranslation();
    const { openDuplicationModal, handleOpenDuplicationModal, handleCloseDuplicationModal } = useDuplicationModals();
    const { sourceField, isLoading: isLoadingSourceField } = useGetSourceField();
    const { targetFields, isLoading: isLoadingTargetFields } = useGetTargetFields();
    const [finaliseMutation, { isLoading: isFinalisingFieldCrop }] = useFinalizeDuplicationMutation();
    const isLoading = isLoadingSourceField || isLoadingTargetFields;

    if (isLoading) return <OperationDuplicationActionPanelSkeleton />;
    if (!sourceField || !targetFields) return null;

    const handleDuplicationFinished = () => {
        handleCloseDuplicationModal(sourceField);
        navigate(technicalItineraryRoutes.introduction());
    };

    const handleExceptionClick = async () => {
        await finaliseMutation({
            farmSeasonId: Number(currentSeasonId),
            payload: {
                finalised_field_crop_ids: [Number(fieldCropId)],
            },
        });

        navigate(technicalItineraryRoutes.introduction());
    };

    return (
        <encodingShared.ActionPanel>
            <div className={classes.content}>
                <Typography variant="h2" className={classes.title}>
                    {t('encoding-technical-itinerary.duplication.intro.title')}
                </Typography>
                <Typography>
                    {t('encoding-technical-itinerary.duplication.intro.text', {
                        field: sourceField.name,
                        crop: t(`db.crop.slug-${sourceField.staticCrop.slug}`),
                    })}
                </Typography>
            </div>
            <img className={classes.image} src="/assets/images/duplication/duplication-intro.png" alt="duplication" />
            <div className={classes.actions}>
                <Button
                    variant="contained"
                    color="white"
                    onClick={() => handleOpenDuplicationModal(sourceField, targetFields)}
                >
                    <IconLightning />️ <span>{t('encoding-technical-itinerary.duplication.intro.confirm')}</span>
                </Button>
                <Button variant="text" color="white" onClick={handleExceptionClick} loading={isFinalisingFieldCrop}>
                    {t('encoding-technical-itinerary.duplication.intro.cancel')}
                </Button>
            </div>
            <OperationDuplicationModal
                sourceField={sourceField}
                targetFields={targetFields}
                open={openDuplicationModal}
                onClose={handleDuplicationFinished}
                navigate={navigate}
            />
        </encodingShared.ActionPanel>
    );
};
