import { CONTRACT_LANGUAGES, CONTRACT_DATE, CONTRACT_ANNEX_DATE } from '@shared/constants/contract';
import { useTranslation } from 'react-i18next';

export const useGetTermsAndConditions = () => {
    const { i18n } = useTranslation();
    const language = i18n.language;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const langKey = (CONTRACT_LANGUAGES as any).includes(language) ? language : CONTRACT_LANGUAGES[0];

    const termsAndConditionsPdfLink = `/terms-and-conditions/${CONTRACT_DATE}-${langKey?.toLocaleLowerCase()}.pdf`;
    const landownerApprovalPdfLink = `/terms-and-conditions/${CONTRACT_ANNEX_DATE}-${langKey?.toLocaleLowerCase()}-annex3.pdf`;

    return { termsAndConditionsPdfLink, landownerApprovalPdfLink };
};
