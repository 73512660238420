import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    picker: {
        boxShadow: theme.shadows[8],
        borderRadius: theme.spacing(1),
        width: '340px',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        margin: `0 ${theme.spacing(2)}`,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(0.5),
        borderBottom: `1px solid ${theme.palette.darkScale[200]}`,
        button: {
            background: 'none!important',
            border: 'none!important',
            outline: 'none!important',
            boxShadow: 'none!important',
            minWidth: 'auto!important',
            svg: {
                fill: theme.palette.darkScale[900],
                fontSize: '1.5em',
            },
            '&:disabled': {
                visibility: 'hidden',
            },
        },
    },
    headerBack: {
        justifyContent: 'start',
    },
    headerYear: {
        justifyContent: 'center',
    },
    monthPicker: {
        display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 1,
        justifyContent: 'space-between',
        padding: theme.spacing(0.5),
        '& > *': {
            width: `calc(33% - ${theme.spacing(1)})`,
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    periodPicker: {
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(0.5),
    },
    activePeriod: {
        background: theme.palette.primaryScale[100],
    },
    clickableValue: {
        display: 'block',
        textAlign: 'center',
        color: theme.palette.darkScale[900],
        margin: theme.spacing(0.5),
    },
    dateDisplay: {
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
    },
    subheader: {
        padding: '0 !important',
    },
    paper: {
        '& .MuiList-root': {
            overflow: 'hidden',
            padding: 0,
            paddingRight: '0 !important',
        },
    },
    asButton: {
        background: 'none !important',
        boxShadow: 'none !important',

        '> div': {
            background: 'none !important',
            border: 'none !important',

            padding: theme.spacing(0.5),
            paddingTop: 0,
        },

        '> svg': {
            display: 'none',
        },

        fieldset: {
            border: 'none !important',
            boxShadow: 'none !important',
        },
    },
}));
