import { MineralFertiliserStaticDataT } from './mineralFertiliserApi.types';
import { MineralFertiliserStaticDataTypeT } from '../mineralFertiliser.types';
import api from '@shared/api';

export const mineralFertiliserApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMineralFertiliserStaticDataByType: builder.query<
            MineralFertiliserStaticDataT[],
            { type: MineralFertiliserStaticDataTypeT }
        >({
            query: ({ type }) => ({
                url: `/v3/static/static-data/${type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetMineralFertiliserStaticDataByTypeQuery } = mineralFertiliserApi;
