import { OnboardingDiscountT } from '@modules/onboarding/api/onboarding.type';
import { useDeletePromotionCodeMutation, usePostAddPromotionCodeMutation } from '@modules/onboarding/api/onboardingApi';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

export const usePromoCodeLogic = (
    farmSeasonId: number,
    farmSeasonProductPlanId: number,
    t: (key: string) => string,
    promotionCodes: OnboardingDiscountT[] | undefined,
) => {
    type DiscountT = 'FirstDiscount' | 'SecondDiscount';
    const [isOpen, setIsOpen] = useState(false);
    const [promoCode, setPromoCode] = useState('');
    const [showPromochip, setShowPromochip] = useState(false);
    const [secondPromoCode, setSecondPromoCode] = useState('');
    const [showSecondPromochip, setShowSecondPromochip] = useState(false);
    const [addMorePromoCode, setAddMorePromoCode] = useState(false);
    const [isPromoCodeBeingDeleted, setIsPromoCodeBeingDeleted] = useState(false);
    const [isSecondPromoCodeBeingDeleted, setIsSecondPromoCodeBeingDeleted] = useState(false);
    const [postMutation, { isLoading }] = usePostAddPromotionCodeMutation();
    const [deleteMutation] = useDeletePromotionCodeMutation();

    const handleDeletePromoCode = async (discountId: number | undefined, discountType: DiscountT) => {
        if (!discountId) {
            return enqueueSnackbar(t('pages.onboarding.pricing.card.promocode.delete.error'), { variant: 'error' });
        }
        const isFirstDiscount = discountType === 'FirstDiscount';

        isFirstDiscount ? setIsPromoCodeBeingDeleted(true) : setIsSecondPromoCodeBeingDeleted(true);
        const response = await deleteMutation({
            farmSeasonId,
            farmSeasonProductPlanId,
            discountId,
        });
        if ('data' in response) {
            if (isFirstDiscount) {
                setShowPromochip(!!secondPromoCode);
                setPromoCode(secondPromoCode);
                setShowSecondPromochip(false);
                setSecondPromoCode('');
            } else {
                setShowSecondPromochip(false);
                setSecondPromoCode('');
            }
            setAddMorePromoCode(false);
            setIsPromoCodeBeingDeleted(false);
            setIsSecondPromoCodeBeingDeleted(false);
        }
        if ('error' in response) {
            enqueueSnackbar(t('pages.onboarding.pricing.card.promocode.delete.error'), { variant: 'error' });
            setIsSecondPromoCodeBeingDeleted(false);
            setIsPromoCodeBeingDeleted(false);
        }
    };

    const handlePostPromoCode = async (promoCode: string, discountType: DiscountT) => {
        const response = await postMutation({
            farmSeasonId,
            farmSeasonProductPlanId,
            body: { promotion_code: promoCode },
        });
        if ('data' in response) {
            if (discountType === 'FirstDiscount') {
                setShowPromochip(true);
            } else {
                setShowSecondPromochip(true);
            }
        }
        if ('error' in response) {
            const error = response.error as FetchBaseQueryError;
            if (error.data && typeof error.data === 'object' && 'message' in error.data) {
                const errorMessage = (error.data as { message: string }).message;
                if (errorMessage === 'The product plan already has a discount') {
                    enqueueSnackbar(t('pages.onboarding.pricing.card.promocode.already-discounted'), {
                        variant: 'error',
                    });
                } else enqueueSnackbar(t('pages.onboarding.pricing.card.promocode.error'), { variant: 'error' });
            }
        }
    };

    useEffect(() => {
        if (promotionCodes && promotionCodes.length > 0) {
            if (promotionCodes[0].code) {
                setPromoCode(promotionCodes[0].code);
                setShowPromochip(true);
            }
            if (promotionCodes?.[1]?.code) {
                setAddMorePromoCode(true);
                setSecondPromoCode(promotionCodes?.[1]?.code);
                setShowSecondPromochip(true);
            }
        }
    }, [promotionCodes]);

    return {
        handleDeletePromoCode,
        handlePostPromoCode,
        isLoading,
        isOpen,
        setIsOpen,
        promoCode,
        showPromochip,
        secondPromoCode,
        showSecondPromochip,
        addMorePromoCode,
        isPromoCodeBeingDeleted,
        isSecondPromoCodeBeingDeleted,
        setSecondPromoCode,
        setPromoCode,
        setAddMorePromoCode,
    };
};
