import { FarmEndpointParamsT, FarmT } from './farm.types';
import { createEntityAdapter } from '@reduxjs/toolkit';
import createCacheQueryHelper from '../createCacheQueryHelper';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import api from '@shared/api';

export const farmCacheAdapter = createEntityAdapter<FarmT>({ sortComparer: getDefaultSortComparerById<FarmT>() });

const farmCacheQueryHelper = createCacheQueryHelper<FarmEndpointParamsT, FarmT, 'Farm'>(api, 'Farm', farmCacheAdapter);

export default {
    transformResponse: farmCacheQueryHelper.transformResponse,
    add: farmCacheQueryHelper.add,
    update: farmCacheQueryHelper.update,
};
