import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { useCoverCropLegumeQuestion } from './useCoverCropLegumeQuestion';
import { BooleanRadioGroup } from '@shared/components/form/BooleanRadioGroup/BooleanRadioGroup';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';

export const CoverCropLegumeQuestion = ({ onChange }: { onChange: () => void }) => {
    const { t } = useTranslation();
    const { isVisible } = useCoverCropLegumeQuestion();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.legume')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.legume"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.legume"
                    />
                    <BooleanRadioGroup name={COVER_CROP_FORM_INPUT_NAMES.HAS_LEGUMES} onChange={onChange} />
                </FormRow>
            )}
        </>
    );
};
