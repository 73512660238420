import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { ConfirmationStep } from '@modules/payment/components/PaymentStepModal/components/ConfirmationStep/ConfirmationStep';
import { useConfirmationLogic } from './useConfirmationLogic';

export const Confirmation = () => {
    const {
        t,
        title,
        ctaAction,
        harvestYear,
        isPartnerDataLoading,
        isPricingDataLoading,
        isPostFinalizeOnboardingLoading,
    } = useConfirmationLogic();

    return (
        <OnboardingStepsModal stepProgress={5}>
            <ConfirmationStep
                title={title}
                subtitle={t('pages.onboarding.confirmation.subtitle')}
                ctaText={t('pages.onboarding.confirmation.cta', { harvestYear })}
                ctaAction={ctaAction}
                isCtaLoading={isPostFinalizeOnboardingLoading}
                isLoading={isPartnerDataLoading || isPricingDataLoading || !harvestYear}
            />
        </OnboardingStepsModal>
    );
};
