import { useSelector } from 'react-redux';
import { getHasExistingToken } from '../utils/getHasAccessTokenCookie';
import slice, { AuthStateT } from './authSlice';

const useAuthSelector = <S>(cb: (s: AuthStateT) => S): S =>
    useSelector((state: { [slice.name]: AuthStateT }) => cb(state[slice.name]));

const useAuthUser = () => useAuthSelector((s) => s.authUser);
const useIsAuthenticated = () => useAuthSelector((s) => !!s.authUser) && getHasExistingToken().hasAccessTokenCookie;
const useUrlToRedirect = () => useAuthSelector((s) => s.urlToRedirect);
const useShouldRedirectToV2 = () => {
    const isV2Farmer = useAuthSelector((s) => s.userInfos?.redirect_url === process.env.REACT_APP_URL_V2);
    return isV2Farmer;
};
const useLatestActiveSeasonId = () => useAuthSelector((s) => s.userInfos?.latestActiveSeasonId);

const useLatestFarmSeasonId = () => useAuthSelector((s) => s.userInfos?.latestFarmSeason);

const useAppUrl = () => useAuthSelector((s) => s.userInfos?.redirect_url);

export default {
    useIsAuthenticated,
    useAuthUser,
    useUrlToRedirect,
    useShouldRedirectToV2,
    useLatestActiveSeasonId,
    useLatestFarmSeasonId,
    useAppUrl,
};
