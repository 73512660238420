import { useGetResultsQuery } from '@modules/results/api/results.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';

export const useResultsEarning = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { data: results, isLoading: isLoadingResults } = useGetResultsQuery({ farmSeasonId: currentSeasonId });
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();

    const isLoading = isLoadingResults || isLoadingHarvestYear;
    const isNetEmitter = results?.ghg_balance ? results.ghg_balance > 0 : null;

    return { currentSeasonId, results, harvestYear, isLoading, isNetEmitter };
};
