import { addListener } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import saveStatusListener from '../store/saveStatus.listener';

export default () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const unsubscribe = dispatch(addListener(saveStatusListener)) as unknown as () => void;
        return () => {
            unsubscribe();
        };
    }, [dispatch]);
};
