import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const partnerPortalApi = createApi({
    tagTypes: ['PartnerInfo'],
    reducerPath: 'partnerPortalApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_PP_API_BASE_URL,
        credentials: 'include',
        prepareHeaders: (headers) => {
            headers.set('Content-Type', 'application/json');
            headers.set('Accept', 'application/json, */*');
            return headers;
        },
    }),
    endpoints: () => ({}),
    refetchOnMountOrArgChange: false,
});

export default partnerPortalApi;
