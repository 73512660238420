import entities from '@shared/entities';

export const useGetLatestStaticSeason = (farmSeasonId: number | null | undefined) => {
    if (!farmSeasonId) return null;
    const { staticSeasonState } = entities.staticSeason.useState();
    const { farmState } = entities.farm.useState({ farmSeasonId: farmSeasonId });

    const { staticCountryState } = entities.staticCountry.useState();
    const countries = staticCountryState.list;

    const countryId = farmState.list[0]?.country?.id;
    const countryGroupId = countries.find((country) => country.id === countryId)?.country_group_id;
    const countryGroupSeasons = staticSeasonState.list.filter((season) => season.country_group_id === countryGroupId);

    const latestSeason = countryGroupSeasons.reduce(
        (max, season) => (season.year > max.year ? season : max),
        countryGroupSeasons[0],
    );

    return latestSeason;
};
