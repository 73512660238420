import { StepsTimeline } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { IconEuro, IconPenWritting, IconCreditCard, IconCheckCircle, IconPound } from '@soil-capital/ui-kit/icons';
import { usePaymentStepsStyle } from './PaymentSteps.style';
import { useNavigate } from 'react-router-dom';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const PaymentSteps = ({ stepProgress, currency }: { stepProgress: number; currency?: string }) => {
    const { t } = useTranslation();
    const { classes } = usePaymentStepsStyle();
    const navigate = useNavigate();
    const { currentSeasonId } = useCurrentSeasonId();

    return (
        <div className={classes.container}>
            <StepsTimeline
                steps={[
                    {
                        name: t('pages.payment.pricing.step'),
                        icon: currency === '£' ? <IconPound /> : <IconEuro />,
                        stepDoneOnClick: () => navigate(`/${currentSeasonId}/payment/pricing`),
                    },
                    {
                        name: t('pages.payment.billing-info.step'),
                        icon: <IconPenWritting />,
                        stepDoneOnClick: () => navigate(`/${currentSeasonId}/payment/billing-info`),
                    },
                    {
                        name: t('pages.payment.processing.step'),
                        icon: <IconCreditCard />,
                    },
                    { name: t('pages.payment.confirmation.step'), icon: <IconCheckCircle /> },
                ]}
                progress={stepProgress}
            />
        </div>
    );
};
