import { ChangeEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TOSModal } from './TOSModal/TOSModal';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSignUpPageStyle from './SignUp.style';
import { AuthLayout } from '@modules/auth/components/AuthLayout/AuthLayout';
import { Input, Button, Checkbox } from '@soil-capital/ui-kit/components';
import { Typography, Grid, Divider, IconButton, Modal } from '@soil-capital/ui-kit/material-core';
import { useSignUpLogic, SIGNUP } from './useSignUpLogic';
import { FormProvider } from 'react-hook-form';
import { IconArrowRight, IconEyeOff, IconEyeOutlined } from '@soil-capital/ui-kit/icons';
import { PasswordValidationTooltip } from './components/PasswordValidationTooltip/PasswordValidationTooltip';
import Auth0Buttons from '@modules/auth/components/Auth0Buttons/Auth0Buttons';

export const SignUp = (): JSX.Element => {
    const [openModal, setOpenModal] = useState(false);
    const { classes } = useSignUpPageStyle();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState(false);
    const [showPasswordValidations, setShowPasswordValidations] = useState(false);
    const { onSubmit, registerLoading, methods, errors, password, setPassword, emailParam } = useSignUpLogic();
    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setPassword(value);
    };
    return (
        <AuthLayout
            form={
                <FormProvider {...methods}>
                    <form id="signup-form" noValidate onSubmit={onSubmit} className={classes.form}>
                        <div className={classes.flexbox}>
                            <Input
                                disabled={registerLoading}
                                id="first_name"
                                label={t('pages.auth.signup.first-name')}
                                name="first_name"
                                type="text"
                                fullWidth
                                placeholder={t('pages.auth.signup.first-name.placeholder')}
                                inputProps={{
                                    ...methods.register(SIGNUP.FIRSTNAME),
                                }}
                            />
                            {errors[SIGNUP.FIRSTNAME] && (
                                <Typography variant="subcaption" color="error">
                                    {errors[SIGNUP.FIRSTNAME]?.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.flexbox}>
                            <Input
                                disabled={registerLoading}
                                id="last_name"
                                label={t('pages.auth.signup.last-name')}
                                name="last_name"
                                type="text"
                                fullWidth
                                placeholder={t('pages.auth.signup.last-name.placeholder')}
                                inputProps={{
                                    ...methods.register(SIGNUP.LASTNAME),
                                }}
                                sx={{ marginBottom: 0, paddingBottom: 0 }}
                            />
                            {errors[SIGNUP.LASTNAME] && (
                                <Typography variant="subcaption" color="error">
                                    {errors[SIGNUP.LASTNAME]?.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.flexbox}>
                            <Input
                                disabled={registerLoading || searchParams.get('token') !== null || !!emailParam}
                                id="email"
                                label={t('pages.auth.signup.email-address')}
                                name="email"
                                type="email"
                                fullWidth
                                placeholder={t('pages.auth.signup.email-address.placeholder')}
                                inputProps={{
                                    ...methods.register(SIGNUP.EMAIL),
                                }}
                            />
                            {errors[SIGNUP.EMAIL] && (
                                <Typography variant="subcaption" color="error">
                                    {errors[SIGNUP.EMAIL]?.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.flexbox}>
                            <PasswordValidationTooltip
                                showPasswordValidations={showPasswordValidations}
                                password={password}
                            >
                                <Input
                                    disabled={registerLoading}
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label={t('pages.auth.signup.password')}
                                    name="password"
                                    sx={{ width: '300px' }}
                                    onBlur={() => setShowPasswordValidations(false)}
                                    onFocus={() => setShowPasswordValidations(true)}
                                    placeholder={t('pages.auth.signup.password.placeholder')}
                                    inputProps={{
                                        ...methods.register(SIGNUP.PASSWORD),
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={() => setShowPassword(!showPassword)}
                                                edge="end"
                                            >
                                                {showPassword ? <IconEyeOff /> : <IconEyeOutlined />}
                                            </IconButton>
                                        ),
                                    }}
                                    onChange={handlePasswordChange}
                                />
                            </PasswordValidationTooltip>
                            {errors[SIGNUP.PASSWORD] && (
                                <Typography variant="subcaption" color="error">
                                    {errors[SIGNUP.PASSWORD]?.message}
                                </Typography>
                            )}
                        </div>
                        <div className={classes.flexbox}>
                            <Input
                                disabled={registerLoading}
                                id="repeat-password"
                                label={t('pages.auth.signup.repeat-password')}
                                name="repeat-password"
                                type={showRepeatPassword ? 'text' : 'password'}
                                fullWidth
                                placeholder={t('pages.auth.signup.repeat-password.placeholder')}
                                inputProps={{
                                    ...methods.register(SIGNUP.PASSWORDCONFIRMATION),
                                }}
                                InputProps={{
                                    endAdornment: (
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                            edge="end"
                                        >
                                            {showRepeatPassword ? <IconEyeOff /> : <IconEyeOutlined />}
                                        </IconButton>
                                    ),
                                }}
                            />
                            {errors[SIGNUP.PASSWORDCONFIRMATION] && (
                                <Typography variant="subcaption" color="error">
                                    {errors[SIGNUP.PASSWORDCONFIRMATION]?.message}
                                </Typography>
                            )}
                        </div>
                        <Grid className={classes.checkbox} textAlign="left">
                            <Checkbox
                                disabled={registerLoading}
                                label={
                                    <Typography variant="body2" className={classes.tandc}>
                                        <Trans i18nKey="pages.auth.signup.t&c">
                                            I agree to the
                                            <span /* eslint-disable-line */
                                                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setOpenModal(true);
                                                }}
                                            >
                                                terms of use and privacy policy
                                            </span>
                                            .
                                        </Trans>
                                    </Typography>
                                }
                                name="terms"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    methods.setValue(SIGNUP.TERMS, e.target.checked);
                                }}
                            />
                            {errors[SIGNUP.TERMS] && (
                                <Typography variant="subcaption" color="error">
                                    {errors[SIGNUP.TERMS]?.message}
                                </Typography>
                            )}
                        </Grid>
                        <Grid>
                            <Grid className={classes.padding}>
                                <Button
                                    id="submit-button"
                                    loading={registerLoading}
                                    type="submit"
                                    variant="contained"
                                    fullWidth
                                    onClick={onSubmit}
                                    endIcon={<IconArrowRight />}
                                >
                                    {t('constants.signup')}
                                </Button>
                            </Grid>
                            <Divider className={classes.divider}>
                                <Typography color="GrayText" variant="subcaption" sx={{ whiteSpace: 'nowrap' }}>
                                    {t('pages.signup.login-question')}
                                </Typography>
                            </Divider>
                            <Button
                                variant="text"
                                style={{ width: '100%' }}
                                onClick={() => navigate('../login')}
                                endIcon={<IconArrowRight />}
                            >
                                {t('constants.login')}
                            </Button>
                            {!searchParams.get('email') && (
                                <>
                                    <Divider className={classes.divider}>
                                        <Typography color="GrayText" variant="subcaption" sx={{ whiteSpace: 'nowrap' }}>
                                            {t('pages.auth.or')}
                                        </Typography>
                                    </Divider>
                                    <Auth0Buttons />
                                </>
                            )}
                        </Grid>
                        <Modal className={classes.modalHolder} open={openModal} onClose={() => setOpenModal(false)}>
                            <div>
                                <TOSModal onClose={() => setOpenModal(false)} />
                            </div>
                        </Modal>
                    </form>
                </FormProvider>
            }
            sideZone={<div className={classes.sideZoneImage}></div>}
            title={t('pages.auth.signup.title')}
        />
    );
};
