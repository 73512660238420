import { Map } from 'mapbox-gl';

export const initMap = (container: HTMLDivElement, coords: [number, number]) => {
    return new Map({
        container,
        style: process.env.REACT_APP_MAPBOX_STYLE as string,
        center: coords,
        zoom: 15,
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN as string,
        doubleClickZoom: false,
    });
};
