import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { useFormRowStyles } from '@shared/components/form/FormRow/FormRow.style';

export const FormRow: FC<PropsWithChildren<HTMLAttributes<HTMLDivElement>>> = ({ children, ...rest }) => {
    const { classes } = useFormRowStyles();

    return (
        <div className={classes.row} {...rest}>
            {children}
        </div>
    );
};
