import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { IconCross, IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Button } from '@soil-capital/ui-kit/components';
import { useCreatePayment } from '@modules/payment/hooks/useCreatePayment';

export const FailedPayment = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const { createPayment: handleCreatePayment, isLoading } = useCreatePayment();

    return (
        <Grid
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            justifyContent="space-evenly"
            height="60vh"
        >
            <Grid fontSize="88px" display="flex" justifyContent="center" alignItems="center" height="88px" width="88px">
                <IconCross fontSize="inherit" color="error" sx={{ padding: '22px' }} />
            </Grid>
            <Grid>
                <Grid paddingBottom={theme.spacing(4)}>
                    <Typography variant="h3">{t('pages.payment.processing.failed.interjection')}</Typography>
                    <Typography variant="h3">{t('pages.payment.processing.failed.title')}</Typography>
                </Grid>
                <Typography variant="body2">{t('pages.payment.processing.failed.subtitle')}</Typography>
            </Grid>
            <Button variant="contained" endIcon={<IconArrowRight />} onClick={handleCreatePayment} loading={isLoading}>
                {t('pages.payment.processing.failed.cta')}
            </Button>
        </Grid>
    );
};
