import { useFormContext } from 'react-hook-form';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';

export const useMineralFertiliserProductInput = ({ productId }: { productId: number }) => {
    const { register } = useFormContext();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticFertiliserState } = entities.staticFertiliser.useState({ farmId: currentFarm?.id });
    const staticFertiliser = staticFertiliserState.getById(productId);
    const hasProductNitrogen = Number(staticFertiliser?.n) > 0;

    return { hasProductNitrogen, register, staticFertiliser };
};
