import staticLanguage from '@shared/entities/staticLanguage';
import { SharedStateT } from '@shared/store';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import authApi from '../api/authApi';
import { LoginRespT } from '../api/authApi.types';
import authSelectors from './authSelectors';
import authSlice from './authSlice';
import { removeHasAccessTokenCookie } from '@modules/auth/utils/getHasAccessTokenCookie';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { routesConfig } from '../auth.routes';

const useLogout = () => {
    const [logoutMutation, { isLoading }] = authApi.useLogoutMutation();
    const { storeAuthData } = _useStoreAuthData();

    const logout = async () => {
        const logoutPromise = logoutMutation();
        // make sure we already remove the cookie even if the BE logout fail.
        removeHasAccessTokenCookie();
        await logoutPromise;
        storeAuthData(null);
    };

    return { logout, logoutLoading: isLoading };
};

const useRegister = () => {
    const { storeAuthData } = _useStoreAuthData();
    const [registerMutation, { isLoading: registerLoading }] = authApi.useRegisterMutation();
    const [lazyGetPermissionsQuery, { isLoading: getPermissionsLoading }] = authApi.useLazyGetPermissionsQuery();
    const { t } = useTranslation();

    const register = (...p: Parameters<typeof registerMutation>) =>
        registerMutation(...p)
            .unwrap()
            .then(async (r) => {
                await lazyGetPermissionsQuery();
                storeAuthData(r);
                return r;
            })
            .catch((e) => {
                if (e.data.message === 'The email has already been taken.') {
                    enqueueSnackbar(t('errors.register.email'), {
                        variant: 'error',
                    });
                } else
                    enqueueSnackbar(t('errors.register'), {
                        variant: 'error',
                    });
            });

    return {
        register,
        registerLoading: registerLoading || getPermissionsLoading,
    };
};

const useLogin = () => {
    const { storeAuthData } = _useStoreAuthData();
    const [isLoading, setIsLoading] = useState(false);
    const [loginMutation] = authApi.useLoginMutation();
    const [lazyGetPermissionsQuery] = authApi.useLazyGetPermissionsQuery();
    const redirectionAfterAuthentication = authSelectors.useUrlToRedirect();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const login = async (...p: Parameters<typeof loginMutation>) => {
        setIsLoading(true);
        try {
            const loginResponse = await loginMutation(...p).unwrap();
            await lazyGetPermissionsQuery();
            storeAuthData(loginResponse);
            if (loginResponse.user_infos?.redirect_url && loginResponse.user_infos?.redirect_url !== location.origin) {
                window.location.replace(loginResponse.user_infos?.redirect_url);
            }
            if (redirectionAfterAuthentication) {
                navigate(redirectionAfterAuthentication);
                dispatch(authSlice.actions.keepUrlToRedirect(null));
            } else {
                navigate('/');
            }
            setIsLoading(false);
            return loginResponse;
        } catch (e) {
            setIsLoading(false);
            throw e;
        }
    };

    return {
        login,
        loginLoading: isLoading,
    };
};

const useAuthentification0 = () => {
    const navigate = useNavigate();
    const { storeAuthData } = _useStoreAuthData();
    const [authorizeAuth0Mutation] = authApi.useAuthorizeAuth0Mutation();

    const auth0 = async (token: string) => {
        const response = await authorizeAuth0Mutation({ token }).unwrap();
        storeAuthData(response);
        if (!response.user_infos?.latest_active_season) {
            navigate('/onboarding');
        } else {
            navigate(`/${response.user_infos?.latest_active_season}`);
        }
        return response;
    };

    return { auth0 };
};

const useValidateCookie = () => {
    const [isLoading, setIsLoading] = useState(false);
    const { storeAuthData } = _useStoreAuthData();
    const [lazyValidateCookieQuery] = authApi.useLazyValidateCookieQuery();
    const [lazyGetPermissionsQuery] = authApi.useLazyGetPermissionsQuery();

    const redirectionAfterAuthentication = authSelectors.useUrlToRedirect();
    const redirectionAfterLoginRef = useRef(redirectionAfterAuthentication);
    redirectionAfterLoginRef.current = redirectionAfterAuthentication;

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateCookie = async (...p: Parameters<typeof lazyValidateCookieQuery>) => {
        setIsLoading(true);
        try {
            const validateResponse = await lazyValidateCookieQuery(...p).unwrap();
            storeAuthData(validateResponse);

            await lazyGetPermissionsQuery();

            if (redirectionAfterLoginRef.current) {
                navigate(redirectionAfterLoginRef.current);
                dispatch(authSlice.actions.keepUrlToRedirect(null));
            }

            setIsLoading(false);
            return validateResponse;
        } catch (e: unknown) {
            setIsLoading(false);
            //if (e.status === 401) {
            // TODO Cookie has_access_token should be deleted? Should we call storeAuthData(null)? This behavior should stand for all 401 in the app.
            //}
            throw e; // Rethrow the error so the calling code can handle it if needed
        }
    };

    return {
        validateCookie,
        validateCookieLoading: isLoading,
    };
};

const useChangeLanguage = () => {
    const dispatch = useDispatch();
    const { staticLanguageState } = staticLanguage.useState();
    const [changeLanguageMutation, { isLoading: changeLanguageLoading }] = authApi.useChangeLanguageMutation();
    const changeLanguage = ({ isoCode }: { isoCode: string }) => {
        const newLanguage = staticLanguageState.getByIsoCode(isoCode);
        if (!newLanguage?.id) {
            throw Error('language not found');
        }
        changeLanguageMutation({ id: newLanguage.id })
            .unwrap()
            .then((r) => {
                dispatch(authSlice.actions.mergeAuthUser({ language_id: r.language_id }));
                return r;
            });
    };

    return {
        changeLanguage,
        changeLanguageLoading,
    };
};

const _useStoreAuthData = () => {
    const dispatch = useDispatch();
    return {
        storeAuthData: (data: LoginRespT | null): void => {
            // handle logout state data
            if (data === null) {
                dispatch(authSlice.actions.setAuthUser(null));
                return;
            }

            // handle authenticate state data
            const authUserData = {
                ...data.user,
            };
            const userInfos = data.user_infos
                ? {
                      latestActiveSeasonId: data.user_infos?.latest_active_season ?? null,
                      latestFarmSeason: data.user_infos?.latest_farm_season ?? null,
                      modelType: data.user_infos?.model_type ?? null,
                      redirect_url: data.user_infos?.redirect_url ?? null,
                  }
                : null;
            dispatch(authSlice.actions.setAuthUser(authUserData));
            dispatch(authSlice.actions.setUserInfos(userInfos));
        },
    };
};

const useUpdateUserInfos = () => {
    const dispatch = useDispatch();
    const userInfos = useSelector((state: SharedStateT) => state.auth.userInfos);

    const updateUserInfos = ({
        latestActiveSeason,
        latestFarmSeason,
    }: {
        latestActiveSeason: number | null;
        latestFarmSeason: number | null;
    }) => {
        const newUserInfos = {
            modelType: userInfos?.modelType ?? null,
            latestActiveSeasonId: latestActiveSeason,
            latestFarmSeason: latestFarmSeason,
            redirect_url: userInfos?.redirect_url ?? null,
        };
        dispatch(authSlice.actions.setUserInfos(newUserInfos));
    };
    return { updateUserInfos };
};

/** keep the url pathname (and query params) so we can redirect after an authentication */
const useKeepUrlToRedirect = (isAuthenticated: boolean) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const routesConfigValues = Object.values(routesConfig);
    const isUnauthentificatedRoute = routesConfigValues.some((route) => location.pathname.includes(route));

    useEffect(() => {
        if (!isAuthenticated && !isUnauthentificatedRoute) {
            dispatch(authSlice.actions.keepUrlToRedirect(location));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
};

export default {
    useChangeLanguage,
    useLogout,
    useLogin,
    useRegister,
    useValidateCookie,
    useKeepUrlToRedirect,
    useUpdateUserInfos,
    useAuthentification0,
};
