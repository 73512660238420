import { useTranslation } from 'react-i18next';
import { useMapFallbackComponentStyles } from './useMapFallbackComponent.style';
import { HelpBox } from '@soil-capital/ui-kit/components';

export default () => {
    const { t } = useTranslation();
    const { classes } = useMapFallbackComponentStyles();

    return (
        <div className={classes.container}>
            <HelpBox color="warning" className={classes.helpBox}>
                {t('map.render-error.description')}
            </HelpBox>
        </div>
    );
};
