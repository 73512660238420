import { BaseEntityT } from '../baseEntity.types';

export const PESTICIDE_TYPE_SLUGS = {
    ADDITIVE: 'additive',
    FUNGICIDE: 'fungicide',
    HERBICIDE: 'herbicide',
    GROWTH_REGULATOR: 'growth-regulator',
    INSECTICIDE: 'insecticide',
    GLYPHOSATE: 'glyphosate',
    OTHERS: 'others',
    MISCELANEOUS: 'Miscelaneous',
} as const;

export const PESTICIDE_UNIT_SLUGS = {
    L: 'L',
    KG: 'KG',
} as const;

export type PesticideTypeT = (typeof PESTICIDE_TYPE_SLUGS)[keyof typeof PESTICIDE_TYPE_SLUGS];

export type StaticPesticideT = {
    pesticide_type: PesticideTypeT;
    name: string;
    state: string;
    unit: string;
    is_authorized: boolean;
    national_identifier: string;
} & BaseEntityT;
