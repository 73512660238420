import { Input, Modal } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { PAC_FIELD_UPDATE_INPUT_NAMES } from '@modules/encoding/modules/rotation/components/PacImportedFields/usePacFieldUpdateForm';
import { usePacFieldUpdateModalStyles } from '@modules/encoding/modules/rotation/components/PacImportedFields/PacFieldUpdateModal/PacFieldUpdateModal.style';
import { usePacFieldUpdateModalLogic } from '@modules/encoding/modules/rotation/components/PacImportedFields/PacFieldUpdateModal/usePacFieldUpdateModalLogic';
import { Typography } from '@soil-capital/ui-kit/material-core';

export type PacFieldUpdateModalProps = {
    fieldId: number;
    open: boolean;
    onClose: () => void;
};

export const PacFieldUpdateModal = ({ fieldId, open, onClose }: PacFieldUpdateModalProps) => {
    const { classes } = usePacFieldUpdateModalStyles();
    const { t } = useTranslation();
    const { field, methods, onConfirm, isUpdatingField, errors } = usePacFieldUpdateModalLogic({ fieldId, onClose });

    return (
        <FormProvider {...methods}>
            <form>
                <Modal
                    size={'small'}
                    open={open}
                    onClose={onClose}
                    onConfirm={onConfirm}
                    header={
                        <Modal.Header>
                            <Modal.HeaderTitle>
                                {t('encoding-rotation.pac-file.update-field-modal.title')}
                            </Modal.HeaderTitle>
                            <Modal.Close />
                        </Modal.Header>
                    }
                    body={
                        <Modal.Body className={classes.body}>
                            <Input
                                label={t('constants.name')}
                                type="text"
                                defaultValue={field?.name}
                                inputProps={{
                                    ...methods.register(`${PAC_FIELD_UPDATE_INPUT_NAMES.FIELD}.name`),
                                }}
                            />
                            <div className={classes.container}>
                                <Input
                                    disabled={field?.area_source === 'pac' ? true : false}
                                    label={t('constants.area')}
                                    type="number"
                                    suffix={t('constants.hectare-unit')}
                                    defaultValue={field?.user_area ?? field?.area}
                                    inputProps={{
                                        ...methods.register(`${PAC_FIELD_UPDATE_INPUT_NAMES.FIELD}.area`, {
                                            setValueAs: (v) => Number(v),
                                        }),
                                    }}
                                    placeholder={field?.area?.toString()}
                                />
                                {errors.field?.area && (
                                    <Typography color="error" className={classes.description}>
                                        {errors.field?.area.message}
                                    </Typography>
                                )}
                            </div>
                        </Modal.Body>
                    }
                    footer={
                        <Modal.Footer>
                            <Modal.Confirm loading={isUpdatingField}>{t('constants.save')}</Modal.Confirm>
                        </Modal.Footer>
                    }
                />
            </form>
        </FormProvider>
    );
};
