import { Map } from 'mapbox-gl';
import { useEffect } from 'react';

type UseSyncFeatureStatePropsT = {
    map: Map | null;
    dataSource: GeoJSON.FeatureCollection;
    sourceId: string;
    /** callback that must return the featureState properties to overwrite. Make sure to memoize it as it's part of useEffect deps */
    getFeatureStateCb: (feature: GeoJSON.Feature) => { [key: string]: unknown };
};

/**
 * Keep sync the featureState of the dataSource with the map and the 'getFeatureState callback'.
 * handle the potential map style reloads and safely apply the featureState updates. */
export const useSyncFeatureState = ({ map, dataSource, sourceId, getFeatureStateCb }: UseSyncFeatureStatePropsT) => {
    useEffect(() => {
        if (!map) {
            return;
        }
        const effect = () => {
            dataSource.features.forEach((feature) => {
                map.setFeatureState({ source: sourceId, id: feature.id }, getFeatureStateCb(feature));
            });
        };

        if (!map.isStyleLoaded()) {
            // update featureStates will impact the style. We have to wait the style to be loaded to avoid errors.
            // idle fire after the last frame rendered. So when the style is loaded. see https://docs.mapbox.com/mapbox-gl-js/api/map/#map.event:idle
            map.on('idle', effect);
            return () => {
                map.off('idle', effect);
            };
        }

        effect();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [map, getFeatureStateCb]);
};
