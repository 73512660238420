import { useForm } from '@shared/hooks';
import * as z from 'zod';
import { useFinalizeDuplicationMutation } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/api/duplicationApi';
import { useParams } from 'react-router-dom';

export const FINALISE_FORM_INPUT_NAMES = {
    FINALISED_FIELD_CROP_IDS: 'finalised_field_crop_ids',
} as const;

const schema = z.object({
    [FINALISE_FORM_INPUT_NAMES.FINALISED_FIELD_CROP_IDS]: z.array(z.number()),
});

export const useFinaliseForm = ({ onMutationSuccess }: { onMutationSuccess: () => void }) => {
    const { seasonId } = useParams();
    const [finalizeMutation] = useFinalizeDuplicationMutation();

    const methods = useForm({
        schema,
        defaultValues: {
            [FINALISE_FORM_INPUT_NAMES.FINALISED_FIELD_CROP_IDS]: [],
        },
        displaySnackbarOnSchemaError: true,
    });
    const { handleSubmit } = methods;

    const onSubmit = handleSubmit(async (data) => {
        try {
            if (data.finalised_field_crop_ids.length > 0)
                await finalizeMutation({
                    farmSeasonId: Number(seasonId),
                    payload: data,
                }).unwrap();

            onMutationSuccess();
        } catch (e) {
            console.error(e);
        }
    });

    return { methods, onSubmit };
};
