import { useCallback } from 'react';
import createStaticEntity from '../createStaticEntity';
import { StaticMachineryT } from '@shared/entities';

export default createStaticEntity<StaticMachineryT>()({
    entityName: 'staticMachinery',
    url: ({ country_id }: { country_id: number }) => `/v3/static/machineries?countryId=${country_id}`,
    useGetCustomState: (defaultState) => {
        const getByOperationTypeId = useCallback(
            (operationTypeId: number | null | undefined) => {
                if (!operationTypeId) return [];

                return defaultState.list.filter(({ operation_type_ids }) =>
                    operation_type_ids.includes(operationTypeId),
                );
            },
            [defaultState.list],
        );

        const getBySlug = useCallback(
            (slug: string) => {
                return defaultState.list.find(({ slug: machinerySlug }) => machinerySlug === slug);
            },
            [defaultState.list],
        );

        return {
            ...defaultState,
            getByOperationTypeId,
            getBySlug,
        };
    },
});
