import { ReactElement, ReactNode } from 'react';
import { Container, Grid } from '@soil-capital/ui-kit/material-core';
import useMainScreenLayoutStyle from './MainScreenLayout.style';

export type MainScreenLayoutPropsT = {
    navComponent: ReactElement;
    headerComponent: ReactElement;
    children: ReactNode;
    contentBgColor: string;
};
export default ({ navComponent, headerComponent, children, contentBgColor }: MainScreenLayoutPropsT) => {
    const { classes } = useMainScreenLayoutStyle();

    return (
        <Container maxWidth="xl" className={classes.container}>
            <Grid px={3} pb={3} height="100vh" display="flex" flexDirection="column">
                <Grid px={1}>{headerComponent}</Grid>
                <Grid
                    container
                    p={1}
                    className={classes.contentSection}
                    bgcolor={contentBgColor}
                    data-testid="content-section"
                >
                    <Grid width="280px" mr={1} height="100%" bgcolor="inherit">
                        {navComponent}
                    </Grid>
                    <Grid flex="1" height="100%" position="relative">
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    );
};
