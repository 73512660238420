export const MINERAL_FERTILISER_STATIC_DATA_TYPE = {
    MINERAL_FERTILISATION_APPLICATION: 'MINERAL_FERTILISATION_APPLICATION',
} as const;

export type MineralFertiliserStaticDataTypeT = keyof typeof MINERAL_FERTILISER_STATIC_DATA_TYPE;

export const MINERAL_FERTILISER_STATIC_DATA_APPLICATION_METHODS_SLUGS = {
    MINERAL_FERTILISER_APPLICATION_BROADCAST: 'mineral-fertilisation-application-broadcast',
    MINERAL_FERTILISER_APPLICATION_FERTIGATION_DRIP: 'mineral-fertilisation-application-fertigation-drip',
    MINERAL_FERTILISER_APPLICATION_FERTIGATION_FURROW: 'mineral-fertilisation-application-fertigation-furrow',
    MINERAL_FERTILISER_APPLICATION_FERTIGATION_SPRINKLER: 'mineral-fertilisation-application-fertigation-sprinkler',
    MINERAL_FERTILISER_APPLICATION_FERTIGATION_SUBSURFACE_DRIP:
        'mineral-fertilisation-application-fertigation-subsurface-drip',
} as const;

export type MineralFertiliserApplicationMethodSlugsT =
    (typeof MINERAL_FERTILISER_STATIC_DATA_APPLICATION_METHODS_SLUGS)[keyof typeof MINERAL_FERTILISER_STATIC_DATA_APPLICATION_METHODS_SLUGS];

export const MINERAL_FERTILISER_STATIC_DATA_SLUGS = {
    ...MINERAL_FERTILISER_STATIC_DATA_APPLICATION_METHODS_SLUGS,
} as const;

export type MineralFertiliserStaticDataSlugsT =
    (typeof MINERAL_FERTILISER_STATIC_DATA_SLUGS)[keyof typeof MINERAL_FERTILISER_STATIC_DATA_SLUGS];
