import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useComputeCropInfoBoxData } from '@modules/encoding/modules/rotation/helpers/useComputeCropInfoBoxData';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

export const useFarmSeasonCropEditModalLogic = ({
    farmSeasonCropId,
    modalController,
}: {
    farmSeasonCropId?: number;
    modalController: ModalPropsT<{ farmSeasonCropId: number }, boolean>;
}) => {
    const { t } = useTranslation();
    const { cropState } = useGetFarmSeasonCrops();
    const fsc = cropState.getById(farmSeasonCropId);
    const { computeCropInfoBoxData } = useComputeCropInfoBoxData();
    const crop = fsc?.id ? computeCropInfoBoxData({ farmSeasonCropId: fsc.id }) : null;
    const [selectedStaticCropId, setSelectedStaticCropId] = useState<null | number>(null);
    const { enqueueSnackbar } = useSnackbar();

    const { currentSeasonId } = useCurrentSeasonId();
    const { currentFarm } = useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const [changeStaticCropForCrop, { isLoading }] = entities.crop.useChangeStaticCrop();

    const confirmEditCrop = async () => {
        if (!modalController.data?.farmSeasonCropId || !selectedStaticCropId) {
            throw new Error('confirm edit crop issue');
        }
        await changeStaticCropForCrop({
            farmSeasonId: currentSeasonId,
            id: modalController.data.farmSeasonCropId,
            body: { new_static_crop_id: selectedStaticCropId },
        });
        setSelectedStaticCropId(null);
        modalController.onClose?.(true);
        enqueueSnackbar(
            t('encoding-rotation.crop-edit-modal.success-snackbar', {
                oldCropName: crop?.id ? getStaticCropName(crop?.id) : '',
                newCropName: getStaticCropName(selectedStaticCropId),
            }),
            { variant: 'success' },
        );
    };

    const onClose = () => {
        setSelectedStaticCropId(null);
        modalController.onClose?.(false);
    };

    const getStaticCropName = (staticCropId: number) => {
        const staticCrop = staticCropState.getById(staticCropId);
        return staticCrop ? t(`db.crop.slug-${staticCrop.slug}`) : '';
    };

    const staticCropIds = staticCropState.list
        .map((staticCrop) => staticCrop.id)
        .filter((staticCropId) => staticCropId !== fsc?.crop_id);

    const onStaticCropChange = setSelectedStaticCropId;

    return {
        crop,
        confirmLoading: isLoading,
        getStaticCropName,
        staticCropIds,
        confirmEditCrop,
        confirmDisabled: !selectedStaticCropId,
        onStaticCropChange,
        selectedStaticCropId,
        onClose,
    };
};
