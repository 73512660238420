import progressApi from './progress.api';
import { progressCacheAdapter } from './progress.cache';
import { ComputeProgressDetailRespT, ProgressEndpointParamsT, ProgressSlugT, ProgressT } from './progress.types';
import createDefaultState from '../createDefaultState';
import { useCallback } from 'react';

export default ({ seasonId }: ProgressEndpointParamsT, options?: { skip?: boolean }) => {
    const shouldSkip = options?.skip || !seasonId;

    const defaultState = createDefaultState(progressCacheAdapter, () =>
        progressApi.useGet({ seasonId }, { skip: shouldSkip }),
    );

    const getBySlug = useCallback(
        (slug: ProgressT['slug']) => {
            return defaultState.list.find((progressStep) => progressStep.slug === slug);
        },
        [defaultState.list],
    );

    const getBySlugAndFieldCropId = useCallback(
        (slug: ProgressT['slug'], fieldCropId: number) => {
            return defaultState.list
                .find((progressStep) => progressStep.slug === slug)
                ?.progress_details.filter((progressDetail) => progressDetail.farm_season_field_crop_id === fieldCropId);
        },
        [defaultState.list],
    );

    const computeProgressDetail = useCallback(
        (
            progressStep: ProgressSlugT,
            progressDetailRelationKey:
                | 'farm_season_crop_id'
                | 'farm_season_field_id'
                | 'farm_season_field_crop_id'
                | 'farm_season_field_crop_operation_id',
            progressDetailRelationId: number,
        ): ComputeProgressDetailRespT => {
            const filteredList =
                getBySlug(progressStep)?.progress_details.filter(
                    (progressDetail) => progressDetail[progressDetailRelationKey] === progressDetailRelationId,
                ) ?? [];
            const done = filteredList.filter((progressDetail) => progressDetail.is_done).length;
            return {
                done,
                total: filteredList.length,
            };
        },
        [getBySlug],
    );

    return {
        progressState: {
            ...defaultState,
            getBySlug,
            getBySlugAndFieldCropId,
            computeProgressDetail,
        },
    };
};
