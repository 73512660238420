import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import staticMachinery from '@shared/entities/staticMachinery/index';
import staticPesticide from '@shared/entities/staticPesticide/index';
import { useGetDestructionFormStaticDataByTypeQuery } from '../api/destructionFormApi';
import { DESTRUCTION_STATIC_DATA_TYPE } from '../destruction.types';
import { useGetCurrentOperationFromUrl } from '../../../hooks/useGetCurrentOperationFromUrl';
import { useTranslation } from 'react-i18next';

export const useDestructionFormOptions = () => {
    const { t } = useTranslation();
    const { operation } = useGetCurrentOperationFromUrl();
    const { currentFarm } = useCurrentFarm();
    const { staticMachineryState } = staticMachinery.useState({ country_id: currentFarm?.country_id });
    const { staticPesticideState } = staticPesticide.useState({ country_id: currentFarm?.country_id });

    const { data: destructionMethod, isLoading: isLoadingDestructionMethod } =
        useGetDestructionFormStaticDataByTypeQuery({
            type: DESTRUCTION_STATIC_DATA_TYPE.HARVEST_DESTRUCTION_METHOD,
        });

    const destructionMethodOptions = destructionMethod
        ?.map((el) => ({
            value: el.id,
            label: t(`static-data.slug.${el.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const destructionMachinery = operation
        ? staticMachineryState.getByOperationTypeId(operation?.operation_type_id)
        : [];
    const machineryOptions = destructionMachinery
        .map((machinery) => ({
            value: machinery.id,
            label: t(`db.machineries.${machinery.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const destructionHerbicides = staticPesticideState.getByPesticideType('herbicide');
    const herbicideOptions = destructionHerbicides
        .map((herbicide) => ({
            value: herbicide.id,
            label: t(herbicide.name),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const isLoading = isLoadingDestructionMethod || staticMachineryState.isLoading || staticPesticideState.isLoading;

    return {
        destructionMethods: destructionMethodOptions || [],
        machineries: machineryOptions || [],
        herbicides: herbicideOptions || [],
        isLoading,
    };
};
