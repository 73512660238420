import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useDeleteFarmSeasonFields } from '@modules/encoding/shared/hooks/useDeleteFarmSeasonFields';
import { usePacFieldUpdateFormToasts } from '@modules/encoding/modules/rotation/components/PacImportedFields/usePacFieldUpdateFormToasts';

export const useFarmSeasonFieldsDeleteModalLogic = ({ onClose }: { onClose: () => void }) => {
    const { fieldState } = useGetFarmSeasonFields();
    const { deleteFields, isLoading } = useDeleteFarmSeasonFields();
    const { onFieldsDeleted } = usePacFieldUpdateFormToasts();
    const farmSeasonFieldsCount = fieldState?.list?.length ?? 0;

    const onDeleteConfirm = async () => {
        await deleteFields();
        onFieldsDeleted();
        onClose();
    };

    return { farmSeasonFieldsCount, isDeletingFields: isLoading, onDeleteConfirm };
};
