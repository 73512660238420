import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetStaticCrops } from '@shared/hooks/useGetStaticCrops';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const useGetSeedingOperationTitle = () => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldCropId } = useParams();
    const { cropState } = useGetFarmSeasonCrops();
    const { staticCropState } = useGetStaticCrops();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: currentSeasonId,
    });
    const { staticOperationTypeState } = entities.staticOperationType.useState();

    const cropName = t(
        `db.crop.slug-${
            staticCropState.getById(
                cropState.getById(fieldCropState.getById(fieldCropId)?.farm_season_crop_id)?.crop_id,
            )?.slug
        }`,
    );

    const isLoading =
        cropState.isLoading ||
        staticCropState.isLoading ||
        fieldCropState.isLoading ||
        operationState.isLoading ||
        staticOperationTypeState.isLoading;

    const getSeedingOperationTitle = (operationId: number) => {
        const isCompanionSeeding =
            staticOperationTypeState.getById(operationState.getById(operationId)?.operation_type_id)?.slug ===
            OPERATION_TYPE_SLUGS.SEEDING_COMPANION_SEEDING;
        const companionCropName = t(
            `db.crop.slug-${staticCropState.getById(fieldCropState.getById(fieldCropId)?.companion_crop_id)?.slug}`,
        );
        const hasCompanionCrop = fieldCropState.getById(fieldCropId)?.has_companion_crop;
        if (hasCompanionCrop && isCompanionSeeding)
            return t('encoding-technical-itinerary.timeline-nav.seeding-operation-name', {
                cropName: companionCropName,
            });

        const hasSeedingOperation = operationState.list.some(
            (operation) =>
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.SEEDING_SEEDING,
        );
        const hasCompanionSeedingOperation = operationState.list.some(
            (operation) =>
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.SEEDING_COMPANION_SEEDING,
        );
        const hasSeparateCompanionSeeding = hasSeedingOperation && hasCompanionSeedingOperation;
        if (!hasCompanionCrop || hasSeparateCompanionSeeding)
            return t('encoding-technical-itinerary.timeline-nav.seeding-operation-name', { cropName });

        return t('encoding-technical-itinerary.timeline-nav.composed-seeding-operation-name', {
            cropName,
            companionCropName,
        });
    };

    return {
        getSeedingOperationTitle,
        isLoading,
    };
};
