import { auth } from '@modules/auth';
import { useGetOnboardingPricingQuery } from '@modules/onboarding/api/onboardingApi';
import { useCreatePayment } from '@modules/payment/hooks/useCreatePayment';
import { useNavigate } from 'react-router-dom';

export const usePricingLogic = () => {
    const navigate = useNavigate();
    const farmSeasonId = auth.useLatestFarmSeasonId();
    const {
        data: pricingData,
        isLoading: isPricingDataLoading,
        isFetching,
    } = useGetOnboardingPricingQuery({
        farmSeasonId,
    });
    const currency = pricingData?.currency.symbol || '';
    const subscriptionPrice = pricingData?.subtotal || 0;
    const haPrice = pricingData?.ha_price_amount || 0;
    const floorPrice = pricingData?.ha_floor_price_amount || 0;
    const ceiledPrice = pricingData?.ha_ceiling_price_amount || 0;
    const hasDiscount = !!pricingData?.discounts.length ?? false;
    const discountedPrice = pricingData?.total_excluding_tax || 0;
    const isDeferredPayment = pricingData?.is_deferred_payment;
    const farmSeasonProductPlanId = pricingData?.farm_season_product_plan_id || 0;
    const isBelgian = pricingData?.country.iso_code === 'BE';
    const discounts = pricingData?.discounts;
    const { createPayment: redirectToStripe, isLoading } = useCreatePayment();

    return {
        currency,
        subscriptionPrice,
        haPrice,
        floorPrice,
        ceiledPrice,
        hasDiscount,
        discountedPrice,
        isDeferredPayment,
        farmSeasonProductPlanId,
        isBelgian,
        redirectToStripe,
        isLoading: isLoading,
        isPricingDataLoading,
        isFetching,
        discounts,
        farmSeasonId,
        navigate,
    };
};
