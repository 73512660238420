import api from '@shared/api';
import { FarmSeasonInstallationT } from './framSeasonProfileApi.types';

export const farmSeasonApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getFarmSeasonV2stepsCompletion: builder.query<FarmSeasonInstallationT, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/v2steps-completion`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetFarmSeasonV2stepsCompletionQuery } = farmSeasonApi;
