/* eslint-disable @typescript-eslint/no-explicit-any */
import { Comparer, EntityState, createEntityAdapter } from '@reduxjs/toolkit';
import createDefaultState from './createDefaultState';
import getDefaultSortComparerById from './getDefaultSortComparerById';
import api from '@shared/api';

export default <StaticEntityT extends { id: number }>() =>
    <
        N extends string,
        CustomStateT extends Record<string, any> = ReturnType<typeof createDefaultState<StaticEntityT>>,
        EndpointParamsT = void,
    >({
        entityName,
        url,
        useGetCustomState,
        sortComparer,
    }: {
        entityName: N;
        url: (params: EndpointParamsT) => string;
        /** the createStaticEntity helper create a state from createDefaultState. This callback give the ability to extend/override it */
        useGetCustomState?: (defaultState: ReturnType<typeof createDefaultState<StaticEntityT>>) => CustomStateT;
        /** how the data are sorted in the store (= order got from selects). Need the same comparer as used by Array.prototype.sort().
         * default value: `(c1, c2) => (c1.id > c2.id ? 1 : -1)`
         **/
        sortComparer?: false | Comparer<StaticEntityT>;
    }) => {
        const capitalizedEntityName = `${entityName.charAt(0).toUpperCase()}${entityName.slice(1)}`;
        const entityCacheAdapter = createEntityAdapter<StaticEntityT>({
            sortComparer: sortComparer ?? getDefaultSortComparerById<StaticEntityT>(),
        });

        const apiCreated = api.injectEndpoints({
            endpoints: (build) => ({
                [`get${capitalizedEntityName}`]: build.query<EntityState<StaticEntityT>, EndpointParamsT>({
                    query: (params: EndpointParamsT) => ({
                        url: url(params),
                        method: 'GET',
                    }),
                    transformResponse: (response: StaticEntityT[]) =>
                        entityCacheAdapter.setAll(entityCacheAdapter.getInitialState(), response),
                }),
            }),
        });

        const useState = (params: EndpointParamsT extends void ? EndpointParamsT : Partial<EndpointParamsT>) => {
            const paramValues = Object.values(params ?? {});
            const defaultState = createDefaultState(entityCacheAdapter, () => {
                const skip = paramValues.findIndex((v) => !v) !== -1;
                return (apiCreated as any)[`useGet${capitalizedEntityName}Query`](params, {
                    skip,
                });
            });
            return {
                [`${entityName}State`]: useGetCustomState?.(defaultState) ?? defaultState,
            } as { [k in `${N}State`]: CustomStateT };
        };

        return {
            api: apiCreated,
            useGet: (apiCreated as any)[`useGet${capitalizedEntityName}Query`],
            useState,
        };
    };
