import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';

export const useGetFarmSeasonOperations = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { operationState } = entities.operation.useState({ farmSeasonId: currentSeasonId });

    return {
        operationState,
    };
};
