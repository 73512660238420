import { makeStyles } from '@soil-capital/ui-kit/style';

export const usePageWithHeaderStyle = makeStyles()((theme) => ({
    main: {
        borderRadius: theme.shape.borderRadius,
        background: 'white',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflowY: 'scroll',
        ...theme.styles.scrollSection,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: theme.spacing(1),
        borderBottom: `1px solid ${theme.palette.divider}`,
        position: 'sticky',
        top: 0,
        zIndex: 100,
        background: 'white',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6),
        padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    },
}));
