import i18next from 'i18next';
import { auth } from '@modules/auth';
import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { useTranslation } from 'react-i18next';

export const useUpdateIntercom = () => {
    const user = auth.useAuthUser();
    const { t } = useTranslation();
    const { update: intercomUpdate } = useIntercom();

    useEffect(() => {
        if (user) {
            intercomUpdate({
                userId: user?.id?.toString(),
                email: user?.email,
                name: `${user?.first_name} ${user?.last_name}`,
                languageOverride: i18next?.language,
                customAttributes: {
                    userId_Hubspot: user?.hubspot_id,
                },
            });
        }
    }, [user, intercomUpdate, t]);
};
