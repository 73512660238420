import {
    DuplicationPayloadT,
    FinalizeDuplicationPayload,
    OperationDuplicationT,
    ReuseOperationPayloadT,
} from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/api/duplicationApi.types';
import api from '@shared/api';
import type { FieldCropT } from '@shared/entities';

export const duplicationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        duplicateOperations: builder.mutation<number[], { farmSeasonId: number; payload: DuplicationPayloadT }>({
            query: ({ farmSeasonId, payload }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/duplications/operations`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: [
                'Progress',
                'FieldCrop',
                'Operation',
                'Operations',
                'FieldCropsToFinalise',
                'FieldCropsThatNeedFinalisation',
            ],
        }),

        // Field crops with data coming from a duplication but not finalised
        getFieldCropsThatNeedFinalisation: builder.query<FieldCropT[], { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/not-finalised`,
                method: 'GET',
            }),
            providesTags: ['FieldCropsThatNeedFinalisation'],
        }),

        // Fields to finalise after duplication(s)
        getFieldCropsToFinalise: builder.query<FieldCropT[], { farmSeasonId: number; farmSeasonFieldCropId: number }>({
            query: ({ farmSeasonId, farmSeasonFieldCropId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/field-crops/${farmSeasonFieldCropId}/to-finalise`,
                method: 'GET',
            }),
            providesTags: ['FieldCropsToFinalise'],
        }),

        finalizeDuplication: builder.mutation<number[], { farmSeasonId: number; payload: FinalizeDuplicationPayload }>({
            query: ({ farmSeasonId, payload }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/duplications/finalize`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Progress', 'FieldCropsToFinalise', 'FieldCropsThatNeedFinalisation'],
        }),

        getReusedOperation: builder.query<
            OperationDuplicationT,
            {
                farmSeasonId: number;
                fieldCropId: number;
                operationId: number;
            }
        >({
            query: ({ farmSeasonId, fieldCropId, operationId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/field-crops/${fieldCropId}/operations/${operationId}/reused-operation`,
                method: 'GET',
            }),
            keepUnusedDataFor: 0,
        }),
        reuseOperation: builder.mutation<
            OperationDuplicationT,
            { farmSeasonId: number; fieldCropId: number; operationId: number; payload: ReuseOperationPayloadT }
        >({
            query: ({ farmSeasonId, fieldCropId, operationId, payload }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/field-crops/${fieldCropId}/operations/${operationId}/reuse-operation`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['Progress'],
        }),
    }),
});

export const {
    useGetFieldCropsToFinaliseQuery,
    useGetFieldCropsThatNeedFinalisationQuery,
    useDuplicateOperationsMutation,
    useFinalizeDuplicationMutation,
    useGetReusedOperationQuery,
    useReuseOperationMutation,
} = duplicationApi;
