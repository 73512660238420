import { auth } from '@modules/auth';
import { useGetPaymentMethodQuery, usePostBillingPortalSessionMutation } from '@modules/billing/api/billingApi';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

export const usePaymentInfoCardLogic = (farmId: number) => {
    const isReadOnly = !auth.usePermission('canEditBillingPage');
    const { seasonId } = useParams();
    const { i18n } = useTranslation();
    const { data: paymentMethod, isLoading } = useGetPaymentMethodQuery({ farmId });
    const [postBillingPortalSession, { isLoading: isRedirectUrlLoading }] = usePostBillingPortalSessionMutation();
    const handleBillingPortalSession = async () => {
        const body = { locale: i18n.language, return_url: `${process.env.REACT_APP_URL_V3}/${seasonId}/billing` };
        const response = await postBillingPortalSession({ farmId, body }).unwrap();
        return (window.location.href = response.billing_portal_session_url);
    };
    const displayedCardNumber =
        paymentMethod?.type === 'sepa_debit'
            ? `XXXX ${paymentMethod?.last4}`
            : `XXXX XXXX XXXX ${paymentMethod?.last4}`;

    return {
        handleBillingPortalSession,
        displayedCardNumber,
        isLoading,
        isRedirectUrlLoading,
        paymentMethod,
        isReadOnly,
    };
};
