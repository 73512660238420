import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { IconGoogle, IconFacebook } from '@soil-capital/ui-kit/icons';
import { Button } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { Grid, useTheme } from '@soil-capital/ui-kit/material-core';
import authActions from '@modules/auth/store/authActions';

const Auth0Buttons = (): JSX.Element => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { getAccessTokenSilently, loginWithPopup } = useAuth0();
    const [idProviderLoading, setIdProviderLoading] = useState<'facebook' | 'google-oauth2'>();
    const [lastIdProviderLoading, setLastIdProviderLoading] = useState<'facebook' | 'google-oauth2'>();
    const { isLoading: auth0Loading } = useAuth0();
    const { auth0 } = authActions.useAuthentification0();

    const authorize = async (connection: 'facebook' | 'google-oauth2') => {
        if (idProviderLoading) {
            return;
        }
        setIdProviderLoading(connection);
        setLastIdProviderLoading(connection);
        const popupWidth = 800;
        const popupHeight = 650;
        // center the popup relative to app window (who is relative to screen)
        const popupLeft = window.screenX + (window.outerWidth - popupWidth) / 2;
        const popupTop = window.screenY + (window.outerHeight - popupHeight) / 2;

        const popup = window.open(
            undefined,
            undefined,
            `width=${popupWidth},height=${popupHeight},top=${popupTop},left=${popupLeft}`,
        );

        try {
            await loginWithPopup({ connection, ui_locales: window.location.origin }, { popup });
            const token = await getAccessTokenSilently();
            await auth0(token);
        } catch (e) {
            console.error(e);
        }
        setIdProviderLoading(undefined);
    };

    return (
        <Grid display="flex" flexDirection="column" paddingX={theme.spacing(3)} gap={theme.spacing(1)}>
            <Button
                variant="contained"
                sx={{ bgcolor: '#4267b2' }}
                onClick={() => authorize('facebook')}
                loading={(auth0Loading && lastIdProviderLoading === 'facebook') || idProviderLoading === 'facebook'}
                startIcon={<IconFacebook sx={{ color: '#fff' }} />}
            >
                {t('pages.auth.facebook-button')}
            </Button>
            <Button
                onClick={() => authorize('google-oauth2')}
                loading={
                    (auth0Loading && lastIdProviderLoading === 'google-oauth2') || idProviderLoading === 'google-oauth2'
                }
                startIcon={<IconGoogle />}
            >
                {t('pages.auth.google-button')}
            </Button>
        </Grid>
    );
};

export default Auth0Buttons;
