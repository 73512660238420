import { useTranslation } from 'react-i18next';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useFormContext } from 'react-hook-form';
import type { PickerItemT } from '@soil-capital/ui-kit';
import { ROTATION_ASSIGNATION_INPUT_NAMES } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/hooks/useRotationAssignationForm';
import { useCreateFarmSeasonCrop } from '@modules/encoding/shared/hooks/useCreateFarmSeasonCrop';
import { useState } from 'react';

export const useCropListPickerLogic = () => {
    const { t } = useTranslation();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { cropState } = useGetFarmSeasonCrops();
    // Used to force the picker to rerender when a crop is added
    const [cropPickerKey, setCropPickerKey] = useState(0);

    const context = useFormContext();

    const pickerItems = staticCropState.list
        ?.reduce(
            (acc, curr) => {
                const cropItem = {
                    label: t(`db.crop.slug-${curr.slug}`),
                    value: curr.id,
                    disabled: false,
                };

                // Only add crop to list if it is not already exists
                if (!cropState.list?.find((crop) => crop.crop_id === curr.id)) acc[0].items.push(cropItem);

                return acc;
            },
            [{ items: [] }] as PickerItemT<number>[],
        )
        ?.map((group) => ({
            ...group,
            items: group.items.sort((a, b) => a.label.localeCompare(b.label)),
        }));

    const handleCropAdded = (id: number) => {
        context.setValue(ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID, id);
        setCropPickerKey(id);
    };

    const { addCrop, isLoading: isAddingCrop } = useCreateFarmSeasonCrop({ onCropAdded: handleCropAdded });

    return { pickerItems, addCrop, isAddingCrop, cropPickerKey };
};
