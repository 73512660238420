import { ListNavItem, Timeline, Tooltip } from '@soil-capital/ui-kit/components';
import useOperationTimelineNavStyle from './useOperationTimelineNav.style';
import useOperationTimelineNavData from './useOperationTimelineNav.data';
import { OperationTimelineNavPropsT } from './OperationTimelineNav.types';
import useOperationTimelineNavLogic from './useOperationTimelineNav.logic';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';

export default ({
    fieldCropId,
    seasonId,
    operationId,
    navigate,
    setTimelineNavIsLoading,
}: OperationTimelineNavPropsT) => {
    const { t } = useTranslation();
    const { classes } = useOperationTimelineNavStyle();
    const dataContext = useOperationTimelineNavData({ fieldCropId, seasonId });
    const { timelineItems, navigateToOperationForm } = useOperationTimelineNavLogic({
        dataContext,
        operationId,
        fieldCropId,
        seasonId,
        navigate,
        setTimelineNavIsLoading,
    });

    return (
        <div className={classes.OperationTimelineNav}>
            <div className={classes.caption}>
                <Typography variant="caption" color="darkScale.600">
                    {t('encoding-technical-itinerary.contextual-panel.caption')}
                </Typography>
            </div>
            <Timeline
                items={timelineItems.map((item) => ({
                    ...item,
                    offset: item.children.subtitle !== '' ? '-0.75rem' : '-0.25rem',
                    children: (
                        <>
                            <Tooltip title={item.children.tooltip} placement="right">
                                <ListNavItem
                                    key={item.children.operationId}
                                    onClick={() =>
                                        navigateToOperationForm(item.children.operationId, item.children.category)
                                    }
                                    title={item.children.title}
                                    disabled={item.children.disabled}
                                    subtitle={item.children.subtitle}
                                    active={item.children.active}
                                    icon={item.children.icon}
                                    className={classes.navItem}
                                    data-testid="list-nav-item"
                                />
                                {item.children.active && (
                                    // Hidden item used to scroll into the view with offset of ~50px when the active item change.
                                    <div
                                        id={`active-item-to-scroll-into-the-view`}
                                        className={classes.activeItemToScroll}
                                    />
                                )}
                            </Tooltip>
                        </>
                    ),
                }))}
            />
        </div>
    );
};
