import { OnboardingDiscountT } from '@modules/onboarding/api/onboarding.type';
import { Button, Chip, InputTextButton, Loader } from '@soil-capital/ui-kit/components';
import { IconChevronDown, IconChevronUp, IconCross, IconPlusCircle } from '@soil-capital/ui-kit/icons';
import { Grid, useTheme } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { usePromoCodeLogic } from './usePromoCodeLogic';

export const PromoCode = ({
    farmSeasonId,
    farmSeasonProductPlanId,
    promotionCodes,
    isFetching,
}: {
    farmSeasonId: number;
    farmSeasonProductPlanId: number;
    promotionCodes: OnboardingDiscountT[] | undefined;
    isFetching: boolean;
}) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        handleDeletePromoCode,
        handlePostPromoCode,
        isLoading,
        isOpen,
        setIsOpen,
        promoCode,
        showPromochip,
        secondPromoCode,
        showSecondPromochip,
        addMorePromoCode,
        isPromoCodeBeingDeleted,
        isSecondPromoCodeBeingDeleted,
        setSecondPromoCode,
        setPromoCode,
        setAddMorePromoCode,
    } = usePromoCodeLogic(farmSeasonId, farmSeasonProductPlanId, t, promotionCodes);

    return (
        <>
            <Button onClick={() => setIsOpen(!isOpen)} endIcon={isOpen ? <IconChevronUp /> : <IconChevronDown />}>
                {t('pages.onboarding.pricing.card.promocode')}
            </Button>
            {isOpen && (
                <Grid
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    paddingBottom={theme.spacing(2)}
                    gap={theme.spacing(3)}
                >
                    {showPromochip ? (
                        <Chip
                            sx={{ maxHeight: '48px' }}
                            label={promoCode}
                            deleteIcon={
                                isPromoCodeBeingDeleted ? (
                                    <Loader sx={{ maxWidth: '16px', maxHeight: '16px' }} />
                                ) : (
                                    <IconCross sx={{ maxWidth: '12px', maxHeight: '12px' }} />
                                )
                            }
                            disabled={isPromoCodeBeingDeleted}
                            onDelete={() =>
                                handleDeletePromoCode(
                                    promotionCodes?.find((promotionCode) => promotionCode.code === promoCode)?.id,
                                    'FirstDiscount',
                                )
                            }
                        />
                    ) : (
                        <InputTextButton
                            sx={{
                                '& input': {
                                    textTransform: 'uppercase',
                                },
                                '& input:placeholder-shown': {
                                    textTransform: 'none',
                                },
                            }}
                            placeholder={t('pages.onboarding.pricing.card.promocode.add')}
                            buttonText={t('constants.validate')}
                            handleClick={() => handlePostPromoCode(promoCode, 'FirstDiscount')}
                            onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                            isLoading={isLoading}
                            disabled={isFetching}
                        />
                    )}
                    {addMorePromoCode ? (
                        showSecondPromochip ? (
                            <Chip
                                sx={{
                                    maxHeight: '48px',
                                }}
                                label={secondPromoCode}
                                deleteIcon={
                                    isSecondPromoCodeBeingDeleted ? (
                                        <Loader sx={{ maxWidth: '16px', maxHeight: '16px' }} />
                                    ) : (
                                        <IconCross sx={{ maxWidth: '12px', maxHeight: '12px' }} />
                                    )
                                }
                                disabled={isSecondPromoCodeBeingDeleted}
                                onDelete={() =>
                                    handleDeletePromoCode(
                                        promotionCodes?.find((promotionCode) => promotionCode.code === secondPromoCode)
                                            ?.id,
                                        'SecondDiscount',
                                    )
                                }
                            />
                        ) : (
                            <InputTextButton
                                sx={{
                                    '& input': {
                                        textTransform: 'uppercase',
                                    },
                                    '& input:placeholder-shown': {
                                        textTransform: 'none',
                                    },
                                }}
                                placeholder={t('pages.onboarding.pricing.card.promocode.add')}
                                buttonText={t('constants.validate')}
                                handleClick={() => handlePostPromoCode(secondPromoCode, 'SecondDiscount')}
                                onChange={(e) => setSecondPromoCode(e.target.value.toUpperCase())}
                                isLoading={isLoading}
                                disabled={isFetching}
                            />
                        )
                    ) : (
                        <Button
                            onClick={() => setAddMorePromoCode(true)}
                            startIcon={<IconPlusCircle />}
                            disabled={!showPromochip}
                        >
                            {t('pages.onboarding.pricing.card.promocode.add-more')}
                        </Button>
                    )}
                </Grid>
            )}
        </>
    );
};
