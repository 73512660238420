import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useExportedQuantityInputVisibility } from './useExportedQuantityInputVisibility';
import { FormRow } from '@shared/components';
import { HARVEST_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/harvestFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type ExportedQuantityInputPropsT = {
    onChange: () => void;
    disabled?: boolean;
};

export const ExportedQuantityInput = ({ onChange, disabled }: ExportedQuantityInputPropsT) => {
    const { t } = useTranslation();
    const { shouldDisplayExportedQuantityInput } = useExportedQuantityInputVisibility();
    const context = useFormContext();

    if (!context) throw new Error('ExportedQuantityInput should be used inside a FormProvider');

    return shouldDisplayExportedQuantityInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.harvest.question.exported-quantity')}
                tooltipTitleKey="encoding-technical-itinerary.harvest.question.exported-quantity.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.harvest.question.exported-quantity.tooltip.text"
            />
            <Input
                type="number"
                suffix={t('constants.unit.tons-ha')}
                onChange={onChange}
                inputProps={{
                    ...context.register(HARVEST_FORM_INPUT_NAMES.EXPORTED_STRAWS_QUANTITY, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
                style={{ width: '250px' }}
                disabled={disabled}
            />
        </FormRow>
    ) : (
        <></>
    );
};
