// Make sure this is kept in sync with PROGRESS_SLUGS @shared/entities/progress/progress.types
export const encodingRoutesConfig = {
    PREPARATION: 'preparation',
    FARM_PROFILE: 'farm-profile',
    ROTATION: 'rotation',
    TECHNICAL_ITINERARY: 'technical-itinerary',
    IRRIGATION: 'irrigation',
    AGROFORESTRY: 'agroforestry',
    LIVESTOCK: 'livestock',
    DRAINAGE: 'drainage',
    SOIL_ANALYSIS: 'soil-analysis',
    HISTORY: 'history',
    ENCODING_DONE: 'encoding-done',
} as const;
