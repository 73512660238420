import { ReactNode } from 'react';
import { MediaInfoBlock } from '@soil-capital/ui-kit/MediaInfoBlock';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useFieldCropInfoBox } from './FieldCropInfoBox.style';
import { IconLocalisation } from '@soil-capital/ui-kit/icons';
import { cropNamesT } from '@shared/map/types/mapTypes';

export type FieldCropInfoBoxProps = {
    fieldName: string;
    fieldArea: string;
    cropNames?: cropNamesT[];
    mapSnapshot: string | null;
    icon?: ReactNode;
    snapshotSize?: 'small' | 'medium' | 'large';
    className?: string;
    handleFieldClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    disabled?: boolean;
};

export const FieldCropInfoBox = ({
    fieldName,
    fieldArea,
    cropNames,
    mapSnapshot,
    icon,
    snapshotSize = 'medium',
    className,
    handleFieldClick,
    disabled,
}: FieldCropInfoBoxProps) => {
    const hasCrops = cropNames && cropNames?.length > 0;
    const { classes } = useFieldCropInfoBox();

    return (
        <MediaInfoBlock
            disabled={disabled}
            Title={<MediaInfoBlock.Title text={fieldName} />}
            Subtitle={
                <>
                    <div style={{ maxWidth: '70%', display: hasCrops ? 'block' : 'none' }}>
                        {hasCrops &&
                            cropNames?.map((cropName, index) => (
                                <MediaInfoBlock.Subtitle key={index} text={cropName.name} />
                            ))}
                    </div>
                    <Typography variant="subcaption" style={{ maxWidth: '20%' }}>
                        {fieldArea}
                    </Typography>
                </>
            }
            Media={
                <div className={classes.snapshotContainer} onClick={handleFieldClick}>
                    <MediaInfoBlock.Image src={mapSnapshot} size={snapshotSize} />
                    {handleFieldClick && (
                        <>
                            <div className={classes.overlay} />
                            <IconLocalisation className={classes.icon} />
                        </>
                    )}
                </div>
            }
            Icon={icon}
            className={className}
        />
    );
};
