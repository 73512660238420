import { useEffect } from 'react';
import { initMap } from './utils/initMap';
import { useContext, useRef } from 'react';
import { MapContext } from './utils/MapProvider';
import { useToggleWithPayload } from '@shared/hooks/useToggleWithPayload';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import { intersectPolygon } from './utils/polygonHelper';
import { Polygon } from 'geojson';

const useMapComponent = (coordinates?: [number, number] | undefined) => {
    const mapContext = useContext(MapContext);
    if (!mapContext) {
        throw new Error('MapContext is null');
    }

    const {
        map,
        mapContainerRef: mapRef,
        recenterMapOnPolygons,
        centerMapOnCoordinates,
        zoomIn,
        zoomOut,
        setMap,
        fields,
        selectedFields,
    } = mapContext || {};

    const {
        open: fieldCreationModalOpen,
        payload: fieldCreationModalPayload,
        handleOpen: onFieldCreationModalOpen,
        handleClose: onFieldCreationModalClose,
    } = useToggleWithPayload<Feature<Geometry, GeoJsonProperties>>();

    const polygons = fields?.map((field) => field.polygon);

    const hasField = fields?.length > 0;

    const fieldsPolygon = fields?.map((field) => field.polygon.geometry);
    const fieldsPolygonRef = useRef(fieldsPolygon);

    const coords: [number, number] = coordinates ?? [4.8151, 50.6242];

    const recenterMap = () => {
        return hasField && recenterMapOnPolygons ? recenterMapOnPolygons(polygons) : centerMapOnCoordinates(coords);
    };

    useEffect(() => {
        if (mapRef?.current && !map && setMap) {
            const mapInstance = initMap(mapRef.current, coords);
            setMap(mapInstance);
            return () => {
                mapInstance.remove();
                setMap(null);
            };
        } // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (hasField && recenterMapOnPolygons) {
            recenterMapOnPolygons(polygons);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recenterMapOnPolygons]);

    useEffect(() => {
        fieldsPolygonRef.current = fieldsPolygon;
    }, [fieldsPolygon]);

    const onClick = (feature?: MapboxGeoJSONFeature) => {
        if (!feature) return;
        const isIntersecting = intersectPolygon(feature.geometry as Polygon, fieldsPolygonRef.current);
        if (!isIntersecting) {
            onFieldCreationModalOpen(feature);
        }
    };

    return {
        mapRef,
        recenterMapOnPolygons,
        centerMapOnCoordinates,
        zoomIn,
        zoomOut,
        fieldsPolygonRef,
        selectedFields,
        recenterMap,
        fieldCreationModalOpen,
        onFieldCreationModalOpen,
        onFieldCreationModalClose,
        fieldCreationModalPayload,
        onClick,
    };
};

export default useMapComponent;
