import { useTranslation } from 'react-i18next';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { FullscreenModal } from '@shared/components/FullscreenModal/FullscreenModal';
import { FormProvider } from 'react-hook-form';
import { useOperationDuplicationForm } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationForm';
import { OperationDuplicationLeftPanel } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/OperationDuplicationLeftPanel/OperationDuplicationLeftPanel';
import { OperationDuplicationRightPanel } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/OperationDuplicationRightPanel/OperationDuplicationRightPanel';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { ConfirmDuplicationModal } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/ConfirmDuplicationModal/ConfirmDuplicationModal';
import { FinaliseDuplicationModal } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FinaliseDuplicationModal/FinaliseDuplicationModal';
import { useDuplicationModals } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useDuplicationModals';
import { SuccessDuplicationModal } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/SuccessDuplicationModal/SuccessDuplicationModal';
import technicalItineraryRoutes from '@modules/encoding/modules/technicalItinerary/technicalItinerary.routes';
import { NavigateFunction } from 'react-router-dom';
import { useFieldsReadyForFinalisation } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FinaliseDuplicationModal/useFieldsReadyForFinalisation';
import { Button } from '@soil-capital/ui-kit/components';
import { useFullscreenModalMap } from '@shared/components/FullscreenModal/useFullscreenModalMap';
import { MapView } from '@shared/map/MapView';

export type OperationDuplicationModalPropsT = {
    open: boolean;
    onClose: () => void;
    sourceField: DuplicationFieldT;
    targetFields: DuplicationFieldT[];
    navigate: NavigateFunction;
};

export const OperationDuplicationModal = ({
    open,
    onClose,
    sourceField,
    targetFields,
    navigate,
}: OperationDuplicationModalPropsT) => {
    const { t } = useTranslation();
    const {
        openConfirmModal,
        handleOpenConfirmModal,
        handleCloseConfirmModal,
        openFinaliseModal,
        handleOpenFinaliseModal,
        handleCloseFinaliseModal,
        openSuccessModal,
        handleOpenSuccessModal,
        handleCloseSuccessModal,
    } = useDuplicationModals();

    const { fieldsReadyForFinalisation } = useFieldsReadyForFinalisation();

    const handleMutationSuccess = () => {
        (selectedFieldsNbr: number) => {
            handleCloseConfirmModal(sourceField, targetFields, selectedFieldsNbr);
        };

        handleOpenSuccessModal();
    };

    const { methods, onSubmit, onReset, isLoadingDuplication } = useOperationDuplicationForm({
        sourceField,
        onMutationSuccess: handleMutationSuccess,
    });

    const onModalSuccessClose = () => {
        onReset();
        handleCloseSuccessModal();
        handleCloseConfirmModal();
    };

    const onModalSuccessFinish = () => {
        handleCloseSuccessModal();
        return fieldsReadyForFinalisation?.length > 0
            ? handleOpenFinaliseModal()
            : navigate(technicalItineraryRoutes.introduction());
    };

    const onModalFinaliseConfirm = () => {
        handleCloseFinaliseModal();
        navigate(technicalItineraryRoutes.introduction());
    };

    const allFields = targetFields.concat(sourceField);
    const { polygons, calculateCentroid } = useFullscreenModalMap(allFields);
    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <FullscreenModal
                        open={open}
                        onClose={onClose}
                        title={
                            <>
                                <IconLightning />
                                {t('encoding-technical-itinerary.duplication.modal.title')}
                            </>
                        }
                        headerButton={
                            <Button onClick={onClose} variant="contained" color="primary">
                                {t('constants.finish')}
                            </Button>
                        }
                        leftPanel={<OperationDuplicationLeftPanel sourceField={sourceField} />}
                        rightPanel={
                            <OperationDuplicationRightPanel
                                sourceField={sourceField}
                                targetFields={targetFields}
                                onConfirmDuplicationClick={(selectedFields) =>
                                    handleOpenConfirmModal(sourceField, targetFields, selectedFields)
                                }
                            />
                        }
                        extraSection={
                            <MapView
                                helpText={t('selection-on-map-available')}
                                readOnly={true}
                                coordinates={calculateCentroid?.(polygons)}
                                hasOpendata={false}
                                hasControls
                            />
                        }
                    />
                    <ConfirmDuplicationModal
                        sourceFieldName={sourceField.name}
                        open={openConfirmModal}
                        onClose={(selectedFields: number) =>
                            handleCloseConfirmModal(sourceField, targetFields, selectedFields)
                        }
                        onConfirm={onSubmit}
                        isLoading={isLoadingDuplication}
                    />
                </form>
            </FormProvider>
            <SuccessDuplicationModal
                open={openSuccessModal}
                onConfirm={onModalSuccessClose}
                onFinish={onModalSuccessFinish}
            />
            <FinaliseDuplicationModal
                open={openFinaliseModal}
                onConfirm={onModalFinaliseConfirm}
                harvestYear={sourceField.harvestYear}
                fieldCrops={fieldsReadyForFinalisation}
            />
        </>
    );
};
