import { Typography } from '@soil-capital/ui-kit/material-core';
import { SnackbarContent } from 'notistack';
import { forwardRef } from 'react';
import { BoxedIcon, Button } from '@soil-capital/ui-kit';
import { SnackbarPropsT } from './Snackbar.types';
import useSnackbarLogic from './useSnackbar.logic';
import useSnackbarStyle from './useSnackbar.style';

/**
 * The custom snackbars used in the applications based on notistack library.
 */
export default forwardRef<HTMLDivElement, SnackbarPropsT>((props, ref) => {
    const { message, variant, snackbarAction } = props;
    const { classes } = useSnackbarStyle();
    const { snackbarIcon, iconBackgroundColor } = useSnackbarLogic(variant);

    return (
        <SnackbarContent ref={ref} role="alert">
            <div className={classes.card}>
                <BoxedIcon icon={snackbarIcon} style={{ backgroundColor: iconBackgroundColor }}></BoxedIcon>
                <Typography className={classes.typography} variant="body">
                    {message}
                </Typography>
                {snackbarAction && (
                    <Button variant="text" onClick={snackbarAction.onClick} className={classes.snackbarAction}>
                        {snackbarAction.label}
                    </Button>
                )}
            </div>
        </SnackbarContent>
    );
});
