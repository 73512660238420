import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import WeedingMachineForm from '../../components/WeedingMachineForm/WeedingMachineForm';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    return (
        <encodingShared.OperationFormActionPage
            title={t('db.operation_type.soil-work.weeding-machine')}
            navigate={navigate}
        >
            <WeedingMachineForm />
        </encodingShared.OperationFormActionPage>
    );
};
