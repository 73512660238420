import entities from '@shared/entities';
import useCurrentFarm from './useCurrentFarm';
import useCurrentSeasonId from './useCurrentSeasonId';

export default () => {
    const { currentFarm, currentFarmLoading } = useCurrentFarm();
    const { seasonState } = entities.season.useState({ farmId: currentFarm?.id }, { skip: !currentFarm?.id });
    const { currentSeasonId } = useCurrentSeasonId();

    return {
        currentSeason: seasonState.getById(currentSeasonId),
        currentSeasonLoading: currentFarmLoading || seasonState.isLoading,
    };
};
