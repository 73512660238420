import { TFunction } from 'i18next';

export const formatDateWithTranslatedMonth = (date: string, t: TFunction) => {
    const dateParts = date.split('-');
    const year = dateParts[0];
    const month = dateParts[1];
    const day = dateParts[2];

    const monthlabels = [
        'constants.months.january',
        'constants.months.february',
        'constants.months.march',
        'constants.months.april',
        'constants.months.may',
        'constants.months.june',
        'constants.months.july',
        'constants.months.august',
        'constants.months.september',
        'constants.months.october',
        'constants.months.november',
        'constants.months.december',
    ];

    return `${day} ${t(monthlabels[Number(month) - 1])} ${year}`;
};
