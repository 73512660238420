import { shapeEntity } from '../shapeEntity';
import operationApi, { operationApiInstance } from './operation.api';
import operationSchema from './operation.schema';
import operationState from './operation.state';

export default shapeEntity('Operation', {
    api: operationApiInstance,
    schema: operationSchema,
    ...operationApi,
    useState: operationState,
});
