import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()(() => {
    return {
        card: {
            backgroundColor: '#FFFFFF',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)',
            borderRadius: '4px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '8px 16px 8px 8px',
            gap: '8px',
        },
        typography: {
            whiteSpace: 'nowrap',
            maxWidth: '800px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        },
        snackbarAction: {
            marginLeft: '16px',
        },
    };
});
