import { CreateLivestockT, FarmSeasonLivestockT } from '@modules/onboarding/api/onboarding.type';
import { Dispatch, SetStateAction, useRef, useState } from 'react';

export interface ChildFormRef {
    getChildFormValues: () => void;
    changeLivestockType: () => void;
}

export const useLivestockFormsLogic = ({
    setSelectedLivestock,
}: {
    setSelectedLivestock: Dispatch<
        SetStateAction<{
            [key: number]: number | null;
        }>
    >;
}) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [livestockForms, setLivestockForms] = useState<number[]>([1]);
    const [livestockFormsCounter, setlivestockFormsCounter] = useState(1);
    const livestockFormRefs = useRef<ChildFormRef[]>([]);
    const [LivestockFormsValues, setLivestockFormsValues] = useState<CreateLivestockT[]>([]);
    const [defaultLivestockFormsValues, setDefaultLivestockFormsValues] = useState<
        {
            number: number;
            data: FarmSeasonLivestockT;
        }[]
    >([]);

    const addForm = () => {
        setLivestockForms([...livestockForms, livestockFormsCounter + 1]);
        setlivestockFormsCounter(livestockFormsCounter + 1);
    };
    const removeForm = (formNumber: number) => {
        setLivestockForms((prevForms) => prevForms.filter((num) => num !== formNumber));

        setLivestockFormsValues((prevLivestockFormsValues) => {
            const updatedValues = { ...prevLivestockFormsValues };
            delete updatedValues[formNumber];
            return updatedValues;
        });

        setDefaultLivestockFormsValues((prevDefaultValues) =>
            prevDefaultValues.filter((form) => form.number !== formNumber),
        );

        setSelectedLivestock((prevSelected) => {
            const updatedSelected = { ...prevSelected };
            delete updatedSelected[formNumber];
            return updatedSelected;
        });
        livestockFormRefs.current = livestockFormRefs.current.filter((ref, i) => i !== formNumber);
    };

    const handleReceiveChildData = (formNumber: number, data: CreateLivestockT) => {
        setLivestockFormsValues((prevLivestockFormsValues) => ({
            ...prevLivestockFormsValues,
            [formNumber]: data,
        }));
    };

    const submitForms = async () => {
        setIsSubmitting(true);
        livestockFormRefs.current.forEach((ref) => {
            if (ref) {
                ref.getChildFormValues();
            }
        });
    };

    return {
        addForm,
        removeForm,
        handleReceiveChildData,
        livestockFormRefs,
        LivestockFormsValues,
        setLivestockFormsValues,
        livestockForms,
        setLivestockForms,
        livestockFormsCounter,
        setlivestockFormsCounter,
        defaultLivestockFormsValues,
        setDefaultLivestockFormsValues,
        submitForms,
        isSubmitting,
        setIsSubmitting,
    };
};
