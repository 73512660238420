import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFormRowStyles = makeStyles()((theme) => ({
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(2),
    },
}));
