import { useState } from 'react';

export const useToggle = ({ defaultOpen = false }: { defaultOpen?: boolean } = {}) => {
    const [open, setOpen] = useState(defaultOpen);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleToggle = () => setOpen((prev) => !prev);

    return {
        open,
        handleOpen,
        handleClose,
        handleToggle,
    };
};
