import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    fieldMapSnapshot: {
        overflow: 'hidden',
        position: 'sticky',
        pointerEvents: 'none',
        top: 0,
        zIndex: 1,
    },
    snapshot: {
        '--snapshot-height': '212px',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.common.white,
        borderRadius: `0 0 ${theme.spacing(3)} ${theme.spacing(3)}`,
        paddingTop: theme.spacing(3),
        overflow: 'hidden',
        position: 'sticky',
        top: 0,
        marginBottom: 'calc(  min( var(--field-map-snapshot-scroll-position, 0), var(--snapshot-height)))',
        '&:after': {
            content: '""',
            display: 'block',
            marginBottom: 'max(calc(var(--snapshot-height) - var(--field-map-snapshot-scroll-position, 0)), 0px)',
        },
        zIndex: 1,
    },
    snapshotBg: {
        position: 'absolute',
        top: theme.spacing(2),
        left: 0,
        width: '100%',
        height: '100%',
    },
    snapshotImage: {
        objectFit: 'cover',
        borderRadius: theme.spacing(3),
        display: 'object',
    },
    content: {
        position: 'relative',
    },
    header: {
        pointerEvents: 'visible',
        textAlign: 'center',
        backgroundColor: theme.palette.common.white,
        marginBottom: theme.spacing(-1),
        paddingTop: theme.spacing(1),
        position: 'sticky',
        zIndex: 2,
    },
}));
