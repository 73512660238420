/* ---------------------------- route parameters ---------------------------- */
import { RouteParamsToStringT } from '@shared/utils/routesHelper';

/* -------------------------------------------------------------------------- */

export const paymentRoutesConfig = {
    inProgress: 'in-progress',
    failed: 'failed',
} as const;

/* --------------------------------- Routes --------------------------------- */
export type IntroductionParamsT = Record<string, never>;

const inProgress = () => `${paymentRoutesConfig.inProgress}`;
const failed = () => `${paymentRoutesConfig.failed}`;

/* ------------------- Route params to type useParams<X>() ------------------ */
export type paymentRouteParamsT = {
    inProgress: RouteParamsToStringT<IntroductionParamsT>;
    failed: RouteParamsToStringT<IntroductionParamsT>;
};
/* -------------------------------------------------------------------------- */

export default {
    inProgress,
    failed,
};
