import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import OperationDeepWorkMachineForm from '@modules/encoding/modules/technicalItinerary/components/OperationDeepWorkMachineForm/OperationDeepWorkMachineForm';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    return (
        <encodingShared.OperationFormActionPage
            title={t('db.operation_type.soil-work.deep-work-machine')}
            navigate={navigate}
        >
            <OperationDeepWorkMachineForm />
        </encodingShared.OperationFormActionPage>
    );
};
