import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCreateMineralFertiliserFormStyles = makeStyles()((theme) => ({
    row: {
        display: 'flex',
        marginBottom: theme.spacing(2),
        '& > *': {
            paddingRight: theme.spacing(2),
        },
    },
    createMineralFertiliserForm: {
        '& label': {
            display: 'none',
        },
    },
    tooltip: {
        padding: theme.spacing(1),
    },
    nameInput: {
        '& > *': {
            width: '100%',
        },
    },
    icon: {
        color: theme.palette.darkScale[700],
        padding: ' 2px 2px 2px 0',
    },
    text: { marginBottom: theme.spacing(1) },
    error: { backgroundColor: theme.palette.error.main },
}));
