import { makeStyles } from '@soil-capital/ui-kit/style';

export const useNavItemStyle = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        borderRadius: theme.shape.borderRadiusM,
        padding: theme.spacing(1),
        cursor: 'pointer',
        width: '100%',
    },
    doneContainer: {
        '&:hover': {
            backgroundColor: theme.palette.primaryScale[800],
            boxShadow: `inset 0 0 0 2px ${theme.palette.primaryScale[700]}`,
        },
    },
    activeDoneContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: `inset 0 0 0 2px ${theme.palette.primaryScale[300]}`,
    },
    activeContainer: {
        backgroundColor: theme.palette.common.white,
        boxShadow: `inset 0 0 0 2px ${theme.palette.primaryScale[300]}`,
        '& .MuiBox-root': {
            backgroundColor: `${theme.palette.darkScale[900]} !important`,
        },
    },
    disabledContainer: {
        cursor: 'auto',
        pointerEvents: 'none',
    },
    containerHover: {
        '&:hover': {
            boxShadow: `inset 0 0 0 2px ${theme.palette.primaryScale[700]}`,
            backgroundColor: theme.palette.primaryScale[800],
        },
    },
    title: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flexStart',
        flex: '1 0',
        userSelect: 'none',
    },
    doneIcon: {
        color: theme.palette.common.white,
    },
    arrowIcon: {
        fontSize: '16px',
    },
    endIcon: {
        display: 'flex',
        padding: theme.spacing(1),
        justifyContent: 'center',
        alignItems: 'center',
        color: theme.palette.darkScale[50],
    },
    elementHidden: {
        display: 'none',
    },
    elementVisible: {
        display: 'inherit',
    },
}));
