import { makeStyles } from '@soil-capital/ui-kit/style';

export const useLoginStyle = makeStyles()((theme) => ({
    logoImg: {
        maxWidth: '140px',
        marginBottom: '40px',
    },
    title: {
        color: theme.palette.primary.main,
        fontSize: '1.5em',
        marginBottom: '30px',
    },
    checkbox: {
        '& > div': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    forgotLink: { color: theme.palette.primary.main },
    signUpLink: {
        textDecoration: 'underline',
    },

    centerContent: {
        margin: 'auto',
    },
    divider: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
    },

    sideZoneImage: {
        backgroundImage: "url('/assets/images/sunny-field.jpg')",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
    },
    form: { display: 'flex', flexDirection: 'column', gap: theme.spacing(3), width: '100%' },
    loginButton: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(1),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    group: { display: 'flex', flexDirection: 'column', gap: theme.spacing(1) },
}));
