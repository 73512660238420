import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    GlobalSaveStatus: {
        width: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    saveStatusItem: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    loading: {
        color: theme.palette.neutral,
        width: '18px !important',
        height: '18px !important',
        marginLeft: '1px',
        marginRight: '1px',
    },
    errorIcon: {
        color: theme.palette.neutral,
    },
}));
