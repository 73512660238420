import fieldCropApi from './fieldCrop.api';
import { fieldCropCacheAdapter } from './fieldCrop.cache';
import { FIELD_CROP_CREATION_SOURCE_SLUGS, FieldCropEndpointParamsT } from './fieldCrop.types';
import createDefaultState from '../createDefaultState';
import { useCallback, useMemo } from 'react';

export default ({ farmSeasonId }: FieldCropEndpointParamsT, options?: { skip?: boolean }) => {
    const defaultState = createDefaultState(fieldCropCacheAdapter, () =>
        fieldCropApi.useGet({ farmSeasonId }, { skip: !farmSeasonId || options?.skip }),
    );

    const getByCropId = useCallback(
        ({ cropId }: { cropId: number }) => {
            return defaultState.list.filter((fieldCrop) => fieldCrop && fieldCrop.farm_season_crop_id === cropId);
        },
        [defaultState.list],
    );

    const getByFieldId = useCallback(
        ({ fieldId }: { fieldId: number }) => {
            return defaultState.list.filter((fieldCrop) => fieldCrop && fieldCrop.farm_season_field_id === fieldId);
        },
        [defaultState.list],
    );

    const getByCropIdAndFieldId = useCallback(
        ({ cropId, fieldId }: { cropId: number | null | undefined; fieldId: number | null | undefined }) => {
            return defaultState.list.find(
                (fieldCrop) => fieldCrop.farm_season_field_id === fieldId && fieldCrop.farm_season_crop_id === cropId,
            );
        },
        [defaultState],
    );

    const getImportedFieldCrops = useCallback(() => {
        return defaultState.list.filter(
            (fieldCrop) => fieldCrop.creation_source === FIELD_CROP_CREATION_SOURCE_SLUGS.PAC,
        );
    }, [defaultState.list]);

    return {
        fieldCropState: useMemo(
            () => ({
                ...defaultState,
                getByCropId,
                getByFieldId,
                getByCropIdAndFieldId,
                getImportedFieldCrops,
            }),
            [defaultState, getByCropId, getByCropIdAndFieldId, getByFieldId, getImportedFieldCrops],
        ),
    };
};
