import { useParams } from 'react-router-dom';
import { useFieldCropsWithCommonCrop } from '@shared/hooks';
import {
    DuplicationFieldT,
    useGetDuplicationFieldCropData,
} from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';

export const useGetTargetFields = () => {
    const { seasonId: seasonIdParam, fieldCropId: fieldCropIdParam } = useParams();
    const fieldCropId = Number(fieldCropIdParam);
    const seasonId = Number(seasonIdParam);

    const { fieldCropsWithCommonCrop, isLoading: isLoadingFields } = useFieldCropsWithCommonCrop({
        fieldCropId,
        seasonId,
    });
    const { getComputedField, isLoading: isDataLoading } = useGetDuplicationFieldCropData({
        farmSeasonId: seasonId,
    });

    const filteredFieldCrops = fieldCropsWithCommonCrop.filter((fieldCrop) => fieldCrop.id !== fieldCropId);
    const targetFieldsData = filteredFieldCrops.reduce<DuplicationFieldT[]>((acc, fieldCrop) => {
        const computedField = getComputedField(fieldCrop.id);
        if (computedField)
            acc.push({
                ...computedField,
            });

        return acc;
    }, []);

    const targetFieldsDataOrderedByName = targetFieldsData.sort((computedFieldA, computedFieldB) =>
        computedFieldA.name.toLowerCase().localeCompare(computedFieldB.name.toLowerCase()),
    );

    const isLoading = isLoadingFields || isDataLoading;

    return {
        targetFields: targetFieldsDataOrderedByName,
        isLoading,
    };
};
