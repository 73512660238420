import { useFarmProfileFormStyles } from '@modules/encoding/modules/farmProfile/components/FarmProfileForm/FarmProfileForm.style';
import { SkeletonInputRow } from '@shared/components/form/SkeletonInputRow/SkeletonInputRow';

export const FarmProfileFormSkeleton = () => {
    const { classes } = useFarmProfileFormStyles();

    return (
        <div className={classes.form} data-testid="farm-profile-form-skeleton">
            <SkeletonInputRow />
            <SkeletonInputRow size="sm" />
            <SkeletonInputRow size="lg" />
            <SkeletonInputRow size="sm" variant="radio" />
            <SkeletonInputRow size="lg" variant="radio" />
            <SkeletonInputRow />
            <SkeletonInputRow variant="radio" />
            <SkeletonInputRow size="lg" variant="radio" />
            <SkeletonInputRow size="sm" variant="radio" />
            <SkeletonInputRow variant="radio" />
            <SkeletonInputRow />
        </div>
    );
};
