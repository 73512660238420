import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { MINERAL_FERTILISER_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/mineralFertilisationFormSchema';
import { useMineralFertiliserSprayerWidthInputVisibility } from './useMineralFertiliserSprayerWidthInputVisibility';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type DestructionSprayerWidthInputPropsT = {
    onBlur: () => void;
    disabled?: boolean;
};

export const MineralFertiliserSprayerWidthInput = ({ onBlur, disabled }: DestructionSprayerWidthInputPropsT) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplayMineralFertiliserSprayerWidthInput } = useMineralFertiliserSprayerWidthInputVisibility();

    if (!context) throw new Error('MineralFertiliserSprayerWidthInput should be used inside a FormProvider');

    return shouldDisplayMineralFertiliserSprayerWidthInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.mineral-fertiliser.question.sprayer-width')}
                tooltipTitleKey="encoding-technical-itinerary.mineral-fertiliser.question.sprayer-width.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.mineral-fertiliser.question.sprayer-width.tooltip.text"
            />
            <Input
                type="number"
                data-testid="mineral-fertiliser-sprayer-width-input"
                suffix={t('constants.unit.meters')}
                onBlur={onBlur}
                inputProps={{
                    ...context.register(MINERAL_FERTILISER_FORM_INPUT_NAMES.SPRAYER_WIDTH, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
                style={{ width: '250px' }}
                disabled={disabled}
            />
        </FormRow>
    ) : (
        <></>
    );
};
