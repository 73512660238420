import { makeStyles } from '@soil-capital/ui-kit/style';

const useEncodingNavStyle = makeStyles()((theme) => ({
    encodingNav: {
        height: '100%',
        position: 'relative',
        width: '280px',
        display: 'flex',
        paddingTop: theme.spacing(2),
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'stretch',
        borderRadius: theme.shape.borderRadiusM,
        backgroundColor: theme.palette.darkScale[900],
    },
    skeletonSection: {
        backgroundColor: 'inherit',
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        transition: `${theme.transitions.easing.easeIn} opacity ${theme.transitions.duration.leavingScreen}ms, visibility ${theme.transitions.duration.leavingScreen}ms`,
        opacity: 0,
        visibility: 'hidden',
        paddingLeft: theme.spacing(2),
        zIndex: 900,
    },
    skeletonSectionLoading: {
        visibility: 'visible !important' as 'visible',
        opacity: '1 !important',
    },
    nav: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(2),
        flex: '1 0 0',
        alignSelf: 'stretch',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.darkScale[200],
            borderRadius: theme.shape.borderRadiusM,
        },
        paddingBottom: theme.spacing(3),
    },
    title: {
        display: 'flex',
        padding: `0px ${theme.spacing(2)}`,
        alignItems: 'flex-start',
        gap: theme.spacing(1),
    },
    list: {
        display: 'flex',
        padding: `0px ${theme.spacing(1)}`,
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: theme.spacing(1),
        alignSelf: 'stretch',
    },
    selectSeasons: {
        padding: `0px ${theme.spacing(2)}`,
    },
}));

export default useEncodingNavStyle;
