import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import useGlobalCharacteristicsActionPageLogic from './useGlobalCharacteristicsActionPage.logic';
import { GlobalCharacteristicsForm } from '../../components/GlobalCharacteristicsForm/GlobalCharacteristicsForm';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const {
        fieldName,
        cropName,
        harvestYear,
        isLoading,
        isContinueDisabled,
        isPerformingMutation,
        handleBackClick,
        handleContinueClick,
        isFarmTillageConventional,
        readOnly,
    } = useGlobalCharacteristicsActionPageLogic(navigate);

    const { t } = useTranslation();

    return (
        <encodingShared.ActionPage
            title={t('encoding-technical-itinerary.global-characteristics.title', { cropName, fieldName, harvestYear })}
            leftButtonProps={{
                onClick: handleBackClick(),
                children: t('constants.back'),
            }}
            rightButtonProps={{
                onClick: handleContinueClick(),
                loading: isPerformingMutation,
                disabled: isContinueDisabled,
                children: t('constants.continue'),
            }}
            isLoading={isLoading}
            readOnly={readOnly}
        >
            <GlobalCharacteristicsForm isFarmTillageConventional={isFarmTillageConventional} />
        </encodingShared.ActionPage>
    );
};
