import farmApi from './farm.api';
import { farmCacheAdapter } from './farm.cache';
import { FarmEndpointParamsT } from './farm.types';
import createDefaultState from '../createDefaultState';

export default ({ farmSeasonId }: FarmEndpointParamsT, options?: { skip?: boolean }) => {
    const shouldSkip = options?.skip || !farmSeasonId;

    const defaultState = createDefaultState(farmCacheAdapter, () =>
        farmApi.useGet({ farmSeasonId }, { skip: shouldSkip }),
    );

    return {
        farmState: {
            ...defaultState,
        },
    };
};
