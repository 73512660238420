import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';
import { useCanSeeResults } from '@modules/results/hooks/useCanSeeResults';

export const useAreResultsVisible = () => {
    const { hasAccessToResults, isLoading } = useCanSeeResults();

    if (isLoading) return undefined;

    return !!hasAccessToResults;
};

export const AreResultsVisibleGuard = (props: GuardT) => {
    const passed = useAreResultsVisible();

    return <BaseGuard passed={passed} {...props} />;
};
