import { useEffect, useMemo, useRef } from 'react';
import { GlobalMutationStatusT } from './GlobalSaveStatus.types';
import useGlobalSaveStatusData from './useGlobalSaveStatus.data';

const DELAY_RESET_STATUSES_AFTER_FAIL = 10; // seconds

export default ({ dataContext }: { dataContext: ReturnType<typeof useGlobalSaveStatusData> }) => {
    const globalMutationStatus = useMemo(() => {
        let status: GlobalMutationStatusT = 'none';
        if (dataContext.requestStatusesList.find((request) => request.status === 'failed')) {
            status = 'failed';
        } else if (dataContext.requestStatusesList.find((request) => request.status === 'pending')) {
            status = 'pending';
        } else if (dataContext.requestStatusesList.find((request) => request.status === 'success')) {
            status = 'success';
        }
        return status;
    }, [dataContext.requestStatusesList]);

    const latestFailedRequestId = useMemo(() => {
        if (globalMutationStatus !== 'failed') {
            return null;
        }
        return [...dataContext.requestStatusesList].reverse().find((req) => req.status === 'failed')?.id;
    }, [dataContext.requestStatusesList, globalMutationStatus]);

    const latestFailedRequestTimeoutRef = useRef<NodeJS.Timeout>();

    // when a request has failed, reset the statuses after x seconds
    useEffect(() => {
        if (latestFailedRequestId) {
            // clear the previous failed request timeout. we explictely don't clear on unmount.
            clearTimeout(latestFailedRequestTimeoutRef.current);

            latestFailedRequestTimeoutRef.current = setTimeout(() => {
                dataContext.resetRequestStatusList();
            }, DELAY_RESET_STATUSES_AFTER_FAIL * 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [latestFailedRequestId]);

    return {
        globalMutationStatus,
    };
};
