/* ---------------------------- route parameters ---------------------------- */
import { RouteParamsToStringT } from '@shared/utils/routesHelper';

/* -------------------------------------------------------------------------- */

export const eligibilityRoutesConfig = {
    eligibilityForm: '',
    livestock: 'livestock',
    eligible: 'eligible',
    nonEligible: 'non-eligible',
    livestockNonEligible: 'livestock-non-eligible',
    countryNonEligible: 'country-non-eligible',
} as const;

/* --------------------------------- Routes --------------------------------- */
export type IntroductionParamsT = Record<string, never>;

const eligibilityForm = () => `${eligibilityRoutesConfig.eligibilityForm}`;
const livestock = () => `${eligibilityRoutesConfig.eligibilityForm}/${eligibilityRoutesConfig.livestock}`;
const eligible = () => `${eligibilityRoutesConfig.eligibilityForm}/${eligibilityRoutesConfig.eligible}`;
const nonEligible = () => `${eligibilityRoutesConfig.eligibilityForm}/${eligibilityRoutesConfig.nonEligible}`;
const livestockNonEligible = () =>
    `${eligibilityRoutesConfig.eligibilityForm}/${eligibilityRoutesConfig.livestockNonEligible}`;
const countryNonEligible = () =>
    `${eligibilityRoutesConfig.eligibilityForm}/${eligibilityRoutesConfig.countryNonEligible}`;

/* ------------------- Route params to type useParams<X>() ------------------ */
export type EligibilityRouteParamsT = {
    eligibilityForm: RouteParamsToStringT<IntroductionParamsT>;
    livestock: RouteParamsToStringT<IntroductionParamsT>;
    eligible: RouteParamsToStringT<IntroductionParamsT>;
    nonEligible: RouteParamsToStringT<IntroductionParamsT>;
    livestockNonEligible: RouteParamsToStringT<IntroductionParamsT>;
    countryNonEligible: RouteParamsToStringT<IntroductionParamsT>;
};
/* -------------------------------------------------------------------------- */

export default {
    eligibilityForm,
    livestock,
    eligible,
    nonEligible,
    livestockNonEligible,
    countryNonEligible,
};
