import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCoverCropFormStyles = makeStyles()((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(3),
    },
    hiddenForm: {
        visibility: 'hidden',
        height: 0,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: theme.spacing(4),
    },
    select: {
        minWidth: 250,
        maxWidth: 250,
    },
}));
