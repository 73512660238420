import { FormProvider } from 'react-hook-form';
import { useSprayingFormLogic } from './useSprayingForm.logic';
import { SprayingFormSkeleton } from '../SprayingFormSkeleton/SprayingFormSkeleton';
import { SprayingSprayerWidthInput } from '../SprayingSprayerWidthInput/SprayingSprayerWidthInput';
import { SprayingPassageInput } from '../SprayingPassageInput/SprayingPassageInput';
import { useSprayingFormStyle } from './useSprayingForm.style';
import { useTranslation } from 'react-i18next';
import { SprayingFormPesticideTypeInput } from '@modules/encoding/modules/technicalItinerary/module/spraying/components/SprayingFormPesticideTypeInput/SprayingFormPesticideTypeInput';
import { SPRAYING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/sprayingFormschema';
import { ReuseOperationSelect } from '../../../operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { useReuseOperation } from '../../../operationDuplication/reuse/hooks/useReuseOperation';

export const SprayingForm = () => {
    const { classes, cx } = useSprayingFormStyle();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();
    const {
        pesticideTypes,
        selectedPesticideTypes,
        methods,
        isFormLoading,
        handleSubmit,
        totalPassageError,
        refetchFormData,
        getFormValues,
    } = useSprayingFormLogic();

    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        getFormValues: getFormValues,
        onOperationReused: refetchFormData,
    });

    const { t } = useTranslation();

    return (
        <>
            {isFormLoading ? <SprayingFormSkeleton /> : null}

            <FormProvider {...methods}>
                <form className={cx(classes.form, isFormLoading && classes.hiddenForm)} data-testid="spraying-form">
                    <ReuseOperationSelect
                        onReuseOperationTriggered={handleReuseOperation}
                        fieldCropId={fieldCropId}
                        operationId={operationId}
                        farmSeasonId={seasonId}
                        actionLoading={isReuseOperationLoading}
                        disabled={isReuseOperationLoading}
                    />
                    <SprayingSprayerWidthInput onChange={handleSubmit} disabled={isReuseOperationLoading} />

                    <SprayingFormPesticideTypeInput
                        onChange={handleSubmit}
                        onTypeDelete={handleSubmit}
                        name={SPRAYING_FORM_INPUT_NAMES.PESTICIDE_TYPES}
                        types={pesticideTypes}
                        disabled={isReuseOperationLoading}
                    />

                    {selectedPesticideTypes?.length > 1 && (
                        <SprayingPassageInput
                            onChange={handleSubmit}
                            name={SPRAYING_FORM_INPUT_NAMES.TOTAL_PASSAGE}
                            question={t('encoding-technical-itinerary.spraying.total-passages')}
                            tooltipTitleKey="encoding-technical-itinerary.spraying.total-passages-tooltip-title"
                            tooltipTextKey="encoding-technical-itinerary.spraying.total-passages-tooltip-text"
                            errorMessage={totalPassageError}
                            disabled={isReuseOperationLoading}
                        />
                    )}
                </form>
            </FormProvider>
        </>
    );
};
