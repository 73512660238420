import { useGlobalNavStyles } from '@shared/components/GlobalNav/GlobalNav.styles';
import { useTranslation } from 'react-i18next';
import { Typography, Skeleton } from '@soil-capital/ui-kit/material-core';
import { NavLink } from '@soil-capital/ui-kit';
import { useGlobalNav } from '@shared/components/GlobalNav/useGlobalNav';

export const GlobalNav = () => {
    const { harvestYear, isLoading, links, isPartnerPrepaying } = useGlobalNav();
    const { classes } = useGlobalNavStyles();
    const { t } = useTranslation();

    if (isLoading)
        return (
            <nav className={classes.nav} style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
                <Skeleton variant="text" width={200} height={50} />
                <Skeleton variant="rounded" width={265} height={60} />
                <Skeleton variant="rounded" width={265} height={60} />
                <Skeleton variant="rounded" width={265} height={60} sx={{ marginTop: 'auto' }} />
            </nav>
        );

    return (
        <nav className={classes.nav} data-testid="global-nav">
            <Typography variant="h3">{t('constants.harvest-year', { harvestYear })}</Typography>
            <ul className={isPartnerPrepaying ? classes.links : `${classes.links} ${classes.bottomLink}`}>
                {links.map((link) => (
                    <NavLink key={link.text} {...link} />
                ))}
            </ul>
        </nav>
    );
};
