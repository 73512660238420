import { useWatch, useFormContext } from 'react-hook-form';
import { useGetCoverCropFormStaticDataByTypeQuery } from '../../../api/coverCropFormApi';
import { COVER_CROP_STATIC_DATA_TYPE } from '../../../api/coverCropFormApi.types';
import { COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS } from '../coverCrop.types';
import { useEffect } from 'react';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';

export const useCoverCropHerbicideQuestion = () => {
    const destructionMethodId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID });

    const { setValue } = useFormContext();

    const { data: destructionMethods } = useGetCoverCropFormStaticDataByTypeQuery({
        type: COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_DESTRUCTION,
    });

    const destructionMethod = destructionMethods?.find((item) => item.id === destructionMethodId);

    const isVisible =
        destructionMethod?.slug === COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_CHEMICAL;

    useEffect(() => {
        if (destructionMethod && !isVisible) {
            setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_PRODUCT_ID, null);
        }
    }, [setValue, isVisible, destructionMethod]);

    return {
        isVisible,
    };
};
