import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    OperationTimelineNav: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    activeItemToScroll: {
        height: '350px',
        position: 'absolute',
        transform: 'translateY(-235px)', // FIXED height used to don't be hidden by the snapshot image.
    },
    navItem: {
        marginBottom: theme.spacing(1),
    },
    caption: {
        margin: theme.spacing(1, 0),
    },
}));
