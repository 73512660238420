import { useMemo } from 'react';
import entities, { OperationT, StaticOperationTypeConfigT, StaticOperationTypeT } from '@shared/entities';
import getDefaultSortComparerById from '@shared/entities/getDefaultSortComparerById';
import { OperationTypePeriodT } from '@shared/entities/staticOperationTypeConfig/staticOperationTypeConfig.types';

export type TimelineOperationListItem = {
    type: StaticOperationTypeT;
    config: StaticOperationTypeConfigT;
    operations: OperationT[];
};

export default ({
    period,
    fieldCropId,
    seasonId,
}: {
    period: OperationTypePeriodT;
    fieldCropId: string | number | undefined;
    seasonId: string | number | undefined;
}) => {
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { staticOperationTypeConfigState } = entities.staticOperationTypeConfig.useState();
    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: Number(seasonId),
    });

    const items = useMemo(() => {
        // the operationTypes must be loaded to be able to match with the operationTypeConfigs
        if (staticOperationTypeState.isLoading) return [];

        return staticOperationTypeConfigState
            .getByPeriod(period)
            .list.map<TimelineOperationListItem>((operationTypeConfig) => {
                const operationType = staticOperationTypeState.getById(operationTypeConfig.operation_type_id);
                if (!operationType)
                    throw Error('no operationType match for id ' + operationTypeConfig.operation_type_id);

                const sortedOperations = operationState
                    .getByOperationTypeConfigId(operationTypeConfig.id)
                    .slice() // Create a copy to avoid mutating the original array
                    .sort((a, b) => {
                        if (a.operation_date === null) return 1;
                        if (b.operation_date === null) return -1;
                        return new Date(a.operation_date).getTime() - new Date(b.operation_date).getTime();
                    });

                return {
                    config: operationTypeConfig,
                    operations: sortedOperations,
                    type: operationType,
                };
            })
            .sort(getDefaultSortComparerById<TimelineOperationListItem>((group) => group.operations[0]?.id ?? 0));
    }, [operationState, period, staticOperationTypeConfigState, staticOperationTypeState]);

    const isLoading =
        staticOperationTypeState.isLoading || staticOperationTypeConfigState.isLoading || operationState.isLoading;

    return {
        items,
        isLoading,
    };
};
