import { useMemo } from 'react';
import { OperationFormSchemaMapT, getOperationFormSchema } from '../schema/getOperationFormSchema';
import { OperationFormTypeT, getOperationFormApi } from '../api/getOperationFormApi';
import { useOperationRouteParams } from './useOperationRouteParams';

export const useOperationFormDefaultValues = <OperationTypeT extends OperationFormTypeT>(
    operationType: OperationTypeT,
) => {
    const formApi = getOperationFormApi(operationType);
    const formSchema = getOperationFormSchema(operationType);

    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();

    const {
        data: formApiData,
        isLoading: isFormLoading,
        refetch: refetchFormData,
    } = formApi.useGet({
        farmSeasonId: seasonId,
        fieldCropId,
        operationId,
    });

    const computeApiDataToMatchSchema: OperationFormSchemaMapT[OperationTypeT]['mapApiDataToSchema'] = useMemo(
        () => formSchema.mapApiDataToSchema,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return { formApiData: formApiData, computeApiDataToMatchSchema, isFormLoading, refetchFormData };
};
