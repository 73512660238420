import { Typography } from '@soil-capital/ui-kit/material-core';
import { IconWarning } from '@soil-capital/ui-kit/icons';
import { useWarningBoxStyles } from './WarningBox.style';
import { HTMLAttributes, PropsWithChildren } from 'react';

export const WarningBox = ({
    className,
    children,
    displayWarningIcon = true,
    variant = 'default',
}: { displayWarningIcon?: boolean; variant?: 'default' | 'withBorder' } & PropsWithChildren<
    HTMLAttributes<HTMLDivElement>
>) => {
    const { classes, cx } = useWarningBoxStyles();

    return (
        <div className={cx(classes.summary, variant === 'withBorder' && classes.withBorder, className)}>
            {displayWarningIcon ? <IconWarning color="warning" /> : null}
            <Typography variant="body2">{children}</Typography>
        </div>
    );
};
