import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { useMineralFertiliserProductsInput } from './useMineralFertiliserProductsInput.logic';
import { useMineralFertiliserProductsInputStyles } from './useMineralFertiliserProductsInput.style';
import { MineralFertiliserProductInput } from './MineralFertiliserProductInput/MineralFertiliserProductInput';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { MineralFertilisationInputNameT } from '@modules/encoding/modules/technicalItinerary/schema/mineralFertilisationFormSchema';
import { SelectMineralFertiliser } from '@modules/encoding/modules/technicalItinerary/components/SelectMineralFertiliser/SelectMineralFertiliser';

type MineralFertiliserProductsInputProps = {
    name: MineralFertilisationInputNameT;
    onChange: () => void;
    disabled?: boolean;
    physicalState: 'solid' | 'liquid';
};

export const MineralFertiliserProductsInput = ({
    name,
    onChange,
    disabled,
    physicalState,
}: MineralFertiliserProductsInputProps) => {
    const { t } = useTranslation();
    const { classes } = useMineralFertiliserProductsInputStyles();
    const { selectedProducts, handleFertilisersChange, handleProductRemoved } = useMineralFertiliserProductsInput({
        name,
        onChange,
    });

    return (
        <>
            <FormRow>
                <FormQuestion
                    question={t('encoding-technical-itinerary.mineral-fertiliser.question.used-products')}
                    tooltipTitleKey="encoding-technical-itinerary.mineral-fertiliser.question.used-products.tooltip.title"
                    tooltipTextKey="encoding-technical-itinerary.mineral-fertiliser.question.used-products.tooltip.text"
                />
                <SelectMineralFertiliser
                    physicalState={physicalState}
                    selectedFertiliserIds={selectedProducts.map((p) => p.static_fertiliser_id)}
                    handleFertilisersChange={handleFertilisersChange}
                    disabled={disabled}
                />
            </FormRow>
            <ul data-testid="mineral-fertiliser-form-selected-products-list" className={classes.list}>
                {selectedProducts?.map((product, index: number) => (
                    <li key={product.static_fertiliser_id}>
                        <MineralFertiliserProductInput
                            name={`${name}[${index}]`}
                            product={product}
                            handleProductRemoved={handleProductRemoved}
                            onChange={onChange}
                            disabled={disabled}
                        />
                    </li>
                ))}
            </ul>
        </>
    );
};
