import { Button } from '@soil-capital/ui-kit';
import { IconArrowLeft, IconCheckCircle } from '@soil-capital/ui-kit/icons';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useActionPageSkeletonStyles } from '@modules/encoding/shared/components/ActionPage/ActionPageSkeleton.style';

export const DATA_TEST_ID = 'action-page-skeleton';

export const ActionPageSkeleton = () => {
    const { classes } = useActionPageSkeletonStyles();

    return (
        <div className={classes.actionPageSkeleton} data-testid={DATA_TEST_ID}>
            <div className={classes.header}>
                <div className="left-zone">
                    <Skeleton>
                        <Button size="large" startIcon={<IconArrowLeft />} variant="text">
                            Précédent
                        </Button>
                    </Skeleton>
                </div>

                <Skeleton>
                    <Button size="large" startIcon={<IconCheckCircle color="inherit" />} variant="contained">
                        Continuer
                    </Button>
                </Skeleton>
            </div>

            <div className={classes.body}>
                {/* add overline */}
                <Skeleton variant="text" className={classes.subtitle} />
                <Skeleton variant="rectangular" className={classes.childrenSkeleton} />
                <Skeleton variant="rectangular" className={classes.childrenSkeleton} />
            </div>
        </div>
    );
};
