import { Grid, Skeleton, useTheme } from '@soil-capital/ui-kit/material-core';

export const BillingFormSkeleton = ({ withPaymentUpdate }: { withPaymentUpdate?: boolean }) => {
    const theme = useTheme();

    return (
        <>
            {withPaymentUpdate && <Skeleton variant="text" width="100%" height="154px" />}
            <Grid display="flex" gap={theme.spacing(3)}>
                <Skeleton height="28px" variant="rounded" width="28px" />
                <Skeleton height="28px" variant="text" width="80%" />
            </Grid>
            <Skeleton variant="text" width="20%" />
            <Skeleton height="56px" variant="rounded" />
            <Skeleton variant="text" width="20%" />
            <Skeleton height="56px" variant="rounded" />
            <Grid display="flex" gap={theme.spacing(3)}>
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
            </Grid>
            <Grid display="flex" gap={theme.spacing(3)}>
                <Skeleton height="56px" variant="rounded" width="100%" />
                <Skeleton height="56px" variant="rounded" width="100%" />
                <Skeleton height="56px" variant="rounded" width="100%" />
            </Grid>
            <Skeleton variant="text" width="20%" />
            <Skeleton height="56px" variant="rounded" />
            <Skeleton variant="text" width="20%" />
            <Skeleton height="56px" variant="rounded" />
        </>
    );
};
