import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { useDestructionSprayerWidthInputVisibility } from './useDestructionSprayerWidthInputVisibility';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type DestructionSprayerWidthInputPropsT = {
    onChange: () => void;
};

export const DestructionSprayerWidthInput = ({ onChange }: DestructionSprayerWidthInputPropsT) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplayDestructionSprayerWidthInput } = useDestructionSprayerWidthInputVisibility();

    if (!context) throw new Error('DestructionSprayerWidthInput should be used inside a FormProvider');

    return shouldDisplayDestructionSprayerWidthInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.destruction.question.destruction-sprayer-width')}
                tooltipTitleKey="encoding-technical-itinerary.destruction.question.destruction-sprayer-width.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.destruction.question.destruction-sprayer-width.tooltip.text"
            />
            <Input
                type="number"
                suffix={t('constants.unit.meters')}
                onChange={onChange}
                inputProps={{
                    ...context.register(DESTRUCTION_FORM_INPUT_NAMES.SPRAYER_WIDTH, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
                style={{ width: '250px' }}
            />
        </FormRow>
    ) : (
        <></>
    );
};
