import { NavigateFunction, useLocation } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { useIntroductionContentData } from './useIntroductionContentData';
import { IntroProgressLayout } from '@modules/encoding/shared/components/IntroProgressLayout/IntroProgressLayout';
import { HelpBox, Stack } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import useCurrentLanguage from '@shared/hooks/useCurrentLanguage';
import { HistoryDuplicationModal } from '@modules/encoding/modules/history/components/HistoryDuplicationModal/HistoryDuplicationModal';
import { useToggle } from '@shared/hooks/useToggle';
import { useGetHistoryDuplicationDataQuery } from '@modules/encoding/modules/history/api/historyApi';
import { skipToken } from '@reduxjs/toolkit/query';
import { useEffect } from 'react';
import entities from '@shared/entities';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';
import { ContentPage } from '@modules/encoding/shared/components/ContentPage/ContentPage';
import { IntroProgressItem } from '@modules/encoding/shared/components/IntroProgressItem/IntroProgressItem';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { cropDataList, harvestYear, isDataLoading, handleContinueClick, handleCropClick } =
        useIntroductionContentData();
    const { currentSeasonId: seasonId } = useCurrentSeasonId();
    const { nextStepToComplete, lastCompletedStep, getRouteMatchingStep } = useProgressTracker();
    const { pathname } = useLocation();
    const { isBaseline, isHistoryFarmSeason, baselineSeason } = useBaselineFarmSeason();
    const currentLanguage = useCurrentLanguage();
    const {
        data: historyDuplication,
        isLoading: isLoadingHistoryDuplication,
        isFetching: isFetchingHistoryDuplication,
    } = useGetHistoryDuplicationDataQuery(isHistoryFarmSeason ? { farmSeasonId: seasonId } : skipToken);
    const {
        open: openHistoryDuplicationModal,
        handleOpen: handleOpenHistoryDuplicationModal,
        handleClose: handleCloseHistoryDuplicationModal,
    } = useToggle();
    const { progressState } = entities.progress.useState({ seasonId: seasonId });
    const isTechnicalItineraryDone = progressState?.getBySlug(PROGRESS_SLUGS.TECHNICAL_ITINERARY)?.status === 'done';

    const handleStepRedirect = () => {
        // Technical itinerary is last step of history FS
        if (!nextStepToComplete && !isBaseline)
            return navigate(`/${baselineSeason?.id}/${encodingRoutesConfig.HISTORY}`);

        if (nextStepToComplete) return navigate(`/${seasonId}/${getRouteMatchingStep(nextStepToComplete?.slug)}`);
        if (lastCompletedStep && pathname === `/${seasonId}/${getRouteMatchingStep(lastCompletedStep?.slug)}`)
            return navigate(`/${seasonId}/${encodingRoutesConfig.ENCODING_DONE}`);
        if (lastCompletedStep) return navigate(`/${seasonId}/${getRouteMatchingStep(lastCompletedStep?.slug)}`);

        return navigate(`/${seasonId}/${encodingRoutesConfig.ENCODING_DONE}`);
    };

    useEffect(() => {
        if (
            !isLoadingHistoryDuplication &&
            !isFetchingHistoryDuplication &&
            !isTechnicalItineraryDone &&
            historyDuplication?.length === 0
        )
            handleOpenHistoryDuplicationModal();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [historyDuplication, isLoadingHistoryDuplication, isFetchingHistoryDuplication, isTechnicalItineraryDone]);

    const hasStarted = cropDataList.some((cropData) =>
        cropData.fieldDataList.some((fieldData) => fieldData.currentProgress > 0),
    );

    return (
        <>
            <HistoryDuplicationModal open={openHistoryDuplicationModal} onClose={handleCloseHistoryDuplicationModal} />
            <ContentPage
                Title={
                    <ContentPage.Title>
                        {t('encoding-technical-itinerary.introduction.title', { harvestYear })}
                    </ContentPage.Title>
                }
                Image={<ContentPage.Image src="/assets/images/farm-tractor-field.webp" />}
                Action={
                    <ContentPage.Action
                        onClick={
                            cropDataList.every((cropData) =>
                                cropData.fieldDataList.every(
                                    (fieldData) => fieldData.currentProgress === fieldData.maxProgress,
                                ),
                            )
                                ? handleStepRedirect
                                : handleContinueClick
                        }
                    >
                        {hasStarted ? t('constants.continue') : t('constants.start')}
                    </ContentPage.Action>
                }
                isLoading={isDataLoading}
            >
                <IntroProgressLayout
                    title={t('encoding-technical-itinerary.introduction.progress-title')}
                    leftSection={
                        <Stack spacing={1} mt={1}>
                            {cropDataList.map((cropData) => (
                                <IntroProgressItem
                                    image={cropData.image}
                                    key={cropData.cropId}
                                    text={cropData.name}
                                    progress={{
                                        max: cropData.maxProgress,
                                        current: cropData.currentProgress,
                                        text: cropData.textProgress,
                                    }}
                                >
                                    {cropData.fieldDataList.length > 0 && (
                                        <Stack spacing={1} mt={1}>
                                            {cropData.fieldDataList.map((fieldData) => (
                                                <IntroProgressItem
                                                    key={fieldData.fieldId}
                                                    onClick={() => handleCropClick(fieldData, cropData)}
                                                    text={fieldData.name}
                                                    infoText={fieldData.infoText}
                                                    image={fieldData.image}
                                                    progress={{
                                                        max: fieldData.maxProgress,
                                                        current: fieldData.currentProgress,
                                                    }}
                                                />
                                            ))}
                                        </Stack>
                                    )}
                                </IntroProgressItem>
                            ))}
                        </Stack>
                    }
                    rightSection={
                        <HelpBox>
                            <Typography variant="h3">
                                {t('encoding-technical-itinerary.introduction.help-title')}
                            </Typography>
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-technical-itinerary.introduction.help-subtitle-1', { harvestYear })}
                            </Typography>
                            <Typography variant="subcaption" color="neutral">
                                <Trans
                                    i18nKey="encoding-technical-itinerary.introduction.help-description-1"
                                    components={{
                                        video:
                                            currentLanguage?.iso_code === 'fr' ? (
                                                <a
                                                    href="https://www.loom.com/share/00ea03fa6e104d45a707f8f35986f1ff?sid=71698c5b-3c55-46a0-bf09-cbf3927a3044"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                />
                                            ) : (
                                                <a
                                                    href="https://www.loom.com/share/e2743562306a4557928fb94c159a632a?sid=1d9b0296-4c55-4300-8ae4-bdedcf61b6ff"
                                                    target="_blank"
                                                    rel="noreferrer"
                                                />
                                            ),
                                    }}
                                />
                            </Typography>
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-technical-itinerary.introduction.help-subtitle-2')}
                            </Typography>
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-technical-itinerary.introduction.help-description-2')}
                            </Typography>
                            <Typography variant="body" component="h4" mt={2}>
                                {t('encoding-technical-itinerary.introduction.help-subtitle-3')}
                            </Typography>
                            <Typography variant="subcaption" color="neutral">
                                {t('encoding-technical-itinerary.introduction.help-description-3')}
                            </Typography>
                        </HelpBox>
                    }
                />
            </ContentPage>
        </>
    );
};
