import { useWatch, useFormContext, useFormState } from 'react-hook-form';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { useEffect } from 'react';

export const useCoverCropSeedingDepthQuestion = () => {
    const seedingMachineId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.SEEDING_MACHINE_ID }) as number;
    const isFromPreviousCrop = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.IS_FROM_PREVIOUS_CROP }) as boolean;

    const { currentFarm } = encodingShared.useCurrentFarm();

    const { dirtyFields } = useFormState();

    const { setValue } = useFormContext();

    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });
    const seedingMachine = staticMachineryState.getById(seedingMachineId);

    useEffect(() => {
        if (seedingMachine && dirtyFields[COVER_CROP_FORM_INPUT_NAMES.SEEDING_MACHINE_ID]) {
            // the machine has changed, we need to reset the seeding depth
            setValue(
                COVER_CROP_FORM_INPUT_NAMES.SEEDING_DEPTH,
                seedingMachine.depth ? Number(seedingMachine.depth) : seedingMachine.depth,
            );
        } else if (isFromPreviousCrop) {
            setValue(COVER_CROP_FORM_INPUT_NAMES.SEEDING_DEPTH, null);
        }
    }, [seedingMachine, setValue, dirtyFields, isFromPreviousCrop]);

    return {
        isVisible: seedingMachine && seedingMachine.depth === null,
    };
};
