import { useTranslation } from 'react-i18next';
import { useFarmSeasonFieldDeleteModalLogic } from '@modules/encoding/modules/rotation/components/FarmSeasonFieldDeleteModal/useFarmSeasonFieldDeleteModalLogic';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';

type FarmSeasonFieldDeleteModalProps = {
    fieldId: number;
    open: boolean;
    onClose: () => void;
};

export const FarmSeasonFieldDeleteModal = ({ fieldId, open, onClose }: FarmSeasonFieldDeleteModalProps) => {
    const { t } = useTranslation();
    const { field, onDelete, isDeletingField } = useFarmSeasonFieldDeleteModalLogic({ fieldId, onClose });

    return (
        <ConfirmModal
            isOpen={open}
            type="warning"
            onClose={({ confirmed }) => (confirmed ? onDelete() : onClose())}
            title={t('encoding-rotation.modal.field-delete-modal.title')}
            primaryText={{
                translationKey: 'encoding-rotation.modal.field-delete-modal.warning',
                translationValues: { fieldName: field?.name },
            }}
            secondaryText={{ translationKey: t('encoding-rotation.modal.field-delete-modal.description') }}
            cancelText={t('constants.cancel')}
            confirmText={t('constants.confirm-deletion')}
            confirmLoading={isDeletingField}
        />
    );
};
