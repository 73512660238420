import api from '@shared/api';

export const farmSeasonPreparationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        createFarmSeasonPreparation: builder.mutation<object, { farmSeasonId: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/preparation`,
                method: 'POST',
            }),
            invalidatesTags: ['Progress'],
        }),
    }),
});

export const { useCreateFarmSeasonPreparationMutation } = farmSeasonPreparationApi;
