import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetRenewalInformationQuery } from '@modules/payment/api/paymentApi';
import { countrySlugs } from '@shared/constants/countrySlugs';
import { currencyIsoCodeSlugs } from '@shared/constants/currencyIsoCodeSlugs';
import { PRICING } from '@shared/constants/pricing';
import { formatDateWithTranslatedMonth } from '@shared/utils/formatDateWithTranslatedMonth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import { auth } from '@modules/auth';
import { useEffect } from 'react';

export const usePricingLogic = () => {
    const { t } = useTranslation();
    const [track] = useTrackMutation();
    const navigate = useNavigate();
    const { currentSeason } = useCurrentSeason();
    const farmId = currentSeason?.farm_id;
    const { data, isLoading } = useGetRenewalInformationQuery({ farmId });
    const authUser = auth.useAuthUser();

    const floorPrice = data?.currency.iso_code === currencyIsoCodeSlugs.EUR ? PRICING.floorEUR : PRICING.floorUK;
    const ceilingPrice = data?.currency.iso_code === currencyIsoCodeSlugs.EUR ? PRICING.ceilingEUR : PRICING.ceilingUK;
    const isDeferredPayment = data?.deferred_payment;
    const subscriptionPrice = data?.subtotal || 0;
    const price = data?.total_excluding_tax || 0;
    const currency = data?.currency.symbol || '';
    const discount = data?.discounts[0]?.value;
    const payableHectares = data?.quantity || 0;
    const discountEndDate = formatDateWithTranslatedMonth(data?.discounts[0]?.end_date || '', t);
    const nextYear = data?.harvest_year ? data?.harvest_year + 1 : 0;
    const isBelgian = data?.country === countrySlugs.BE;
    // when a payment has failed and user returns at the first step his discount info is still valid no matter of the discount end date
    const hasTriedToPay = !!data?.invoice_id;

    const deferredPaymentCta = isDeferredPayment
        ? () => {
              navigate(`/${currentSeason?.id}/payment/billing-info`);
          }
        : undefined;
    const onBackClick = () => navigate(`/${currentSeason?.id}`);
    useEffect(() => {
        track({
            event: EVENTS.PRICING_FLOW_STARTED,
            properties: {
                user_role: authUser?.role?.name ?? '',
                pricing_stage: isDeferredPayment ? 'deferred' : 'renewal',
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        data,
        floorPrice,
        ceilingPrice,
        isLoading,
        isDeferredPayment,
        subscriptionPrice,
        price,
        currency,
        discount,
        payableHectares,
        discountEndDate,
        isBelgian,
        hasTriedToPay,
        deferredPaymentCta,
        onBackClick,
        nextYear,
    };
};
