import { makeStyles } from '@soil-capital/ui-kit/style';

const useAuthLayoutStyle = makeStyles()((theme) => {
    const largeScreen = theme.breakpoints.up('md');

    return {
        root: {
            height: '100vh',
            width: '100%',
        },

        content: {
            minHeight: '100vh',
            width: '100%',
            overflowY: 'auto',
            position: 'relative',
        },

        sidezone: {
            width: '100%',
            height: '100vh',
            backgroundImage: 'url(assets/images/background-dots.png)',
            right: 0,
            [largeScreen]: {
                position: 'fixed',
            },
        },

        box: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            boxSizing: 'border-box',
            backgroundColor: 'white',
            padding: theme.spacing(4),
            [largeScreen]: {
                borderRadius: 0,
                minHeight: '100%',
                padding: theme.spacing(4),
            },
        },

        logo: {
            marginBottom: theme.spacing(5),
            height: '64px',
            [largeScreen]: {
                marginBottom: 0,
            },
        },

        title: {
            textAlign: 'center',
            color: theme.palette.text.primary,
            fontSize: '1.5em',
            margin: 'auto',
            marginBottom: theme.spacing(5),
            marginTop: theme.spacing(6),
            maxWidth: '450px',
        },
        contentCenter: {
            flex: '1',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        formContainer: {
            maxWidth: '300px',
            width: '100%',
            margin: '0 auto',
        },
    };
});

export default useAuthLayoutStyle;
