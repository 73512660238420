import useContentPageStyle from '@modules/encoding/shared/components/ContentPage/ContentPage.style';

export const NotFoundPanel = () => {
    const { classes } = useContentPageStyle();

    return (
        <div className={classes.contentPage}>
            <img
                alt="Not found"
                src={'/assets/images/not_found.png'}
                style={{ width: '100%', height: '95%', objectFit: 'contain' }}
            />
        </div>
    );
};
