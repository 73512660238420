import { Dispatch, ReactNode, RefObject, SetStateAction, createContext, useRef, useState } from 'react';
import { Map } from 'mapbox-gl';

export const MapContext = createContext<{
    mapContainerRef: RefObject<HTMLDivElement>;
    map: Map | null;
    setMap: Dispatch<SetStateAction<Map | null>>;
} | null>(null);

/** Provide the map context for internal map behavior: useMap, MapRoot, MapPortal */
export const MapProvider = ({ children }: { children: ReactNode }) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<mapboxgl.Map | null>(null);

    return (
        <MapContext.Provider
            value={{
                map,
                setMap,
                mapContainerRef,
            }}
        >
            {children}
        </MapContext.Provider>
    );
};
