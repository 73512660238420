import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { Checkbox } from '@soil-capital/ui-kit/components';
import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { Trans } from 'react-i18next';
import { useCommitmentStyle } from './useCommitment.style';
import { useCommitmentLogic, COMMITMENT } from './useCommitmentLogic';
import { FormProvider } from 'react-hook-form';
import { CommitmentFormSkeleton } from '@modules/onboarding/components/CommitmentFormSkeleton/CommitmentFormSkeleton';

export const Commitment = () => {
    const { classes } = useCommitmentStyle();
    const theme = useTheme();
    const {
        methods,
        onSubmit,
        hasApproved,
        termsAndConditionsPdfLink,
        landownerApprovalPdfLink,
        onBackClick,
        isTacDataLoading,
        isPostTACLoading,
        isFrenchFarm,
        t,
    } = useCommitmentLogic();

    return (
        <OnboardingStepsModal
            stepProgress={2}
            ctaAction={onSubmit}
            ctaText={'pages.onboarding.commitment.cta'}
            isCTAloading={isPostTACLoading}
            isCTAdisabled={!hasApproved}
            onBackClick={onBackClick}
        >
            {isTacDataLoading ? (
                <CommitmentFormSkeleton />
            ) : (
                <FormProvider {...methods}>
                    <form noValidate onSubmit={onSubmit}>
                        <Grid
                            display="flex"
                            flexDirection="column"
                            paddingX={theme.spacing(3)}
                            paddingTop={theme.spacing(6)}
                            gap={theme.spacing(3)}
                            paddingBottom={isFrenchFarm ? 0 : theme.spacing(6)}
                        >
                            <Typography>{t('pages.onboarding.commitment.title')}</Typography>
                            <Checkbox
                                label={
                                    <Typography variant="body2" className={classes.padding}>
                                        <Trans i18nKey="pages.onboarding.commitment.t-and-c">
                                            Accept the
                                            <span /* eslint-disable-line */
                                                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(termsAndConditionsPdfLink, '_blank');
                                                }}
                                            >
                                                General Conditions of Sale
                                            </span>
                                            .
                                        </Trans>
                                    </Typography>
                                }
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    methods.setValue(COMMITMENT.APPROVAL, e.target.checked as true);
                                }}
                                checked={hasApproved}
                            />
                            <Checkbox
                                label={
                                    <Typography variant="body2" className={classes.padding}>
                                        <Trans i18nKey="pages.onboarding.commitment.estate">
                                            Some of my leases end in 5 years or less, so I commit to providing
                                            <span /* eslint-disable-line */
                                                style={{ textDecoration: 'underline', fontWeight: 'bold' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    window.open(landownerApprovalPdfLink, '_blank');
                                                }}
                                            >
                                                landowner approval
                                            </span>
                                            before receiving my first carbon payments.
                                        </Trans>
                                    </Typography>
                                }
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    methods.setValue(COMMITMENT.LANDOWNERAPPROVAL, e.target.checked);
                                }}
                                checked={methods.watch(COMMITMENT.LANDOWNERAPPROVAL)}
                            />

                            {isFrenchFarm && (
                                <Grid display="flex" flexDirection="column" gap={theme.spacing(2)}>
                                    <Typography variant="body2">{t('pages.onboarding.commitment.ges')}</Typography>
                                    <Checkbox
                                        label={
                                            <Typography variant="body2" className={classes.padding}>
                                                {t('pages.onboarding.commitment.rapeseed')}
                                            </Typography>
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            methods.setValue(COMMITMENT.RAPESEED, e.target.checked);
                                        }}
                                        checked={methods.watch(COMMITMENT.RAPESEED)}
                                    />

                                    <Checkbox
                                        label={
                                            <Typography variant="body2" className={classes.padding}>
                                                {t('pages.onboarding.commitment.sunflower')}
                                            </Typography>
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            methods.setValue(COMMITMENT.SUNFLOWER, e.target.checked);
                                        }}
                                        checked={methods.watch(COMMITMENT.SUNFLOWER)}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </form>
                </FormProvider>
            )}
        </OnboardingStepsModal>
    );
};
