import { useForm } from '@shared/hooks';
import { useCoverCropFormOptions } from './useCoverCropFormOptions';
import { useCallback, useEffect } from 'react';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { COVER_CROP_FORM_INPUT_NAMES, coverCropFormSchema } from '../../schema/coverCropFormSchema';
import { getOperationFormApi } from '../../api/getOperationFormApi';
import { CoverCropFormDataT } from '../../api/operationFormApi.types';
import { useDispatch } from 'react-redux';
import api from '@shared/api';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';

export const useCoverCropForm = () => {
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const dispatch = useDispatch();

    const {
        isLoading: isLoadingOptions,
        destructionMethodOptions,
        destructionHeightOptions,
        destructionMachinesOptions,
        seedingMachinesOptions,
        pesticidesOptions,
    } = useCoverCropFormOptions();

    const { data: formApiData, isLoading: isLoadingDefaultData } = getOperationFormApi('cover-crop').useGet({
        farmSeasonId: seasonId,
        fieldCropId: fieldCropId,
        operationId: operationId,
    });

    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });

    const isLoading = isLoadingOptions || isLoadingDefaultData || staticMachineryState.isLoading || currentFarmLoading;

    const [updateFormData] = getOperationFormApi('cover-crop').useUpdate();

    const methods = useForm<typeof coverCropFormSchema.schema>({
        schema: coverCropFormSchema.schema,
        defaultValues: coverCropFormSchema.mapApiDataToSchema(),
        displaySnackbarOnSchemaError: true,
    });

    const {
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { dirtyFields },
    } = methods;

    useEffect(() => {
        if (formApiData) {
            reset(coverCropFormSchema.mapApiDataToSchema(formApiData));
        }
    }, [formApiData, reset]);

    const invalidateOperationState = useCallback(() => {
        dispatch(api.util.invalidateTags(['Operation']));
    }, [dispatch]);

    const setDestructionWidthDefaultValue = useCallback(
        (data: CoverCropFormDataT) => {
            const destructionMachine = staticMachineryState.getById(data.destruction_machinery?.machinery_id);
            if (destructionMachine && destructionMachine.slug === STATIC_MACHINERY_SLUGS.SPRAYER) {
                const currentDestructionWidth = getValues(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_WIDTH);
                if (currentDestructionWidth === null)
                    setValue(
                        COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_WIDTH,
                        data.defaultOptions.sprayerWidth ? Number(data.defaultOptions.sprayerWidth) : null,
                    );
            }
        },
        [getValues, setValue, staticMachineryState],
    );

    const onSubmit = useStableDebounce(
        handleSubmit(async (data) => {
            const shouldInvalidateOperation =
                formApiData?.destruction_date !== data.destruction_date &&
                dirtyFields[COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE];
            try {
                const response = await updateFormData({
                    farmSeasonId: seasonId,
                    fieldCropId: fieldCropId,
                    operationId: operationId,
                    body: data,
                }).unwrap();

                if (shouldInvalidateOperation) {
                    invalidateOperationState();
                }

                setDestructionWidthDefaultValue(response);
            } catch (e) {
                console.error(e);
            }

            // keep the current values but reset the dirtyFields object - ie.  once saved, the form is not dirty anymore
            reset({}, { keepValues: true });
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    return {
        methods,
        onSubmit,
        isLoading,
        destructionMethodOptions: destructionMethodOptions ?? [],
        destructionHeightOptions: destructionHeightOptions ?? [],
        destructionMachinesOptions: destructionMachinesOptions ?? [],
        seedingMachinesOptions: seedingMachinesOptions ?? [],
        pesticidesOptions: pesticidesOptions ?? [],
    };
};
