import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';

export const useGetCurrentHarvestYear = (seasonId?: number) => {
    const { currentSeason, currentSeasonLoading } = encodingShared.useCurrentSeason();
    const currentSeasonId = seasonId ?? currentSeason?.season_id;
    const { staticSeasonState } = entities.staticSeason.useState();
    const harvestYear = staticSeasonState.getById(currentSeasonId)?.harvest_year;

    const isLoading = currentSeasonLoading || staticSeasonState.isLoading;

    return { harvestYear, isLoading };
};
