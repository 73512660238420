import { useCoverCropSeedingDepthQuestion } from './useCoverCropSeedingDepthQuestion';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@soil-capital/ui-kit/components';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';

export const CoverCropSeedingDepthQuestion = ({ onChange }: { onChange: () => void }) => {
    const { isVisible } = useCoverCropSeedingDepthQuestion();
    const methods = useFormContext();
    const { t } = useTranslation();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.seeding-depth')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.seeding-depth"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.seeding-depth"
                    />
                    <Input
                        type="number"
                        suffix={t('constants.unit.cm')}
                        onChange={onChange}
                        inputProps={{
                            ...methods.register(COVER_CROP_FORM_INPUT_NAMES.SEEDING_DEPTH, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        placeholder={t('encoding-cover-crop.question.placeholder.depth')}
                        style={{ width: '250px' }}
                    />
                </FormRow>
            )}
        </>
    );
};
