import { useLocation } from 'react-router-dom';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';

export const useGetProgressStepMatchingCurrentRoute = () => {
    const location = useLocation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });

    for (const stepKey in encodingRoutesConfig) {
        const step = stepKey as keyof typeof encodingRoutesConfig;
        const route = encodingRoutesConfig[step];
        const match = location.pathname.match(new RegExp(route));

        if (match) {
            const stepSlug = route.toUpperCase().replaceAll('-', '_') as keyof typeof PROGRESS_SLUGS;
            const slug = PROGRESS_SLUGS[stepSlug];
            return {
                currentProgressStep: slug ? progressState?.getBySlug(slug) : undefined,
                isLoading: progressState.isLoading,
            };
        }
    }

    return { currentProgressStep: undefined, isLoading: progressState.isLoading };
};
