export const onboardingRoutesConfig = {
    INVITATION: 'invitation',
    ELIGIBILITY: 'eligibility',
    BILLING: 'billing-information',
    SEASONCLOSED: 'season-closed',
    COMMITMENT: 'commitment',
    PRICING: 'pricing',
    PAYMENT: 'onboarding-payment',
    CONFIRMATION: 'confirmation',
} as const;
