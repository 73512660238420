import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { useDestructionMachineryInputVisibility } from './useDestructionMachineryInputVisibility';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import {
    FormAutoCompleteOptionT,
    FormAutocomplete,
    FormAutocompleteProps,
} from '@shared/components/FormAutocomplete/FormAutocomplete';

type DestructionMachineryInputPropsT = {
    machineries: FormAutoCompleteOptionT[];
    onChange: FormAutocompleteProps<FormAutoCompleteOptionT, false>['onChange'];
};

export const DestructionMachineryInput = ({ machineries, onChange }: DestructionMachineryInputPropsT) => {
    const { t } = useTranslation();

    const { shouldDisplayDestructionMachineryInput } = useDestructionMachineryInputVisibility();

    return shouldDisplayDestructionMachineryInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.destruction.question.destruction-machinery')}
                tooltipTitleKey="encoding-technical-itinerary.destruction.question.destruction-machinery.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.destruction.question.destruction-machinery.tooltip.text"
            />
            <FormAutocomplete
                name={DESTRUCTION_FORM_INPUT_NAMES.MACHINERY_ID}
                options={machineries}
                placeholder={t('encoding-technical-itinerary.destruction.question.destruction-machinery.placeholder')}
                onChange={onChange}
                multiple={false}
            />
        </FormRow>
    ) : (
        <></>
    );
};
