import { useWatch } from 'react-hook-form';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import entities from '@shared/entities';
import { useParams } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';

export const useCoverCropDestructionDateQuestion = () => {
    const { fieldCropId: fieldCropIdParam, operationId: operationIdParam } = useParams();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { staticSeasonState } = entities.staticSeason.useState();
    const { currentSeason } = encodingShared.useCurrentSeason();
    const harvestYear = staticSeasonState.getById(currentSeason?.season_id)?.harvest_year;

    const destructionDate = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE });

    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropIdParam),
        farmSeasonId: Number(currentSeasonId),
    });

    const operation = operationState.getById(operationIdParam);

    const monthDiff = (d1: Date, d2: Date) => {
        let months;
        months = (d2.getFullYear() - d1.getFullYear()) * 12;

        months -= d1.getMonth();
        months += d2.getMonth();
        return months <= 0 ? 0 : months;
    };

    return {
        timeOnField: destructionDate
            ? monthDiff(new Date(operation?.operation_date as string), new Date(destructionDate))
            : 0,
        isTimeonFieldVisible: destructionDate !== null,
        minDate: operation?.operation_date ? new Date(operation?.operation_date) : undefined,
        harvestYear,
    };
};
