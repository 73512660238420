import { type OrganicFertiliserStaticDataT } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/api/organicFertiliserApi.types';
import { type OrganicFertiliserStaticDataTypeT } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/organicFertiliser.types';
import api from '@shared/api';

export const organicFertiliserApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getOrganicFertiliserStaticDataByType: builder.query<
            OrganicFertiliserStaticDataT[],
            { type: OrganicFertiliserStaticDataTypeT }
        >({
            query: ({ type }) => ({
                url: `/v3/static/static-data/${type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetOrganicFertiliserStaticDataByTypeQuery } = organicFertiliserApi;
