export const COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS = {
    COVER_CROP_DESTRUCTION_MECHANICAL: 'cover-crop-destruction-mechanical',
    COVER_CROP_DESTRUCTION_PLOUGHING: 'cover-crop-destruction-ploughing',
    COVER_CROP_DESTRUCTION_FROST: 'cover-crop-destruction-frost',
    COVER_CROP_DESTRUCTION_GRAZING: 'cover-crop-destruction-grazing',
    COVER_CROP_DESTRUCTION_CHEMICAL: 'cover-crop-destruction-chemical',
} as const;

export const COVER_CROP_HEIGHT_STATIC_DATA_SLUGS = {
    COVER_CROP_HEIGHT_BELLOW_KNEE_LEVEL: 'cover-crop-height-bellow-knee-level',
    COVER_CROP_HEIGHT_BELLOW_WAIST_LEVEL: 'cover-crop-height-bellow-waist-level',
    COVER_CROP_HEIGHT_ABOVE_WAIST_LEVEL: 'cover-crop-height-above-waist-level',
} as const;
