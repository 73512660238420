import { useRotationAssignationForm } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/hooks/useRotationAssignationForm';
import {
    changeActiveFilter,
    resetFieldId,
    resetFilters,
    ROTATION_FIELDS_FILTERS,
} from '@modules/encoding/modules/rotation/store/filter.slice';
import { encodingShared } from '@modules/encoding/shared';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useToggle } from '@shared/hooks/useToggle';
import { useDispatch } from 'react-redux';
import { useFinalizeFieldCropsAssignation } from './hooks/useFinalizeFieldCropsAssignation';
import entities from '@shared/entities';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const useFieldCropAssignationModalLogic = ({ onClose }: { onClose: () => void }) => {
    const { open: openFinaliseModal, handleOpen, handleClose: onFinaliseModalClose } = useToggle();
    const dispatch = useDispatch();
    const { methods } = useRotationAssignationForm();
    const { currentSeason } = encodingShared.useCurrentSeason();

    const { fieldState } = useGetFarmSeasonFields();
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeason?.id });
    const { getFieldsWithoutCrop } = fieldState.useGetFieldsWithoutCrop();
    const fieldsWithoutCrop = getFieldsWithoutCrop();
    const [track] = useTrackMutation();

    const { finalizeAssignation, isFinalizeLoading } = useFinalizeFieldCropsAssignation();

    const onFinaliseWarningModalConfirm = () => {
        dispatch(resetFieldId());
        dispatch(changeActiveFilter(ROTATION_FIELDS_FILTERS.FIELDS_WITHOUT_CROP));
        methods.reset();
        track({
            event: EVENTS.FORGOTTEN_FIELDS_MODAL_DISPLAYED,
            properties: { forgotten_fields_number: fieldsWithoutCrop.length, action: 'continue' },
        });
        onFinaliseModalClose();
    };

    // Runs when assignation modal is closed
    const handleModalClose = async () => {
        await finalizeAssignation();
        onFinaliseModalClose();

        dispatch(resetFilters());
        methods.reset();
        if (fieldsWithoutCrop.length > 0) {
            track({
                event: EVENTS.FORGOTTEN_FIELDS_MODAL_DISPLAYED,
                properties: { forgotten_fields_number: fieldsWithoutCrop.length, action: 'cancel' },
            });
        }
        onClose();
    };

    const handleReadOnlyModalClose = () => {
        onFinaliseModalClose();
        dispatch(resetFilters());
        onClose();
    };

    // Open finalise when there are fields without crop
    const onAssignationModalFinish = () => {
        if (readOnly) {
            return handleReadOnlyModalClose();
        }
        return fieldsWithoutCrop.length > 0 ? handleOpen() : handleModalClose();
    };

    const { readOnly } = useFarmSeasonReadOnly();

    const isWaitingForFieldCropCreation = fieldCropState.list.some((fieldCrop) => fieldCrop.id < 0);

    return {
        methods,
        isFinalizeLoading,
        onAssignationModalFinish,
        openFinaliseModal,
        onFinaliseWarningModalConfirm,
        onFinaliseModalClose: handleModalClose,
        readOnly,
        isWaitingForFieldCropCreation,
    };
};
