import { MINERAL_FERTILISER_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/mineralFertilisationFormSchema';
import { useWatch } from 'react-hook-form';

export const useMineralFertiliserSprayerWidthInputVisibility = () => {
    const liquidProductsValue = useWatch({
        name: MINERAL_FERTILISER_FORM_INPUT_NAMES.MINERAL_FERTILISER_LIQUID_PRODUCTS,
    });

    const shouldDisplayMineralFertiliserSprayerWidthInput = !!(liquidProductsValue && liquidProductsValue.length > 0);

    return { shouldDisplayMineralFertiliserSprayerWidthInput };
};
