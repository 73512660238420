import { Button, Modal } from '@soil-capital/ui-kit';
import { IconCheckCircle, IconLightning } from '@soil-capital/ui-kit/icons';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { useSuccessDuplicationModalStyles } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/SuccessDuplicationModal/useSuccessDuplicationModalStyles';
import { useFieldsReadyForFinalisation } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FinaliseDuplicationModal/useFieldsReadyForFinalisation';

export type SuccessDuplicationModalProps = {
    open: boolean;
    onFinish: () => void;
    onConfirm: () => void;
};

export const SuccessDuplicationModal = ({ open, onFinish, onConfirm }: SuccessDuplicationModalProps) => {
    const { t } = useTranslation();
    const { classes } = useSuccessDuplicationModalStyles();
    const { isFetching } = useFieldsReadyForFinalisation();

    return (
        <Modal
            size={'small'}
            open={open}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>
                        {t('encoding-technical-itinerary.duplication.modal.success-modal.title')}
                    </Modal.HeaderTitle>
                </Modal.Header>
            }
            body={
                <Modal.Body className={classes.body}>
                    <IconCheckCircle className={classes.icon} color="success" variant="filled" />
                    <Typography className={classes.text}>
                        {t('encoding-technical-itinerary.duplication.modal.success-modal.text')}
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Button onClick={onFinish} variant="text" disabled={isFetching} loading={isFetching}>
                        {t('constants.end')}
                    </Button>
                    <Button onClick={onConfirm} variant="contained">
                        <IconLightning />
                        {t('encoding-technical-itinerary.duplication.modal.success-modal.confirm')}
                    </Button>
                </Modal.Footer>
            }
        />
    );
};
