import { useWatch } from 'react-hook-form';
import { HARVEST_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/harvestFormSchema';
import { HARVEST_STATIC_DATA_SLUGS, HARVEST_STATIC_DATA_TYPE } from '../../harvest.types';
import { useGetHarvestFormStaticDataByTypeQuery } from '../../api/harvestFormApi';

export const useExportedQuantityInputVisibility = () => {
    const { data: strawsUsage } = useGetHarvestFormStaticDataByTypeQuery({
        type: HARVEST_STATIC_DATA_TYPE.HARVEST_STRAWS_USAGE,
    });

    const strawsUsageValue = useWatch({ name: HARVEST_FORM_INPUT_NAMES.STRAWS_USAGE_STATIC_DATA_ID });
    const exportedStrawsId = strawsUsage?.find(
        (el) => el.slug === HARVEST_STATIC_DATA_SLUGS.HARVEST_STRAWS_USAGE_EXPORTED,
    )?.id;

    const shouldDisplayExportedQuantityInput = strawsUsageValue === exportedStrawsId;

    return { shouldDisplayExportedQuantityInput };
};
