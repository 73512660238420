import { useToggle } from '@shared/hooks/useToggle';

const useMapEdit = () => {
    const {
        open: editFieldModalOpen,
        handleOpen: onEditFieldModalOpen,
        handleClose: onEditFieldModalClose,
    } = useToggle();

    return {
        editFieldModalOpen,
        onEditFieldModalOpen,
        onEditFieldModalClose,
    };
};

export default useMapEdit;
