import { useRoutes } from 'react-router-dom';
import { onboardingRoutesConfig } from './Onboarding.routes';
import { eligibility } from './pages/Eligibility';
import { BillingInformation } from './pages/BillingInformation/BillingInformation';
import { Pricing } from './pages/Pricing/Pricing';
import { payment } from './pages/Payment';
import { Confirmation } from './pages/Confirmation/Confirmation';
import PaymentPageLayout from '@shared/components/PaymentPage/PaymentPageLayout';
import { Invitation } from './pages/Invitation/Invitation';
import { RedirectionLoader } from './pages/RedirectionLoader/RedirectionLoader';
import { Commitment } from './pages/Commitment/Commitment';
import { SeasonClosed } from './pages/SeasonClosed/SeasonClosed';
import OnboardingGuard from './components/OnboardingGuard/Onboarding.guard';

export default () => {
    const routes = useRoutes([
        { path: '/', element: <RedirectionLoader /> },
        {
            path: onboardingRoutesConfig.INVITATION,
            element: <Invitation />,
        },
        {
            path: `${onboardingRoutesConfig.ELIGIBILITY}/*`,
            element: <eligibility.Component />,
        },
        {
            path: onboardingRoutesConfig.BILLING,
            element: <BillingInformation />,
        },
        {
            path: onboardingRoutesConfig.SEASONCLOSED,
            element: <SeasonClosed />,
        },
        {
            path: onboardingRoutesConfig.COMMITMENT,
            element: <Commitment />,
        },
        {
            path: onboardingRoutesConfig.PRICING,
            element: <Pricing />,
        },
        {
            path: `${onboardingRoutesConfig.PAYMENT}/*`,
            element: <payment.Component />,
        },
        {
            path: onboardingRoutesConfig.CONFIRMATION,
            element: <Confirmation />,
        },
    ]);

    return (
        <OnboardingGuard>
            <PaymentPageLayout>{routes}</PaymentPageLayout>
        </OnboardingGuard>
    );
};
