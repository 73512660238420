import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { useDestructionProductInputVisibility } from './useDestructionProductInputVisibility';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormAutoCompleteOptionT, FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';

type DestructionProductInputPropsT = {
    destructionProducts: FormAutoCompleteOptionT[];
    onChange: () => void;
};

export const DestructionProductInput = ({ destructionProducts, onChange }: DestructionProductInputPropsT) => {
    const { t } = useTranslation();

    const { shouldDisplayDestructionProductInput } = useDestructionProductInputVisibility();

    return shouldDisplayDestructionProductInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.destruction.question.destruction-product')}
                tooltipTitleKey="encoding-technical-itinerary.destruction.question.destruction-product.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.destruction.question.destruction-product.tooltip.text"
            />
            <FormAutocomplete
                name={DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_PESTICIDE_ID}
                options={destructionProducts}
                placeholder={t('encoding-technical-itinerary.destruction.question.destruction-product.placeholder')}
                onChange={onChange}
                multiple={false}
            />
        </FormRow>
    ) : (
        <></>
    );
};
