import { Typography } from '@soil-capital/ui-kit/material-core';
import { RadioGroup, RadioGroupOptionT } from '@shared/components/form/RadioGroup/RadioGroup';
import { ORGANIC_FERTILISER_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/organicFertilisationSchema';
import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { useOrganicFertiliserFormStyles } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/components/OrganicFertiliserForm/OrganicFertiliserForm.style';

type ApplicationMethodInputProps = {
    options: RadioGroupOptionT[];
    onChange: () => void;
    disabled?: boolean;
};

export const ApplicationMethodInput = ({ options, onChange, disabled }: ApplicationMethodInputProps) => {
    const { classes } = useOrganicFertiliserFormStyles();
    const { t } = useTranslation();

    return (
        <FormRow>
            <Typography>{t('encoding-technical-itinerary.organic-fertiliser.question.how-were-they-apply')}</Typography>
            <RadioGroup
                type="number"
                className={classes.radioGroup}
                name={ORGANIC_FERTILISER_FORM_INPUT_NAMES.APPLICATION_METHOD_ID}
                options={options}
                onChange={onChange}
                disabled={disabled}
            />
        </FormRow>
    );
};
