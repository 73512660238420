import { makeStyles } from '@soil-capital/ui-kit/style';

const EMITING_MORE_GRADIENT = 'linear-gradient(180deg, #F10D0D 0%, #F5D101 60%, #F5DA00 100%)';
const EMITING_LESS_GRADIENT = 'linear-gradient(180deg, #F4890B 0%, #F5D101 60%, #F5DA00 100%)';
const STORING_LESS_GRADIENT = 'linear-gradient(180deg, #9DD057 0%, #F8E22F 50%, #F6DB01 100%)';
const STORING_MORE_GRADIENT = 'linear-gradient(180deg, #42BD7F 0%, #F8E22F 60%, #F6DB01 100%)';

const getGraphGradient = (value: number) => {
    if (value > 1.5) return EMITING_MORE_GRADIENT;
    if (value > 0) return EMITING_LESS_GRADIENT;
    if (value > -0.3) return STORING_LESS_GRADIENT;
    return STORING_MORE_GRADIENT;
};

export const useCarbonResultsGraphStyles = makeStyles<{ verticalAxisHeight: number; value: number }>()(
    (theme, { verticalAxisHeight, value }) => {
        const graphGradient = getGraphGradient(value);

        return {
            container: {
                width: '100%',
                position: 'relative',
                height: 320,
            },
            ground: {
                width: '100%',
                background: `repeating-linear-gradient(-10deg, transparent, transparent 4px, ${theme.palette.secondaryScale[300]} 1px, ${theme.palette.secondaryScale[300]} 5px)`,
                position: 'absolute',
                bottom: 0,
            },
            grass: {
                position: 'absolute',
                top: 0,
                // So grass blend nicely with the ground
                transform: 'translateY(-95%)',
                left: '35%',
            },
            zero: {
                position: 'absolute',
                left: '60px',
            },
            graph: {
                zIndex: 3,
                position: 'absolute',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 30,
                background: graphGradient,
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
            },
            graphMedialLine: {
                zIndex: 2,
                left: '50%',
                top: 0,
                transform: 'translateX(-50%)',
                position: 'absolute',
                display: 'block',
                width: 1,
                height: '100%',
                background: theme.palette.darkScale[300],
            },
            graphLegend: {
                position: 'absolute',
                bottom: theme.spacing(-0.5),
                left: '50%',
                transform: 'translate(-50%, 100%)',
                width: 75,
                textAlign: 'center',
                color: theme.palette.success.main,
            },
            invertedGraph: {
                transform: 'translate(-50%, 100%) scaleY(-1)',
            },
            horizontalAxis: {
                position: 'relative',
                // So it extends slightly on the left of the vertical axis
                width: 'calc(100% - 60px)',
                height: 2,
                background: theme.palette.darkScale[900],
                marginLeft: 'auto',
                zIndex: 4,
            },
            verticalAxis: {
                position: 'relative',
                zIndex: 2,
                marginLeft: '80px',
                height: `100%`,
                width: 3,
                borderRadius: theme.shape.borderRadius,
                background: theme.palette.darkScale[900],
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            },
            verticalAxisLegend: {
                position: 'absolute',
                bottom: theme.spacing(-0.5),
                left: 0,
                transform: 'translate(-50%, 100%)',
                display: 'block',
                width: 75,
                textAlign: 'center',
            },
            // The separators with the scale values starts with a slight padding from the bottom
            verticalAxisSeparatorContainer: {
                height: `${verticalAxisHeight}%`,
                marginTop: 'auto',
                marginBottom: 'auto',
                width: 3,
                position: 'relative',
                zIndex: 2,
            },
            axisSeparator: {
                position: 'absolute',
                zIndex: 2,
                display: 'flex',
                alignItems: 'flex-end',
                gap: '2px',
                left: 0,
                transform: 'translateX(-100%)',
                marginLeft: '6px',
            },
            axisSeparatorLine: {
                display: 'block',
                width: 10,
                height: 2,
                background: theme.palette.darkScale[900],
            },
            axisValue: {
                transform: 'translateY(50%)',
            },
            sequestration: {
                position: 'absolute',
                display: 'block',
                transform: 'rotate(-90deg)',
                maxWidth: 60,
                wordBreak: 'break-word',
                textAlign: 'center',
                hyphens: 'manual',
                left: -10,
                bottom: '10%',
                zIndex: 2,
                lineHeight: '12px',
            },
            emission: {
                position: 'absolute',
                display: 'block',
                transform: 'rotate(-90deg)',
                textAlign: 'center',
                left: -10,
                top: '10%',
                zIndex: 2,
                lineHeight: '12px',
            },
            hide: {
                display: 'none',
            },
        };
    },
);
