import { MenuItem, Select } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import {
    FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY,
    type FarmProfileStaticDataSlugsKeysT,
} from '@modules/encoding/modules/farmProfile/constants';
import { type FarmProfileStaticDataSlugsT } from '@modules/encoding/modules/farmProfile/farmProfile.types';
import { Controller, useFormContext } from 'react-hook-form';
import { forwardRef } from 'react';
import { FarmProfileStaticDataT } from '@modules/encoding/modules/farmProfile/api/farmProfileApi.types';

type FarmProfileSelectProps = {
    className?: string;
    defaultValue?: FarmProfileStaticDataSlugsT | '';
    options: FarmProfileStaticDataT[];
    name: string;
    onChange?: () => void;
};

export const FarmProfileSelect = forwardRef(
    ({ className, defaultValue = '', onChange, name, options }: FarmProfileSelectProps, ref) => {
        const { t } = useTranslation();

        const context = useFormContext();

        if (!context) throw new Error('FarmProfileSelect should be used inside a FormProvider');

        return (
            <Controller
                control={context.control}
                name={name}
                defaultValue={defaultValue}
                render={({ field: { onChange: onFieldChange, ...field } }) => (
                    <Select
                        {...field}
                        ref={ref}
                        className={className}
                        displayEmpty
                        placeholder={t('constants.select')}
                        onChange={(e) => {
                            onFieldChange(e);
                            onChange?.();
                        }}
                    >
                        <MenuItem sx={{ display: 'none' }} value="" disabled>
                            {t('constants.select')}
                        </MenuItem>
                        {options?.map((data) => (
                            <MenuItem key={data.slug} value={data.slug}>
                                {t(
                                    FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY[
                                        data.slug as FarmProfileStaticDataSlugsKeysT
                                    ],
                                )}
                            </MenuItem>
                        ))}
                    </Select>
                )}
            />
        );
    },
);
