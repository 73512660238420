import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';

type ValidateRotationConfirmModalProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const ValidateRotationConfirmModal = ({ open, onClose, onConfirm }: ValidateRotationConfirmModalProps) => {
    const { t } = useTranslation();

    return (
        <ConfirmModal
            isOpen={open}
            onClose={({ confirmed }) => (confirmed ? onConfirm() : onClose())}
            title={t('encoding-rotation.modal.finalise-rotation-assignation-confirm-modal.title')}
            primaryText={{
                translationKey: 'encoding-rotation.modal.finalise-rotation-assignation-confirm-modal.information',
            }}
            secondaryText={{
                translationKey: 'encoding-rotation.modal.finalise-rotation-assignation-confirm-modal.description',
            }}
            confirmText={t('constants.continue')}
        />
    );
};
