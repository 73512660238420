import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { QueryStatus } from '@reduxjs/toolkit/query';

export const useIsLoadingQueries = () => {
    const isLoading = useSelector((state: SharedStateT) => {
        return Object.values(state.api.queries).some((query) => {
            return query && query.status === QueryStatus.pending;
        });
    });

    return { isLoading };
};
