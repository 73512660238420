import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { HARVEST_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/harvestFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormAutoCompleteOptionT, FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';

type HarvestMachineryInputPropsT = {
    machineries: FormAutoCompleteOptionT[];
    onChange: () => void;
    disabled?: boolean;
};

export const HarvestMachineryInput = ({ machineries, onChange, disabled }: HarvestMachineryInputPropsT) => {
    const { t } = useTranslation();

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.harvest.question.used-machinery')}
                tooltipTitleKey="encoding-technical-itinerary.harvest.question.used-machinery.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.harvest.question.used-machinery.tooltip.text"
            />
            <FormAutocomplete
                name={HARVEST_FORM_INPUT_NAMES.MACHINERY_ID}
                options={machineries}
                placeholder={t('encoding-technical-itinerary.harvest.question.used-machinery.placeholder')}
                onChange={onChange}
                disabled={disabled}
                multiple={false}
            />
        </FormRow>
    );
};
