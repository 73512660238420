import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { formatNumber } from '@shared/utils';
import { useTranslation } from 'react-i18next';

type FieldDataT = {
    id: number;
    name: string;
    image: string;
    infoText: string;
    maxOrganicMatterProgress: number;
    currentOrganicMatterProgress: number;
    maxSoilAcidityProgress: number;
    currentSoilAcidityProgress: number;
};

export const useIntroductionContentData = () => {
    const { t } = useTranslation();

    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();

    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { harvestedFields, harvestedFieldsLoading } = fieldState.useHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });

    const { getFieldThumbnail } = encodingShared.useGetFieldThumbnail({ seasonId: currentSeasonId });

    const isDataLoading =
        harvestedFieldsLoading ||
        currentFarmLoading ||
        fieldState.isLoading ||
        cropState.isLoading ||
        fieldCropState.isLoading ||
        staticCropState.isLoading;

    const getFieldInfoText = ({ fieldId, fieldArea }: { fieldId: number; fieldArea: number }) => {
        const firstFieldCrop = fieldCropState.getByFieldId({ fieldId }).at(0);
        const crop = cropState.getById(firstFieldCrop?.farm_season_crop_id);
        const staticCrop = staticCropState.getById(crop?.crop_id);

        const infoText = `${t(staticCrop?.translation_slug ?? '')} ${formatNumber(fieldArea, 2)} HA`;

        return infoText;
    };

    const fieldDataList = harvestedFields.map<FieldDataT>((field) => {
        const infoText = getFieldInfoText({ fieldArea: field.area, fieldId: field.id });
        const fieldThumbnail = getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at });

        const maxOrganicMatterProgress = 1;
        const currentOrganicMatterProgress = field.soil_organic_matter_id ? 1 : 0;
        const maxSoilAcidityProgress = 1;
        const currentSoilAcidityProgress = field.soil_acidity_id ? 1 : 0;

        return {
            id: field.id,
            image: fieldThumbnail,
            infoText,
            name: field.name,
            maxOrganicMatterProgress,
            currentOrganicMatterProgress,
            maxSoilAcidityProgress,
            currentSoilAcidityProgress,
        };
    });

    const maxSoilAcidityProgress =
        fieldDataList.reduce((progress, fieldData) => {
            // false | true si considered as assigned.
            return progress + fieldData.maxSoilAcidityProgress;
        }, 0) || 1;
    let currentSoilAcidityProgress = fieldDataList.reduce((progress, fieldData) => {
        // false | true si considered as assigned.
        return progress + fieldData.currentSoilAcidityProgress;
    }, 0);
    if (harvestedFields.length === 0) {
        currentSoilAcidityProgress = 1;
    }

    const maxOrganicMatterProgress =
        fieldDataList.reduce((progress, fieldData) => {
            // false | true si considered as assigned.
            return progress + fieldData.maxOrganicMatterProgress;
        }, 0) || 1;
    let currentOrganicMatterProgress = fieldDataList.reduce((progress, fieldData) => {
        // false | true si considered as assigned.
        return progress + fieldData.currentOrganicMatterProgress;
    }, 0);
    if (harvestedFields.length === 0) {
        currentOrganicMatterProgress = 1;
    }

    const maxSoilAnalysisImportProgress = 1;
    const currentSoilAnalysisImportProgress = 0;

    return {
        isDataLoading,
        fieldDataList,
        maxOrganicMatterProgress,
        currentOrganicMatterProgress,
        maxSoilAcidityProgress,
        currentSoilAcidityProgress,
        maxSoilAnalysisImportProgress,
        currentSoilAnalysisImportProgress,
    };
};
