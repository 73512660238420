import { COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS } from '../coverCrop.types';
import { useWatch, useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import { COVER_CROP_STATIC_DATA_TYPE } from '../../../api/coverCropFormApi.types';
import { useGetCoverCropFormStaticDataByTypeQuery } from '../../../api/coverCropFormApi';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';

export const useCoverCropDestructionWidthQuestion = () => {
    const destructionMachineId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_MACHINE_ID }) as number;

    const destructionMethodId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_METHOD_ID }) as number;

    const { currentFarm } = encodingShared.useCurrentFarm();

    const { setValue } = useFormContext();

    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });
    const destructionMachine = staticMachineryState.getById(destructionMachineId);

    const { data: destructionMethods } = useGetCoverCropFormStaticDataByTypeQuery({
        type: COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_DESTRUCTION,
    });

    const destructionMethod = destructionMethods?.find((item) => item.id === destructionMethodId);

    useEffect(() => {
        if (
            (destructionMachine && destructionMachine?.slug !== STATIC_MACHINERY_SLUGS.SPRAYER) ||
            (destructionMethod &&
                destructionMethod?.slug !== COVER_CROP_DESTRUCTION_STATIC_DATA_SLUGS.COVER_CROP_DESTRUCTION_CHEMICAL)
        ) {
            setValue(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_WIDTH, null);
        }
    }, [setValue, destructionMachine, destructionMethod]);

    return {
        isVisible: destructionMachine && destructionMachine.slug === STATIC_MACHINERY_SLUGS.SPRAYER,
    };
};
