import { Button, Card } from '@soil-capital/ui-kit/components';
import useDeferredPaymentInfoBoxStyle from './useDeferredPaymentInfoBox.style';
import { IconWarning } from '@soil-capital/ui-kit/icons';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useNavigate } from 'react-router-dom';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const DeferredPaymentInfoBox = () => {
    const { classes } = useDeferredPaymentInfoBoxStyle();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currentSeasonId } = useCurrentSeasonId();

    return (
        <Card className={classes.card}>
            <IconWarning color="warning" />
            <Typography variant="body2">
                <Trans i18nKey="homepage.actionBox.deferred-payment" />
            </Typography>
            <Button sx={{ whiteSpace: 'nowrap' }} onClick={() => navigate(`/${currentSeasonId}/payment/pricing`)}>
                <span className={classes.button}>{t('homepage.actionBox.deferred-payment.cta')}</span>
            </Button>
        </Card>
    );
};
