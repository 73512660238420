import { TFunction, Trans, useTranslation } from 'react-i18next';
import { Button, Modal } from '@soil-capital/ui-kit';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { useFieldCropWarningModalStyles } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/push/components/FieldCropWarningModal/FieldCropWarningModal.style';
import { WarningBox } from '@shared/components/WarningBox/WarningBox';

const formatSelectedOperationTypeNames = (names: string[], t: TFunction) => {
    if (names.length === 1) return names[0];
    if (names.length === 2) return names.join(` ${t('constants.and')} `);

    const last = names.slice(-1)[0];
    const rest = names.slice(0, -1);

    return `${rest.join(', ')} ${t('constants.and')} ${last}`;
};

type FieldCropWarningModalProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
    sourceFieldName: string;
    selectedFieldName: string;
    selectedOperationTypeNames: string[];
};

export const FieldCropWarningModal = ({
    open,
    onClose,
    onConfirm,
    sourceFieldName,
    selectedFieldName,
    selectedOperationTypeNames,
}: FieldCropWarningModalProps) => {
    const { t } = useTranslation();
    const { classes } = useFieldCropWarningModalStyles();
    const formattedSelectedOperationTypeNames = formatSelectedOperationTypeNames(selectedOperationTypeNames, t);

    return (
        <Modal
            size={'small'}
            open={open}
            onClose={onClose}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>
                        {t('encoding-technical-itinerary.duplication.modal.warning-modal.title')}
                    </Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body className={classes.body}>
                    <WarningBox displayWarningIcon>
                        <Trans
                            i18nKey="encoding-technical-itinerary.duplication.modal.warning-modal.summary"
                            values={{
                                selectedFieldName,
                                selectedOperationTypeNames: formattedSelectedOperationTypeNames,
                            }}
                        />
                    </WarningBox>
                    <Typography variant="body2">
                        <Trans
                            i18nKey="encoding-technical-itinerary.duplication.modal.warning-modal.description"
                            values={{ sourceFieldName }}
                        />
                    </Typography>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Button variant="text" onClick={onClose}>
                        {t('constants.cancel')}
                    </Button>
                    <Button onClick={onConfirm} variant="contained">
                        {t('constants.confirm-selection')}
                    </Button>
                </Modal.Footer>
            }
        />
    );
};
