import { useFormContext, useWatch } from 'react-hook-form';
import { StaticPesticideTypeT } from '@shared/entities/staticPesticideType/staticPesticideType.types';
import { SPRAYING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/sprayingFormschema';
import entities from '@shared/entities';

export type SprayingFormInputNameT = (typeof SPRAYING_FORM_INPUT_NAMES)[keyof typeof SPRAYING_FORM_INPUT_NAMES];

export const useSprayingFormPesticideTypeInput = ({
    name,
    onChange,
    onTypeDelete,
}: {
    name: SprayingFormInputNameT;
    onChange: () => void;
    onTypeDelete: () => void;
}) => {
    const { setValue } = useFormContext();
    const selectedTypes: StaticPesticideTypeT[] = useWatch({ name });
    const { staticPesticideTypeState } = entities.staticPesticideType.useState();

    const handleTypeChange = (values: number[]) => {
        let newState: StaticPesticideTypeT[] = [];

        const typesToDelete = selectedTypes?.filter((f) => !values?.find((v) => v === f.id));

        if (typesToDelete?.length > 0) newState = selectedTypes.filter((f) => !typesToDelete.includes(f));

        const newValue = values?.find((v) => !selectedTypes?.find((f) => f.id === v));
        const newPesticideType = staticPesticideTypeState.getById(newValue);
        if (newPesticideType) newState = [...selectedTypes, newPesticideType];

        setValue(name, newState);
        onChange();
    };

    const handleTypeRemoved = (product: StaticPesticideTypeT) => {
        setValue(
            name,
            selectedTypes.filter((p) => p.id !== product.id),
        );
        onTypeDelete();
    };

    return {
        selectedTypes,
        handleTypeChange,
        handleTypeRemoved,
        getPesticideTypeById: staticPesticideTypeState.getById,
    };
};
