import authApi from '@modules/auth/api/authApi';
import { useForm } from '@shared/hooks';
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { z } from 'zod';

export const FORGOTPASSWORD = {
    EMAIL: 'email',
} as const;

export const useForgotPasswordLogic = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [msgOpen, setMsgOpen] = useState(false);

    const defaultValues = {
        [FORGOTPASSWORD.EMAIL]: '',
    };
    const schema = z.object({
        [FORGOTPASSWORD.EMAIL]: z.string().email(t('validation.no-email-provided')),
    });
    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const {
        handleSubmit,
        formState: { errors },
    } = methods;

    const [sendResetPasswordLink, { isLoading: sendResetPasswordLinkLoading }] =
        authApi.useSendResetPasswordLinkMutation();

    const onSubmit = handleSubmit(async (data) => {
        const response = await sendResetPasswordLink(data);
        if ('data' in response) {
            setMsgOpen(true);
        } else {
            enqueueSnackbar(t('constants.server-error'), { variant: 'error' });
        }
    });

    useEffect(() => {
        if (msgOpen) {
            setTimeout(() => {
                navigate('../login');
            }, 8000);
        }
    }, [msgOpen, navigate]);

    return { sendResetPasswordLinkLoading, msgOpen, t, methods, onSubmit, errors };
};
