import { OnboardingSteps } from './components/OnboardingSteps';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { IconArrowLeft, IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Button } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useOnboardingStepsModalStyle } from './OnboardingStepsModal.style';
import { ReactNode } from 'react';
import { auth } from '@modules/auth';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import { PaymentPageLoader } from '@shared/components/PaymentPage/PaymentPageLoader';

export const OnboardingStepsModal = ({
    children,
    stepProgress,
    ctaAction,
    ctaText,
    isCTAdisabled,
    onBackClick,
    hideBackButton,
    isCTAloading,
    isCurrentStepDone,
}: {
    children: ReactNode;
    stepProgress: number;
    ctaAction?: () => void;
    ctaText?: string;
    isCTAdisabled?: boolean;
    onBackClick?: () => void;
    hideBackButton?: boolean;
    isCTAloading?: boolean;
    isCurrentStepDone?: boolean;
}) => {
    const { t } = useTranslation();
    const { classes } = useOnboardingStepsModalStyle();
    const { data: partnerData, isLoading } = useGetPartnerInfoQuery({ userId: auth.useAuthUser()?.id });

    return isLoading ? (
        <PaymentPageLoader />
    ) : (
        <Grid className={classes.container}>
            {stepProgress < 5 && (
                <>
                    <Grid display="flex" justifyContent="space-between" alignContent="center">
                        {!hideBackButton && (
                            <Button
                                variant="text"
                                startIcon={<IconArrowLeft />}
                                onClick={onBackClick}
                                sx={{ alignSelf: 'flex-start' }}
                            >
                                {t('constants.back')}
                            </Button>
                        )}
                        {ctaAction && ctaText && (
                            <Button
                                variant="contained"
                                onClick={ctaAction}
                                endIcon={<IconArrowRight />}
                                disabled={isCTAdisabled}
                                loading={isCTAloading}
                                sx={{ marginLeft: 'auto' }}
                            >
                                {t(`${ctaText}`)}
                            </Button>
                        )}
                    </Grid>
                    <OnboardingSteps
                        stepProgress={stepProgress}
                        isCurrentStepDone={isCurrentStepDone}
                        hasToPay={!partnerData?.is_partner_prepaying}
                    />
                </>
            )}
            {children}
        </Grid>
    );
};
