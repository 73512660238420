import { z } from 'zod';
import { DeepWorkFormDataT } from '../api/operationFormApi.types';
import { OperationFormSchema } from './OperationFormSchema';

export const deepWorkFormSchema = new OperationFormSchema(
    {
        machinery_id: z.number().nullable(),
        passage: z.number().nullable(),
        depth: z.number().nullable(),
        soil_work_width: z.number().nullable(),
        interrow: z.number().nullable(),
    },
    (apiData?: DeepWorkFormDataT) => ({
        machinery_id: apiData?.machinery_id ?? null,
        depth: apiData?.depth ?? null,
        soil_work_width: apiData?.soil_work_width ?? null,
        interrow: apiData?.interrow ?? null,
        passage: apiData?.passage ?? null,
    }),
    [],
);
export type DeepWorkFormSchemaT = z.infer<typeof deepWorkFormSchema.schema>;
export const DEEP_WORK_FORM_INPUT_NAMES = deepWorkFormSchema.INPUT_NAMES;
