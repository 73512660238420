import { makeStyles } from '@soil-capital/ui-kit/style';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

export const useTOSModalStyle = makeStyles()((theme) => ({
    paper: {
        overflow: 'hidden',
        position: 'relative',
    },
    container: {
        padding: '30px',
        width: '800px',
        minHeight: '500px',
        height: '80vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: '5px',
            backgroundColor: '#e2d0b6',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#b17c43',
            borderRadius: '15px',
        },
    },
    documentEN: {
        height: '2000px',
        overflow: 'hidden',
    },
    documentFR: {
        height: '2250px',
        overflow: 'hidden',
    },
    pdfMargin: {
        marginTop: '-85px',
    },
    pdfMarginLastFR: {
        marginTop: '-40px',
    },
    close: {
        position: 'absolute',
        top: 20,
        right: 25,
        fontSize: '18px',
        cursor: 'pointer',
        color: theme.palette.secondary.main,
        '&:hover': {
            color: theme.palette.secondary.light,
        },
    },
}));
