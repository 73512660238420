import { StaticCropGroupT } from '@shared/entities';

export const cropGroupSlugImgMapping: { [k in StaticCropGroupT['slug']]: string } = {
    cereals: '/assets/images/crop-group-cereals.webp',
    aromatics: '/assets/images/crop-group-aromatics.webp',
    oilseed: '/assets/images/crop-group-oilseed.webp',
    pastures: '/assets/images/crop-group-pastures.webp',
    peas: '/assets/images/crop-group-peas.webp',
    vegetables: '/assets/images/crop-group-vegetables.webp',
    'other-crops': '/assets/images/crop-group-other-crops.webp',
    'root-crops': '/assets/images/crop-group-root-crops.webp',
};

export default (cropGroupSlug: StaticCropGroupT['slug'] | null | undefined) => {
    const placeholderImg = '/assets/images/crop-group-placeholder.jpg';

    if (!cropGroupSlug || !cropGroupSlugImgMapping[cropGroupSlug]) return placeholderImg;

    return cropGroupSlugImgMapping[cropGroupSlug];
};
