import { useTheme } from '@soil-capital/ui-kit/material-core';
import { IconCheck, IconInfo, IconWarning } from '@soil-capital/ui-kit/icons';

/**
 * Checks the snackbar variant used and return a boolean value and background color according to it.
 *
 * @param variant The snackbar variant used
 * @returns Which snackbar to display and the icon background color for this snackbar
 */
export default (variant: 'success' | 'error' | 'info') => {
    const theme = useTheme();
    const iconColor = theme.palette.common.white;

    switch (variant) {
        case 'info':
            return {
                iconBackgroundColor: theme.palette.primary.light,
                snackbarIcon: <IconInfo fontSize="medium" style={{ color: iconColor }} />,
            };
        case 'success':
            return {
                iconBackgroundColor: theme.palette.success.main,
                snackbarIcon: <IconCheck fontSize="small" style={{ color: iconColor }} />,
            };
        case 'error':
            return {
                iconBackgroundColor: theme.palette.error.main,
                snackbarIcon: <IconWarning fontSize="small" style={{ color: iconColor }} />,
            };
    }
};
