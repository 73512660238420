import { useFormContext, useWatch } from 'react-hook-form';
import {
    MineralFertilisationInputNameT,
    MineralFertilisationProductSchemaT,
} from '@modules/encoding/modules/technicalItinerary/schema/mineralFertilisationFormSchema';

export const useMineralFertiliserProductsInput = ({
    name,
    onChange,
}: {
    name: MineralFertilisationInputNameT;
    onChange: () => void;
}) => {
    const { setValue } = useFormContext();
    const selectedProducts: MineralFertilisationProductSchemaT[] = useWatch({ name });

    /** compare the new fertiliiser ids with selected ones ones and add or remove a fertiliser schema data */
    const handleFertilisersChange = (fertiliserIds: number[]) => {
        const selectedFertiliserIds = selectedProducts.map((p) => p.static_fertiliser_id);
        let newState: MineralFertilisationProductSchemaT[] = selectedProducts;

        // 1 fertiliser has been added
        if (fertiliserIds.length > selectedProducts.length) {
            const newFertiliserId = fertiliserIds.find((fertiliserId) => !selectedFertiliserIds.includes(fertiliserId));
            newState = newFertiliserId
                ? [...selectedProducts, { static_fertiliser_id: newFertiliserId, inhibitor: null, quantity: null }]
                : newState;
        }

        // 1 fertiliser has been removed
        if (fertiliserIds.length < selectedProducts.length) {
            newState = selectedProducts.filter((p) => fertiliserIds.includes(p.static_fertiliser_id));
        }

        setValue(name, newState);
        onChange();
    };

    const handleProductRemoved = (productId: number) => {
        setValue(
            name,
            selectedProducts.filter((p) => p.static_fertiliser_id !== productId),
        );
        onChange();
    };

    return { selectedProducts, handleFertilisersChange, handleProductRemoved };
};
