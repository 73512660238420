import { useState } from 'react';
import { useImportPacFileMutation } from './api/pacFileImportApi';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import JSZip from 'jszip';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';

export const acceptedFiles = {
    'application/octet-stream': [
        '.zip',
        '.kmz',
        '.kml',
        '.shp',
        '.dbf',
        '.cst',
        '.prj',
        '.shx',
        '.cpg',
        '.sbn',
        '.sbx',
        '.xml',
        '.geojson',
    ],
};

export const usePacFileImportModalLogic = ({
    currentSeasonId,
    handleClose,
}: {
    currentSeasonId: number;
    handleClose: () => void;
}) => {
    const { fieldState } = useGetFarmSeasonFields();
    const { cropState } = useGetFarmSeasonCrops();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { t } = useTranslation();
    const [files, setFiles] = useState<File[]>([]);
    const [processingErrors, setProcessingErrors] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    // Files that are mandatory for a shapefile
    const mandatoryFilesTypes = ['shp', 'shx', 'dbf'];
    // An exception file is a file that can be present alone without the mandatory files
    const exceptionFiles = ['kmz', 'kml', 'geojson'];

    const { enqueueSnackbar } = useSnackbar();
    const importPacFile = useImportPacFileMutation();

    const handleImportPacFile = async () => {
        setIsLoading(true);
        try {
            await importPacFile({ farmSeasonId: currentSeasonId, body: { shape_files: files } }).then((result) => {
                if (!result.invalid_fields)
                    return enqueueSnackbar(t('encoding-rotation.capfile-import-modal.import-error'), {
                        variant: 'error',
                    });

                setFiles([]);
                fieldState.refetch();
                cropState.refetch();
                fieldCropState.refetch();
                handleClose();
                if (result.invalid_fields.length > 0)
                    return enqueueSnackbar(t('encoding-rotation.capfile-import-modal.warning'), { variant: 'warning' });

                return enqueueSnackbar(t('encoding-rotation.capfile-import-modal.success'), { variant: 'success' });
            });
        } catch {
            return enqueueSnackbar(t('encoding-rotation.capfile-import-modal.import-error'), { variant: 'error' });
        }
        setIsLoading(false);
    };

    const onFilesChange = (newFiles: File[]) => {
        setFiles(newFiles);
        checkMissingFileTypes(newFiles);
    };

    const checkMissingFileTypes = (files: File[]) => {
        const filesTypes = files.map((file) => {
            const fileNameSplit = file.name.split('.');
            return fileNameSplit[fileNameSplit.length - 1].toLowerCase();
        });

        if (
            files.length === 0 ||
            // If there's only one file and it's an exception file type, no other file is mandatory
            (files.length === 1 && filesTypes.some((fileType) => exceptionFiles.includes(fileType)))
        ) {
            return setProcessingErrors([]);
        }

        // Check if there's a zip file and if so check its content
        const zipFile = filesTypes.includes('zip') ? files[filesTypes.indexOf('zip')] : null;
        if (!!zipFile) {
            return inspectZipFileContent(zipFile);
        }

        // Check if there's at least one .shp, one .shx, and one .dbf file
        const missingFileTypes = mandatoryFilesTypes.filter((mandatoryFile) => !filesTypes.includes(mandatoryFile));
        setProcessingErrors(
            missingFileTypes.map((fileType) =>
                t('encoding-rotation.capfile-import-modal.missing-file-error', { fileType }),
            ),
        );
    };

    const inspectZipFileContent = (file: File) => {
        const zip = new JSZip();
        zip.loadAsync(file).then((zipContent) => {
            const fileNames = Object.keys(zipContent.files);
            const filesTypes = fileNames.map((fileName) => {
                const fileNameSplit = fileName.split('.');
                return fileNameSplit[fileNameSplit.length - 1].toLowerCase();
            });
            const missingFileTypes = mandatoryFilesTypes.filter((mandatoryFile) => !filesTypes.includes(mandatoryFile));
            setProcessingErrors(
                missingFileTypes.map((fileType) =>
                    t('encoding-rotation.capfile-import-modal.invalid-zip-file-error', { fileType }),
                ),
            );
        });
    };

    return {
        isLoading,
        acceptedFiles,
        processingErrors,
        files,
        setFiles,
        handleImportPacFile,
        onFilesChange,
    };
};
