import { Accept } from 'react-dropzone';
import { useDropZoneStyles } from './DropZone.style';
import { Paper, Typography } from '@soil-capital/ui-kit/material-core';
import { IconClose, IconUploadCloud } from '@soil-capital/ui-kit/icons';
import { Button, HelpBox, IconButton } from '@soil-capital/ui-kit/components';
import { useDropZoneLogic } from './useDropZone.logic';

export type DropZonePropsT = {
    files: File[];
    onFilesChange: (newFiles: File[]) => void;
    processingErrors: string[];
    textDropFiles: string;
    textBrowseFiles?: string;
    /**
     * All files are accepted if not provided
     * exemple: `{ 'application/octet-stream': ['.zip', '.shp', '.dbf', '.cst', '.prj', '.shx'] }`
     */
    acceptedFilesTypes?: Accept;
};

export const DropZone = ({
    processingErrors,
    files,
    onFilesChange,
    textDropFiles,
    textBrowseFiles,
    acceptedFilesTypes,
}: DropZonePropsT) => {
    const { classes } = useDropZoneStyles();
    const { uploadErrors, dropzoneRootProps, isDragActive, getInputProps, onBrowseClick, handleDeleteFile } =
        useDropZoneLogic({
            files,
            acceptedFilesTypes,
            onFilesChange,
        });

    return (
        <div className={classes.dropZone}>
            <HelpBox className={`${classes.uploadHelpBox} ${isDragActive ? classes.hover : ''}`}>
                <div className={classes.uploadSection} {...dropzoneRootProps}>
                    <input {...getInputProps()} />
                    <IconUploadCloud fontSize="large" className={classes.uploadIcon} data-testid="icon-upload-button" />
                    <Typography variant="caption">{textDropFiles}</Typography>
                    <Button onClick={onBrowseClick} variant="contained" color="secondary" size="small">
                        {textBrowseFiles}
                    </Button>
                </div>
            </HelpBox>

            {files.length > 0 && (
                <div className={classes.fileList}>
                    {files.map((file, index: number) => {
                        const key = `${file.name}-${index}`;
                        return (
                            <div key={key}>
                                <Paper className={classes.container} elevation={0}>
                                    <Typography variant="subcaption">{file.name}</Typography>
                                    <IconButton size="small" onClick={() => handleDeleteFile(file)}>
                                        <IconClose className={classes.icon} />
                                    </IconButton>
                                </Paper>
                            </div>
                        );
                    })}
                </div>
            )}

            {uploadErrors &&
                [...uploadErrors, ...processingErrors].map((error) => (
                    <div key={error} className={classes.fileImportError}>
                        <Typography variant="caption">{error}</Typography>
                    </div>
                ))}
        </div>
    );
};
