import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { SeedingForm } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingForm/SeedingForm';
import { useGetSeedingOperationTitle } from '@modules/encoding/modules/technicalItinerary/hooks/useGetSeedingOperationTitle';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';

export const SeedingActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { operationId } = useOperationRouteParams();
    const { isLoading, getSeedingOperationTitle } = useGetSeedingOperationTitle();

    return (
        <encodingShared.OperationFormActionPage
            isTitleLoading={isLoading}
            title={getSeedingOperationTitle(operationId)}
            navigate={navigate}
        >
            <SeedingForm />
        </encodingShared.OperationFormActionPage>
    );
};
