import { useFarmProfileFormStyles } from './FarmProfileForm.style';
import { FarmProfileSelect } from '@modules/encoding/modules/farmProfile/components/FarmProfileForm/FarmProfileSelect/FarmProfileSelect';
import { useTranslation } from 'react-i18next';
import { FormProvider } from 'react-hook-form';
import { FarmProfileMultiSelect } from '@modules/encoding/modules/farmProfile/components/FarmProfileForm/FarmProfileMultiSelect/FarmProfileMultiSelect';
import {
    FARM_PROFILE_INPUT_NAME,
    useFarmProfileForm,
} from '@modules/encoding/modules/farmProfile/components/FarmProfileForm/useFarmProfileForm';
import { BooleanRadioGroup, FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

export const FarmProfileForm = () => {
    const { classes } = useFarmProfileFormStyles();
    const { t } = useTranslation();
    const { methods, options, onSubmit } = useFarmProfileForm();

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit} className={classes.form}>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.goals')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.goals"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.goals"
                    />
                    <FarmProfileMultiSelect
                        name={FARM_PROFILE_INPUT_NAME.OBJECTIVES}
                        onChange={() => onSubmit()}
                        options={options.objectives}
                        className={classes.select}
                    />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.struggle')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.difficulties"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.difficulties"
                    />
                    <FarmProfileMultiSelect
                        name={FARM_PROFILE_INPUT_NAME.DIFFICULTIES}
                        onChange={() => onSubmit()}
                        options={options.difficulties}
                        className={classes.select}
                    />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.farm-type')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.farm-type"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.farm-type"
                    />
                    <FarmProfileSelect
                        name={FARM_PROFILE_INPUT_NAME.FARM_TYPE}
                        onChange={() => onSubmit()}
                        options={options.farmType}
                        className={classes.select}
                    />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.cover-crop')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.cover-crop"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.cover-crop"
                    />
                    <BooleanRadioGroup
                        name={FARM_PROFILE_INPUT_NAME.HAS_INTER_CROP_COVER}
                        onChange={() => onSubmit()}
                    />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.organic-fertilisers')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.fertilisers"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.fertilisers"
                    />
                    <BooleanRadioGroup
                        name={FARM_PROFILE_INPUT_NAME.USE_ORGANIC_FERTILIZER}
                        onChange={() => onSubmit()}
                    />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.tillage-practice')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.tillage-practice"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.tillage-practice"
                    />
                    <FarmProfileSelect
                        name={FARM_PROFILE_INPUT_NAME.TILLAGE_PRACTICE}
                        onChange={onSubmit}
                        options={options.tillagePractice}
                        className={classes.select}
                    />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.agroforestry')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.agroforestry"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.agroforestry"
                    />
                    <BooleanRadioGroup name={FARM_PROFILE_INPUT_NAME.HAS_AGROFORESTRY} onChange={() => onSubmit()} />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.drained-fields')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.drained-fields"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.drained-fields"
                    />
                    <BooleanRadioGroup name={FARM_PROFILE_INPUT_NAME.HAS_DRAINED_FIELDS} onChange={() => onSubmit()} />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.irrigated-fields')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.irrigated-fields"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.irrigated-fields"
                    />
                    <BooleanRadioGroup
                        name={FARM_PROFILE_INPUT_NAME.HAS_IRRIGATED_FIELDS}
                        onChange={() => onSubmit()}
                    />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.livestock')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.livestock"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.livestock"
                    />
                    <BooleanRadioGroup name={FARM_PROFILE_INPUT_NAME.HAS_LIVESTOCK} onChange={() => onSubmit()} />
                </FormRow>
                <FormRow>
                    <FormQuestion
                        question={t('encoding-farm-profile.question.expectations')}
                        tooltipTitleKey="encoding-farm-profile.question.tooltips.title.expectations"
                        tooltipTextKey="encoding-farm-profile.question.tooltips.text.expectations"
                    />
                    <FarmProfileMultiSelect
                        name={FARM_PROFILE_INPUT_NAME.EXPECTATIONS}
                        onChange={() => onSubmit()}
                        options={options.expectations}
                        className={classes.select}
                    />
                </FormRow>
            </form>
        </FormProvider>
    );
};
