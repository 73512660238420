import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { OrganicFertiliserForm } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/components/OrganicFertiliserForm/OrganicFertiliserForm';
import { HelpBox } from '@soil-capital/ui-kit/components';
import { useOrganicFertiliserActionPageStyles } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/pages/OrganicFertiliserActionPage/OrganicFertiliserActionPage.style';

export const OrganicFertiliserActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { classes } = useOrganicFertiliserActionPageStyles();

    return (
        <encodingShared.OperationFormActionPage
            title={t('db.operation_type.fertilisation.organic-fertilisation')}
            navigate={navigate}
        >
            <main className={classes.container}>
                <OrganicFertiliserForm />
                <HelpBox>{t('encoding-technical-itinerary.organic-fertiliser.help-box')}</HelpBox>
            </main>
        </encodingShared.OperationFormActionPage>
    );
};
