import { makeStyles } from '@soil-capital/ui-kit/style';

export const useWarningBoxStyles = makeStyles()((theme) => ({
    summary: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        backgroundColor: theme.palette.darkScale[100],
        borderRadius: theme.shape.borderRadiusS,
        padding: theme.spacing(1),
    },
    withBorder: {
        border: `2px solid ${theme.palette.warning.main}`,
        background: 'white',
    },
}));
