import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { HarvestForm } from '../../components/HarvestForm/HarvestForm';

export const HarvestActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    return (
        <encodingShared.OperationFormActionPage title={t('db.operation_type.harvest.harvest')} navigate={navigate}>
            <HarvestForm />
        </encodingShared.OperationFormActionPage>
    );
};
