import { auth } from '@modules/auth';
import { encodingShared } from '@modules/encoding/shared';

export const useFarmSeasonReadOnly = () => {
    const { currentSeason } = encodingShared.useCurrentSeason();

    // Check if the user has the permission to edit the farm season
    // If season is not validated yet, use CAN_EDIT scopes
    // If season is validated, use CAN_FINALIZE scopes

    const readOnly = !(
        (!currentSeason?.summary_validated_at && auth.usePermission('canEditFarmSeason')) ||
        (!!currentSeason?.summary_validated_at && auth.usePermission('canFinalizeFarmSeason'))
    );

    return {
        readOnly,
    };
};
