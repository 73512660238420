import { auth } from '@modules/auth';
import { routesConfig } from '@modules/auth/auth.routes';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export default ({ children }: { children: React.JSX.Element }): React.JSX.Element | null => {
    const location = useLocation();
    const navigate = useNavigate();

    const { seasonId } = useParams();
    const hasSeasonId = Number(seasonId);
    const latestActiveSeasonId = auth.useLatestActiveSeasonId();
    const appUrl = auth.useAppUrl();
    // if no seasonId, Redirect to the same route but with the /:seasonId in addition
    useEffect(() => {
        if (!hasSeasonId && latestActiveSeasonId) {
            const pathnameIsUnauthenticatedRoute = Object.values(routesConfig).find(
                (route) => route === location.pathname,
            );
            navigate(
                {
                    ...location,
                    pathname: `/${latestActiveSeasonId}${
                        location.pathname === '/' || pathnameIsUnauthenticatedRoute ? '' : location.pathname
                    }`,
                },
                { replace: true },
            );
        } else if (!latestActiveSeasonId && !hasSeasonId) {
            if (appUrl && appUrl !== window.location.origin) {
                window.location.replace(appUrl);
            }
            navigate('/onboarding');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return hasSeasonId ? children : null;
};
