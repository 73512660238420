import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { formatNumber } from '@shared/utils';
import { useTranslation } from 'react-i18next';

type FieldDataT = {
    id: number;
    name: string;
    image: string;
    infoText: string;
    maxProgress: number;
    currentProgress: number;
};

export const useIntroductionContentData = () => {
    const { t } = useTranslation();

    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { currentSeason, currentSeasonLoading } = encodingShared.useCurrentSeason();

    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });
    const { harvestedFields, harvestedFieldsLoading } = fieldState.useHarvestedFields({
        countryId: currentFarm?.country_id,
    });
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticSeasonState } = entities.staticSeason.useState();

    const { getFieldThumbnail } = encodingShared.useGetFieldThumbnail({ seasonId: currentSeasonId });

    const isDataLoading =
        currentFarmLoading ||
        currentSeasonLoading ||
        fieldState.isLoading ||
        cropState.isLoading ||
        fieldCropState.isLoading ||
        staticCropState.isLoading ||
        staticSeasonState.isLoading ||
        harvestedFieldsLoading;

    const getFieldInfoText = ({ fieldId, fieldArea }: { fieldId: number; fieldArea: number }) => {
        const firstFieldCrop = fieldCropState.getByFieldId({ fieldId }).at(0);
        const crop = cropState.getById(firstFieldCrop?.farm_season_crop_id);
        const staticCrop = staticCropState.getById(crop?.crop_id);

        const infoText = `${t(staticCrop?.translation_slug ?? '')} ${formatNumber(fieldArea, 2)} HA`;

        return infoText;
    };

    const agroforestryFields = harvestedFields.filter((field) => field.has_agroforestry);
    const fieldDataList = agroforestryFields.map<FieldDataT>((field) => {
        const infoText = getFieldInfoText({ fieldArea: field.area, fieldId: field.id });
        const fieldThumbnail = getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at });

        const currentProgress = 0; // TODO should come from progress details

        return { currentProgress, id: field.id, image: fieldThumbnail, infoText, maxProgress: 1, name: field.name };
    });

    const harvestYear = staticSeasonState.getById(currentSeason?.season_id)?.harvest_year;

    const maxAgroforestryDefinitionProgress = harvestedFields.length || 1;
    let currentAgroforestryDefinitionProgress = harvestedFields.reduce((progress, field) => {
        // false | true si considered as assigned.
        return progress + (field.has_agroforestry !== null ? 1 : 0);
    }, 0);
    if (harvestedFields.length === 0) {
        currentAgroforestryDefinitionProgress = 1;
    }

    return {
        isDataLoading,
        fieldDataList,
        harvestYear,
        maxAgroforestryPracticeProgress: 1,
        currentAgroforestryPracticeProgress: 0,
        maxAgroforestryDefinitionProgress,
        currentAgroforestryDefinitionProgress,
    };
};
