/* eslint-disable @typescript-eslint/no-explicit-any */
import * as z from 'zod';

export class OperationFormSchema<SchemaT extends z.ZodRawShape, DataT> {
    constructor(
        private zodSchemaObject: SchemaT,
        public mapApiDataToSchema: (data?: DataT) => z.infer<z.ZodObject<SchemaT>>,
        private skipHasDataCheckProperties?: Extract<keyof SchemaT, string>[],
    ) {}

    /**
     * Compute an "enum" with the input names. This is useful to provide the name key for the form.
     */
    private computeInputNames = <KeysT extends string = Extract<keyof SchemaT, string>>(
        schemaShape: SchemaT,
    ): {
        [key in Uppercase<KeysT>]: Lowercase<key>;
    } => {
        return Object.keys(schemaShape).reduce((acc, key) => {
            acc[key.toUpperCase()] = key;
            return acc;
        }, {} as any);
    };

    /**
     * the zod schema object, useful to validate the form.
     */
    readonly schema = z.object(this.zodSchemaObject);

    /**
     * "enum" of input names. This is useful to provide the name key for the form.
     */
    INPUT_NAMES = this.computeInputNames(this.schema.shape);

    /**
     * Check if any data exist in the schema. useful to know if the user has already saved some data.\
     * Some properties can be skipped in case they are not relevant for the check.
     */
    hasExistingData = (data: DataT): boolean => {
        const schemaData = this.mapApiDataToSchema(data);

        return Object.entries(schemaData ?? {}).some(([key, value]) =>
            Array.isArray(value)
                ? value.length > 0
                : value !== null && !(this.skipHasDataCheckProperties as string[])?.includes(key),
        );
    };
}
