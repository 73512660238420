import { Skeleton, Box } from '@soil-capital/ui-kit/material-core';

export const SprayingFormSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" gap={2} data-testid="spraying-form-skeleton">
            <Skeleton variant="rounded" width="100%" height={100} />
            <Skeleton variant="rounded" width="100%" height={100} />
        </Box>
    );
};
