import { useFarmSeasonCropDeleteModalLogic } from '@modules/encoding/modules/rotation/components/FarmSeasonCropDeleteModal/useFarmSeasonCropDeleteModalLogic';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';
import { ModalPropsT } from '@shared/hooks/useModalController';
import { useTranslation } from 'react-i18next';

export const FarmSeasonCropDeleteModal = ({
    modalController,
}: {
    modalController: ModalPropsT<{ farmSeasonCropId: number }, boolean>;
}) => {
    const { t } = useTranslation();
    const { crop } = useFarmSeasonCropDeleteModalLogic({ farmSeasonCropId: modalController.data?.farmSeasonCropId });

    return (
        <ConfirmModal
            type="warning"
            isOpen={modalController.isOpen}
            onClose={({ confirmed }) => modalController.onClose?.(confirmed)}
            title={t('encoding-rotation.modal.crop-delete-modal.title')}
            primaryText={{
                translationKey: 'encoding-rotation.modal.crop-delete-modal.warning',
                translationValues: {
                    cropName: crop?.cropName,
                    count: crop?.numberOfAssignedFields,
                },
            }}
            secondaryText={{ translationKey: 'encoding-rotation.modal.crop-delete-modal.description' }}
            confirmText={t('constants.confirm-deletion')}
            cancelText={t('constants.cancel')}
        />
    );
};
