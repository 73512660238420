import { BaseEntityT } from '../baseEntity.types';

export const STATIC_MACHINERY_SLUGS = {
    COMBINED_DRILL_DECOMPACTION: 'combined-drill-(decompaction)',
    COMBINED_DRILL_ROT_HARROW: 'combined-drill-(rot-harrow)',
    SIMPLIFIED_DRILL_DISCS: 'simplified-drill-(discs)',
    SIMPLIFIED_DRILL_TINES: 'simplified-drill-(tines)',
    DIRECT_DRILL_TINES: 'direct-drill-(tines)',
    DIRECT_DRILL_DISCS: 'direct-drill-(discs)',
    PLANTER: 'planter',
    PRECISION_DRILL: 'precision-drill',
    PLOUGH: 'plough',
    SHALLOW_TILLAGE_LT10CM: 'shallow-tillage-(<10cm)',
    INTERMEDIATE_TILLAGE_1020CM: 'intermediate-tillage-(10-20cm)',
    DEEP_TILLAGE_GT20CM: 'deep-tillage-(>20cm)',
    STRIP_TILL: 'strip-till',
    POWER_HARROW: 'power-harrow',
    ROTARY_HOE: 'rotary-hoe',
    ROLLER: 'roller',
    SHREDDER: 'shredder',
    SPRAYER: 'sprayer',
    FERTILISER_SPREADER: 'fertiliser-spreader',
    MANURE_SPREADER: 'manure-spreader',
    SLURRY_TANK: 'slurry-tank',
    POTATO_RIDGER: 'potato-ridger',
    SPIKED_HARROW: 'spiked-harrow',
    HOE: 'hoe',
    ROTARY_HOE_WEEDING: 'rotary-hoe-weeding',
    CEREAL_HARVEST: 'cereal-harvest',
    CEREAL_HARVEST_INCL_BALING: 'cereal-harvest-(incl.-baling)',
    FLAX_HARVEST: 'flax-harvest',
    HAY_HARVEST: 'hay-harvest',
    EXTENSIVE_VEGETABLE_HARVEST: 'extensive-vegetable-harvest',
    INTENSIVE_VEGETABLE_HARVEST: 'intensive-vegetable-harvest',
    SUGARBEET_CHICORY_HARVEST: 'sugarbeet-/-chicory-harvest',
    POTATO_HARVEST: 'potato-harvest',
    SILLAGE_HARVEST: 'sillage-harvest',
    MANUAL_LABOUR: 'manual-labour',
    SOWING_WITH_A_STUBBLE_CULTIVATOR: 'sowing-with-a-stubble-cultivator',
    BROADCAST_SOWING: 'broadcast-sowing',
    STONE_CRUSHER: 'stone-crusher',
    THERMAL_WEEDING_100L_HA: 'thermal-weeding-100l-ha',
    DECIMATOR: 'decimator',
    COMBINED_POTATO_RIDGER_AND_PLANTER: 'combined-potato-ridger-and-planter',
    ROTARY_HARROW: 'rotary-harrow',
    MANUAL_WORK: 'manual-work',
    BROADCAST_QUAD_TRACTOR: 'broadcast-(quad/tractor)',
    DRONE: 'drone',
    HARROW: 'harrow',
    WEED_BURNER: 'weed-burner',
    WEEDING_ROBOT: 'weeding-robot',
} as const;

export type StaticMachinerySlugT = (typeof STATIC_MACHINERY_SLUGS)[keyof typeof STATIC_MACHINERY_SLUGS];

export type StaticMachineryT = {
    name: string;
    slug: StaticMachinerySlugT;
    fuel_consumption: string | null;
    unit: string | null;
    ranking: number | null;
    cft_machinery_id: number | null;
    depth: number | null;
    operation_type_ids: number[];
} & BaseEntityT;
