import { ModalPropsT } from '@shared/hooks/useModalController';
import { Trans, useTranslation } from 'react-i18next';
import { useFarmSeasonCropEditModalLogic } from './useFarmSeasonCropEditModalLogic';
import { Autocomplete, Modal } from '@soil-capital/ui-kit/components';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { WarningBox } from '@shared/components/WarningBox/WarningBox';

export const FarmSeasonCropEditModal = ({
    modalController,
}: {
    modalController: ModalPropsT<{ farmSeasonCropId: number }, boolean>;
}) => {
    const { t } = useTranslation();
    const {
        crop,
        confirmEditCrop,
        getStaticCropName,
        confirmLoading,
        staticCropIds,
        confirmDisabled,
        onStaticCropChange,
        selectedStaticCropId,
        onClose,
    } = useFarmSeasonCropEditModalLogic({
        farmSeasonCropId: modalController.data?.farmSeasonCropId,
        modalController,
    });

    return (
        <Modal
            size={'small'}
            open={modalController.isOpen}
            onClose={onClose}
            onConfirm={confirmEditCrop}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{t('encoding-rotation.crop-edit-modal.title')}</Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <WarningBox displayWarningIcon={false}>
                        <Trans
                            t={t}
                            i18nKey={'encoding-rotation.crop-edit-modal.warning'}
                            values={{
                                cropName: crop?.cropName ?? '',
                                count: crop?.numberOfAssignedFields ?? 0,
                            }}
                        />
                    </WarningBox>
                    <Typography variant="body2" sx={{ mt: 2 }}>
                        <Trans
                            t={t}
                            i18nKey={'encoding-rotation.crop-edit-modal.description'}
                            values={{ cropName: crop?.cropName ?? '' }}
                        />
                    </Typography>
                    <Autocomplete
                        sx={{ width: '256px', mt: 2 }}
                        disabled={confirmLoading}
                        value={selectedStaticCropId}
                        noOptionsText={t('constants.no-results')}
                        onChange={(event, cropId) => onStaticCropChange(cropId)}
                        blurOnSelect
                        clearOnBlur
                        options={staticCropIds}
                        getOptionLabel={(staticCropId) => getStaticCropName(staticCropId)}
                        placeholder={t('encoding-rotation.crop-edit-modal.select-crop-placeholder')}
                        isOptionEqualToValue={(option, value) => {
                            return option === value;
                        }}
                        multiple={false}
                    />
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel>{t('constants.cancel')}</Modal.Cancel>
                    <Modal.Confirm loading={confirmLoading} disabled={confirmDisabled}>
                        {t('encoding-rotation.crop-edit-modal.confirm-edit-crop')}
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
