import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';
import { ROTATION_ASSIGNATION_INPUT_NAMES } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/hooks/useRotationAssignationForm';
import { useSnackbar } from 'notistack';
import entities from '@shared/entities';
import { useModalController } from '@shared/hooks/useModalController';
import { encodingShared } from '@modules/encoding/shared';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';

export const useFieldCropAssignationModalLeftPanelLogic = () => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { enqueueSnackbar } = useSnackbar();

    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { harvestYear } = useGetCurrentHarvestYear();
    const selectedCropId = useWatch({ name: ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID });
    const selectedCrop = cropState.getById(selectedCropId);
    const { isCropAssignedToAtLeastOneField } = cropState.useGetCropsAssignedToAField();

    const confirmDeleteCropModal = useModalController<{ farmSeasonCropId: number }>();
    const changeStaticCropModal = useModalController<{ farmSeasonCropId: number }>();
    const setSelectedCrop = (cropId: number | null) => {
        context?.setValue(ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID, cropId);
    };
    const [deleteCropEntity] = entities.crop.useDelete();
    const deleteCrop = async (cropId: number) => {
        try {
            if (cropId === selectedCropId) {
                setSelectedCrop(null);
            }
            await deleteCropEntity({ id: cropId, farmSeasonId: currentSeasonId }).unwrap();
            enqueueSnackbar(t('encoding-rotation.modal.left-panel.selected-crop-deleted'), { variant: 'success' });
        } catch (e) {
            if (cropId === selectedCropId) {
                setSelectedCrop(cropId);
            }
        }
    };

    const onDeleteIconClicked = async (cropId: number) => {
        const hasFieldsAssigned = isCropAssignedToAtLeastOneField(cropId);

        if (!hasFieldsAssigned) {
            deleteCrop(cropId);
        } else if (await confirmDeleteCropModal.open({ farmSeasonCropId: cropId })) {
            deleteCrop(cropId);
        }
    };

    return {
        harvestYear,
        selectedCrop,
        numberOfFarmSeasonCrops: cropState.list.length,
        onDeleteIconClicked,
        confirmDeleteCropModal,
        changeStaticCropModal,
    };
};
