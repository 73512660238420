import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { useCoverCropSeedingMachineQuestion } from './useCoverCropSeedingMachineQuestion';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';
import { FormAutoCompleteOptionT, FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';

export const CoverCropSeedingMachineQuestion = ({
    options,
    onChange,
}: {
    options: FormAutoCompleteOptionT[];
    onChange: () => void;
}) => {
    const { t } = useTranslation();
    const { isVisible } = useCoverCropSeedingMachineQuestion();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.seeding-machine')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.seeding-machine"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.seeding-machine"
                    />
                    <FormAutocomplete
                        name={COVER_CROP_FORM_INPUT_NAMES.SEEDING_MACHINE_ID}
                        placeholder={t(
                            'encoding-technical-itinerary.global-characteristics.placeholder.tillage-practice',
                        )}
                        onChange={onChange}
                        options={options}
                        multiple={false}
                    />
                </FormRow>
            )}
        </>
    );
};
