import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import fieldApi from '@shared/entities/field/field.api';

export const useDeleteFarmSeasonField = ({ onFieldDeleted }: { onFieldDeleted?: (fieldId: number) => void }) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [deleteMutation, { isLoading }] = fieldApi.useDelete();

    const deleteField = async (fieldId: number) => {
        try {
            await deleteMutation({ farmSeasonId: currentSeasonId, id: fieldId });
            onFieldDeleted?.(fieldId);
        } catch (e) {
            console.error(e);
        }
    };

    return {
        deleteField,
        isLoading,
    };
};
