export const FARM_PROFILE_STATIC_DATA_TYPE = {
    FARM_OBJECTIVES: 'FARM_OBJECTIVES',
    FARM_DIFFICULTIES: 'FARM_DIFFICULTIES',
    FARM_TYPE: 'FARM_TYPE',
    FARM_TILLAGE_PRACTICE: 'FARM_TILLAGE_PRACTICE',
    FARM_EXPECTATIONS: 'FARM_EXPECTATIONS',
} as const;

export type FarmProfileStaticDataTypeT = keyof typeof FARM_PROFILE_STATIC_DATA_TYPE;

export const FARM_PROFILE_STATIC_DATA_OBJECTIVES_SLUGS = {
    FARM_OBJECTIVES_PROFITABILITY: 'farm-objectives-profitability',
    FARM_OBJECTIVES_SOIL_HEALTH: 'farm-objectives-soil-health',
    FARM_OBJECTIVES_FEWER_WORKING_HOURS: 'farm-objectives-fewer-working-hours',
    FARM_OBJECTIVES_BIODIVERSITY: 'farm-objectives-biodiversity',
    FARM_OBJECTIVES_RESILIENCE: 'farm-objectives-resilience',
    FARM_OBJECTIVES_OTHER: 'farm-objectives-other',
} as const;

export type FarmProfileObjectivesSlugsT =
    (typeof FARM_PROFILE_STATIC_DATA_OBJECTIVES_SLUGS)[keyof typeof FARM_PROFILE_STATIC_DATA_OBJECTIVES_SLUGS];

export const FARM_PROFILE_STATIC_DATA_DIFFICULTIES_SLUGS = {
    FARM_DIFFICULTIES_SOIL_EROSION: 'farm-difficulties-soil-erosion',
    FARM_DIFFICULTIES_CIDE_RESISTANCE: 'farm-difficulties-cide-resistance',
    FARM_DIFFICULTIES_DIMINISHING_YIELDS: 'farm-difficulties-diminishing-yields',
    FARM_DIFFICULTIES_SOIL_FERTILITY: 'farm-difficulties-soil-fertility',
    FARM_DIFFICULTIES_SOIL_COMPACTION: 'farm-difficulties-soil-compaction',
    FARM_DIFFICULTIES_DROUGHTS: 'farm-difficulties-droughts',
    FARM_DIFFICULTIES_NO_PROBLEM: 'farm-difficulties-no-problem',
    FARM_DIFFICULTIES_OTHER: 'farm-difficulties-other',
} as const;

export type FarmProfileDifficultiesSlugsT =
    (typeof FARM_PROFILE_STATIC_DATA_DIFFICULTIES_SLUGS)[keyof typeof FARM_PROFILE_STATIC_DATA_DIFFICULTIES_SLUGS];

export const FARM_PROFILE_STATIC_DATA_FARM_TYPE_SLUGS = {
    FARM_TYPE_CONVENTIONAL: 'farm-type-conventional',
    FARM_TYPE_PARTIALLY_ORGANIC: 'farm-type-partially-organic',
    FARM_TYPE_TOTALLY_ORGANIC: 'farm-type-totally-organic',
} as const;

export type FarmProfileFarmTypeSlugsT =
    (typeof FARM_PROFILE_STATIC_DATA_FARM_TYPE_SLUGS)[keyof typeof FARM_PROFILE_STATIC_DATA_FARM_TYPE_SLUGS];

export const FARM_PROFILE_STATIC_DATA_TILLAGE_PRACTICE_SLUGS = {
    FARM_TILLAGE_PRACTICE_CONVENTIONAL_TILL: 'farm-tillage-practice-conventional-till',
    FARM_TILLAGE_PRACTICE_REDUCED_TILL: 'farm-tillage-practice-reduced-till',
    FARM_TILLAGE_PRACTICE_NO_TILL: 'farm-tillage-practice-no-till',
    FARM_TILLAGE_PRACTICE_NO_TILL_WORK: 'farm-tillage-practice-no-till-work',
} as const;

export type FarmProfileTillagePracticeSlugsT =
    (typeof FARM_PROFILE_STATIC_DATA_TILLAGE_PRACTICE_SLUGS)[keyof typeof FARM_PROFILE_STATIC_DATA_TILLAGE_PRACTICE_SLUGS];

export const FARM_PROFILE_STATIC_DATA_EXPECTATIONS_SLUGS = {
    FARM_EXPECTATIONS_REMUNERATION: 'farm-expectations-remuneration',
    FARM_EXPECTATIONS_ENVIRONMENTAL_FOOTPRINT_INDICATOR: 'farm-expectations-environmental-footprint-indicator',
    FARM_EXPECTATIONS_AGRONOMIC_ADVICE: 'farm-expectations-agronomic-advice',
    FARM_EXPECTATIONS_REGENERATIVE_AGRICULTURE_TRANSITION: 'farm-expectations-regenerative-agriculture-transition',
    FARM_EXPECTATIONS_NETWORK_WITH_FARMERS: 'farm-expectations-network-with-farmers',
    FARM_EXPECTATIONS_OTHER: 'farm-expectations-other',
} as const;

export type FarmProfileExpectationsSlugsT =
    (typeof FARM_PROFILE_STATIC_DATA_EXPECTATIONS_SLUGS)[keyof typeof FARM_PROFILE_STATIC_DATA_EXPECTATIONS_SLUGS];

export const FARM_PROFILE_STATIC_DATA_SLUGS = {
    ...FARM_PROFILE_STATIC_DATA_OBJECTIVES_SLUGS,
    ...FARM_PROFILE_STATIC_DATA_DIFFICULTIES_SLUGS,
    ...FARM_PROFILE_STATIC_DATA_FARM_TYPE_SLUGS,
    ...FARM_PROFILE_STATIC_DATA_TILLAGE_PRACTICE_SLUGS,
    ...FARM_PROFILE_STATIC_DATA_EXPECTATIONS_SLUGS,
} as const;

export type FarmProfileStaticDataSlugsT =
    (typeof FARM_PROFILE_STATIC_DATA_SLUGS)[keyof typeof FARM_PROFILE_STATIC_DATA_SLUGS];
