import { StepsTimeline } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import {
    IconBankNote,
    IconFile,
    IconAnnotationCheck,
    IconPenWritting,
    IconCreditCard,
} from '@soil-capital/ui-kit/icons';
import { useNavigate } from 'react-router-dom';
import { Grid, useTheme } from '@soil-capital/ui-kit/material-core';

export const OnboardingSteps = ({
    stepProgress,
    isCurrentStepDone,
    hasToPay,
}: {
    stepProgress: number;
    isCurrentStepDone?: boolean;
    hasToPay?: boolean;
}) => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <Grid display="flex" justifyContent="center">
            <Grid
                display="flex"
                justifyContent="center"
                padding={theme.spacing(4)}
                width={hasToPay ? '680px' : '500px'}
            >
                <StepsTimeline
                    steps={[
                        {
                            name: t('pages.onboarding.eligibility.step'),
                            icon: <IconFile />,
                            stepDoneOnClick: () => navigate('/onboarding/eligibility'),
                        },
                        {
                            name: t('pages.onboarding.billing.step'),
                            icon: <IconPenWritting />,
                            stepDoneOnClick: () => navigate('/onboarding/billing-information'),
                        },
                        {
                            name: t('pages.onboarding.commitment.step'),
                            icon: <IconAnnotationCheck />,
                            stepDoneOnClick: () => navigate('/onboarding/commitment'),
                        },
                        ...(hasToPay
                            ? [
                                  {
                                      name: t('pages.onboarding.pricing.step'),
                                      icon: <IconBankNote />,
                                      stepDoneOnClick: () => navigate('/onboarding/pricing'),
                                  },
                                  {
                                      name: t('pages.onboarding.payment.step'),
                                      icon: <IconCreditCard />,
                                  },
                              ]
                            : []),
                    ]}
                    progress={stepProgress}
                    isCurrentStepDone={isCurrentStepDone}
                />
            </Grid>
        </Grid>
    );
};
