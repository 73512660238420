import { makeStyles } from '@soil-capital/ui-kit/style';

export const usePacFieldUpdateModalStyles = makeStyles()((theme) => ({
    body: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(4),
    },
    description: { fontSize: theme.typography.subcaption.fontSize, marginTop: `${theme.spacing(1)}` },
    container: { display: 'flex', flexDirection: 'column' },
}));
