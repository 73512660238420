import { CropInfoBox } from '@shared/components/CropInfoBox/CropInfoBox';
import { useTranslation } from 'react-i18next';
import { useSelectedCropStyles } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/SelectedCrop/SelectedCrop.style';
import { IconClose, IconEdit } from '@soil-capital/ui-kit/icons';
import { Button, Tooltip } from '@soil-capital/ui-kit';
import { useSelectedCropLogic } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/SelectedCrop/useSelectedCropLogic';

export const SelectedCrop = ({
    farmSeasonCropId,
    onDelete,
    readOnly,
    onEdit,
}: {
    onEdit?: () => void;
    farmSeasonCropId: number;
    onDelete: () => void;
    readOnly: boolean;
}) => {
    const { classes } = useSelectedCropStyles();
    const { t } = useTranslation();
    const { crop } = useSelectedCropLogic({ farmSeasonCropId });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...rest } = crop;

    return (
        <div className={classes.row}>
            <CropInfoBox className={classes.cropInfoBox} {...rest} />
            <Tooltip text={readOnly ? '' : t('encoding-rotation.modal.left-panel.selected-crop-icon-tooltip-edit')}>
                <Button className={classes.button} variant="text" onClick={onEdit} disabled={readOnly}>
                    <IconEdit className={classes.icon} />
                </Button>
            </Tooltip>
            <Tooltip text={readOnly ? '' : t('encoding-rotation.modal.left-panel.selected-crop-icon-tooltip')}>
                <Button className={classes.button} variant="text" onClick={onDelete} disabled={readOnly}>
                    <IconClose className={classes.icon} data-testid="SelectedCrop-close-icon" />
                </Button>
            </Tooltip>
        </div>
    );
};
