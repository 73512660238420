import { HTMLAttributes } from 'react';
import { useLogoStyles } from '@shared/components/Logo/Logo.styles';

type LogoProps = {
    className?: string;
} & HTMLAttributes<HTMLImageElement>;

export const Logo = ({ className, ...rest }: LogoProps) => {
    const { classes, cx } = useLogoStyles();

    return (
        <img alt="my soil capital" className={cx(classes.logo, className)} src="/assets/images/logo.svg" {...rest} />
    );
};
