import { InferType } from 'yup';
import cropSchema from './crop.schema';
import { MakeUndefinedPartial } from '@shared/utils';
import { BaseEntityT } from '../baseEntity.types';
import { FieldCropT } from '../fieldCrop/fieldCrop.types';

export type CropT = {
    farm_season_id: number;
    crop_id: number;
    yield: string | null;
    residue: number | null;
    is_accompanied: boolean | null;
    accompanied_crop_id: number | null;
    order: number | null;
    coupled_id: string | null;
    creation_source: CropCreationSourceT | null;
} & BaseEntityT;

export const CROP_CREATION_SOURCE_SLUGS = {
    PAC: 'pac',
    MANUAL: 'manual',
    OPENDATA: 'opendata',
} as const;

export type CropCreationSourceT = (typeof CROP_CREATION_SOURCE_SLUGS)[keyof typeof CROP_CREATION_SOURCE_SLUGS];

export type CropBodyT = MakeUndefinedPartial<InferType<typeof cropSchema>>;

export type CropEndpointParamsT = {
    farmSeasonId?: number;
};

export type ChangeStaticCropResponseT = {
    field_crops_created: FieldCropT[];
    field_crops_deleted: number[];
    crop_updated?: CropT;
    crop_deleted?: number;
};
