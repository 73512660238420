import { SeasonEndpointParamsT, SeasonT } from './season.types';
import { createEntityAdapter } from '@reduxjs/toolkit';
import createCacheQueryHelper from '../createCacheQueryHelper';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import api from '@shared/api';

export const seasonCacheAdapter = createEntityAdapter<SeasonT>({ sortComparer: getDefaultSortComparerById<SeasonT>() });

const seasonCacheQueryHelper = createCacheQueryHelper<SeasonEndpointParamsT, SeasonT, 'Season'>(
    api,
    'Season',
    seasonCacheAdapter,
);

export default {
    transformResponse: seasonCacheQueryHelper.transformResponse,
    update: seasonCacheQueryHelper.update,
};
