import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import {
    CreateMineralFertiliserModalCloseDataT,
    CreateMineralFertiliserModalOpenDataT,
} from '../CreateMineralFertiliserModal/CreateMineralFertiliserModal';
import { SelectMineralFertiliserPropsT } from './SelectMineralFertiliser';
import { useModalController } from '@shared/hooks/useModalController';
import { useTranslation } from 'react-i18next';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import entities from '@shared/entities';
import { useMemo } from 'react';
import { sortBytranslation } from '@shared/utils/sortByTranslation';
import { FERTILISER_TYPE_SLUGS } from '@shared/entities/staticFertiliser/staticFertiliser.types';

export const useSelectMineralFertiliser = ({
    physicalState,
    selectedFertiliserIds,
    handleFertilisersChange,
}: Pick<SelectMineralFertiliserPropsT, 'physicalState'> & {
    selectedFertiliserIds: number[];
    handleFertilisersChange: (newFertiliserIds: number[]) => void;
}) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();

    let textCreateFertiliser: string;
    if (physicalState === 'liquid') {
        textCreateFertiliser = t('encoding-technical-itinerary.select-mineral-fertiliser.add-liquid-fertiliser-button');
    } else {
        textCreateFertiliser = t('encoding-technical-itinerary.select-mineral-fertiliser.add-solid-fertiliser-button');
    }

    const { currentFarm } = useCurrentFarm();
    const { staticFertiliserState } = entities.staticFertiliser.useState({ farmId: currentFarm?.id });
    const fertilisersList = useMemo(
        () => {
            return staticFertiliserState
                .getByFilters({
                    physicalState,
                    fertiliserTypes: [
                        FERTILISER_TYPE_SLUGS.MINERAL_FERTILISER,
                        FERTILISER_TYPE_SLUGS.MINERAL_SOIL_AMENDMENT,
                    ],
                })
                .sort(sortBytranslation(t));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [physicalState, staticFertiliserState],
    );

    const { open: openCreateFertiliserModal, ...createFertiliserModalProps } = useModalController<
        CreateMineralFertiliserModalOpenDataT,
        CreateMineralFertiliserModalCloseDataT
    >();
    const handleAddCustomFertiliser = async () => {
        const { addedFertiliserId } = await openCreateFertiliserModal({ physicalState, seasonId: currentSeasonId });
        if (!addedFertiliserId) return;
        handleFertilisersChange([
            ...selectedFertiliserIds.filter((fertiliserId) => fertiliserId !== addedFertiliserId), // this make sure we don't add duplicates
            addedFertiliserId,
        ]);
    };

    return {
        handleAddCustomFertiliser,
        createFertiliserModalProps,
        textCreateFertiliser,
        fertilisersList,
        getFertiliserById: staticFertiliserState.getById,
    };
};
