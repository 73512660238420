import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => {
    return {
        container: {
            zIndex: 1000,
            position: 'absolute',
            left: '50%',
            bottom: 0,
            margin: '0 10px 10px 30px',
            backgroundColor: theme.palette.common.white,
            borderRadius: theme.shape.borderRadiusS,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: theme.spacing(1),
            transform: 'translate(-50%, 0%)',
            gap: theme.spacing(1),
        },
    };
});
