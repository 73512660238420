import { PaymentStepModal } from '@modules/payment/components/PaymentStepModal/PaymentStepModal';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { BillingInfoForm } from '@modules/billing/components/BillingInfoForm/BillingInfoForm';
import { useBillingInfoLogic } from './useBillingInfoLogic';
import { UpdatePaymentMean } from './components/UpdatePaymentMean';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { BillingFormSkeleton } from './components/BillingFormSkeleton';

export const BillingInfo = () => {
    const theme = useTheme();
    const seasonId = useParams().seasonId;
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();
    const {
        currency,
        billingInfo,
        isLoading,
        handleConfirmClick,
        onConfirmRef,
        refetch,
        farmId,
        priceToPay,
        isDeferredPayment,
        isDataLoading,
        redirectToStripe,
        isCardExpired,
        isNextFarmSeasonProductPlanLoading,
    } = useBillingInfoLogic();

    const desactivateConfirmButton = (isFormValid: boolean) => {
        // If the card is expired, the form is not valid and we deactivate the continue button
        setIsFormValid(isFormValid && !isCardExpired);
    };

    const [isFormSubmitting, setIsFormSubmitting] = useState(false);

    return (
        <Grid display="flex" justifyContent="center">
            <PaymentStepModal
                stepProgress={1}
                ctaAction={handleConfirmClick}
                ctaText="pages.payment.billing-info.cta"
                onBackClick={() => navigate(`/${seasonId}/payment/pricing`)}
                isCTAdisabled={!isFormValid || isNextFarmSeasonProductPlanLoading}
                isCTAloading={isFormSubmitting || isLoading}
            >
                <Grid gap={theme.spacing(3)} display="flex" flexDirection="column" paddingTop={theme.spacing(5)}>
                    {!farmId || isDataLoading ? (
                        <BillingFormSkeleton withPaymentUpdate />
                    ) : billingInfo && farmId ? (
                        <>
                            {!isDeferredPayment && (
                                <UpdatePaymentMean farmId={farmId} priceToPay={priceToPay} currency={currency} />
                            )}
                            <BillingInfoForm
                                refetch={refetch}
                                setOnConfirm={(onConfirm) => (onConfirmRef.current = onConfirm)}
                                userCompany={billingInfo}
                                setDisableContinue={desactivateConfirmButton}
                                setIsSubmitting={(isFormSubmitting) => setIsFormSubmitting(isFormSubmitting)}
                                nextAction={redirectToStripe}
                            />
                        </>
                    ) : (
                        <BillingInfoForm
                            refetch={refetch}
                            setOnConfirm={(onConfirm) => (onConfirmRef.current = onConfirm)}
                            setDisableContinue={desactivateConfirmButton}
                            setIsSubmitting={(isFormSubmitting) => setIsFormSubmitting(isFormSubmitting)}
                            nextAction={redirectToStripe}
                        />
                    )}
                </Grid>
            </PaymentStepModal>
        </Grid>
    );
};
