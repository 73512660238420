import { useWatch } from 'react-hook-form';
import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';
import { useGetSeedingMachineries } from '@modules/encoding/modules/technicalItinerary/module/seeding/hooks/useGetSeedingMachineries';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';

export const useSeedingSoilWorkWidthInputVisibility = () => {
    const machineryId = useWatch({ name: SEEDING_FORM_INPUT_NAMES.MACHINERY_ID });
    const { seedingMachineries } = useGetSeedingMachineries();

    const seedingMachinery = seedingMachineries.find(({ id }) => id === machineryId);
    const shouldDisplay = seedingMachinery?.slug && seedingMachinery?.slug === STATIC_MACHINERY_SLUGS.STRIP_TILL;

    return {
        shouldDisplay,
    };
};
