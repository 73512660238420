import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import useOperationContextualPanelLogic from './useOperationContextualPanel.logic';
import useOperationContextualPanelStyle from './useOperationContextualPanel.style';
import OperationTimelineNav from '../../components/OperationTimelineNav/OperationTimelineNav';
import { useTranslation } from 'react-i18next';
import FieldMapSnapshot from '../../components/FieldMapSnapshot/FieldMapSnapshot';
import { Button } from '@soil-capital/ui-kit/components';
import { IconFile2 } from '@soil-capital/ui-kit/icons';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import technicalItineraryRoutes from '../../technicalItinerary.routes';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const {
        fieldCropId,
        operationId,
        seasonId,
        fieldId,
        isLoading,
        setFieldMapSnapshotIsLoading,
        setTimelineNavIsLoading,
        setFieldIdentityCardIsLoading,
    } = useOperationContextualPanelLogic();
    const { classes } = useOperationContextualPanelStyle();
    const { t } = useTranslation();

    return (
        <encodingShared.ContextualPanel isLoading={isLoading}>
            <div className={classes.OperationContextualPanel}>
                <FieldMapSnapshot
                    farmSeasonId={seasonId}
                    fieldId={fieldId}
                    header={
                        <Button
                            variant="text"
                            startIcon={<IconFile2 fontSize="small" />}
                            size="medium"
                            onClick={() =>
                                navigate(
                                    `../${
                                        encodingRoutesConfig.TECHNICAL_ITINERARY
                                    }/${technicalItineraryRoutes.introduction()}`,
                                )
                            }
                        >
                            {t('encoding-technical-itinerary.contextual-panel.back-to-crops-list')}
                        </Button>
                    }
                    setFieldMapSnapshotIsLoading={setFieldMapSnapshotIsLoading}
                >
                    <encodingShared.FieldIdentityCard
                        fieldCropId={fieldCropId}
                        setIsLoading={setFieldIdentityCardIsLoading}
                    />
                </FieldMapSnapshot>

                <OperationTimelineNav
                    fieldCropId={fieldCropId}
                    operationId={operationId}
                    seasonId={seasonId}
                    navigate={navigate}
                    setTimelineNavIsLoading={setTimelineNavIsLoading}
                />
            </div>
        </encodingShared.ContextualPanel>
    );
};
