import { useRoutes } from 'react-router-dom';
import { paymentRoutesConfig } from './payment.routes';
import { PaymentInProgress } from './PaymentInProgress/PaymentInProgress';
import { PaymentFailed } from './PaymentFailed/PaymentFailed';

export default () => {
    const routes = useRoutes([
        {
            path: paymentRoutesConfig.inProgress,
            element: <PaymentInProgress />,
        },
        {
            path: paymentRoutesConfig.failed,
            element: <PaymentFailed />,
        },
    ]);

    return <>{routes}</>;
};
