import entities from '@shared/entities';
import { ReplaceCropModalProps } from './ReplaceCropModal';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import useCurrentFarm from '@modules/encoding/shared/hooks/useCurrentFarm';
import { useTranslation } from 'react-i18next';

export const useReplaceCropModalLogic = ({
    controller,
}: {
    controller: ReplaceCropModalProps['replaceCropModalController'];
}) => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: currentSeasonId });
    const { currentFarm } = useCurrentFarm();
    const { cropState } = entities.crop.useState({ farmSeasonId: currentSeasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { fieldState } = entities.field.useState({ farmSeasonId: currentSeasonId });

    const existingFieldCrop = fieldCropState.getById(controller.data?.existingFieldCropId);

    const previousCrop = cropState.getById(existingFieldCrop?.farm_season_crop_id);
    const previousStaticCrop = staticCropState.getById(previousCrop?.crop_id);
    const previousCropName = previousStaticCrop?.name ? t(`db.crop.slug-${previousStaticCrop?.slug}`) : '';
    const fieldName = fieldState.getById(existingFieldCrop?.farm_season_field_id)?.name;
    const newCrop = cropState.getById(controller.data?.newCropId);
    const newStaticCrop = staticCropState.getById(newCrop?.crop_id);
    const newCropName = newStaticCrop?.name ? t(`db.crop.slug-${newStaticCrop?.slug}`) : '';

    return {
        previousCropName,
        newCropName,
        fieldName,
    };
};
