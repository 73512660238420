import { auth } from '@modules/auth';
import { useGetPartnerInfoQuery } from '@modules/billing/api/partnerApi';
import { useGetUserCompanyQuery } from '@modules/billing/api/userCompanyApi';
import { useGetOnboardingFarmQuery } from '@modules/onboarding/api/onboardingApi';
import { useEffect, useState } from 'react';

export const useBillingInformationLogic = () => {
    const { data: farmData, isLoading } = useGetOnboardingFarmQuery({ userId: auth.useAuthUser()?.id });
    const {
        data: billingInfo,
        refetch,
        isLoading: isBillingInfoLoading,
    } = useGetUserCompanyQuery({
        farmId: farmData?.id,
    });
    const { data: partnerData, isLoading: isPartnerDataLoading } = useGetPartnerInfoQuery({
        userId: auth.useAuthUser()?.id,
    });

    const [isFormValid, setIsFormValid] = useState(false);
    const [isFormSubmitting, setIsFormSubmitting] = useState(false);
    const desactivateConfirmButton = (isFormValid: boolean) => {
        setIsFormValid(isFormValid);
    };

    useEffect(() => {
        if (partnerData?.is_partner_prepaying) {
            setIsFormValid(true);
        }
    }, [partnerData]);

    return {
        isLoading: isLoading || isPartnerDataLoading || isBillingInfoLoading,
        billingInfo,
        refetch,
        isFormValid,
        setIsFormValid,
        isFormSubmitting,
        setIsFormSubmitting,
        desactivateConfirmButton,
        partnerData,
    };
};
