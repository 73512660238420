import { ModalPropsT } from '@shared/hooks/useModalController';
import { Modal } from '@soil-capital/ui-kit/components';
import { Trans, useTranslation } from 'react-i18next';
import { useCreateMineralFertiliserModal } from './useCreateMineralFertiliserModal';
import { StaticFertiliserT } from '@shared/entities/staticFertiliser/staticFertiliser.types';
import { useCreateMineralFertiliserModalStyle } from './useCreateMineralFertiliserModal.style';
import { CreateMineralFertiliserForm } from '../CreateMineralFertiliserForm/CreateMineralFertiliserForm';
import { FormProvider } from 'react-hook-form';

export type CreateMineralFertiliserModalOpenDataT = { physicalState: 'solid' | 'liquid'; seasonId: number };
export type CreateMineralFertiliserModalCloseDataT = { addedFertiliserId: number | null };

export type CreateMineralFertiliserModalPropsT = ModalPropsT<
    CreateMineralFertiliserModalOpenDataT,
    CreateMineralFertiliserModalCloseDataT
>;
export const CreateMineralFertiliserModal = (modalProps: CreateMineralFertiliserModalPropsT) => {
    const { t } = useTranslation();
    const { classes } = useCreateMineralFertiliserModalStyle();
    const {
        textModalTitle,
        textModalName,
        createStaticFertiliserLoading,
        onSubmit,
        errors,
        isSubmitted,
        isValid,
        formMethods,
        globalError,
        existingFertiliserForNPK,
        defaultFertiliserNameComputed,
    } = useCreateMineralFertiliserModal(modalProps);

    return (
        <Modal
            data-testid="create-mineral-fertiliser-modal"
            size="small"
            open={modalProps.isOpen}
            onClose={() => modalProps.onClose?.({ addedFertiliserId: null })}
            onConfirm={onSubmit}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{textModalTitle}</Modal.HeaderTitle>
                    <Modal.Close />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <FormProvider {...formMethods}>
                        <CreateMineralFertiliserForm
                            textModalName={textModalName}
                            defaultFertiliserNameComputed={defaultFertiliserNameComputed}
                            globalErrors={isSubmitted ? globalError || errors.fertiliser_name?.message : undefined}
                            NPKErrors={t(errors.n?.message || errors.p?.message || errors.k?.message || '')}
                            CaMgSErrors={t(errors.ca?.message || errors.mg?.message || errors.s?.message || '')}
                        />
                    </FormProvider>
                    <FertiliserSuggestions
                        closeModal={modalProps.onClose}
                        existingDefaultsForNPK={existingFertiliserForNPK}
                        className={classes.fertiliserSuggestion}
                        linkClassName={classes.link}
                    />
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel disabled={createStaticFertiliserLoading}>{t('constants.cancel')}</Modal.Cancel>
                    <Modal.Confirm
                        disabled={isSubmitted && (!isValid || globalError)}
                        loading={createStaticFertiliserLoading}
                    >
                        {t('encoding-technical-itinerary.create-mineral-fertiliser-modal.confirm-button')}
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};

const FertiliserSuggestions = ({
    closeModal,
    existingDefaultsForNPK,
    className,
    linkClassName,
}: {
    existingDefaultsForNPK: StaticFertiliserT[];
    closeModal: CreateMineralFertiliserModalPropsT['onClose'];
    className?: string;
    linkClassName?: string;
}) => {
    const { t } = useTranslation();
    return (
        <div className={className}>
            {existingDefaultsForNPK.length ? (
                <Trans
                    components={{
                        fertilisers: (
                            <>
                                {existingDefaultsForNPK?.map((f, i) => (
                                    <>
                                        <span
                                            key={f.id}
                                            className={linkClassName}
                                            onClick={() => closeModal?.({ addedFertiliserId: f.id })}
                                        >
                                            {t(f.translation_slug)}
                                        </span>
                                        {i < existingDefaultsForNPK.length - 1 ? <>, &nbsp;</> : ''}
                                    </>
                                ))}
                            </>
                        ),
                    }}
                    count={existingDefaultsForNPK.length}
                    i18nKey="components.modal-create-fertiliser.npk-exist"
                />
            ) : null}
        </div>
    );
};
