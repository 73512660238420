import { useRotationFieldFilters } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/hooks/useRotationFieldFilters';
import { useFieldCropAssignationModalRightPanelViewModel } from '@modules/encoding/modules/rotation/components/FieldCropAssignationModal/components/FieldCropAssignationModalRightPanel/useFieldCropAssignationModalRightPanelViewModel';
import { useWatch } from 'react-hook-form';
import { ROTATION_ASSIGNATION_INPUT_NAMES } from '../../hooks/useRotationAssignationForm';
import { useModalController } from '@shared/hooks/useModalController';
import { ReplaceCropModalCloseProps, ReplaceCropModalOpenProps } from '../ReplaceCropModal/ReplaceCropModal';

export const useFieldCropAssignationModalRightPanelLogic = () => {
    const selectedFieldIds: number[] = useWatch({ name: ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_FIELD_IDS });
    const selectedCropId: number | null = useWatch({ name: ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID });

    const {
        harvestYear,
        fieldList,
        getFieldCrop,
        getFieldCropIdByCropIdAndFieldId,
        createFieldCrop,
        getHasOperationsByFarmSeasonFieldCropId,
        deleteFieldCrop,
        getFieldName,
        getStaticCropName,
        mapLayerSource,
        getFieldCropsByFieldId,
    } = useFieldCropAssignationModalRightPanelViewModel();

    const confirmDeleteFieldCropModal = useModalController<{ fieldName: string; staticCropName: string }>();
    const replaceCropModal = useModalController<ReplaceCropModalOpenProps, ReplaceCropModalCloseProps>();

    const {
        search,
        fieldMatchingFilter,
        selectedFilter,
        handleSearchChange,
        handleResetSearch,
        doesFieldMatchFilters,
        handleFilterChanged,
        handleFilterReset,
        handleFieldIdFilterReset,
    } = useRotationFieldFilters();

    const handleFieldSelection = async (fieldId: number) => {
        if (!selectedCropId) {
            console.error('No crop selected');
            return;
        }
        const selectedFieldCropId = getFieldCropIdByCropIdAndFieldId({ cropId: selectedCropId, fieldId });

        // if selectedField exist in FE but not yet in DB (optimistic response), we stop action.
        if (Number(selectedFieldCropId) < 0) {
            return;
        }

        const otherCropLinkedToField = getFieldCropsByFieldId({ fieldId })[0];
        const cropCanBeReplaced = !selectedFieldCropId && otherCropLinkedToField;
        if (cropCanBeReplaced) {
            const { action } = await replaceCropModal.open({
                existingFieldCropId: otherCropLinkedToField.id,
                newCropId: selectedCropId,
            });

            if (action === 'replace_crop' || action === 'add_crop') {
                createFieldCrop({
                    cropId: selectedCropId,
                    fieldId,
                });
            }
            if (action === 'replace_crop') {
                deleteFieldCrop(otherCropLinkedToField.id);
            }
            return;
        }

        // if selectedField does not exist, we can create it
        if (!selectedFieldCropId) {
            createFieldCrop({
                cropId: selectedCropId,
                fieldId,
            });
            return;
        }

        // if selectedField exist in FE and DB delete it
        if (selectedFieldCropId > 0) {
            const hasOperationsLinked = getHasOperationsByFarmSeasonFieldCropId(selectedFieldCropId);
            if (!hasOperationsLinked) {
                deleteFieldCrop(selectedFieldCropId);
                return;
            }

            // fieldCrop has operations => ask confirmation to delete.
            if (
                await confirmDeleteFieldCropModal.open({
                    fieldName: getFieldName(fieldId),
                    staticCropName: getStaticCropName(selectedCropId),
                })
            ) {
                deleteFieldCrop(selectedFieldCropId);
                return;
            }
        }
    };

    return {
        harvestYear,
        fieldList,
        selectedFieldIds,
        search,
        fieldMatchingFilter,
        selectedFilter,
        handleSearchChange,
        handleResetSearch,
        doesFieldMatchFilters,
        handleFilterChanged,
        handleFilterReset,
        handleFieldIdFilterReset,
        handleFieldSelection,
        selectedCropId,
        getFieldCrop,
        confirmDeleteFieldCropModal,
        mapLayerSource,
        replaceCropModal,
    };
};
