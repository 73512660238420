import { useCallback } from 'react';

export const useGetFieldThumbnail = ({ seasonId }: { seasonId: number }) => {
    const getFieldThumbnail = useCallback(
        ({ fieldId, updatedAt }: { fieldId: number; updatedAt: string }) => {
            // updatedAtTimestamp is used for the cache-control header.
            const updatedAtTimestamp = new Date(updatedAt).getTime();
            return `${process.env.REACT_APP_API_BASE_URL}/v3/farm-seasons/${seasonId}/fields/${fieldId}/thumbnail?updated_at_timestamp=${updatedAtTimestamp}`;
        },
        [seasonId],
    );

    return { getFieldThumbnail };
};
