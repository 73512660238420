import { makeStyles } from '@soil-capital/ui-kit/style';

export const useMineralFertiliserProductsInputStyles = makeStyles()((theme) => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        listStyle: 'none',
        paddingLeft: 0,
    },
    select: {
        maxWidth: 256,
    },
}));
