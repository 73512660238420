import { makeStyles } from '@soil-capital/ui-kit/style';

export const usePacImportActionPageStyles = makeStyles()((theme) => ({
    body: {
        padding: 0,
        overflowY: 'hidden',
    },
    main: {
        height: '100%',
        position: 'relative',

        '&::after': {
            position: 'absolute',
            inset: 0,
            display: 'block',
            content: '""',
            background: '#D9D9D9',
            opacity: 0.5,
            borderRadius: theme.shape.borderRadius,
        },
    },
    hideFilter: {
        '&::after': {
            display: 'none',
        },
    },
    mapSnapshot: {
        display: 'block',
        width: '100%',
        height: '101%',
        objectFit: 'cover',
        borderRadius: theme.shape.borderRadius,
    },
    toast: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
        background: 'white',
        padding: theme.spacing(2),
        borderRadius: theme.shape.borderRadiusS,
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 2,
    },
    placeholderContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(2),
        height: '100%',
    },
    mapPlaceholder: {},
    description: {
        maxWidth: '330px',
        textAlign: 'center',
        color: theme.palette.darkScale[700],
    },
}));
