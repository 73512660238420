import { FieldT } from '@shared/entities';
import { DuplicationFieldT } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { Feature, Geometry, GeoJsonProperties } from 'geojson';

export type geoJsonFeatureT = Feature<Geometry, GeoJsonProperties>;

export type MapContextT = {
    map: mapboxgl.Map | null;
    mapContainerRef: React.RefObject<HTMLDivElement>;
    setMap: React.Dispatch<React.SetStateAction<mapboxgl.Map | null>>;
    recenterMapOnPolygons: (polygonToCenter: geoJsonFeatureT[]) => void;
    centerMapOnCoordinates: (coordinates: [number, number]) => void;
    calculateCentroid: (polygonToCenter: geoJsonFeatureT[]) => [number, number] | undefined;
    zoomIn: () => void;
    zoomOut: () => void;
    fields: FieldT[] | DuplicationFieldT[] | fieldWithCropT[];
    setFields: React.Dispatch<React.SetStateAction<FieldT[] | DuplicationFieldT[] | fieldWithCropT[]>>;
    selectedFields: FieldT[] | DuplicationFieldT[] | fieldWithCropT[];
    setSelectedFields: React.Dispatch<React.SetStateAction<FieldT[] | DuplicationFieldT[] | fieldWithCropT[]>>;
    drawMode: boolean;
    setDrawMode: React.Dispatch<React.SetStateAction<boolean>>;
    editMode: boolean;
    setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
};

export type cropNamesT = {
    id: number;
    name: string;
};

export type fieldWithCropT = FieldT & {
    crops: cropNamesT[];
};

export const POLYGON_SLUGS = {
    POLYGON: 'polygon',
    POLYGON_OUTLINE: 'polygon-outline',
    POLYGON_TEXT: 'polygon-text',
    SELECTED_POLYGON: 'selected-polygon',
    SELECTED_POLYGON_OUTLINE: 'selected-polygon-outline',
    SELECTED_POLYGON_TEXT: 'selected-polygon-text',
} as const;
