import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

type MonthPeriodTypeT = 'start' | 'mid' | 'end';

const dayToMonthPeriodMapping: Record<number, MonthPeriodTypeT> = {
    1: 'start',
    2: 'start',
    3: 'start',
    4: 'start',
    5: 'start',
    6: 'start',
    7: 'start',
    8: 'start',
    9: 'start',
    10: 'start',

    11: 'mid',
    12: 'mid',
    13: 'mid',
    14: 'mid',
    15: 'mid',
    16: 'mid',
    17: 'mid',
    18: 'mid',
    19: 'mid',
    20: 'mid',

    21: 'end',
    22: 'end',
    23: 'end',
    24: 'end',
    25: 'end',
    26: 'end',
    27: 'end',
    28: 'end',
    29: 'end',
    30: 'end',
    31: 'end',
};

/** array built to access the slug with month as index then period key: `monthSlugs[date.getMonth()][periodDayKey]` */
const monthSlugs: Array<{ [k in MonthPeriodTypeT]: string }> = [
    {
        start: 'encoding-technical-itinerary.operation-date.start-january',
        mid: 'encoding-technical-itinerary.operation-date.mid-january',
        end: 'encoding-technical-itinerary.operation-date.end-january',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-february',
        mid: 'encoding-technical-itinerary.operation-date.mid-february',
        end: 'encoding-technical-itinerary.operation-date.end-february',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-march',
        mid: 'encoding-technical-itinerary.operation-date.mid-march',
        end: 'encoding-technical-itinerary.operation-date.end-march',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-april',
        mid: 'encoding-technical-itinerary.operation-date.mid-april',
        end: 'encoding-technical-itinerary.operation-date.end-april',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-may',
        mid: 'encoding-technical-itinerary.operation-date.mid-may',
        end: 'encoding-technical-itinerary.operation-date.end-may',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-june',
        mid: 'encoding-technical-itinerary.operation-date.mid-june',
        end: 'encoding-technical-itinerary.operation-date.end-june',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-july',
        mid: 'encoding-technical-itinerary.operation-date.mid-july',
        end: 'encoding-technical-itinerary.operation-date.end-july',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-august',
        mid: 'encoding-technical-itinerary.operation-date.mid-august',
        end: 'encoding-technical-itinerary.operation-date.end-august',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-september',
        mid: 'encoding-technical-itinerary.operation-date.mid-september',
        end: 'encoding-technical-itinerary.operation-date.end-september',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-october',
        mid: 'encoding-technical-itinerary.operation-date.mid-october',
        end: 'encoding-technical-itinerary.operation-date.end-october',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-november',
        mid: 'encoding-technical-itinerary.operation-date.mid-november',
        end: 'encoding-technical-itinerary.operation-date.end-november',
    },
    {
        start: 'encoding-technical-itinerary.operation-date.start-december',
        mid: 'encoding-technical-itinerary.operation-date.mid-december',
        end: 'encoding-technical-itinerary.operation-date.end-december',
    },
];

export const useGetOperationDateString = () => {
    const { t } = useTranslation();

    const getOperationDateString = useCallback(
        (operationDate: string | null | undefined, fallbackSlug = '') => {
            if (!operationDate) {
                return fallbackSlug;
            }

            // set date object
            const date = new Date(operationDate);
            // getTime will return 'NaN' if not valid date. And NaN is never equal to itself
            const isDateValid = date.getTime() === date.getTime();
            if (!isDateValid) {
                return fallbackSlug;
            }

            // compute final slug
            const periodDayKey = dayToMonthPeriodMapping[date.getDate()];
            const periodMonthSlug = monthSlugs[date.getMonth()][periodDayKey];
            const year = date.getFullYear();
            return `${t(periodMonthSlug)} ${year}`; // format string as "End June 2023"
        },
        [t],
    );

    return { getOperationDateString };
};
