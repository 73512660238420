import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { CollapsableGroup } from './components/CollapsableGroup/CollapsableGroup';
import { useTranslation } from 'react-i18next';
import { InfoBox } from '@modules/payment/pages/Pricing/components/InfoBox/InfoBox';
import { PricingPromoCard } from './components/PricingPromoCard/PricingPromoCard';
import { Typography, Grid, useTheme } from '@soil-capital/ui-kit/material-core';
import { Trans } from 'react-i18next';
import { PricingPromoCardSkeleton } from './components/PricingPromoCardSkeleton/PricingPromoCardSkeleton';
import { usePricingLogic } from './usePricingLogic';

export const Pricing = () => {
    const { t } = useTranslation();
    const theme = useTheme();

    const {
        currency,
        subscriptionPrice,
        haPrice,
        floorPrice,
        ceiledPrice,
        hasDiscount,
        discountedPrice,
        isDeferredPayment,
        farmSeasonProductPlanId,
        isBelgian,
        redirectToStripe,
        isLoading,
        isPricingDataLoading,
        isFetching,
        discounts,
        farmSeasonId,
        navigate,
    } = usePricingLogic();

    return (
        <OnboardingStepsModal
            stepProgress={3}
            ctaAction={isDeferredPayment ? () => navigate('/onboarding/confirmation') : redirectToStripe}
            ctaText={isDeferredPayment ? t('constants.continue') : t('pages.onboarding.pricing.card.cta')}
            isCTAdisabled={isPricingDataLoading}
            isCTAloading={isLoading}
            onBackClick={() => navigate('/onboarding/commitment')}
        >
            <CollapsableGroup />
            {isPricingDataLoading ? (
                <PricingPromoCardSkeleton />
            ) : isDeferredPayment ? (
                <Grid paddingBottom={theme.spacing(12)}>
                    <InfoBox
                        price={subscriptionPrice}
                        currency={currency}
                        // var not used when component is in deferred payment mode
                        payableHectares={0}
                        isDeferredPayment={isDeferredPayment}
                        pricePerHectare={haPrice}
                        ceilingPrice={ceiledPrice}
                        floorPrice={floorPrice}
                        isBelgian={isBelgian}
                        caption={t('pages.onboarding.pricing.deferred-payment.caption')}
                    />
                    <Typography variant="body2" textAlign="center" paddingTop={theme.spacing(3)}>
                        <Trans i18nKey="pages.onboarding.pricing.deferred-payment.description" />
                    </Typography>
                </Grid>
            ) : (
                <PricingPromoCard
                    hasDiscount={hasDiscount}
                    subscriptionPrice={subscriptionPrice}
                    discountedPrice={discountedPrice}
                    currency={currency}
                    haPrice={haPrice}
                    floorPrice={floorPrice}
                    ceiledPrice={ceiledPrice}
                    isLoading={isLoading}
                    isBelgian={isBelgian}
                    farmSeasonProductPlanId={farmSeasonProductPlanId}
                    redirectToStripe={redirectToStripe}
                    promotionCodes={discounts}
                    isFetching={isFetching}
                    farmSeasonId={farmSeasonId ?? 0}
                    t={t}
                />
            )}
        </OnboardingStepsModal>
    );
};
