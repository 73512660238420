import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';

export const useFinaliseAssignationWarningModalLogic = () => {
    const { fieldState } = useGetFarmSeasonFields();
    const { getFieldsWithoutCrop } = fieldState.useGetFieldsWithoutCrop();
    const fieldsWithoutCrop = getFieldsWithoutCrop();

    return {
        fieldsWithoutCrop,
    };
};
