import { makeStyles } from '@soil-capital/ui-kit/style';

export const useOperationDuplicationActionPanelStyles = makeStyles()((theme) => ({
    image: {
        width: '100%',
        height: 190,
        objectFit: 'contain',
        borderRadius: theme.shape.borderRadiusM,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        gap: theme.spacing(2),
        color: theme.palette.common.white,
    },
    title: {
        textAlign: 'left',
    },
    actions: {
        marginTop: theme.spacing(4),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));
