import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@soil-capital/ui-kit/components';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { FormRow } from '@shared/components';
import { useCoverCropDestructionPassageQuestion } from './useCoverCropDestructionPassageQuestion';

export const CoverCropDestructionPassageQuestion = ({ onChange }: { onChange: () => void }) => {
    const methods = useFormContext();
    const { isVisible } = useCoverCropDestructionPassageQuestion();
    const { t } = useTranslation();

    return (
        <>
            {isVisible && (
                <FormRow>
                    <FormQuestion
                        question={t('encoding-cover-crop.question.destruction-passage')}
                        tooltipTitleKey="encoding-cover-crop.question.tooltips.title.destruction-passage"
                        tooltipTextKey="encoding-cover-crop.question.tooltips.text.destruction-passage"
                    />
                    <Input
                        type="number"
                        onChange={onChange}
                        inputProps={{
                            ...methods.register(COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_PASSAGE, {
                                valueAsNumber: true,
                            }),
                        }}
                        placeholder={t('encoding-cover-crop.question.placeholder.destruction-passage')}
                        style={{ width: '250px' }}
                    />
                </FormRow>
            )}
        </>
    );
};
