import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { PacFileImportDataT, PacFileImportPayloadT } from './pacFileImportApi.types';

export const useImportPacFileMutation = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { refetch } = entities.progress.useGet({ seasonId: currentSeasonId });

    return async ({ farmSeasonId, body }: { farmSeasonId: number; body: PacFileImportPayloadT }) => {
        const baseUrl = process.env.REACT_APP_API_BASE_URL;
        const myHeaders = new Headers();

        myHeaders.set('Accept', 'application/json, */*');

        const formdata = new FormData();
        const files = body.shape_files;
        files.forEach((file) => {
            formdata.append('shape_files[]', file, file.name);
        });

        const requestOptions: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            credentials: 'include' as RequestCredentials,
        };

        const url = baseUrl + `/v3/farm-seasons/${farmSeasonId}/import-shape-file`;

        const response = await fetch(url, requestOptions);
        refetch();
        return await (response.json() as Promise<PacFileImportDataT>);
    };
};
