export const SEEDING_STATIC_DATA_TYPE = {
    SEEDING_ORIGIN: 'SEEDING_ORIGIN',
} as const;

export type SeedingStaticDataTypeT = keyof typeof SEEDING_STATIC_DATA_TYPE;

export const SEEDING_STATIC_DATA_SEED_ORIGIN_SLUGS = {
    SEEDING_ORIGIN_COMMERCIAL: 'seeding-origin-commercial',
    SEEDING_ORIGIN_OWN_PRODUCTION: 'seeding-origin-own-production',
} as const;

export type SeedingOriginSlugsT =
    (typeof SEEDING_STATIC_DATA_SEED_ORIGIN_SLUGS)[keyof typeof SEEDING_STATIC_DATA_SEED_ORIGIN_SLUGS];

export const SEEDING_STATIC_DATA_SLUGS = {
    ...SEEDING_STATIC_DATA_SEED_ORIGIN_SLUGS,
} as const;

export type SeedingStaticDataSlugsT = (typeof SEEDING_STATIC_DATA_SLUGS)[keyof typeof SEEDING_STATIC_DATA_SLUGS];
