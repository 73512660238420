import { useCallback, useMemo } from 'react';
import createStaticEntity from '../createStaticEntity';
import {
    FERTILISER_UNIT,
    FertiliserTypeSlugT,
    StaticFertiliserT,
} from '@shared/entities/staticFertiliser/staticFertiliser.types';
import { staticFertiliserSortComparer } from './staticFertiliser.cache';

export type StaticFertiliserEndpointParamsT = { farmId?: number };
const staticFertiliserUrl = ({ farmId }: StaticFertiliserEndpointParamsT) => `/v3/farms/${farmId}/fertilisers`;

type FertiliserPhysicalStateT = 'solid' | 'liquid';
const physicalStateMappingUnit: Record<FertiliserPhysicalStateT, StaticFertiliserT['unit']> = {
    solid: FERTILISER_UNIT.KG,
    liquid: FERTILISER_UNIT.L,
};

const getFilterByTypes = <T extends StaticFertiliserT>(fertiliserTypes?: FertiliserTypeSlugT[]) => {
    return (fertiliser: T) =>
        !fertiliserTypes ||
        fertiliserTypes?.some((fertiliserType) => fertiliser.fertiliser_type_slug === fertiliserType);
};
const getFilterByPhisicalState = <T extends StaticFertiliserT>(physicalState?: FertiliserPhysicalStateT) => {
    return (fertiliser: T) => !physicalState || fertiliser.unit === physicalStateMappingUnit[physicalState];
};

const staticFertiliserEntity = createStaticEntity<StaticFertiliserT>()({
    entityName: 'staticFertiliser',
    url: staticFertiliserUrl,
    useGetCustomState: (defaultState) => {
        const getByFilters = useCallback(
            (filters: { fertiliserTypes?: FertiliserTypeSlugT[]; physicalState?: FertiliserPhysicalStateT }) => {
                return defaultState.list
                    .filter(getFilterByTypes(filters.fertiliserTypes))
                    .filter(getFilterByPhisicalState(filters.physicalState));
            },
            [defaultState.list],
        );

        return useMemo(() => ({ ...defaultState, getByFilters }), [defaultState, getByFilters]);
    },
    sortComparer: staticFertiliserSortComparer, // just to make sure the sortComparer is the same as the one used in the api file
});

export default {
    ...staticFertiliserEntity,
};
