import { SkeletonInputRow } from '@shared/components/form/SkeletonInputRow/SkeletonInputRow';
import { Box } from '@soil-capital/ui-kit/material-core';

export const DestructionFormSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" gap={2} data-testid="destruction-form-skeleton">
            <SkeletonInputRow size="lg" variant="input" />
        </Box>
    );
};
