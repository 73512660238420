import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { useTranslation } from 'react-i18next';
import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';
import { useSeedingMachineryDepthInputVisibility } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingMachineryDepthInput/useSeedingMachineryDepthInputVisibility';

type SeedingMachineryDepthInputProps = {
    onChange: () => void;
};

export const SeedingMachineryDepthInput = ({ onChange }: SeedingMachineryDepthInputProps) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplay } = useSeedingMachineryDepthInputVisibility();

    if (!context) throw new Error('SeedingMachineryDepthInput must be used within a FormProvider');

    return shouldDisplay ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.seeding.question.seed-machinery-depth')}
                tooltipTitleKey="encoding-technical-itinerary.seeding.question.seed-machinery-depth.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.seeding.question.seed-machinery-depth.tooltip.text"
            />
            <Input
                sx={{ maxWidth: 250 }}
                onChange={onChange}
                type="number"
                suffix="cm"
                defaultValue={null}
                inputProps={{
                    ...context.register(SEEDING_FORM_INPUT_NAMES.MACHINERY_DEPTH, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
            />
        </FormRow>
    ) : (
        <></>
    );
};
