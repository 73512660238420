import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { useInfoBoxStyle } from './InfoBox.style';
import { useTranslation } from 'react-i18next';
import { ConditionalTooltip } from '@soil-capital/ui-kit/components';

interface InfoBoxPropsT {
    price: number;
    currency: string;
    payableHectares: number;
    pricePerHectare: number;
    floorPrice: number;
    ceilingPrice: number;
    isBelgian?: boolean;
    isDeferredPayment?: boolean;
    caption?: string;
}

export const InfoBox = ({
    price,
    currency,
    payableHectares,
    pricePerHectare,
    floorPrice,
    ceilingPrice,
    isBelgian,
    isDeferredPayment,
    caption,
}: InfoBoxPropsT) => {
    const { classes } = useInfoBoxStyle();
    const { t } = useTranslation();

    return (
        <Grid className={classes.container}>
            <Grid className={classes.background}>
                {isDeferredPayment ? (
                    <>
                        <Typography variant={'h3'} className={classes.dark}>
                            {t('pages.payment.pricing.info-box.deferred-payment.title', { currency, price })}
                            {isBelgian && ` ${t('constants.tax-free')}`}
                        </Typography>
                        <Typography variant="caps" className={classes.light}>
                            {caption ? caption : t('pages.payment.pricing.info-box.deferred-payment.caption')}
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography variant={'label1'} className={classes.dark}>
                            {t('pages.payment.pricing.info-box.renewal.title', {
                                currency,
                                payable_ha: payableHectares,
                                price_per_hectare: pricePerHectare,
                            })}
                            <ConditionalTooltip
                                text={t('billing.subscription.timeline.price-tooltip.deferred-payment.future-harvest', {
                                    currency,
                                    price_per_hectare: pricePerHectare,
                                    floor_price: floorPrice,
                                    ceiling_price: ceilingPrice,
                                })}
                            />
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: '14px' }} className={classes.medium}>
                            {t('pages.payment.pricing.info-box.renewal.description')}
                        </Typography>
                        <Typography variant="caps" className={classes.light}>
                            {t('pages.payment.pricing.info-box.renewal.caption')}
                        </Typography>
                    </>
                )}
            </Grid>
        </Grid>
    );
};
