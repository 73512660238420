import { CircularProgress } from '@soil-capital/ui-kit/material-core';

import useLoadingStyle from './LoaderFullScreen.style';
import { LoaderFullScreenPropsT } from './LoaderFullScreen.types';

export default ({
    isLoading,
    children,
    displayLoader = true,
    displayChildrenWhileLoading = false,
}: LoaderFullScreenPropsT): JSX.Element => {
    const { classes } = useLoadingStyle();

    return (
        <>
            {(!isLoading || displayChildrenWhileLoading) && children}
            <div
                data-testid="loaderContainer"
                className={`${classes.LoaderFullScreen} ${isLoading ? classes.loaderFullScreenLoading : ''}`}
            >
                {displayLoader && (
                    <CircularProgress data-testid="circular-loader" className={classes.loading} size="6rem" />
                )}
            </div>
        </>
    );
};
