import { Skeleton } from '@soil-capital/ui-kit/material-core';

const SkeletonRow = () => (
    <div>
        <Skeleton
            variant="rounded"
            height={58}
            sx={{
                marginBottom: '1rem',
            }}
        />
    </div>
);
const OperationPloughingFormSkeleton = () => {
    return (
        <div data-testid="operation-ploughing-form-skeleton">
            <SkeletonRow />
            <SkeletonRow />
        </div>
    );
};

export default OperationPloughingFormSkeleton;
