import { useWatch } from 'react-hook-form';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { WEEDING_MACHINE_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/weedingMachineFormSchema';

export const useWeedingMachineDepthInputVisibility = () => {
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });

    const machineryId = useWatch({ name: WEEDING_MACHINE_FORM_INPUT_NAMES.MACHINERY_ID });
    const machine = staticMachineryState.getById(machineryId);

    const shouldDisplayWeedingMachineDepthInput = machine?.depth === null;

    return { shouldDisplayWeedingMachineDepthInput };
};
