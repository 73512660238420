import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type RequestStatusTypesT = 'pending' | 'failed' | 'success';

export type SaveStatusStateT = {
    requests: Record<
        string,
        {
            id: string;
            status: RequestStatusTypesT;
        }
    >;
};

const initialState: SaveStatusStateT = {
    requests: {},
};

export default createSlice({
    name: 'saveStatus',
    initialState,
    reducers: {
        addRequestStatus: (state, action: PayloadAction<{ id: string; status: RequestStatusTypesT }>) => {
            state.requests[action.payload.id] = { id: action.payload.id, status: action.payload.status };
        },

        removeRequestStatus: (
            state,
            action: PayloadAction<{
                /** request ids to remove from state */
                requestIds?: string[];
                /** statuses to remove from state */
                statuses?: RequestStatusTypesT[];
            }>,
        ) => {
            const requestIdsToRemove = [...(action.payload.requestIds ?? [])];

            if (action.payload.statuses) {
                action.payload.statuses.forEach((status) => {
                    requestIdsToRemove.push(
                        ...Object.values(state.requests)
                            .filter((req) => req.status === status)
                            .map((req) => req.id),
                    );
                });
            }

            requestIdsToRemove.forEach((requestId) => {
                delete state.requests[requestId];
            });
        },
    },
});
