import useFieldMapSnapshotStyle from './useFieldMapSnapshot.style';
import { FieldMapSnapshotPropsT } from './FieldMapSnapshot.types';
import useFieldMapSnapshotLogic from '@modules/encoding/modules/technicalItinerary/components/FieldMapSnapshot/useFieldMapSnapshot.logic';
import { useRef } from 'react';

export default ({ children, farmSeasonId, fieldId, header, setFieldMapSnapshotIsLoading }: FieldMapSnapshotPropsT) => {
    const { classes } = useFieldMapSnapshotStyle();
    const element = useRef<HTMLDivElement>(null);

    const { image } = useFieldMapSnapshotLogic({
        element: element.current,
        farmSeasonId,
        fieldId,
        setFieldMapSnapshotIsLoading,
    });

    return (
        <div data-testid="field-map-snapshot" ref={element} className={classes.fieldMapSnapshot}>
            {header && <div className={classes.header}>{header}</div>}
            <div className={classes.snapshot}>
                <img
                    className={classes.snapshotBg + ' ' + classes.snapshotImage}
                    src={image}
                    alt="Field map snapshot"
                />
                <div className={classes.content}>{children}</div>
            </div>
        </div>
    );
};
