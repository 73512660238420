import { useSeedingForm } from '@modules/encoding/modules/technicalItinerary/module/seeding/hooks/useSeedingForm';
import { FormProvider } from 'react-hook-form';
import { SeedingQuantityInput } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingQuantityInput/SeedingQuantityInput';
import { useSeedingFormStyles } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingForm/SeedingForm.style';
import { SeedingMachineryInput } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingMachineryInput/SeedingMachineryInput';
import { SeedingMachineryDepthInput } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingMachineryDepthInput/SeedingMachineryDepthInput';
import { SeedingOriginInput } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingOriginInput/SeedingOriginInput';
import { SeedingFormSkeleton } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingForm/SeedingFormSkeleton';
import { SeedingSoilWorkWidthInput } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingSoilWorkWidthInput/SeedingSoilWorkWidthInput';
import { SeedingInterRowSpacingInput } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingInterRowSpacingInput/SeedingInterRowSpacingInput';

export const SeedingForm = () => {
    const { classes, cx } = useSeedingFormStyles();
    const { methods, onSubmit, options, isFormLoading, changeMachineryRelatedInputs } = useSeedingForm();

    const { seedingOrigins, seedingMachineries } = options;

    return (
        <>
            {isFormLoading ? <SeedingFormSkeleton /> : null}
            <FormProvider {...methods}>
                <form onSubmit={onSubmit} className={cx(classes.form, isFormLoading && classes.hiddenForm)}>
                    <SeedingOriginInput options={seedingOrigins} onChange={onSubmit} />
                    <SeedingQuantityInput onChange={onSubmit} />
                    <SeedingMachineryInput
                        options={seedingMachineries}
                        onChange={() => {
                            changeMachineryRelatedInputs();
                            onSubmit();
                        }}
                    />
                    <SeedingMachineryDepthInput onChange={onSubmit} />
                    <SeedingSoilWorkWidthInput onChange={onSubmit} />
                    <SeedingInterRowSpacingInput onChange={onSubmit} />
                </form>
            </FormProvider>
        </>
    );
};
