import { OperationEndpointParamsT, OperationT } from './operation.types';
import { createEntityAdapter } from '@reduxjs/toolkit';
import createCacheQueryHelper from '../createCacheQueryHelper';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import api from '@shared/api';

export const operationCacheAdapter = createEntityAdapter<OperationT>({
    sortComparer: getDefaultSortComparerById<OperationT>(),
});

const operationCacheQueryHelper = createCacheQueryHelper<OperationEndpointParamsT, OperationT, 'Operation'>(
    api,
    'Operation',
    operationCacheAdapter,
);

export default {
    transformResponse: operationCacheQueryHelper.transformResponse,
    add: operationCacheQueryHelper.add,
    update: operationCacheQueryHelper.update,
    remove: operationCacheQueryHelper.remove,
};
