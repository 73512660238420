import entities from '@shared/entities';
import { useGetCurrentOperationFromUrl } from '../../../../hooks/useGetCurrentOperationFromUrl';
import { useTranslation } from 'react-i18next';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { encodingShared } from '@modules/encoding/shared';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';

export const useSprayingFormActionPageLogic = () => {
    const { t } = useTranslation();
    const { fieldCropId } = useOperationRouteParams();
    const { operation } = useGetCurrentOperationFromUrl();

    /* ------------------------------- use states ------------------------------- */
    const { fieldState } = useGetFarmSeasonFields();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { cropState } = useGetFarmSeasonCrops();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { harvestYear, isLoading: harvestYearIsLoading } = useGetCurrentHarvestYear();

    /* ---------------------------- get current data ---------------------------- */
    const fieldCrop = fieldCropState.getById(fieldCropId);
    const field = fieldState.getById(fieldCrop?.farm_season_field_id);
    const crop = cropState.getById(fieldCrop?.farm_season_crop_id);
    const staticCrop = staticCropState.getById(crop?.crop_id);

    const isLoading =
        harvestYearIsLoading ||
        currentFarmLoading ||
        fieldState.isLoading ||
        cropState.isLoading ||
        staticOperationTypeState.isLoading;

    const operationTypeName = t(staticOperationTypeState.getById(operation?.operation_type_id)?.translation_slug ?? '');

    return {
        title: operationTypeName,
        fieldName: field?.name ?? '',
        cropName: t(`db.crop.slug-${staticCrop?.slug}`) ?? '',
        harvestYear,
        isLoading,
    };
};
