import { object, string, number, boolean } from 'yup';

export default object({
    crop_id: number().required(),
    yield: string().nullable(),
    residue: number().nullable(),
    is_accompanied: boolean().nullable(),
    accompanied_crop_id: number().nullable(),
    order: number().nullable(),
    coupled_id: string().nullable(),
});
