import { usePacFieldUpdateFormToasts } from '@modules/encoding/modules/rotation/components/PacImportedFields/usePacFieldUpdateFormToasts';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import {
    PAC_FIELD_UPDATE_INPUT_NAMES,
    usePacFieldUpdateForm,
} from '@modules/encoding/modules/rotation/components/PacImportedFields/usePacFieldUpdateForm';
import { useEffect } from 'react';

export const usePacFieldUpdateModalLogic = ({ fieldId, onClose }: { fieldId: number; onClose: () => void }) => {
    const { fieldState } = useGetFarmSeasonFields();
    const field = fieldState?.getById(fieldId) ?? null;

    const { onFieldUpdated } = usePacFieldUpdateFormToasts();
    const { methods, onSubmit, isLoading, errors, isValid } = usePacFieldUpdateForm({ field, onFieldUpdated });

    useEffect(() => {
        methods.setValue(PAC_FIELD_UPDATE_INPUT_NAMES.FIELD, field);
    }, [field, methods]);

    const onConfirm = async () => {
        await onSubmit();
        if (isValid) {
            onClose();
        }
    };

    return { field, methods, onConfirm, isUpdatingField: isLoading, errors };
};
