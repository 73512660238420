import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import { encodingRoutesConfig } from './encoding.routes';
import layout from './modules/layout';
import technicalItinerary from './modules/technicalItinerary';
import FadeTransition from './shared/components/FadeTransition/FadeTransition';
import { farmProfile } from './modules/farmProfile';
import { preparation } from './modules/preparation';
import { rotation } from './modules/rotation';
import { irrigation } from './modules/irrigation';
import { agroforestry } from './modules/agroforestry';
import { soilAnalysis } from './modules/soilAnalysis';
import { history } from './modules/history';
import './shared/components/FadeTransition/fade-transition.css';
import EncodingGuard from './shared/components/EncodingGuard/Encoding.guard';
import { EncodingDoneContentPage } from './pages/EncodingDoneContentPage';
import { MapProvider } from '@shared/map/utils/MapProvider';
import { MapProvider as MapProvider2 } from '@shared/map2';
import { NotFoundPanel } from '@shared/errors/pages/NotFoundPanel';
import { Guard } from '@shared/guards/Guard';
import { IsEncodingDoneGuard } from '@shared/guards/IsEncodingDoneGuard';

export default () => {
    const navigate = useNavigate();

    const routes = useRoutes([
        {
            path: `${encodingRoutesConfig.PREPARATION}/*`,
            element: <preparation.Component />,
        },
        {
            path: `${encodingRoutesConfig.FARM_PROFILE}/*`,
            element: <farmProfile.Component />,
        },
        {
            path: `${encodingRoutesConfig.ROTATION}/*`,
            element: <rotation.Component />,
        },
        {
            path: `${encodingRoutesConfig.TECHNICAL_ITINERARY}/*`,
            element: <technicalItinerary.Component />,
        },
        {
            path: `${encodingRoutesConfig.IRRIGATION}/*`,
            element: <irrigation.Component />,
        },
        {
            path: `${encodingRoutesConfig.AGROFORESTRY}/*`,
            element: <agroforestry.Component />,
        },
        {
            path: `${encodingRoutesConfig.SOIL_ANALYSIS}/*`,
            element: <soilAnalysis.Component />,
        },
        {
            path: `${encodingRoutesConfig.HISTORY}/*`,
            element: <history.Component />,
        },
        {
            path: encodingRoutesConfig.ENCODING_DONE,
            element: <Guard element={<EncodingDoneContentPage navigate={navigate} />} guards={[IsEncodingDoneGuard]} />,
        },

        { path: '/', element: <Navigate to={encodingRoutesConfig.PREPARATION} replace /> },
        {
            path: '*',
            element: <NotFoundPanel />,
        },
    ]);

    return (
        <EncodingGuard>
            <MapProvider>
                <MapProvider2>
                    <layout.Component>
                        <FadeTransition transitionKey={routes?.props?.match?.pathnameBase as string | undefined}>
                            {routes}
                        </FadeTransition>
                    </layout.Component>
                </MapProvider2>
            </MapProvider>
        </EncodingGuard>
    );
};
