import { FieldEndpointParamsT, FieldT } from './field.types';
import { createEntityAdapter } from '@reduxjs/toolkit';
import fieldCropCache from '../fieldCrop/fieldCrop.cache';
import createCacheQueryHelper from '../createCacheQueryHelper';
import api from '@shared/api';

export const fieldCacheAdapter = createEntityAdapter<FieldT>({
    sortComparer: (field1, field2) => field2.area - field1.area,
});
const fieldCacheQueryHelper = createCacheQueryHelper<FieldEndpointParamsT, FieldT, 'Field'>(
    api,
    'Field',
    fieldCacheAdapter,
);

const remove: typeof fieldCacheQueryHelper.remove = (cacheStrategy) => {
    return async ({ id, farmSeasonId }, apiContext) => {
        // Cascade
        fieldCropCache.removeWhere('farm_season_field_id', id, cacheStrategy)({ farmSeasonId }, apiContext);

        fieldCacheQueryHelper.remove(cacheStrategy)({ id, farmSeasonId }, apiContext);
    };
};

export default {
    transformResponse: fieldCacheQueryHelper.transformResponse,
    add: fieldCacheQueryHelper.add,
    update: fieldCacheQueryHelper.update<'cache-then-fetch'>, // generic who allow the update method to handle an additional optimistic strategy // TODO set allowed strategies
    remove,
};
