import { ConditionalTooltip } from '@soil-capital/ui-kit/components';
import { IconInfo } from '@soil-capital/ui-kit/icons';
import { Input } from '@soil-capital/ui-kit';
import { Box, Typography } from '@soil-capital/ui-kit/material-core';
import { useCreateMineralFertiliserFormStyles } from './CreateMineralFertiliserForm.style';
import { useTranslation } from 'react-i18next';
import { HelpBox } from '@soil-capital/ui-kit/components';
import {
    CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES,
    CustomStaticFertiliserFormValuesT,
} from '../../schema/customStaticFertiliserFormSchema';
import { useFormContext } from 'react-hook-form';
import { useEffect } from 'react';
import { computeCustomName } from '../CreateMineralFertiliserModal/createMineralFertiliserModalUtils';
import { useState } from 'react';

type CreateMineralFertiliserFormPropsT = {
    NPKErrors?: string;
    CaMgSErrors?: string;
    globalErrors?: string;
    defaultFertiliserNameComputed: string;
    textModalName: string;
};

export const CreateMineralFertiliserForm = (props: CreateMineralFertiliserFormPropsT) => {
    const { classes } = useCreateMineralFertiliserFormStyles();
    const { t } = useTranslation();
    const context = useFormContext();
    const [fertiliserName, setFertiliserName] = useState('');

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFertiliserName(event.target.value);
    };

    const N = context.watch(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.N);
    const P = context.watch(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.P);
    const K = context.watch(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.K);

    useEffect(() => {
        const computedName = computeCustomName(context.getValues() as CustomStaticFertiliserFormValuesT);
        setFertiliserName(computedName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [N, P, K]);

    return (
        <Box className={classes.createMineralFertiliserForm}>
            <Typography variant="body" className={classes.text}>
                {t('encoding-technical-itinerary.select-mineral-fertiliser.NPK')}
            </Typography>
            <div className={classes.row}>
                <div>
                    <Input
                        inputProps={{
                            ...context.register(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.N, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        suffix="N"
                        label="N"
                        size="small"
                        placeholder="%"
                    />
                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.select-mineral-fertiliser.N.tooltip.text')}
                        className={classes.tooltip}
                    >
                        <IconInfo fontSize="small" className={classes.icon} />
                    </ConditionalTooltip>
                </div>
                <div>
                    <Input
                        inputProps={{
                            ...context.register(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.P, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        suffix="P"
                        label="P"
                        placeholder="%"
                    />
                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.select-mineral-fertiliser.P.tooltip.text')}
                        className={classes.tooltip}
                    >
                        <IconInfo fontSize="small" className={classes.icon} />
                    </ConditionalTooltip>
                </div>
                <div>
                    <Input
                        inputProps={{
                            ...context.register(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.K, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        suffix="K"
                        label="K"
                        placeholder="%"
                    />
                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.select-mineral-fertiliser.K.tooltip.text')}
                        className={classes.tooltip}
                    >
                        <IconInfo fontSize="small" className={classes.icon} />
                    </ConditionalTooltip>
                </div>
            </div>
            {props.NPKErrors && <HelpBox color="error">{props.NPKErrors}</HelpBox>}
            <Typography variant="body" className={classes.text}>
                {t('encoding-technical-itinerary.select-mineral-fertiliser.CaMgS')}
            </Typography>
            <div className={classes.row}>
                <div>
                    <Input
                        inputProps={{
                            ...context.register(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.CA, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        suffix="Ca"
                        label="Ca"
                        placeholder="%"
                    />
                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.select-mineral-fertiliser.Ca.tooltip.text')}
                        className={classes.tooltip}
                    >
                        <IconInfo fontSize="small" className={classes.icon} />
                    </ConditionalTooltip>
                </div>
                <div>
                    <Input
                        inputProps={{
                            ...context.register(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.MG, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        suffix="Mg"
                        label="Mg"
                        placeholder="%"
                    />
                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.select-mineral-fertiliser.Mg.tooltip.text')}
                        className={classes.tooltip}
                    >
                        <IconInfo fontSize="small" className={classes.icon} />
                    </ConditionalTooltip>
                </div>
                <div>
                    <Input
                        inputProps={{
                            ...context.register(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.S, {
                                setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                            }),
                        }}
                        suffix="S"
                        label="S"
                        placeholder="%"
                    />
                    <ConditionalTooltip
                        title={t('encoding-technical-itinerary.select-mineral-fertiliser.S.tooltip.text')}
                        className={classes.tooltip}
                    >
                        <IconInfo fontSize="small" className={classes.icon} />
                    </ConditionalTooltip>
                </div>
            </div>
            {props.CaMgSErrors && <HelpBox color="error">{props.CaMgSErrors}</HelpBox>}
            <Typography variant="body" className={classes.text}>
                {props.textModalName}
            </Typography>
            <div className={classes.nameInput}>
                <Input
                    placeholder={props.defaultFertiliserNameComputed}
                    value={fertiliserName}
                    onChange={handleInputChange}
                    type="text"
                    label="name"
                    inputProps={{
                        ...context.register(CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES.FERTILISER_NAME, {
                            setValueAs: (v: string | null) => (v === '' || v === null ? null : v),
                        }),
                    }}
                />
            </div>
            <ConditionalTooltip
                title={t('encoding-technical-itinerary.select-mineral-fertiliser.name.tooltip.text')}
                className={classes.tooltip}
            >
                <IconInfo fontSize="small" className={classes.icon} />
            </ConditionalTooltip>
            {props.globalErrors && <HelpBox color="error">{props.globalErrors}</HelpBox>}
        </Box>
    );
};
