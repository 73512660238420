import { RadioButton, RadioGroup } from '@soil-capital/ui-kit/components';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBooleanRadioGroupStyle } from './useBooleanRadioGroupStyle';

type BooleanRadioGroupProps = {
    className?: string;
    name: string;
    onChange?: (value: 'true' | 'false') => void;
    disabled?: boolean;
};

export const BooleanRadioGroup = ({ className, name, onChange, disabled, ...rest }: BooleanRadioGroupProps) => {
    const { cx, classes } = useBooleanRadioGroupStyle();
    const { t } = useTranslation();
    const context = useFormContext();

    if (!context) throw new Error('BooleanRadioGroup should be used inside a FormProvider');

    return (
        <Controller
            control={context.control}
            name={name}
            defaultValue={null}
            render={({ field: { onChange: onFieldChange, value, ...field } }) => (
                <RadioGroup
                    className={cx(classes.radioGroupHorizontal, className)}
                    onChange={(_, v) => {
                        onFieldChange(v === 'true');
                        onChange?.(v as 'true' | 'false');
                    }}
                    value={typeof value === 'boolean' ? `${value}` : value}
                    {...rest}
                    {...field}
                >
                    <RadioButton disabled={disabled} value="true" label={t('constants.yes')} />
                    <RadioButton disabled={disabled} value="false" label={t('constants.no')} />
                </RadioGroup>
            )}
        />
    );
};
