import { makeStyles } from '@soil-capital/ui-kit/style';

const useMainScreenLayoutStyle = makeStyles()((theme) => ({
    container: {
        minWidth: '1200px',
        paddingLeft: '0 !important',
        paddingRight: '0 !important',
    },

    contentSection: {
        borderRadius: theme.shape.borderRadiusL,
        flex: '1',
        overflow: 'hidden',
    },
}));

export default useMainScreenLayoutStyle;
