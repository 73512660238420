import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { useForm } from '@shared/hooks';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePostCreateFarmMutation } from '@modules/onboarding/api/onboardingApi';
import { auth } from '@modules/auth';
import { useGetOnboardingFarmQuery } from '@modules/onboarding/api/onboardingApi';
import entities from '@shared/entities';
import { enqueueSnackbar } from 'notistack';

export const FARM = {
    ID: 'farm_id',
    NAME: 'name',
    AREA: 'eligibility_area',
    ADDRESS: 'address',
    POSTALCODE: 'postal_code',
    CITY: 'city',
    COUNTRY: 'country_id',
    PHONENUMBER: 'phone_number',
    LIVESTOCK: 'eligibility_has_livestock',
    PERMANENTPASTURES: 'eligibility_no_land_conversion',
} as const;

export const useEligibilityFormLogic = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [farmId, setFarmId] = useState<number | null>(null);
    const { staticCountryState } = entities.staticCountry.useState();
    const countries = staticCountryState.list;
    const { data: farmData, isLoading } = useGetOnboardingFarmQuery({ userId: auth.useAuthUser()?.id });

    const defaultValues = {
        [FARM.ID]: farmData?.id || farmId,
        [FARM.NAME]: farmData?.name || '',
        [FARM.AREA]: farmData?.eligibility_area.toString() || '',
        [FARM.ADDRESS]: farmData?.address || '',
        [FARM.POSTALCODE]: farmData?.postal_code || '',
        [FARM.CITY]: farmData?.city || '',
        [FARM.COUNTRY]: farmData?.country?.id || undefined,
        [FARM.PHONENUMBER]: farmData?.phone_number || '',
        [FARM.LIVESTOCK]: farmData?.eligibility_has_livestock || true,
        [FARM.PERMANENTPASTURES]: farmData?.eligibility_no_land_conversion || false,
    };

    const schema = z.object({
        [FARM.ID]: z.number().nullable(),
        [FARM.NAME]: z.string().min(2, t('pages.onboarding.eligibility.farm-name.error')),
        [FARM.AREA]: z.string().refine((value) => Number(value) > 0, {
            message: t('pages.onboarding.eligibility.cultivated-area.error'),
        }),
        [FARM.ADDRESS]: z.string().min(2, t('pages.onboarding.eligibility.street-number.error')),
        [FARM.POSTALCODE]: z.string().min(2, t('pages.onboarding.eligibility.postal-code.error')),
        [FARM.CITY]: z.string().min(2, t('pages.onboarding.eligibility.city.error')),
        [FARM.COUNTRY]: z.number({ message: t('pages.onboarding.eligibility.country.error') }),
        [FARM.PHONENUMBER]: z.string().min(2, t('pages.onboarding.eligibility.phone-number.error')),
        [FARM.LIVESTOCK]: z.boolean(),
        [FARM.PERMANENTPASTURES]: z.boolean(),
    });
    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const {
        handleSubmit,
        formState: { isValid, errors },
        watch,
        setValue,
    } = methods;
    const [postCreateFarm, { isLoading: isPostFormLoading }] = usePostCreateFarmMutation();

    const onSubmit = handleSubmit(async (data) => {
        const response = await postCreateFarm({ body: data });

        if ('data' in response && response.data) {
            setFarmId(response.data.farm.id);
            updateUserInfos({
                latestActiveSeason: null,
                latestFarmSeason: response.data.farmSeason.id,
            });
            if (response.data.eligibility_status === 'eligible') {
                navigate('eligible');
            } else if (response.data.eligibility_status === 'requires_livestock_details') {
                navigate('livestock');
            } else if (response.data.eligibility_status === 'not_eligible_country') {
                navigate('country-non-eligible');
            } else if (response.data.eligibility_status === 'not_eligible_no_invitation') {
                navigate('/onboarding/invitation');
            } else if (response.data.eligibility_status === 'not_eligible_excessive_livestock') {
                navigate('livestock-non-eligible');
            } else {
                navigate('non-eligible');
            }
        } else {
            enqueueSnackbar(t('pages.onboarding.eligibility.error'), { variant: 'error' });
        }
    });
    const { updateUserInfos } = auth.useUpdateUserInfos();

    const selectedCountry = countries.find((country) => country.id === Number(watch(FARM.COUNTRY))) || null;
    useEffect(() => {
        if (farmData) {
            setValue('farm_id', farmData.id);
            setValue('name', farmData.name);
            setValue('eligibility_area', farmData.eligibility_area.toString());
            setValue('address', farmData.address);
            setValue('postal_code', farmData.postal_code);
            setValue('city', farmData.city);
            setValue('country_id', farmData.country.id);
            setValue('phone_number', farmData.phone_number);
            setValue('eligibility_has_livestock', farmData.eligibility_has_livestock);
            setValue('eligibility_no_land_conversion', farmData.eligibility_no_land_conversion);
        }
    }, [farmData, setValue]);

    return {
        onSubmit,
        isValid,
        errors,
        methods,
        password,
        setPassword,
        countries,
        selectedCountry,
        isPostFormLoading,
        defaultValues,
        isLoading,
    };
};
