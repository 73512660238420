import auth from '@modules/auth/store/authSelectors';
import languageConfig from '@shared/constants/languageConfig';
import { StaticLanguageT } from '@shared/entities/staticLanguage/staticLanguage.types';
import staticLanguage from '@shared/entities/staticLanguage';
import i18next from 'i18next';

export default () => {
    const userLanguageId = auth.useAuthUser()?.language_id;
    const { staticLanguageState } = staticLanguage.useState();

    let currentLang: StaticLanguageT | undefined;

    if (userLanguageId && staticLanguageState.list.length) {
        currentLang = staticLanguageState.getById(userLanguageId);
    } else {
        const browserLanguage = languageConfig.languages.includes(navigator.language.substring(0, 2))
            ? navigator.language.substring(0, 2)
            : null;

        const platformLanguage =
            i18next?.language?.substring(0, 2) || browserLanguage || languageConfig.defaultLanguage;
        currentLang = staticLanguageState.list.length
            ? staticLanguageState.getByIsoCode(platformLanguage)
            : { iso_code: platformLanguage, id: 0, name: 'English', translated: 0 };
    }

    return currentLang;
};
