import { PaymentSteps } from './components/PaymentSteps/PaymentSteps';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { IconArrowLeft, IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Button } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { usePaymentStepModalStyle } from './PaymentStepModal.style';
import { ReactNode } from 'react';

export const PaymentStepModal = ({
    children,
    stepProgress,
    currency,
    ctaAction,
    ctaText,
    isCTAdisabled,
    onBackClick,
    hideBackButton,
    isCTAloading,
}: {
    children: ReactNode;
    stepProgress: number;
    currency?: string;
    ctaAction?: () => void;
    ctaText?: string;
    isCTAdisabled?: boolean;
    onBackClick?: () => void;
    hideBackButton?: boolean;
    isCTAloading?: boolean;
}) => {
    const { t } = useTranslation();
    const { classes } = usePaymentStepModalStyle();

    return (
        <Grid className={classes.container}>
            {stepProgress < 4 && (
                <>
                    <Grid display="flex" justifyContent="space-between" alignContent="center">
                        {!hideBackButton && (
                            <Button
                                variant="text"
                                startIcon={<IconArrowLeft />}
                                onClick={onBackClick}
                                sx={{ alignSelf: 'flex-start' }}
                            >
                                {t('constants.back')}
                            </Button>
                        )}
                        {ctaAction && ctaText && (
                            <Button
                                variant="contained"
                                onClick={ctaAction}
                                endIcon={<IconArrowRight />}
                                disabled={isCTAdisabled}
                                loading={isCTAloading}
                            >
                                {t(`${ctaText}`)}
                            </Button>
                        )}
                    </Grid>
                    <PaymentSteps stepProgress={stepProgress} currency={currency} />
                </>
            )}
            {children}
        </Grid>
    );
};
