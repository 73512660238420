import { auth } from '@modules/auth';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default ({ children }: { children: React.JSX.Element }): React.JSX.Element => {
    const navigate = useNavigate();

    const latestActiveSeasonId = auth.useLatestActiveSeasonId();
    // if no seasonId, Redirect to the same route but with the /:seasonId in addition
    useEffect(() => {
        if (latestActiveSeasonId) {
            navigate(`/${latestActiveSeasonId}`);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children;
};
