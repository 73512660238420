import { useState } from 'react';

export const useToggleWithPayload = <T>() => {
    const [open, setOpen] = useState(false);
    const [payload, setPayload] = useState<T | null>(null);

    const handleOpen = (data: T) => {
        setOpen(true);
        setPayload(data);
    };
    const handleClose = () => {
        setOpen(false);
        setPayload(null);
    };

    return {
        open,
        payload,
        handleOpen,
        handleClose,
    };
};
