import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { RadioGroup, RadioGroupOptionT } from '@shared/components/form/RadioGroup/RadioGroup';
import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';
import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';

type SeedingOriginInputProps = {
    options: RadioGroupOptionT[];
    onChange: () => void;
};

export const SeedingOriginInput = ({ options, onChange }: SeedingOriginInputProps) => {
    const { t } = useTranslation();

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.seeding.question.seed-origin')}
                tooltipTitleKey="encoding-technical-itinerary.seeding.question.seed-origin.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.seeding.question.seed-origin.tooltip.text"
            />
            <RadioGroup
                type="number"
                name={SEEDING_FORM_INPUT_NAMES.SEEDING_ORIGIN}
                options={options}
                onChange={onChange}
            />
        </FormRow>
    );
};
