import { useParams } from 'react-router-dom';
import { useGetDuplicationFieldCropData } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';

export const useGetSourceField = () => {
    const { seasonId: seasonIdParam, fieldCropId: fieldCropIdParam } = useParams();
    const seasonId = Number(seasonIdParam);
    const fieldCropId = Number(fieldCropIdParam);
    const { getComputedField, isLoading } = useGetDuplicationFieldCropData({ farmSeasonId: seasonId });

    return {
        sourceField: getComputedField(fieldCropId),
        isLoading,
    };
};
