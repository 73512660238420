import { makeStyles } from '@soil-capital/ui-kit/style';

export const useCreateMineralFertiliserModalStyle = makeStyles()((theme) => ({
    fertiliserSuggestion: {
        marginBottom: theme.spacing(4),
    },
    link: {
        textDecoration: 'underline',
        cursor: 'pointer',
    },
}));
