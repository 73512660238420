import { IconCreditCard, IconPen } from '@soil-capital/ui-kit/icons';
import { Typography, Grid, Skeleton } from '@soil-capital/ui-kit/material-core';
import usePaymentInfoCardStyle from './usePaymentInfoCard.style';
import { useTranslation } from 'react-i18next';
import { Button, Loader } from '@soil-capital/ui-kit/components';
import { usePaymentInfoCardLogic } from './usePaymentInfoCardLogic';

export const PaymentInfoCard = ({ farmId }: { farmId: number }) => {
    const { classes } = usePaymentInfoCardStyle();

    const { t } = useTranslation();

    const {
        handleBillingPortalSession,
        displayedCardNumber,
        isLoading,
        isRedirectUrlLoading,
        paymentMethod,
        isReadOnly,
    } = usePaymentInfoCardLogic(farmId);

    return isLoading ? (
        <Grid>
            <Skeleton className={classes.skeleton} data-testId="skeleton" />
        </Grid>
    ) : (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <IconCreditCard />
                <Typography className={classes.title} variant="h2">
                    {t('billing.subscription.payment-info.title')}
                </Typography>
                <Button className={classes.penIcon} onClick={handleBillingPortalSession} disabled={isReadOnly}>
                    {isRedirectUrlLoading ? <Loader sx={{ maxWidth: '24px', maxHeight: '24px' }} /> : <IconPen />}
                </Button>
            </div>
            <div className={classes.content}>
                <Typography variant="body" color="dark">
                    {paymentMethod?.type === 'sepa_debit'
                        ? t('billing.subscription.payment-info.sepa')
                        : t('billing.subscription.payment-info.credit-card')}
                </Typography>
                <Typography variant="body" color="dark">
                    {displayedCardNumber}
                </Typography>
            </div>
        </div>
    );
};
