import { ReactNode } from 'react';
import { MediaInfoBlock } from '@soil-capital/ui-kit/MediaInfoBlock';
import { useTranslation } from 'react-i18next';

export type CropInfoBoxProps = {
    cropName: string;
    numberOfAssignedFields: number;
    assignedFieldsTotalArea: string;
    icon?: ReactNode;
    cropImage: string | null;
    cropImageSize?: 'small' | 'medium' | 'large';
    className?: string;
};

export const CropInfoBox = ({
    cropName,
    numberOfAssignedFields,
    assignedFieldsTotalArea,
    icon,
    cropImage,
    cropImageSize = 'medium',
    className,
}: CropInfoBoxProps) => {
    const { t } = useTranslation();

    return (
        <MediaInfoBlock
            Title={<MediaInfoBlock.Title text={cropName} />}
            Subtitle={
                <>
                    <MediaInfoBlock.Subtitle
                        text={`${numberOfAssignedFields} ${t('constants.field', {
                            count: numberOfAssignedFields,
                        })}`}
                    />
                    <MediaInfoBlock.Subtitle text={`${assignedFieldsTotalArea} HA`} />
                </>
            }
            Media={<MediaInfoBlock.Image src={cropImage} size={cropImageSize} />}
            Icon={icon}
            className={className}
        />
    );
};
