import { createEntityAdapter } from '@reduxjs/toolkit';
import { FieldCropEndpointParamsT, FieldCropT } from './fieldCrop.types';
import createCacheQueryHelper from '../createCacheQueryHelper';
import api from '@shared/api';

export const fieldCropCacheAdapter = createEntityAdapter<FieldCropT>();
const fieldCropCacheQueryHelper = createCacheQueryHelper<FieldCropEndpointParamsT, FieldCropT, 'FieldCrop'>(
    api,
    'FieldCrop',
    fieldCropCacheAdapter,
);

export default {
    transformResponse: fieldCropCacheQueryHelper.transformResponse,
    add: fieldCropCacheQueryHelper.add,
    update: fieldCropCacheQueryHelper.update<'cache-then-fetch' | 'cache-only'>,
    removeWhere: fieldCropCacheQueryHelper.removeWhere,
    remove: fieldCropCacheQueryHelper.remove,
};
