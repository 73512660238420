import { ReactNode } from 'react';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { useChangePasswordLayoutStyle } from './useChangePasswordLayout.style';
import { Header } from '@shared/components/Header/Header';
import { ScrollSection } from '@soil-capital/ui-kit/components';
import { Logo } from '@shared/components/Logo/Logo';
import { useNavigate } from 'react-router-dom';

export default ({ children }: { children: ReactNode }) => {
    const { classes } = useChangePasswordLayoutStyle();
    const navigate = useNavigate();

    return (
        <ScrollSection spacingX={1} className={classes.container}>
            <Grid px={1}>
                <Header
                    leftElement={<></>}
                    middleElement={<Logo onClick={() => navigate('/login')} />}
                    rightElement={<></>}
                />
            </Grid>
            <Grid className={classes.contentSection}>
                <Grid className={classes.modal}>{children}</Grid>
                <div className={classes.fieldBg}></div>
            </Grid>
        </ScrollSection>
    );
};
