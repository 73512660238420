import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';

export const useResultsStepsProgress = () => {
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();

    let currentStep = 1;

    // we check if the last step is done, if so we set the max step available.
    if (!!currentSeason?.has_results_published) currentStep = 4;
    // then we decrease according to previous steps undone to be "conservative".
    else currentStep = 3;
    if (!currentSeason?.has_results) currentStep = 2;
    if (!currentSeason?.summary_validated_at) currentStep = 1;

    return { currentStep, isLoading: currentSeasonLoading };
};
