import { z } from 'zod';
import { OperationFormSchema } from './OperationFormSchema';
import { WeedingMachineFormDataT } from '../api/operationFormApi.types';

export const weedingMachineFormSchema = new OperationFormSchema(
    {
        machinery_id: z.number().nullable(),
        passage: z.number().nullable(),
        depth: z.number().nullable(),
    },
    (apidata?: WeedingMachineFormDataT) => {
        return {
            machinery_id: apidata?.machinery_id || null,
            passage: apidata?.passage || null,
            depth: apidata?.depth || null,
        };
    },
);

export const WEEDING_MACHINE_FORM_INPUT_NAMES = weedingMachineFormSchema.INPUT_NAMES;

export type WeedingMachineFormSchemaT = z.infer<typeof weedingMachineFormSchema.schema>;
