import { zodResolver } from '@hookform/resolvers/zod';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { UseFormProps, useForm as useFormRHF } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as z from 'zod';
import { useLogErrorMutation } from '@shared/api/logErrorApi';

export const useForm = <TSchema extends z.ZodType>(
    props: Omit<UseFormProps<TSchema['_input']>, 'resolver'> & {
        schema: TSchema;
        /** if the validation errors are displayed in the form, you should pass false.
         * The purpose is to make sure the user is aware about a "unsavable form". It also log the error to the BE/slack
         */
        displaySnackbarOnSchemaError?: boolean;
    },
) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const [logError] = useLogErrorMutation();

    const methods = useFormRHF<TSchema['_input']>({
        resolver: zodResolver(props.schema, undefined),
        shouldFocusError: false,
        ...props,
    });

    useEffect(() => {
        if (!props.displaySnackbarOnSchemaError) {
            return;
        }
        const hasErrors = Object.keys(methods.formState.errors).length > 0;

        if (hasErrors) {
            const errorMessages = ['Context: ' + 'Frontend forms', 'URL: ' + window.location.href];
            const errors = Object.values(methods.formState.errors).map((error) => {
                return 'Message: ' + error?.message + ' - Type: ' + error?.type;
            });
            errorMessages.push(...errors);
            const formValues = methods.getValues();
            errorMessages.push('Form values: ' + JSON.stringify(formValues));

            logError({ errors: errorMessages });
            enqueueSnackbar(t('encoding-technical-itinerary.form.error-message'), {
                variant: 'error',
                snackbarAction: {
                    label: t('constants.reload'),
                    onClick: () => window.location.reload(),
                },
            });
        }
    }, [enqueueSnackbar, logError, methods, methods.formState.errors, t, props.displaySnackbarOnSchemaError]);

    return methods;
};
