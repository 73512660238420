import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useHasAtLeastTwoFieldsForACrop } from '@shared/guards/HasAtLeastTwoFieldsForCropGuard';
import { IconLightning } from '@soil-capital/ui-kit/icons';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useLocation } from 'react-router-dom';
import { useGetOperationDateString } from '../../hooks/useGetOperationDateString';
import { useGetSeedingOperationTitle } from '../../hooks/useGetSeedingOperationTitle';
import { useTechnicalItineraryNavigation } from '../../hooks/useTechnicalItineraryNavigation';
import technicalItineraryRoutes from '../../technicalItinerary.routes';
import { OperationTimelineNavLogicPropsT, TimelineItemT } from './OperationTimelineNav.types';
import { useGetOperationTimelineItemsProperties } from './useGetOperationTimelineItemsProperties';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export default ({
    dataContext,
    operationId,
    fieldCropId,
    navigate,
    setTimelineNavIsLoading,
}: OperationTimelineNavLogicPropsT) => {
    const { t } = useTranslation();
    const { getOperationDateString } = useGetOperationDateString();
    const { getSeedingOperationTitle } = useGetSeedingOperationTitle();
    const { navigateToTechnicalItineraryPage } = useTechnicalItineraryNavigation();
    const hasAtLeastTwoFieldsForACrop = useHasAtLeastTwoFieldsForACrop();

    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { operationState } = entities.operation.useState({
        farmSeasonId: currentSeasonId,
        farmSeasonFieldCropId: fieldCropId,
    });
    const { pathname } = useLocation();
    const basePath = `/${currentSeasonId}/${encodingRoutesConfig.TECHNICAL_ITINERARY}/`;
    const { isActiveLink, isDisabled, getStatus, getTitle, getSubTitle, getTooltip, getOperationId } =
        useGetOperationTimelineItemsProperties(
            currentSeasonId,
            fieldCropId,
            operationId,
            dataContext.progressDetailItems,
        );
    const { readOnly } = useFarmSeasonReadOnly();

    const timelineItems: TimelineItemT[] = useMemo(() => {
        const progressDetailTimelineItems = [...dataContext.progressDetailItems].map((progressDetail) => {
            const isTimelineItemDisabled = isDisabled(progressDetail.category, progressDetail.operationTypeId);

            const timelineItem: TimelineItemT = {
                status: getStatus(progressDetail),
                children: {
                    operationId: getOperationId(progressDetail),
                    title: getTitle(progressDetail),
                    subtitle: getSubTitle(progressDetail),
                    active: isActiveLink(progressDetail.operationId, progressDetail.category),
                    disabled: isTimelineItemDisabled,
                    category: progressDetail.category,
                    tooltip: isTimelineItemDisabled ? getTooltip(progressDetail) : '',
                },
            };

            return timelineItem;
        });

        // Add the duplication step if at least 1 operation (+ global characteristics and operations timeline)
        if (progressDetailTimelineItems.length > 2 && hasAtLeastTwoFieldsForACrop) {
            const isDuplicationDisabled = isDisabled('duplication');
            progressDetailTimelineItems.push({
                status: isDuplicationDisabled ? 'disabled' : 'not-started',
                children: {
                    operationId: undefined,
                    title: t('encoding-technical-itinerary.contextual-panel.duplication'),
                    subtitle: '',
                    active:
                        matchPath(
                            basePath + technicalItineraryRoutes.operationDuplication({ fieldCropId }),
                            pathname,
                        ) !== null,
                    disabled: isDuplicationDisabled || readOnly,
                    category: 'duplication',
                    icon: <IconLightning />,
                    tooltip: '',
                },
            });
        }

        return progressDetailTimelineItems;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        dataContext.progressDetailItems,
        getOperationDateString,
        getSeedingOperationTitle,
        staticOperationTypeState,
        t,
    ]);

    const navigateToOperationForm = (operationId: number | undefined, category: string) => {
        const operation = operationState.getById(
            dataContext.progressDetailItems?.find((progressDetail) => progressDetail.operationId === operationId)
                ?.operationId,
        );
        navigateToTechnicalItineraryPage(fieldCropId, navigate, operation, category);
    };

    // Scroll active item into the view:
    useEffect(() => {
        const timeout = setTimeout(() => {
            const navItem = document.getElementById(`active-item-to-scroll-into-the-view`);
            if (navItem) {
                navItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
            }
        }, 10);
        return () => clearTimeout(timeout);
        // `timelineItems` dependancy is necessary because id "active-item-to-scroll-into-the-view" depend on it
    }, [operationId, timelineItems]);

    setTimelineNavIsLoading(dataContext.isLoading);

    return {
        timelineItems,
        navigateToOperationForm,
    };
};
