import api from './index';
import { SegmentTrackPayloadT } from './segmentApi.types';

export const segmentApi = api.injectEndpoints({
    endpoints: (builder) => ({
        track: builder.mutation<null, SegmentTrackPayloadT>({
            query: (body) => ({
                url: `/v3/track`,
                method: 'POST',
                body,
            }),
        }),
    }),
});

export const { useTrackMutation } = segmentApi;
