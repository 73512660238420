import { useContext, useEffect, useMemo } from 'react';
import { MapContext } from '@shared/map/utils/MapProvider';
import { DuplicationFieldT } from '../../../shared/hooks/useGetDuplicationFieldCropData';
import { usePolygon } from '@shared/map/hook/usePolygonHook';

export const useOperationDuplicationRightPanelMap = (
    sourceField: DuplicationFieldT,
    targetFields: DuplicationFieldT[],
    fieldCropIds: number[],
) => {
    const mapContext = useContext(MapContext);
    if (!mapContext) {
        throw new Error('MapContext is null');
    }
    const allFields: DuplicationFieldT[] = useMemo(
        () => [sourceField, ...targetFields],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [targetFields.length],
    );

    const { recenterMapOnPolygons, setSelectedFields, setFields } = mapContext || {};

    const memoizedSelectedFields = useMemo(
        () => targetFields.filter((field) => fieldCropIds.includes(field.fieldCrop.id)),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [fieldCropIds.length],
    );

    useEffect(
        () => {
            if (setFields) {
                setFields(allFields);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [allFields],
    );

    useEffect(
        () => {
            if (setSelectedFields) {
                setSelectedFields(memoizedSelectedFields);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [memoizedSelectedFields],
    );

    usePolygon(true, sourceField);

    return { recenterMapOnPolygons };
};
