import { makeStyles } from '@soil-capital/ui-kit/style';

const useLoadingViewStyle = makeStyles()((theme) => ({
    LoaderFullScreen: {
        height: '100vh',
        width: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        opacity: 0,
        transition: `${theme.transitions.easing.easeIn} opacity ${theme.transitions.duration.leavingScreen}ms, visibility ${theme.transitions.duration.leavingScreen}ms`,
        visibility: 'hidden',
        background: 'white',
        zIndex: 1000,
    },
    loaderFullScreenLoading: {
        opacity: 1,
        visibility: 'visible',
    },
    loading: {
        color: theme.palette.primary.main,
    },
}));

export default useLoadingViewStyle;
