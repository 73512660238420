import { useFormContext } from 'react-hook-form';
import { useOperationDuplicationFormValues } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useOperationDuplicationFormValues';

export const useCanConfirmDuplication = () => {
    const context = useFormContext();

    if (!context) throw new Error('useCanConfirmDuplication must be used inside a FormProvider');

    const { fieldCropIds, operationIds, hasSameOrganic, hasSameTillageId, hasSameCompanionCrop } =
        useOperationDuplicationFormValues();

    return (
        fieldCropIds.length > 0 &&
        (operationIds.length > 0 || hasSameOrganic || hasSameTillageId || hasSameCompanionCrop)
    );
};
