import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFullscreenModalStyles = makeStyles()((theme) => ({
    modal: {
        width: '100%',
        height: '100%',
    },
    modalClose: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row-reverse',
    },
    body: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        padding: theme.spacing(1),
        height: '100%',
        overflow: 'hidden',
        backgroundColor: theme.palette.primaryScale[900],
        '& > *': {
            minWidth: '25%',
        },
        '& > :last-child': {
            borderRadius: '0 8px 8px 0',
        },
    },
}));
