/* eslint-disable @typescript-eslint/no-explicit-any */
import { coverCropFormSchema } from './coverCropFormSchema';
import { deepWorkFormSchema } from './deepWorkFormSchema';
import { destructionFormSchema } from './destructionFormSchema';
import { harvestFormSchema } from './harvestFormSchema';
import { mineralFertilisationFormSchema } from './mineralFertilisationFormSchema';
import { organicFertilisationFormSchema } from './organicFertilisationSchema';
import { ploughingFormSchema } from './ploughingFormSchema';
import { seedingFormSchema } from './seedingFormSchema';
import { shallowWorkFormSchema } from './shallowWorkFormSchema';
import { sprayingFormSchema } from './sprayingFormschema';
import { weedingMachineFormSchema } from './weedingMachineFormSchema';

export const operationFormSchemaMap = {
    // [OPERATION_TYPE_SLUGS.SOIL_WORK_ROLLER]: , // no form and no api
    // [OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION]: , // no form and no api
    'cover-crop': coverCropFormSchema,
    'mineral-fertilisation': mineralFertilisationFormSchema,
    'organic-fertilisation': organicFertilisationFormSchema,
    harvest: harvestFormSchema,
    destruction: destructionFormSchema,
    seeding: seedingFormSchema,
    'deep-work': deepWorkFormSchema,
    ploughing: ploughingFormSchema,
    'shallow-work': shallowWorkFormSchema,
    'weeding-machine': weedingMachineFormSchema,
    spraying: sprayingFormSchema,
} as const;

export type OperationFormSchemaMapT = typeof operationFormSchemaMap;
type OperationFormSchemaMapKeysT = keyof OperationFormSchemaMapT;

export const getOperationFormSchema = <OperationTypeT extends OperationFormSchemaMapKeysT>(
    operationTypeSlug: OperationTypeT,
) => operationFormSchemaMap[operationTypeSlug];
