import { makeStyles } from '@soil-capital/ui-kit/style';

export const useRegenagFaqStyle = makeStyles()((theme) => ({
    container: {
        padding: theme.spacing(3),
        background: theme.palette.darkScale[50],
        borderRadius: theme.shape.borderRadius,
    },
    title: {
        marginBottom: theme.spacing(3),
    },
    description: {
        marginBottom: theme.spacing(4),
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),

        '&:not(:last-child)': {
            marginBottom: theme.spacing(4),
        },
    },
    sectionSubtitle: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    accordion: {
        boxShadow: 'none',
    },
    content: {
        whiteSpace: 'pre-wrap',
    },
}));
