import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import OperationShallowWorkMachineForm from '@modules/encoding/modules/technicalItinerary/components/OperationShallowWorkMachineForm/OperationShallowWorkMachineForm';

export default ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();

    return (
        <encodingShared.OperationFormActionPage
            title={t('encoding-technical-itinerary.shallow-work-machine.title')}
            navigate={navigate}
        >
            <OperationShallowWorkMachineForm />
        </encodingShared.OperationFormActionPage>
    );
};
