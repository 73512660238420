import {
    FinalizeOperationsTimelineRequestT,
    FinalizeOperationsTimelineResponseT,
    OperationBodyT,
    OperationEndpointParamsT,
    OperationT,
} from './operation.types';
import { EntityState } from '@reduxjs/toolkit';
import operationCache from './operation.cache';
import api from '@shared/api';

const url = (farmSeasonId?: number, farmSeasonFieldCropId?: number) =>
    `/v3/farm-seasons/${farmSeasonId}/field-crops/${farmSeasonFieldCropId}/operations`;

export const operationApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getFarmSeasonOperations: build.query<EntityState<OperationT>, { farmSeasonId?: number }>({
            query: ({ farmSeasonId }) => ({
                url: `/v3/farm-seasons/${farmSeasonId}/operations`,
                method: 'GET',
            }),
            providesTags: ['Operations'],
            transformResponse: operationCache.transformResponse,
        }),
        getOperation: build.query<EntityState<OperationT>, OperationEndpointParamsT>({
            query: ({ farmSeasonFieldCropId, farmSeasonId }) => ({
                url: url(farmSeasonId, farmSeasonFieldCropId),
                method: 'GET',
            }),
            transformResponse: operationCache.transformResponse,
            providesTags: ['Operation'],
        }),
        createOperation: build.mutation<OperationT, OperationEndpointParamsT & { body: OperationBodyT }>({
            query: ({ body, farmSeasonId, farmSeasonFieldCropId }) => ({
                url: url(farmSeasonId, farmSeasonFieldCropId),
                method: 'POST',
                body,
            }),
            onQueryStarted: operationCache.add('cache-then-fetch'),
            invalidatesTags: ['Progress'],
        }),

        updateOperation: build.mutation<OperationT, OperationEndpointParamsT & { body: OperationBodyT; id: number }>({
            query: ({ body, farmSeasonId, farmSeasonFieldCropId, id }) => ({
                url: `${url(farmSeasonId, farmSeasonFieldCropId)}/${id}`,
                method: 'PUT',
                body,
            }),
            onQueryStarted: operationCache.update('cache-then-fetch'),
            invalidatesTags: ['Progress'],
        }),

        deleteOperation: build.mutation<void, OperationEndpointParamsT & { id: number }>({
            query: ({ farmSeasonId, farmSeasonFieldCropId, id }) => ({
                url: `${url(farmSeasonId, farmSeasonFieldCropId)}/${id}`,
                method: 'DELETE',
            }),
            onQueryStarted: operationCache.remove('cache-only'),
            invalidatesTags: ['Progress'],
        }),

        finalizeOperationsTimeline: build.mutation<
            FinalizeOperationsTimelineResponseT,
            FinalizeOperationsTimelineRequestT
        >({
            query: ({ farmSeasonId, id }) => ({
                url: `${url(farmSeasonId, id)}/finalize`,
                method: 'POST',
            }),
            invalidatesTags: ['Progress', 'Operation'],
        }),
    }),
});

export default {
    useGetAll: operationApiInstance.useGetFarmSeasonOperationsQuery,
    useGet: operationApiInstance.useGetOperationQuery,
    useCreate: operationApiInstance.useCreateOperationMutation,
    useUpdate: operationApiInstance.useUpdateOperationMutation,
    useDelete: operationApiInstance.useDeleteOperationMutation,
    useFinalizeOperationsTimeline: operationApiInstance.useFinalizeOperationsTimelineMutation,
};
