import {
    useGetFarmSeasonPaymentStatusQuery,
    useGetNextFarmSeasonProductPlanQuery,
} from '@modules/payment/api/paymentApi';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Stripe, loadStripe } from '@stripe/stripe-js';

export const useProcessingLogic = () => {
    const { currentSeason } = useCurrentSeason();
    const navigate = useNavigate();
    const [stripePromise, setStripePromise] = useState<Promise<Stripe | null> | undefined>();
    const { data: nextFarmSeasonProductPlan, isLoading: isNextFarmSeasonProductPlanLoading } =
        useGetNextFarmSeasonProductPlanQuery({
            farmSeasonId: currentSeason?.id,
        });
    const stripeSecret = process.env.REACT_APP_STRIPE_API_KEY;
    const farmSeasonId = nextFarmSeasonProductPlan?.farm_season_id
        ? nextFarmSeasonProductPlan?.farm_season_id
        : isNextFarmSeasonProductPlanLoading
        ? undefined
        : currentSeason?.id;
    const {
        data: paymentStatus,
        refetch,
        isFetching,
    } = useGetFarmSeasonPaymentStatusQuery({
        farmSeasonId: farmSeasonId,
    });
    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;

        if ((paymentStatus?.status === 'active' && paymentStatus?.paid_at) || paymentStatus?.status === 'pending') {
            navigate(`/${currentSeason?.id}/payment/confirmation`, { replace: true });
        } else if (paymentStatus?.status === 'payment_failed' && !isFetching) {
            navigate(`/${currentSeason?.id}/payment/processing/failed`, { replace: true });
        } else if (
            paymentStatus?.status === 'payment_in_progress' ||
            paymentStatus?.status === 'to_pay' ||
            !paymentStatus?.paid_at
        ) {
            interval = setInterval(() => {
                refetch();
            }, 3000);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [paymentStatus, navigate, currentSeason?.id, refetch, isFetching]);

    useEffect(() => {
        if (!stripeSecret) return;
        const promise = loadStripe(stripeSecret);
        setStripePromise(promise);
    }, [stripeSecret]);

    return {
        stripePromise,
        farmSeasonId,
    };
};
