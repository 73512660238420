import { useEffect, useState } from 'react';
import { IntroProgressItemLogicPropsT } from './IntroProgressItem.types';

export default ({ onClick, openDefault = false }: IntroProgressItemLogicPropsT) => {
    const [isCollapseOpen, setIsCollapseOpen] = useState(openDefault);

    useEffect(() => {
        setIsCollapseOpen(openDefault);
    }, [openDefault]);

    const handleClickedItem = () => {
        setIsCollapseOpen((isOpen) => !isOpen);
        onClick?.();
    };

    return {
        isCollapseOpen,
        handleClickedItem,
    };
};
