import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { usePricingCardStyle } from './PricingCard.style';
import { ConditionalTooltip, Button } from '@soil-capital/ui-kit/components';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { ReactNode } from 'react';

// showcase put a border and make the CTA contained
interface PricingCardPropsT {
    title: string;
    price: ReactNode;
    tooltip: string;
    children: ReactNode;
    cta: string;
    ctaAction: () => void;
    isCtaLoading: boolean;
    showcase?: boolean;
}

export const PricingCard = ({
    title,
    price,
    tooltip,
    children,
    cta,
    showcase,
    ctaAction,
    isCtaLoading,
}: PricingCardPropsT) => {
    const { classes } = usePricingCardStyle();

    return (
        <Grid className={showcase ? `${classes.container} ${classes.border}` : classes.container}>
            <Grid>
                <Grid height="80px" maxHeight="80px" display="flex" justifyContent="center">
                    <Typography variant="caps" className={classes.title}>
                        {title}
                    </Typography>
                </Grid>
                <Grid display="flex" justifyContent="center" padding="24px" alignItems="center">
                    <Grid className={classes.flexbox}>
                        <Typography variant="h3">{price}</Typography>
                        <ConditionalTooltip text={tooltip} />
                    </Grid>
                </Grid>
            </Grid>
            {children}
            <Grid display="flex" alignSelf="flex-end" flexDirection="column">
                <Button
                    variant={showcase ? 'contained' : 'text'}
                    color={showcase ? 'primary' : 'inherit'}
                    className={showcase ? classes.ctaContained : classes.cta}
                    endIcon={<IconArrowRight />}
                    onClick={ctaAction}
                    loading={isCtaLoading}
                >
                    {cta}
                </Button>
            </Grid>
        </Grid>
    );
};
