import { useForm } from '@shared/hooks';
import * as z from 'zod';
import { useSelector } from 'react-redux';
import { SharedStateT } from '@shared/store';
import { useEffect } from 'react';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';

export const ROTATION_ASSIGNATION_INPUT_NAMES = {
    SELECTED_CROP_ID: 'selected_crop_id',
    SELECTED_FIELD_IDS: 'selected_field_ids',
} as const;

export const schema = z.object({
    [ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID]: z.number().nullable(),
    [ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_FIELD_IDS]: z.array(z.number()),
});

export type RotationAssignationSchemaT = z.infer<typeof schema>;

const defaultValues = {
    [ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID]: null,
    [ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_FIELD_IDS]: [],
};

export const useRotationAssignationForm = () => {
    const { fieldState } = useGetFarmSeasonFields();
    const { cropState } = useGetFarmSeasonCrops();
    const { getFieldsForCrop } = fieldState.useGetFieldsForCrop();
    const { getCropsAssignedToAField } = cropState.useGetCropsAssignedToAField();

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: true,
    });

    const selectedFarmSeasonFieldId = useSelector((state: SharedStateT) => state.rotationFilters.farmSeasonFieldId);
    const selectedCropId = methods.watch(ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID);

    // When opening the modal by clicking on a field, we want to preselect it
    useEffect(() => {
        if (selectedFarmSeasonFieldId) {
            const assignedCrops = getCropsAssignedToAField(selectedFarmSeasonFieldId);
            if (assignedCrops.length > 0) {
                methods.setValue(ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_CROP_ID, assignedCrops[0].id);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods, selectedFarmSeasonFieldId]);

    // Set assigned fields as default form values when switching selected crop & no fieldId filter
    useEffect(() => {
        const assignedFields = selectedCropId ? getFieldsForCrop({ cropId: selectedCropId }) : [];
        const assignedFieldIds = assignedFields.map((field) => field.id);

        methods.setValue(ROTATION_ASSIGNATION_INPUT_NAMES.SELECTED_FIELD_IDS, assignedFieldIds);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods, selectedCropId, getFieldsForCrop]);

    const onReset = () => methods.reset(defaultValues);

    return { methods, onReset };
};
