import { auth } from '@modules/auth';
import { useEffect } from 'react';
import useHotjar from 'react-use-hotjar';

export const useUpdateHotjar = () => {
    const user = auth.useAuthUser();

    const { initHotjar, identifyHotjar } = useHotjar();

    useEffect(() => {
        initHotjar(
            process.env.REACT_APP_HOTJAR_ID,
            process.env.REACT_APP_HOTJAR_SV,
            process.env.NODE_ENV === 'development',
        );
    }, [initHotjar]);

    useEffect(() => {
        if (user) {
            identifyHotjar(user?.id.toString(), {
                email: user?.email,
                name: `${user?.first_name} ${user?.last_name}`,
            });
        } else {
            // temporary fix until this PR is fixed: https://github.com/the-bugging/react-use-hotjar/pull/20
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            identifyHotjar(null as any, {});
        }
    }, [user, identifyHotjar]);
};
