import { BaseEntityT } from '../baseEntity.types';
import { ProgressT } from '@shared/entities';
import { ProgressDetailT } from '../progress/progress.types';

export type FieldCropT = {
    farm_season_field_id: number;
    farm_season_crop_id: number;

    is_organic?: boolean | null;
    tillage_id?: number | null;
    has_companion_crop?: boolean | null;
    companion_crop_id?: number | null;
    creation_source?: FieldCropCreationSourceT | null;
} & BaseEntityT;

export const FIELD_CROP_CREATION_SOURCE_SLUGS = {
    PAC: 'pac',
    MANUAL: 'manual',
    OPENDATA: 'opendata',
} as const;

export type FieldCropCreationSourceT =
    (typeof FIELD_CROP_CREATION_SOURCE_SLUGS)[keyof typeof FIELD_CROP_CREATION_SOURCE_SLUGS];

export type FieldCropFinalizeAssignationPayloadT = {
    farm_season_field_crop_ids: number[];
};

export type FieldCropUpdatePayloadT = {
    is_organic?: boolean | null;
    tillage_id?: number | null;
    has_companion_crop?: boolean | null;
    companion_crop_id?: number | null;
};

export type FieldCropEndpointParamsT = {
    farmSeasonId?: number;
};

/* --------------------------------- ACTIONS -------------------------------- */
export type UpdateGlobalCharacteristicsResponseT = {
    field_crop_updated: FieldCropT;
    progress_updated: ProgressT[];
};
export type UpdateGlobalCharacteristicsRequestT = {
    farmSeasonId: number;
    id: number;
    body: {
        is_organic?: boolean | null;
        tillage_id?: number | null;
        has_companion_crop?: boolean | null;
        companion_crop_id?: number | null;
    };
};

export type FinalizeGlobalCharacteristicsResponseT = {
    progress_detail_updated: ProgressDetailT;
};
export type FinalizeGlobalCharacteristicsRequestT = {
    farmSeasonId: number;
    id: number;
};
