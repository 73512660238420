/* eslint-disable @typescript-eslint/no-explicit-any */

export const computeFormInputNames = <KeysT extends string>(
    schemaShape: Record<KeysT, unknown>,
): {
    [key in Uppercase<KeysT>]: Lowercase<key>;
} => {
    return Object.keys(schemaShape).reduce((acc, key) => {
        acc[key.toUpperCase()] = key;
        return acc;
    }, {} as any);
};
