import { z } from 'zod';
import { OperationFormSchema } from './OperationFormSchema';
import { SprayingFormDataT } from '../api/operationFormApi.types';

export const sprayingFormSchema = new OperationFormSchema(
    {
        total_passage: z.number().nullable(),
        sprayer_width: z.number().nullable(),
        pesticide_types: z.array(
            z.object({
                id: z.number(),
                passage: z.number().nullable().optional(),
                slug: z.string().nullable(), // not required for the api payload but used to manage the form
            }),
        ),
    },
    (apiData?: SprayingFormDataT) => ({
        pesticide_types:
            apiData?.pesticide_types.map((pesticideType) => ({
                id: pesticideType.id,
                passage: pesticideType.passage ?? null,
                slug: pesticideType.slug ?? null,
            })) ?? [],
        sprayer_width: apiData?.sprayer_width ?? null,
        total_passage: apiData?.total_passage ?? null,
    }),
    ['sprayer_width'],
);

export type SprayingPesticideTypeT = z.infer<(typeof sprayingFormSchema)['schema']>['pesticide_types'];

export type SprayingFormSchemaT = z.infer<(typeof sprayingFormSchema)['schema']>;

export const SPRAYING_FORM_INPUT_NAMES = { ...sprayingFormSchema.INPUT_NAMES, PASSAGE: 'passage' };
