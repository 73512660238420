import { useWatch } from 'react-hook-form';
import { useGetDestructionFormStaticDataByTypeQuery } from '../../api/destructionFormApi';
import { DESTRUCTION_STATIC_DATA_SLUGS, DESTRUCTION_STATIC_DATA_TYPE } from '../../destruction.types';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';

export const useDestructionProductInputVisibility = () => {
    const { data: destructionMethods } = useGetDestructionFormStaticDataByTypeQuery({
        type: DESTRUCTION_STATIC_DATA_TYPE.HARVEST_DESTRUCTION_METHOD,
    });

    const destructionMethodValue = useWatch({ name: DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_METHOD_STATIC_DATA_ID });
    const chemicalDestructionMethod = destructionMethods?.find(
        (el) => el.slug === DESTRUCTION_STATIC_DATA_SLUGS.HARVEST_DESTRUCTION_METHOD_CHEMICAL,
    )?.id;

    const shouldDisplayDestructionProductInput = destructionMethodValue === chemicalDestructionMethod;

    return { shouldDisplayDestructionProductInput };
};
