import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { IconCheckCircle, IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Button } from '@soil-capital/ui-kit/components';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { ConfirmationStepSkeleton } from '../ConfirmationStepSkeleton/ConfirmationStepSkeleton';

type ConfirmationStepPropsT = {
    title: string;
    subtitle: string;
    secondarySubtitle?: string;
    ctaText: string;
    ctaAction: () => void;
    secondaryCtaText?: string;
    secondaryCtaAction?: () => void;
    isCtaLoading?: boolean;
    isLoading?: boolean;
};

export const ConfirmationStep = ({
    title,
    subtitle,
    secondarySubtitle,
    ctaText,
    ctaAction,
    secondaryCtaText,
    secondaryCtaAction,
    isCtaLoading,
    isLoading,
}: ConfirmationStepPropsT) => {
    const theme = useTheme();

    return isLoading ? (
        <ConfirmationStepSkeleton />
    ) : (
        <Grid
            display="flex"
            flexDirection="column"
            alignItems="center"
            height={secondarySubtitle ? '70vh' : '60vh'}
            justifyContent="start"
            textAlign="center"
            gap={theme.spacing(10)}
            marginTop={theme.spacing(5)}
        >
            <Grid width="88px" height="88px" display="flex" justifyContent="center" alignItems="center" fontSize="80px">
                <IconCheckCircle variant="filled" color="success" fontSize="inherit" />
            </Grid>
            <Grid gap={theme.spacing(3)} display="flex" flexDirection="column">
                <Typography variant="h3">{title}</Typography>
                <Typography variant="body2">{subtitle}</Typography>
                {secondarySubtitle && (
                    <Typography sx={{ paddingTop: theme.spacing(1) }} variant="body2">
                        {secondarySubtitle}
                    </Typography>
                )}
            </Grid>
            <Grid
                display="flex"
                justifyContent={secondaryCtaText ? ' space-between ' : 'center'}
                alignItems="center"
                width="100%"
                marginTop={secondaryCtaText ? 'auto' : '0'}
            >
                {secondaryCtaText && secondaryCtaAction && (
                    <Button onClick={secondaryCtaAction}>{secondaryCtaText}</Button>
                )}
                <Button variant="contained" onClick={ctaAction} loading={isCtaLoading} endIcon={<IconArrowRight />}>
                    {ctaText}
                </Button>
            </Grid>
        </Grid>
    );
};
