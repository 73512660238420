import { PaymentStepModal } from '@modules/payment/components/PaymentStepModal/PaymentStepModal';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { ProcessingPayment } from './components/ProcessingPayment/ProcessingPayment';
import { useProcessingLogic } from './useProcessingLogic';
import { Elements } from '@stripe/react-stripe-js';

export const Processing = ({ failedPayment }: { failedPayment?: boolean }) => {
    const { stripePromise, farmSeasonId } = useProcessingLogic();

    return (
        <Grid display="flex" justifyContent="center">
            <PaymentStepModal stepProgress={2} hideBackButton>
                {stripePromise && (
                    <Elements stripe={stripePromise}>
                        <ProcessingPayment hasFailed={!!failedPayment} farmSeasonId={farmSeasonId} />
                    </Elements>
                )}
            </PaymentStepModal>
        </Grid>
    );
};
