import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFieldCropInfoBox = makeStyles()((theme) => ({
    icon: {
        fill: theme.palette.common.white,
        opacity: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        backgroundColor: 'rgba(50, 63, 69, 0.8)',
        borderRadius: theme.shape.borderRadiusS,
    },
    snapshotContainer: {
        aspectRatio: '1/1',
        overflow: 'hidden',
        position: 'relative',
        '&:hover > *': {
            opacity: 1,
        },
    },
}));
