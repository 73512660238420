import { useCallback, useMemo } from 'react';
import createStaticEntity from '../createStaticEntity';
import { StaticCropT } from '@shared/entities';

export default createStaticEntity<StaticCropT>()({
    entityName: 'staticCrop',
    url: ({ countryId }: { countryId: number }) => `/v3/static/crops/${countryId}`,
    useGetCustomState: (defaultState) => {
        const getByStaticCropGroupId = useCallback(
            (staticCropGroupId: number | null) => {
                if (staticCropGroupId === null) {
                    return null;
                }

                defaultState.list.filter((crop) => crop.crop_group_id === staticCropGroupId);
            },
            [defaultState.list],
        );

        return useMemo(() => ({ ...defaultState, getByStaticCropGroupId }), [defaultState, getByStaticCropGroupId]);
    },
});
