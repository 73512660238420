import fieldApi from './field.api';
import { FieldEndpointParamsT } from './field.types';
import { fieldCacheAdapter } from './field.cache';
import fieldCrop from '../fieldCrop';
import crop from '../crop';
import staticCrop from '../staticCrop';
import createDefaultState from '../createDefaultState';
import { useCallback } from 'react';

export default ({ farmSeasonId }: Partial<FieldEndpointParamsT>, options?: { skip?: boolean }) => {
    const defaultState = createDefaultState(fieldCacheAdapter, () =>
        fieldApi.useGet({ farmSeasonId }, { skip: !farmSeasonId || options?.skip }),
    );

    const useGetFieldsForCrop = useCallback(() => {
        const { fieldCropState } = fieldCrop.useState({ farmSeasonId });

        const getFieldsForCrop = useCallback(
            ({ cropId }: { cropId: number }) => {
                const fieldCrops = fieldCropState.getByCropId({ cropId });
                return defaultState.getByIds(fieldCrops.map((fc) => fc.farm_season_field_id));
            },
            [fieldCropState],
        );
        return {
            getFieldsForCrop,
        };
    }, [defaultState, farmSeasonId]);

    const useGetFieldsAreaForCrop = () => {
        const { getFieldsForCrop } = useGetFieldsForCrop();

        const getFieldsAreaForCrop = ({ cropId }: { cropId: number }) =>
            getFieldsForCrop({ cropId })?.reduce((totalArea, f) => f.area + totalArea, 0) ?? 0;

        return { getFieldsAreaForCrop };
    };

    const useGetFieldsWithoutCrop = () => {
        const { fieldCropState } = fieldCrop.useState({ farmSeasonId });

        const getFieldsWithoutCrop = () => {
            return defaultState.list.filter(
                (field) => !fieldCropState.list.find((fieldCrop) => fieldCrop.farm_season_field_id === field.id),
            );
        };

        return { getFieldsWithoutCrop };
    };

    const _useGetStaticCrops = useCallback(
        ({ countryId }: { countryId: number | undefined }) => {
            const { fieldCropState } = fieldCrop.useState({ farmSeasonId });
            const { cropState } = crop.useState({ farmSeasonId });
            const { staticCropState } = staticCrop.useState({ countryId });

            const getStaticCrops = ({ fieldId }: { fieldId: number }) => {
                const fieldCrops = fieldCropState.getByFieldId({ fieldId });
                const crops = cropState.getByIds(fieldCrops.map((fc) => fc.farm_season_crop_id));
                // TODO handle duplicates? (seasonCrop can have 2 same static crop with "is_accompagned")
                const staticCrops = staticCropState.getByIds(crops.map<number>((c) => c.crop_id));
                return staticCrops;
            };

            return {
                getStaticCrops,
                isLoading: fieldCropState.isLoading || cropState.isLoading || staticCropState.isLoading,
            };
        },
        [farmSeasonId],
    );

    const useGetFieldIsHarvested = useCallback(
        ({ countryId }: { countryId: number | undefined }) => {
            const { getStaticCrops, isLoading } = _useGetStaticCrops({ countryId });
            // const nonCultivatedCropsSlugs = [NON_CULTIVATED_CROP_SLUGS.FALLOW];

            const getFieldIsHarvested = ({ fieldId }: { fieldId: number }) => {
                const staticCrops = getStaticCrops({ fieldId });
                return !staticCrops.find(
                    (c) => c.is_permanent,
                    // FP-5864 - Exclude non-cultivated crops from the list (commented because we need to also handle the progress in parallel)
                    // && nonCultivatedCropsSlugs.find((nonCultivatedCropSlug) => c.slug === nonCultivatedCropSlug) !==
                    //     undefined,
                );
            };

            return { getFieldIsHarvested, isLoading };
        },
        [_useGetStaticCrops],
    );

    const useFieldAreaHarvested = useCallback(
        ({ countryId }: { countryId: number | undefined }) => {
            const { getFieldIsHarvested } = useGetFieldIsHarvested({ countryId });

            const fieldAreaHarvested = defaultState.list.reduce((totalArea, field) => {
                if (getFieldIsHarvested({ fieldId: field.id })) {
                    return field.area + totalArea;
                }
                return totalArea;
            }, 0);
            return { fieldAreaHarvested };
        },
        [defaultState.list, useGetFieldIsHarvested],
    );

    const useFieldAreaPermanent = useCallback(
        ({ countryId }: { countryId: number | undefined }) => {
            const { getFieldIsHarvested } = useGetFieldIsHarvested({ countryId });
            const fieldAreaPermanent = defaultState.list.reduce((totalArea, field) => {
                if (!getFieldIsHarvested({ fieldId: field.id })) {
                    return field.area + totalArea;
                }
                return totalArea;
            }, 0);
            return { fieldAreaPermanent };
        },
        [defaultState.list, useGetFieldIsHarvested],
    );

    /**
     * get fields without crop assigned or with crops assigned if all is_permanent = false
     */
    const useHarvestedFields = useCallback(
        ({ countryId }: { countryId: number | undefined }) => {
            const { getFieldIsHarvested, isLoading } = useGetFieldIsHarvested({ countryId });
            const harvestedFields = defaultState.list.filter((field) => getFieldIsHarvested({ fieldId: field.id }));
            return { harvestedFields, harvestedFieldsLoading: isLoading };
        },
        [defaultState.list, useGetFieldIsHarvested],
    );

    const getTotalArea = useCallback(
        () => defaultState.list?.reduce((acc, field) => acc + field.area, 0) ?? 0,
        [defaultState.list],
    );

    const getImportedFields = () => {
        return defaultState.list.filter((field) => field.area_source === 'pac');
    };

    return {
        fieldState: {
            ...defaultState,
            useGetFieldsWithoutCrop,
            useGetFieldsForCrop,
            useGetFieldsAreaForCrop,
            useGetFieldIsHarvested,
            useFieldAreaHarvested,
            useFieldAreaPermanent,
            useHarvestedFields,
            getTotalArea,
            getImportedFields,
        },
    };
};
