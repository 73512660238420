import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetCurrentHarvestYear } from '@modules/encoding/modules/technicalItinerary/hooks/useGetCurrentHarvestYear';

export type fieldDataListT = {
    id: number;
    name: string;
    crops: string[];
    area: number;
    image: string;
    hasCrop: boolean;
};
export const useIntroductionContentData = () => {
    const { t } = useTranslation();

    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { fieldState } = useGetFarmSeasonFields();
    const { cropState } = useGetFarmSeasonCrops();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticCropGroupState } = entities.staticCropGroup.useState({ countryId: currentFarm?.country_id });
    const { staticSeasonState } = entities.staticSeason.useState();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { harvestYear, isLoading: isLoadingHarvestYear } = useGetCurrentHarvestYear();

    const { getFieldThumbnail } = encodingShared.useGetFieldThumbnail({ seasonId: currentSeasonId });

    const isDataLoading =
        currentFarmLoading ||
        isLoadingHarvestYear ||
        fieldState.isLoading ||
        cropState.isLoading ||
        fieldCropState.isLoading ||
        staticCropState.isLoading ||
        staticCropGroupState.isLoading ||
        staticSeasonState.isLoading ||
        progressState.isLoading ||
        progressState.isFetching;

    const getStaticCropsByFieldId = (fieldId: number) => {
        const fieldCrops = fieldCropState.getByFieldId({ fieldId });
        const crops = fieldCrops.map((fieldCrop) => cropState.getById(fieldCrop.farm_season_crop_id));
        return crops.map((crop) => staticCropState.getById(crop?.crop_id));
    };

    const fieldDataList: fieldDataListT[] = fieldState.list.map((field) => {
        const staticCropsNames = getStaticCropsByFieldId(field.id).map((staticCrop) =>
            t(staticCrop?.translation_slug ?? ''),
        );

        const image = getFieldThumbnail({ fieldId: field.id, updatedAt: field.updated_at });
        const hasCrop = !!fieldCropState.getByFieldId({ fieldId: field.id })?.length;
        return {
            id: field.id,
            name: field.name,
            crops: staticCropsNames,
            area: field.area,
            image,
            hasCrop,
        };
    });

    return {
        fieldDataList,
        isDataLoading,
        harvestYear,
    };
};
