import { useTranslation } from 'react-i18next';
import { BooleanRadioGroup, FormRow } from '@shared/components';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type DestructionCompanionRemovedInputPropsT = {
    onChange: () => void;
};

export const DestructionCompanionRemovedInput = ({ onChange }: DestructionCompanionRemovedInputPropsT) => {
    const { t } = useTranslation();

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.destruction.question.companion-crop-removed')}
                tooltipTitleKey="encoding-technical-itinerary.destruction.question.companion-crop-removed.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.destruction.question.companion-crop-removed.tooltip.text"
            />
            <BooleanRadioGroup
                name={DESTRUCTION_FORM_INPUT_NAMES.HAS_COMPANION_CROP_STAYED_ON_FIELD}
                onChange={onChange}
            />
        </FormRow>
    );
};
