import { shapeEntity } from '../shapeEntity';
import cropApi, { cropApiInstance } from './crop.api';
import cropSchema from './crop.schema';
import cropState from './crop.state';

export default shapeEntity('Crop', {
    api: cropApiInstance,
    schema: cropSchema,
    ...cropApi,
    useState: cropState,
});
