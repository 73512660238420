import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useCopyRotationFromBaseline } from '@modules/encoding/modules/history/hooks/useCopyRotationFromBaseline';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';

export const useHistoryPacHeaderLogic = () => {
    const { fieldState } = useGetFarmSeasonFields();
    const { isHistoryFarmSeason, baselineHarvestYear } = useBaselineFarmSeason();
    const { copyRotationFromBaseline, isLoading } = useCopyRotationFromBaseline();

    const hasFields = fieldState?.list?.length > 0;

    return {
        harvestYear: baselineHarvestYear,
        isHistoryFarmSeason,
        hasFields,
        copyRotationFromBaseline,
        isCopyingFromBaseline: isLoading,
    };
};
