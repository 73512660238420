import operationApi from './operation.api';
import { operationCacheAdapter } from './operation.cache';
import { OperationEndpointParamsT } from './operation.types';
import createDefaultState from '../createDefaultState';
import { useCallback } from 'react';

export default (
    { farmSeasonFieldCropId, farmSeasonId }: OperationEndpointParamsT,
    options?: { skip?: boolean; refetchOnMountOrArgChange?: boolean },
) => {
    const defaultState = createDefaultState(operationCacheAdapter, () =>
        farmSeasonFieldCropId
            ? operationApi.useGet(
                  { farmSeasonFieldCropId, farmSeasonId },
                  { skip: options?.skip, refetchOnMountOrArgChange: options?.refetchOnMountOrArgChange },
              )
            : operationApi.useGetAll(
                  { farmSeasonId },
                  { skip: options?.skip, refetchOnMountOrArgChange: options?.refetchOnMountOrArgChange },
              ),
    );

    const getByOperationTypeConfigId = useCallback(
        (operationTypeConfigId: number) => {
            return defaultState.list.filter((item) => item.operation_type_config_id === operationTypeConfigId);
        },
        [defaultState.list],
    );

    const getByOperationTypeId = useCallback(
        (operationTypeId: number) => {
            return defaultState.list.filter((item) => item.operation_type_id === operationTypeId);
        },
        [defaultState.list],
    );

    const getByFarmSeasonFieldCropId = useCallback(
        (farmSeasonFieldCropId: number) => {
            return defaultState.list.filter((item) => item.farm_season_field_crop_id === farmSeasonFieldCropId);
        },
        [defaultState.list],
    );

    return {
        operationState: {
            ...defaultState,
            getByOperationTypeConfigId,
            getByOperationTypeId,
            getByFarmSeasonFieldCropId,
        },
    };
};
