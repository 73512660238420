import { Button } from '@soil-capital/ui-kit/components';
import { IconChevronDown, IconChevronUp } from '@soil-capital/ui-kit/icons';
import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { BulletedList } from '../BulletedList/BulletedList';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const CollapsableGroup = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Grid
                display="flex"
                justifyContent="center"
                alignItems="flex-start"
                paddingTop={theme.spacing(6)}
                width="100%"
            >
                <Grid display="flex" flexDirection="column" alignItems="center" flex="1">
                    <img src="/assets/images/fair-remuneration.svg" height="60px" />
                    <Typography variant="h3" paddingTop={theme.spacing(1)}>
                        {t('pages.onboarding.pricing.left-item.title')}
                    </Typography>
                    {isOpen && (
                        <BulletedList
                            firstPoint={t('pages.onboarding.pricing.left-item.description-1')}
                            secondPoint={t('pages.onboarding.pricing.left-item.description-2')}
                            thirdPoint={t('pages.onboarding.pricing.left-item.description-3')}
                        />
                    )}
                </Grid>
                <Grid display="flex" flexDirection="column" alignItems="center" flex="1">
                    <img src="/assets/images/total-flexibility.svg" height="60px" />
                    <Typography variant="h3" paddingTop={theme.spacing(1)}>
                        {t('pages.onboarding.pricing.center-item.title')}
                    </Typography>
                    {isOpen && (
                        <BulletedList
                            firstPoint={t('pages.onboarding.pricing.center-item.description-1')}
                            secondPoint={t('pages.onboarding.pricing.center-item.description-2')}
                            thirdPoint={t('pages.onboarding.pricing.center-item.description-3')}
                        />
                    )}
                </Grid>
                <Grid display="flex" flexDirection="column" alignItems="center" flex="1">
                    <img src="/assets/images/expert-advices.svg" height="60px" />
                    <Typography variant="h3" paddingTop={theme.spacing(1)}>
                        {t('pages.onboarding.pricing.right-item.title')}
                    </Typography>
                    {isOpen && (
                        <BulletedList
                            firstPoint={t('pages.onboarding.pricing.right-item.description-1')}
                            secondPoint={t('pages.onboarding.pricing.right-item.description-2')}
                            thirdPoint={t('pages.onboarding.pricing.right-item.description-3')}
                        />
                    )}
                </Grid>
            </Grid>
            <Grid
                display="flex"
                width="100%"
                justifyContent="center"
                paddingTop={theme.spacing(1)}
                paddingBottom={theme.spacing(3)}
            >
                <Button
                    variant="text"
                    endIcon={isOpen ? <IconChevronUp /> : <IconChevronDown />}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {t('pages.onboarding.pricing.know-more')}
                </Button>
            </Grid>
        </>
    );
};
