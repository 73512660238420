/* ---------------------------- route parameters ---------------------------- */
import { RouteParamsToStringT } from '@shared/utils/routesHelper';

/* -------------------------------------------------------------------------- */

export const soilAnalysisRoutesConfig = {
    introduction: '',
} as const;

/* --------------------------------- Routes --------------------------------- */
export type IntroductionParamsT = Record<string, never>;

const introduction = () => `${soilAnalysisRoutesConfig.introduction}`;

/* ------------------- Route params to type useParams<X>() ------------------ */
export type RotationRouteParamsT = {
    introduction: RouteParamsToStringT<IntroductionParamsT>;
};
/* -------------------------------------------------------------------------- */

export default {
    introduction,
};
