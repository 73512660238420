import { useOperationShallowWorkMachineForm } from '@modules/encoding/modules/technicalItinerary/components/OperationShallowWorkMachineForm/useOperationShallowWorkMachineForm';
import OperationShallowWorkMachineFormSkeleton from '@modules/encoding/modules/technicalItinerary/components/OperationShallowWorkMachineForm/OperationShallowWorkMachineFormSkeleton';
import { Input } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { useOperationShallowWorkMachineFormStyle } from '@modules/encoding/modules/technicalItinerary/components/OperationShallowWorkMachineForm/useOperationShallowWorkMachineForm.style';
import { FormProvider } from 'react-hook-form';
import { FormRow } from '@shared/components';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { ReuseOperationSelect } from '../../module/operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { useReuseOperation } from '../../module/operationDuplication/reuse/hooks/useReuseOperation';
import { SHALLOW_WORK_FORM_INPUT_NAMES } from '../../schema/shallowWorkFormSchema';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';
import { FormAutocomplete } from '@shared/components/FormAutocomplete/FormAutocomplete';

const OperationShallowWorkMachineForm = () => {
    const { t } = useTranslation();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();

    const {
        selectedMachinery,
        methods,
        machineryOptions,
        register,
        onSubmit,
        isFormLoading,
        getFormValues,
        refetchFormData,
        changeMachineryRelatedInputs,
    } = useOperationShallowWorkMachineForm();

    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        onOperationReused: refetchFormData,
        getFormValues,
    });

    const { classes, cx } = useOperationShallowWorkMachineFormStyle();

    return (
        <>
            {isFormLoading ? <OperationShallowWorkMachineFormSkeleton /> : null}
            <FormProvider {...methods}>
                <form onSubmit={onSubmit} className={cx(classes.form, isFormLoading && classes.hiddenForm)}>
                    <ReuseOperationSelect
                        fieldCropId={fieldCropId}
                        farmSeasonId={seasonId}
                        operationId={operationId}
                        onReuseOperationTriggered={handleReuseOperation}
                        disabled={isReuseOperationLoading}
                        actionLoading={isReuseOperationLoading}
                    />

                    <FormRow>
                        <FormQuestion
                            question={t('encoding-technical-itinerary.shallow-work-machine.machine')}
                            tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.machine-tooltip-title"
                            tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.machine-tooltip-text"
                        />
                        <FormAutocomplete
                            name={SHALLOW_WORK_FORM_INPUT_NAMES.MACHINERY_ID}
                            placeholder={t('constants.select')}
                            options={machineryOptions}
                            disabled={isReuseOperationLoading}
                            multiple={false}
                            onChange={(value) => {
                                changeMachineryRelatedInputs(value.value);
                                onSubmit();
                            }}
                        />
                    </FormRow>

                    <FormRow>
                        <FormQuestion
                            question={t('encoding-technical-itinerary.shallow-work-machine.passage')}
                            tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.passage-tooltip-title"
                            tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.passage-tooltip-text"
                        />
                        <Input
                            type="number"
                            onChange={onSubmit}
                            inputProps={{
                                ...register(SHALLOW_WORK_FORM_INPUT_NAMES.PASSAGE, {
                                    setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                                }),
                                'data-testid': 'passage-input',
                            }}
                            style={{ width: '250px' }}
                            disabled={isReuseOperationLoading}
                        />
                    </FormRow>

                    {selectedMachinery && selectedMachinery.depth === null && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.shallow-work-machine.depth')}
                                tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.depth-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.depth-tooltip-text"
                            />
                            <Input
                                type="number"
                                suffix={t('constants.unit.cm')}
                                onChange={onSubmit}
                                inputProps={{
                                    ...register(SHALLOW_WORK_FORM_INPUT_NAMES.DEPTH, {
                                        setValueAs: (v: string | null) =>
                                            v === '' || v === null ? null : parseFloat(v),
                                    }),
                                    'data-testid': 'depth-input',
                                }}
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}

                    {selectedMachinery?.slug === STATIC_MACHINERY_SLUGS.STRIP_TILL && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.shallow-work-machine.width')}
                                tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.width-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.width-tooltip-text"
                            />
                            <Input
                                type="number"
                                suffix={t('constants.unit.cm')}
                                onChange={onSubmit}
                                inputProps={{
                                    ...register(SHALLOW_WORK_FORM_INPUT_NAMES.SOIL_WORK_WIDTH, {
                                        setValueAs: (v: string | null) =>
                                            v === '' || v === null ? null : parseFloat(v),
                                    }),
                                    'data-testid': 'width-input',
                                }}
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}
                    {selectedMachinery?.slug === STATIC_MACHINERY_SLUGS.STRIP_TILL && (
                        <FormRow>
                            <FormQuestion
                                question={t('encoding-technical-itinerary.shallow-work-machine.interrow')}
                                tooltipTitleKey="encoding-technical-itinerary.shallow-work-machine.interrow-tooltip-title"
                                tooltipTextKey="encoding-technical-itinerary.shallow-work-machine.interrow-tooltip-text"
                            />
                            <Input
                                type="number"
                                suffix={t('constants.unit.cm')}
                                onChange={onSubmit}
                                inputProps={{
                                    ...register(SHALLOW_WORK_FORM_INPUT_NAMES.INTERROW, {
                                        setValueAs: (v: string | null) =>
                                            v === '' || v === null ? null : parseFloat(v),
                                    }),
                                    'data-testid': 'interrow-input',
                                }}
                                style={{ width: '250px' }}
                                disabled={isReuseOperationLoading}
                            />
                        </FormRow>
                    )}
                </form>
            </FormProvider>
        </>
    );
};

export default OperationShallowWorkMachineForm;
