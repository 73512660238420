import { useToggle } from '@shared/hooks/useToggle';

const useMapDraw = () => {
    const {
        open: drawnFieldCreationModalOpen,
        handleOpen: onDrawnFieldCreationModalOpen,
        handleClose: onDrawnFieldCreationModalClose,
    } = useToggle();

    return {
        drawnFieldCreationModalOpen,
        onDrawnFieldCreationModalOpen,
        onDrawnFieldCreationModalClose,
    };
};

export default useMapDraw;
