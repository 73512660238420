import entities, { StaticOperationTypeConfigT, StaticOperationTypeT } from '@shared/entities';
import { PickerItemT } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { OperationTypePeriodT } from '@shared/entities/staticOperationTypeConfig/staticOperationTypeConfig.types';
import { useParams } from 'react-router-dom';
import { TechnicalItineraryRouteParamsT } from '@modules/encoding/modules/technicalItinerary/technicalItinerary.routes';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';

export type OperationTypeItemsT = PickerItemT<{
    parent_id?: number | null;
    operationTypeId: number;
    operationTypeConfigId: number;
    operationDate?: string | null;
}>;

export type ComputedOperationTypeItemT = OperationTypeItemsT['items'][number];

/**
 * combine the operationType and operationTypeConfig + filter the crop/intercrop + order the children alphabetically.
 * To be used in the operationType select.
 */
export const useOperationTypeItemsForPicker = ({ period }: { period: OperationTypePeriodT }) => {
    const { t } = useTranslation();
    const { fieldCropId, seasonId } = useParams<TechnicalItineraryRouteParamsT['operationTimeline']>();
    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: Number(seasonId),
    });
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const { staticOperationTypeConfigState } = entities.staticOperationTypeConfig.useState();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const fieldCrop = fieldCropState.getById(fieldCropId);
    const operationTypeConfigByPeriod = staticOperationTypeConfigState.getByPeriod(period);
    const parentOperationTypes = staticOperationTypeState.getByParentId(null);

    const computeOperationTypeFromConfig = (operationTypeConfig: StaticOperationTypeConfigT) => {
        const operationType = staticOperationTypeState.getById(operationTypeConfig.operation_type_id);
        if (
            !operationType ||
            // if the fieldCrop has no companion crop, we don't want to display the companion seeding operation
            (fieldCrop?.has_companion_crop === false &&
                operationType.slug === OPERATION_TYPE_SLUGS.SEEDING_COMPANION_SEEDING)
        )
            return null;
        return {
            parent_id: operationType?.parent_id,
            label: t(operationType?.translation_slug ?? ''),
            value: {
                operationTypeConfigId: operationTypeConfig.id,
                operationTypeId: operationType.id,
            },
        };
    };

    const doesOperationTypeAlreadyExistsForPeriod = (operationType: ComputedOperationTypeItemT) => {
        const operations = operationState.getByOperationTypeId(operationType.value.operationTypeId);
        const foundOperationForPeriod = operations.find(
            (operation) =>
                staticOperationTypeConfigState.getById(operation.operation_type_config_id)?.period === period,
        );
        return !!foundOperationForPeriod;
    };

    const getParentOperationTypeChildrenFromConfigs = (
        parentOperationType: StaticOperationTypeT,
        operationTypeConfigs: StaticOperationTypeConfigT[],
    ) => {
        return operationTypeConfigs.reduce((acc, otc) => {
            const operationType = computeOperationTypeFromConfig(otc);
            if (!operationType) return acc;

            const operationTypeAlreadyExistsForPeriod = doesOperationTypeAlreadyExistsForPeriod(operationType);
            if (!operationTypeAlreadyExistsForPeriod && operationType?.parent_id === parentOperationType.id)
                acc.push(operationType);

            return acc;
        }, [] as ComputedOperationTypeItemT[]);
    };

    const groupByParentOperationType = (operationTypeConfigs: StaticOperationTypeConfigT[]) =>
        parentOperationTypes.map((parentOperationType) => {
            const children = getParentOperationTypeChildrenFromConfigs(parentOperationType, operationTypeConfigs);

            return {
                label: t(parentOperationType.translation_slug),
                items: children.sort((a, b) => {
                    const firstOperationType = staticOperationTypeState.getById(a.value.operationTypeId);
                    if (firstOperationType?.slug === OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION) return 1;
                    if (firstOperationType?.slug === OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT) return -1;
                    return a.label.localeCompare(b.label);
                }),
            };
        });

    const options = useMemo(() => {
        const list = groupByParentOperationType(operationTypeConfigByPeriod.list);
        return list.filter((item) => item.items.length > 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operationTypeConfigByPeriod.list]);

    return {
        isLoading: staticOperationTypeState.isLoading || staticOperationTypeConfigState.isLoading,
        items: options,
    };
};
