import { makeStyles } from '@soil-capital/ui-kit/style';

export const useOperationDuplicationLeftPanelStyles = makeStyles()((theme) => ({
    sourceFieldTitle: {
        textTransform: 'uppercase',
        color: theme.palette.darkScale[600],
        marginBottom: theme.spacing(1),
    },
    panel: {
        minWidth: 370,
    },
    panelBody: {
        paddingBottom: 0,
    },
    fieldCropInfos: {
        border: `1px solid ${theme.palette.darkScale[300]}`,
    },
}));
