import { makeStyles } from '@soil-capital/ui-kit/style';

export const useInfoBoxStyle = makeStyles()((theme) => ({
    container: {
        justifyContent: 'center',
        display: 'flex',
    },
    background: {
        backgroundColor: theme.palette.primaryScale[50],
        padding: '12px 24px',
        borderRadius: theme.shape.borderRadiusL,
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        width: 'fit-content',
        textAlign: 'center',
    },
    dark: {
        color: theme.palette.darkScale[900],
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(0.5),
    },
    medium: { color: theme.palette.darkScale[700] },
    light: { color: theme.palette.darkScale[500] },
}));
