export const ORGANIC_FERTILISER_STATIC_DATA_TYPE = {
    ORGANIC_FERTILISATION_APPLICATION: 'ORGANIC_FERTILISATION_APPLICATION',
} as const;

export type OrganicFertiliserStaticDataTypeT = keyof typeof ORGANIC_FERTILISER_STATIC_DATA_TYPE;

export const ORGANIC_FERTILISER_STATIC_DATA_APPLICATION_METHODS_SLUGS = {
    ORGANIC_FERTILISER_APPLICATION_METHOD_BURIAL: 'organic-fertilisation-application-burial',
    ORGANIC_FERTILISER_APPLICATION_METHOD_SPRAYING: 'organic-fertilisation-application-spraying',
} as const;

export type OrganicFertiliserApplicationMethodSlugsT =
    (typeof ORGANIC_FERTILISER_STATIC_DATA_APPLICATION_METHODS_SLUGS)[keyof typeof ORGANIC_FERTILISER_STATIC_DATA_APPLICATION_METHODS_SLUGS];

export const ORGANIC_FERTILISER_STATIC_DATA_SLUGS = {
    ...ORGANIC_FERTILISER_STATIC_DATA_APPLICATION_METHODS_SLUGS,
} as const;

export type OrganicFertiliserStaticDataSlugsT =
    (typeof ORGANIC_FERTILISER_STATIC_DATA_SLUGS)[keyof typeof ORGANIC_FERTILISER_STATIC_DATA_SLUGS];
