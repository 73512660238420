import { usePageWithHeaderStyle } from '@shared/components/PageWithHeader/PageWithHeader.style';
import { ReactNode } from 'react';

type PageWithHeaderProps = {
    header: ReactNode;
    content: ReactNode;
};

export const PageWithHeader = ({ header, content }: PageWithHeaderProps) => {
    const { classes } = usePageWithHeaderStyle();

    return (
        <main className={classes.main}>
            <header className={classes.header}>{header}</header>
            <div className={classes.content}>{content}</div>
        </main>
    );
};
