import { useCopyRotationFromBaselineMutation } from '@modules/encoding/modules/history/api/historyApi';

export const useCopyRotationFromBaseline = () => {
    const [copy, { isLoading }] = useCopyRotationFromBaselineMutation();

    const copyRotationFromBaseline = async (farmSeasonId: number) => {
        try {
            return copy({ farmSeasonId });
        } catch (e) {
            throw new Error('Could not copy rotation from baseline');
        }
    };

    return { copyRotationFromBaseline, isLoading };
};
