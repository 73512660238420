import { Button, Quote, Tooltip } from '@soil-capital/ui-kit/components';
import { PageWithHeader } from '@shared/components/PageWithHeader/PageWithHeader';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { IconArrowLeft } from '@soil-capital/ui-kit/icons';
import { useNavigate } from 'react-router-dom';
import { resultRoutesConfig } from '@modules/results/result.routes';
import { CarbonResultsGraph } from '@modules/results/components/CarbonResultsGraph/CarbonResultsGraph';
import { useResultsEarningStyle } from '@modules/results/pages/ResultsEarning/ResultsEarning.style';
import { IconInfo } from '@soil-capital/ui-kit/icons';
import { WarningBox } from '@shared/components/WarningBox/WarningBox';
import { Accordion } from '@soil-capital/ui-kit';
import { ResultsCalculationDetail } from '@modules/results/components/ResultsCalculationDetail/ResultsCalculationDetail';
import { useResultsEarning } from '@modules/results/pages/ResultsEarning/useResultsEarning';
import { truncateDecimals } from '@shared/utils/truncateDecimals';

export const ResultsEarning = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { classes } = useResultsEarningStyle();
    const { currentSeasonId, results, harvestYear, isNetEmitter, isLoading } = useResultsEarning();

    const handleBackClicked = () => navigate(`/${currentSeasonId}/results/${resultRoutesConfig.SUMMARY}`);

    if (isLoading || !harvestYear || !results || isNetEmitter === null) return null;

    return (
        <PageWithHeader
            header={
                <Button onClick={handleBackClicked} size="large" startIcon={<IconArrowLeft />} variant="text">
                    {t('constants.back')}
                </Button>
            }
            content={
                <>
                    <Typography variant="h1">{t('results.earning.title')}</Typography>
                    <Quote
                        author={{
                            avatar: '/assets/images/agro-max-avatar.jpeg',
                            name: 'Max,',
                            role: t('results.summary.quote.author.role'),
                        }}
                    >
                        <Typography sx={{ mb: 1 }} variant="h3">
                            {isNetEmitter
                                ? t('results.earning.quote.emission-title')
                                : t('results.earning.quote.sequestration-title')}
                        </Typography>
                        {t('results.earning.quote.content')}
                    </Quote>
                    <div className={classes.resultsContainer}>
                        <div className={classes.graphContainer}>
                            <CarbonResultsGraph
                                value={results.ghg_balance_average}
                                year={harvestYear}
                                yearLegend={t('constants.baseline')}
                                tooltip={t(
                                    isNetEmitter
                                        ? 'results.earning.graph.tooltip.emission'
                                        : 'results.earning.graph.tooltip.sequestration',
                                    {
                                        tonsPerHa: truncateDecimals(Math.abs(results.ghg_balance_average), 2),
                                        tonsTotal: truncateDecimals(Math.abs(results.ghg_balance), 2),
                                    },
                                )}
                            />
                        </div>
                        <div className={classes.earningContainer}>
                            <Tooltip text={t('results.earning.price.title.tooltip')}>
                                <Typography className={classes.priceTitle} variant="body">
                                    {t('results.earning.price.title')}
                                    <IconInfo />
                                </Typography>
                            </Tooltip>
                            <div className={classes.blurredContainer}>
                                <div className={classes.blurred}>
                                    <Typography className={classes.priceRange} variant="h1">
                                        {t('results.earning.price.range', { min: 0, max: 2000 })}
                                    </Typography>
                                    <Typography className={classes.tonsEquivalent} variant="subcaption">
                                        {t('results.earning.price.tons-equivalent', { tons: 11 })}
                                    </Typography>
                                    <WarningBox variant="withBorder">
                                        <Typography variant="body2">
                                            <Trans
                                                i18nKey="results.earning.price.warning"
                                                components={{ strong: <strong /> }}
                                                values={{ harvestYear }}
                                            />
                                        </Typography>
                                    </WarningBox>
                                </div>
                                <div className={classes.soonAvailableContainer}>
                                    <div className={classes.soonAvailable}>
                                        <svg
                                            className={classes.arrow}
                                            width="30"
                                            height="73"
                                            viewBox="0 0 30 73"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M28.1307 72.6221C28.5428 72.9898 29.175 72.9538 29.5427 72.5417C29.9104 72.1296 29.8744 71.4975 29.4623 71.1298L28.1307 72.6221ZM8.75469 0.648386C8.40594 0.220148 7.77607 0.155708 7.34782 0.504462L0.369224 6.18769C-0.0590168 6.53644 -0.123456 7.16632 0.225295 7.59456C0.574047 8.02281 1.20392 8.08725 1.63217 7.73849L7.83537 2.68673L12.8871 8.88992C13.2359 9.31817 13.8658 9.38261 14.294 9.03386C14.7222 8.6851 14.7867 8.05523 14.4379 7.62698L8.75469 0.648386ZM29.4623 71.1298C21.2091 63.7656 15.1458 55.8391 11.6068 45.0513C8.05972 34.2389 7.0213 20.4698 8.9741 1.38163L6.98449 1.17809C5.01652 20.4144 6.03868 34.4945 9.70642 45.6747C13.3822 56.8793 19.6878 65.0886 28.1307 72.6221L29.4623 71.1298Z"
                                                fill="#42BD7F"
                                            />
                                        </svg>
                                        <Typography variant="h3">{t('results.earning.soon-available')}</Typography>
                                        <Typography variant="body2">
                                            {t('results.earning.come-back-september')}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                            <Typography className={classes.carbonFootprint} variant="caption">
                                {t(
                                    isNetEmitter
                                        ? 'results.earning.price.carbon-footprint.emission'
                                        : 'results.earning.price.carbon-footprint.sequestration',
                                    {
                                        tonsPerHa: truncateDecimals(Math.abs(results.ghg_balance_average), 2),
                                        tonsTotal: truncateDecimals(Math.abs(results.ghg_balance), 2),
                                    },
                                )}
                            </Typography>
                        </div>
                    </div>
                    <div className={classes.accordionsContainer}>
                        <Accordion
                            Header={
                                <Accordion.Header className={classes.accordionHeader}>
                                    {t('results.earning.carbon-footprint.calculation-detail.title')}
                                </Accordion.Header>
                            }
                            Content={
                                <Accordion.Content className={classes.detailedCalculation}>
                                    <ResultsCalculationDetail
                                        ghgBalance={results.ghg_balance}
                                        cropEmissions={results.crop_net_emissions}
                                        cropSequestrations={results.crop_net_sequestrations}
                                        livestockEmissions={results.livestock_emissions}
                                    />
                                </Accordion.Content>
                            }
                            defaultExpanded
                        />
                        <Accordion
                            Header={
                                <Accordion.Header className={classes.accordionHeader}>
                                    {t('results.earning.carbon-footprint.how-calculation-work.title')}
                                </Accordion.Header>
                            }
                            Content={
                                <Accordion.Content>
                                    {t('results.earning.carbon-footprint.how-calculation-work.description')}
                                </Accordion.Content>
                            }
                        />
                    </div>
                </>
            }
        />
    );
};
