import entities, { ProgressT } from '@shared/entities';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useEncodingDoneContentData = () => {
    const { currentSeason, currentSeasonLoading } = useCurrentSeason();
    const { currentSeasonId } = useCurrentSeasonId();
    const { staticSeasonState } = entities.staticSeason.useState();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });

    const isDataLoading = currentSeasonLoading || staticSeasonState.isLoading || progressState.isLoading;

    const harvestYear = staticSeasonState.getById(currentSeason?.season_id)?.harvest_year;

    const getIsStepVisible = (slug: ProgressT['slug']) => {
        return progressState.getBySlug(slug)?.is_visible ?? null;
    };

    const getStepProgress = (slug: ProgressT['slug']): { max: number; current: number } => {
        const progressStep = progressState.getBySlug(slug);
        return { max: progressStep?.progress_total ?? 0, current: progressStep?.progress_done ?? 0 };
    };

    return { harvestYear, isDataLoading, getIsStepVisible, getStepProgress };
};
