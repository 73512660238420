import { EntityState } from '@reduxjs/toolkit';
import api from '@shared/api';
import fieldCropCache from './fieldCrop.cache';
import {
    FieldCropEndpointParamsT,
    FieldCropFinalizeAssignationPayloadT,
    FieldCropT,
    FieldCropUpdatePayloadT,
    FinalizeGlobalCharacteristicsRequestT,
    FinalizeGlobalCharacteristicsResponseT,
    UpdateGlobalCharacteristicsRequestT,
    UpdateGlobalCharacteristicsResponseT,
} from './fieldCrop.types';
import { MakeUndefinedPartial } from '@shared/utils';

const url = (farmSeasonId?: number) => `/v3/farm-seasons/${farmSeasonId}/field-crops`;

export const fieldCropApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getFieldCrop: build.query<EntityState<FieldCropT>, FieldCropEndpointParamsT>({
            query: ({ farmSeasonId }) => ({
                url: url(farmSeasonId),
                method: 'GET',
            }),
            providesTags: ['FieldCrop'],
            transformResponse: fieldCropCache.transformResponse,
        }),

        createFieldCrop: build.mutation<
            FieldCropT,
            FieldCropEndpointParamsT & { body: MakeUndefinedPartial<Omit<FieldCropT, 'id'>> }
        >({
            query: ({ farmSeasonId, body }) => ({
                url: url(farmSeasonId),
                method: 'POST',
                body,
            }),
            onQueryStarted: fieldCropCache.add('fetch-only'),
            invalidatesTags: ['Progress'],
        }),

        createFieldCropOptimistic: build.mutation<
            FieldCropT,
            FieldCropEndpointParamsT & { body: MakeUndefinedPartial<Omit<FieldCropT, 'id'>> }
        >({
            query: ({ farmSeasonId, body }) => ({
                url: url(farmSeasonId),
                method: 'POST',
                body,
            }),
            onQueryStarted: fieldCropCache.add('cache-then-fetch'),
            invalidatesTags: ['Progress'],
        }),

        deleteFieldCrop: build.mutation<FieldCropT, FieldCropEndpointParamsT & { id: number }>({
            query: ({ farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'DELETE',
            }),
            onQueryStarted: fieldCropCache.remove('cache-only'),
            invalidatesTags: ['Progress'],
        }),

        finalizeFieldCropsAssignation: build.mutation<
            number[],
            FieldCropEndpointParamsT & { body: FieldCropFinalizeAssignationPayloadT }
        >({
            query: ({ farmSeasonId, body }) => ({
                url: `${url(farmSeasonId)}/finalize-assignation`,
                method: 'POST',
                body,
            }),
            invalidatesTags: ['FieldCrop', 'Progress'],
        }),

        updateFieldCrop: build.mutation<
            FieldCropT,
            FieldCropEndpointParamsT & { body: FieldCropUpdatePayloadT; id: number }
        >({
            query: ({ body, farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}`,
                method: 'PATCH',
                body,
            }),
            onQueryStarted: fieldCropCache.update('cache-then-fetch'),
        }),

        updateFieldCropGlobalCharacteristics: build.mutation<
            UpdateGlobalCharacteristicsResponseT,
            UpdateGlobalCharacteristicsRequestT
        >({
            query: ({ body, farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}/global-characteristics`,
                method: 'PATCH',
                body,
            }),
            onQueryStarted: async ({ body, farmSeasonId, id }, apiContext) => {
                fieldCropCache.update('cache-only')({ body, farmSeasonId, id }, apiContext);

                const { data: globalCharacteristicsResp } = await apiContext.queryFulfilled;

                fieldCropCache.update('cache-only')(
                    { body: globalCharacteristicsResp.field_crop_updated, farmSeasonId, id },
                    apiContext,
                );
            },
            invalidatesTags: ['Progress'],
        }),

        finalizeGlobalCharacteristics: build.mutation<
            FinalizeGlobalCharacteristicsResponseT,
            FinalizeGlobalCharacteristicsRequestT
        >({
            query: ({ farmSeasonId, id }) => ({
                url: `${url(farmSeasonId)}/${id}/global-characteristics/finalize`,
                method: 'POST',
            }),
            invalidatesTags: ['Progress'],
        }),
    }),
});

export default {
    useGet: fieldCropApiInstance.useGetFieldCropQuery,
    useCreate: fieldCropApiInstance.useCreateFieldCropMutation,
    useCreateOptimistic: fieldCropApiInstance.useCreateFieldCropOptimisticMutation,
    useFinalizeAssignation: fieldCropApiInstance.useFinalizeFieldCropsAssignationMutation,
    useUpdate: fieldCropApiInstance.useUpdateFieldCropMutation,
    useDelete: fieldCropApiInstance.useDeleteFieldCropMutation,
    useUpdateGlobalCharacteristics: fieldCropApiInstance.useUpdateFieldCropGlobalCharacteristicsMutation,
    useFinalizeGlobalCharacteristics: fieldCropApiInstance.useFinalizeGlobalCharacteristicsMutation,
};
