import { useWatch, useFormContext, useFormState } from 'react-hook-form';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';
import { useEffect } from 'react';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../../schema/coverCropFormSchema';
import { STATIC_MACHINERY_SLUGS } from '@shared/entities/staticMachinery/staticMachinery.types';

export const useCoverCropInterrowSpacingQuestion = () => {
    const seedingMachineId = useWatch({ name: COVER_CROP_FORM_INPUT_NAMES.SEEDING_MACHINE_ID }) as number;
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { dirtyFields } = useFormState();

    const { setValue } = useFormContext();

    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });
    const seedingMachine = staticMachineryState.getById(seedingMachineId);

    const isStripTillMachine = seedingMachine?.slug === STATIC_MACHINERY_SLUGS.STRIP_TILL;

    useEffect(() => {
        if (
            !isStripTillMachine &&
            (dirtyFields[COVER_CROP_FORM_INPUT_NAMES.SEEDING_MACHINE_ID] ||
                dirtyFields[COVER_CROP_FORM_INPUT_NAMES.IS_FROM_PREVIOUS_CROP])
        ) {
            setValue(COVER_CROP_FORM_INPUT_NAMES.INTERROW_SPACING, null);
        }
    }, [isStripTillMachine, setValue, seedingMachine, dirtyFields]);

    return {
        isVisible: isStripTillMachine,
    };
};
