import { useParams } from 'react-router-dom';
import entities from '@shared/entities';
import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';

export const useHasFieldCropGuard = () => {
    const { seasonId: seasonIdParam, fieldCropId } = useParams();
    const seasonId = Number(seasonIdParam);
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: seasonId });

    const fieldCrop = fieldCropState.getById(Number(fieldCropId));

    const isLoading = fieldCropState.isLoading;

    return isLoading ? undefined : !!fieldCrop;
};

export const HasFieldCropGuard = (props: GuardT) => {
    const passed = useHasFieldCropGuard();

    return <BaseGuard passed={passed} {...props} />;
};
