import useMapBannerStyles from './MapBanner.style';

interface MapBannerProps {
    helpText?: string;
}

export const MapBanner: React.FC<MapBannerProps> = ({ helpText }) => {
    const { classes } = useMapBannerStyles();

    return helpText ? <div className={classes.banner}>{helpText}</div> : null;
};
