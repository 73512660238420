import { useTranslation } from 'react-i18next';
import useBillingPageStyle from './useBillingPage.style';
import { Typography, Skeleton } from '@soil-capital/ui-kit/material-core';
import { SubscriptionCard } from '@modules/billing/components/SubscriptionCard/SubscriptionCard';
import { PaymentInfoCard } from '@modules/billing/components/PaymentInfoCard/PaymentInfoCard';
import { BillingInfoCard } from '@modules/billing/components/BillingInfoCard/BillingInfoCard';
import { useBillingPageLogic } from './useBillingPageLogic';

const Billing = (): JSX.Element => {
    const { t } = useTranslation();
    const { classes } = useBillingPageStyle();
    const {
        farmId,
        payments,
        isPaymentLoading,
        paymentMethod,
        isPaymentMethodLoading,
        billingInfo,
        isBillingInfoLoading,
    } = useBillingPageLogic();

    return (
        <div className={classes.background}>
            <Typography variant="h1">{t('billing.title')}</Typography>
            <>
                {isPaymentLoading || !farmId ? (
                    <Skeleton variant="rectangular" className={classes.skeleton} />
                ) : (
                    payments && <SubscriptionCard farmId={farmId} />
                )}

                {isPaymentMethodLoading || !farmId ? (
                    <Skeleton variant="rectangular" className={classes.skeleton} />
                ) : (
                    paymentMethod?.id && <PaymentInfoCard farmId={farmId} />
                )}

                {isBillingInfoLoading || !farmId ? (
                    <Skeleton variant="rectangular" className={classes.skeleton} />
                ) : (
                    billingInfo?.id && <BillingInfoCard farmId={farmId} />
                )}
            </>
        </div>
    );
};

export default Billing;
