import { IconHome } from '@soil-capital/ui-kit/icons';
import { IconPlus } from '@soil-capital/ui-kit/icons';
import { IconMinus } from '@soil-capital/ui-kit/icons';
import useMapControlStyle from './MapControl.style';
import { useMapControlLogic } from './MapControl.logic';

interface MapControlProps {
    polygons: GeoJSON.Feature[];
    fallbackCoord: [number, number];
}

/** duplicated from ../map/MapControl but with his own logic handled internally */
export const MapControl: React.FC<MapControlProps> = ({ polygons, fallbackCoord }) => {
    const { recenterMap, zoomIn, zoomOut } = useMapControlLogic({ polygons, fallbackCoord });
    const { classes } = useMapControlStyle();

    return (
        <div className={classes.button}>
            <div className={classes.home}>
                <IconHome onClick={recenterMap} color="primary" data-testid="home-icon" />
            </div>
            <div className={classes.zoom}>
                <IconPlus className={classes.plusIcon} onClick={zoomIn} color="primary" data-testid="plus-icon" />
                <div className={classes.divider} />
                <IconMinus className={classes.minusIcon} onClick={zoomOut} color="primary" data-testid="minus-icon" />
            </div>
        </div>
    );
};
