import { makeStyles } from '@soil-capital/ui-kit/style';

export const useMineralFertiliserFormStyles = makeStyles()((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    hiddenForm: {
        visibility: 'hidden',
        height: 0,
    },
    renderSelection: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));
