import { useMineralFertiliserFormOptions } from '../../hooks/useMineralFertiliserFormOptions';
import { useEffect } from 'react';
import { useForm } from '@shared/hooks';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { getOperationFormApi } from '@modules/encoding/modules/technicalItinerary/api/getOperationFormApi';
import {
    MineralFertilisationFormSchemaT,
    mineralFertilisationFormSchema,
} from '@modules/encoding/modules/technicalItinerary/schema/mineralFertilisationFormSchema';
import { useOperationFormDefaultValues } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationFormDefaultValues';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';
import entities from '@shared/entities';
import { encodingShared } from '@modules/encoding/shared';

export const useMineralFertiliserForm = () => {
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();
    const {
        formApiData,
        computeApiDataToMatchSchema,
        isFormLoading: isLoadingDefaultValues,
        refetchFormData,
    } = useOperationFormDefaultValues('mineral-fertilisation');

    // Form options
    const { isLoading: isLoadingOptions, applicationMethods } = useMineralFertiliserFormOptions();

    const { currentFarmLoading, currentFarm } = encodingShared.useCurrentFarm();
    const { staticFertiliserState } = entities.staticFertiliser.useState({ farmId: currentFarm?.id });

    const isFormLoading =
        isLoadingDefaultValues || isLoadingOptions || currentFarmLoading || staticFertiliserState.isLoading;

    // Mutations
    const [updateForm] = getOperationFormApi('mineral-fertilisation').useUpdate();

    const methods = useForm({
        schema: mineralFertilisationFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(),
        displaySnackbarOnSchemaError: true,
    });

    const { handleSubmit, reset, getValues } = methods;

    // Reset form default values when API data was fetched
    useEffect(() => {
        if (formApiData) reset(computeApiDataToMatchSchema(formApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData]);

    // Form callbacks
    const onSubmit = useStableDebounce(
        handleSubmit(async (data: MineralFertilisationFormSchemaT) => {
            await updateForm({
                farmSeasonId: seasonId,
                fieldCropId: fieldCropId,
                operationId: operationId,
                body: data,
            });
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    return {
        methods,
        onSubmit,
        isFormLoading,
        options: {
            applicationMethods,
        },
        getFormValues: getValues,
        refetchFormData,
    };
};
