import { formatNumber } from '@shared/utils';
import {
    DuplicationFieldT,
    useGetDuplicationFieldCropData,
} from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/shared/hooks/useGetDuplicationFieldCropData';
import { useTranslation } from 'react-i18next';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useParams } from 'react-router-dom';
import { useGetFieldCropsToFinaliseQuery } from '@modules/encoding/modules/technicalItinerary/module/operationDuplication/api/duplicationApi';

export const useFieldsReadyForFinalisation = () => {
    const { t } = useTranslation();
    const { currentSeasonId } = useCurrentSeasonId();
    const { fieldCropId: fieldCropIdParam } = useParams();
    const { data: fieldCrops, isFetching } = useGetFieldCropsToFinaliseQuery({
        farmSeasonId: currentSeasonId,
        farmSeasonFieldCropId: Number(fieldCropIdParam),
    });
    const { getComputedField } = useGetDuplicationFieldCropData({
        farmSeasonId: currentSeasonId,
    });

    const computedFieldCrops = fieldCrops?.map((field) => getComputedField(field.id) as DuplicationFieldT) ?? [];

    const fieldsReadyForFinalisation = computedFieldCrops.map((field) => ({
        id: field.fieldCrop.id,
        fieldName: field.name,
        fieldArea: `${formatNumber(field.area, 2)} HA`,
        cropName: [{ name: t(`db.crop.slug-${field.staticCrop.slug}`), id: field.staticCrop.id }],
        mapSnapshot: field.snapshot,
    }));

    return { fieldsReadyForFinalisation, isFetching };
};
