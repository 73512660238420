import { makeStyles } from '@soil-capital/ui-kit/style';

export const useFieldCropAssignationModalRightPanelStyles = makeStyles()((theme) => ({
    hide: {
        display: 'none',
    },
    list: {
        overflow: 'hidden',
    },
    fieldInfoBoxOverride: {
        padding: 0,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    cursorDefault: {
        cursor: 'default',
    },
    panelFooter: {
        display: 'flex',
        justifyContent: 'center',
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
}));
