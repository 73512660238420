import { auth } from '@modules/auth';
import { useEffect } from 'react';
import { useUpscope } from '@upscopeio/react';

export const useUpdateUpscope = () => {
    const user = auth.useAuthUser();
    const { Upscope } = useUpscope();

    useEffect(() => {
        try {
            if (user) {
                Upscope?.('updateConnection', {
                    identities: [
                        `${user?.first_name} ${user?.last_name}`,
                        user?.email,
                        ...(user?.hubspot_id ? [user?.hubspot_id.toString()] : []),
                    ],
                    uniqueId: user?.id.toString(),
                });
            } else {
                Upscope?.('reset');
            }
            // silent catch because Upscope might not be loaded yet when calling reset
        } catch {}
    }, [user, Upscope]);
};
