/* eslint-disable @typescript-eslint/no-explicit-any */
import i18n from 'i18next';

/**
 * Format a number to be displayed with the correct locale
 * @param value number to be formatted. We allow null | undefined, but they won't be formatted
 * @param maxDecimals
 * @param minDecimals
 * @returns formatted number as string. This string can't be converted in number
 */
const formatNumber = <T extends null | undefined | number>(
    value: T,
    maxDecimals?: number,
    minDecimals?: number,
): T extends number ? string : T => {
    if (value === null) return null as any;

    if (value === undefined) return undefined as any;

    return new Intl.NumberFormat(i18n.resolvedLanguage, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
    }).format(value) as any;
};

export default formatNumber;
