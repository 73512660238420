import { KeysOfType } from '@shared/utils/types/KeysOfType';
import {
    CustomStaticFertiliserFormSchemaT,
    CustomStaticFertiliserFormValuesT,
} from '../../schema/customStaticFertiliserFormSchema';
import { StaticFertiliserT } from '@shared/entities/staticFertiliser/staticFertiliser.types';

export const computeCustomName = (formData: CustomStaticFertiliserFormValuesT) =>
    `Custom ${(formData.n || 0).toLocaleString()}-${(formData.p || 0).toLocaleString()}-${(
        formData.k || 0
    ).toLocaleString()}`;

const sumValues = (
    keys: KeysOfType<CustomStaticFertiliserFormSchemaT, number | null>[],
    formData: CustomStaticFertiliserFormValuesT,
) => keys.reduce((total: number, key) => total + Number(formData[key]), 0);

export const haveSimilarComponents = (
    fertiliser: StaticFertiliserT,
    formData: CustomStaticFertiliserFormValuesT,
): boolean =>
    `${fertiliser.n}${fertiliser.p}${fertiliser.k}${fertiliser.ca}${fertiliser.mg}${fertiliser.s}` ===
    `${formData.n}${formData.p}${formData.k}${formData.ca || 0}${formData.mg || 0}${formData.s || 0}`;

export const getFertiliserByName = (
    fertilisers: StaticFertiliserT[],
    formData: CustomStaticFertiliserFormValuesT,
): StaticFertiliserT | undefined => {
    const customName = computeCustomName(formData);

    return fertilisers.find(
        (f) =>
            f.name?.toLowerCase() ===
            (formData.fertiliser_name !== null
                ? formData.fertiliser_name?.toLowerCase()?.trim()
                : customName?.toLowerCase()),
    );
};

export const getExistingFertiliserForNPK = (
    formData: CustomStaticFertiliserFormValuesT,
    mineralFertilisers: StaticFertiliserT[],
) => {
    return mineralFertilisers?.filter(
        (f) => f.farm_id === null && `${f.n}${f.p}${f.k}` === `${formData.n}${formData.p}${formData.k}`,
    );
};

/**
 * @returns error: fertiliser name exist or total components > 100
 */
export const getCustomValidationErrors = (
    formData: CustomStaticFertiliserFormValuesT,
    mineralFertilisers: StaticFertiliserT[],
    t: (s: string, params?: unknown) => string,
) => {
    let error: string | undefined;

    const totalComponents = sumValues(['n', 'p', 'k', 'ca', 'mg', 's'], formData);
    if (totalComponents > 100) {
        error = t('components.modal-create-fertiliser.total-is-max-100', { total: totalComponents });
    }
    const fertiliserNameExisting = getFertiliserByName(mineralFertilisers, formData);
    if (fertiliserNameExisting) {
        error = haveSimilarComponents(fertiliserNameExisting, formData)
            ? undefined
            : t('components.modal-create-fertiliser.name-already-exist');
    }

    return error;
};
