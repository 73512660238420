import { makeStyles } from '@soil-capital/ui-kit/style';
import rings from '@assets/images/rings.svg';

export default makeStyles()((theme) => ({
    background: {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: `url(${rings})`,
        backgroundPosition: 'right bottom',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
        position: 'absolute',
        borderTopRightRadius: theme.shape.borderRadiusM,
        borderBottomRightRadius: theme.shape.borderRadiusM,
        padding: theme.spacing(5),
        paddingBottom: theme.spacing(9),
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'auto',
    },
    skeleton: {
        height: '30vh',
        width: '100%',
        marginTop: theme.spacing(8),
        borderRadius: theme.shape.borderRadiusM,
    },
}));
