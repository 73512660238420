import { useGetOrganicFertiliserStaticDataByTypeQuery } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/api/organicFertiliserApi';
import { ORGANIC_FERTILISER_STATIC_DATA_TYPE } from '@modules/encoding/modules/technicalItinerary/module/organicFertiliser/organicFertiliser.types';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { FERTILISER_TYPE_SLUGS } from '@shared/entities/staticFertiliser/staticFertiliser.types';
import { sortBytranslation } from '@shared/utils/sortByTranslation';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const useOrganicFertiliserFormOptions = () => {
    const { t } = useTranslation();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticFertiliserState } = entities.staticFertiliser.useState({ farmId: currentFarm?.id });

    const { data: applicationMethods, isLoading: isLoadingApplicationMethods } =
        useGetOrganicFertiliserStaticDataByTypeQuery({
            type: ORGANIC_FERTILISER_STATIC_DATA_TYPE.ORGANIC_FERTILISATION_APPLICATION,
        });

    const applicationMethodsOptions = applicationMethods
        ?.map((el) => ({
            label: t(`static-data.slug.${el.slug}`),
            value: el.id,
        }))
        .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));

    const organicFertilisersSolid = useMemo(
        () => {
            return staticFertiliserState
                .getByFilters({
                    physicalState: 'solid',
                    fertiliserTypes: [
                        FERTILISER_TYPE_SLUGS.ORGANIC_FERTILISER,
                        FERTILISER_TYPE_SLUGS.MICRO_ORGANISMS,
                        FERTILISER_TYPE_SLUGS.PLANT_BASED_FERTILISER,
                        FERTILISER_TYPE_SLUGS.ORGANIC_AMENDMENT,
                    ],
                })
                .sort(sortBytranslation(t));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [staticFertiliserState.getByFilters],
    );

    const organicFertilisersLiquid = useMemo(
        () => {
            return staticFertiliserState
                .getByFilters({
                    physicalState: 'liquid',
                    fertiliserTypes: [
                        FERTILISER_TYPE_SLUGS.ORGANIC_FERTILISER,
                        FERTILISER_TYPE_SLUGS.MICRO_ORGANISMS,
                        FERTILISER_TYPE_SLUGS.PLANT_BASED_FERTILISER,
                        FERTILISER_TYPE_SLUGS.ORGANIC_AMENDMENT,
                    ],
                })
                .sort(sortBytranslation(t));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [staticFertiliserState.getByFilters],
    );

    const isLoading = isLoadingApplicationMethods || staticFertiliserState.isLoading;

    return {
        applicationMethods: applicationMethodsOptions || [],
        organicFertilisersSolid,
        organicFertilisersLiquid,
        isLoading,
    };
};
