import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { useWeedingMachineDepthInputVisibility } from './useWeedingMachineDepthInputVisibility';
import { WEEDING_MACHINE_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/weedingMachineFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type WeedingMachineDepthInputPropsT = {
    onChange: () => void;
    disabled?: boolean;
};

export const WeedingMachineDepthInput = ({ onChange, disabled }: WeedingMachineDepthInputPropsT) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplayWeedingMachineDepthInput } = useWeedingMachineDepthInputVisibility();

    if (!context) throw new Error('This component should be used inside a FormProvider');

    return shouldDisplayWeedingMachineDepthInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.weeding-machine.depth')}
                tooltipTitleKey="encoding-technical-itinerary.weeding-machine.depth-tooltip-title"
                tooltipTextKey="encoding-technical-itinerary.weeding-machine.depth-tooltip-text"
            />
            <Input
                type="number"
                suffix={t('constants.unit.cm')}
                onChange={onChange}
                inputProps={{
                    ...context.register(WEEDING_MACHINE_FORM_INPUT_NAMES.DEPTH, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
                style={{ width: '250px' }}
                disabled={disabled}
            />
        </FormRow>
    ) : (
        <></>
    );
};
