import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    card: {
        marginTop: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    button: {
        borderBottom: '2px solid currentColor',
        paddingBottom: '1px',
    },
}));
