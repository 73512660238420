import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import i18nextHttpBackend from 'i18next-http-backend';

i18next
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(I18nextBrowserLanguageDetector)
    .use(i18nextHttpBackend)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: process.env.REACT_APP_I18N_DEBUG === 'true',
        load: 'languageOnly',
        fallbackLng: 'en',
        fallbackNS: 'common',
        ns: ['common', 'main'],
        defaultNS: 'main',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
            loadPath: `${window.location.origin}/assets/translations/{{lng}}/{{ns}}.json`,
        },
    });
