import { useTranslation } from 'react-i18next';
import { useConfirmDeleteOperationModalLogic } from './useConfirmDeleteOperationModalLogic';
import { ConfirmDeleteOperationModalProps } from './ConfirmDeleteOperationModal.types';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';

export const ConfirmDeleteOperationModal = ({
    data,
    isOpen,
    onClose,
    fieldCropId,
    farmSeasonId,
}: ConfirmDeleteOperationModalProps) => {
    const { t } = useTranslation();

    const { operationToDeleteDate, operationToDeleteName } = useConfirmDeleteOperationModalLogic({
        fieldCropId,
        farmSeasonId,
        data,
    });

    return (
        <ConfirmModal
            isOpen={isOpen}
            onClose={onClose}
            title={t('encoding-technical-itinerary.delete-operation-component.modal-title')}
            primaryText={{
                translationKey: operationToDeleteDate
                    ? 'encoding-technical-itinerary.delete-operation-component.modal-operation-text'
                    : 'encoding-technical-itinerary.delete-operation-component.modal-operation-without-date-text',
                translationValues: operationToDeleteDate
                    ? { operationName: operationToDeleteName, operationDate: operationToDeleteDate }
                    : { operationName: operationToDeleteName },
            }}
            secondaryText={{
                translationKey: 'encoding-technical-itinerary.delete-operation-component.modal-complementary-text',
            }}
            confirmText={t('encoding-technical-itinerary.delete-operation-component.modal-confirm-button')}
            cancelText={t('constants.cancel')}
        />
    );
};
