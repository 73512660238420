import { PaymentStepModal } from '@modules/payment/components/PaymentStepModal/PaymentStepModal';
import { InfoBox } from './components/InfoBox/InfoBox';
import { Skeleton, Typography } from '@soil-capital/ui-kit/material-core';
import { Trans } from 'react-i18next';
import { Grid } from '@soil-capital/ui-kit/material-core';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { RenewalCard } from './components/PricingCard/RenewalCard/RenewalCard';
import { NetOffCard } from './components/PricingCard/NetOffCard/NetOffCard';
import { PaymentStepModalSkeleton } from '@modules/payment/components/PaymentStepModal/PaymentStepModalSkeleton';
import { usePricingLogic } from './usePricingLogic';

export const Pricing = () => {
    const theme = useTheme();
    const {
        currency,
        isBelgian,
        isLoading,
        price,
        subscriptionPrice,
        payableHectares,
        isDeferredPayment,
        floorPrice,
        ceilingPrice,
        data,
        discount,
        discountEndDate,
        hasTriedToPay,
        deferredPaymentCta,
        onBackClick,
        nextYear,
    } = usePricingLogic();

    return !isLoading && data ? (
        <Grid display="flex" justifyContent="center">
            <PaymentStepModal
                stepProgress={0}
                currency={currency}
                ctaAction={deferredPaymentCta}
                ctaText="pages.payment.pricing.cta"
                onBackClick={onBackClick}
            >
                <Grid gap={theme.spacing(3)} display="flex" flexDirection="column" paddingTop={theme.spacing(5)}>
                    <InfoBox
                        currency={currency}
                        payableHectares={payableHectares}
                        isDeferredPayment={isDeferredPayment}
                        isBelgian={isBelgian}
                        pricePerHectare={data.price_amount}
                        floorPrice={floorPrice}
                        ceilingPrice={ceilingPrice}
                        price={price}
                    />
                    {isDeferredPayment ? (
                        <Typography variant="body2" sx={{ textAlign: 'center' }}>
                            <Trans i18nKey={'pages.payment.pricing.description.deferred-payment'} />
                        </Typography>
                    ) : (
                        <Grid display="flex" width="100%" gap={theme.spacing(1.5)}>
                            <NetOffCard
                                currency={currency}
                                subscriptionPrice={subscriptionPrice}
                                payableHectares={payableHectares}
                                isBelgian={isBelgian}
                                isCeiling={data.is_ceiling}
                                isFloor={data.is_floor}
                                pricePerHectare={data.price_amount}
                                nextYear={nextYear}
                            />
                            <RenewalCard
                                currency={currency}
                                price={price}
                                subscriptionPrice={subscriptionPrice}
                                payableHectares={payableHectares}
                                discount={discount}
                                discountEndDate={discountEndDate}
                                isBelgian={isBelgian}
                                isCeiling={data.is_ceiling}
                                isFloor={data.is_floor}
                                pricePerHectare={data.price_amount}
                                hasTriedToPay={hasTriedToPay}
                            />
                        </Grid>
                    )}
                </Grid>
            </PaymentStepModal>
        </Grid>
    ) : (
        <Grid display="flex" justifyContent="center">
            <PaymentStepModalSkeleton>
                <Grid display="flex" flexDirection="column">
                    <Grid display={'flex'} justifyContent={'center'} height={'150px'} alignItems={'stretch'}>
                        <Skeleton height={'100%'} width={'40%'} />
                    </Grid>
                    <Skeleton width={'100%'} height={'200px'} />
                </Grid>
            </PaymentStepModalSkeleton>
        </Grid>
    );
};
