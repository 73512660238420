import { useGetOperationDateString } from '@modules/encoding/modules/technicalItinerary/hooks/useGetOperationDateString';
import entities from '@shared/entities';
import { UseConfirmReuseOperationModalLogicProps } from './ConfirmReuseOperationModal.types';
import { useTranslation } from 'react-i18next';

export const useConfirmReuseOperationModalLogic = ({
    farmSeasonId,
    fieldCropId,
    data,
}: UseConfirmReuseOperationModalLogicProps) => {
    const { t } = useTranslation();
    const { getOperationDateString } = useGetOperationDateString();

    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: fieldCropId,
        farmSeasonId,
    });
    const { staticOperationTypeState } = entities.staticOperationType.useState();

    const sourceOperation = operationState.getById(data?.sourceOperationId);
    const targetOperation = operationState.getById(data?.targetOperationId);
    const sourceOperationType = staticOperationTypeState.getById(sourceOperation?.operation_type_id);
    const targetOperationType = staticOperationTypeState.getById(targetOperation?.operation_type_id);

    const sourceOperationName = t(sourceOperationType?.translation_slug ?? '');
    const sourceOperationDate = getOperationDateString(sourceOperation?.operation_date);

    const targetOperationName = t(targetOperationType?.translation_slug ?? '');
    const targetOperationDate = getOperationDateString(targetOperation?.operation_date);

    return { sourceOperationName, sourceOperationDate, targetOperationName, targetOperationDate };
};
