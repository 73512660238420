import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const usePacFieldUpdateFormToasts = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();

    const onFieldUpdated = () =>
        enqueueSnackbar(t('encoding-rotation.pac-file.update-field-toast'), { variant: 'success' });

    const onFieldDeleted = () =>
        enqueueSnackbar(t('encoding-rotation.pac-file.delete-field-toast'), { variant: 'success' });

    const onFieldsDeleted = () =>
        enqueueSnackbar(t('encoding-rotation.pac-file.delete-fields-toast'), { variant: 'success' });

    return {
        onFieldUpdated,
        onFieldDeleted,
        onFieldsDeleted,
    };
};
