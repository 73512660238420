import { makeStyles } from '@soil-capital/ui-kit/style';

export const useIntroductionContentPageStyles = makeStyles()((theme) => ({
    harvestYear: {
        color: theme.palette.success.main,
    },
    subText: {
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(1),
    },
    fieldArea: {
        margin: 'auto 0',
    },
}));
