import { z } from 'zod';
import { OperationFormSchema } from './OperationFormSchema';
import { HarvestFormDataT } from '../api/operationFormApi.types';

export const harvestFormSchema = new OperationFormSchema(
    {
        straws_usage_static_data_id: z.number().nullable(),
        machinery_id: z.number().nullable(),
        yield: z.number().nullable(),
        exported_straws_quantity: z.number().nullable(),
        companion_destruction_date: z.string().nullable(),
    },
    (apiData?: HarvestFormDataT) => ({
        companion_destruction_date: apiData?.companion_destruction_date ?? null,
        exported_straws_quantity: apiData?.exported_straws_quantity ?? null,
        machinery_id: apiData?.machinery_id ?? null,
        straws_usage_static_data_id: apiData?.straws_usage_static_data_id ?? null,
        yield: apiData?.yield ?? null,
    }),
    [],
);

export type HarvestFormSchemaT = z.infer<typeof harvestFormSchema.schema>;

export const HARVEST_FORM_INPUT_NAMES = harvestFormSchema.INPUT_NAMES;
