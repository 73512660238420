import { useForm } from '@shared/hooks';
import { useSeedingFormOptions } from '@modules/encoding/modules/technicalItinerary/module/seeding/hooks/useSeedingFormOptions';
import { useEffect } from 'react';
import { useOperationRouteParams } from '../../../hooks/useOperationRouteParams';
import { getOperationFormApi } from '../../../api/getOperationFormApi';
import { useOperationFormDefaultValues } from '../../../hooks/useOperationFormDefaultValues';
import { SEEDING_FORM_INPUT_NAMES, seedingFormSchema } from '../../../schema/seedingFormSchema';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';

export const useSeedingForm = () => {
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();
    const {
        formApiData,
        computeApiDataToMatchSchema,
        isFormLoading: isLoadingDefaultValues,
    } = useOperationFormDefaultValues('seeding');
    const { isLoading: isLoadingOptions, ...options } = useSeedingFormOptions();
    const [updateMutation] = getOperationFormApi('seeding').useUpdate();

    const methods = useForm({
        schema: seedingFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(formApiData),
        displaySnackbarOnSchemaError: true,
    });

    const isFormLoading = isLoadingDefaultValues || isLoadingOptions;
    const { handleSubmit, reset, setValue } = methods;

    useEffect(() => {
        if (formApiData) reset(computeApiDataToMatchSchema(formApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData]);

    const onSubmit = useStableDebounce(
        handleSubmit(async (data) => {
            try {
                await updateMutation({
                    farmSeasonId: seasonId,
                    fieldCropId: fieldCropId,
                    operationId: operationId,
                    body: data,
                });

                reset({}, { keepValues: true });
            } catch (e) {
                console.error(e);
            }
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    /**
     * set the default values for the new machinery
     */
    const changeMachineryRelatedInputs = () => {
        setValue(SEEDING_FORM_INPUT_NAMES.SOIL_WORK_WIDTH, null);
        setValue(SEEDING_FORM_INPUT_NAMES.MACHINERY_DEPTH, null);
        setValue(SEEDING_FORM_INPUT_NAMES.INTERROW_SPACING, null);
    };

    return { methods, onSubmit, isFormLoading, options, changeMachineryRelatedInputs };
};
