import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { FarmProfileFormSkeleton } from '@modules/encoding/modules/farmProfile/components/FarmProfileForm/FarmProfileFormSkeleton';
import { useFarmProfileOptions } from '@modules/encoding/modules/farmProfile/hooks/useFarmProfileOptions';
import { useFarmSeasonProfileComputed } from '@modules/encoding/modules/farmProfile/hooks/useFarmSeasonProfileComputed';
import { useFarmProfileProgress } from '@modules/encoding/modules/farmProfile/pages/FarmProfileActionPage/useFarmProfileProgress';
import { encodingShared } from '@modules/encoding/shared';
import { HelpBox } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, useParams } from 'react-router-dom';
import { FarmProfileForm } from '../../components/FarmProfileForm/FarmProfileForm';
import { useFarmProfileActionPageStyles } from './FarmProfileActionPage.style';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';

export const FarmProfileActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { seasonId } = useParams();
    const farmSeasonId = Number(seasonId as string);

    const { readOnly } = useFarmSeasonReadOnly();

    const { classes } = useFarmProfileActionPageStyles();
    const { t } = useTranslation();
    const { isLoading: isLoadingDefaultValues } = useFarmSeasonProfileComputed({
        farmSeasonId,
    });
    const { isLoading: isLoadingOptions } = useFarmProfileOptions();

    const { canContinue, isLoading: isLoadingProgress } = useFarmProfileProgress({ farmSeasonId });

    const isLoadingForm = isLoadingDefaultValues || isLoadingOptions;
    const isLoading = isLoadingForm || isLoadingProgress;

    const handleBackClick = () => navigate(`../${encodingRoutesConfig.PREPARATION}`);
    const handleGoNext = () => {
        navigate(`../${encodingRoutesConfig.ROTATION}`);
    };

    return (
        <encodingShared.ActionPage
            title={t('encoding-farm-profile.action-page.title')}
            leftButtonProps={{
                onClick: handleBackClick,
                children: t('constants.back'),
            }}
            rightButtonProps={{
                disabled: !canContinue,
                loading: isLoading,
                onClick: handleGoNext,
                children: t('constants.continue'),
            }}
            readOnly={readOnly}
        >
            <main className={classes.container}>
                <HelpBox>{t('encoding-farm-profile.help-box')}</HelpBox>
                {isLoadingForm ? <FarmProfileFormSkeleton /> : <FarmProfileForm />}
            </main>
        </encodingShared.ActionPage>
    );
};
