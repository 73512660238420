export const COVER_CROP_STATIC_DATA_TYPE = {
    COVER_CROP_DESTRUCTION: 'COVER_CROP_DESTRUCTION',
    COVER_CROP_HEIGHT: 'COVER_CROP_HEIGHT',
} as const;

export type CoverCropStaticDataTypeT = keyof typeof COVER_CROP_STATIC_DATA_TYPE;

type BaseStaticData = {
    id: number;
    name: string;
};

export type CoverCropDestructionStaticDataT = {
    type: typeof COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_DESTRUCTION;
    slug: string;
} & BaseStaticData;

export type CoverCropHeightStaticDataT = {
    type: typeof COVER_CROP_STATIC_DATA_TYPE.COVER_CROP_HEIGHT;
    slug: string;
} & BaseStaticData;

export type CoverCropStaticDataT = CoverCropDestructionStaticDataT | CoverCropHeightStaticDataT;
