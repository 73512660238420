import { makeStyles } from '@soil-capital/ui-kit/style';

export const useHarvestFormStyles = makeStyles()((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    hiddenForm: {
        visibility: 'hidden',
        height: 0,
    },
}));
