import useMapViewStyle from './useMapView.style';
import { MapControl } from './components/MapControl/MapControl';
import { useOpendata } from './hook/useOpendata';
import { FieldControls } from './components/FieldControls/FieldControls';
import { FieldsCreationModal } from '@modules/encoding/modules/rotation/components/FieldCreationModal/FieldsCreationModal';
import { MapBanner } from './components/MapBanner/MapBanner';
import useMapComponent from './useMapComponent';

export type MapComponentProps = {
    hasOpendata: boolean;
    coordinates?: [number, number];
    hasControls?: boolean;
    isDrawable?: boolean;
    helpText?: string;
    readOnly: boolean;
};

export const MapComponent = ({
    hasOpendata,
    coordinates,
    hasControls,
    isDrawable,
    helpText,
    readOnly,
}: MapComponentProps) => {
    const { classes } = useMapViewStyle();

    const {
        mapRef,
        zoomIn,
        zoomOut,
        recenterMap,
        onFieldCreationModalClose,
        fieldCreationModalOpen,
        fieldCreationModalPayload,
        onClick,
    } = useMapComponent(coordinates);

    useOpendata({ onClick, hasOpendata });

    return (
        <div ref={mapRef} className={classes.map}>
            {hasControls && (
                <MapControl
                    onHomeClick={() => recenterMap()}
                    onZoomInClick={() => zoomIn?.()}
                    onZoomOutClick={() => zoomOut?.()}
                />
            )}
            {isDrawable && <FieldControls readOnly={readOnly} />}
            {fieldCreationModalPayload && (
                <FieldsCreationModal
                    feature={fieldCreationModalPayload}
                    open={fieldCreationModalOpen}
                    onClose={onFieldCreationModalClose}
                />
            )}
            {helpText && <MapBanner helpText={helpText} />}
        </div>
    );
};
