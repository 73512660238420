import { type SeedingStaticDataTypeT } from '@modules/encoding/modules/technicalItinerary/module/seeding/seeding.types';
import { type SeedingStaticDataT } from '@modules/encoding/modules/technicalItinerary/module/seeding/api/seedingApi.types';
import api from '@shared/api';

export const seedingApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getSeedingStaticDataByType: builder.query<SeedingStaticDataT[], { type: SeedingStaticDataTypeT }>({
            query: ({ type }) => ({
                url: `/v3/static/static-data/${type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetSeedingStaticDataByTypeQuery } = seedingApi;
