import { useForm } from '@shared/hooks';
import { useEffect } from 'react';
import entities from '@shared/entities';
import { useDestructionFormOptions } from '../../hooks/useDestructionFormOptions';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { getOperationFormApi } from '@modules/encoding/modules/technicalItinerary/api/getOperationFormApi';
import { useOperationFormDefaultValues } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationFormDefaultValues';
import {
    DESTRUCTION_FORM_INPUT_NAMES,
    DestructionFormSchemaT,
    destructionFormSchema,
} from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';

export const useDestructionForm = () => {
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();

    const {
        computeApiDataToMatchSchema,
        formApiData,
        isFormLoading: isLoadingDefaultValues,
    } = useOperationFormDefaultValues('destruction');

    const { cropState } = entities.crop.useState({ farmSeasonId: Number(seasonId) });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: Number(seasonId) });

    const fieldCrop = fieldCropState.getById(fieldCropId);

    const hasCompanionCrop = !!fieldCrop?.companion_crop_id;

    // Form options
    const { isLoading: isLoadingOptions, ...options } = useDestructionFormOptions();

    const isFormLoading = isLoadingDefaultValues || isLoadingOptions || cropState.isLoading || fieldCropState.isLoading;

    // Mutations
    const [updateForm] = getOperationFormApi('destruction').useUpdate();

    /* -------------------------------- Form -------------------------------- */
    const methods = useForm<typeof destructionFormSchema.schema>({
        schema: destructionFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(formApiData),
        displaySnackbarOnSchemaError: true,
    });

    const { handleSubmit, reset, setValue } = methods;

    // Reset form default values when API data was fetched
    useEffect(() => {
        if (formApiData) reset(computeApiDataToMatchSchema(formApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData]);

    // Form callbacks
    const onSubmit = useStableDebounce(
        handleSubmit(async (data: DestructionFormSchemaT) => {
            try {
                await updateForm({
                    farmSeasonId: seasonId,
                    fieldCropId: fieldCropId,
                    operationId: operationId,
                    body: {
                        ...data,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    /**
     * set the default values for the new machinery
     */
    const changeMachineryRelatedInputs = () => {
        setValue(DESTRUCTION_FORM_INPUT_NAMES.DEPTH, null);
    };
    /**
     * set the default values for the new method
     */
    const changeMethodRelatedInputs = () => {
        setValue(DESTRUCTION_FORM_INPUT_NAMES.MACHINERY_ID, null);
        setValue(DESTRUCTION_FORM_INPUT_NAMES.DEPTH, null);
        setValue(DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_PESTICIDE_ID, null);
        setValue(DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_PESTICIDE_QUANTITY, null);
    };

    return {
        methods,
        hasCompanionCrop,
        options: {
            ...options,
        },
        isFormLoading,
        onSubmit,
        changeMachineryRelatedInputs,
        changeMethodRelatedInputs,
    };
};
