import { useEffect, useState } from 'react';
import entities from '@shared/entities';
import { useTranslation } from 'react-i18next';
import { useOperationRouteParams } from '../../hooks/useOperationRouteParams';
import { OPERATION_TYPE_SLUGS } from '@shared/entities/staticOperationType/staticOperationType.types';
import { COVER_CROP_FORM_INPUT_NAMES } from '../../schema/coverCropFormSchema';

export const useCoverCropActionPage = () => {
    const { t } = useTranslation();

    const { seasonId, fieldCropId } = useOperationRouteParams();

    const { staticOperationTypeState } = entities.staticOperationType.useState();

    const { operationState } = entities.operation.useState({
        farmSeasonFieldCropId: Number(fieldCropId),
        farmSeasonId: seasonId,
    });

    const [errors, setErrors] = useState<Map<string, string>>(new Map());

    useEffect(() => {
        const coverCropDestructionOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_DESTRUCTION
            );
        });
        const coverCropSeedingOperation = operationState.list.find((operation) => {
            return (
                staticOperationTypeState.getById(operation.operation_type_id)?.slug ===
                OPERATION_TYPE_SLUGS.COVER_CROP_MANAGEMENT_SEEDING
            );
        });
        if (!coverCropDestructionOperation?.operation_date || !coverCropSeedingOperation?.operation_date) {
            return;
        }
        const newErrors = new Map();
        if (
            new Date(coverCropDestructionOperation.operation_date) < new Date(coverCropSeedingOperation.operation_date)
        ) {
            newErrors.set(
                COVER_CROP_FORM_INPUT_NAMES.DESTRUCTION_DATE,
                t('encoding-technical-itinerary.timeline.cover-crop-dates-error-message'),
            );
        }
        setErrors(newErrors);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [operationState.list, t]);

    return {
        errors,
    };
};
