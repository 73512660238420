import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useComputeCropInfoBoxData } from '@modules/encoding/modules/rotation/helpers/useComputeCropInfoBoxData';

export const useFarmSeasonCropDeleteModalLogic = ({ farmSeasonCropId }: { farmSeasonCropId?: number }) => {
    const { cropState } = useGetFarmSeasonCrops();
    const fsc = cropState.getById(farmSeasonCropId);
    const { computeCropInfoBoxData } = useComputeCropInfoBoxData();
    const crop = fsc?.id ? computeCropInfoBoxData({ farmSeasonCropId: fsc.id }) : null;

    return {
        crop,
    };
};
