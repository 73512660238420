import { DiscountT } from '@modules/billing/api/billing.types';

export const sumPercentageDiscounts = (discounts: DiscountT[]) => {
    return discounts.reduce((total, discount) => {
        if (discount.type === 'percentage') {
            return total + discount.value;
        }
        return total;
    }, 0);
};
