import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useComputeCropInfoBoxData } from '@modules/encoding/modules/rotation/helpers/useComputeCropInfoBoxData';

export const useCropListLogic = () => {
    const { cropState } = useGetFarmSeasonCrops();
    const { computeCropInfoBoxData } = useComputeCropInfoBoxData();

    const cropList = cropState.list
        .map((fsc) => computeCropInfoBoxData({ farmSeasonCropId: fsc.id }))
        .sort((a, b) => a.cropName.localeCompare(b.cropName));

    return { cropList };
};
