import { NavigateFunction } from 'react-router-dom';
import { encodingShared } from '@modules/encoding/shared';
import { useTranslation } from 'react-i18next';
import { MineralFertiliserForm } from '../components/MineralFertiliserForm/MineralFertiliserForm';
import { HelpBox } from '@soil-capital/ui-kit/components';
import { useMineralFertiliserActionPageStyles } from './useMineralFertiliserActionPage.style';

export const MineralFertiliserActionPage = ({ navigate }: { navigate: NavigateFunction }) => {
    const { t } = useTranslation();
    const { classes } = useMineralFertiliserActionPageStyles();

    return (
        <encodingShared.OperationFormActionPage
            title={t('db.operation_type.fertilisation.mineral-fertilisation')}
            navigate={navigate}
        >
            <main className={classes.container}>
                <MineralFertiliserForm />
                <HelpBox>{t('encoding-technical-itinerary.mineral-fertiliser.help-box')}</HelpBox>
            </main>
        </encodingShared.OperationFormActionPage>
    );
};
