import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useLoginStyle } from './Login.style';
import { Typography, Divider, Grid } from '@soil-capital/ui-kit/material-core';
import { AuthLayout } from '@modules/auth/components/AuthLayout/AuthLayout';
import { Input, Button, IconButton } from '@soil-capital/ui-kit/components';
import { IconArrowRight, IconEyeOutlined, IconEyeOff } from '@soil-capital/ui-kit/icons';
import { useLoginLogic } from './useLoginLogic';
import { useTranslation } from 'react-i18next';
import { LOGIN } from './useLoginLogic';
import { useState } from 'react';
import Auth0Buttons from '@modules/auth/components/Auth0Buttons/Auth0Buttons';

export const Login = (): JSX.Element => {
    const { classes } = useLoginStyle();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { methods, onSubmit, loginLoading, errors, loginError } = useLoginLogic();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <AuthLayout
            form={
                <form id="login-form" onSubmit={onSubmit} className={classes.form}>
                    <div className={classes.group}>
                        <Input
                            id="email"
                            label={t('pages.login.email-address')}
                            name="email"
                            placeholder={t('pages.login.email.placeholder')}
                            type="email"
                            fullWidth
                            inputProps={{
                                ...methods.register(LOGIN.EMAIL),
                            }}
                        />
                        {errors[LOGIN.EMAIL] && (
                            <Typography variant="subcaption" color="error">
                                {errors[LOGIN.EMAIL]?.message}
                            </Typography>
                        )}
                        {loginError && (
                            <Typography variant="subcaption" color="error">
                                {loginError}
                            </Typography>
                        )}
                    </div>
                    <div className={classes.group}>
                        <Input
                            id="password"
                            label={t('pages.login.password')}
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            inputProps={{
                                ...methods.register(LOGIN.PASSWORD),
                            }}
                            placeholder={t('pages.login.password.placeholder')}
                            InputProps={{
                                endAdornment: (
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={() => setShowPassword(!showPassword)}
                                        edge="end"
                                    >
                                        {showPassword ? <IconEyeOff /> : <IconEyeOutlined />}
                                    </IconButton>
                                ),
                            }}
                        />
                        {errors[LOGIN.PASSWORD] && (
                            <Typography variant="subcaption" color="error">
                                {errors[LOGIN.PASSWORD]?.message}
                            </Typography>
                        )}
                        {loginError && (
                            <Typography variant="subcaption" color="error">
                                {loginError}
                            </Typography>
                        )}
                    </div>
                    <Typography
                        className={classes.forgotLink}
                        component={RouterLink}
                        to="../forgot-password"
                        variant="body2"
                    >
                        {t('pages.login.forgot-password')}
                    </Typography>
                    <Grid>
                        <Grid className={classes.loginButton}>
                            <Button
                                type="submit"
                                variant="contained"
                                endIcon={<IconArrowRight />}
                                loading={loginLoading}
                                size="large"
                            >
                                {t('constants.login')}
                            </Button>
                        </Grid>
                        <Divider className={classes.divider}>
                            <Typography color="GrayText" variant="subcaption" sx={{ whiteSpace: 'nowrap' }}>
                                {t('pages.login.create-account-question')}
                            </Typography>
                        </Divider>
                        <Grid width="100%" display="flex" justifyContent="center">
                            <Button onClick={() => navigate('../signup')} endIcon={<IconArrowRight />}>
                                {t('constants.signup')}
                            </Button>
                        </Grid>
                    </Grid>
                    <Divider>
                        <Typography color="GrayText" variant="subcaption" sx={{ whiteSpace: 'nowrap' }}>
                            {t('pages.auth.or')}
                        </Typography>
                    </Divider>
                    <Auth0Buttons />
                </form>
            }
            sideZone={<div className={classes.sideZoneImage}></div>}
            title={t('pages.login.log-in-title')}
        />
    );
};
