import useMainHeaderLayoutStyle from './MainHeaderLayout.style';
import { ReactElement } from 'react';

export type MainHeaderLayoutPropsT = {
    leftComponent?: ReactElement;
    middleComponent?: ReactElement;
    rightComponent?: ReactElement;
};
export default ({ leftComponent, middleComponent, rightComponent }: MainHeaderLayoutPropsT) => {
    const { classes } = useMainHeaderLayoutStyle();
    return (
        <header className={classes.header} data-testid="header">
            <div className={classes.leftComponent}>{leftComponent}</div>
            <div className={classes.middleComponent}>{middleComponent}</div>
            <div className={classes.rightComponent}>{rightComponent}</div>
        </header>
    );
};
