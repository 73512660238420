import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { usePacFieldUpdateFormToasts } from '@modules/encoding/modules/rotation/components/PacImportedFields/usePacFieldUpdateFormToasts';
import { useDeleteFarmSeasonField } from '@modules/encoding/shared/hooks/useDeleteFarmSeasonField';

export const useFarmSeasonFieldDeleteModalLogic = ({ fieldId, onClose }: { fieldId: number; onClose: () => void }) => {
    const { fieldState } = useGetFarmSeasonFields();
    const field = fieldState.getById(fieldId);

    const { onFieldDeleted } = usePacFieldUpdateFormToasts();
    const { deleteField, isLoading } = useDeleteFarmSeasonField({ onFieldDeleted });

    const onDelete = async () => {
        await deleteField(fieldId);
        onClose();
    };

    return { field, onDelete, isDeletingField: isLoading };
};
