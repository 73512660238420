import { makeStyles } from '@soil-capital/ui-kit/style';

const useMainHeaderLayoutStyle = makeStyles()(() => ({
    header: {
        height: '70px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    leftComponent: { flex: '1' },
    middleComponent: {},
    rightComponent: { flex: '1', display: 'flex', justifyContent: 'end' },
}));

export default useMainHeaderLayoutStyle;
