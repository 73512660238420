import { DestructionStaticDataT } from './destructionFormApi.types';
import { DestructionStaticDataTypeT } from '../destruction.types';
import api from '@shared/api';

export const destructionFormApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getDestructionFormStaticDataByType: builder.query<
            DestructionStaticDataT[],
            { type: DestructionStaticDataTypeT }
        >({
            query: ({ type }) => ({
                url: `/v3/static/static-data/${type}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useGetDestructionFormStaticDataByTypeQuery } = destructionFormApi;
