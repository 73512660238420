import { Controller, useFormContext } from 'react-hook-form';
import { PropsWithChildren } from 'react';
import { SelectableList, SelectableListItem } from '@soil-capital/ui-kit/components';

type ControlledSelectableListProps<T> = {
    className?: string;
    name: string;
    singleValue?: boolean;
    options: T[];
    getOptionKey: (option: T) => number;
    getOptionValue: (option: T) => number | number[];
    renderOption: (option: T, props: SelectableListItem) => JSX.Element;
};

export const ControlledSelectableList = <T,>({
    className,
    name,
    singleValue = false,
    options,
    getOptionKey,
    getOptionValue,
    renderOption,
}: PropsWithChildren<ControlledSelectableListProps<T>>) => {
    const context = useFormContext();

    if (!context) throw new Error('ControlledSelectableList must be used within a FormProvider');

    return (
        <SelectableList className={className}>
            <Controller
                name={name}
                render={({ field: inputField }) => {
                    return (
                        <>
                            {options.map((option) => {
                                const key = getOptionKey(option);
                                const value = getOptionValue(option);
                                let isSelected: boolean;

                                if (!inputField.value) isSelected = false;
                                else if (singleValue) isSelected = inputField.value === value;
                                else if (typeof value === 'number') isSelected = inputField.value.includes(value);
                                else isSelected = value.every((v: number) => inputField.value.includes(v));

                                const props = {
                                    key,
                                    isSelected,
                                    name: inputField.name,
                                    CheckboxProps: {
                                        value: value,
                                        onChange: (event: { target: { value: string } }, checked: boolean) => {
                                            if (singleValue) {
                                                const id = parseInt(event.target.value);
                                                return checked ? inputField.onChange(id) : inputField.onChange(null);
                                            }

                                            const ids = event.target.value.split(',').map((id: string) => Number(id));
                                            return checked
                                                ? inputField.onChange([...inputField.value, ...ids])
                                                : inputField.onChange(
                                                      inputField.value.filter((value: number) => {
                                                          return !ids.includes(value);
                                                      }),
                                                  );
                                        },
                                    },
                                };

                                return renderOption(option, props);
                            })}
                        </>
                    );
                }}
            />
        </SelectableList>
    );
};
