import { IconPenWritting, IconChevronDown, IconChevronUp } from '@soil-capital/ui-kit/icons';
import { Typography, Skeleton } from '@soil-capital/ui-kit/material-core';
import { Button, Timeline } from '@soil-capital/ui-kit/components';
import useSubscriptionCardStyle from './useSubscriptionCard.style';
import { useTranslation } from 'react-i18next';
import { PaymentStepTimeline } from './components/PaymentStepTimeline';
import { useSubscriptionCardLogic } from './useSubscriptionCardLogic';

export const SubscriptionCard = ({ farmId }: { farmId: number }) => {
    const { t } = useTranslation();
    const { classes } = useSubscriptionCardStyle();
    const { isExpanded, setIsExpanded, isLoading, payments } = useSubscriptionCardLogic(farmId);

    return isLoading ? (
        <Skeleton variant="rectangular" className={classes.skeleton} data-testid="skeleton" />
    ) : (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <IconPenWritting color="primary" />
                <Typography className={classes.title} variant="h2">
                    {t('billing.subscription.title')}
                </Typography>
            </div>
            <Timeline
                items={(isExpanded ? payments : payments.slice(0, 3)).map((payment) => ({
                    ...payment,
                    children: (
                        <PaymentStepTimeline
                            key={payment.children.id}
                            payment={payment.children}
                            status={payment.status}
                            farmId={farmId}
                        />
                    ),
                }))}
            />
            {payments.length > 3 && (
                <Button
                    variant="text"
                    color="primary"
                    sx={{ alignSelf: 'center' }}
                    size="small"
                    onClick={() => setIsExpanded(!isExpanded)}
                >
                    {isExpanded ? (
                        <>
                            <IconChevronUp />
                            {t('billing.subscription.show-less')}
                        </>
                    ) : (
                        <>
                            <IconChevronDown data-testid="show-more" />
                            {t('billing.subscription.show-more')}
                        </>
                    )}
                </Button>
            )}
        </div>
    );
};
