import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()(() => {
    return {
        map: {
            height: '100%',
            width: '100%',
            zIndex: 0,
        },
    };
});
