import { Grid, Typography } from '@soil-capital/ui-kit/material-core';
import { Loader } from '@soil-capital/ui-kit/components';
import { useTheme } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';

export const LoadingPayment = () => {
    const theme = useTheme();
    const { t } = useTranslation();

    return (
        <Grid
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            justifyContent="center"
            height="60vh"
            gap={theme.spacing(10)}
        >
            <Grid
                fontSize="128px"
                display="flex"
                justifyContent="space-evenly"
                alignItems="center"
                height="132px"
                width="132px"
            >
                <Loader size="inherit" thickness={4} />
            </Grid>
            <Grid display="flex" flexDirection="column" gap={theme.spacing(3)}>
                <Typography variant="h3">{t('pages.payment.processing.title')}</Typography>
                <Typography variant="body2">{t('pages.payment.processing.subtitle')}</Typography>
            </Grid>
        </Grid>
    );
};
