import { makeStyles } from '@soil-capital/ui-kit/style';
import happyFarmer from '@assets/images/happy-farmer.svg';

export default makeStyles()((theme) => ({
    imageContainer: {
        backgroundImage: `url(${happyFarmer})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        height: '140px',
        width: '140px',
        marginBottom: theme.spacing(5),
    },
    flexbox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: theme.spacing(1),
        textAlign: 'center',
        paddingBottom: theme.spacing(5),
    },
}));
