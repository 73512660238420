import { useGetNeedFinalizationStep } from '@modules/encoding/modules/rotation/hooks/useNeedFinalizationStep';
import rotationRoutes from '@modules/encoding/modules/rotation/rotation.routes';
import { encodingShared } from '@modules/encoding/shared';
import { useFarmSeasonReadOnly } from '@modules/encoding/shared/hooks/useFarmSeasonReadOnly';
import { useTrackMutation } from '@shared/api/segmentApi';
import { EVENTS } from '@shared/api/segmentApi.types';
import entities, { ProgressT } from '@shared/entities';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useToggle } from '@shared/hooks/useToggle';
import { NavigateFunction } from 'react-router-dom';

export const usePacImportActionPageLogic = ({ navigate }: { navigate: NavigateFunction }) => {
    const [track] = useTrackMutation();
    const { fieldState } = useGetFarmSeasonFields();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();
    const { farmState } = entities.farm.useState({ farmSeasonId: currentSeasonId });
    const { getFarmSnapshot } = encodingShared.useGetFarmSnapshot({ farmSeasonId: currentSeasonId });
    const {
        open: openValidateRotationModal,
        handleOpen: handleOpenValidateRotationModal,
        handleClose: onValidateRotationClose,
    } = useToggle();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const getProgressStepBySlug = (slug: ProgressT['slug']) => progressState.getBySlug(slug);

    const { needFinalization, isLoading: isLoadingFinalization } = useGetNeedFinalizationStep();

    const canContinue = fieldState?.list?.length > 0 && !isLoadingFinalization;
    const farmSnapshot = getFarmSnapshot();
    const rotationProgress = getProgressStepBySlug('rotation');

    const onConfirm = () => {
        if (rotationProgress?.progress_done !== rotationProgress?.progress_total) {
            track({
                event: EVENTS.FIELDS_DEFINITION_COMPLETED,
                properties: {
                    fields_number: fieldState?.list?.length,
                },
            });
        }
        needFinalization && rotationProgress?.progress_done !== rotationProgress?.progress_total
            ? handleOpenValidateRotationModal()
            : navigate(rotationRoutes.introduction());
    };

    const hasFields = fieldState?.list?.length > 0;

    const fields = fieldState?.list;

    const onValidateRotationWarningModalConfirm = () => {
        navigate(rotationRoutes.introduction());
    };

    const onValidateRotationWarningModalClose = () => {
        onValidateRotationClose();
    };

    const farmCoordinates = farmState.list[0]?.center?.geometry?.coordinates;

    const { readOnly } = useFarmSeasonReadOnly();

    return {
        canContinue,
        farmSnapshot,
        isLoading: fieldState.isLoading,
        onConfirm,
        hasFields,
        fields,
        openValidateRotationModal,
        onValidateRotationWarningModalClose,
        onValidateRotationWarningModalConfirm,
        farmCoordinates,
        readOnly,
    };
};
