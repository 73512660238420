import { usePacFieldListStyles } from '@modules/encoding/modules/rotation/components/PacImportedFields/PacFieldList/PacFieldList.style';
import { FieldCropInfoBox } from '@shared/components/FieldCropInfoBox/FieldCropInfoBox';
import { IconCross, IconEdit } from '@soil-capital/ui-kit/icons';
import { usePacFieldListLogic } from './usePacFieldListLogic';
import { List } from '@soil-capital/ui-kit/List';
import { Skeleton } from '@soil-capital/ui-kit/material-core';
import { useContext } from 'react';
import { MapContext } from '@shared/map/utils/MapProvider';
import { Button, Tooltip } from '@soil-capital/ui-kit/components';
import { useTranslation } from 'react-i18next';

type PacFieldListProps = {
    onEdit: (fieldId: number) => void;
    onDelete: (fieldId: number) => void;
    readOnly: boolean;
};

export const PacFieldList = ({ onEdit, onDelete, readOnly }: PacFieldListProps) => {
    const { classes } = usePacFieldListStyles();
    const { fields, isFetching } = usePacFieldListLogic();
    const mapContext = useContext(MapContext);
    if (!mapContext) {
        throw new Error('MapContext is null');
        return null;
    }
    const { recenterMapOnPolygons } = mapContext;
    const { t } = useTranslation();

    return isFetching ? (
        <div className={classes.skeletonList}>
            <Skeleton variant="rounded" width="100%" height={48} />
            <Skeleton variant="rounded" width="100%" height={48} />
            <Skeleton variant="rounded" width="100%" height={48} />
            <Skeleton variant="rounded" width="100%" height={48} />
            <Skeleton variant="rounded" width="100%" height={48} />
        </div>
    ) : (
        <List className={classes.list}>
            {fields.map((field) => (
                <FieldCropInfoBox
                    className={classes.fieldCropInfoBox}
                    key={field.id}
                    fieldName={field.name}
                    fieldArea={`${field.area} HA`}
                    mapSnapshot={field.snapshot}
                    handleFieldClick={() => recenterMapOnPolygons([field.polygon])}
                    icon={
                        !readOnly && (
                            <div className={classes.iconContainer}>
                                <Tooltip text={t('encoding-rotation.modal.field-list.edit')}>
                                    <Button variant="text" className={classes.button}>
                                        <IconEdit
                                            data-testid={`field-${field.id}-edit`}
                                            onClick={() => onEdit(field.id)}
                                            className={classes.iconEdit}
                                        />
                                    </Button>
                                </Tooltip>
                                <Tooltip text={t('encoding-rotation.modal.field-list.delete')}>
                                    <Button variant="text" className={classes.button}>
                                        <IconCross
                                            data-testid={`field-${field.id}-delete`}
                                            onClick={() => onDelete(field.id)}
                                            className={classes.iconCross}
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                        )
                    }
                />
            ))}
        </List>
    );
};
