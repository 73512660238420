import authSlice from '@modules/auth/store/authSlice';
import saveStatusSlice from '@modules/encoding/modules/layout/store/saveStatus.slice';
import { filterSlice } from '@modules/encoding/modules/rotation/store/filter.slice';
import { configureStore, createListenerMiddleware } from '@reduxjs/toolkit';
import api from '@shared/api';
import partnerPortalApi from '@shared/api/partnerPortalApi';
const listenerMiddleware = createListenerMiddleware();

export default configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        [authSlice.name]: authSlice.reducer,
        [saveStatusSlice.name]: saveStatusSlice.reducer,
        [filterSlice.name]: filterSlice.reducer,
        [partnerPortalApi.reducerPath]: partnerPortalApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            api.middleware,
            listenerMiddleware.middleware,
            partnerPortalApi.middleware,
        ),
});
