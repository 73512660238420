import { makeStyles } from '@soil-capital/ui-kit/style';

export const useUpdatePaymentMeanStyle = makeStyles()((theme) => ({
    error: {
        outline: `1px solid ${theme.palette.error.main}`,
        padding: theme.spacing(3),
        gap: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: theme.shape.borderRadiusM,
    },
    padding: {
        padding: theme.spacing(3),
        gap: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        borderRadius: theme.shape.borderRadiusM,
    },
    dark: { color: theme.palette.darkScale[900] },
}));
