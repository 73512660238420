import partnerPortalApi from '@shared/api/partnerPortalApi';
import { PartnerInvitationT, PartnerInvitationResponseT } from './partnerInvitation.type';

export const partnerInvitationApi = partnerPortalApi.injectEndpoints({
    endpoints: (builder) => ({
        getPartnerInvitation: builder.query<PartnerInvitationResponseT, { email: string }>({
            query: ({ email }) => ({
                url: `/private/invitations/farmer-email/${email}`,
                method: 'GET',
            }),
        }),
        putAcceptPartnerInvitation: builder.mutation<PartnerInvitationT, { invitationId: number }>({
            query: ({ invitationId }) => ({
                url: `/private/invitations/farmer/${invitationId}/accept`,
                method: 'PUT',
            }),
            invalidatesTags: ['PartnerInfo'],
        }),
        putDeclinePartnerInvitation: builder.mutation<PartnerInvitationT, { invitationId: number }>({
            query: ({ invitationId }) => ({
                url: `/private/invitations/farmer/${invitationId}/reject`,
                method: 'PUT',
            }),
            invalidatesTags: ['PartnerInfo'],
        }),
    }),
});

export const { usePutAcceptPartnerInvitationMutation, usePutDeclinePartnerInvitationMutation } = partnerInvitationApi;

export const useGetPartnerInvitationQuery = (params: { email: string | undefined; skip?: boolean }) => {
    return partnerInvitationApi.useGetPartnerInvitationQuery(
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        { email: params.email! },
        { skip: !params.email || params.skip },
    );
};
