import { Skeleton, Grid } from '@soil-capital/ui-kit/material-core';
import { ReactNode } from 'react';
import { usePaymentStepModalStyle } from './PaymentStepModal.style';

export const PaymentStepModalSkeleton = ({ children }: { children: ReactNode }) => {
    const { classes } = usePaymentStepModalStyle();

    return (
        <Grid className={classes.container}>
            <Grid display={'flex'} justifyContent={'space-between'} gap={'60px'} width={'100%'}>
                <Skeleton variant="circular" height="60px" width="60px" />
                <Skeleton variant="circular" height="60px" width="60px" />
                <Skeleton variant="circular" height="60px" width="60px" />
                <Skeleton variant="circular" height="60px" width="60px" />
            </Grid>
            {children}
        </Grid>
    );
};
