import { auth } from '@modules/auth';
import { CompanyT } from '@modules/billing/api/userCompany.types';
import { usePostPartnerPrepaidUserCompanyMutation } from '@modules/billing/api/userCompanyApi';
import entities from '@shared/entities';
import { useForm } from '@shared/hooks';
import { isValidIBAN, electronicFormatIBAN } from 'ibantools';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import * as z from 'zod';

export const IBAN = 'iban';

export const useUpdateIbanFormLogic = (
    refetch: () => void,
    t: (key: string) => string,
    nextAction: () => void,
    userCompany?: CompanyT,
    setIsSubmitting?: (isSubmitting: boolean) => void,
) => {
    const { farmState } = entities.farm.useState({ farmSeasonId: auth.useLatestFarmSeasonId() ?? 0 });
    const farm = farmState.list[0];
    const canEditIban = auth.usePermission('canEditIban');
    const defaultValues = { [IBAN]: userCompany?.iban ?? null };
    const schema = z.object({
        [IBAN]: z
            .string()
            .nullable()
            .transform((val) => (val === '' ? null : val))
            .refine(
                (iban) => {
                    if (iban) {
                        return Boolean(iban && isValidIBAN(electronicFormatIBAN(iban) as string));
                    }
                    return true;
                },
                { message: 'billing.subscription.billing-form.IBAN.error' },
            ),
    });
    const methods = useForm({ schema, defaultValues, displaySnackbarOnSchemaError: false, mode: 'onBlur' });

    const {
        handleSubmit,
        formState: { errors },
    } = methods;
    const [postPartnerPrepaidUserCompany, { isLoading: isFormPostFormLoading }] =
        usePostPartnerPrepaidUserCompanyMutation();

    const submitForm = handleSubmit(async (data) => {
        const response = await postPartnerPrepaidUserCompany({ farmId: farm.id, company: data });
        if ('error' in response && response.error) {
            enqueueSnackbar(t('pages.payment.billing-info.iban.error'), { variant: 'error' });
        } else {
            if (userCompany) {
                refetch();
            }
            nextAction();
        }
    });

    useEffect(() => {
        setIsSubmitting?.(isFormPostFormLoading);
    }, [setIsSubmitting, isFormPostFormLoading]);

    nextAction;

    return { defaultValues, methods, canEditIban, errors, submitForm };
};
