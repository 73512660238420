import { useNavigate } from 'react-router-dom';
import { useGetNextFarmSeasonProductPlanQuery, usePostCreatePaymentMutation } from '../api/paymentApi';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';
import { auth } from '@modules/auth';

export const useCreatePayment = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { currentSeason } = useCurrentSeason();
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const onboardingSeasonId = auth.useLatestFarmSeasonId();

    const { data: nextFarmSeasonProductPlan, isLoading: isNextFarmSeasonProductPlanLoading } =
        useGetNextFarmSeasonProductPlanQuery({
            farmSeasonId: activeSeason?.id,
        });
    const [postCreatePayment, { isLoading }] = usePostCreatePaymentMutation();

    const createPayment = async () => {
        if (currentSeason || onboardingSeasonId) {
            const response = await postCreatePayment({
                farmSeasonId: nextFarmSeasonProductPlan?.farm_season_id
                    ? nextFarmSeasonProductPlan?.farm_season_id
                    : activeSeason?.id
                    ? activeSeason?.id
                    : onboardingSeasonId
                    ? onboardingSeasonId
                    : currentSeason?.id ?? 0,
            });

            if ('data' in response) {
                if (nextFarmSeasonProductPlan) {
                    navigate(response.data.redirectUrl, { replace: true });
                } else {
                    window.location.href = response.data.redirectUrl;
                }
            } else if ('error' in response) {
                enqueueSnackbar(t('pages.payment.processing.failed.snackbar'), {
                    variant: 'error',
                });
            }
        }
    };

    return {
        createPayment,
        isLoading,
        isNextFarmSeasonProductPlanLoading,
    };
};
