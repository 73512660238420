import { auth } from '@modules/auth';
import { useGetOnboardingStepQuery } from '@modules/onboarding/api/onboardingApi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const useOnboardingLoginRedirection = () => {
    const authUser = auth.useAuthUser();
    const { data: onboardingStep, isLoading } = useGetOnboardingStepQuery({
        userId: authUser?.id,
    });
    const navigate = useNavigate();
    const farmSeasonId = auth.useLatestFarmSeasonId();
    const appUrl = auth.useAppUrl();
    let redirectURL = '/onboarding/eligibility';

    switch (onboardingStep?.onboarding_step) {
        case 'farm_creation':
            redirectURL = '/onboarding/eligibility';
            break;
        case 'livestock_eligibility':
            redirectURL = '/onboarding/eligibility/livestock';
            break;
        case 'not_eligible_livestock':
            redirectURL = '/onboarding/eligibility/livestock-non-eligible';
            break;
        case 'not_eligible_company_country':
        case 'not_eligible_farm_country':
            redirectURL = '/onboarding/eligibility/country-non-eligible';
            break;
        case 'not_eligible_land_conversion':
            redirectURL = '/onboarding/eligibility/non-eligible';
            break;
        case 'season_closed':
            redirectURL = '/onboarding/season-closed';
            break;
        case 'billing_information':
            redirectURL = '/onboarding/billing-information';
            break;
        case 'user_invitation':
            redirectURL = '/onboarding/invitation';
            break;
        case 'terms_and_conditions':
            redirectURL = '/onboarding/commitment';
            break;
        case 'pricing':
            redirectURL = '/onboarding/pricing';
            break;
        case 'enrolled':
            redirectURL = `/${farmSeasonId}`;
            break;
        case 'season_confirmation':
            redirectURL = '/onboarding/confirmation';
            break;
        default:
            redirectURL;
            break;
    }

    useEffect(() => {
        if (appUrl && appUrl !== location.origin) {
            window.location.replace(appUrl);
        }
        if (location.pathname !== redirectURL && !isLoading) {
            navigate(redirectURL);
        }
    }, [redirectURL, isLoading, navigate, appUrl]);

    return { isLoading };
};
