import { z } from 'zod';
import { useTranslation } from 'react-i18next';
import { useForm } from '@shared/hooks';
import authActions from '@modules/auth/store/authActions';
import { useState } from 'react';
import { enqueueSnackbar } from 'notistack';

export const LOGIN = {
    EMAIL: 'email',
    PASSWORD: 'password',
} as const;
type loginErrorT = {
    data: {
        code: string;
        entityId: null | number;
        entityType: null | string;
        message: string;
        name: string;
    };
    status: number;
};

export const useLoginLogic = () => {
    const { t } = useTranslation();

    const defaultValues = {
        [LOGIN.EMAIL]: '',
        [LOGIN.PASSWORD]: '',
    };
    const schema = z.object({
        [LOGIN.EMAIL]: z.string().email(t('validation.no-email-provided')),
        [LOGIN.PASSWORD]: z.string().min(1, t('validation.no-password-provided')),
    });
    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const {
        handleSubmit,
        formState: { errors },
    } = methods;

    const { login, loginLoading } = authActions.useLogin();

    const [loginError, setLoginError] = useState<string>('');

    const onSubmit = handleSubmit(async (data) => {
        try {
            setLoginError('');
            await login(data);
        } catch (error: unknown) {
            const loginError = error as loginErrorT;
            loginError.data?.message === 'Invalid credentials'
                ? setLoginError(t('validation.invalid-email-or-password'))
                : enqueueSnackbar(t('constants.server-error'), {
                      variant: 'error',
                  });
        }
    });

    return { methods, onSubmit, errors, loginLoading, loginError };
};
