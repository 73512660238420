import { useCallback } from 'react';
import { geoJsonFeatureT } from '../types/mapTypes';
import { BBox2d } from '@turf/helpers/dist/js/lib/geojson';
import bbox from '@turf/bbox';
import centroid from '@turf/centroid';
import bboxPolygon from '@turf/bbox-polygon';

export const useMap = (map: mapboxgl.Map | null, mapRef: React.RefObject<HTMLDivElement>) => {
    const recenterMapOnPolygons = useCallback(
        (polygonToCenter: geoJsonFeatureT[]) => {
            const bbox = calculateBoundingBox(polygonToCenter);

            if (map && polygonToCenter.length > 0) {
                map.fitBounds(bbox, { padding: 20 });
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        },
        [map],
    );

    const calculateCentroid = (polygonToCenter: geoJsonFeatureT[]): [number, number] | undefined => {
        if (polygonToCenter.length > 0) {
            const boundingBox = calculateBoundingBox(polygonToCenter);
            const bbox = bboxPolygon(boundingBox);
            const centroidResult = centroid(bbox);
            return centroidResult.geometry.coordinates as [number, number];
        } else return undefined;
    };

    // Calculate the smallest box that can fit the given polygon(s)
    const calculateBoundingBox = (polygonToCenter: geoJsonFeatureT[]): BBox2d => {
        return bbox({ type: 'FeatureCollection', features: polygonToCenter }) as BBox2d;
    };

    const centerMapOnCoordinates = (coordinates: [number, number]) => {
        if (map && coordinates) {
            map.flyTo({
                center: coordinates,
                zoom: 14,
                essential: true,
            });
        }
    };

    const zoomIn = () => {
        if (map) {
            const currentZoom = map.getZoom();
            const newZoom = currentZoom + 1;

            map.flyTo({
                zoom: newZoom,
                essential: true,
            });
        }
    };

    const zoomOut = () => {
        if (map) {
            const currentZoom = map.getZoom();
            const newZoom = currentZoom - 1;

            map.flyTo({
                zoom: newZoom,
                essential: true,
            });
        }
    };

    return {
        map,
        mapRef,
        calculateBoundingBox,
        recenterMapOnPolygons,
        calculateCentroid,
        centerMapOnCoordinates,
        zoomIn,
        zoomOut,
    };
};
