import { shapeEntity } from '../shapeEntity';
import farmApi, { farmApiInstance } from './farm.api';
import farmSchema from './farm.schema';
import farmState from './farm.state';

export default shapeEntity('Crop', {
    api: farmApiInstance,
    schema: farmSchema,
    ...farmApi,
    useState: farmState,
});
