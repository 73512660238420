import { useTranslation } from 'react-i18next';
import { useFinaliseAssignationWarningModalLogic } from '@modules/encoding/modules/rotation/components/FinaliseAssignationWarningModal/useFinaliseAssignationWarningModalLogic';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';

type FinaliseAssignationWarningModalProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const FinaliseAssignationWarningModal = ({ open, onClose, onConfirm }: FinaliseAssignationWarningModalProps) => {
    const { t } = useTranslation();
    const { fieldsWithoutCrop } = useFinaliseAssignationWarningModalLogic();

    return (
        <ConfirmModal
            isOpen={open}
            type="warning"
            onClose={({ confirmed }) => (confirmed ? onConfirm() : onClose())}
            title={t('encoding-rotation.modal.finalise-rotation-warning-modal.title')}
            primaryText={{
                translationKey: 'encoding-rotation.modal.finalise-rotation-warning-modal.warning',
                translationValues: {
                    count: fieldsWithoutCrop.length,
                },
            }}
            secondaryText={{ translationKey: 'encoding-rotation.modal.finalise-rotation-warning-modal.description' }}
            confirmText={t('encoding-rotation.modal.finalise-rotation-warning-modal.confirm')}
            cancelText={t('constants.continue')}
        />
    );
};
