import { useGetFarmSeasonPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { useStripe } from '@stripe/react-stripe-js';
import { useCallback, useEffect } from 'react';

export const useProcessingPaymentLogic = ({ farmSeasonId }: { farmSeasonId?: number }) => {
    const stripe = useStripe();
    const { data: paymentStatus } = useGetFarmSeasonPaymentStatusQuery({
        farmSeasonId: farmSeasonId,
    });

    const confirmStripePayment = useCallback(async () => {
        if (!paymentStatus?.client_secret) return;
        await stripe?.confirmPayment({
            clientSecret: paymentStatus?.client_secret,
            redirect: 'if_required',
        });
    }, [paymentStatus?.client_secret, stripe]);

    useEffect(() => {
        if (paymentStatus?.status === 'requires_action') {
            confirmStripePayment();
        }
    }, [paymentStatus, confirmStripePayment]);
};
