import { NavigateFunction, useParams } from 'react-router-dom';
import technicalItineraryRoutes, { TechnicalItineraryRouteParamsT } from '../technicalItinerary.routes';
import entities, { OperationT } from '@shared/entities';
import { useOperationListOrder } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationListOrder';
import { useFieldCropNeedFinalisation } from '@modules/encoding/modules/technicalItinerary/hooks/useFieldCropNeedFinalisation';
import { useHasAtLeastTwoFieldsForACrop } from '@shared/guards/HasAtLeastTwoFieldsForCropGuard';

/**
 * Provides the functions to navigate to the next or previous form in the technical itinerary.
 */
export const useTechnicalItineraryOperationFormRedirect = (
    currentOperation: OperationT | undefined,
    navigate: NavigateFunction,
) => {
    const { fieldCropId } = useParams<TechnicalItineraryRouteParamsT['operationForm']>();
    const { staticOperationTypeState } = entities.staticOperationType.useState();
    const hasAtLeastTwoFieldsForACrop = useHasAtLeastTwoFieldsForACrop();
    const {
        isLastOperation,
        areAllOperationsDone,
        getNextOperation,
        getNextNotDoneOperation,
        getPreviousOperation,
        sortedOperations,
    } = useOperationListOrder();
    const { doesFieldCropNeedFinalisation } = useFieldCropNeedFinalisation();

    const previousOperation = getPreviousOperation(currentOperation?.id);
    const navigateToPrevious = () => {
        if (previousOperation?.id) {
            const previousStepSlug = staticOperationTypeState.getById(previousOperation?.operation_type_id)?.slug;
            navigate(
                technicalItineraryRoutes.operationForm(
                    { fieldCropId: fieldCropId, operationId: previousOperation.id },
                    previousStepSlug,
                ),
            );
        } else {
            navigate(technicalItineraryRoutes.operationTimeline({ fieldCropId: fieldCropId }));
        }
    };

    if (
        currentOperation?.id &&
        isLastOperation(currentOperation?.id) &&
        doesFieldCropNeedFinalisation(Number(fieldCropId))
    )
        return {
            navigateToNext: () => navigate(technicalItineraryRoutes.introduction()),
            navigateToPrevious: navigateToPrevious,
        };

    // If there is no operation, redirect to the timeline
    if (sortedOperations.length === 0)
        return {
            navigateToNext: () => navigate(technicalItineraryRoutes.operationTimeline({ fieldCropId: fieldCropId })),
            navigateToPrevious: navigateToPrevious,
        };

    const isLastOperationBool = currentOperation?.id ? isLastOperation(currentOperation?.id) : false;

    if (isLastOperationBool) {
        if (!hasAtLeastTwoFieldsForACrop)
            return {
                navigateToNext: () => navigate(technicalItineraryRoutes.introduction()),
                navigateToPrevious: navigateToPrevious,
            };

        return {
            navigateToNext: () =>
                navigate(
                    technicalItineraryRoutes.operationDuplication({
                        fieldCropId: fieldCropId,
                    }),
                ),
            navigateToPrevious: navigateToPrevious,
        };
    }

    // If on operations timeline page, redirect to the first not done operation
    if (currentOperation === undefined && !areAllOperationsDone()) {
        const nextNotDoneOperation = getNextNotDoneOperation();
        const nextNotDoneOperationSlug = staticOperationTypeState.getById(
            nextNotDoneOperation?.operation_type_id,
        )?.slug;

        return {
            navigateToNext: () => {
                navigate(
                    technicalItineraryRoutes.operationForm(
                        {
                            fieldCropId: fieldCropId,
                            operationId: nextNotDoneOperation?.id ?? 0,
                        },
                        nextNotDoneOperationSlug,
                    ),
                );
            },
            navigateToPrevious: navigateToPrevious,
        };
    }

    // Otherwise, redirect to the next operation
    const nextOperation = getNextOperation(currentOperation?.id);
    const nextOperationSlug = staticOperationTypeState.getById(nextOperation.operation_type_id)?.slug;

    return {
        navigateToNext: () => {
            navigate(
                technicalItineraryRoutes.operationForm(
                    { fieldCropId: fieldCropId, operationId: nextOperation.id },
                    nextOperationSlug,
                ),
            );
        },
        navigateToPrevious: navigateToPrevious,
    };
};
