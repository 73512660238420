import { ProgressEndpointParamsT, ProgressT } from './progress.types';
import { EntityState } from '@reduxjs/toolkit';
import progressCache from './progress.cache';
import api from '@shared/api';

const url = (seasonId?: number) => `/v3/farm-seasons/${seasonId}/progress`;

export const progressApiInstance = api.injectEndpoints({
    endpoints: (build) => ({
        getProgress: build.query<EntityState<ProgressT>, ProgressEndpointParamsT>({
            query: ({ seasonId }) => ({
                url: url(seasonId),
                method: 'GET',
            }),
            transformResponse: progressCache.transformResponse,
            providesTags: ['Progress'],
        }),
    }),
});

export const { useGetProgressQuery } = progressApiInstance;

export default {
    useGet: progressApiInstance.useGetProgressQuery,
    useLazyGet: progressApiInstance.useLazyGetProgressQuery,
};
