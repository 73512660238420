import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetRenewalInformationQuery, useGetNextFarmSeasonProductPlanQuery } from '@modules/payment/api/paymentApi';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';

export const useNetOffLogic = () => {
    const [isLoading, setIsLoading] = useState(true);
    const isNextSeasonOpened = false;
    const navigate = useNavigate();
    const { currentSeason } = useCurrentSeason();
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);

    const farmId = currentSeason?.farm_id;
    const { data: renewalInformation } = useGetRenewalInformationQuery({ farmId });
    const { data: nextFarmSeasonProductPlan } = useGetNextFarmSeasonProductPlanQuery({
        farmSeasonId: activeSeason?.id,
    });
    // FP-7169: Implement the redirection to the new season
    const handleRedirect = () => {
        if (isNextSeasonOpened) {
            navigate('/rotation');
        } else navigate(`/${activeSeason?.id}`);
    };
    const harvestYear = renewalInformation?.harvest_year;
    const currency = renewalInformation?.currency.symbol ?? '€';
    const price = nextFarmSeasonProductPlan?.subtotal ?? 0;

    useEffect(() => {
        if (renewalInformation && nextFarmSeasonProductPlan) {
            setIsLoading(false);
        }
    }, [renewalInformation, nextFarmSeasonProductPlan]);

    return {
        isLoading,
        isNextSeasonOpened,
        handleRedirect,
        harvestYear,
        currency,
        price,
    };
};
