import { Box } from '@soil-capital/ui-kit/material-core';
import { SkeletonInputRow } from '@shared/components/form/SkeletonInputRow/SkeletonInputRow';

export const dataTestId = 'seeding-form-skeleton';

export const SeedingFormSkeleton = () => {
    return (
        <Box display="flex" flexDirection="column" gap={2} data-testid={dataTestId}>
            <SkeletonInputRow size="lg" variant="radio" />
            <SkeletonInputRow size="sm" variant="input" />
            <SkeletonInputRow size="lg" variant="input" />
        </Box>
    );
};
