import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { LoadingPayment } from '@modules/payment/pages/Processing/components/LoadingPayment/LoadingPayment';
import { usePaymentInProgressLogic } from './usePaymentInProgressLogic';

export const PaymentInProgress = () => {
    usePaymentInProgressLogic();
    return (
        <OnboardingStepsModal stepProgress={4} hideBackButton>
            <LoadingPayment />
        </OnboardingStepsModal>
    );
};
