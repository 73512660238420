import { Grid, Skeleton, useTheme } from '@soil-capital/ui-kit/material-core';

export const CommitmentFormSkeleton = () => {
    const theme = useTheme();

    return (
        <Grid
            display="flex"
            flexDirection="column"
            paddingX={theme.spacing(3)}
            paddingTop={theme.spacing(6)}
            paddingBottom={theme.spacing(6)}
            gap={theme.spacing(3)}
        >
            <Grid>
                <Skeleton variant="text" width="100%" height="22px" />
                <Skeleton variant="text" width="10%" height="22px" />
            </Grid>
            <Grid display="flex" gap={theme.spacing(1)}>
                <Skeleton variant="rectangular" width="22px" height="22px" />
                <Skeleton variant="text" width="100%" />
            </Grid>
            <Grid display="flex" gap={theme.spacing(1)}>
                <Skeleton variant="rectangular" width="22px" height="22px" />
                <Skeleton variant="text" width="100%" />
            </Grid>
        </Grid>
    );
};
