import { makeStyles } from '@soil-capital/ui-kit/style';

export default makeStyles()((theme) => ({
    container: { position: 'relative', paddingBottom: theme.spacing(4) },
    title: {
        color: theme.palette.darkScale[700],
        paddingLeft: theme.spacing(1),
    },
    textBlock: { display: 'flex', flexDirection: 'column', justifySelf: 'start !important' },
    textTooltip: { display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) },
    orangeText: { color: theme.palette.warning.main },
    redText: { color: theme.palette.error.main },
    content: {
        paddingTop: theme.spacing(1),
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        paddingLeft: theme.spacing(1),
        gap: theme.spacing(1.5),
        width: '100%',
        '& > :nth-child(2)': {
            justifySelf: 'center',
        },
    },
    greyContainer: {
        gridColumn: '1 / -1',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(2),
    },
    bold: { fontWeight: theme.typography.fontWeightBold },
    buttonStyle: { gridColumn: '3', justifySelf: 'end !important' },
    tooltip: {
        gridColumn: '3',
        justifySelf: 'end !important',
    },
    crossed: {
        textDecoration: 'line-through',
    },
}));
