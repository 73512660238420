import { Card, Button } from '@soil-capital/ui-kit/components';
import { useTranslation, Trans } from 'react-i18next';
import { IconArrowRight } from '@soil-capital/ui-kit/icons';
import { Typography, Skeleton } from '@soil-capital/ui-kit/material-core';
import { useUpdatePaymentMeanLogic } from './useUpdatePaymentMeanLogic';
import { useUpdatePaymentMeanStyle } from './UpdatePaymentMean.style';

export const UpdatePaymentMean = ({
    farmId,
    priceToPay,
    currency,
}: {
    farmId: number;
    priceToPay: number;
    currency: string;
}) => {
    const { t } = useTranslation();
    const { classes } = useUpdatePaymentMeanStyle();
    const { paymentMethod, isLoading, isRedirectUrlLoading, handleBillingPortalSession } =
        useUpdatePaymentMeanLogic(farmId);

    return isLoading ? (
        <Skeleton width="100%" height="154px" />
    ) : (
        <Card className={paymentMethod?.is_expired ? classes.error : classes.padding}>
            <Typography variant="body2">
                <Trans
                    i18nKey={
                        paymentMethod?.is_expired
                            ? 'pages.payment.billing-info.update-payment-mean.text-expired'
                            : 'pages.payment.billing-info.update-payment-mean.text'
                    }
                    values={{
                        price: priceToPay,
                        last_four_digits: paymentMethod?.last4,
                        currency,
                        payment_mean:
                            paymentMethod?.type === 'sepa_debit'
                                ? t('constants.bank-account')
                                : t('constants.bank-card'),
                    }}
                />
            </Typography>
            <Button
                className={classes.dark}
                variant="text"
                endIcon={<IconArrowRight />}
                loading={isRedirectUrlLoading}
                onClick={handleBillingPortalSession}
            >
                {t('pages.payment.billing-info.update-payment-mean.cta')}
            </Button>
        </Card>
    );
};
