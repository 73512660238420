import { FieldT } from '@shared/entities/field/field.types';

export const getTextLayoutLabel = (field: FieldT, cropNames?: string) => {
    return cropNames ? `${field.name} - ${field.area} ha  \n ${cropNames}` : `${field.name} \n - ${field.area} ha`;
};

export const getTextLayout = (field: FieldT, cropNames?: string): mapboxgl.SymbolLayout => {
    const text = getTextLayoutLabel(field, cropNames);

    return {
        'text-field': text,
        'text-size': 12,
        'text-font': ['literal', ['DIN Offc Pro Medium', 'Arial Unicode MS Regular']],
        'text-anchor': 'center',
        'text-max-width': 10,
    };
};

export const getTextLayerProps = (field: FieldT, prefix: string, cropNames?: string): mapboxgl.AnyLayer => {
    return {
        id: `${prefix}-${field.id}`,
        type: 'symbol',
        source: {
            type: 'geojson',
            data: field.polygon,
        },
        layout: getTextLayout(field, cropNames),
        paint: {
            'text-color': 'white',
            'text-halo-color': 'black',
            'text-halo-width': 1,
        },
    };
};
