import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { useGetFieldThumbnail } from '@modules/encoding/shared/hooks/useGetFieldThumbnail';
import { formatNumber } from '@shared/utils';

export const usePacFieldListLogic = () => {
    const { fieldState } = useGetFarmSeasonFields();
    const fields = fieldState.list;
    const { currentSeasonId } = useCurrentSeasonId();
    const { getFieldThumbnail } = useGetFieldThumbnail({ seasonId: currentSeasonId });
    const computedFields = fields.map((fsf) => ({
        ...fsf,
        area: formatNumber(fsf.area, 1),
        snapshot: getFieldThumbnail({ fieldId: fsf.id, updatedAt: fsf.updated_at }),
    }));

    return {
        fields: computedFields,
        isFetching: fieldState.isFetching,
    };
};
