import { ProgressEndpointParamsT, ProgressStatusT, ProgressT } from './progress.types';
import { createEntityAdapter } from '@reduxjs/toolkit';
import createCacheQueryHelper from '../createCacheQueryHelper';
import api from '@shared/api';

export const progressCacheAdapter = createEntityAdapter<ProgressT>({
    sortComparer: (progressA, progressB) => progressA.order - progressB.order,
});

const progressCacheQueryHelper = createCacheQueryHelper<ProgressEndpointParamsT, ProgressT, 'Progress'>(
    api,
    'Progress',
    progressCacheAdapter,
);

const getStatusFromPercentage = (percentage: number): ProgressStatusT => {
    return percentage === 100 ? 'done' : percentage > 0 ? 'ongoing' : 'not-started';
};

export default {
    transformResponse: (response: ProgressT[]) => {
        const items = response.map<ProgressT>((progress) => {
            const percentage = (progress.progress_done / progress.progress_total) * 100;
            return {
                ...progress,
                progress_percentage: percentage,
                status: getStatusFromPercentage(percentage),
            };
        });
        return progressCacheAdapter.setAll(progressCacheAdapter.getInitialState(), items);
    },
    update: progressCacheQueryHelper.update,
};
