import { useState, useEffect } from 'react';
import {
    useGetOnboardingLivestockQuery,
    usePostCreateFarmSeasonLivestockMutation,
} from '@modules/onboarding/api/onboardingApi';
import { auth } from '@modules/auth';
import { useNavigate } from 'react-router-dom';
import { useLivestockListLogic } from './useLivestockListLogic';
import { useLivestockFormsLogic } from './useLivestockFormsLogic';
import { enqueueSnackbar } from 'notistack';

export const useLivestockLogic = (t: (key: string) => string) => {
    const { livestockList, setSelectedLivestock, getFilteredLivestockList, handleLivestockChange } =
        useLivestockListLogic();
    const {
        addForm,
        removeForm,
        handleReceiveChildData,
        livestockFormRefs,
        LivestockFormsValues,
        livestockForms,
        setLivestockForms,
        setlivestockFormsCounter,
        defaultLivestockFormsValues,
        setDefaultLivestockFormsValues,
        submitForms,
        isSubmitting,
        setIsSubmitting,
    } = useLivestockFormsLogic({ setSelectedLivestock });

    const farmSeasonId = auth.useLatestFarmSeasonId();
    const navigate = useNavigate();
    const { data, isLoading } = useGetOnboardingLivestockQuery({ farmSeasonId });
    const [livestockMutation, { isLoading: isPostFormLoading }] = usePostCreateFarmSeasonLivestockMutation();
    const [isGettingDefaultValues, setIsGettingDefaultValues] = useState(true);
    const [disableAddFormButton, setDisableAddFormButton] = useState(false);

    // Submit the form
    useEffect(() => {
        if (
            farmSeasonId &&
            isSubmitting &&
            Object.keys(LivestockFormsValues).length === livestockForms.length &&
            location.pathname === '/onboarding/eligibility/livestock'
        ) {
            const submitData = async () => {
                const response = await livestockMutation({
                    body: { livestocks: LivestockFormsValues },
                    farmSeasonId: farmSeasonId,
                });
                setIsSubmitting(false);
                if ('data' in response) {
                    if (response.data.eligibility_status === 'eligible') {
                        navigate('/onboarding/eligibility/eligible');
                    } else {
                        navigate('/onboarding/eligibility/livestock-non-eligible');
                    }
                } else {
                    enqueueSnackbar(t('constants.server-error'), { variant: 'error' });
                }
            };
            submitData();
        }
    }, [
        t,
        isSubmitting,
        farmSeasonId,
        LivestockFormsValues,
        livestockMutation,
        livestockForms,
        navigate,
        data,
        setIsSubmitting,
    ]);

    // Populate forms with fetched data
    useEffect(() => {
        if (data && Object.keys(data).length > 0 && !isLoading && isGettingDefaultValues) {
            const initialForms = data.map((livestock, index) => ({
                number: index,
                data: livestock,
            }));
            setLivestockForms(initialForms.map((form) => form.number));
            setlivestockFormsCounter(initialForms.length + 1);
            setDefaultLivestockFormsValues(initialForms);
            setSelectedLivestock(initialForms.map((form) => form.data.livestock_id));
        }
    }, [
        data,
        isLoading,
        isGettingDefaultValues,
        setSelectedLivestock,
        setDefaultLivestockFormsValues,
        setLivestockForms,
        setlivestockFormsCounter,
    ]);

    // Check if the values are set to stop loading state
    useEffect(() => {
        if (defaultLivestockFormsValues.length === data?.length && isGettingDefaultValues && !isLoading) {
            setIsGettingDefaultValues(false);
        }
    }, [defaultLivestockFormsValues, isGettingDefaultValues, isLoading, data]);

    useEffect(() => {
        setDisableAddFormButton(livestockForms.length === livestockList.length);
    }, [livestockForms, livestockList]);

    return {
        livestockForms,
        addForm,
        removeForm,
        handleReceiveChildData,
        livestockFormRefs,
        submitForms,
        isPostFormLoading,
        isLoading,
        defaultLivestockFormsValues,
        isGettingDefaultValues,
        handleLivestockChange,
        getFilteredLivestockList,
        disableAddFormButton,
    };
};
