import authApi from '@modules/auth/api/authApi';
import { useForm } from '@shared/hooks';
import { enqueueSnackbar } from 'notistack';
import { useState, useEffect, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { z } from 'zod';

export const RESET = {
    EMAIL: 'email',
    PASSWORD: 'password',
    PASSWORDCONFIRMATION: 'password_confirmation',
    TOKEN: 'token',
} as const;

export const useResetPasswordLogic = () => {
    const { resetCode } = useParams();
    const navigate = useNavigate();
    const [msgOpen, setMsgOpen] = useState(false);
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setPassword(value);
    };
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email') || '';
    const defaultValues = {
        [RESET.EMAIL]: email,
        [RESET.PASSWORD]: '',
        [RESET.PASSWORDCONFIRMATION]: '',
        [RESET.TOKEN]: resetCode,
    };

    const schema = z.object({
        [RESET.EMAIL]: z.string().email(t('validation.no-email-provided')),
        [RESET.PASSWORD]: z
            .string()
            .min(1, t('validation.no-password-provided'))
            .min(8, t('validation.password-must-be-at-least-x-characters', { min: 8 }))
            .regex(/\d/, t('validation.password-must-at-least-contain-one-figure'))
            .regex(/[^A-Za-z0-9]/, t('validation.password-must-at-least-contain-one-special-character')),
        [RESET.PASSWORDCONFIRMATION]: z
            .string()
            .min(1, t('validation.no-password-confirmation'))
            .refine((data) => data === password, {
                message: t('validation.password-must-match'),
            }),
        [RESET.TOKEN]: z.string(),
    });

    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const {
        handleSubmit,
        formState: { errors },
    } = methods;

    const [resetPassword, { isLoading: resetPasswordLoading }] = authApi.useResetPasswordMutation();

    const onSubmit = handleSubmit(async (data) => {
        const response = await resetPassword(data);
        if ('error' in response) {
            enqueueSnackbar(t('constants.server-error'), { variant: 'error' });
        } else {
            setMsgOpen(true);
        }
    });

    useEffect(() => {
        if (msgOpen) {
            setTimeout(() => {
                navigate('/login');
            }, 8000);
        }
    }, [msgOpen, navigate]);

    return {
        resetPasswordLoading,
        msgOpen,
        t,
        methods,
        onSubmit,
        password,
        handlePasswordChange,
        errors,
    };
};
