import { BaseEntityT } from '../baseEntity.types';

export const CROP_SLUGS = {
    ALENOIS_WATERCRESS: 'alenois-watercress',
    ALFALFA: 'alfalfa',
    ANGELICA: 'angelica',
    ANIS: 'anis',
    ANNUAL_MEADOW_GRASS: 'annual-meadow-grass',
    ARTICHOKE: 'artichoke',
    ASPARAGUS: 'asparagus',
    AUBERGINE: 'aubergine',
    BASIL: 'basil',
    BEANS: 'beans',
    BEDSTRAW: 'bedstraw',
    BEETROOT: 'beetroot',
    BORAGE: 'borage',
    BROCOLI: 'brocoli',
    BRUSSEL_SPROUTS: 'brussel-sprouts',
    BUCKWHEAT: 'buckwheat',
    BURDOCK: 'burdock',
    CABBAGE: 'cabbage',
    CAMELINA: 'camelina',
    CANNING_PEAS: 'canning-peas',
    CARAWAY: 'caraway',
    CARINATE: 'carinate',
    CARROTS: 'carrots',
    CELERY: 'celery',
    CEREAL_LEGUME_ASSOCIATION: 'cereal-&-legume-association',
    CEREAL_OTHER_ASSOCIATION: 'cereal-&-other-association',
    CHAMOMILLE: 'chamomille',
    CHARD: 'chard',
    CHERVIL: 'chervil',
    CHICKPEAS: 'chickpeas',
    CHICORY: 'chicory',
    CHINISE_RADISH: 'chinise-radish',
    CHIVE: 'chive',
    CLOVER: 'clover',
    COMMON_VETCH: 'common-vetch',
    CORIANDER: 'coriander',
    CORNFLOWER: 'cornflower',
    CORNILLE: 'cornille',
    CORN_SEEDS: 'corn-seeds',
    COWPEA: 'cowpea',
    CREEPING_BUGLE: 'creeping-bugle',
    CUCUMBER_GHERKIN: 'cucumber/gherkin',
    CUMIN: 'cumin',
    CURCUMA: 'curcuma',
    DAIZY: 'daizy',
    DANDELION: 'dandelion',
    DILL: 'dill',
    DRY_BEAN: 'dry-bean',
    DURUM_SPRING_WHEAT: 'durum-spring-wheat',
    DURUM_WINTER_WHEAT: 'durum-winter-wheat',
    EARLY_CORN: 'early-corn',
    EARLY_SOYBEAN: 'early-soybean',
    EARTHNUT_PEA: 'earthnut-pea',
    ENDIVES: 'endives',
    FALLOW: 'fallow',
    FAVA_BEAN: 'fava-bean',
    FENNEL: 'fennel',
    FENUGREEK: 'fenugreek',
    FESCUE: 'fescue',
    FIELD_LETTUCE: 'field-lettuce',
    FODDER_BEETS: 'fodder-beets',
    FODDER_CABBAGE: 'fodder-cabbage',
    FODDER_CARROTS: 'fodder-carrots',
    FODDER_GRASS: 'fodder-grass',
    FODDER_GRASS_LEGUME: 'fodder-grass-legume',
    FODDER_IMMATURE_CEREAL: 'fodder-immature-cereal',
    FODDER_LEGUME: 'fodder-legume',
    FODDER_LENTIL: 'fodder-lentil',
    FODDER_MESLIN: 'fodder-meslin',
    FODDER_SORGHUM: 'fodder-sorghum',
    FORAGE_RADISH: 'forage-radish',
    FORAGE_TURNIP: 'forage-turnip',
    FOX_TILL_MILET: 'fox-till-milet',
    GARLIC: 'garlic',
    GERANIUM: 'geranium',
    GIANT_DAISY: 'giant-daisy',
    GRAIN_CORN: 'grain-corn',
    GREEN_GARLIC: 'green-garlic',
    GREEN_PEA_POD: 'green-pea-pod',
    GROUNDNUT: 'groundnut',
    HIMALAYAN_BLUE_POPPY: 'himalayan-blue-poppy',
    HOP: 'hop',
    HORSE_BEAN: 'horse-bean',
    JAROSSE: 'jarosse',
    JERUSALEM_ARTICHOKE: 'jerusalem-artichoke',
    LATE_CORN: 'late-corn',
    LATE_SOYBEAN: 'late-soybean',
    LAVENDER: 'lavender',
    LEEK: 'leek',
    LEGUMES_OTHERS: 'legumes---others',
    LEMON_BALM: 'lemon-balm',
    LENTIL: 'lentil',
    LOTTER: 'lotter',
    MALLOW_PLANT: 'mallow-plant',
    MEDICAL_PLANTS: 'medical-plants',
    MELON: 'melon',
    MILK_THISTLE: 'milk-thistle',
    MILLET: 'millet',
    MINETTE: 'minette',
    MINT: 'mint',
    MISCANTHUS: 'miscanthus',
    MOHA: 'moha',
    MUNG_BEAN: 'mung-bean',
    NETTLE: 'nettle',
    NIGER_WILDFLOWER: 'niger-wildflower',
    ONIONS_SHALLOTS: 'onions/shallots',
    ORCHARD_GRASS: 'orchard-grass',
    OREGANO: 'oregano',
    OTHER_ANNUAL_VEGETABLE_OR_FRUIT: 'other-annual-vegetable-or-fruit',
    OTHER_AROMATIC_PLANTS: 'other-aromatic-plants',
    OTHER_CEREALS: 'other-cereals',
    OTHER_OILSEEDS: 'other-oilseeds',
    PANSY: 'pansy',
    PARSLEY: 'parsley',
    PARSNIP: 'parsnip',
    PEANUT: 'peanut',
    PEPPER_PEPPER: 'pepper-pepper',
    PERENIAL_VEGETABLES_OTHERS: 'perenial-vegetables---others',
    PERMANENT_FALLOW: 'permanent-fallow',
    PERMANENT_PASTURE: 'permanent-pasture',
    PHACELIA: 'phacélia',
    PLANTAGO: 'plantago',
    POTATOES_FOR_CONSERVATION: 'potatoes-for-conservation',
    POTATOES_PLANT: 'potatoes-plant',
    POTATO_STRACH: 'potato-strach',
    PRIMROSE: 'primrose',
    PSYLLIUM: 'psyllium',
    QUINOA: 'quinoa',
    RADISH: 'radish',
    RHUBARB: 'rhubarb',
    RICE: 'rice',
    ROCKET: 'rocket',
    ROSEMARY: 'rosemary',
    RYEGRASS: 'ryegrass',
    SAFFLOWER: 'safflower',
    SAGE: 'sage',
    SAINFOIN: 'sainfoin',
    SALAD: 'salad',
    SALSIFY: 'salsify',
    SERRADELLE: 'serradelle',
    SILAGE_CORN: 'silage-corn',
    SMALL_RED_FRUITS: 'small-red-fruits',
    SMALL_SPELT: 'small-spelt',
    SORGHUM: 'sorghum',
    SOYA_SOYBEAN: 'soya/soybean',
    SPEEDWELL: 'speedwell',
    SPELT: 'spelt',
    SPINACH: 'spinach',
    SPRING_BARLEY_2_ROWS: 'spring-barley-2-rows',
    SPRING_CEREALS_OTHERS: 'spring-cereals---others',
    SPRING_FAVA_BEAN: 'spring-fava-bean',
    SPRING_FODDER_LUPINE: 'spring-fodder-lupine',
    SPRING_FODDER_PEAS: 'spring-fodder-peas',
    SPRING_MUSTARD: 'spring-mustard',
    SPRING_OAT: 'spring-oat',
    SPRING_OILSEED_FLAX: 'spring-oilseed-flax',
    SPRING_OILSEED_RAPE: 'spring-oilseed-rape',
    SPRING_PEAS: 'spring-peas',
    SPRING_PROTEIN_PEAS: 'spring-protein-peas',
    SPRING_RAPESEED: 'spring-rapeseed',
    SPRING_RYE: 'spring-rye',
    SPRING_SOFT_LUPINE: 'spring-soft-lupine',
    SPRING_TRITICALE: 'spring-triticale',
    SPRING_WHEAT: 'spring-wheat',
    SQUASH: 'squash',
    STINGING_NETTLE: 'stinging-nettle',
    ST_JOHNS_WORT: 'st-johns-wort',
    STRAWBERRY: 'strawberry',
    SUGAR_BEET: 'sugar-beet',
    SUMMER_SAVORY: 'summer-savory',
    SUNFLOWER: 'sunflower',
    SWEDISH_TURNIP_SWEEDE: 'swedish-turnip/-swede',
    SWEET_CORN: 'sweet-corn',
    SWEET_POTATOES: 'sweet-patatos',
    SWEET_YELLOW_CLOVER: 'sweet-yellow-clover',
    TARRAGON: 'tarragon',
    TEXTILE_HEMP: 'textile-hemp',
    TEXTIL_FLAX: 'textil-flax',
    THYME: 'thyme',
    TIMOTHY_GRASS: 'timothy-grass',
    TOBACCO: 'tobacco',
    TOMATO: 'tomato',
    TULIP: 'tulip',
    TURNIP: 'turnip',
    VALERIAN: 'valerian',
    VETIVER: 'vetiver',
    VINE: 'vine',
    WATERCRESS: 'watercress',
    WATER_MELON: 'water-melon',
    WINTER_BARLEY_2_ROWS: 'winter-barley-2-rows',
    WINTER_BARLEY_6_ROWS: 'winter-barley-6-rows',
    WINTER_CEREALS_OTHERS: 'winter-cereals---others',
    WINTER_FAVA_BEAN: 'winter-fava-bean',
    WINTER_FODDER_LUPINE: 'winter-fodder-lupine',
    WINTER_FODDER_PEAS: 'winter-fodder-peas',
    WINTER_MUSTARD: 'winter-mustard',
    WINTER_OAT: 'winter-oat',
    WINTER_OILSEED_FLAX: 'winter-oilseed-flax',
    WINTER_OILSEED_RAPE: 'winter-oilseed-rape',
    WINTER_PROTEIN_PEAS: 'winter-protein-peas',
    WINTER_RAPESEED: 'winter-rapeseed',
    WINTER_RYE: 'winter-rye',
    WINTER_SOFT_LUPINE: 'winter-soft-lupine',
    WINTER_TRITICALE: 'winter-triticale',
    WINTER_WHEAT: 'winter-wheat',
} as const;

export const NON_CULTIVATED_CROP_SLUGS = {
    FALLOW: CROP_SLUGS.FALLOW,
} as const;

export type StaticCropSlugT = (typeof CROP_SLUGS)[keyof typeof CROP_SLUGS];

export type StaticCropT = {
    translation_slug: string;
    slug: StaticCropSlugT;
    name: string;
    n_threshold?: number;
    p_threshold?: number;
    k_threshold?: number;
    cft_crop_id?: number;
    apparented_to_cover_crop?: boolean | null;
    opendata_residue_id?: number;
    machinery_id?: number;
    companion_opendata_residue_id?: number;
    is_permanent?: boolean;
    crop_group_id: number | null;
} & BaseEntityT;
