import { createEntityAdapter } from '@reduxjs/toolkit';
import api from '@shared/api';
import createCacheQueryHelper from '../createCacheQueryHelper';
import fieldCropCache from '../fieldCrop/fieldCrop.cache';
import getDefaultSortComparerById from '../getDefaultSortComparerById';
import { CropEndpointParamsT, CropT } from './crop.types';

export const cropCacheAdapter = createEntityAdapter<CropT>({ sortComparer: getDefaultSortComparerById<CropT>() });

const cropCacheQueryHelper = createCacheQueryHelper<CropEndpointParamsT, CropT, 'Crop'>(api, 'Crop', cropCacheAdapter);

const removeAndCascade: typeof cropCacheQueryHelper.remove = (optimisticStrategy) => {
    return async ({ id, farmSeasonId }, apiContext) => {
        cropCacheQueryHelper.remove(optimisticStrategy)({ id, farmSeasonId }, apiContext);
        fieldCropCache.removeWhere('farm_season_crop_id', id, optimisticStrategy)({ farmSeasonId }, apiContext);
    };
};

export default {
    transformResponse: cropCacheQueryHelper.transformResponse,
    add: cropCacheQueryHelper.add,
    remove: removeAndCascade,
    update: cropCacheQueryHelper.update,
};
