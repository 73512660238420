import { z } from 'zod';
import { SeedingFormDataT } from '../api/operationFormApi.types';
import { OperationFormSchema } from './OperationFormSchema';

export const seedingFormSchema = new OperationFormSchema(
    {
        seeding_origin: z.number().nullable(),
        seeding_quantity: z.number().nullable(),
        machinery_id: z.number().nullable(),
        machinery_depth: z.number().nullable(),
        soil_work_width: z.number().nullable(),
        interrow_spacing: z.number().nullable(),
    },
    (apidata?: SeedingFormDataT) => ({
        seeding_origin: apidata?.seeding_origin ?? null,
        seeding_quantity: apidata?.seeding_quantity ?? null,
        machinery_id: apidata?.machinery_id ?? null,
        machinery_depth: apidata?.machinery_depth ?? null,
        soil_work_width: apidata?.soil_work_width ?? null,
        interrow_spacing: apidata?.interrow_spacing ?? null,
    }),
    [],
);

export type SeedingFormSchemaT = z.infer<typeof seedingFormSchema.schema>;

export const SEEDING_FORM_INPUT_NAMES = seedingFormSchema.INPUT_NAMES;
