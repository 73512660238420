import { useContext } from 'react';
import { MapContext } from './MapProvider';

/**
 * get the map instance from the context. If the map is not initialized, an error is thrown.\
 * This to keep context internal to the map module.
 */
export const useMap = () => {
    const mapContext = useContext(MapContext);
    if (!mapContext) {
        throw new Error('MapContext is null');
    }

    if (!mapContext.map) {
        return null;
    }

    return mapContext.map;
};
