import { useTranslation } from 'react-i18next';
import { useGetHarvestFormStaticDataByTypeQuery } from '../api/harvestFormApi';
import { HARVEST_STATIC_DATA_TYPE } from '../harvest.types';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useGetCurrentOperationFromUrl } from '../../../hooks/useGetCurrentOperationFromUrl';

export const useHarvestFormOptions = () => {
    const { t } = useTranslation();
    const { operation } = useGetCurrentOperationFromUrl();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticMachineryState } = entities.staticMachinery.useState({ country_id: currentFarm?.country_id });

    const { data: strawsUsage, isLoading: isLoadingStrawsUsage } = useGetHarvestFormStaticDataByTypeQuery({
        type: HARVEST_STATIC_DATA_TYPE.HARVEST_STRAWS_USAGE,
    });

    const strawsUsageOptions = strawsUsage
        ?.map((el) => ({
            label: t(`static-data.slug.${el.slug}`),
            value: el.id,
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const harvestMachinery = operation ? staticMachineryState.getByOperationTypeId(operation?.operation_type_id) : [];
    const machineryOptions = harvestMachinery
        .map((machinery) => ({
            value: machinery.id,
            label: t(`db.machineries.${machinery.slug}`),
        }))
        .sort((a, b) => a.label.localeCompare(b.label));

    const isLoading = isLoadingStrawsUsage || staticMachineryState.isLoading;

    return {
        strawsUsage: strawsUsageOptions || [],
        machineries: machineryOptions || [],
        isLoading,
    };
};
