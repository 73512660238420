import { useNavigate } from 'react-router-dom';
import entities from '@shared/entities';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { useIsEncodingDoneGuard } from '@shared/guards/IsEncodingDoneGuard';
import { useProgressTracker } from '@shared/hooks/useProgressTracker';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetFarmPaymentStatusQuery } from '@modules/payment/api/paymentApi';
import { useGetCurrentRenewalDiscountQuery } from '@modules/billing/api/billingApi';
import { formatDateWithTranslatedMonth } from '@shared/utils/formatDateWithTranslatedMonth';
import { useTranslation } from 'react-i18next';
import { useGetActiveSeason } from '@shared/hooks/useGetLatestActiveSeason';
import { getHomePageStatus } from './getHomePageStatus';

export const useHomePageMessage = (isPartnerPrepaying: boolean) => {
    const { currentSeason } = useCurrentSeason();
    const { activeSeason } = useGetActiveSeason(currentSeason?.farm_id);
    const { staticSeasonState } = entities.staticSeason.useState();
    const activeSeasonYear = staticSeasonState.getById(activeSeason?.id)?.year;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { progressState } = entities.progress.useState(
        { seasonId: activeSeason?.id ?? currentSeason?.id ?? 0 },
        { skip: !activeSeason?.id && !currentSeason?.id },
    );
    const { nextStepToComplete, getRouteMatchingStep } = useProgressTracker();
    const { data: farmPaymentStatus } = useGetFarmPaymentStatusQuery({ farmId: currentSeason?.farm_id });
    const hasResultsPublished = activeSeason?.has_results_published;
    const hasToPayForBaseline = !!(
        farmPaymentStatus?.status === 'deferred' &&
        hasResultsPublished &&
        !isPartnerPrepaying
    );
    const { data: discount, isLoading: isCurrentRenewalDiscountLoading } = useGetCurrentRenewalDiscountQuery({
        farmId: currentSeason?.farm_id,
    });
    const isLoading = progressState.isLoading || isCurrentRenewalDiscountLoading || !currentSeason;
    const handleClick = (path: string) => navigate(path);

    const homePageStatus = getHomePageStatus({
        isSummaryValidated: !!currentSeason?.summary_validated_at,
        farmPaymentStatus: farmPaymentStatus?.status,
        hasResultsPublished: !!currentSeason?.has_results_published,
        isNextSeasonOpened: false, // FP-7169: Implement the season opening logic pls
        isPartnerPrepaying: isPartnerPrepaying,
        isEncodingDone: !!useIsEncodingDoneGuard(),
        isHistoryOngoing: progressState?.getBySlug(encodingRoutesConfig.HISTORY)?.status === 'ongoing',
        hasStartedEncoding: progressState?.getBySlug(encodingRoutesConfig.PREPARATION)?.status === 'done',
    });

    const getMessage = () => {
        if (homePageStatus === 'SELF_ENCODING_NOT_STARTED')
            return {
                title: 'homepage.messages.ready-to-start.title',
                description: 'homepage.messages.ready-to-start.description',
                action: {
                    label: 'homepage.messages.ready-to-start.cta',
                    link: `/${activeSeason?.id}/${encodingRoutesConfig.PREPARATION}`,
                },
            };

        if (homePageStatus === 'SELF_ENCODING_ONGOING') {
            const pathToRedirectTo =
                nextStepToComplete && getRouteMatchingStep(nextStepToComplete?.slug)
                    ? getRouteMatchingStep(nextStepToComplete?.slug)
                    : null;

            return {
                title: 'homepage.messages.encoding-pending.title',
                description: 'homepage.messages.encoding-pending.description',
                action: {
                    label: 'homepage.messages.encoding-pending.cta',
                    link: `/${activeSeason?.id}/${pathToRedirectTo ?? encodingRoutesConfig.PREPARATION}`,
                },
            };
        }
        if (homePageStatus === 'RESULTS_READY_DEFERRED_PAYMENT') {
            return {
                title: 'homepage.messages.deferred-payment.title',
                description: 'homepage.messages.deferred-payment.description',
                descriptionValues: {
                    year: activeSeasonYear,
                },
                action: {
                    label: 'homepage.messages.deferred-payment.cta',
                    link: `/${activeSeason?.id}/payment/pricing`,
                },
            };
        }
        if (homePageStatus === 'RESULTS_READY_RENEWAL_PAYMENT') {
            return {
                title: 'homepage.messages.renewal.title',
                description: discount
                    ? 'homepage.messages.renewal-discount.description'
                    : 'homepage.messages.renewal.description',
                descriptionValues: {
                    discount_amount: discount?.value,
                    discount_end_date: discount?.end_date && formatDateWithTranslatedMonth(discount?.end_date, t),
                },
                action: {
                    label: 'homepage.messages.renewal.cta',
                    link: `/${activeSeason?.id}/payment/pricing`,
                },
            };
        }

        if (homePageStatus === 'RESULTS_READY_NO_PAYMENT') {
            return {
                title: 'homepage.messages.results-ready.title',
                description: 'homepage.messages.results-ready.description',
                action: {
                    label: 'homepage.messages.results-ready.cta',
                    link: `/${activeSeason?.id}/results`,
                },
            };
        }

        if (homePageStatus === 'PAYMENT_DONE_NEXT_SEASON_CLOSED') {
            return {
                title: 'homepage.messages.next-season-closed.title',
                description: 'homepage.messages.next-season-closed.description',
                action: {
                    label: 'homepage.messages.next-season-closed.cta',
                    link: `/${activeSeason?.id}/results`,
                },
            };
        }

        if (homePageStatus === 'RESULTS_BEING_REVIEWED') {
            return {
                title: 'homepage.messages.results-being-reviewed.title',
                description: 'homepage.messages.results-being-reviewed.description',
            };
        }

        if (homePageStatus === 'NEXT_SEASON_OPEN') {
            return {
                title: 'homepage.messages.next-season-open.title',
                description: 'homepage.messages.next-season-open.description',
            };
        }

        if (homePageStatus === 'SELF_ENCODING_DONE') {
            return {
                title: 'homepage.messages.self-encoding-done.title',
                description: 'homepage.messages.self-encoding-done.description',
            };
        }

        return {
            title: '',
            description: '',
        };
    };
    const message = getMessage();

    return { isLoading: isLoading, message, handleClick, hasToPayForBaseline };
};
