import { useToggle } from '@shared/hooks/useToggle';
import { useState } from 'react';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetFarmSeasonOperations } from '@shared/hooks/useGetFarmSeasonOperations';
import { useDeleteFarmSeasonField } from '@modules/encoding/shared/hooks/useDeleteFarmSeasonField';
import { usePacFieldUpdateFormToasts } from '@modules/encoding/modules/rotation/components/PacImportedFields/usePacFieldUpdateFormToasts';

export const usePacFieldUpdateFormLogic = () => {
    const [fieldId, setFieldId] = useState<number | null>(null);
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { operationState } = useGetFarmSeasonOperations();
    const { onFieldDeleted } = usePacFieldUpdateFormToasts();
    const { deleteField } = useDeleteFarmSeasonField({ onFieldDeleted });

    const {
        open: openUpdateFieldModal,
        handleOpen: handleOpenUpdateFieldModal,
        handleClose: onCloseUpdateFieldModal,
    } = useToggle();

    const {
        open: openDeleteFieldModal,
        handleOpen: handleOpenDeleteFieldModal,
        handleClose: onCloseDeleteFieldModal,
    } = useToggle();

    const onEdit = (fieldId: number) => {
        setFieldId(fieldId);
        handleOpenUpdateFieldModal();
    };

    const doesFieldHaveOperations = (fieldId: number) => {
        const fieldCrops = fieldId ? fieldCropState?.getByFieldId({ fieldId }) : [];
        return !!fieldCrops.find((fieldCrop) => {
            const operations = operationState.getByFarmSeasonFieldCropId(fieldCrop.id);

            return operations.length > 0;
        });
    };

    const onDelete = async (fieldId: number) => {
        if (doesFieldHaveOperations(fieldId)) {
            setFieldId(fieldId);
            return handleOpenDeleteFieldModal();
        }

        return deleteField(fieldId);
    };

    return {
        fieldId,
        openUpdateFieldModal,
        onEdit,
        onCloseUpdateFieldModal,
        openDeleteFieldModal,
        onDelete,
        onCloseDeleteFieldModal,
    };
};
