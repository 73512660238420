import { useLogErrorMutation } from '@shared/api/logErrorApi';
import { ErrorInfo } from 'react';

export default () => {
    const [logErrorMutation] = useLogErrorMutation();

    const logError = (error: Error, info: ErrorInfo) => {
        const errorMessage = '' + error + info.componentStack;
        console.error('Error during render:', errorMessage);
        logErrorMutation({ errors: [errorMessage] });
    };

    return { logError };
};
