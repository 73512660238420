import {
    FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY,
    FarmProfileStaticDataSlugsKeysT,
} from '@modules/encoding/modules/farmProfile/constants';
import { MultipleSelectCheckmarksProps } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { ForwardedRef, forwardRef } from 'react';
import { FarmProfileStaticDataT } from '@modules/encoding/modules/farmProfile/api/farmProfileApi.types';
import { MultiSelectCheckbox } from '@shared/components/form/MultiSelectCheckbox/MultiSelectCheckbox';

type FarmProfileSelectProps = {
    name: string;
    onChange?: (values: FarmProfileStaticDataT[]) => void;
    options: FarmProfileStaticDataT[];
} & Omit<
    MultipleSelectCheckmarksProps<FarmProfileStaticDataT>,
    'options' | 'getOptionValue' | 'getOptionLabel' | 'getOptionKey' | 'name' | 'onChange' | 'values' | 'onBlur'
>;

export const FarmProfileMultiSelect = forwardRef(
    ({ className, name, options, ...rest }: FarmProfileSelectProps, ref: ForwardedRef<HTMLDivElement>) => {
        const { t } = useTranslation();

        return (
            <MultiSelectCheckbox
                ref={ref}
                className={className}
                name={name}
                options={options}
                getOptionLabel={(option) =>
                    t(
                        FARM_PROFILE_STATIC_DATA_SLUGS_TO_TRANSLATION_KEY[
                            option?.slug as FarmProfileStaticDataSlugsKeysT
                        ],
                    )
                }
                getOptionValue={(option) => option.slug}
                getOptionKey={(option) => Number(option.id)}
                {...rest}
            />
        );
    },
);
