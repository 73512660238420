import { useConfirmReuseOperationModalLogic } from './useConfirmReuseOperationModalLogic';
import { ConfirmReuseOperationModalProps } from './ConfirmReuseOperationModal.types';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '@shared/components/ConfirmModal/ConfirmModal';

export const ConfirmReuseOperationModal = ({
    data,
    isOpen,
    onClose,
    fieldCropId,
    farmSeasonId,
}: ConfirmReuseOperationModalProps) => {
    const { t } = useTranslation();

    const { sourceOperationName, sourceOperationDate, targetOperationName, targetOperationDate } =
        useConfirmReuseOperationModalLogic({ fieldCropId, farmSeasonId, data });

    return (
        <ConfirmModal
            isOpen={isOpen}
            onClose={onClose}
            title={t('encoding-technical-itinerary.reuse-operation-component.modal-title')}
            primaryText={{
                translationKey: 'encoding-technical-itinerary.reuse-operation-component.modal-source-text',
                translationValues: { operationName: sourceOperationName, operationDate: sourceOperationDate },
            }}
            secondaryText={{
                translationKey: 'encoding-technical-itinerary.reuse-operation-component.modal-target-text',
                translationValues: { operationName: targetOperationName, operationDate: targetOperationDate },
            }}
            confirmText={t('encoding-technical-itinerary.reuse-operation-component.modal-confirm-button')}
            cancelText={t('constants.cancel')}
        />
    );
};
