import { z } from 'zod';
import { computeFormInputNames } from '@shared/utils';
import { DefaultValues } from 'react-hook-form';

export const customStaticFertiliserFormSchema = z.object({
    fertiliser_name: z.string().nullable(),
    n: z
        .number({
            invalid_type_error: 'encoding-technical-itinerary.custom-static-fertiliser.n-p-k-required-error',
        })
        .min(0, 'encoding-technical-itinerary.custom-static-fertiliser.min-0-error')
        .max(100, 'encoding-technical-itinerary.custom-static-fertiliser.max-100-error')
        .multipleOf(0.01, 'encoding-technical-itinerary.custom-static-fertiliser.precision-001-error'),
    p: z
        .number({
            invalid_type_error: 'encoding-technical-itinerary.custom-static-fertiliser.n-p-k-required-error',
        })
        .min(0, 'encoding-technical-itinerary.custom-static-fertiliser.min-0-error')
        .max(100, 'encoding-technical-itinerary.custom-static-fertiliser.max-100-error')
        .multipleOf(0.01, 'encoding-technical-itinerary.custom-static-fertiliser.precision-001-error'),
    k: z
        .number({
            invalid_type_error: 'encoding-technical-itinerary.custom-static-fertiliser.n-p-k-required-error',
        })
        .min(0, 'encoding-technical-itinerary.custom-static-fertiliser.min-0-error')
        .max(100, 'encoding-technical-itinerary.custom-static-fertiliser.max-100-error')
        .multipleOf(0.01, 'encoding-technical-itinerary.custom-static-fertiliser.precision-001-error'),
    s: z
        .number()
        .min(0, 'encoding-technical-itinerary.custom-static-fertiliser.min-0-error')
        .max(100, 'encoding-technical-itinerary.custom-static-fertiliser.max-100-error')
        .multipleOf(0.01, 'encoding-technical-itinerary.custom-static-fertiliser.precision-001-error')
        .nullable(),
    mg: z
        .number()
        .min(0, 'encoding-technical-itinerary.custom-static-fertiliser.min-0-error')
        .max(100, 'encoding-technical-itinerary.custom-static-fertiliser.max-100-error')
        .multipleOf(0.01, 'encoding-technical-itinerary.custom-static-fertiliser.precision-001-error')
        .nullable(),
    ca: z
        .number()
        .min(0, 'encoding-technical-itinerary.custom-static-fertiliser.min-0-error')
        .max(100, 'encoding-technical-itinerary.custom-static-fertiliser.max-100-error')
        .multipleOf(0.01, 'encoding-technical-itinerary.custom-static-fertiliser.precision-001-error')
        .nullable(),
});
export type CustomStaticFertiliserFormSchemaT = z.infer<typeof customStaticFertiliserFormSchema>;
export type CustomStaticFertiliserFormValuesT = DefaultValues<z.infer<typeof customStaticFertiliserFormSchema>>;
export const customStaticFertiliserFormDefaultValues: CustomStaticFertiliserFormValuesT = {
    n: undefined,
    p: undefined,
    k: undefined,
    ca: undefined,
    mg: undefined,
    s: undefined,
    fertiliser_name: undefined,
};

export const CUSTOM_STATIC_FERTILISER_FORM_INPUT_NAMES = computeFormInputNames(customStaticFertiliserFormSchema.shape);
