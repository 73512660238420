import ChangePasswordLayout from '@modules/auth/components/ChangePasswordLayout/ChangePasswordLayout';
import { Grid, Typography, useTheme } from '@soil-capital/ui-kit/material-core';
import { useResetPasswordLogic } from './useResetPasswordLogic';
import { ResetPasswordForm } from './components/ResetPasswordForm/ResetPasswordForm';

export const ResetPassword = () => {
    const theme = useTheme();
    const { msgOpen, t, methods, onSubmit, resetPasswordLoading, password, handlePasswordChange, errors } =
        useResetPasswordLogic();

    return (
        <ChangePasswordLayout>
            {msgOpen ? (
                <Grid
                    paddingTop={theme.spacing(10)}
                    paddingBottom={theme.spacing(10)}
                    display="flex"
                    flexDirection="column"
                    gap={theme.spacing(6)}
                >
                    <img src="/assets/images/check-circle.svg" height="64px" />
                    <Grid display="flex" flexDirection="column" gap={theme.spacing(2)}>
                        <Typography variant="h3" textAlign="center">
                            {t('pages.reset-password.confirmation.title')}
                        </Typography>
                        <Typography variant="body2" textAlign="center">
                            {t('pages.reset-password.confirmation.subtitle')}
                        </Typography>
                    </Grid>
                </Grid>
            ) : (
                <ResetPasswordForm
                    t={t}
                    onSubmit={onSubmit}
                    resetPasswordLoading={resetPasswordLoading}
                    errors={errors}
                    methods={methods}
                    handlePasswordChange={handlePasswordChange}
                    password={password}
                />
            )}
        </ChangePasswordLayout>
    );
};
