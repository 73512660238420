import { formatNumber } from '@shared/utils';
import entities from '@shared/entities';
import getCropGroupImg from '@modules/encoding/shared/utils/getCropGroupImg';
import { useTranslation } from 'react-i18next';
import { useGetFarmSeasonCrops } from '@shared/hooks/useGetFarmSeasonCrops';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { encodingShared } from '@modules/encoding/shared';

export type CropInfoBoxDataT = ReturnType<typeof useComputeCropInfoBoxData>;

export const useComputeCropInfoBoxData = () => {
    const { t } = useTranslation();
    const { cropState } = useGetFarmSeasonCrops();
    const { fieldState } = useGetFarmSeasonFields();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticCropGroupState } = entities.staticCropGroup.useState({ countryId: currentFarm?.country_id });

    const computeCropInfoBoxData = ({ farmSeasonCropId }: { farmSeasonCropId: number }) => {
        const farmSeasonCrop = cropState.list.find((c) => c.id === farmSeasonCropId);
        const crop = staticCropState.list?.find((c) => farmSeasonCrop?.crop_id === c.id);
        const cropGroup = staticCropGroupState.list?.find((cg) => cg.id === crop?.crop_group_id);

        const cropName = t(`db.crop.slug-${crop?.slug}`) ?? '';
        const fieldCrops = fieldCropState.list.filter((fsfc) => fsfc.farm_season_crop_id === farmSeasonCropId);
        const numberOfAssignedFields = fieldCrops.length;
        const assignedFieldsTotalArea = fieldCrops.reduce((acc, fieldCrop) => {
            const fieldId = fieldCrop.farm_season_field_id;
            const fieldArea = fieldState.list?.find((field) => field.id === fieldId)?.area;

            return acc + (fieldArea ?? 0);
        }, 0);
        const formattedAssignedFieldsTotalArea = formatNumber(assignedFieldsTotalArea, 1, 0);

        const cropImage = getCropGroupImg(cropGroup?.slug);

        return {
            id: farmSeasonCropId,
            cropName,
            cropImage,
            numberOfAssignedFields,
            assignedFieldsTotalArea: formattedAssignedFieldsTotalArea,
        };
    };

    return { computeCropInfoBoxData };
};
