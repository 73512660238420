import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';
import { useGetProgressStepMatchingCurrentRoute } from '@shared/hooks/useGetProgressStepMatchingCurrentRoute';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import entities from '@shared/entities';
import { useArePreviousStepsCompleted } from '@shared/hooks/useArePreviousStepsCompleted';

export const useArePreviousStepCompletedGuard = () => {
    const { currentSeasonId } = useCurrentSeasonId();
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const { currentProgressStep, isLoading: isLoadingCurrentProgressStep } = useGetProgressStepMatchingCurrentRoute();
    const { arePreviousStepsCompleted } = useArePreviousStepsCompleted({ farmSeasonId: currentSeasonId });

    const isLoading = progressState.isLoading || isLoadingCurrentProgressStep;

    if (isLoading) return undefined;
    if (!currentProgressStep || progressState?.list?.length === 0) return false;

    return arePreviousStepsCompleted(currentProgressStep.slug);
};

export const ArePreviousStepCompletedGuard = (props: GuardT) => {
    const passed = useArePreviousStepCompletedGuard();

    return <BaseGuard passed={passed} {...props} />;
};
