import useOperationDatePickerStyle from './useOperationDatePicker.style';

import type { OperationDatePickerPropsT, Period } from './OperationDatePicker.types';
import { Button, MenuItem, Select } from '@soil-capital/ui-kit/components';
import { IconArrowLeft, IconArrowRight, IconCalendar, IconPlus } from '@soil-capital/ui-kit/icons';
import useOperationDatePickerLogic from './useOperationDatePicker.logic';
import { Typography } from '@soil-capital/ui-kit/material-core';
import { ListSubheader } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';

export default ({
    max,
    min,
    onChange,
    startMonthIndex,
    value,
    disabled,
    validationError,
    variant,
}: OperationDatePickerPropsT) => {
    const { t } = useTranslation();
    const { classes } = useOperationDatePickerStyle();
    const {
        backButtonClicked,
        canDecreaseYear,
        canIncreaseYear,
        decreaseYear,
        getPeriod,
        increaseYear,
        months,
        panel,
        panelTitle,
        setMonth,
        setPeriod,
        periodMatchingDays,
        selectedDate,
        displayedValue,
        fakeMenuItemRef,
        resetPanelView,
        operationDateStringHelper,
    } = useOperationDatePickerLogic({ max, min, onChange, startMonthIndex, value });

    return (
        <Select
            defaultValue={'fake-value'}
            sx={{ width: '250px' }}
            onOpen={() => resetPanelView()}
            disabled={disabled}
            data-testid="operation-date-picker"
            error={validationError}
            MenuProps={{
                anchorOrigin: {
                    horizontal: 'center',
                },
                transformOrigin: {
                    horizontal: 'center',
                },
                PaperProps: {
                    style: {
                        minWidth: 'auto',
                        width: 'auto',
                        maxHeight: 'initial',
                    },
                    className: classes.paper,
                },
            }}
            className={variant === 'button' ? classes.asButton : undefined}
        >
            <MenuItem ref={fakeMenuItemRef} disabled value={'fake-value'} sx={{ display: 'none' }}>
                <div className={classes.dateDisplay} data-testid="date-display">
                    {variant === 'button' ? (
                        <IconPlus data-testid="plus-icon" fontSize="small" />
                    ) : (
                        <IconCalendar data-testid="calendar-icon" fontSize="small" />
                    )}

                    {displayedValue ? (
                        <Typography>{displayedValue}</Typography>
                    ) : (
                        <Typography
                            color={variant !== 'button' ? 'neutral' : undefined}
                            variant={variant === 'button' ? 'caption' : undefined}
                        >
                            {t('constants.date-picker.placeholder')}
                        </Typography>
                    )}
                </div>
            </MenuItem>
            <ListSubheader className={classes.subheader}>
                <div className={classes.picker}>
                    {panel === 'month' && (
                        <>
                            <div className={`${classes.header} ${classes.headerYear}`}>
                                <Button
                                    data-testid="left-arrow-button"
                                    disabled={!canDecreaseYear()}
                                    size="small"
                                    onClick={decreaseYear}
                                >
                                    <IconArrowLeft />
                                </Button>
                                <Typography variant="caption" color="darkScale.900" width="3em" textAlign="center">
                                    {panelTitle}
                                </Typography>
                                <Button
                                    data-testid="right-arrow-button"
                                    disabled={!canIncreaseYear()}
                                    size="small"
                                    onClick={increaseYear}
                                >
                                    <IconArrowRight />
                                </Button>
                            </div>
                            <div className={classes.monthPicker}>
                                {months.map((month, index) => (
                                    <Button
                                        key={index}
                                        onClick={() => setMonth(index)}
                                        className={classes.clickableValue}
                                    >
                                        {t(month)}
                                    </Button>
                                ))}
                            </div>
                        </>
                    )}
                    {panel === 'period' && (
                        <>
                            <div className={`${classes.header} ${classes.headerBack}`}>
                                <Button size="small" onClick={backButtonClicked}>
                                    <IconArrowLeft data-testid="left-arrow-icon" />

                                    <Typography variant="caption" color="darkScale.900">
                                        {t('constants.back')}
                                    </Typography>
                                </Button>
                            </div>
                            <div className={classes.periodPicker}>
                                {Object.keys(periodMatchingDays).map((period) => (
                                    <Button
                                        key={period}
                                        onClick={() => setPeriod(period as Period)}
                                        className={
                                            classes.clickableValue +
                                            (value &&
                                            value.getTime() === selectedDate.getTime() &&
                                            period === getPeriod(value)
                                                ? ' ' + classes.activePeriod
                                                : '')
                                        }
                                    >
                                        {operationDateStringHelper(periodMatchingDays[period as Period].value)}
                                    </Button>
                                ))}
                            </div>
                        </>
                    )}
                </div>
            </ListSubheader>
        </Select>
    );
};
