import { makeStyles } from '@soil-capital/ui-kit/style';

export const useOrganicFertiliserProductsInputStyles = makeStyles()((theme) => ({
    list: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        listStyle: 'none',
        paddingLeft: 0,
    },
    row: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(5),
    },
    iconCross: {
        width: 12,
        cursor: 'pointer',
    },
    select: {
        minWidth: 250,
        maxWidth: 256,
    },
}));
