import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { SEEDING_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/seedingFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { useSeedingQuantityInputVisibility } from '@modules/encoding/modules/technicalItinerary/module/seeding/components/SeedingQuantityInput/useSeedingQuantityInputVisibility';

type SeedQuantityInputProps = {
    onChange: () => void;
};

export const SeedingQuantityInput = ({ onChange }: SeedQuantityInputProps) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplay } = useSeedingQuantityInputVisibility();

    if (!context) throw new Error('SeedingQuantityInput must be used within a FormProvider');

    return shouldDisplay ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.seeding.question.seed-quantity')}
                tooltipTitleKey="encoding-technical-itinerary.seeding.question.seed-quantity.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.seeding.question.seed-quantity.tooltip.text"
            />
            <Input
                sx={{ maxWidth: 250 }}
                onChange={onChange}
                type="number"
                suffix="kg/hectares"
                defaultValue={null}
                inputProps={{
                    ...context.register(SEEDING_FORM_INPUT_NAMES.SEEDING_QUANTITY, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
            />
        </FormRow>
    ) : (
        <></>
    );
};
