import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { useDestructionProductQuantityInputVisibility } from './useDestructionProductQuantityInputVisibility';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type DestructionProductQuantityInputPropsT = {
    onChange: () => void;
};

export const DestructionProductQuantityInput = ({ onChange }: DestructionProductQuantityInputPropsT) => {
    const { t } = useTranslation();
    const { shouldDisplayDestructionProductQuantityInput } = useDestructionProductQuantityInputVisibility();
    const context = useFormContext();

    if (!context) throw new Error('DestructionProductQuantityInput should be used inside a FormProvider');

    return shouldDisplayDestructionProductQuantityInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.destruction.question.destruction-product-quantity')}
                tooltipTitleKey="encoding-technical-itinerary.destruction.question.destruction-product-quantity.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.destruction.question.destruction-product-quantity.tooltip.text"
            />
            <Input
                type="number"
                suffix={t('constants.unit.l-ha')}
                onChange={onChange}
                inputProps={{
                    ...context.register(DESTRUCTION_FORM_INPUT_NAMES.DESTRUCTION_PESTICIDE_QUANTITY, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
                style={{ width: '250px' }}
            />
        </FormRow>
    ) : (
        <></>
    );
};
