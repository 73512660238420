export const DESTRUCTION_STATIC_DATA_TYPE = {
    HARVEST_DESTRUCTION_METHOD: 'HARVEST_DESTRUCTION_METHOD',
} as const;

export type DestructionStaticDataTypeT = keyof typeof DESTRUCTION_STATIC_DATA_TYPE;

export const DESTRUCTION_METHOD_SLUGS = {
    HARVEST_DESTRUCTION_METHOD_CHEMICAL: 'harvest-destruction-method-chemical',
    HARVEST_DESTRUCTION_METHOD_GRAZING: 'harvest-destruction-method-grazing',
    HARVEST_DESTRUCTION_METHOD_FROST: 'harvest-destruction-method-frost',
    HARVEST_DESTRUCTION_METHOD_PLOUGHING: 'harvest-destruction-method-ploughing',
    HARVEST_DESTRUCTION_METHOD_MECHANICAL: 'harvest-destruction-method-mechanical',
    // HARVEST_DESTRUCTION_METHOD_SHALLOW_MECHANICAL_WORK: 'harvest-destruction-method-shallow-mechanical-work',
    // HARVEST_DESTRUCTION_METHOD_DEEP_MECHANICAL_WORK: 'harvest-destruction-method-deep-mechanical-work',
} as const;

export type DestructionMethodSlugsT = (typeof DESTRUCTION_METHOD_SLUGS)[keyof typeof DESTRUCTION_METHOD_SLUGS];

export const DESTRUCTION_STATIC_DATA_SLUGS = {
    ...DESTRUCTION_METHOD_SLUGS,
} as const;

export type DestructionStaticDataSlugsT =
    (typeof DESTRUCTION_STATIC_DATA_SLUGS)[keyof typeof DESTRUCTION_STATIC_DATA_SLUGS];
