import { HelpBox, Modal } from '@soil-capital/ui-kit';
import { useTranslation } from 'react-i18next';
import { useHistoryCopyFromBaseline } from '@modules/encoding/modules/history/hooks/useHistoryCopyFromBaseline';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

type HistoryDuplicationModalProps = {
    open: boolean;
    onClose: () => void;
    onSuccess?: () => void;
};

export const HistoryDuplicationModal = ({ open, onClose, onSuccess }: HistoryDuplicationModalProps) => {
    const { t } = useTranslation();

    const { copyHistoryFromBaseline, isLoading } = useHistoryCopyFromBaseline({
        onSuccess,
    });
    const { currentSeasonId } = useCurrentSeasonId();

    const handleConfirmDuplicationModal = async () => {
        try {
            await copyHistoryFromBaseline(currentSeasonId);
            onClose();
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Modal
            size="small"
            open={open}
            onConfirm={handleConfirmDuplicationModal}
            onClose={onClose}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{t('encoding-history.duplication-modal.title')}</Modal.HeaderTitle>
                    <Modal.Close onClick={onClose} />
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <HelpBox>{t('encoding-history.duplication-modal.body')}</HelpBox>
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Cancel disabled={isLoading}>{t('constants.cancel')}</Modal.Cancel>
                    <Modal.Confirm disabled={isLoading} loading={isLoading}>
                        {t('constants.continue')}
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
