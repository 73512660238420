import cropApi from '@shared/entities/crop/crop.api';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';

export const useCreateFarmSeasonCrop = ({ onCropAdded }: { onCropAdded?: (id: number) => void }) => {
    const { currentSeasonId } = useCurrentSeasonId();
    const [createCrop, { isLoading }] = cropApi.useCreate();

    const addCrop = async (value: number) => {
        try {
            const res = await createCrop({
                farmSeasonId: currentSeasonId,
                body: {
                    crop_id: value,
                },
            }).unwrap();

            onCropAdded?.(res?.id);
        } catch (e) {
            console.error(e);
        }
    };

    return { addCrop, isLoading };
};
