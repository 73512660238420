import { makeStyles } from '@soil-capital/ui-kit/style';

const useSignUpPageStyle = makeStyles()((theme) => ({
    form: { display: 'flex', gap: theme.spacing(3), flexDirection: 'column' },
    checkbox: {
        marginTop: 0,
        '& > div': {
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            marginBottom: 0,
        },
    },
    loginLink: {
        textDecoration: 'underline',
    },
    tandc: { paddingLeft: theme.spacing(1) },
    padding: { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) },

    divider: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },

    modalHolder: {
        zIndex: 999999,
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'rgba(0,0,0,0.3)',
    },

    sideZoneImage: {
        backgroundImage: "url('/assets/images/sunny-field.jpg')",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        height: '100%',
    },

    joinText: {
        marginTop: theme.spacing(4),
        textAlign: 'center',
    },
    flexbox: { display: 'flex', flexDirection: 'column', alignItems: 'start', gap: theme.spacing(1) },
}));

export default useSignUpPageStyle;
