import { useState } from 'react';

export type ModalPropsT<OpenData, CloseData> = {
    isOpen: boolean;
    data: OpenData | null;
    onClose: ((closeData: CloseData) => void) | null;
};

/**
 * This controller handle the modal state and provide a way to open it, pass data and get back data as a simple Promise:\
 * (openData: OpenData) => Promise\<CloseData>.
 *
 * @example
    const {
        open: openConfirmDeleteModal,
        isOpen,
        data,
        onClose,
    } = useModalController<{ itemNameToDelete: string }, ConfirmModalCloseDataT>();

    const handleDelete = async (id: number) => {
        const { confirmed } = await openConfirmDeleteModal({ itemNameToDelete: 'foo' });
        if (confirmed) {
            api.deleteItem(id);
        }
    };

    return (
        <Button onClick={() => handleDelete(1234)}>Delete item</Button>
        <ConfirmDeleteModal isOpen={isOpen} data={data} onClose={onClose} />
    )
 */
export const useModalController = <OpenData = void, CloseData = boolean>(): ModalPropsT<OpenData, CloseData> & {
    open: (openData: OpenData) => Promise<CloseData>;
} => {
    const [data, setData] = useState<null | OpenData>(null);
    const [onClose, setOnClose] = useState<null | ((closeData: CloseData) => void)>(null);
    const isOpen = !!onClose;

    const open = (openData: OpenData): Promise<CloseData> => {
        let triggerCloseResolver: (value: CloseData) => void;

        const waitForClose = new Promise<CloseData>((resolve) => {
            triggerCloseResolver = resolve;
        });

        const closeModal = (data: CloseData) => {
            triggerCloseResolver(data);
            setOnClose(null);
        };

        setData(openData);
        setOnClose(() => closeModal);

        return waitForClose;
    };

    return {
        open,
        isOpen,
        data,
        onClose,
    };
};
