import { makeStyles } from '@soil-capital/ui-kit/style';

const useDividerTextStyle = makeStyles()((theme) => ({
    dividerContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: `${theme.spacing(1)} 0`,
        '&>span': {
            marginRight: theme.spacing(2),
        },
        '&>hr': {
            flexGrow: 1,
            background: theme.palette.grey[400],
        },
    },
    dividerText: {
        color: theme.palette.grey[400],
    },
}));

export default useDividerTextStyle;
