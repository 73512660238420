import { Navigate, useRoutes } from 'react-router-dom';
import { Login } from './pages/Login/Login';
import { ResetPassword } from './pages/ResetPassword/ResetPassword';
import { ForgotPassword } from './pages/ForgotPassword/ForgotPassword';
import { SignUp } from './pages/SignUp/SignUp';
import { AuthenticatedRoutesGuard } from './components/AuthenticatedRoutesGuard/AuthenticatedRoutes.guard';
import { routesConfig } from './auth.routes';
import { useUpdateIntercom } from '@shared/hooks/useUpdateIntercom';
import { useUpdateHotjar } from '@shared/hooks/useUpdateHotjar';
import { useUpdateUpscope } from '@shared/hooks/useUpdateUpscope';

export const AuthRoot = ({ children }: { children: React.ReactElement }) => {
    useUpdateIntercom();

    useUpdateHotjar();

    useUpdateUpscope();

    const authRoutes = useRoutes([
        {
            path: routesConfig.login,
            element: <Login />,
        },
        {
            path: routesConfig.signup,
            element: <SignUp />,
        },
        {
            path: routesConfig.resetPassword,
            element: <ResetPassword />,
        },
        {
            path: routesConfig.forgotPassword,
            element: <ForgotPassword />,
        },
        {
            // we consider that any other path is in the authenticated app side
            path: '*',
            element: <Navigate to={routesConfig.login} replace />,
        },
    ]);
    return <AuthenticatedRoutesGuard authenticatedComponent={children} unauthenticatedComponent={authRoutes} />;
};
