import { OnboardingStepsModal } from '@modules/onboarding/components/OnboardingStepsModal/OnboardingStepsModal';
import { LivestockForm } from './LivestockForm/LivestockForm';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography, Grid, useTheme } from '@soil-capital/ui-kit/material-core';
import { Button } from '@soil-capital/ui-kit/components';
import { IconPlus } from '@soil-capital/ui-kit/icons';
import { useLivestockLogic } from './useLivestockLogic';
import { LivestockFormSkeleton } from '../../../components/LivestockFormSkeleton/LivestockFormSkeleton';
import { ChildFormRef } from './useLivestockFormsLogic';

export const Livestock = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const {
        livestockForms,
        addForm,
        removeForm,
        handleReceiveChildData,
        livestockFormRefs,
        submitForms,
        isPostFormLoading,
        isLoading,
        defaultLivestockFormsValues,
        isGettingDefaultValues,
        handleLivestockChange,
        disableAddFormButton,
        getFilteredLivestockList,
    } = useLivestockLogic(t);

    return (
        <OnboardingStepsModal
            stepProgress={0}
            ctaAction={() => {
                submitForms();
            }}
            ctaText={t('pages.onboarding.eligibility.cta')}
            isCTAloading={isPostFormLoading}
            onBackClick={() => navigate('/onboarding/eligibility')}
        >
            <Grid
                paddingTop={theme.spacing(6)}
                paddingBottom={theme.spacing(3)}
                paddingLeft={theme.spacing(3)}
                paddingRight={theme.spacing(3)}
                gap={theme.spacing(3)}
                display="flex"
                justifyContent="center"
                flexDirection="column"
            >
                <Typography>{t('pages.onboarding.eligibility.livestock.title')}</Typography>
                {!isGettingDefaultValues ? (
                    livestockForms.map((formNumber, index) => (
                        <LivestockForm
                            key={formNumber}
                            number={index + 1}
                            onRemove={() => removeForm(formNumber)}
                            isRemoveDisabled={livestockForms.length === 1}
                            ref={(el: ChildFormRef) => (livestockFormRefs.current[formNumber] = el)}
                            getData={(data) => handleReceiveChildData(formNumber, data)}
                            defaultFormValues={defaultLivestockFormsValues[index]?.data}
                            onLivestockChange={(newLivestock: number) =>
                                handleLivestockChange(formNumber, newLivestock)
                            }
                            livestockList={getFilteredLivestockList(formNumber)}
                        />
                    ))
                ) : (
                    <LivestockFormSkeleton />
                )}
                <Button onClick={addForm} disabled={isLoading || disableAddFormButton} startIcon={<IconPlus />}>
                    {t('pages.onboarding.eligibility.livestock.add-more')}
                </Button>
            </Grid>
        </OnboardingStepsModal>
    );
};
