import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormRow } from '@shared/components';
import { useDestructionDepthInputVisibility } from './useDestructionDepthInputVisibility';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import { DESTRUCTION_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/destructionFormSchema';

type DestructionDepthInputPropsT = {
    onChange: () => void;
};

export const DestructionDepthInput = ({ onChange }: DestructionDepthInputPropsT) => {
    const { t } = useTranslation();
    const context = useFormContext();
    const { shouldDisplayDestructionDepthInput } = useDestructionDepthInputVisibility();

    if (!context) throw new Error('DestructionDepthInput should be used inside a FormProvider');

    return shouldDisplayDestructionDepthInput ? (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.destruction.question.soil-work-depth')}
                tooltipTitleKey="encoding-technical-itinerary.destruction.question.soil-work-depth.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.destruction.question.soil-work-depth.tooltip.text"
            />
            <Input
                type="number"
                suffix={t('constants.unit.cm')}
                onChange={onChange}
                inputProps={{
                    ...context.register(DESTRUCTION_FORM_INPUT_NAMES.DEPTH, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
                style={{ width: '250px' }}
            />
        </FormRow>
    ) : (
        <></>
    );
};
