import { useParams } from 'react-router-dom';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useGetFarmSeasonFields } from '@shared/hooks/useGetFarmSeasonFields';
import { BaseGuard, GuardT } from '@shared/guards/BaseGuard';

export const useHasAtLeastTwoFieldsForACrop = () => {
    const { fieldCropId: fieldCropIdParam } = useParams();
    const fieldCropId = Number(fieldCropIdParam);

    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const { fieldState } = useGetFarmSeasonFields();

    const fieldCrop = fieldCropState.getById(fieldCropId);
    const cropId = fieldCrop?.farm_season_crop_id;
    const { getFieldsForCrop } = fieldState.useGetFieldsForCrop();

    const isLoading = fieldCropState.isLoading || fieldState.isLoading;

    return isLoading ? undefined : !!cropId && getFieldsForCrop({ cropId }).length > 1;
};

export const HasAtLeastTwoFieldsForCropGuard = (props: GuardT) => {
    const passed = useHasAtLeastTwoFieldsForACrop();

    return <BaseGuard passed={passed} {...props} />;
};
