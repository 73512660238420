import { FormRow } from '@shared/components';
import { useTranslation } from 'react-i18next';
import { WEEDING_MACHINE_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/weedingMachineFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';
import {
    FormAutocomplete,
    FormAutoCompleteOptionT,
    FormAutocompleteProps,
} from '@shared/components/FormAutocomplete/FormAutocomplete';

type WeedingMachineMachineryInputPropsT = {
    machineries: FormAutoCompleteOptionT[];
    disabled?: boolean;
    onChange: FormAutocompleteProps<FormAutoCompleteOptionT, false>['onChange'];
};

export const WeedingMachineMachineryInput = ({
    machineries,
    disabled,
    onChange,
}: WeedingMachineMachineryInputPropsT) => {
    const { t } = useTranslation();

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.weeding-machine.machine')}
                tooltipTitleKey="encoding-technical-itinerary.weeding-machine.machine-tooltip-title"
                tooltipTextKey="encoding-technical-itinerary.weeding-machine.machine-tooltip-text"
            />
            <FormAutocomplete
                name={WEEDING_MACHINE_FORM_INPUT_NAMES.MACHINERY_ID}
                options={machineries}
                placeholder={t('encoding-technical-itinerary.destruction.question.destruction-machinery.placeholder')}
                onChange={onChange}
                disabled={disabled}
                multiple={false}
            />
        </FormRow>
    );
};
