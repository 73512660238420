import { makeStyles } from '@soil-capital/ui-kit/style';

export const useSprayingFormPesticideTypeInputStyles = makeStyles()(() => ({
    select: {
        minWidth: 250,
        maxWidth: 256,
    },
    iconCross: {
        width: 12,
        cursor: 'pointer',
    },
}));
