import { useForm } from '@shared/hooks';
import { useEffect } from 'react';
import { useHarvestFormOptions } from '../../hooks/useHarvestFormOptions';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { getOperationFormApi } from '@modules/encoding/modules/technicalItinerary/api/getOperationFormApi';
import {
    HARVEST_FORM_INPUT_NAMES,
    HarvestFormSchemaT,
    harvestFormSchema,
} from '@modules/encoding/modules/technicalItinerary/schema/harvestFormSchema';
import { useOperationFormDefaultValues } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationFormDefaultValues';
import { FORM_SUBMIT_DEBOUNCE_TIME_MS } from '@shared/constants/formsConfig';
import { useStableDebounce } from '@shared/hooks/useStableDebounce';

export const useHarvestForm = () => {
    const { seasonId, fieldCropId, operationId } = useOperationRouteParams();
    const {
        formApiData,
        computeApiDataToMatchSchema,
        isFormLoading: isLoadingDefaultValues,
        refetchFormData,
    } = useOperationFormDefaultValues('harvest');

    const { currentFarm, currentFarmLoading } = encodingShared.useCurrentFarm();
    const { currentSeason, currentSeasonLoading } = encodingShared.useCurrentSeason();
    const { cropState } = entities.crop.useState({ farmSeasonId: seasonId });
    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: seasonId });
    const { staticCropState } = entities.staticCrop.useState({ countryId: currentFarm?.country_id });
    const { staticSeasonState } = entities.staticSeason.useState();
    const { staticCropGroupState } = entities.staticCropGroup.useState({ countryId: currentFarm?.country_id });

    const fieldCrop = fieldCropState.getById(fieldCropId);
    const crop = cropState.getById(fieldCrop?.farm_season_crop_id);
    const staticCrop = staticCropState.getById(crop?.crop_id);
    const cropGroup = staticCropGroupState.getById(staticCrop?.crop_group_id);

    const isCerealCropGroup = cropGroup?.slug === 'cereals';
    const hasCompanionCrop = !!fieldCrop?.companion_crop_id;
    const harvestYear = staticSeasonState.getById(currentSeason?.season_id)?.harvest_year;

    // Form options
    const { isLoading: isLoadingOptions, ...options } = useHarvestFormOptions();

    const isFormLoading =
        isLoadingDefaultValues ||
        isLoadingOptions ||
        currentFarmLoading ||
        currentSeasonLoading ||
        cropState.isLoading ||
        fieldCropState.isLoading ||
        staticCropState.isLoading ||
        staticCropGroupState.isLoading;

    // Mutations
    const [updateForm] = getOperationFormApi('harvest').useUpdate();

    /* -------------------------------- Form -------------------------------- */
    const methods = useForm<typeof harvestFormSchema.schema>({
        schema: harvestFormSchema.schema,
        defaultValues: computeApiDataToMatchSchema(formApiData),
        displaySnackbarOnSchemaError: true,
    });

    const { handleSubmit, reset, setValue, getValues } = methods;

    // Reset form default values when API data was fetched
    useEffect(() => {
        if (formApiData) reset(computeApiDataToMatchSchema(formApiData));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formApiData]);

    // Form callbacks
    const onSubmit = useStableDebounce(
        handleSubmit(async (data: HarvestFormSchemaT) => {
            try {
                await updateForm({
                    farmSeasonId: seasonId,
                    fieldCropId: fieldCropId,
                    operationId: operationId,
                    body: {
                        ...data,
                    },
                });
            } catch (e) {
                console.error(e);
            }
        }),
        FORM_SUBMIT_DEBOUNCE_TIME_MS,
    );

    const changeStrawUsageRelatedInputs = () => {
        setValue(HARVEST_FORM_INPUT_NAMES.EXPORTED_STRAWS_QUANTITY, null);
    };

    return {
        methods,
        isCerealCropGroup,
        hasCompanionCrop,
        harvestYear,
        options: {
            ...options,
        },
        isFormLoading,
        onSubmit,
        changeStrawUsageRelatedInputs,
        getFormValues: getValues,
        refetchFormData,
    };
};
