import { FormRow } from '@shared/components';
import { Input } from '@soil-capital/ui-kit/components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { HARVEST_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/harvestFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type YieldInputPropsT = {
    onChange: () => void;
    disabled?: boolean;
};

export const YieldInput = ({ onChange, disabled }: YieldInputPropsT) => {
    const { t } = useTranslation();
    const context = useFormContext();

    if (!context) throw new Error('YieldInput should be used inside a FormProvider');

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.harvest.question.yield')}
                tooltipTitleKey="encoding-technical-itinerary.harvest.question.yield.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.harvest.question.yield.tooltip.text"
            />
            <Input
                type="number"
                suffix={t('constants.unit.tons-ha')}
                style={{ width: '250px' }}
                onChange={onChange}
                inputProps={{
                    ...context.register(HARVEST_FORM_INPUT_NAMES.YIELD, {
                        setValueAs: (v: string | null) => (v === '' || v === null ? null : parseFloat(v)),
                    }),
                }}
                disabled={disabled}
            />
        </FormRow>
    );
};
