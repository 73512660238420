import { encodingShared } from '@modules/encoding/shared';
import { Skeleton } from '@soil-capital/ui-kit/material-core';

export const dataTestId = 'operation-duplication-action-panel-skeleton';

export const OperationDuplicationActionPanelSkeleton = () => {
    return (
        <encodingShared.ActionPanel>
            <div data-testid={dataTestId}>
                <Skeleton sx={{ backgroundColor: 'primary.dark' }} height={40} variant="text" />
                <Skeleton sx={{ backgroundColor: 'primary.dark', mt: 1, width: '50%' }} height={40} variant="text" />
                <Skeleton sx={{ backgroundColor: 'primary.dark', mt: 2 }} variant="text" />
                <Skeleton sx={{ backgroundColor: 'primary.dark', mt: 1 }} variant="text" />
                <Skeleton sx={{ backgroundColor: 'primary.dark', mt: 1, width: '40%' }} variant="text" />
                <div
                    style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 32, marginTop: 32 }}
                >
                    <Skeleton variant="circular" sx={{ backgroundColor: 'primary.dark' }} height={180} width={180} />
                    <Skeleton variant="circular" sx={{ backgroundColor: 'primary.dark' }} height={180} width={180} />
                    <Skeleton variant="circular" sx={{ backgroundColor: 'primary.dark' }} height={180} width={180} />
                </div>
                <Skeleton sx={{ backgroundColor: 'primary.dark', mt: 4 }} height={60} variant="rounded" />
                <Skeleton
                    sx={{ backgroundColor: 'primary.dark', mt: 2, width: '30%', mr: 'auto', ml: 'auto' }}
                    height={40}
                    variant="text"
                />
            </div>
        </encodingShared.ActionPanel>
    );
};
