import { CardScore, MenuItem, NumberInput, Select, Button } from '@soil-capital/ui-kit/components';
import { IconClose } from '@soil-capital/ui-kit/icons';
import { Typography, Grid, useTheme } from '@soil-capital/ui-kit/material-core';
import { useTranslation } from 'react-i18next';
import { LIVESTOCK, useLivestockFormLogic } from './useLivestockFormLogic';
import { CreateLivestockT, FarmSeasonLivestockT } from '@modules/onboarding/api/onboarding.type';
import { ChildFormRef } from '../useLivestockFormsLogic';
import { forwardRef } from 'react';
import { FormProvider } from 'react-hook-form';
import { StaticLivestockT } from '@shared/entities';

type LivestockFormPropsT = {
    number: number;
    onRemove: () => void;
    isRemoveDisabled: boolean;
    getData: (data: CreateLivestockT) => void;
    defaultFormValues?: FarmSeasonLivestockT;
    livestockList: StaticLivestockT[];
    onLivestockChange: (value: number) => void;
};

export const LivestockForm = forwardRef<ChildFormRef, LivestockFormPropsT>(
    ({ number, onRemove, isRemoveDisabled, getData, defaultFormValues, livestockList, onLivestockChange }, ref) => {
        const { t } = useTranslation();
        const theme = useTheme();
        const { selectedLivestockType, livestockQuantity, methods, onSubmit, errors, handleLivestockTypeChange } =
            useLivestockFormLogic({
                getData,
                onLivestockChange,
                ref,
                defaultFormValues,
            });

        return (
            <FormProvider {...methods}>
                <form onSubmit={onSubmit}>
                    <CardScore>
                        <Grid display="flex" justifyContent="space-between" width="100%" alignItems="center">
                            <Typography variant="body2">
                                {t('pages.onboarding.eligibility.livestock.card.title', { number })}
                            </Typography>
                            <Button onClick={onRemove} disabled={isRemoveDisabled}>
                                <IconClose />
                            </Button>
                        </Grid>
                        <Grid
                            display="flex"
                            justifyContent="space-between"
                            width="100%"
                            alignItems="flex-start"
                            gap={theme.spacing(2)}
                        >
                            <Grid width="100%">
                                <Select
                                    onChange={handleLivestockTypeChange}
                                    label={t('pages.onboarding.eligibility.livestock.card.type-input').toUpperCase()}
                                    value={selectedLivestockType}
                                    fullWidth
                                    displayEmpty
                                >
                                    <MenuItem sx={{ display: 'none' }} value={0} disabled>
                                        {t('pages.onboarding.eligibility.livestock.card.type-input.placeholder')}
                                    </MenuItem>
                                    {livestockList?.length > 0 &&
                                        livestockList.map((livestock) => (
                                            <MenuItem key={livestock.id} value={livestock.id}>
                                                {t(livestock.translation_slug)}
                                            </MenuItem>
                                        ))}
                                </Select>
                                {errors[LIVESTOCK.TYPE] && (
                                    <Typography variant="subcaption" color="error">
                                        {errors[LIVESTOCK.TYPE]?.message}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid width="320px">
                                <NumberInput
                                    sx={{
                                        '& .MuiFormLabel-root': {
                                            fontSize: `${theme.typography.body.fontSize} !important`,
                                        },
                                    }}
                                    fullWidth
                                    label={t('pages.onboarding.eligibility.livestock.card.quantity-input')}
                                    placeholder={t(
                                        'pages.onboarding.eligibility.livestock.card.quantity-input.placeholder',
                                    )}
                                    onChange={(v) => methods.setValue(LIVESTOCK.QUANTITY, v ?? 0)}
                                    value={livestockQuantity ?? null}
                                    allowedDecimals={0}
                                    min={1}
                                    incrementationStep={1}
                                />
                                {errors[LIVESTOCK.QUANTITY] && (
                                    <Typography variant="subcaption" color="error">
                                        {errors[LIVESTOCK.QUANTITY]?.message}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </CardScore>
                </form>
            </FormProvider>
        );
    },
);
