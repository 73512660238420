import { useParams } from 'react-router-dom';
import { TechnicalItineraryRouteParamsT } from '../../technicalItinerary.routes';
import entities from '@shared/entities';
import { useState } from 'react';

export default () => {
    const { seasonId, fieldCropId, operationId } = useParams<TechnicalItineraryRouteParamsT['operationForm']>();
    const [fieldMapSnapshotIsLoading, setFieldMapSnapshotIsLoading] = useState<boolean>(true);
    const [timelineNavIsLoading, setTimelineNavIsLoading] = useState<boolean>(false);
    const [fieldIdentityCardIsLoading, setFieldIdentityCardIsLoading] = useState<boolean>(false);

    const { fieldCropState } = entities.fieldCrop.useState({ farmSeasonId: Number(seasonId) });
    const fieldId = Number(fieldCropState.getById(fieldCropId)?.farm_season_field_id);

    const isLoading = fieldMapSnapshotIsLoading || timelineNavIsLoading || fieldIdentityCardIsLoading;

    return {
        seasonId: Number(seasonId),
        fieldCropId: Number(fieldCropId),
        operationId: Number(operationId),
        fieldId,
        isLoading,
        setFieldMapSnapshotIsLoading,
        setTimelineNavIsLoading,
        setFieldIdentityCardIsLoading,
    };
};
