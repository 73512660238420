import { FormRow } from '@shared/components';
import { FormDatePicker } from '@shared/components/form/FormDatePicker/FormDatePicker';
import { useTranslation } from 'react-i18next';
import { HARVEST_FORM_INPUT_NAMES } from '@modules/encoding/modules/technicalItinerary/schema/harvestFormSchema';
import { FormQuestion } from '@modules/encoding/shared/components/FormQuestion/FormQuestion';

type CompanionDestructionDateInputPropsT = {
    harvestYear?: number | undefined;
    onChange: () => void;
    disabled?: boolean;
};

export const CompanionDestructionDateInput = ({
    harvestYear,
    onChange,
    disabled,
}: CompanionDestructionDateInputPropsT) => {
    const { t } = useTranslation();

    return (
        <FormRow>
            <FormQuestion
                question={t('encoding-technical-itinerary.harvest.question.companion-destruction-date')}
                tooltipTitleKey="encoding-technical-itinerary.harvest.question.companion-destruction-date.tooltip.title"
                tooltipTextKey="encoding-technical-itinerary.harvest.question.companion-destruction-date.tooltip.text"
            />
            <FormDatePicker
                name={HARVEST_FORM_INPUT_NAMES.COMPANION_DESTRUCTION_DATE}
                onChange={onChange}
                max={harvestYear ? new Date(harvestYear, 11, 31) : undefined}
                disabled={disabled}
            />
        </FormRow>
    );
};
