import { RadioButton, RadioGroup as MuiRadioGroup } from '@soil-capital/ui-kit/components';
import { Controller, useFormContext } from 'react-hook-form';
import { useRadioGroupStyles } from '@shared/components/form/RadioGroup/RadioGroup.style';

export type RadioGroupOptionT = {
    label: string;
    value: string | number;
};

type RadioGroupProps<T extends 'string' | 'number', K = T extends 'string' ? string : number> = {
    className?: string;
    name: string;
    type?: T;
    onChange?: (value: K) => void;
    onBlur?: () => void;
    options: RadioGroupOptionT[];
    disabled?: boolean;
};

export const RadioGroup = <T extends 'string' | 'number'>({
    className,
    name,
    type,
    options,
    onChange,
    onBlur,
    disabled,
}: RadioGroupProps<T>) => {
    const { classes, cx } = useRadioGroupStyles();
    const context = useFormContext();

    if (!context) throw new Error('RadioGroup should be used inside a FormProvider');

    return (
        <Controller
            control={context.control}
            name={name}
            render={({ field: { value, onChange: onFieldChange, onBlur: onFieldBlur, ...field } }) => (
                <MuiRadioGroup
                    className={cx(classes.container, className)}
                    onChange={(_, v) => {
                        const value = (type === 'number' ? Number(v) : v) as T extends 'string' ? string : number;
                        onFieldChange(value);
                        onChange?.(value);
                    }}
                    value={type === 'number' ? Number(value) : value}
                    onBlur={() => {
                        onFieldBlur();
                        onBlur?.();
                    }}
                    {...field}
                >
                    {options.map((option) => (
                        <RadioButton key={option.value} value={option.value} label={option.label} disabled={disabled} />
                    ))}
                </MuiRadioGroup>
            )}
        />
    );
};
