import {
    OrganicFertilisationProductSchemaT,
    OrganicFertiliserInputNameT,
} from '@modules/encoding/modules/technicalItinerary/schema/organicFertilisationSchema';
import { encodingShared } from '@modules/encoding/shared';
import entities from '@shared/entities';
import { useFormContext, useWatch } from 'react-hook-form';

export const useOrganicFertiliserProductsInput = ({
    name,
    onChange,
}: {
    name: OrganicFertiliserInputNameT;
    onChange: () => void;
}) => {
    const { register, setValue } = useFormContext();
    const selectedFertilisers: OrganicFertilisationProductSchemaT[] = useWatch({ name }) ?? [];
    const { currentFarm } = encodingShared.useCurrentFarm();
    const { staticFertiliserState } = entities.staticFertiliser.useState({ farmId: currentFarm?.id });

    const handleProductsChange = (newFertiliserIds: number[]) => {
        let newState: OrganicFertilisationProductSchemaT[] = [];

        const fertilisersToDelete = selectedFertilisers?.filter(
            (selectedFertiliser) =>
                !newFertiliserIds?.find(
                    (newFertiliserId) => newFertiliserId === selectedFertiliser.static_fertiliser_id,
                ),
        );
        if (fertilisersToDelete.length > 0)
            newState = selectedFertilisers.filter((f) => !fertilisersToDelete.includes(f));

        const newFertiliserId = newFertiliserIds?.find(
            (newFertiliserId) =>
                !selectedFertilisers?.find(
                    (selectedFertiliser) => newFertiliserId === selectedFertiliser.static_fertiliser_id,
                ),
        );
        if (newFertiliserId)
            newState = [...selectedFertilisers, { static_fertiliser_id: newFertiliserId, quantity: null }];

        setValue(name, newState);
        onChange();
    };

    const handleProductRemoved = (productId: number) => {
        setValue(
            name,
            selectedFertilisers.filter((p) => p.static_fertiliser_id !== productId),
        );
        onChange();
    };

    return {
        selectedFertilisers,
        handleProductsChange,
        handleProductRemoved,
        register,
        getFertiliserById: staticFertiliserState.getById,
    };
};
