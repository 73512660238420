import { useGetPaymentTimelineQuery, useGetPaymentMethodQuery } from '@modules/billing/api/billingApi';
import { useGetUserCompanyQuery } from '@modules/billing/api/userCompanyApi';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';

export const useBillingPageLogic = () => {
    const { currentSeason } = useCurrentSeason();
    const farmId = currentSeason?.farm_id;
    const { data: payments, isLoading: isPaymentLoading } = useGetPaymentTimelineQuery({ farmId });
    const { data: paymentMethod, isLoading: isPaymentMethodLoading } = useGetPaymentMethodQuery({ farmId });
    const { data: billingInfo, isLoading: isBillingInfoLoading } = useGetUserCompanyQuery({
        farmId,
    });

    return {
        farmId,
        payments,
        isPaymentLoading,
        paymentMethod,
        isPaymentMethodLoading,
        billingInfo,
        isBillingInfoLoading,
    };
};
