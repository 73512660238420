import { Modal } from '@soil-capital/ui-kit';
import { usePacFileImportModalLogic } from './usePacFileImportModal.logic';
import { useTranslation } from 'react-i18next';
import { DropZone } from '@modules/encoding/shared/components/DropZone/DropZone';

type PacFileImportModalProps = {
    open: boolean;
    currentSeasonId: number;
    onClose: () => void;
};

export const PacFileImportModal = ({ open, currentSeasonId, onClose }: PacFileImportModalProps) => {
    const { t } = useTranslation();
    const { isLoading, acceptedFiles, processingErrors, files, handleImportPacFile, onFilesChange } =
        usePacFileImportModalLogic({
            currentSeasonId: currentSeasonId,
            handleClose: onClose,
        });

    return (
        <Modal
            size={'small'}
            open={open}
            onConfirm={handleImportPacFile}
            onClose={onClose}
            header={
                <Modal.Header>
                    <Modal.HeaderTitle>{t('encoding-rotation.capfile-import-modal.title')}</Modal.HeaderTitle>
                    <Modal.Close onClick={onClose}></Modal.Close>
                </Modal.Header>
            }
            body={
                <Modal.Body>
                    <DropZone
                        processingErrors={processingErrors}
                        files={files}
                        onFilesChange={onFilesChange}
                        textBrowseFiles={t('encoding-rotation.capfile-import-modal.description-browsing-files')}
                        textDropFiles={t('encoding-rotation.capfile-import-modal.description-upload-file')}
                        acceptedFilesTypes={acceptedFiles}
                    />
                </Modal.Body>
            }
            footer={
                <Modal.Footer>
                    <Modal.Confirm disabled={files.length === 0 || processingErrors.length > 0} loading={isLoading}>
                        {t('encoding-rotation.capfile-import-modal.confirm')}
                    </Modal.Confirm>
                </Modal.Footer>
            }
        />
    );
};
