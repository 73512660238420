import { useHarvestForm } from './useHarvestForm.logic';
import { FormProvider } from 'react-hook-form';
import { useHarvestFormStyles } from './useHarvestForm.style';
import { StrawsUsageInput } from '../StrawsUsageInput/StrawsUsageInput';
import { HarvestMachineryInput } from '../HarvestMachineryInput/HarvestMachineryInput';
import { YieldInput } from '../YieldInput/YieldInput';
import { ExportedQuantityInput } from '../ExportedQuantityInput/ExportedQuantityInput';
import { CompanionDestructionDateInput } from '../CompanionDestructionDateInput/CompanionDestructionDateInput';
import { HarvestFormSkeleton } from './HarvestFormSkeleton';
import { ReuseOperationSelect } from '../../../operationDuplication/reuse/components/ReuseOperationSelect/ReuseOperationSelect';
import { useOperationRouteParams } from '@modules/encoding/modules/technicalItinerary/hooks/useOperationRouteParams';
import { useReuseOperation } from '../../../operationDuplication/reuse/hooks/useReuseOperation';
import { DIVIDER_TEXT_ALIGNMENT, DividerText } from '@shared/components/DividerText/DividerText';
import { useTranslation } from 'react-i18next';

export const HarvestForm = () => {
    const { t } = useTranslation();
    const { classes } = useHarvestFormStyles();
    const { fieldCropId, operationId, seasonId } = useOperationRouteParams();
    const {
        methods,
        isCerealCropGroup,
        harvestYear,
        hasCompanionCrop,
        options,
        isFormLoading,
        onSubmit,
        changeStrawUsageRelatedInputs,
        getFormValues,
        refetchFormData,
    } = useHarvestForm();

    const { handleReuseOperation, isReuseOperationLoading } = useReuseOperation({
        getFormValues,
        onOperationReused: refetchFormData,
    });

    if (isFormLoading) return <HarvestFormSkeleton />;

    const { machineries, strawsUsage } = options;

    return (
        <FormProvider {...methods}>
            <form onSubmit={onSubmit} className={classes.form}>
                <ReuseOperationSelect
                    fieldCropId={fieldCropId}
                    operationId={operationId}
                    farmSeasonId={seasonId}
                    onReuseOperationTriggered={handleReuseOperation}
                    actionLoading={isReuseOperationLoading}
                    disabled={isReuseOperationLoading}
                />
                <HarvestMachineryInput
                    machineries={machineries}
                    onChange={onSubmit}
                    disabled={isReuseOperationLoading}
                />
                <YieldInput onChange={onSubmit} disabled={isReuseOperationLoading} />
                {isCerealCropGroup && (
                    <StrawsUsageInput
                        onChange={() => {
                            changeStrawUsageRelatedInputs();
                            onSubmit();
                        }}
                        options={strawsUsage}
                        disabled={isReuseOperationLoading}
                    />
                )}
                <ExportedQuantityInput onChange={onSubmit} disabled={isReuseOperationLoading} />

                {hasCompanionCrop && (
                    <>
                        <DividerText text={t('constants.optional')} textAlign={DIVIDER_TEXT_ALIGNMENT.LEFT} />
                        <CompanionDestructionDateInput
                            harvestYear={harvestYear}
                            onChange={onSubmit}
                            disabled={isReuseOperationLoading}
                        />
                    </>
                )}
            </form>
        </FormProvider>
    );
};
