import { useTranslation } from 'react-i18next';
import authActions from '@modules/auth/store/authActions';
import { z } from 'zod';
import { useForm } from '@shared/hooks';
import useCurrentLanguage from '@shared/hooks/useCurrentLanguage';
import { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import staticLanguage from '@shared/entities/staticLanguage';

export const SIGNUP = {
    FIRSTNAME: 'first_name',
    LASTNAME: 'last_name',
    EMAIL: 'email',
    PASSWORD: 'password',
    PASSWORDCONFIRMATION: 'password_confirmation',
    TERMS: 'terms',
    LANGUAGEID: 'language_id',
} as const;

export const useSignUpLogic = () => {
    const { t } = useTranslation();
    const [password, setPassword] = useState('');
    const { staticLanguageState } = staticLanguage.useState();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const emailParam = searchParams.get('email');
    const lngParam = searchParams.get('lng');
    const language = staticLanguageState.list.find((language) => language.iso_code === lngParam);
    const currentLanguage = useCurrentLanguage();
    const defaultValues = {
        [SIGNUP.FIRSTNAME]: '',
        [SIGNUP.LASTNAME]: '',
        [SIGNUP.EMAIL]: emailParam ? emailParam : '',
        [SIGNUP.PASSWORD]: '',
        [SIGNUP.PASSWORDCONFIRMATION]: '',
        [SIGNUP.TERMS]: false,
        [SIGNUP.LANGUAGEID]: language?.id || currentLanguage?.id || 1,
    };
    const schema = z.object({
        [SIGNUP.FIRSTNAME]: z.string().min(2, t('validation.no-first-name-provided')),
        [SIGNUP.LASTNAME]: z.string().min(2, t('validation.no-last-name-provided')),
        [SIGNUP.EMAIL]: z.string().email(t('validation.no-email-provided')),
        [SIGNUP.PASSWORD]: z
            .string()
            .min(1, t('validation.no-password-provided'))
            .min(8, t('validation.password-must-be-at-least-x-characters', { min: 8 }))
            .regex(/\d/, t('validation.password-must-at-least-contain-one-figure'))
            .regex(/[^A-Za-z0-9]/, t('validation.password-must-at-least-contain-one-special-character')),
        [SIGNUP.PASSWORDCONFIRMATION]: z
            .string()
            .min(1, t('validation.no-password-confirmation'))
            .refine((data) => data === password, {
                message: t('validation.password-must-match'),
            }),
        [SIGNUP.TERMS]: z.boolean().refine((value) => value === true, {
            message: t('validation.signup.terms-accept'),
        }),
        [SIGNUP.LANGUAGEID]: z.number(),
    });
    const methods = useForm({
        schema,
        defaultValues,
        displaySnackbarOnSchemaError: false,
    });

    const { register, registerLoading } = authActions.useRegister();
    const {
        handleSubmit,
        formState: { isValid, errors },
    } = methods;

    const onSubmit = handleSubmit(async (data) => {
        await register(data).then((response) => {
            if (response?.user_infos?.redirect_url && response?.user_infos?.redirect_url !== location.origin) {
                window.location.replace(response?.user_infos?.redirect_url);
            }
            navigate('/onboarding');
        });
    });

    return { defaultValues, onSubmit, isValid, errors, registerLoading, methods, password, setPassword, emailParam };
};
