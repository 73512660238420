import { useGetUserCompanyQuery } from '@modules/billing/api/userCompanyApi';
import { useRef } from 'react';
import useCurrentSeason from '@modules/encoding/shared/hooks/useCurrentSeason';
import { useGetRenewalInformationQuery } from '@modules/payment/api/paymentApi';
import { useCreatePayment } from '@modules/payment/hooks/useCreatePayment';
import { useGetPaymentMethodQuery } from '@modules/billing/api/billingApi';

export const useBillingInfoLogic = () => {
    const { currentSeason } = useCurrentSeason();
    const farmId = currentSeason?.farm_id;
    const { data: userPaymentContext, isLoading: isUserPaymentContextLoading } = useGetRenewalInformationQuery({
        farmId,
    });
    const { data: paymentMethod, isLoading: isPaymentMethodLoading } = useGetPaymentMethodQuery({ farmId });

    const isCardExpired = paymentMethod?.is_expired;
    const currency = userPaymentContext?.currency.symbol || '';
    const priceToPay = userPaymentContext?.total_excluding_tax ?? (userPaymentContext?.price_amount || 0);
    const isDeferredPayment = userPaymentContext?.deferred_payment || false;

    const {
        data: billingInfo,
        refetch,
        isLoading: isBillingInfoLoading,
    } = useGetUserCompanyQuery({
        farmId: farmId,
    });
    const onConfirmRef = useRef<(() => void) | null>(null);
    const handleConfirmClick = async () => {
        if (onConfirmRef.current) {
            onConfirmRef.current();
        }
    };

    const { createPayment: redirectToStripe, isLoading, isNextFarmSeasonProductPlanLoading } = useCreatePayment();

    return {
        isDataLoading: isUserPaymentContextLoading || isPaymentMethodLoading || isBillingInfoLoading,
        currency,
        billingInfo,
        handleConfirmClick,
        onConfirmRef,
        refetch,
        farmId,
        priceToPay,
        isDeferredPayment,
        isLoading,
        isNextFarmSeasonProductPlanLoading,
        redirectToStripe,
        isCardExpired,
    };
};
