import { useNavigate } from 'react-router-dom';
import { rotationRoutesConfig } from '../../rotation.routes';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import technicalItineraryRoutes from '@modules/encoding/modules/technicalItinerary/technicalItinerary.routes';
import {
    ROTATION_PROGRESS_STATUSES,
    RotationProgressStatusT,
} from '@modules/encoding/modules/rotation/pages/IntroductionContentPage/useIntroductionContentProgress';
import { encodingRoutesConfig } from '@modules/encoding/encoding.routes';
import { changeFieldId } from '@modules/encoding/modules/rotation/store/filter.slice';
import { useToggle } from '@shared/hooks/useToggle';
import { useGetHistoryDuplicationDataQuery } from '@modules/encoding/modules/history/api/historyApi';
import { useBaselineFarmSeason } from '@modules/encoding/modules/history/hooks/useBaselineFarmSeason';
import useCurrentSeasonId from '@modules/encoding/shared/hooks/useCurrentSeasonId';
import { skipToken } from '@reduxjs/toolkit/query';
import entities from '@shared/entities';
import { PROGRESS_SLUGS } from '@shared/entities/progress/progress.types';

type PropsT = {
    status: RotationProgressStatusT;
};

export const useIntroductionContentPageLogic = ({ status = 'PENDING' }: PropsT) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const { currentSeasonId } = useCurrentSeasonId();
    const { isHistoryFarmSeason } = useBaselineFarmSeason();
    const { data: historyDuplication } = useGetHistoryDuplicationDataQuery(
        isHistoryFarmSeason ? { farmSeasonId: currentSeasonId } : skipToken,
    );
    const { progressState } = entities.progress.useState({ seasonId: currentSeasonId });
    const isTechnicalItineraryDone = progressState?.getBySlug(PROGRESS_SLUGS.TECHNICAL_ITINERARY)?.status === 'done';
    const {
        open: openHistoryDuplicationModal,
        handleOpen: handleOpenHistoryDuplicationModal,
        handleClose: handleCloseHistoryDuplicationModal,
    } = useToggle();

    const handleOpenWithFieldId = (farmSeasonFieldId: number) => {
        dispatch(changeFieldId(farmSeasonFieldId));
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const handleRedirectToPac = () => navigate(rotationRoutesConfig.pacImport);
    const handleRedirectToTechnicalItinerary = () =>
        navigate(`../${encodingRoutesConfig.TECHNICAL_ITINERARY}/${technicalItineraryRoutes.introduction()}`);

    const handleContinue = () => {
        if (
            status === ROTATION_PROGRESS_STATUSES.FINISHED &&
            !isTechnicalItineraryDone &&
            historyDuplication?.length === 0
        )
            return handleOpenHistoryDuplicationModal();
        if (status === ROTATION_PROGRESS_STATUSES.FINISHED) return handleRedirectToTechnicalItinerary();
        if (status === ROTATION_PROGRESS_STATUSES.PENDING) return handleOpen();
        return handleRedirectToPac();
    };

    return {
        open,
        handleOpen,
        handleOpenWithFieldId,
        handleContinue,
        handleClose,
        handleRedirectToPac,
        openHistoryDuplicationModal,
        handleCloseHistoryDuplicationModal,
    };
};
