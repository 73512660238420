import entities from '@shared/entities';
import { useGetNeedFinalizationStep } from '@modules/encoding/modules/rotation/hooks/useNeedFinalizationStep';
import { encodingShared } from '@modules/encoding/shared';
import { useGetFarmSeasonFieldCrops } from '@shared/hooks/useGetFarmSeasonFieldCrops';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

export const useFinalizeFieldCropsAssignation = () => {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const needFinalization = useGetNeedFinalizationStep();
    const { fieldCropState } = useGetFarmSeasonFieldCrops();
    const [finalizeFieldCropsAssignation, { isLoading: isFinalizeLoading }] =
        entities.fieldCrop.useFinalizeAssignation();
    const { currentSeasonId } = encodingShared.useCurrentSeasonId();

    const finalizeAssignation = async () => {
        try {
            if (needFinalization) {
                await finalizeFieldCropsAssignation({
                    farmSeasonId: Number(currentSeasonId),
                    body: { farm_season_field_crop_ids: fieldCropState.list.map((fieldCrop) => fieldCrop.id) },
                }).unwrap();
            }
        } catch (e) {
            enqueueSnackbar(t('encoding-rotation.modal.finalization-error'), { variant: 'error' });
            console.error(e);
        }
    };

    return { finalizeAssignation, isFinalizeLoading };
};
