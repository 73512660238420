import { makeStyles } from '@soil-capital/ui-kit/style';

export const usePricingCardStyle = makeStyles()((theme) => ({
    container: {
        boxShadow: '0px 0px 32px 8px rgba(50, 63, 69, 0.08)',
        borderRadius: theme.shape.borderRadiusM,
        padding: theme.spacing(3),
        display: 'grid',
        gridAutoRows: 'auto',
        gap: theme.spacing(3),
        width: '100%',
    },
    border: {
        border: '4px solid',
        borderColor: theme.palette.success.main,
    },
    title: {
        color: theme.palette.darkScale[900],
        textAlign: 'center',
    },
    flexbox: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    button: {
        display: 'flex',
        justifyContent: 'start',
        color: theme.palette.darkScale[900],
    },
    cta: { color: theme.palette.darkScale[900] },
    ctaContained: { color: theme.palette.common.white, padding: theme.spacing(2) },
    price: { textDecoration: 'line-through' },
    greyContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
    },
    dark: { color: theme.palette.darkScale[700], minHeight: '44px' },
}));
